import { processFontFamily } from 'expo-font';
import React from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import Colors from '../constants/Colors';

const ReportsBarGraphWeb = (props) => (
    <VictoryChart
      containerComponent={
        <VictoryVoronoiContainer
          style={{
            flex: 1,
            zIndex: -1,
            width: '100%',
          }}
        />
      }
      width={666}
      height={287}
      domain={{ x: [0, 4], y: [0, 100] }}
      padding={{
        top: 30,
        left: 180,
        right: 20,
        bottom: 10,
      }}
    >
      <VictoryAxis
        disableInlineStyles
        fixLabelOverlap={true}
        dependentAxis
        style={{
          axis: { stroke: 'none' },
          tickLabels: {
            stroke: Colors.light.hereworksBlack400,
            fontFamily: processFontFamily('Poppins_400Regular'),
            fontSize: 14,
            strokeWidth: 0,
          },
          grid: {
            stroke: Colors.light.hereworksWhite100,
            strokeWidth: 2,
            pointerEvents: 'none',
          },
        }}
      />
      <VictoryAxis
        disableInlineStyles
        fixLabelOverlap={true}
        axisLabelComponent={
          <VictoryLabel style={{ stroke: Colors.light.hereworksYellow500, fontSize: 12 }} />
        }
        tickFormat={(t) => {
          if (typeof t !== 'string') {
            return '';
          }
          const split = t.split(' ', 4);
          const line1 = `${split[0] ?? ''} ${split[1] ?? ''}`;
          if (split[2] === undefined) {
            return line1;
          }
          const line2 = (split[2] ?? '') + (split[3] ?? '');
          return `${line1}\n${line2}`;
        }}
        style={{
          axis: { stroke: 'none' },
          tickLabels: {
            fill: Colors.light.hereworksBlack400,
            fontFamily: processFontFamily('Poppins_400Regular'),
            fontSize: 14,
            strokeWidth: 0,
          },
        }}
      />
      {!props.compareToggled && (
        <VictoryGroup offset={34}>
          <VictoryBar
            horizontal
            style={{
              data: { fill: Colors.light.hereworksDarkBlue },
              labels: {
                fontSize: 12,
                strokeWidth: 0,
                fill: Colors.light.hereworksDarkBlue,
                fontFamily: processFontFamily('Roboto_400Regular'),
              },
            }}
            x={'key'}
            y={'value'}
            data={props.data}
            barWidth={26}
            labels={({ datum }) => datum.value}
            labelComponent={<VictoryTooltip />}
            cornerRadius={{ top: 2 }}
          />
        </VictoryGroup>
      )}
      {props.compareToggled && (
        <VictoryGroup offset={15}>
          <VictoryBar
            horizontal
            style={{ data: { fill: Colors.light.hereworksYellow500 } }}
            x={'key'}
            y={'value'}
            data={props.compareData}
            labels={({ datum }) => datum.value}
            labelComponent={<VictoryTooltip centerOffset={{ y: 8 }} />}
            barWidth={11}
            cornerRadius={{ top: 2 }}
          />
          <VictoryBar
            horizontal
            style={{ data: { fill: Colors.light.hereworksDarkBlue } }}
            x={'key'}
            y={'value'}
            data={props.data}
            labels={({ datum }) => datum.value}
            labelComponent={<VictoryTooltip centerOffset={{ y: -8 }} />}
            barWidth={11}
            cornerRadius={{ top: 2 }}
          />
        </VictoryGroup>
      )}
    </VictoryChart>
);

export default ReportsBarGraphWeb;
