import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CompareArrowsIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="M9 12H2m7 0-3 3m3-3L6 9M7 5h7M7 5l3 3M7 5l3-3"
      stroke="#2173C4"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default CompareArrowsIcon;
