import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const NotificationsSettingsIcon = (props: SvgProps) => (
  <Svg
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <Rect width={40} height={40} rx={8} fill="none" />
    <Path
      d="M29.849 26.5c.04-.332.04-.668 0-1l1.057-.82c.1-.07.12-.18.06-.32l-1.007-1.72c-.06-.11-.19-.14-.302-.11L28.4 23c-.282-.17-.533-.34-.845-.46l-.191-1.33a.249.249 0 0 0-.252-.21h-2.013c-.12 0-.232.09-.252.21l-.18 1.33c-.323.12-.575.29-.856.46l-1.228-.47c-.131-.03-.272 0-.332.11l-1.007 1.72c-.06.14-.03.25.06.32l1.067.82c-.02.17-.04.34-.04.5 0 .16.02.33.04.5l-1.067.82c-.09.07-.12.21-.06.32l1.007 1.73c.06.13.2.13.332.13l1.228-.53c.281.2.533.37.855.5l.181 1.32c.02.12.131.21.252.21h2.013a.25.25 0 0 0 .252-.21l.19-1.32c.313-.13.564-.3.846-.5l1.258.53c.111 0 .242 0 .302-.13l1.007-1.73c.06-.11.04-.25-.06-.32l-1.057-.82Zm-3.744 1.25c-.967 0-1.762-.78-1.762-1.75s.795-1.75 1.762-1.75c.966 0 1.76.78 1.76 1.75s-.784 1.75-1.76 1.75ZM19.139 27H10v-1l2.013-2v-6c0-3.1 2.013-5.8 5.033-6.7V11c0-1.1.906-2 2.013-2 1.107 0 2.013.9 2.013 2v.3c3.02.9 5.033 3.6 5.033 6.7v1c-.695 0-1.38.11-2.014.29V18c0-2.8-2.214-5-5.032-5-2.819 0-5.033 2.2-5.033 5v7h5.113c-.05.33-.08.66-.08 1 0 .34.03.67.08 1Zm.222 1c.201.6.443 1.17.765 1.69-.312.19-.675.31-1.067.31-1.107 0-2.013-.9-2.013-2h2.315Z"
      fill="#2173C4"
    />
  </Svg>
);

export default NotificationsSettingsIcon;
