import React, { useRef, useState } from 'react';
import {
  ActivityIndicator,
  Animated,
  BackHandler,
  Pressable,
  RefreshControl,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Modal from 'react-native-modal';
import * as Analytics from 'expo-firebase-analytics';
import { FlatList, ScrollView } from 'react-native-gesture-handler';
import * as Animatable from 'react-native-animatable';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { useFocusEffect } from '@react-navigation/native';
import SearchIcon from '../components/SearchIcon';
import Colors from '../constants/Colors';
import getButtonInstances from '../requests/GetButtonInstances';
import { RootTabScreenProps } from '../types';
import EllipsisIcon from '../components/EllipsisIcon';
import OrionArrowIcon from '../components/24pxIcons/OrionArrowIcon';
import PlusIcon from '../components/16pxIcons/PlusIcon';
import AddTemplateGroupModal from '../components/AssetManagement/AddTemplateGroupModal';
import LeftArrow16 from '../components/16pxIcons/LeftArrow16';
import TemplateGroupRowMobile from '../components/AssetManagement/TemplateGroupRowMobile';
import TemplateSideBarRow from '../components/AssetManagement/TemplateSideBarRow';
import CheckBox from '../components/16pxIcons/CheckBox';
import AssetRowMobile from '../components/AssetManagement/AssetRowMobile';
import AssetCardMobile from '../components/AssetManagement/AssetCardMobile';
import DownloadIcon from '../components/DownloadIcon';
import FilterSearchBox from '../components/FilterSearchBox';
import FilterIconMobile16 from '../components/16pxIcons/FilterIconMobile';
import ScanIcon16 from '../components/16pxIcons/ScanIcon16';
import AddAssetModal from '../components/AssetManagement/AddAssetModal';
import CloseModalIcon from '../components/CloseModalIcon';
import Style from '../constants/Style';
import assetSearch from '../requests/AssetSearch';
import AlertModal from '../components/AlertModal';
import SearchResultsPane from '../components/AssetManagement/SearchResultsPane';
import ReportIssueIcon from '../components/12pxIcons/ReportIssueIcon';
import ReportIssueModal from '../components/AssetManagement/ReportIssueModal';
import AddTemplateModal, { Template, TemplateGroup } from '../components/AssetManagement/AddTemplateModal';
import getTemplateGroups from '../requests/GetTemplateGroups';
import { UserGroup } from '../components/AssetManagement/AddTemplateModal/IssueRow';
import getUserGroups from '../requests/GetUserGroups';

export default function TemplateMgmtScreenMobile({
  // eslint-disable-next-line no-unused-vars
  navigation, route,
}: RootTabScreenProps<'AssetManagement'>) {
  // todo - hook in setLoading
  // eslint-disable-next-line no-unused-vars
  const [instancesLoading, setInstancesLoading] = useState(true);
  const [prototypesLoading, setPrototypesLoading] = useState(true);

  const [addTemplateModalVisible, setAddTemplateModalVisible] = useState(false);
  const [reportIssueModalVisible, setReportIssueModalVisible] = useState(false);

  const [linkAssetsView, setLinkAssetsView] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [optionsSelected, setOptionsSelected] = useState(null);
  const [templateGroups, setTemplateGroups] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [floors, setFloors] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [allInstances, setAllInstances] = useState([]);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);

  const [selectedGroupID, setSelectedGroupID] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [assetFilterVisible, setAssetFilterVisible] = useState(false);
  const [detailedFilterVisible, setDetailedFilterVisible] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterSerial, setFilterSerial] = useState('');
  const [filterModel, setFilterModel] = useState('');
  const [filterDescription, setFilterDescription] = useState('');
  const [filterManufacturer, setFilterManufacturer] = useState('');
  const [assetFilterLoading, setAssetFilterLoading] = useState(false);
  const [assetSearchResults, setAssetSearchResults] = useState([]);
  const [roomSelected, setRoomSelected] = useState(false);
  const [assetCount, setAssetCount] = useState(0);
  const [allSelected, setAllSelected] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [assetScanValue, setAssetScanValue] = useState(null);
  const [addGroupModalVisible, setAddGroupModalVisible] = useState(false);
  const [templateDrawerInset] = useState(new Animated.Value(-300));
  const [assetDrawerOpen, setAssetDrawerOpen] = useState(false);
  const [templateDrawerOpen, setTemplateDrawerOpen] = useState(false);
  const [sortById, setSortById] = useState(true);
  const [selectAsset, setSelectAsset] = useState(null);
  const [name, updateName] = useState('Anonymous');
  const [scanned, setScanned] = useState(true);
  const [hasPermission, setHasPermission] = useState(null);
  const [addAssetModalVisible, setAddAssetModalVisible] = useState(false);
  const [assetModalSessionActive, setAssetModalSession] = useState(true);
  const [templateSideBarContext, setTemplateSideBarContext] = useState(null);
  const showParentAnalytics = false;
  const deleteEnabled = false;

  const assetDrawerInset = useRef(new Animated.Value(-300)).current;

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [noDataAlertShown, setNoDataAlertShown] = useState(false);

  const showAlert = (title: string, message?: string, error: boolean = true) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertError(error);
    setAlertVisible(true);
    setNoDataAlertShown(true);
  };

  const handleBarCodeScanned = ({ data }) => {
    setScanned(true);
    // Alert.alert(data);
    setAssetScanValue(data.split('.tech/')[1]?.split('?')[0]);
  };

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'TemplateManagement',
      name: title,
    });
  };

  // eslint-disable-next-line func-names
  const groupBy = function <TItem> (xs: TItem[], key: string): { [key: string]: TItem[] } {
    return xs?.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  React.useEffect(() => {
    // @ts-ignore
    const assetId: string = route?.params?.assetId;
    if (assetId && allInstances?.length) {
      setLinkAssetsView(true);
      const filteredAsset = allInstances
        ?.filter((asset: { ButtonInstanceId: string }) => asset.ButtonInstanceId === assetId)[0];
      if (filteredAsset) setSelectAsset(filteredAsset);
      else showAlert('Error', 'Linked asset could not be found. It may have been deleted.');
    }
    // @ts-ignore
  }, [allInstances, route?.params?.assetId]);

  React.useEffect(() => {
    if (
      assetScanValue !== null
      && allInstances?.filter((instance) => instance.ButtonInstanceId.toLowerCase().includes(`${assetScanValue}`.toLowerCase())).length === 0
    ) {
      showAlert('Asset not found!');
      setAssetScanValue(null);
    }
    allInstances
      ?.filter((instance) => instance.ButtonInstanceId.toLowerCase().includes(`${assetScanValue}`.toLowerCase()))
      .map((found) => setSelectAsset(found));
  }, [assetScanValue]);

  const noTemplatesFound = () => {
    if (!noDataAlertShown) {
      showAlert('Error', 'Could not load asset templates at the moment. Please try again later.');
      setNoDataAlertShown(true);
      setPrototypesLoading(false);
    }
  };

  const noAssetsFound = () => {
    if (!noDataAlertShown) {
      showAlert('Error', 'Could not load assets at the moment. Please try again later.');
      setNoDataAlertShown(true);
      setInstancesLoading(false);
    }
  };

  React.useEffect(() => {
    getTemplateGroups().then((response) => {
      if (!response?.groups) noTemplatesFound();
      else {
        setTemplateGroups(response?.groups ?? []);
        setTemplates(response?.groups
          ?.reduce((prev, group) => {
            if (group?.buttonPrototypes?.length) return prev.concat(group.buttonPrototypes);
            return prev;
          }, []) ?? []);
        setPrototypesLoading(false);
      }
    }).catch(noTemplatesFound);
    getUserGroups().then((response: { UserGroups?: UserGroup[] }) => {
      if (response?.UserGroups?.length) {
        setUserGroups(response?.UserGroups
          ?.sort((a: UserGroup, b: UserGroup) => a.Name?.localeCompare(b.Name)));
      }
    });
  }, []);

  const loadAssets = () => getButtonInstances().then((response) => {
    if (response?.instances) {
      const groupFloors = groupBy(response.instances, 'LocationFloor');
      const groupRooms = groupBy(
        response.instances?.sort((a, b) => a.LocationRoom?.localeCompare(b.LocationRoom)),
        'LocationRoom',
      );
      setFloors(groupFloors);
      setRooms(groupRooms);
      if (response.instances.length > 0) {
        setAllInstances(response.instances);
      }
      // if (selectAsset) {
      //   setSelectAsset(response.instances.filter(
      //     (item) => item.ButtonInstanceId === selectAsset.ButtonInstanceId,
      //   )[0]);
      // }
      setInstancesLoading(false);
    } else noAssetsFound();
    setInstancesLoading(false);
  }).catch(noAssetsFound);

  const updateTemplateLocally = (template: Template, add: boolean, groupID: string) => {
    const updatedTemplateGroups: TemplateGroup[] = [...templateGroups];
    const targetTemplateGroup: TemplateGroup = updatedTemplateGroups
      ?.filter((group: TemplateGroup) => group.ButtonPrototypeGroupId === groupID)[0];
    if (add) targetTemplateGroup.buttonPrototypes.push(template);
    else {
      targetTemplateGroup.buttonPrototypes = targetTemplateGroup.buttonPrototypes
        ?.map((t) => (t.id === template.id ? template : t));
    }
    setTemplateGroups(updatedTemplateGroups);
    setTemplates(updatedTemplateGroups
      ?.reduce((prev, group) => {
        if (group?.buttonPrototypes?.length) return prev.concat(group.buttonPrototypes);
        return prev;
      }, []) ?? []);
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AsyncStorage.getItem('name').then((n) => {
      updateName(n);
    }) ?? 'Anonymous';

    loadAssets();

    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    };

    getBarCodeScannerPermissions();
  }, [instancesLoading]);

  React.useEffect(() => {
    if (!assetDrawerOpen) {
      Animated.timing(assetDrawerInset, {
        toValue: -300,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(assetDrawerInset, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  }, [assetDrawerInset, assetDrawerOpen]);

  React.useEffect(() => {
    if (!templateDrawerOpen) {
      Animated.timing(templateDrawerInset, {
        toValue: -300,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  }, [templateDrawerOpen]);

  React.useEffect(() => {
    let count = 0;
    Object.keys(floors ?? []).map((floor) =>
      // eslint-disable-next-line implicit-arrow-linebreak, consistent-return
      floors[floor]?.forEach((instance) => {
        if (
          instance.LocationRoom === selectedRoom?.LocationRoom
          || instance.ButtonPrototypeId === selectedId
        ) {
          count += 1;
        }
      }));
    setAssetCount(count);
  }, [selectedRoom, selectedId, instancesLoading, allInstances]);

  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (selectAsset !== null) {
          setSelectAsset(null);
          return true;
        }
        return false;
      };

      const subscription = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => subscription.remove();
    }, [selectAsset]),
  );

  const resetAssetFilters = () => {
    setFilterName('');
    setFilterModel('');
    setFilterManufacturer('');
    setFilterDescription('');
    setFilterSerial('');
  };

  const clearAssetSearchResults = () => {
    setAssetSearchResults([]);
    setDetailedFilterVisible(false);
    resetAssetFilters();
  };

  React.useEffect(() => {
    clearAssetSearchResults();
    setSearchValue('');
  }, [selectedTemplate]);

  const clearTabSelection = () => {
    setSelectedTitle('');
    setSearchValue('');
    setSelectedTemplate(null);
    setAllSelected(false);
    setSelectedGroupID(null);
    setTemplateSideBarContext(null);
    setSelectedId(null);
    if (templateDrawerOpen) {
      setTemplateDrawerOpen(false);
    }
    if (assetDrawerOpen) {
      setAssetDrawerOpen(false);
    }
    setSelectAsset(null);
    resetAssetFilters();
    clearAssetSearchResults();
  };

  const applyAssetFilters = () => {
    setAssetFilterLoading(true);
    assetSearch(filterName, filterManufacturer, filterDescription, filterSerial, filterModel)
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            if (json.result?.length > 0) {
              setAssetSearchResults(
                json.result.map(
                  (filteredInstance) => allInstances?.filter(
                    (instance) => instance.ButtonInstanceId === filteredInstance.ButtonInstanceId,
                  )[0],
                ),
              );
              setDetailedFilterVisible(false);
            } else {
              showAlert('Error', 'No assets found with the provided search values');
            }
          });
        } else {
          showAlert('Error', 'No assets found with the provided search values');
        }
        setAssetFilterLoading(false);
      })
      .catch(() => {
        showAlert('Error', 'Search could not be completed. Please try again');
        setAssetFilterLoading(false);
      });
  };

  const filterAssets = () => {
    let assetsToRender = allInstances;
    if (sortById) {
      // eslint-disable-next-line max-len
      assetsToRender = allInstances.sort((a, b) => a.Name?.localeCompare(b.Name));
    } else if (!sortById) {
      // eslint-disable-next-line max-len
      assetsToRender = allInstances.sort((a, b) => b.Name?.localeCompare(a.Name));
    }
    if (selectedTemplate) {
      assetsToRender = assetsToRender?.filter((asset: { ButtonPrototypeId: string }) => asset
        .ButtonPrototypeId === selectedTemplate?.id);
    } else if (selectedRoom) {
      assetsToRender = assetsToRender?.filter((asset: { LocationId: string }) => asset
        ?.LocationId === selectedRoom?.LocationId);
    }
    return assetsToRender;
  };

  const renderTemplate = (template: any) => (
    <TouchableOpacity
      disabled={!assetDrawerOpen}
      onPress={() => {
        if (selectedTemplate !== null && selectedTemplate?.id === template.id) {
          setSelectedTemplate(null);
          setSelectedId(null);
          setSelectedTitle('');
        } else {
          setSelectedTemplate(template);
          setSelectedId(template.id);
          setSelectedTitle(template.name);
        }
        setAllSelected(false);
        setAssetDrawerOpen(false);
      }}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor:
          selectedTemplate?.id === template.id ? Colors.light.hereworksDarkBlue : 'transparent',
      }}
    >
      <Text
        style={[
          styles.sectionTitle,
          {
            color: selectedTemplate?.id === template.id ? '#fff' : Colors.light.hereworksBlack500,
          },
        ]}
      >
        {template.name}
      </Text>
      {!linkAssetsView && (
        <EllipsisIcon fill={'#D9D9D9'} style={{ position: 'absolute', right: 24 }} />
      )}
    </TouchableOpacity>
  );

  const renderRoom = (room: any) => (
    <TouchableOpacity
      onPress={() => {
        if (selectedRoom !== null && selectedRoom?.LocationId === room[0]?.LocationId) {
          setSelectedRoom(null);
          setSelectedTitle('');
        } else {
          setSelectedRoom(room[0]);
          setSelectedTitle(room[0]?.LocationRoom);
        }
        setAllSelected(false);
        setAssetDrawerOpen(false);
      }}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor:
          selectedRoom?.LocationId === room[0]?.LocationId
            ? Colors.light.hereworksDarkBlue
            : 'transparent',
      }}
    >
      <Text
        style={[
          styles.sectionTitle,
          {
            color:
              selectedRoom?.LocationId === room[0]?.LocationId
                ? '#fff'
                : Colors.light.hereworksBlack500,
          },
        ]}
      >
        {room[0]?.LocationRoom}
      </Text>
      {!linkAssetsView && (
        <EllipsisIcon fill={'#D9D9D9'} style={{ position: 'absolute', right: 24 }} />
      )}
    </TouchableOpacity>
  );

  const renderTemplatesFromList = (templateList) => templateList
    ?.sort((a, b) => a.name?.localeCompare(b.name))
    .map((prototype, key) => (
      <View key={key} style={{ zIndex: 4 }}>
        {
          <TemplateGroupRowMobile
            {... {
              prototype, optionsSelected, templateGroups, userGroups,
            }}
            update={updateTemplateLocally}
            setLinkAssetsView={() => {
              setLinkAssetsView(!linkAssetsView);
              setSelectedTemplate(prototype);
              setAllSelected(false);
            }}
            setSelectedTitle={() => setSelectedTitle(prototype.name)}
            setSelectedId={() => setSelectedId(prototype.id)}
            setOptionsSelected={() => {
              setOptionsSelected(prototype.id);
            }}
            deleteAction={(id: string) => {
              const updatedTemplateGroups = [...templateGroups];
              updatedTemplateGroups.forEach((group) => {
                const updatedTemplates = group.buttonPrototypes ?? [];
                updatedTemplates.splice(updatedTemplates
                  .findIndex((currentTemplate) => currentTemplate.id === id), 1);
              });
              setTemplateGroups(updatedTemplateGroups);
              setTemplates(updatedTemplateGroups
                ?.reduce((prev, group) => {
                  if (group?.buttonPrototypes?.length) return prev.concat(group.buttonPrototypes);
                  return prev;
                }, []) ?? []);
            }}
          />
        }
      </View>
    ));

  const renderTemplates = () => {
    if (selectedGroupID === null) return renderTemplatesFromList(templates);
    return renderTemplatesFromList(templateGroups?.filter((group) => group.ButtonPrototypeGroupId
      === selectedGroupID)[0]?.buttonPrototypes ?? templates);
  };

  const renderTemplateSidebar = () => (
    <>
      {templateDrawerOpen && (
        <TouchableOpacity
          style={{
            backgroundColor: '#00000040',
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 5,
          }}
          onPress={() => setTemplateDrawerOpen(false)}
        />
      )}
      <Animated.View
        style={{
          width: 300,
          backgroundColor: Colors.light.hereworksBlue50,
          position: 'absolute',
          left: 0,
          height: '100%',
          zIndex: 6,
          transform: [{ translateX: templateDrawerInset }],
        }}
      >
        <View
          style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <View>
            <Text
              style={{
                fontFamily: 'Roboto_500Medium',
                fontSize: 12,
                lineHeight: 14,
                color: Colors.light.hereworksBlack500,
                marginTop: 24,
                marginLeft: 24,
              }}
            >
              TEMPLATE GROUPS
            </Text>
            <Text
              style={{
                fontFamily: 'Roboto_400Regular',
                fontSize: 12,
                lineHeight: 14,
                color: Colors.light.hereworksBlack500,
                marginTop: 12,
                marginLeft: 24,
                marginBottom: 17,
              }}
            >
              Choose template group
            </Text>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: Colors.light.hereworksBlue500,
              borderRadius: 4,
              padding: 6,
              marginRight: 24,
            }}
            onPress={() => {
              analytics('Add Template Group');
              setAddGroupModalVisible(!addGroupModalVisible);
            }}
          >
            <PlusIcon />
          </TouchableOpacity>
          <AddTemplateGroupModal
            isVisible={addGroupModalVisible}
            hide={() => setAddGroupModalVisible(false)}
            update={(Name: string, ButtonPrototypeGroupId: string, create: boolean) => {
              const updatedTemplateGroups = [...templateGroups];
              if (create) {
                updatedTemplateGroups
                  .push({ Name, ButtonPrototypeGroupId, buttonPrototypes: [] });
              } else {
                updatedTemplateGroups?.forEach((group) => {
                  if (group.ButtonPrototypeGroupId === ButtonPrototypeGroupId) {
                    group.Name = Name;
                  }
                });
              }
              setTemplateGroups(updatedTemplateGroups);
            }}
          />
          <AddTemplateModal
            {...{ templates, templateGroups, userGroups }}
            isVisible={addTemplateModalVisible}
            hide={() => setAddTemplateModalVisible(false)}
            update={updateTemplateLocally}
          />
        </View>
        <View
          style={{ backgroundColor: Colors.light.hereworksBlue100, height: 1, width: '100%' }}
        />
        <ScrollView>
          {templateGroups.map((group: any, key: number) => (
            <View key={key} style={{ zIndex: key * -1 }}>
              <TemplateSideBarRow
                group={group}
                selectedGroupID={selectedGroupID}
                onPress={() => {
                  if (selectedGroupID === group.ButtonPrototypeGroupId) {
                    setSelectedGroupID(null);
                  } else {
                    setSelectedGroupID(group.ButtonPrototypeGroupId);
                  }
                }}
                templateGroups={templateGroups}
                deleteAction={() => setTemplateGroups(
                  templateGroups?.filter((i) => i.ButtonPrototypeGroupId
                    !== group.ButtonPrototypeGroupId),
                )
                }
                contextMenuOpen={() => setTemplateSideBarContext(group.ButtonPrototypeGroupId)}
                contextMenuClose={() => setTemplateSideBarContext(null)}
                currentContextMenu={templateSideBarContext}
                update={(newName: string, id: string, create?: boolean, toDelete?: boolean) => {
                  const updatedGroups = [...templateGroups];
                  if (create) {
                    updatedGroups?.push({ Name: newName, ButtonPrototypeGroupId: id });
                  } else if (toDelete) {
                    updatedGroups.splice(updatedGroups.findIndex((templateGroup) => templateGroup
                      .ButtonPrototypeGroupId === id), 1);
                  } else {
                    updatedGroups?.forEach((templateGroup) => {
                      if (templateGroup.ButtonPrototypeGroupId === id) {
                        templateGroup.Name = newName;
                      }
                    });
                  }
                  setTemplateGroups(updatedGroups);
                }}
                mobile
              />
            </View>
          ))}
        </ScrollView>
      </Animated.View>
    </>
  );

  const renderManageGroupsView = () => (
    <Animatable.View
      animation={'fadeIn'}
      style={{
        marginTop: 20,
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#fff',
      }}
    >
      {renderTemplateSidebar()}
      <View
        style={{
          flex: 1,
          zIndex: 4,
        }}
      >
        <View style={{ zIndex: 4, flex: 1 }}>
          <Text
            style={{
              fontFamily: 'Roboto_500Medium',
              fontSize: 12,
              lineHeight: 20,
              color: Colors.light.hereworksBlack500,
              marginTop: 24,
              marginLeft: 24,
            }}
          >
            TEMPLATES
          </Text>
          {selectAsset === null && (<>
            <View style={styles.searchBoxRow}>
              <View style={styles.searchBox}>
                <SearchIcon style={{ marginLeft: 14 }} />
                <TextInput
                  placeholder="Search"
                  style={styles.searchText}
                  placeholderTextColor={Colors.light.hereworksBlack400}
                  value={searchValue}
                  onChangeText={(text) => {
                    setSearchValue(text);
                  }}
                />
              </View>
            </View>
            {renderSearchPane()}
          </>
          )}
          <View
            style={{
              backgroundColor: Colors.light.hereworksBlue100,
              height: 1,
              marginTop: 20,
              width: '100%',
            }}
          />
          {selectedGroupID !== null && (
            <Text
              style={{
                fontFamily: 'Poppins_400Regular',
                fontSize: 16,
                lineHeight: 24,
                color: Colors.light.hereworksBlack600,
                marginTop: 16,
                marginLeft: 24,
              }}
            >
              {templateGroups?.filter((group) => group.ButtonPrototypeGroupId
              === selectedGroupID)[0].Name}
            </Text>
          )}
          <ScrollView style={{ marginTop: 8 }}>{renderTemplates()}</ScrollView>
        </View>
        <View style={styles.bottomButtons}>
          <TouchableOpacity
            style={styles.backButton}
            onPress={() => {
              setTemplateDrawerOpen(true);
              Animated.timing(templateDrawerInset, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
              }).start();
            }}
          >
            <LeftArrow16 />
            <Text
              style={{
                color: Colors.light.hereworksBlue500,
                fontFamily: 'Roboto_400Regular',
                fontSize: 12,
                lineHeight: 22,
                marginLeft: 8,
              }}
            >
              Template groups
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.createButton}
            onPress={() => {
              analytics('Create new template');
              // showAlert('Feature coming soon!', null, false);
              setAddTemplateModalVisible(!addTemplateModalVisible);
            }}
          >
            <PlusIcon />
            <Text style={styles.createButtonTitle}>Add Template</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Animatable.View>
  );

  const renderAssetSidebar = () => (
    <>
      {assetDrawerOpen && (
        <TouchableOpacity
          style={{
            backgroundColor: '#00000040',
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 5,
          }}
          onPress={() => setAssetDrawerOpen(false)}
        />
      )}
      <Animated.View
        style={{
          width: 300,
          position: 'absolute',
          height: '100%',
          zIndex: 5,
          backgroundColor: Colors.light.hereworksBlue50,
          transform: [{ translateX: assetDrawerInset }],
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: assetFilterVisible
              ? Colors.light.hereworksBlue300
              : Colors.light.hereworksBlue50,
          }}
          onPress={() => setAssetFilterVisible(!assetFilterVisible)}
        >
          <Text
            style={{
              fontFamily: 'Roboto_500Medium',
              fontSize: 12,
              lineHeight: 20,
              color: assetFilterVisible ? '#fff' : Colors.light.hereworksBlack500,
              marginTop: 24,
              marginLeft: 24,
              marginBottom: 17,
            }}
          >
            {roomSelected ? 'BY ROOM' : 'BY TEMPLATE'}
          </Text>
          <OrionArrowIcon
            style={{
              position: 'absolute',
              right: 24,
              transform: [{ rotateZ: assetFilterVisible ? '90deg' : '270deg' }],
            }}
            color={assetFilterVisible ? '#fff' : Colors.light.hereworksBlack500}
          />
        </TouchableOpacity>
        <View style={{ backgroundColor: Colors.light.hereworksBlue100, height: 1, width: '100%' }}>
          {assetFilterVisible && (
            <View
              style={{
                backgroundColor: Colors.light.hereworksWhite200,
                position: 'absolute',
                top: 0,
                zIndex: 1,
                width: '100%',
                borderTopWidth: 1,
                borderBottomWidth: 1,
                borderColor: Colors.light.hereworksBlue100,
              }}
            >
              {roomSelected && (
                <TouchableOpacity
                  style={{ paddingLeft: 24, paddingVertical: 13 }}
                  onPress={() => {
                    setRoomSelected(false);
                    setSelectedTemplate(null);
                    setAllSelected(false);
                    setSelectedId(null);
                    setSelectedRoom(null);
                    setAssetFilterVisible(false);
                  }}
                >
                  <Text>BY TEMPLATE</Text>
                </TouchableOpacity>
              )}
              {!roomSelected && (
                <TouchableOpacity
                  style={{ paddingLeft: 24, paddingVertical: 13 }}
                  onPress={() => {
                    setRoomSelected(true);
                    setSelectedTemplate(null);
                    setAllSelected(false);
                    setSelectedId(null);
                    setSelectedRoom(null);
                    setAssetFilterVisible(false);
                  }}
                >
                  <Text>BY ROOM</Text>
                </TouchableOpacity>
              )}
            </View>
          )}
        </View>
        <ScrollView
          style={{ zIndex: -1, height: '100%' }}
          contentContainerStyle={{ paddingBottom: 40 }}
          scrollEnabled={assetDrawerOpen}
        >
          <View>
            {!roomSelected
              && (templates ?? []).map((template: any, key: number) => (
                <View key={key} style={{ zIndex: -1 }}>
                  {renderTemplate(template)}
                </View>
              ))}
            {roomSelected
              && Object.keys(rooms ?? []).sort().map((room: string, key: number) => {
                if (rooms[room][0]?.LocationRoom === undefined) {
                  return null;
                }
                return (
                  <View key={key} style={{ zIndex: -1 }}>
                    {renderRoom(rooms[room])}
                  </View>
                );
              })}
          </View>
        </ScrollView>
      </Animated.View>
    </>
  );

  const safeAreaInsets = useSafeAreaInsets();

  const renderSearchPane = () => (searchValue?.length ? <SearchResultsPane
    searchValue={searchValue}
    assets={allInstances}
    templates={templates}
    templateGroups={templateGroups}
    selectTemplate={setSelectedTemplate}
    selectAsset={setSelectAsset}
    selectTemplateGroup={setSelectedGroupID}
    showAssets={setLinkAssetsView}
    setSearchValue={setSearchValue}
    selectedAsset={selectAsset}
    selectedTemplate={selectedTemplate}
    selectedTemplateGroup={selectedGroupID}
  /> : null);

  const scanner = () => {
    if (hasPermission === null) {
      return <Text>Requesting for camera permission</Text>;
    }
    if (hasPermission === false) {
      return <Text>No access to camera</Text>;
    }

    return (
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />
    );
  };

  if (prototypesLoading) {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size={'large'} color={Colors.light.hereworksLoading} />
        </View>
      </View>
    );
  }

  const refresh = () => {
    setAllInstances([]);
    setPrototypesLoading(true);
    setInstancesLoading(true);
    const timeout = setTimeout(() => {
      setAssetModalSession(false);
      setAssetModalSession(true);
      clearTimeout(timeout);
    }, 500);
    loadAssets();
    clearTabSelection();
  };

  const deleteAsset = (assetId: string) => {
    let instances = [...allInstances];
    instances = instances.filter((asset) => asset.ButtonInstanceId !== assetId);
    setAllInstances(instances);
    setSelectAsset(null);
  };

  const updateAsset = (updatedAsset) => {
    let instances = [...allInstances];
    instances = instances.map((asset) => {
      if (asset.ButtonInstanceId === updatedAsset.ButtonInstanceId) {
        return updatedAsset;
      }
      return asset;
    });
    setAllInstances(instances);
  };

  const addAsset = (asset) => {
    const instances = [...allInstances];
    instances.push(asset);
    setAllInstances(instances);
  };

  const renderItem = ({ item, index }) => (
    <AssetRowMobile
      mobile={true}
      instance={item}
      buttonKey={`Instance_${index}`}
      selected={allSelected ?? null}
      selectAsset={() => setSelectAsset(item)}
      selectionChanged={() => {
        if (allSelected) {
          setAllSelected(null);
        }
      }}
    />
  );

  return (
    <View
      style={{
        backgroundColor: Colors.light.hereworksBackground,
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Modal
        style={styles.detailedFilterModal}
        animationIn="slideInRight"
        animationOut="slideOutRight"
        swipeDirection="right"
        propagateSwipe
        isVisible={detailedFilterVisible}
        onSwipeComplete={() => setDetailedFilterVisible(false)}
        onBackdropPress={() => setDetailedFilterVisible(false)}
      >
        <View
          style={[
            styles.detailedFilterContainer,
            { paddingTop: safeAreaInsets.top > 0 ? 12 + safeAreaInsets.top : 24 },
            { paddingBottom: safeAreaInsets.bottom > 0 ? 4 + safeAreaInsets.bottom : 24 },
            { width: 300 + safeAreaInsets.right },
          ]}
        >
          <Text style={styles.selectedTitle}>Filter</Text>
          <ScrollView
            contentContainerStyle={{ paddingTop: 30, paddingEnd: 24 + safeAreaInsets.right }}
          >
            <FilterSearchBox
              mobile={true}
              title={'Name'}
              placeholder={'Enter name'}
              value={filterName}
              onChangeText={setFilterName}
            />
            <FilterSearchBox
              mobile={true}
              title={'Model'}
              placeholder={'Enter model'}
              value={filterModel}
              onChangeText={setFilterModel}
            />
            <FilterSearchBox
              mobile={true}
              title={'Serial number'}
              placeholder={'Enter serial number'}
              value={filterSerial}
              onChangeText={setFilterSerial}
            />
            <FilterSearchBox
              mobile={true}
              title={'Description'}
              placeholder={'Enter description'}
              value={filterDescription}
              onChangeText={setFilterDescription}
            />
            <FilterSearchBox
              mobile={true}
              title={'Manufacturer'}
              placeholder={'Enter manufacturer'}
              value={filterManufacturer}
              onChangeText={setFilterManufacturer}
            />
          </ScrollView>
          <TouchableOpacity
            style={styles.resetAllFiltersButton}
            onPress={() => {
              analytics('Reset All Button Pressed');
              resetAssetFilters();
            }}
          >
            <Text style={styles.resetAllFiltersText}>RESET ALL FILTERS</Text>
          </TouchableOpacity>
          {assetSearchResults.length > 0 && (
            <TouchableOpacity
              style={styles.resetAllFiltersButton}
              onPress={() => {
                analytics('Asset filters - Clear Search Results button pressed');
                clearAssetSearchResults();
              }}
            >
              <Text style={styles.resetAllFiltersText}>CLEAR SEARCH RESULTS</Text>
            </TouchableOpacity>
          )}
          <View style={{ flexDirection: 'row', marginTop: 24 }}>
            <TouchableOpacity
              style={styles.applyFiltersButton}
              onPress={() => {
                analytics('Asset filters - Apply button pressed');
                if (
                  filterName.trim().length === 0
                  && filterModel.trim().length === 0
                  && filterSerial.trim().length === 0
                  && filterDescription.trim().length === 0
                  && filterManufacturer.trim().length === 0
                ) showAlert('Warning', 'No filters set');
                else applyAssetFilters();
              }}
            >
              <Text style={styles.applyFiltersText}>Apply</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.detailedFilterCancelButton}
              onPress={() => setDetailedFilterVisible(false)}
            >
              <Text style={styles.detailedFilterCancelText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
        <ActivityIndicator
          style={styles.assetFilterSpinner}
          animating={assetFilterLoading}
          size="large"
        />
        <AlertModal modalVisible={alertVisible}
          title={alertTitle}
          message={alertMessage}
          error={alertError}
          setModalVisible={setAlertVisible} />
      </Modal>
      <Modal
        isVisible={!scanned}
        style={{ alignItems: 'center', margin: 0 }}
        onSwipeComplete={() => setScanned(true)}
        swipeDirection={'down'}
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
      >
        {scanner()}
        <TouchableOpacity
          onPress={() => setScanned(true)}
          style={[Style.closeButton, { top: safeAreaInsets.top ?? 32 }]}
        >
          <CloseModalIcon />
        </TouchableOpacity>
      </Modal>
      {assetModalSessionActive && (
        <AddAssetModal
          mobile={true}
          visible={addAssetModalVisible}
          setModalVisible={setAddAssetModalVisible}
          template={selectedTemplate ?? templates[0]}
          addAsset={addAsset}
        />
      )}
      <View style={{ marginTop: 30, flexDirection: 'row', marginLeft: 24 }}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <TouchableOpacity
            onPress={() => {
              clearTabSelection();
              setLinkAssetsView(false);
            }}
            activeOpacity={!linkAssetsView ? 1 : undefined}
          >
            <Text
              style={{
                fontSize: 16,
                lineHeight: 24,
                fontFamily: 'Poppins_400Regular',
                color: !linkAssetsView
                  ? Colors.light.hereworksBlack600
                  : Colors.light.hereworksBlack300,
              }}
            >
              Templates
            </Text>
            {!linkAssetsView && (
              <View
                style={{ backgroundColor: Colors.light.hereworksBlack600, height: 2, marginTop: 4 }}
              />
            )}
          </TouchableOpacity>
          <TouchableOpacity
            style={{ marginLeft: 24 }}
            onPress={() => {
              clearTabSelection();
              setLinkAssetsView(true);
            }}
            activeOpacity={linkAssetsView ? 1 : undefined}
          >
            <Text
              style={{
                fontSize: 16,
                lineHeight: 24,
                fontFamily: 'Poppins_400Regular',
                color: !linkAssetsView
                  ? Colors.light.hereworksBlack300
                  : Colors.light.hereworksBlack600,
              }}
            >
              Assets
            </Text>
            {linkAssetsView && (
              <View
                style={{ backgroundColor: Colors.light.hereworksBlack600, height: 2, marginTop: 4 }}
              />
            )}
          </TouchableOpacity>
          <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 24 }}>
            <TouchableOpacity
              style={{
                borderColor: Colors.light.hereworksBlue500,
                borderRadius: 4,
                borderWidth: 1,
                height: 32,
                width: 32,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={() => {
                showAlert('Feature coming soon!', null, false);
              }}
            >
              <DownloadIcon color={Colors.light.hereworksBlue500} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {!linkAssetsView && renderManageGroupsView()}
      {linkAssetsView && (
        <View
          style={{
            borderRadius: 24,
            marginTop: 20,
            backgroundColor: '#fff',
            flex: 1,
          }}
        >
          {!prototypesLoading && renderAssetSidebar()}
          <View
            style={{
              flex: 1,
              zIndex: 4,
            }}
          >
            <View style={{ zIndex: 4, flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingHorizontal: 24,
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Roboto_500Medium',
                    fontSize: 12,
                    lineHeight: 20,
                    color: Colors.light.hereworksBlack500,
                    marginTop: 24,
                    marginBottom: 17,
                  }}
                >
                  ASSETS
                </Text>
                {assetCount > 0 && (
                  <Text
                    style={{
                      fontFamily: 'Roboto_500Medium',
                      fontSize: 12,
                      lineHeight: 20,
                      color: Colors.light.hereworksBlack500,
                      marginTop: 24,
                      marginLeft: 4,
                      marginBottom: 17,
                    }}
                  >
                    {`> ${selectedTitle?.toUpperCase()}`}
                  </Text>
                )}
              </View>
              <View
                style={{ backgroundColor: Colors.light.hereworksBlue100, height: 1, width: '100%' }}
              />
              {selectAsset === null && (<>
                <View style={styles.searchBoxRow}>
                  <View style={styles.searchBox}>
                    <SearchIcon style={{ marginLeft: 14 }} />
                    <TextInput
                      placeholder="Search"
                      style={styles.searchText}
                      placeholderTextColor={Colors.light.hereworksBlack400}
                      value={searchValue}
                      onChangeText={(text) => {
                        setSearchValue(text);
                      }}
                    />
                  </View>
                  <TouchableOpacity
                    onPress={() => setScanned(!scanned)}
                    style={[styles.detailedFilterButton, { padding: 8, marginRight: 8 }]}
                  >
                    <ScanIcon16 />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      analytics('Filter button');
                      // showAlert('Feature coming soon!', null, false);
                      setDetailedFilterVisible(true);
                    }}
                    style={styles.detailedFilterButton}
                  >
                    <FilterIconMobile16 />
                  </TouchableOpacity>
                </View>
                {renderSearchPane()}
              </>
              )}
              {selectAsset === null && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 12,
                    paddingBottom: 7,
                    marginHorizontal: 22,
                    alignSelf: 'stretch',
                    alignItems: 'center',
                    borderColor: Colors.light.hereworksWhite500,
                    borderBottomWidth: 1,
                  }}
                >
                  {deleteEnabled && (
                    <TouchableOpacity
                      onPress={() => {
                        setAllSelected(!allSelected);
                      }}
                    >
                      <CheckBox
                        checkcolor={allSelected ? undefined : '#fff'}
                        color={allSelected ? undefined : Colors.light.hereworksBlue100}
                        style={{ marginLeft: 26, marginRight: 14 }}
                      />
                    </TouchableOpacity>
                  )}
                  <View style={{ flexDirection: 'row', flex: 1 }}>
                    <View style={{ marginLeft: 44, justifyContent: 'center' }}>
                      <Text style={styles.headerText}>IMAGE</Text>
                    </View>
                    <View style={styles.sortContainer}>
                      <TouchableOpacity
                        onPress={() => {
                          setSortById(!sortById);
                        }}
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          borderWidth: 1,
                          borderRadius: 4,
                          backgroundColor: sortById ? Colors.light.hereworksWhite100 : '#fff',
                          borderColor: sortById ? Colors.light.hereworksWhite500 : '#fff',
                        }}
                      >
                        <OrionArrowIcon
                          style={{ transform: [{ rotateZ: sortById ? '90deg' : '270deg' }] }}
                        />
                        <Text style={styles.headerText}>ID</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}
              {
                assetSearchResults.length > 0 ? (
                  <FlatList
                    style={{ paddingHorizontal: 24 }}
                    refreshControl={<RefreshControl
                      refreshing={prototypesLoading || instancesLoading} onRefresh={refresh} />}
                    data={assetSearchResults}
                    renderItem={renderItem}
                    keyExtractor={(item, index) => String(index)}
                  />
                ) : (
                  <FlatList
                    style={{ paddingHorizontal: 24 }}
                    refreshControl={<RefreshControl
                      refreshing={prototypesLoading || instancesLoading} onRefresh={refresh} />}
                    data={filterAssets()}
                    renderItem={renderItem}
                    keyExtractor={(item, index) => String(index)}
                  />
                )
                /* {assetSearchResults.length > 0
                    ? assetSearchResults.map((instance, index) => <AssetRowMobile
                      mobile={true}
                      instance={instance}
                      buttonKey={`Instance_${index}`}
                      selected={allSelected ?? null}
                      selectAsset={() => setSelectAsset(instance)}
                      selectionChanged={() => {
                        if (allSelected) {
                          setAllSelected(null);
                        }
                      }}
                    />) : Object.keys(allInstances ?? []).map((floor, key) => (
                      <View key={key}>{renderFloor(floor)}</View>
                    ))} */
              }
              {selectAsset && (
                <View
                  style={{
                    position: 'absolute',
                    backgroundColor: '#fff',
                    flex: 1,
                    width: '100%',
                    top: 62,
                    bottom: 0,
                    alignSelf: 'stretch',
                  }}
                >
                  <AssetCardMobile
                    instance={selectAsset}
                    mobile={true}
                    name={name}
                    refresh={refresh}
                    deleteAsset={deleteAsset}
                    updateAsset={updateAsset}
                  />
                </View>
              )}
            </View>
            {showParentAnalytics && (
              <View
                style={{
                  marginTop: 32,
                  borderRadius: 10,
                  width: 338,
                  height: '100%',
                  backgroundColor: Colors.light.hereworksPaleBlue700,
                  opacity: 0,
                }}
              >
                <Text style={styles.parentTitle}>Parent analytics</Text>
                <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>ID NUMBER</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>42956381</Text>
                  </View>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>CREATED ON</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>June 16, 2022</Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>CREATED BY</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>Michael Bell</Text>
                  </View>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>TOTAL REPORTS</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>3139</Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>TOTAL DESKS</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>600</Text>
                  </View>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>TOTAL FLOORS</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>3</Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>INSTANCES</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>600</Text>
                  </View>
                  <View style={styles.parentTile}>
                    <Text style={styles.parentTileTitle}>TOTAL ROOMS</Text>
                    <Text style={[{ marginLeft: 14 }, styles.rowText]}>37</Text>
                  </View>
                </View>
              </View>
            )}
            <View style={[styles.bottomButtons, selectAsset && { justifyContent: 'space-between' }]}>
              <Pressable
                style={({ pressed }) => [
                  styles.backButton,
                  pressed ? { backgroundColor: Colors.light.hereworksWhite300 } : {},
                ]}
                onPress={() => {
                  if (selectAsset === null) {
                    setAssetDrawerOpen(true);
                  } else {
                    setSelectAsset(null);
                    if (assetScanValue !== null) {
                      setAssetScanValue(null);
                    }
                  }
                }}
              >
                <LeftArrow16 />
                <Text
                  style={{
                    color: Colors.light.hereworksBlue500,
                    fontFamily: 'Roboto_400Regular',
                    fontSize: 12,
                    lineHeight: 22,
                    marginLeft: 8,
                  }}
                >
                  {selectAsset === null ? 'Menu' : 'Back to assets'}
                </Text>
              </Pressable>
              {selectAsset === null ? (
                <TouchableOpacity
                  style={styles.createButton}
                  onPress={() => {
                    analytics('Create new asset');
                    setAddAssetModalVisible(!addAssetModalVisible);
                  }}
                >
                  <PlusIcon />
                  <Text style={styles.createButtonTitle}>Add Asset</Text>
                </TouchableOpacity>
              )
                : <TouchableOpacity
                  style={styles.detailedFilterButton}
                  onPress={() => setReportIssueModalVisible(true)}
                >
                  <ReportIssueIcon />
                </TouchableOpacity>}
            </View>
          </View>
        </View>
      )}
      <AlertModal modalVisible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        error={alertError}
        setModalVisible={setAlertVisible} />
      {selectAsset && <ReportIssueModal
        isVisible={reportIssueModalVisible}
        hide={() => setReportIssueModalVisible(false)}
        name={selectAsset.Name}
        template={templates?.filter((prototype: { id: string }) => selectAsset
          ?.ButtonPrototypeId === prototype.id)[0]}
        id={selectAsset.ButtonInstanceId}
      />}
    </View>
  );
}

const styles = StyleSheet.create({
  sectionTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    marginLeft: 24,
    marginRight: 50,
    marginVertical: 16,
  },
  selectedTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 18,
    lineHeight: 27,
    color: Colors.light.hereworksBlack600,
  },
  headingSubTitle: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 11,
    lineHeight: 13,
    marginVertical: 4,
  },
  headingValue: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.light.hereworksBlack600,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.light.hereworksWhite500,
    width: '100%',
    marginTop: 0,
  },
  createButton: {
    borderRadius: 4,
    backgroundColor: Colors.light.hereworksBlue500,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    marginLeft: 8,
  },
  usePresetButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksDarkGreen,
    flex: 1,
    marginRight: 15,
    marginLeft: 125,
    alignItems: 'center',
  },
  customizeButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksExtraDarkTeal700,
    flex: 1,
    marginLeft: 15,
    marginRight: 125,
    alignItems: 'center',
  },
  linkTagButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksDarkGreen,
    flex: 1,
    marginLeft: 15,
    marginRight: 125,
    alignItems: 'center',
  },
  createButtonTitle: {
    color: '#fff',
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    marginLeft: 8,
    paddingVertical: 5,
  },
  usePresetButtonTitle: {
    color: '#fff',
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    marginHorizontal: 33,
    marginVertical: 12,
  },
  searchBoxRow: {
    paddingHorizontal: 24,
    marginTop: 16,
    flexDirection: 'row',
  },
  searchBox: {
    marginEnd: 16,
    flex: 1,
    height: 36,
    borderRadius: 24,
    backgroundColor: '#fff',
    borderColor: Colors.light.hereworksPaleBlue900,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchText: {
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 9,
    fontFamily: 'Roboto_400Regular',
    flex: 1,
    marginRight: 8,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: '#00000050',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalView: {
    backgroundColor: '#fff',
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: 600,
    height: 600,
  },
  createHeader: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 30,
    marginHorizontal: 176,
    marginVertical: 60,
  },
  successHeader: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 30,
    marginHorizontal: 176,
    marginBottom: 20,
    marginTop: 50,
  },
  createCard: {
    flex: 1,
    backgroundColor: Colors.light.hereworksPaleBlue700,
    borderRadius: 10,
    margin: 15,
    alignItems: 'center',
    width: 140,
    height: 160,
  },
  newButtonTitle: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: '#000',
    marginBottom: 17,
    marginTop: 17,
  },
  backArrow: {
    marginRight: 7,
    marginTop: 43,
  },
  descriptionText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 18,
    lineHeight: 27,
    color: Colors.light.hereworksPrimaryGrey900,
  },
  radioButton: {
    backgroundColor: Colors.light.hereworksPaleBlue800,
    height: 40,
    width: 40,
    borderRadius: 20,
    marginVertical: 15,
    marginHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    backgroundColor: Colors.light.hereworksDarkBlue,
    height: 30,
    width: 30,
    borderRadius: 15,
  },
  selectRow: {
    backgroundColor: Colors.light.hereworksPrimaryGrey200,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    width: 327,
    marginBottom: 10,
  },
  selectRowText: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 18,
    color: '#000',
  },
  selectPresetButtons: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 50,
    marginTop: 30,
  },
  tabs: {
    flexDirection: 'row',
    marginTop: 17,
  },
  tabTitle: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    color: '#000',
    marginHorizontal: 4,
    marginBottom: 4,
    lineHeight: 19,
    textAlign: 'center',
  },
  rowText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
  },
  parentTitle: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 24,
    color: '#000',
    marginLeft: 24,
    marginTop: 24,
    marginBottom: 20,
  },
  parentTile: {
    width: 140,
    height: 60,
    marginBottom: 10,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: 10,
    justifyContent: 'center',
  },
  parentTileTitle: {
    marginLeft: 14,
    color: Colors.light.hereworksPrimaryGrey900,
    marginBottom: 4,
    fontSize: 11,
  },
  headerText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    lineHeight: 15,
    letterSpacing: 0.7,
    marginRight: 8,
    color: Colors.light.hereworksBlack400,
  },
  sortContainer: {
    flex: 1,
    alignItems: 'flex-start',
    marginLeft: 30,
  },
  backButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 5,
    zIndex: 5,
    marginLeft: 24,
  },
  bottomButtons: {
    paddingEnd: 24,
    paddingTop: 24,
    paddingBottom: 40,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  detailedFilterButton: {
    alignSelf: 'center',
    backgroundColor: '#fff',
    flexDirection: 'row',
    padding: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: '#323247',
  },
  detailedFilterModal: {
    margin: 0,
  },
  detailedFilterContainer: {
    backgroundColor: '#fff',
    alignSelf: 'flex-end',
    width: 300,
    height: '100%',
    right: 0,
    borderLeftWidth: 1,
    borderColor: Colors.light.hereworksBlue100,
    padding: 24,
    paddingEnd: 0,
  },
  resetAllFiltersButton: {
    justifyContent: 'center',
    marginTop: 20,
    alignSelf: 'flex-start',
    borderBottomWidth: 1,
    borderColor: Colors.light.hereworksPrimaryGrey,
  },
  resetAllFiltersText: {
    color: Colors.light.hereworksPrimaryGrey,
    fontSize: 12,
    fontFamily: 'Roboto_500Medium',
  },
  applyFiltersButton: {
    backgroundColor: Colors.light.hereworksBlue500,
    borderRadius: 4,
    borderColor: Colors.light.hereworksBlue500,
    borderWidth: 1,
    alignSelf: 'flex-start',
    paddingVertical: 5,
    paddingHorizontal: 12,
  },
  applyFiltersText: {
    color: '#fff',
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Roboto_400Regular',
  },
  detailedFilterCancelButton: {
    backgroundColor: '#fff',
    borderColor: Colors.light.hereworksBlue500,
    borderWidth: 1,
    borderRadius: 4,
    alignSelf: 'flex-start',
    paddingVertical: 5,
    paddingHorizontal: 12,
    marginLeft: 8,
  },
  detailedFilterCancelText: {
    color: Colors.light.hereworksBlue500,
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Roboto_400Regular',
  },
  assetFilterSpinner: {
    position: 'absolute',
    top: '40%',
    alignSelf: 'center',
  },
});
