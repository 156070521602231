import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ReopenedOnIcon24 = (props: SvgProps) => (
    <Svg width={24} height={24} fill="none" {...props}>
        <Path d="M13 6.83325H11V12.8333H17V10.8333H13V6.83325Z" fill="#121B23" />
        <Path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20V22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12H20C20 7.58172 16.4183 4 12 4Z" fill="#121B23" />
        <Path d="M20.375 20.8135V22H15.4629V20.9941L17.7578 18.5625C17.9727 18.3151 18.1436 18.097 18.2705 17.9082C18.4007 17.7194 18.4951 17.5485 18.5537 17.3955C18.6123 17.2393 18.6416 17.096 18.6416 16.9658C18.6416 16.7542 18.6074 16.5752 18.5391 16.4287C18.474 16.2822 18.3779 16.1699 18.251 16.0918C18.124 16.0137 17.9694 15.9746 17.7871 15.9746C17.5918 15.9746 17.4242 16.0251 17.2842 16.126C17.1442 16.2269 17.0368 16.3669 16.9619 16.5459C16.887 16.7217 16.8496 16.9219 16.8496 17.1465H15.3164C15.3164 16.7135 15.4206 16.3197 15.6289 15.9648C15.8372 15.6068 16.1286 15.3219 16.5029 15.1104C16.8773 14.8955 17.3151 14.7881 17.8164 14.7881C18.3307 14.7881 18.762 14.8695 19.1104 15.0322C19.4587 15.195 19.7223 15.4277 19.9014 15.7305C20.0804 16.0332 20.1699 16.3978 20.1699 16.8242C20.1699 17.0651 20.1309 17.2979 20.0527 17.5225C19.9779 17.7471 19.8688 17.9701 19.7256 18.1914C19.5824 18.4128 19.4098 18.639 19.208 18.8701C19.0062 19.098 18.7783 19.3389 18.5244 19.5928L17.4502 20.8135H20.375Z" fill="#121B23" />
    </Svg>
);

export default ReopenedOnIcon24;
