import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const MenuIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M3.45 6h.1M3.45 12h.1M3.45 18h.1M9 6h12M9 12h12M9 18h12"
      stroke="#003A70"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default MenuIcon;
