import * as React from 'react';
import Svg, {
  SvgProps, Path, G, Defs, Rect, ClipPath,
} from 'react-native-svg';

const CloseIconSmall = (props: SvgProps) => <Svg width='12' height='14' viewBox='0 0 12 14' fill='none' {...props}>
  <G clip-path='url(#clip0_13472_38825)'>
    <Path fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9.71231 3.84719C10.0052 3.5543 10.0052 3.07942 9.71231 2.78653C9.41942 2.49364 8.94454 2.49364 8.65165 2.78653L6 5.43818L3.34835 2.78653C3.05546 2.49364 2.58058 2.49364 2.28769 2.78653C1.9948 3.07942 1.9948 3.5543 2.28769 3.84719L4.93934 6.49884L2.28769 9.15049C1.9948 9.44338 1.9948 9.91826 2.28769 10.2112C2.58058 10.504 3.05546 10.504 3.34835 10.2112L6 7.5595L8.65165 10.2112C8.94454 10.504 9.41942 10.504 9.71231 10.2112C10.0052 9.91826 10.0052 9.44338 9.71231 9.15049L7.06066 6.49884L9.71231 3.84719Z'
      fill='#8AA4BD'
    />
  </G>
  <Defs>
    <ClipPath id='clip0_13472_38825'>
      <Rect width='12' height='12' fill='white' transform='translate(0 0.5)' />
    </ClipPath>
  </Defs>
</Svg>;

export default CloseIconSmall;
