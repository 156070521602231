import React, { useState } from 'react';
import * as Analytics from 'expo-firebase-analytics';
import {
  Image, StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import EllipsisIcon from '../EllipsisIcon';
import Colors from '../../constants/Colors';
import EditUser from './EditUser';
import DeleteModal from '../DeleteModal';

export default function UserRowMobile(props) {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);

  const generateColor = (length) => {
    const randomColor = Math.floor((1 / length) * 16777215)
      .toString(16)
      .padStart(6, '0');
    return `#${randomColor}`;
  };

  const renderUserIcon = (imageSource, facilityManager = false) => {
    if (imageSource === undefined) {
      return (
        <View
          style={{
            width: 48,
            height: 48,
            borderRadius: 24,
            marginRight: 8,
            backgroundColor: generateColor(props.user.FullName.length),
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              color: '#fff',
              fontFamily: 'Roboto_400Regular',
              fontSize: 14,
            }}
          >
            {props.user.FullName.split(' ').map((word, index) => {
              if (index > 1) {
                return '';
              }
              return word.charAt(0).toUpperCase();
            })}
          </Text>
        </View>
      );
    }
    return (
      <View
        style={{
          width: 48,
          height: 48,
          backgroundColor: facilityManager ? '#fff' : 'transparent',
          borderWidth: 1.5,
          borderColor: facilityManager ? Colors.light.hereworksBlue500 : 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 24,
          marginLeft: -14,
        }}
      >
        <Image
          style={styles.userPic}
          source={{
            uri: imageSource,
          }}
        />
      </View>
    );
  };

  return (
    <View style={{ flex: 1, marginTop: 12 }}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ marginLeft: 14 }}>
          {renderUserIcon(props.user.icon, props.user.facilityManager)}
        </View>
        <Text style={styles.nameStyle}>{`${props.user.GivenName} ${props.user.FamilyName}`}</Text>
      </View>
      <Text style={[styles.headerText, { marginTop: 16, marginBottom: 4 }]}>CONTACT:</Text>
      <Text
        style={[
          styles.userDetails,
          {
            marginBottom: 4,
          },
        ]}
      >
        {props.user.Email}
      </Text>
      <TouchableOpacity
        hitSlop={{
          top: 25,
          bottom: 25,
          left: 15,
          right: 15,
        }}
        style={{ position: 'absolute', top: 8, right: 0 }}
        onPress={() => {
          setContextMenuOpen(!contextMenuOpen);
          Analytics.logEvent('button_press', {
            screen: 'UserMgmt',
            name: `Context Menu ${contextMenuOpen ? 'Close' : 'Open'}`,
          });
        }}
      >
        <EllipsisIcon fill={Colors.light.hereworksBlue400} />
        {contextMenuOpen && (
          <View
            style={{
              position: 'absolute',
              top: 20,
              right: 0,
              width: 87,
              borderWidth: 1.5,
              borderColor: Colors.light.hereworksBlue100,
              borderRadius: 8,
              backgroundColor: '#fff',
            }}
          >
            <TouchableOpacity
              style={styles.contextItem}
              onPress={() => Analytics.logEvent('button_press', {
                screen: 'UserMgmt',
                name: 'Message',
              })
              }
            >
              <Text style={styles.contextItemText}>Message</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.contextItem}
              onPress={() => {
                setEditModalVisible(true);
                setContextMenuOpen(false);
                Analytics.logEvent('button_press', {
                  screen: 'UserMgmt',
                  name: 'Edit',
                });
              }}
            >
              <Text style={styles.contextItemText}>Edit</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.contextItem}
              onPress={() => {
                setDeleteUserModalVisible(true);
                setEditModalVisible(false);
                Analytics.logEvent('button_press', {
                  screen: 'UserMgmt',
                  name: 'Delete',
                });
              }}
            >
              <Text style={styles.contextItemText}>Delete</Text>
            </TouchableOpacity>
          </View>
        )}
      </TouchableOpacity>
      <EditUser
        editModalVisible={editModalVisible}
        user={props.user}
        imageSource={props.user.icon}
        facilityManager={props.user.facilityManager}
        setEditModalVisible={setEditModalVisible}
        mobile={true}
      />
      <DeleteModal
        modalVisible={deleteUserModalVisible}
        setModalVisible={setDeleteUserModalVisible}
        name={props.user.FullName}
        groupName={props.groupName}
        deleteAction={() => props.deleteAction()}
        mobile={true}
        title={'Delete the member'}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  userPic: {
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: 'hidden',
  },
  headerText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 11,
    lineHeight: 16.5,
    letterSpacing: 0.7,
    color: Colors.light.hereworksBlack400,
  },
  nameStyle: {
    marginLeft: 10,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksBlack600,
  },
  userDetails: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
  },
  contextItem: {
    marginVertical: 9,
    backgroundColor: '#fff',
  },
  contextItemText: {
    marginLeft: 12,
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    lineHeight: 21,
  },
});
