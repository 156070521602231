import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ResolvedIcon = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Path
      fill="#fff"
      fillRule="evenodd"
      d="M29.427 6.598a2 2 0 0 1-.025 2.829l-16.286 16a2 2 0 0 1-2.803 0l-7.715-7.58a2 2 0 1 1 2.804-2.853l6.312 6.202L26.598 6.573a2 2 0 0 1 2.829.025Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default ResolvedIcon;
