import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const HomeIconDesktop = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M12 2 3 9v11a2 2 0 0 0 2 2h4v-9.143h6V22h4a2 2 0 0 0 2-2V9l-9-7Z"
      stroke={props.color ?? '#3379BD'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default HomeIconDesktop;
