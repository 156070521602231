import React from 'react';
import {
  View, Text, StyleSheet, Platform, Dimensions,
} from 'react-native';
import Colors from '../../constants/Colors';
import TickIcon from '../TickIcon';

interface ShareTicketPopupProps {
  visible: boolean,
}

const ShareTicketPopup = ({ visible }: ShareTicketPopupProps) => (visible
  ? <View style={styles.container}>
    <View style={styles.circle}>
      <TickIcon color={'#FFF'} />
    </View>
    <Text style={styles.title}>Link is copied</Text>
  </View> : null);

const isWeb: boolean = Platform.OS === 'web' && Dimensions.get('window').width > 500;

const styles = StyleSheet.create({
  container: {
    width: isWeb ? 400 : '100%',
    flex: 1,
    height: 56,
    position: 'absolute',
    end: 0,
    backgroundColor: '#FFF',
    borderColor: Colors.light.hereworksGreen400,
    borderRadius: 12,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    margin: isWeb ? undefined : 24,
    top: isWeb ? undefined : -20,
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: Colors.light.hereworksGreen500,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksGreen,
    marginStart: 16,
  },
});

export default ShareTicketPopup;
