import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  View, StyleSheet, Text, TouchableOpacity, TextInput, ScrollView, Platform,
} from 'react-native';
import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';
import DeleteIcon24 from '../../24pxIcons/DeleteIcon24';
import Button from '../../Button';
import PlusIcon from '../../16pxIcons/PlusIcon';
import DownArrow24 from '../../24pxIcons/DownArrow24';

export type FieldType = {
  name: string,
  Format: string,
}

export type Field = {
  Name: string,
  Format: string,
  isMandatory: boolean,
};

interface FieldsListProps {
  fields: Field[],
  setFields: Dispatch<SetStateAction<Field[]>>,
  refs,
}

interface FieldItemProps extends Field {
  index: number,
}

const TYPES: FieldType[] = [
  { name: 'Plain text', Format: '^.*$' },
  { name: 'IP Address', Format: '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$' },
  { name: 'MAC Address', Format: '^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$' },
  { name: 'Number', Format: '^\\d*' },
  { name: 'Date', Format: '^\\d\\d\\d\\d-\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d.\\d\\d\\d\\d\\d\\d$' },
];

const FieldsList = ({ fields, setFields, refs }: FieldsListProps) => {
  const [typesShownIndex, setTypesShownIndex] = useState<number>(-1);

  const FieldItem = ({ Name, Format, index }: FieldItemProps) => {
    const [fieldName, setFieldName] = useState<string>(Name);

    const updateValue = () => {
      const updatedFields: Field[] = [...fields];
      fields[index].Name = fieldName;
      setFields(updatedFields);
    };

    return <>
      <View style={Style.row}>
        <Text style={styles.fieldLabel}>{`Additional field ${index + 1}`}</Text>
        <TouchableOpacity onPress={() => {
          const updatedFields: Field[] = [...fields];
          updatedFields.splice(index, 1);
          setFields(updatedFields);
        }}>
          <DeleteIcon24 />
        </TouchableOpacity>
      </View>
      <View style={[Style.row, styles.mainSection]}>
        <TextInput
          value={fieldName}
          ref={(ref) => { refs[index] = ref; }}
          onChangeText={setFieldName}
          onEndEditing={updateValue}
          onBlur={updateValue}
          onSubmitEditing={updateValue}
          style={[
            styles.fieldName, styles.text, styles.border,
            !fieldName?.length && { borderColor: styles.errorText.color }]}
        />
        <View style={styles.dropdownSection}>
          <TouchableOpacity
            onPress={() => setTypesShownIndex(typesShownIndex === index ? -1 : index)}
            style={[Style.row, styles.typeIndicator, styles.border]}
          >
            <Text style={[styles.text, { flex: 1 }]}>
              {(TYPES.filter((type: FieldType) => type.Format === Format)[0] ?? TYPES[0]).name}
            </Text>
            <DownArrow24
              color={Colors.light.hereworksBlue200}
              style={typesShownIndex === index && styles.inverted}
            />
          </TouchableOpacity>
          {typesShownIndex === index && <ScrollView style={[styles.typeDropdown, styles.border]}>
            {TYPES.map((type: FieldType) => <TouchableOpacity
              key={`field_type_${type.name}`}
              onPress={() => {
                const updatedFields: Field[] = [...fields];
                fields[index].Format = type.Format;
                setFields(updatedFields);
                setTypesShownIndex(-1);
              }}
            >
              <Text style={[styles.text, type.Format === Format && styles.selected]}>
                {type.name}
              </Text>
            </TouchableOpacity>)}
          </ScrollView>}
        </View>
      </View>
      {!fieldName?.length && <Text style={styles.errorText}>Field name cannot be empty</Text>}
    </>;
  };

  return <View style={styles.container}>
    {fields?.length ? fields.map((field: Field, index: number) => <FieldItem
      key={`field_${field.Name}`}
      {...field}
      {...{ index }}
    />) : <View style={styles.noFieldsTextContainer}>
      <Text style={styles.noFieldsText}>No additional fields</Text>
    </View>}
    <Button
      title='Add field  '
      affirmative={false}
      onPress={() => {
        const updatedFields: Field[] = [...fields];
        updatedFields.push({ Name: 'New additional field', Format: TYPES[0].Format, isMandatory: true });
        setFields(updatedFields);
      }}
      style={styles.button}
    >
      <PlusIcon color={Colors.light.hereworksBlue500} />
    </Button>
  </View>;
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  noFieldsTextContainer: {
    padding: 16,
    backgroundColor: Colors.light.hereworksWhite100,
    borderRadius: 10,
    marginBottom: 16,
    alignSelf: 'stretch',
  },
  noFieldsText: {
    fontSize: 18,
    lineHeight: 27,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksBlack400,
  },
  fieldLabel: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
  },
  mainSection: {
    marginTop: 4,
    marginBottom: 16,
    alignItems: 'flex-start',
  },
  text: {
    paddingVertical: 9,
    paddingHorizontal: 12,
    fontSize: 14,
    lineHeight: Platform.OS === 'ios' ? undefined : 21,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
  },
  border: {
    borderWidth: 1.5,
    borderRadius: 10,
    borderColor: Colors.light.hereworksBlue100,
  },
  fieldName: {
    flex: 1,
    marginEnd: 8,
    paddingTop: 9,
    height: 40,
  },
  button: {
    alignSelf: 'flex-start',
  },
  errorText: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksRed500,
    marginTop: -12,
    marginBottom: 12,
  },
  dropdownSection: {
    flex: 0.7,
    minWidth: 40,
  },
  typeIndicator: {
    paddingEnd: 12,
    minHeight: 40,
    maxHeight: 80,
  },
  typeDropdown: {
    alignSelf: 'stretch',
    maxHeight: 180,
    marginTop: 8,
  },
  inverted: {
    transform: [{ rotate: '180deg' }],
  },
  selected: {
    backgroundColor: Colors.light.hereworksDarkBlue,
    color: '#FFF',
  },
});

export default FieldsList;
