import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ProceedIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 8 5 4.247v7.506L11.5 8Zm1.5.866a1 1 0 0 0 0-1.732L5 2.515a1 1 0 0 0-1.5.866v9.238a1 1 0 0 0 1.5.866l8-4.619Z"
      fill="#fff"
    />
  </Svg>
);

export default ProceedIcon;
