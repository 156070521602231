import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CloseModalIcon = (props: SvgProps) => (
  <Svg
    width={24}
    height={23}
    fill="none"
    {...props}
  >
    <Path
      d="M6.186 5.814 17.5 17.127M6.186 16.814 17.5 5.5"
      stroke="#003A70"
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Svg>
);

export default CloseModalIcon;
