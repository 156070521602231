import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ResolvedIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path d="M2 8.21 5.857 12 14 4" stroke={props.color ?? '#337F3A'} strokeWidth={1.5} />
  </Svg>
);

export default ResolvedIcon;
