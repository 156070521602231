import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const AlertIconMobile = (props: SvgProps) => (
    <Svg width="4" height="17" viewBox="0 0 4 17" fill="none" {...props}>
        <Path fill-rule="evenodd" clip-rule="evenodd" d="M1.9987 0.0820312C2.68905 0.0820312 3.2487 0.641675 3.2487 1.33203V9.66536C3.2487 10.3557 2.68905 10.9154 1.9987 10.9154C1.30834 10.9154 0.748698 10.3557 0.748698 9.66536V1.33203C0.748698 0.641675 1.30834 0.0820312 1.9987 0.0820312Z" fill="white" />
        <Path d="M3.66537 14.6654C3.66537 15.5858 2.91917 16.332 1.9987 16.332C1.07822 16.332 0.332031 15.5858 0.332031 14.6654C0.332031 13.7449 1.07822 12.9987 1.9987 12.9987C2.91917 12.9987 3.66537 13.7449 3.66537 14.6654Z" fill="white" />
    </Svg>
);

export default AlertIconMobile;
