import * as React from 'react';
import Svg, {
  SvgProps, G, Path, Defs, LinearGradient, Stop,
} from 'react-native-svg';

const ScoreCardBuilding = (props: SvgProps) => (
  <Svg width={157} height={67} fill="none" {...props}>
    <G opacity={0.5}>
      <G>
        <Path
          d="M88.594 65.053H47.192c0-8.395 4.044-6.634 4.137-3.557.166-.92.923-1.19 1.383-1.1.308.06.554.753.492 1.858.4-1.017 1.487-2.482 2.63-2.633 3.029-.4 4.136 1.91 4.257 3.543a1.418 1.418 0 0 1 1.61-.017c.264-.958 1.08-1.656 2.046-1.656 1.085 0 1.98.88 2.117 2.019.289-.303.738-.499 1.244-.499.719 0 1.325.395 1.514.934.255-.081.564-.13.897-.13a3.4 3.4 0 0 1 .376.021 1.394 1.394 0 0 1-.072-.443c0-.713.531-1.29 1.186-1.29.517 0 .955.358 1.119.86.114-.22.286-.361.478-.361.258 0 .479.25.574.609.154-.085.327-.133.51-.133.622 0 1.127.548 1.127 1.224v.009c.583-.56 1.46-.918 2.44-.918a3.8 3.8 0 0 1 1.441.279c-.088-.661.108-1.272.556-1.554.447-.281 1.028-.162 1.509.248.63-1.512 2.029-2.565 3.655-2.565 1.16 0 4.176 1.226 4.176 5.252Z"
          fill="#18853C"
        />
        <Path d="M86.916 33.723H58.914v1.063h28.002v-1.063Z" fill="#DFE4E8" />
        <Path d="M86.916 34.719H58.914v30.324h28.002V34.72Z" fill="#F0F5F9" />
        <Path
          d="M70.23 64.088H58.913v.955H70.23v-.955ZM86.914 64.088h-4.336v.955h4.336v-.955Z"
          fill="#DFE4E8"
        />
        <Path d="M58.913 34.718H48.871v30.325h10.042V34.718Z" fill="#DADFE3" />
        <Path d="M58.913 64.088H48.871v.955h10.042v-.955Z" fill="#B8C0C9" />
        <Path d="M82.577 28.5h-8.323v36.543h8.323V28.5Z" fill="#02629E" />
        <Path
          d="M82.577 28.551h-2.214v36.492h2.214V28.551ZM78.415 28.551H77.31v36.492h1.106V28.551Z"
          fill="#0270AB"
        />
        <Path d="M82.577 28.345h-8.323v.92h8.323v-.92Z" fill="#00579C" />
        <Path
          d="M82.577 32.63h-8.323v.505h8.323v-.505ZM82.577 41.52h-8.323v.504h8.323v-.504ZM82.577 50.41h-8.323v.504h8.323v-.505ZM82.577 59.299h-8.323v.504h8.323V59.3Z"
          fill="#004679"
        />
        <Path
          opacity={0.1}
          d="m74.254 48.509 8.33 8.341v-3.107l-8.33-8.341v3.107Z"
          fill="#F0F5F9"
        />
        <Path d="M74.254 28.702h-4.027v36.341h4.027V28.702Z" fill="#04486B" />
        <Path d="M74.254 28.345h-4.027v.92h4.027v-.92Z" fill="#00355E" />
        <Path
          d="M74.254 32.63h-4.027v.505h4.027v-.505ZM74.254 41.52h-4.027v.504h4.027v-.504ZM74.254 50.41h-4.027v.504h4.027v-.505ZM74.254 59.299h-4.027v.504h4.027V59.3Z"
          fill="#05568D"
        />
        <Path
          opacity={0.1}
          d="M74.258 45.402v3.107l-4.028-5.878v-3.108l4.028 5.879Z"
          fill="#F0F5F9"
        />
        <Path d="M58.913 33.723H48.871v.996h10.042v-.996Z" fill="#B8C0C9" />
        <Path d="M68.822 58.945h-8.51v5.143h8.51v-5.143Z" fill="#02629E" />
        <Path
          opacity={0.1}
          d="M65.729 64.088h3.089l-2.15-5.143h-3.09l2.15 5.143ZM62.467 64.088h1.029l-2.151-5.143h-1.029l2.15 5.143Z"
          fill="#F0F5F9"
        />
        <Path d="M68.822 59.867h-8.51v.248h8.51v-.248Z" fill="#004679" />
        <Path
          d="M66.057 58.945h.248v5.143h-.248v-5.143ZM62.834 58.945h.248v5.143h-.248v-5.143Z"
          fill="#004679"
        />
        <Path
          d="M68.825 36.52h-3.52v.817h3.52v-.817ZM68.825 38.666h-3.52v.816h3.52v-.816ZM68.825 40.81h-3.52v.817h3.52v-.817ZM68.825 42.956h-3.52v.816h3.52v-.816ZM68.825 45.1h-3.52v.817h3.52V45.1ZM68.825 47.246h-3.52v.816h3.52v-.816ZM68.825 49.39h-3.52v.817h3.52v-.816ZM68.825 51.536h-3.52v.816h3.52v-.816ZM68.825 53.68h-3.52v.817h3.52v-.816ZM68.825 55.826h-3.52v.816h3.52v-.816ZM63.833 36.52h-3.52v.817h3.52v-.817ZM63.833 38.666h-3.52v.816h3.52v-.816ZM63.833 40.811h-3.52v.816h3.52v-.816ZM63.833 42.956h-3.52v.816h3.52v-.816ZM63.833 45.1h-3.52v.817h3.52V45.1ZM63.833 47.246h-3.52v.816h3.52v-.816ZM63.833 49.39h-3.52v.817h3.52v-.816ZM63.833 51.536h-3.52v.816h3.52v-.816ZM63.833 53.68h-3.52v.817h3.52v-.816ZM63.833 55.826h-3.52v.816h3.52v-.816ZM86.509 36.521h-3.52v.816h3.52v-.816ZM86.509 38.666h-3.52v.816h3.52v-.816ZM86.509 40.812h-3.52v.816h3.52v-.816ZM86.509 42.956h-3.52v.816h3.52v-.816ZM86.509 45.1h-3.52v.817h3.52V45.1ZM86.509 47.246h-3.52v.816h3.52v-.816ZM86.509 49.39h-3.52v.817h3.52v-.816ZM86.509 51.536h-3.52v.816h3.52v-.816ZM86.509 53.68h-3.52v.817h3.52v-.816ZM86.509 55.826h-3.52v.816h3.52v-.816Z"
          fill="#0773A4"
        />
        <Path d="M86.509 58.945h-3.52v5.143h3.52v-5.143Z" fill="#02629E" />
        <Path d="M86.506 58.945h-.834v5.143h.834v-5.143Z" fill="#0270AB" />
        <Path d="M57.362 36.127h-1.51V62.63h1.51V36.127Z" fill="#04486B" />
        <Path
          d="M57.362 38.138h-1.51v.571h1.51v-.571ZM57.362 43.633h-1.51v.571h1.51v-.571ZM57.362 49.128h-1.51v.572h1.51v-.572ZM57.362 54.623h-1.51v.572h1.51v-.572ZM57.362 60.119h-1.51v.571h1.51v-.571Z"
          fill="#05568D"
        />
        <Path d="M54.624 36.127h-1.51V62.63h1.51V36.127Z" fill="#04486B" />
        <Path
          d="M54.624 38.138h-1.51v.571h1.51v-.571ZM54.624 43.632h-1.51v.572h1.51v-.572ZM54.624 49.129h-1.51v.571h1.51v-.571ZM54.624 54.623h-1.51v.572h1.51v-.572ZM54.624 60.119h-1.51v.571h1.51v-.571Z"
          fill="#05568D"
        />
        <Path d="M51.925 36.127h-1.51V62.63h1.51V36.127Z" fill="#04486B" />
        <Path
          d="M51.925 38.138h-1.51v.571h1.51v-.571ZM51.925 43.633h-1.51v.571h1.51v-.571ZM51.925 49.128h-1.51v.572h1.51v-.572ZM51.925 54.623h-1.51v.572h1.51v-.572ZM51.925 60.119h-1.51v.571h1.51v-.571Z"
          fill="#05568D"
        />
        <Path d="M57.834 62.668h-.389v2.385h.389v-2.385Z" fill="#6E331B" />
        <Path
          d="M56.898 59.977c0 1.906.332 3.45.742 3.45s.742-1.544.742-3.45c0-1.905-.332-3.45-.742-3.45s-.742 1.545-.742 3.45Z"
          fill="#18853C"
        />
        <Path d="M85.482 62.668h-.388v2.386h.388v-2.386Z" fill="#6E331B" />
        <Path
          d="M84.547 59.977c0 1.906.332 3.45.741 3.45.41 0 .742-1.544.742-3.45 0-1.905-.332-3.45-.742-3.45s-.741 1.545-.741 3.45Z"
          fill="#18853C"
        />
        <Path d="M54.396 62.668h-.388v2.386h.388v-2.386Z" fill="#6E331B" />
        <Path
          d="M53.457 59.977c0 1.906.332 3.45.742 3.45s.741-1.544.741-3.45c0-1.905-.332-3.45-.741-3.45-.41 0-.742 1.545-.742 3.45Z"
          fill="#18853C"
        />
        <Path d="M50.959 62.668h-.389v2.386h.389v-2.386Z" fill="#6E331B" />
        <Path
          d="M50.023 59.977c0 1.906.332 3.45.742 3.45s.742-1.544.742-3.45c0-1.905-.333-3.45-.742-3.45-.41 0-.742 1.545-.742 3.45Z"
          fill="#18853C"
        />
        <Path
          d="M46.845 66.361h42.094a.655.655 0 1 0 0-1.31H46.845a.655.655 0 0 0 0 1.31ZM52.266 63.86c.658 0 1.191.533 1.191 1.19h-2.382c0-.657.533-1.19 1.191-1.19Z"
          fill="#1C9A46"
        />
        <Path
          d="M50.656 64.383c.37 0 .668.299.668.668h-1.336c0-.37.3-.668.668-.668ZM49.373 64.717c.184 0 .334.15.334.334h-.668c0-.185.15-.334.334-.334ZM48.249 64.428c.343 0 .622.279.622.622h-1.244c0-.343.278-.622.622-.622ZM86.163 64.26c.395 0 .716.354.716.791h-1.431c0-.437.32-.792.716-.792Z"
          fill="#1C9A46"
        />
        <Path
          d="M85.288 64.577c.262 0 .474.212.474.474h-.947c0-.262.212-.474.473-.474Z"
          fill="#1C9A46"
        />
        <Path
          d="M84.31 64.17c.338 0 .612.394.612.881h-1.225c0-.487.274-.881.612-.881ZM70.096 64.08c.545 0 .986.442.986.986H69.11c0-.544.442-.986.986-.986Z"
          fill="#1C9A46"
        />
        <Path
          d="M68.726 64.573c.272 0 .493.221.493.493h-.986c0-.272.22-.493.493-.493Z"
          fill="#1C9A46"
        />
      </G>
    </G>
    <G>
      <Path
        d="M86.703 64.156h71.686c0-14.537-7.003-11.487-7.162-6.16-.288-1.591-1.599-2.058-2.396-1.904-.534.103-.959 1.304-.853 3.217-.692-1.76-2.574-4.297-4.552-4.558-5.245-.692-7.162 3.306-7.372 6.135a2.456 2.456 0 0 0-1.415-.453c-.503 0-.974.156-1.373.424-.456-1.66-1.869-2.868-3.542-2.868-1.878 0-3.427 1.523-3.665 3.495-.5-.525-1.278-.863-2.154-.863-1.245 0-2.295.684-2.622 1.617a5.14 5.14 0 0 0-1.553-.225c-.224 0-.442.013-.65.036.08-.24.124-.497.124-.767 0-1.234-.92-2.235-2.054-2.235-.894 0-1.654.622-1.937 1.489-.197-.381-.495-.624-.828-.624-.446 0-.829.434-.993 1.054a1.817 1.817 0 0 0-.885-.23c-1.076 0-1.949.948-1.949 2.12l.001.015c-1.011-.97-2.528-1.589-4.226-1.589-.899 0-1.747.175-2.496.483.153-1.145-.186-2.203-.962-2.692-.774-.486-1.78-.28-2.613.43-1.09-2.618-3.513-4.44-6.328-4.44-2.008 0-7.23 2.122-7.23 9.093Z"
        fill="#18853C"
      />
      <Path d="M89.613 9.91h48.485v1.84H89.613V9.91Z" fill="#DFE4E8" />
      <Path d="M89.613 11.633h48.485v52.506H89.613V11.633Z" fill="#F0F5F9" />
      <Path
        d="M118.506 62.484h19.592v1.655h-19.592v-1.655ZM89.613 62.485h7.508v1.654h-7.508v-1.654Z"
        fill="#DFE4E8"
      />
      <Path d="M138.097 11.633h17.387v52.506h-17.387V11.633Z" fill="#DADFE3" />
      <Path d="M138.097 62.485h17.387v1.654h-17.387v-1.654Z" fill="#B8C0C9" />
      <Path d="M97.12.865h14.411v63.274h-14.41V.865Z" fill="#02629E" />
      <Path
        d="M97.12.954h3.833V64.14H97.12V.954ZM104.326.954h1.916V64.14h-1.916V.954Z"
        fill="#0270AB"
      />
      <Path d="M97.12.597h14.411v1.595h-14.41V.597Z" fill="#00579C" />
      <Path
        d="M97.12 8.017h14.411v.873h-14.41v-.873ZM97.12 23.409h14.411v.874h-14.41v-.874ZM97.12 38.8h14.411v.874h-14.41v-.873ZM97.12 54.193h14.411v.874h-14.41v-.874Z"
        fill="#004679"
      />
      <Path
        opacity={0.1}
        d="M111.53 35.51 97.106 49.954l.001-5.38 14.424-14.442-.001 5.38Z"
        fill="#F0F5F9"
      />
      <Path d="M111.534 1.215h6.974V64.14h-6.974V1.215Z" fill="#04486B" />
      <Path d="M111.534.597h6.974v1.595h-6.974V.597Z" fill="#00355E" />
      <Path
        d="M111.534 8.017h6.974v.873h-6.974v-.873ZM111.534 23.41h6.974v.873h-6.974v-.874ZM111.534 38.8h6.974v.874h-6.974v-.873ZM111.534 54.193h6.974v.874h-6.974v-.874Z"
        fill="#05568D"
      />
      <Path
        opacity={0.1}
        d="M111.531 30.13v5.38l6.975-10.177v-5.38l-6.975 10.177Z"
        fill="#F0F5F9"
      />
      <Path d="M138.097 9.91h17.387v1.724h-17.387V9.909Z" fill="#B8C0C9" />
      <Path d="M120.935 53.58h14.733v8.904h-14.733V53.58Z" fill="#02629E" />
      <Path
        opacity={0.1}
        d="M126.284 62.484h-5.349l3.724-8.904h5.349l-3.724 8.904ZM131.944 62.484h-1.781l3.724-8.904h1.781l-3.724 8.904Z"
        fill="#F0F5F9"
      />
      <Path d="M120.935 55.176h14.733v.43h-14.733v-.43Z" fill="#004679" />
      <Path
        d="M125.726 53.58h-.429v8.904h.429V53.58ZM131.304 53.58h-.429v8.904h.429V53.58Z"
        fill="#004679"
      />
      <Path
        d="M120.936 14.753h6.095v1.413h-6.095v-1.413ZM120.936 18.467h6.095v1.413h-6.095v-1.413ZM120.936 22.182h6.095v1.413h-6.095v-1.413ZM120.936 25.895h6.095v1.414h-6.095v-1.414ZM120.936 29.609h6.095v1.413h-6.095V29.61ZM120.936 33.324h6.095v1.413h-6.095v-1.413ZM120.936 37.037h6.095v1.413h-6.095v-1.413ZM120.936 40.752h6.095v1.413h-6.095v-1.413ZM120.936 44.465h6.095v1.413h-6.095v-1.413ZM120.936 48.18h6.095v1.413h-6.095V48.18ZM129.572 14.753h6.096v1.413h-6.096v-1.413ZM129.572 18.467h6.096v1.413h-6.096v-1.413ZM129.572 22.182h6.096v1.413h-6.096v-1.413ZM129.572 25.895h6.096v1.414h-6.096v-1.414ZM129.572 29.609h6.096v1.413h-6.096V29.61ZM129.572 33.324h6.096v1.413h-6.096v-1.413ZM129.572 37.037h6.096v1.413h-6.096v-1.413ZM129.572 40.752h6.096v1.413h-6.096v-1.413ZM129.572 44.465h6.096v1.413h-6.096v-1.413ZM129.572 48.18h6.096v1.413h-6.096V48.18ZM90.31 14.753h6.096v1.414H90.31v-1.414ZM90.31 18.468h6.096v1.413H90.31v-1.413ZM90.31 22.182h6.096v1.413H90.31v-1.413ZM90.31 25.895h6.096v1.414H90.31v-1.414ZM90.31 29.609h6.096v1.413H90.31V29.61ZM90.31 33.324h6.096v1.413H90.31v-1.413ZM90.31 37.037h6.096v1.413H90.31v-1.413ZM90.31 40.752h6.096v1.413H90.31v-1.413ZM90.31 44.465h6.096v1.413H90.31v-1.413ZM90.31 48.18h6.096v1.413H90.31V48.18Z"
        fill="#0773A4"
      />
      <Path d="M90.31 53.58h6.096v8.905H90.31V53.58Z" fill="#02629E" />
      <Path d="M90.313 53.58h1.445v8.905h-1.445V53.58Z" fill="#0270AB" />
      <Path d="M140.775 14.072h2.616V59.96h-2.616V14.072Z" fill="#04486B" />
      <Path
        d="M140.775 17.553h2.616v.99h-2.616v-.99ZM140.775 27.067h2.616v.99h-2.616v-.99ZM140.775 36.583h2.616v.99h-2.616v-.99ZM140.775 46.098h2.616v.99h-2.616v-.99ZM140.775 55.612h2.616v.99h-2.616v-.99Z"
        fill="#05568D"
      />
      <Path d="M145.525 14.072h2.616V59.96h-2.616V14.072Z" fill="#04486B" />
      <Path
        d="M145.525 17.553h2.616v.99h-2.616v-.99ZM145.525 27.067h2.616v.99h-2.616v-.99ZM145.525 36.583h2.616v.99h-2.616v-.99ZM145.525 46.098h2.616v.99h-2.616v-.99ZM145.525 55.612h2.616v.99h-2.616v-.99Z"
        fill="#05568D"
      />
      <Path d="M150.193 14.072h2.616V59.96h-2.616V14.072Z" fill="#04486B" />
      <Path
        d="M150.193 17.553h2.616v.99h-2.616v-.99ZM150.193 27.067h2.616v.99h-2.616v-.99ZM150.193 36.583h2.616v.99h-2.616v-.99ZM150.193 46.098h2.616v.99h-2.616v-.99ZM150.193 55.612h2.616v.99h-2.616v-.99Z"
        fill="#05568D"
      />
      <Path d="M139.96 60.025h.673v4.131h-.673v-4.13Z" fill="#6E331B" />
      <Path
        d="M141.586 55.368c0 3.299-.575 5.974-1.284 5.974-.709 0-1.284-2.675-1.284-5.974 0-3.3.575-5.974 1.284-5.974.709 0 1.284 2.674 1.284 5.974Z"
        fill="#18853C"
      />
      <Path d="M92.093 60.025h.673v4.131h-.673v-4.13Z" fill="#6E331B" />
      <Path
        d="M93.71 55.368c0 3.299-.574 5.974-1.283 5.974-.71 0-1.284-2.675-1.284-5.974 0-3.3.575-5.974 1.284-5.974.71 0 1.284 2.674 1.284 5.974Z"
        fill="#18853C"
      />
      <Path d="M145.917 60.025h.673v4.131h-.673v-4.13Z" fill="#6E331B" />
      <Path
        d="M147.539 55.368c0 3.299-.575 5.974-1.284 5.974-.709 0-1.284-2.675-1.284-5.974 0-3.3.575-5.974 1.284-5.974.709 0 1.284 2.674 1.284 5.974Z"
        fill="#18853C"
      />
      <Path d="M151.87 60.025h.673v4.131h-.673v-4.13Z" fill="#6E331B" />
      <Path
        d="M153.492 55.368c0 3.299-.574 5.974-1.283 5.974-.71 0-1.285-2.675-1.285-5.974 0-3.3.575-5.975 1.285-5.975.709 0 1.283 2.675 1.283 5.975Z"
        fill="#18853C"
      />
      <Path
        d="M158.991 66.42H86.107a1.134 1.134 0 1 1 0-2.268h72.884a1.135 1.135 0 1 1 0 2.269Z"
        fill="#1C9A46"
      />
      <Path
        d="M149.601 62.09a2.062 2.062 0 0 0-2.062 2.063h4.124a2.062 2.062 0 0 0-2.062-2.063Z"
        fill="#1C9A46"
      />
      <Path
        d="M152.395 62.995c-.639 0-1.157.518-1.157 1.158h2.314c0-.64-.518-1.158-1.157-1.158ZM154.613 63.573a.579.579 0 0 0-.578.58h1.157a.579.579 0 0 0-.579-.58ZM156.558 63.074c-.595 0-1.078.483-1.078 1.078h2.155c0-.595-.482-1.078-1.077-1.078ZM90.91 62.782c-.683 0-1.238.614-1.238 1.37h2.478c0-.756-.555-1.37-1.24-1.37Z"
        fill="#1C9A46"
      />
      <Path d="M92.43 63.333a.82.82 0 0 0-.82.82h1.639a.82.82 0 0 0-.82-.82Z" fill="#1C9A46" />
      <Path
        d="M94.123 62.626c-.586 0-1.06.683-1.06 1.526h2.121c0-.843-.475-1.526-1.06-1.526ZM118.734 62.47c-.943 0-1.707.765-1.707 1.708h3.415c0-.943-.765-1.707-1.708-1.707Z"
        fill="#1C9A46"
      />
      <Path
        d="M121.104 63.325a.854.854 0 0 0-.854.853h1.707a.854.854 0 0 0-.853-.853Z"
        fill="#1C9A46"
      />
    </G>
    <G>
      <Path d="M10 57h2v8h-2v-8Z" fill="#6E331B" />
      <Path
        d="M14 47.5c0 6.904-1.343 12.5-3 12.5s-3-5.596-3-12.5C8 40.598 9.343 35 11 35s3 5.597 3 12.5Z"
        fill="#18853C"
      />
      <Path d="M20.753 53.08h1.99V65.3h-1.99V53.08Z" fill="#6E331B" />
      <Path
        d="M25.547 39.3c0 9.76-1.7 17.673-3.799 17.673-2.098 0-3.798-7.913-3.798-17.674s1.7-17.673 3.799-17.673c2.098 0 3.798 7.912 3.798 17.673Z"
        fill="#18853C"
      />
      <Path d="M33 58h1v7h-1v-7Z" fill="#6E331B" />
      <Path
        d="M36 50.001c0 5.523-1.119 10-2.5 10-1.38 0-2.5-4.477-2.5-10s1.12-10 2.5-10c1.381 0 2.5 4.477 2.5 10Z"
        fill="#18853C"
      />
      <Path
        d="M46.477 63.575c-.945 0-1.711.766-1.711 1.712h3.422c0-.946-.767-1.712-1.712-1.712Z"
        fill="#1C9A46"
      />
    </G>
    <Defs>
      <LinearGradient
        id="a"
        x1={21.947}
        y1={0}
        x2={21.947}
        y2={38.279}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0.396} />
        <Stop offset={1} stopOpacity={0} />
      </LinearGradient>
      <LinearGradient id="c" x1={119} y1={0} x2={119} y2={66.279} gradientUnits="userSpaceOnUse">
        <Stop offset={0.396} />
        <Stop offset={1} stopOpacity={0} />
      </LinearGradient>
      <LinearGradient id="e" x1={23} y1={-2} x2={23} y2={66} gradientUnits="userSpaceOnUse">
        <Stop />
        <Stop offset={1} stopOpacity={0} />
      </LinearGradient>
    </Defs>
  </Svg>
);

export default ScoreCardBuilding;
