import React, { useState } from 'react';
import {
  View, StyleSheet, Text,
} from 'react-native';
import Moment from 'moment';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory';
import { processFontFamily } from 'expo-font';
import Colors from '../constants/Colors';

const chartTheme = {
  axis: {
    stroke: 'none',
    style: {
      stroke: '#696D70',
      tickLabels: {
        fill: '#696D70',
      },
      grid: { stroke: 'none' },
    },
  },
};

interface EmptyGraphProps {
  dates: { start: Date, end: Date },
  compareDates: { start: Date, end: Date },
  limit?: number,
  title: string,
}

const EmptyGraph = ({
  dates, compareDates, title, limit,
}: EmptyGraphProps) => {
  const [chartWidth, setChartWidth] = useState(1000);
  const xAxisTickCount = 7;
  const xAxisTickValues = (start, end) => {
    const startDateMs = Date.parse(start);
    const dateIntervalMs = (Date.parse(end) - startDateMs) / (xAxisTickCount - 1);
    return Array(7).fill(0).map(
      (_, index) => {
        const dateObj = new Date(startDateMs + index * dateIntervalMs);
        return `${Moment(dateObj).format('MMM')} ${Moment(dateObj).format('DD')}`;
      },
    );
  };

  return <View style={styles.chart} onLayout={(e) => setChartWidth(e.nativeEvent.layout.width)}>
    {title && <Text style={styles.title}>{title}</Text>}
    <VictoryChart width={chartWidth}
      theme={chartTheme}>
      <VictoryAxis name='x-axis'
        crossAxis={false}
        tickCount={xAxisTickCount}
        tickValues={xAxisTickValues(dates.start, dates.end)}
        fixLabelOverlap={true}
        style={{
          axis: { stroke: 'none' },
          tickLabels: { fontSize: 14 },
        }}
        tickLabelComponent={<VictoryLabel
          dy={25}
          dx={-20}
          style={{
            fill: Colors.light.hereworksBlack400,
            fontSize: 14,
            strokeWidth: 0,
            fontFamily: processFontFamily('Poppins_400Regular'),
            textAnchor: 'center',
          }} />} />
      {compareDates.end && compareDates.start && <VictoryAxis
        name='x-axis-compared'
        crossAxis={false}
        tickCount={xAxisTickCount}
        tickValues={xAxisTickValues(compareDates.start, compareDates.end)}
        fixLabelOverlap={true}
        style={{
          axis: { stroke: 'none' },
          tickLabels: { fontSize: 14 },
        }}
        domainPadding={{ x: [0, 0], y: 20 }}
        padding={{ bottom: 20 }}
        tickLabelComponent={
          <VictoryLabel
            dy={40}
            dx={-20}
            style={{
              fill: Colors.light.hereworksYellow600,
              fontSize: 14,
              strokeWidth: 0,
              fontFamily: processFontFamily('Poppins_400Regular'),
              textAnchor: 'center',
            }} />} />}
      <VictoryAxis name='y-axis'
        dependentAxis
        domain={{ y: [0, limit ?? 100] }}
        crossAxis={false}
        tickCount={6}
        fixLabelOverlap={true}
        style={{
          axis: { stroke: 'none' },
          tickLabels: { fontSize: 14 },
          grid: { stroke: Colors.light.hereworksWhite500, strokeWidth: 1, pointerEvents: 'none' },
        }}
        containerComponent={<VictoryVoronoiContainer />}
        tickLabelComponent={
          <VictoryLabel
            style={{
              fill: Colors.light.hereworksBlack400,
              fontSize: 14,
              strokeWidth: 0,
              fontFamily: processFontFamily('Poppins_400Regular'),
              textAnchor: 'center',
            }}
            dx={-50} />} />
    </VictoryChart>
    <View style={styles.descriptionContainer}>
      <Text style={styles.description}>No data available</Text>
    </View>
  </View>;
};

const styles = StyleSheet.create({
  chart: {
    paddingStart: 24,
  },
  title: {
    fontSize: 22,
    lineHeight: 33,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
    marginTop: 12,
  },
  descriptionContainer: {
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  description: {
    color: Colors.light.hereworksBlack400,
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
  },
});

export default EmptyGraph;
