import AsyncStorage from '@react-native-async-storage/async-storage';

const updateTemplateGroup = async (
  Name: string = null,
  id: string = null,
  toDelete: boolean = false,
) => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('jwt', jwtToken ?? '');

    const body: any = toDelete ? {} : { Name };
    if (id) {
      body.ButtonPrototypeGroupId = id;
    }

    let method: string = id ? 'PUT' : 'POST';
    if (toDelete) {
      method = 'DELETE';
    }

    return fetch(
      `https://${apiDomain}/button/buttongroups`,
      {
        method,
        headers: requestHeaders,
        body: JSON.stringify(body),
      },
    ).then((res) => (res ? res.json().then((json) => json) : null));
  } catch (error) {
    return null;
  }
};

export default updateTemplateGroup;
