import React from 'react';
import {
  Image, Text, TouchableOpacity, View,
} from 'react-native';
import Colors from '../../constants/Colors';

export default function GroupUserRow(props) {
  const generateColor = (length) => {
    const randomColor = Math.floor((1 / length) * 16777215)
      .toString(16)
      .padStart(6, '0');
    return `#${randomColor}`;
  };

  const renderUserIcon = () => {
    const imageSize = 32;
    if (props.icon === undefined) {
      return (
        <View
          style={{
            width: imageSize,
            height: imageSize,
            borderRadius: imageSize / 2,
            marginLeft: 16,
            marginRight: 8,
            backgroundColor: generateColor(props.name.length),
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              color: '#fff',
              fontFamily: 'Roboto_400Regular',
              fontSize: 8,
            }}
          >
            {props.name.split(' ').map((word, index) => {
              if (index > 1) {
                return '';
              }
              return word.charAt(0).toUpperCase();
            })}
          </Text>
        </View>
      );
    }
    return (
      <View
        style={{
          width: imageSize + 4,
          height: imageSize + 4,
          backgroundColor: props.selected ? '#fff' : 'transparent',
          borderColor: props.selected ? Colors.light.hereworksBlue500 : 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: (imageSize + 4) / 2,
        }}
      >
        <Image
          style={{
            width: imageSize,
            height: imageSize,
            borderRadius: imageSize / 2,
            overflow: 'hidden',
          }}
          source={{
            uri: props.icon,
          }}
        />
      </View>
    );
  };

  return (
    <TouchableOpacity
      style={{
        flex: 1,
        borderRadius: 10,
        marginRight: 10,
        marginVertical: 2,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: props.selected
          ? Colors.light.hereworksBlue500 : Colors.light.hereworksWhite100,
        borderWidth: 1,
        borderColor: Colors.light.hereworksBlue100,
      }}
      onPress={() => {
        props.setSelected(!props.selected);
      }}
    >
      {renderUserIcon()}
      <Text
        style={{
          marginVertical: 15,
          color: props.selected ? '#fff' : Colors.light.hereworksBlack600,
          fontFamily: 'Poppins_600SemiBold',
          fontSize: 12,
          marginLeft: 14,
        }}
      >
        {props.name}
      </Text>
      <Text
        style={{
          color: props.selected ? '#fff' : Colors.light.hereworksBlue600,
          fontFamily: 'Roboto_500Medium',
          fontSize: 12,
          position: 'absolute',
          right: 16,
        }}
      >
        {props.selected ? 'Remove' : 'Add'}
      </Text>
    </TouchableOpacity>
  );
}
