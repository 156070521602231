/* eslint-disable no-underscore-dangle */
import { processFontFamily } from 'expo-font';
import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory';
import Colors from '../constants/Colors';

const ChildrenBarGraphWeb = (props) => {
  const [tickVals, setTickVals] = useState([]);
  const [graphContainerWidth, setGraphContainerWidth] = useState(600);

  useEffect(() => {
    let length = 20;
    if (props.assets.length > 0) {
      length = props.assets.length;
    }
    const tickArray = [...Array(length).keys()];
    setTickVals(tickArray);
  }, [props.compareAssets, props.assets]);

  const graphHeight = 405;

  class CustomFlyout extends React.Component {
    render() {
      // @ts-ignore
      const { x, y, datum } = this.props;
      const containerHeight = 146;
      const maximumValue: number = Math.max(
        props.assets.map((item: { value: number }) => item.value)
          .reduce((prev, next) => Math.max(prev, next), 0) ?? 0,
        props.compareAssets.map((item: { value: number }) => item.value)
          .reduce((prev, next) => Math.max(prev, next), 0) ?? 0,
      );
      const currentValue: number = Math.max(
        props.assets[datum._x]?.value ?? 0,
        props.compareAssets[datum._x]?.value ?? 0,
      );
      let containerY: number = currentValue > maximumValue * 0.4
        ? Math.max(y, 50 + ((graphHeight - 50) * ((maximumValue - currentValue) / maximumValue)))
        : Math.min(y, ((graphHeight - 50) * ((maximumValue - currentValue) / maximumValue))
          - containerHeight - 50);
      const instance = props.instances?.filter((i) => i.ButtonInstanceId === datum.id)[0];
      const name = instance?.Name?.slice(0, props.compareToggled ? 17 : 20) ?? '';
      const id = datum.id.slice(0, props.compareToggled ? 13 : 15) ?? '-';
      const template = instance?.ButtonGroupName?.slice(0, props.compareToggled ? 8 : 10) ?? '-';
      const floor = instance?.LocationFloor?.slice(0, props.compareToggeld ? 13 : 15) ?? '-';
      const room = instance?.LocationRoom?.slice(0, props.compareToggled ? 8 : 10) ?? '-';
      const containerX = Math.min(x, graphContainerWidth - (props.compareToggled ? 280 : 140)) - 40;
      containerY = Math.min(containerY, graphHeight - containerHeight - 28);

      if (props.compareToggled) {
        return (
          <svg>
            <defs>
              <filter id="f3" x={-0.5} y={-1} width="200%" height="250%">
                <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
                <feColorMatrix
                  result="matrixOut"
                  in="offOut"
                  type="matrix"
                  values="0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0"
                />
                <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="4" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" result="colored" />
              </filter>
            </defs>
            <rect
              x={containerX}
              y={containerY}
              width={291}
              height={containerHeight}
              stroke={Colors.light.hereworksDarkBlue}
              rx={5}
              fill="white"
              filter="url(#f3)"
            />
            <rect
              x={containerX + 5}
              y={containerY + 5}
              width={133}
              height={containerHeight - 10}
              rx={5}
              fill={Colors.light.hereworksWhite100}
            />
            <rect
              x={containerX + 154}
              y={containerY + 5}
              width={133}
              height={containerHeight - 10}
              rx={5}
              fill={Colors.light.hereworksYellow100}
            />
            <text
              x={containerX + 10}
              y={containerY + 25}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack600}
            >
              {name}
            </text>
            <text
              x={containerX + 160}
              y={containerY + 25}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              {name}
            </text>
            <text
              x={containerX + 10}
              y={containerY + 45}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack400}
            >
              {`ID: ${id}`}
            </text>
            <text
              x={containerX + 160}
              y={containerY + 45}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              {`ID: ${id}`}
            </text>
            <text
              x={containerX + 10}
              y={containerY + 65}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack400}
            >
              Scans:
            </text>
            <text
              x={containerX + 52}
              y={containerY + 65}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack600}
            >
              {props.assets[datum._x]?.value ?? 0}
            </text>
            <text
              x={containerX + 160}
              y={containerY + 65}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              Scans:
            </text>
            <text
              x={containerX + 202}
              y={containerY + 65}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              {props.compareAssets[datum._x]?.value ?? 0}
            </text>
            <text
              x={containerX + 10}
              y={containerY + 85}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack400}
            >
              Template:
            </text>
            <text
              x={containerX + 73}
              y={containerY + 85}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack600}
            >
              {template}
            </text>
            <text
              x={containerX + 160}
              y={containerY + 85}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              Template:
            </text>
            <text
              x={containerX + 224}
              y={containerY + 85}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              {template}
            </text>
            <text
              x={containerX + 10}
              y={containerY + 105}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack400}
            >
              Floor:
            </text>
            <text
              x={containerX + 46}
              y={containerY + 105}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack600}
            >
              {floor}
            </text>
            <text
              x={containerX + 160}
              y={containerY + 105}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              Floor:
            </text>
            <text
              x={containerX + 195}
              y={containerY + 105}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              {floor}
            </text>
            <text
              x={containerX + 10}
              y={containerY + 125}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack400}
            >
              Location:
            </text>
            <text
              x={containerX + 65}
              y={containerY + 125}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack600}
            >
              {room}
            </text>
            <text
              x={containerX + 160}
              y={containerY + 125}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              Location:
            </text>
            <text
              x={containerX + 215}
              y={containerY + 125}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksPrimaryGrey800}
            >
              {room}
            </text>
            <svg width={16} height={16} fill="none" x={containerX + 138} y={containerY + 65}>
              <path
                d="M9 12H2m7 0-3 3m3-3L6 9M7 5h7M7 5l3 3M7 5l3-3"
                stroke="#2173C4"
                strokeWidth={1.5}
                strokeLinecap="round"
              />
            </svg>
          </svg>
        );
      }
      return (
        <svg>
          <defs>
            <filter id="f3" x={-0.5} y={-1} width="200%" height="250%">
              <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0"
              />
              <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="4" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" result="colored" />
            </filter>
          </defs>
          <rect
            x={containerX}
            y={containerY}
            width={155}
            height={containerHeight}
            stroke={Colors.light.hereworksDarkBlue}
            rx={5}
            fill="white"
            filter="url(#f3)"
          />
          <rect
            x={containerX + 5}
            y={containerY + 5}
            width={145}
            height={containerHeight - 10}
            rx={5}
            fill={Colors.light.hereworksWhite100}
          />
          <text
            x={containerX + 10}
            y={containerY + 25}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack600}
          >
            {name}
          </text>
          <text
            x={containerX + 10}
            y={containerY + 45}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack400}
          >
            {`ID: ${id}`}
          </text>
          <text
            x={containerX + 10}
            y={containerY + 65}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack400}
          >
            Scans:
          </text>
          <text
            x={containerX + 52}
            y={containerY + 65}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack600}
          >
            {datum.value}
          </text>
          <text
            x={containerX + 10}
            y={containerY + 85}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack400}
          >
            Template:
          </text>
          <text
            x={containerX + 73}
            y={containerY + 85}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack600}
          >
            {template}
          </text>
          <text
            x={containerX + 10}
            y={containerY + 105}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack400}
          >
            Floor:
          </text>
          <text
            x={containerX + 46}
            y={containerY + 105}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack600}
          >
            {floor}
          </text>
          <text
            x={containerX + 10}
            y={containerY + 125}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack400}
          >
            Location:
          </text>
          <text
            x={containerX + 65}
            y={containerY + 125}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack600}
          >
            {room}
          </text>
        </svg>
      );
    }
  }

  return (
    <ScrollView
      horizontal={true}
      onContentSizeChange={(width: number) => setGraphContainerWidth(width)}
    >
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => datum}
            labelComponent={<CustomFlyout />}
            responsive={false}
          />
        }
        width={940}
        height={graphHeight}
        domain={{ x: [0, 20] }}
        domainPadding={{ x: [20, 20], y: [0, 0] }}
        padding={{
          left: 50,
          right: 50,
          top: 40,
          bottom: 70,
        }}
        singleQuadrantDomainPadding={{ x: false }}
      >
        <VictoryAxis
          disableInlineStyles
          fixLabelOverlap={true}
          dependentAxis
          tickCount={10}
          style={{
            axis: { stroke: 'none' },
            tickLabels: {
              stroke: Colors.light.hereworksBlack400,
              fontFamily: processFontFamily('Poppins_400Regular'),
              fontSize: 14,
              strokeWidth: 0,
            },
            grid: {
              stroke: Colors.light.hereworksWhite100,
              strokeWidth: 2,
              pointerEvents: 'none',
            },
          }}
          tickLabelComponent={<VictoryLabel dx={-16} />}
        />
        <VictoryAxis
          disableInlineStyles
          fixLabelOverlap={true}
          axisLabelComponent={
            <VictoryLabel style={{ stroke: Colors.light.hereworksYellow500, fontSize: 12 }} />
          }
          tickCount={20}
          tickValues={tickVals}
          tickFormat={(t) => t + 1}
          style={{
            axis: { stroke: 'none' },
            tickLabels: {
              stroke: Colors.light.hereworksBlack400,
              fontFamily: processFontFamily('Poppins_400Regular'),
              fontSize: 14,
              strokeWidth: 0,
            },
          }}
        />
        {!props.compareToggled && (
          <VictoryBar
            style={{
              data: {
                // eslint-disable-next-line max-len
                fill: ({ active }) => (active ? Colors.light.hereworksDarkBlue200 : Colors.light.hereworksDarkBlue),
              },
              labels: { fontSize: 15, stroke: 'black' },
            }}
            y={'value'}
            data={props.assets}
            barWidth={26}
            // labels={({ datum }) => datum.value}
            // labelComponent={<VictoryTooltip />}
            cornerRadius={{ top: 2 }}
          />
        )}
        {!props.compareToggled && (
          <VictoryScatter
            y={'value'}
            data={props.assets}
            size={({ active }) => (active ? 6 : 0)}
            style={{
              data: { fill: Colors.light.hereworksBlue100 },
            }}
          />
        )}
        {!props.compareToggled && (
          <VictoryScatter
            y={'value'}
            data={props.assets}
            size={({ active }) => (active ? 3 : 0)}
            style={{
              data: { fill: Colors.light.hereworksDarkBlue },
            }}
          />
        )}
        {props.compareToggled && (
          <VictoryGroup offset={15}>
            <VictoryBar
              style={{ data: { fill: Colors.light.hereworksDarkBlue } }}
              y={'value'}
              data={props.assets}
              barWidth={11}
              cornerRadius={{ top: 2 }}
            />
            <VictoryBar
              style={{ data: { fill: Colors.light.hereworksYellow500 } }}
              y={'value'}
              data={props.compareAssets}
              barWidth={11}
              cornerRadius={{ top: 2 }}
            />
          </VictoryGroup>
        )}
      </VictoryChart>
    </ScrollView>
  );
};

export default ChildrenBarGraphWeb;
