import React, { useState } from 'react';
import {
  Animated,
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../../constants/Colors';
import DownArrow24 from '../24pxIcons/DownArrow24';
import IssueSettingRow from './IssueSettingRow';
import TemplateSubIcon from '../24pxIcons/TemplateSubIcon';
import NewSwitch from '../NewSwitch';

export default function NotificationGroupCard({ title, issues }: { title: string; issues: any[] }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [allToggled, setAllToggled] = useState(null);
  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });
  const renderMobile = (): boolean => Platform.OS !== 'web' || dimensions.window?.width < 500 || dimensions.window?.height < 500;
  const showSwitches = false;

  return (
    <TouchableOpacity
      style={styles.groupCard}
      onPress={() => {
        setDrawerOpen(!drawerOpen);
        Analytics.logEvent('button_press', {
          screen: 'UserMgmt',
          name: `Drawer ${drawerOpen ? 'Close' : 'Open'}`,
        });
      }}
      activeOpacity={1}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TemplateSubIcon style={{ marginLeft: 16 }} />
        <Text style={styles.groupName}>{title}</Text>
        <DownArrow24
          style={[
            { position: 'absolute', right: 20 },
            drawerOpen ? { transform: [{ rotateZ: '180deg' }] } : {},
          ]}
        />
      </View>
      <Animated.View style={{ backgroundColor: 'transparent' }}>
        {drawerOpen && (
          <Animatable.View animation={'fadeIn'}>
            {!renderMobile() && (
              <View style={{ marginBottom: 16 }}>
                <View
                  style={{
                    height: 1,
                    backgroundColor: Colors.light.hereworksWhite500,
                    marginBottom: 10,
                  }}
                />
                <View style={{ flexDirection: 'row' }}>
                  <Text style={styles.description}>NOTIFICATIONS</Text>
                  {showSwitches && (
                    <View style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row' }}>
                      <Text style={styles.description}>ALL</Text>
                      <TouchableOpacity
                        onPress={() => {
                          if (allToggled || allToggled === null) {
                            setAllToggled(false);
                          } else {
                            setAllToggled(true);
                          }
                        }}
                        style={{ marginRight: 16 }}
                      >
                        <NewSwitch
                          isToggled={allToggled ?? true}
                          color={Colors.light.hereworksGreen400}
                          style={{ marginLeft: 10 }}
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
                {issues.map((issue, key) => {
                  if (!issue.IsRelatedToUserGroup) {
                    return null;
                  }
                  return (<View key={key} style={{ marginHorizontal: 16 }}>
                    <IssueSettingRow title={issue.Text} toggled={allToggled ?? true} />
                  </View>);
                })}
              </View>
            )}
          </Animatable.View>
        )}
      </Animated.View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  groupCard: {
    backgroundColor: Colors.light.hereworksWhite100,
    alignSelf: 'stretch',
    marginVertical: 8,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: Colors.light.hereworksWhite300,
  },
  groupName: {
    fontSize: 18,
    fontFamily: 'Poppins_600SemiBold',
    marginLeft: 12,
    marginVertical: 16,
    width: 200,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Poppins_500Medium>',
    marginLeft: 24,
  },
  description: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 22,
    color: Colors.light.hereworksBlack400,
    marginLeft: 20,
  },
});
