import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
  Image,
  TextInput,
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Camera, PermissionResponse } from 'expo-camera';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Modal from 'react-native-modal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Colors from '../../constants/Colors';
import reportIssue from '../../requests/ReportIssue';
import IssueReported from '../60pxIcons/IssueReported';
import AlertModal from '../AlertModal';
import CloseModalIcon from '../CloseModalIcon';
import Style from '../../constants/Style';
import CameraIcon from '../24pxIcons/CameraIcon';

const mobile = Dimensions.get('window').width < 600 || (Platform.OS !== 'web');

interface ReportIssueModalProps {
  isVisible: boolean
  hide: () => void,
  name: string,
  id: string,
  template: { Title: string, Subtitle: string, issues: { Text: string, Id: string }[] },
}

const ReportIssueModal = ({
  isVisible, hide, template, name, id,
}: ReportIssueModalProps) => {
  const [selectedIssue, setSelectedIssue] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [reportedIssue, setReportedIssue] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [cameraOpen, setCameraOpen] = useState<boolean>(false);
  const [cameraReady, setCameraReady] = useState<boolean>(false);
  const [status, requestPermission] = Camera.useCameraPermissions();
  const [image, setImage] = useState<string>(null);
  const [capturing, setCapturing] = useState<boolean>(false);
  const [otherIssue, setOtherIssue] = useState<string>('');
  const cameraRef = useRef(null);
  const isFocused: boolean = useIsFocused();

  useEffect(() => {
    AsyncStorage.getItem('email').then((e) => {
      if (e?.length) setEmail(e);
    });
    AsyncStorage.getItem('name').then((e) => {
      if (e?.length) setUsername(e);
    });
  }, []);

  useEffect(() => {
    setLoading(false);
    if (reportedIssue) {
      setSelectedIssue(null);
      setOtherIssue('');
    }
    setReportedIssue(false);
  }, [isVisible]);

  const showError = () => {
    setLoading(false);
    setAlertVisible(true);
  };

  const reportIssueButtonPress = () => {
    setLoading(true);
    reportIssue(
      id,
      template.issues.filter((issue: { Id: string }) => issue.Id === selectedIssue)
        ?.map((issue: { Text: string }) => (issue.Text.toLowerCase() === 'other'
          ? otherIssue : issue.Text)),
      email,
      username,
      selectedIssue,
      image,
      otherIssue,
    ).then((output) => {
      if (output) setReportedIssue(true);
      else showError();
      setLoading(false);
    }).catch(showError);
  };

  const openCamera = () => {
    if (!status || (!status.granted && status.canAskAgain)) {
      requestPermission().then((permission: PermissionResponse) => {
        if (permission?.granted) setCameraOpen(true);
      });
    } else if (status && status.granted) setCameraOpen(true);
  };

  if (!template || !name || !id) return null;

  const insets = useSafeAreaInsets();

  return <Modal
    isVisible={isVisible}
    onBackdropPress={hide}
    onBackButtonPress={hide}
    onSwipeComplete={hide}
    swipeDirection='right'
    style={styles.modal}
  >
    {<View style={[styles.container, {
      paddingTop: insets.top,
      paddingBottom: insets.bottom,
    }]}>
      {reportedIssue ? <>
        <View>
          <IssueReported style={styles.issueReportedImage} />
          <Text style={styles.successTitle}>Someone's coming to resolve your report</Text>
        </View>
        <View style={styles.successDescriptionContainer}>
          <Text style={styles.successTitle}>We will notify you once the issue is fixed</Text>
          <Text style={styles.successDescription}>{'Thank you for reporting!\n\n\nKeep reporting and make your workplace a better place'}</Text>
        </View>
      </> : <>
        <Text style={styles.title}>
          {template?.Title?.replace('{{Asset:Name}}', name).replace(' at {{Location:Name}}', '')
            ?? 'Report issue'}
        </Text>
        <Text style={styles.subTitle}>{template.Subtitle}</Text>
        <ScrollView contentContainerStyle={styles.scrollViewContent}>
          <>
            {template.issues
              ?.map((issue: { Text: string, Id: string }) => <View
                style={styles.issue}
                key={`Issue_${issue.Id}`}
              >
                <TouchableOpacity
                  style={styles.issueContent}
                  disabled={loading}
                  onPress={() => setSelectedIssue(selectedIssue === issue.Id
                    ? undefined : issue.Id)}
                >
                  <View style={[
                    styles.issueCircle,
                    selectedIssue === issue.Id && styles.issueCircleSelected,
                  ]} />
                  <Text style={styles.issueText}>{issue.Text}</Text>
                </TouchableOpacity>
                {issue.Text.toLowerCase() === 'other' ? <TextInput
                  placeholder='Type your issue here...'
                  placeholderTextColor={Colors.light.hereworksPrimaryGrey}
                  style={styles.textInput}
                  value={otherIssue}
                  onChangeText={(text: string) => {
                    setSelectedIssue(issue.Id);
                    setOtherIssue(text);
                  }}
                /> : null}
              </View>)}
            {image ? <View>
              <Image
                style={styles.imagePreview}
                resizeMode='cover'
                source={{ uri: image }}
              />
              <TouchableOpacity
                style={[Style.closeButton, styles.imageCloseButton]}
                onPress={() => setImage(null)}
              >
                <CloseModalIcon />
              </TouchableOpacity>
            </View>
              : null}
          </>
        </ScrollView>
        {mobile ? <TouchableOpacity
          onPress={openCamera}
          style={[
            styles.button, styles.takePhotoButton,
            (!selectedIssue || loading) && styles.buttonDisabled]}
          disabled={!selectedIssue || loading}
        >
          <CameraIcon />
          <Text style={[styles.buttonText, styles.takePhotoText]}>Take a photo</Text>
        </TouchableOpacity> : null}
        <TouchableOpacity
          onPress={reportIssueButtonPress}
          style={[
            styles.button, styles.reportButton,
            (!selectedIssue || loading) && styles.buttonDisabled]}
          disabled={!selectedIssue || loading}
        >
          <Text style={styles.buttonText}>Report an issue</Text>
        </TouchableOpacity>
      </>}
      <TouchableOpacity
        onPress={hide}
        style={[Style.closeButton, { top: 12 + insets.top, end: 12 }]}
      >
        <CloseModalIcon />
      </TouchableOpacity>
    </View>}
    {mobile ? <Modal
      style={styles.modal}
      isVisible={cameraOpen}
    >
      {!status?.granted && <Text>Camera permission denied</Text>}
      {(status?.granted && isFocused) && <Camera
        ratio="16:9"
        style={styles.cameraWindow}
        ref={cameraRef}
        onCameraReady={() => setCameraReady(true)}
        onMountError={() => { }}
      >
        {cameraReady && <TouchableOpacity
          style={styles.captureButton}
          onPress={() => {
            setCapturing(true);
            cameraRef.current.takePictureAsync({ base64: true }).then((picture) => {
              setImage(`data:image/jpeg;base64,${picture.base64}`);
              setCameraOpen(false);
              setCapturing(false);
            });
          }}
        >
          <View style={styles.captureButtonCircle} />
          {capturing && <ActivityIndicator style={{ position: 'absolute' }} />}
        </TouchableOpacity>}
        <TouchableOpacity
          onPress={() => setCameraOpen(false)}
          style={[Style.closeButton, { top: 12 + insets.top, end: 12 }]}
        >
          <CloseModalIcon />
        </TouchableOpacity>
      </Camera>}
    </Modal> : null}
    <AlertModal
      title='Error'
      message='Unable to report issue right now. Please try again later.'
      error
      modalVisible={alertVisible}
      setModalVisible={setAlertVisible}
    />
    <ActivityIndicator
      size='large'
      style={styles.activityIndicator}
      animating={loading}
    />
  </Modal>;
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  container: {
    height: mobile ? '100%' : 600,
    width: mobile ? '100%' : 500,
    alignSelf: 'center',
    backgroundColor: Colors.light.background,
    borderRadius: mobile ? undefined : 12,
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  scrollViewContent: {
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 24,
    lineHeight: 36,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksPrimaryGrey,
    textAlign: 'center',
    marginHorizontal: 64,
    marginTop: 35,
  },
  subTitle: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksPrimaryGrey800,
    textAlign: 'center',
    marginBottom: 20,
    marginHorizontal: 45,
  },
  issue: {
    padding: 10,
    marginBottom: 10,
    borderRadius: 12,
    backgroundColor: '#FFF',
  },
  issueContent: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  issueCircle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: Colors.light.hereworksPaleBlue800,
    marginEnd: 10,
  },
  issueCircleSelected: {
    backgroundColor: Colors.light.hereworksYellow500,
  },
  issueText: {
    fontSize: 18,
    lineHeight: 27,
    color: '#000',
    fontFamily: 'Poppins_500Medium',
    flex: 1,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 52,
    borderRadius: 12,
    marginTop: 12,
    marginHorizontal: 24,
  },
  takePhotoButton: {
    borderWidth: 2,
    borderColor: Colors.light.hereworksBlue500,
    flexDirection: 'row',
  },
  reportButton: {
    backgroundColor: Colors.light.hereworksBlue500,
    marginBottom: 33,
  },
  buttonText: {
    fontSize: 16,
    lineHeight: 24,
    color: '#FFF',
    fontFamily: 'Poppins_500Medium',
    textAlign: 'center',
  },
  takePhotoText: {
    color: Colors.light.hereworksBlue500,
    marginStart: 12,
  },
  activityIndicator: {
    position: 'absolute',
    alignSelf: 'center',
  },
  buttonDisabled: {
    opacity: 0.5,
  },
  issueReportedImage: {
    alignSelf: 'center',
    marginTop: 36,
    marginBottom: 20,
  },
  successTitle: {
    fontSize: 24,
    lineHeight: 36,
    fontFamily: 'Poppins_600SemiBold',
    textAlign: 'center',
    marginHorizontal: 44,
    marginTop: 20,
  },
  successDescription: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Poppins_500Medium',
    textAlign: 'center',
    marginHorizontal: 60,
    marginTop: 10,
  },
  successDescriptionContainer: {
    flex: 1,
    marginTop: '20%',
  },
  noPermissionText: {
    color: '#FFF',
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Poppins_500Medium',
    textAlign: 'center',
  },
  cameraWindow: {
    flex: 1,
    margin: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  captureButton: {
    borderWidth: 6,
    borderColor: '#fff',
    borderRadius: 35,
    width: 70,
    height: 70,
    backgroundColor: 'transparent',
    padding: 8,
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  captureButtonCircle: {
    backgroundColor: '#fff',
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  imagePreview: {
    width: '100%',
    aspectRatio: 1.17,
    borderRadius: 12,
    marginBottom: 12,
  },
  imageCloseButton: {
    top: 12,
    end: 12,
  },
  textInput: {
    marginVertical: 12,
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksPrimaryGrey900,
  },
});

export default ReportIssueModal;
