import React, {
  Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';
import {
  View, ScrollView, StyleSheet, Text, TouchableOpacity, Platform,
} from 'react-native';
import Colors from '../../constants/Colors';
import GridIconSmall from '../12pxIcons/GridIconSmall';

type Asset = {
  ButtonInstanceId: string,
  Name: string,
  Description: string,
  Model: string,
  Manufacturer: string,
  Serial: string,
  LocationFloor: string,
  LocationRoom: string,
  LocationArea: string,
  ButtonPrototypeId: string,
  ButtonPrototypeName: string,
  CustomFields: { CustomValue: string }[],
};

type Template = { name: string, GroupName: string, id: string };

type TemplateGroup = { Name: string, ButtonPrototypeGroupId: string };

interface SearchResultsPaneProps {
  focused?: boolean,
  blur?: () => void,
  searchValue: string,
  assets: Asset[],
  templates: Template[],
  templateGroups: TemplateGroup[],
  selectAsset: Dispatch<SetStateAction<Asset>>,
  selectTemplate: Dispatch<SetStateAction<Template>>,
  selectTemplateGroup: Dispatch<SetStateAction<string>>,
  showAssets: Dispatch<SetStateAction<boolean>>,
  setSearchValue: Dispatch<SetStateAction<string>>,
  selectedAsset: Asset,
  selectedTemplate: Template,
  selectedTemplateGroup: string,
}

const SearchResultsPane = ({
  searchValue, assets, templates, templateGroups,
  selectTemplate, selectAsset, showAssets, selectTemplateGroup, setSearchValue,
  selectedAsset, selectedTemplate, selectedTemplateGroup, focused, blur = null,
}: SearchResultsPaneProps) => {
  const searchString: string = searchValue?.toString().toLowerCase().trim();
  const [childHeight, setChildHeight] = useState<number>(0);
  const [focusIndex, setFocusIndex] = useState<number>(-1);
  const refs = [];
  const filteredAssets: Asset[] = assets?.filter((asset: Asset) => {
    let match: boolean = false;
    asset.CustomFields?.forEach((field: { CustomValue: string }) => {
      if (field.CustomValue.toLowerCase().includes(searchString)) {
        match = true;
      }
    });
    if (asset.Name?.toString().toLowerCase().includes(searchString)
      || asset.Manufacturer?.toString().toLowerCase().includes(searchString)
      || asset.Description?.toString().toLowerCase().includes(searchString)
      || asset.Model?.toString().toLowerCase().includes(searchString)
      || asset.Serial?.toString().toLowerCase().includes(searchString)
      || asset.ButtonInstanceId?.toString().toLowerCase().includes(searchString)
      || asset.ButtonPrototypeName?.toString().toLowerCase().includes(searchString)
      || asset.LocationRoom?.toString().toLowerCase().includes(searchString)
      || asset.LocationFloor?.toString().toLowerCase().includes(searchString)
      || asset.LocationArea?.toString().toLowerCase().includes(searchString)) return true;
    return match;
  });
  const filteredTemplates: Template[] = templates?.filter((template: Template) => template.name
    ?.toLowerCase().includes(searchString));
  const filteredTemplateGroups: TemplateGroup[] = templateGroups
    ?.filter((group) => group.Name?.toLowerCase().includes(searchString)) ?? [];

  const shiftFocus = (e) => {
    if (e.code === 'ArrowDown') {
      setFocusIndex(Math.min(focusIndex + 1, (filteredAssets?.length ?? 0)
        + (filteredTemplates?.length ?? 0) + (filteredTemplateGroups?.length ?? 0) - 1));
    } else if (e.code === 'ArrowUp') {
      if (focusIndex <= 0) blur();
      else setFocusIndex(Math.max(-1, focusIndex - 1));
    }
  };
  const shiftFocusRef = useRef(shiftFocus);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (Platform.OS === 'web') window.addEventListener('keydown', (e) => shiftFocusRef.current(e));
  }, []);

  useEffect(() => {
    if (focused) refs[0]?.focus();
  }, [focused]);

  useEffect(() => {
    shiftFocusRef.current = shiftFocus;
    refs[focusIndex]?.focus();
  }, [focusIndex]);

  return searchString?.length
    ? <View style={[styles.wrapper, Platform.OS === 'android' && { height: childHeight }]}>
      <View style={styles.container} onLayout={(e) => {
        if (Platform.OS === 'android') setChildHeight(e.nativeEvent.layout.height);
      }}>
        <ScrollView style={styles.content}>
          {filteredAssets?.length ? <>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionHeaderText}>ASSETS</Text>
            </View>
            {filteredAssets?.map((asset: Asset, index: number) => {
              const currentlySelected: boolean = selectedAsset?.ButtonInstanceId === asset
                .ButtonInstanceId;
              return <TouchableOpacity
                key={`filtered_asset${asset.ButtonInstanceId}`}
                activeOpacity={currentlySelected ? styles.currentSelection.opacity : undefined}
                style={[styles.assetResult, currentlySelected && styles.currentSelection]}
                ref={(ref) => refs.push(ref)}
                onFocus={() => setFocusIndex(index)}
                onPress={() => {
                  if (currentlySelected) return;
                  const assetTemplate: Template = templates
                    ?.filter((template: Template) => template.id === asset.ButtonPrototypeId)[0];
                  if (assetTemplate) selectTemplate(assetTemplate);
                  selectAsset(asset);
                  setSearchValue('');
                  showAssets(true);
                }}
              >
                <Text style={styles.searchResultTitle}>
                  {asset.Name}
                  {currentlySelected && <Text style={styles.currentlySelectedText}>
                    {' (currently selected)'}
                  </Text>}
                </Text>
                <Text style={styles.searchResultDescription}>{asset.ButtonPrototypeName}</Text>
              </TouchableOpacity>;
            })}
          </> : null}
          {filteredTemplates?.length ? <>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionHeaderText}>TEMPLATES</Text>
            </View>
            {filteredTemplates.map((template: Template, index: number) => {
              const currentlySelected: boolean = !selectedAsset
                && template.id === selectedTemplate?.id;
              return <TouchableOpacity
                key={`filtered_template${template.id}`}
                activeOpacity={currentlySelected ? styles.currentSelection.opacity : undefined}
                style={[styles.templateResult, currentlySelected && styles.currentSelection]}
                ref={(ref) => refs.push(ref)}
                onFocus={() => setFocusIndex(filteredAssets?.length ?? 0 + index)}
                onPress={() => {
                  if (currentlySelected) return;
                  selectTemplate(template);
                  selectAsset(null);
                  selectTemplateGroup(null);
                  setSearchValue('');
                  showAssets(true);
                }}
              >
                <GridIconSmall style={styles.templateIcon} />
                <Text style={styles.searchResultTitle}>
                  {template.name}
                  {currentlySelected && <Text style={styles.currentlySelectedText}>
                    {' (currently selected)'}
                  </Text>}
                </Text>
              </TouchableOpacity>;
            })}
          </> : null}
          {filteredTemplateGroups?.length ? <>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionHeaderText}>TEMPLATE GROUPS</Text>
            </View>
            {filteredTemplateGroups.map((group: TemplateGroup, index: number) => {
              const currentlySelected: boolean = !selectedAsset
                && selectedTemplateGroup === group.ButtonPrototypeGroupId;
              return <TouchableOpacity
                key={`filtered_template_group${group.ButtonPrototypeGroupId}`}
                activeOpacity={currentlySelected ? styles.currentSelection.opacity : undefined}
                style={[styles.templateResult, currentlySelected && styles.currentSelection]}
                ref={(ref) => refs.push(ref)}
                onFocus={() => setFocusIndex((filteredAssets?.length ?? 0)
                  + (filteredTemplates?.length ?? 0) + index)}
                onPress={() => {
                  if (currentlySelected) return;
                  selectTemplateGroup(group.ButtonPrototypeGroupId);
                  selectAsset(null);
                  selectTemplate(null);
                  setSearchValue('');
                  showAssets(false);
                }}
              >
                <Text style={styles.searchResultTitle}>
                  {group.Name}
                  {currentlySelected && <Text style={styles.currentlySelectedText}>
                    {' (currently selected)'}
                  </Text>}
                </Text>
              </TouchableOpacity>;
            })}
          </> : null}
          {!filteredAssets.length && !filteredTemplates.length && !filteredTemplateGroups.length
            && <View style={styles.assetResult}>
              <Text style={styles.searchResultTitle}>No results found</Text>
            </View>}
        </ScrollView>
      </View>
    </View> : null;
};

const styles = StyleSheet.create({
  wrapper: {
    height: 0,
    zIndex: 2,
  },
  container: {
    position: 'absolute',
    top: 8,
    width: '100%',
    paddingHorizontal: 24,
  },
  content: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue100,
    backgroundColor: '#FFF',
    maxHeight: 317,
  },
  sectionHeader: {
    backgroundColor: Colors.light.hereworksWhite400,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  sectionHeaderText: {
    color: Colors.light.hereworksBlack500,
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_500Medium',
    marginHorizontal: 12,
    marginVertical: 6,
  },
  assetResult: {
    paddingHorizontal: 12,
    paddingVertical: 9,
  },
  templateResult: {
    paddingVertical: 18,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchResultTitle: {
    fontSize: 14,
    lineHeight: 21,
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_400Regular',
  },
  searchResultDescription: {
    fontSize: 12,
    lineHeight: 14.06,
    color: Colors.light.hereworksBlack400,
    fontFamily: 'Roboto_400Regular',
    marginTop: 4,
  },
  templateIcon: {
    marginEnd: 10,
  },
  currentlySelectedText: {
    fontSize: 12,
    lineHeight: 14.06,
  },
  currentSelection: {
    opacity: 0.5,
  },
});

export default SearchResultsPane;
