import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const DownloadIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="M5.333 8 8 10.667 10.666 8M3.333 14h9.333M8 2v8.667V2Z"
      stroke={props.color ?? '#fff'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default DownloadIcon;
