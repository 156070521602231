import React, { useEffect, useMemo, useState } from 'react';
import Moment from 'moment';
import {
  View, StyleSheet, Text, TouchableOpacity, Animated, Easing, AppState,
} from 'react-native';
import Colors from '../../../constants/Colors';
import SectionHeader from '../../SectionHeader';
import AirQualityDropdownSelector from './AirQualityDropdownSelector';
import AirQualityGraph from '../../../graphs/AirQualityGraph';
import AirQualitySafeLevelsSection from './AirQualitySafeLevelsSection';
import MetricValueCard from './MetricValueCard';
import MetricInfoCardSection from './MetricInfoCardSection';
import MetricScoreCardItem from './MetricScoreCardItem';
import MetricSelectionButton from './MetricSelectionButton';
import DeleteIcon12 from '../../12pxIcons/DeleteIcon12';
import { GetMetricByDate } from '../../../requests/GetMetricByDate';
import getSensors from '../../../requests/GetSensors';
import getLocations from '../../../requests/GetLocations';

interface MetricProps {
  value: string,
  table: string,
  title: string,
  fullTitle?: string,
  unit: string,
  scoreValue: string,
}

const metrics: MetricProps[] = [
  {
    table: 'hw-rawdata-co2', value: 'data-co2', title: 'CO2', unit: 'ppm', fullTitle: 'CO₂', scoreValue: 'happy_metric_internal_air_co2_local_happyscore_percent',
  },
  {
    table: 'hw-rawdata-lux', value: 'data-lux', title: 'Light', unit: 'lx', scoreValue: 'happy_metric_internal_light_quality_local_happyscore_percent',
  },
  {
    table: 'hw-rawdata-spl', value: 'data-spl', title: 'Noise', unit: 'dB', scoreValue: 'happy_metric_internal_air_spl_local_happyscore_percent',
  },
  {
    table: 'hw-rawdata-pm25', value: 'data-pm25', title: 'PM2.5', unit: 'µg/m3', scoreValue: 'happy_metric_internal_air_pm25_local_happyscore_percent',
  },
  {
    table: 'hw-rawdata-voc', value: 'data-voc', title: 'TVOC', unit: 'ppb', scoreValue: 'happy_metric_internal_air_tvoc_local_happyscore_percent',
  },
  {
    table: 'hw-rawdata-temp', value: 'data-temperature', title: 'Temperature', unit: '°C', scoreValue: 'happy_metric_office_temp_local_happyscore_percent',
  },
  {
    table: 'hw-rawdata-humidity', value: 'data-humidity', title: 'Humidity', unit: '%', scoreValue: 'happy_metric_office_humidity_local_happyscore_percent',
  },
];

interface AirQualitySectionProps {
  showComparison: boolean,
  dates: { start: Date, end: Date },
  compareDates: { start: Date, end: Date },
}

const animation = (animatedValue: Animated.Value) => Animated.sequence([
  Animated.timing(animatedValue, { duration: 0, useNativeDriver: true, toValue: 0 }),
  Animated.timing(animatedValue, {
    duration: 500, useNativeDriver: true, toValue: 1, easing: Easing.ease,
  }),
]);

const AirQualitySection = ({ dates, compareDates, showComparison }: AirQualitySectionProps) => {
  const [defaultShown, setDefaultShown] = useState(true);
  const [overallScore, setOverallScore] = useState(null);
  const [overallScoreCompared, setOverallScoreCompared] = useState(null);
  const [selectedMetricIndex, setMetricButtonSelectedIndex] = useState(0);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [graphData, setGraphData] = useState([[], [], [], [], [], [], []]);
  const [graphDataCompared, setGraphDataCompared] = useState([[], [], [], [], [], [], []]);
  const [liveValues] = useState([null, null, null, null, null, null, null]);
  const [scores] = useState([null, null, null, null, null, null, null]);
  const [scoresCompared] = useState([null, null, null, null, null, null, null]);
  const [chartSectionOpacity] = useState(new Animated.Value(1));
  const [chartOpacity] = useState(new Animated.Value(1));
  const [sectionOpacity] = useState(new Animated.Value(1));

  const fetchGraphData = (compare: boolean = false) => {
    const newData = [[], [], [], [], [], [], []];
    metrics?.forEach((metric, metricIndex) => GetMetricByDate(
      metric.value,
      compare ? compareDates : dates,
      '24h',
      metric.table,
      selectedSensor?.value,
      selectedRoom,
      selectedFloor,
    )?.then((metricData) => {
      (metricData ?? []).forEach((e, index: number) => newData[metricIndex].push({
        value: e.score.value,
        date: e.key_as_string,
        index,
      }));
      if (compare) setGraphDataCompared([...newData]);
      else setGraphData([...newData]);
    }));
  };

  useMemo(() => {
    fetchGraphData();
  }, [new Date(dates.start)?.toDateString(), new Date(dates.end)?.toDateString(),
    selectedFloor, selectedRoom, selectedSensor?.value]);
  useMemo(() => {
    fetchGraphData(true);
  }, [new Date(compareDates.start)?.toDateString(), new Date(compareDates.end)?.toDateString(),
    selectedFloor, selectedRoom, selectedSensor?.value]);

  const fetchLiveData = () => {
    if (!defaultShown || !(AppState.currentState === 'active')) return;
    let floor = null;
    if (selectedFloor === 'Ground Floor') floor = '0';
    else if (selectedFloor === 'First Floor') floor = '1';
    metrics.forEach((metric, index) => GetMetricByDate(
      metric.value,
      { start: Moment().subtract(5, 'minutes').toDate(), end: new Date() },
      '1m',
      metric.table,
      selectedSensor?.value,
      selectedRoom,
      floor,
    )?.then((data) => {
      const values: { score: { value: number | null } }[] = data;
      if (values) {
        const liveValue: number = Math.floor((values ?? [])[values.length - 1]?.score?.value);
        liveValues[index] = Number.isNaN(liveValue) ? null : liveValue;
      } else {
        liveValues[index] = null;
      }
    }));
  };

  useEffect(() => {
    fetchLiveData();
    setInterval(fetchLiveData, 60000);
  }, [selectedFloor, selectedRoom, selectedSensor?.value, defaultShown]);

  const setGraphFloor = (floor: string) => {
    setSelectedFloor(floor);
    setSelectedRoom(null);
    setSelectedSensor(null);
  };

  const setGraphRoom = (room: string) => {
    setSelectedRoom(room);
    setSelectedSensor(null);
  };

  const clearMenuSelection = () => {
    setSelectedFloor(null);
    setSelectedRoom(null);
    setSelectedSensor(null);
  };

  const clearCurrentSelection = () => {
    if (selectedSensor) setSelectedSensor(null);
    else if (selectedRoom) {
      setSelectedSensor(null);
      setSelectedRoom(null);
    } else if (selectedFloor) clearMenuSelection();
  };

  const fetchScoreData = (isCompared: boolean = false) => {
    if (isCompared && !compareDates.end) return;
    metrics.forEach((metric, index) => GetMetricByDate(
      metric.scoreValue,
      isCompared ? compareDates : dates,
      '1h',
      'happyscore_flat_permin',
    )?.then((data) => {
      const values: number[] = data?.map((bucket) => bucket?.score?.value) ?? [];
      if (values) {
        const average: number = values.reduce((prev, next) => prev + next, 0) / values.length;
        const newScore: number = average ? Math.min(100, Math.max(0, Math.floor(average))) : null;
        if (isCompared) {
          scoresCompared[index] = newScore;
        } else {
          scores[index] = newScore;
        }
      }
    }));
  };

  useMemo(() => {
    fetchScoreData(false);
  }, [new Date(dates.start)?.toDateString(), new Date(dates.end)?.toDateString()]);
  useMemo(() => {
    fetchScoreData(true);
  }, [new Date(compareDates.start)?.toDateString(), new Date(compareDates.end)?.toDateString()]);

  const fetchOverallScore = (isComparison: boolean = false) => {
    GetMetricByDate('happy_group_internal_environment_local_happyscore_percent', isComparison ? compareDates : dates)
      .then((data) => {
        const values: number[] = data?.map((bucket) => bucket?.score?.value) ?? [];
        const average: number = (values?.reduce((prev, next) => prev + next, 0) ?? 0)
          / values.length;
        const newScore: number = Number.isNaN(average) ? null : Math.floor(average);
        if (isComparison) setOverallScoreCompared(newScore);
        else setOverallScore(newScore);
      });
  };

  useMemo(() => {
    fetchOverallScore(false);
  }, [new Date(dates.start)?.toDateString(), new Date(dates.end)?.toDateString()]);
  useMemo(() => {
    fetchOverallScore(true);
  }, [new Date(compareDates.start)?.toDateString(), new Date(compareDates.end)?.toDateString]);

  const [locationList, setLocationList] = useState([]);
  const [fullSensorList, setFullSensorList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [sensorList, setSensorList] = useState([]);

  useEffect(() => {
    getLocations().then((locationsData) => {
      const floors = [...new Set(locationsData.locations?.map((item) => item.Floor))];
      setFloorList(floors);
      setLocationList(floors?.map((floor) => ({
        floor,
        rooms: locationsData.locations?.filter((location) => location.Floor === floor)
          ?.map((location) => ({ room: location.Room })),
      })));
    });
    getSensors().then((response) => {
      setFullSensorList((response?.sensors ?? [])
        ?.map((sensor) => ({ ...sensor })));
    });
  }, []);

  useEffect(() => {
    setRoomList(locationList?.filter((location) => location.floor === selectedFloor)[0]?.rooms
      ?.map((roomItem) => roomItem.room)
      ?.filter((room) => fullSensorList?.filter(
        (sensor) => sensor.room?.toLowerCase() === room?.toLowerCase(),
      ).length > 0)
      ?? []);
  }, [locationList, selectedFloor]);

  useEffect(() => {
    setSensorList(fullSensorList?.filter((sensor) => sensor.room?.toLowerCase()
      === selectedRoom?.toLowerCase())
      ?.map((sensor) => ({ label: sensor.id, value: sensor.id })));
  }, [roomList, selectedRoom]);

  useEffect(animation(chartSectionOpacity).start, [selectedMetricIndex]);
  useEffect(animation(chartOpacity).start, [graphData, graphDataCompared]);
  useEffect(animation(sectionOpacity).start, [defaultShown]);

  return <View style={styles.container}>
    <SectionHeader defaultShown={defaultShown}
      setDefaultShown={setDefaultShown}
      button1='GRAPH'
      button2='INSIGHTS'
      title1='Internal environment parameters'
      title2='Internal environment score'
      {...{ dates, compareDates, showComparison }}
    />
    {defaultShown ? <Animated.View style={{ opacity: sectionOpacity }}>
      <View style={styles.metricValuesRow}>
        {metrics?.map((metric, index: number) => <MetricValueCard
          metric={metric}
          value={liveValues[index]}
          index={index}
        />)}
      </View>
      <View style={styles.parametersMainSection}>
        <View style={styles.dropdownHeaderSection}>
          <AirQualityDropdownSelector
            floorList={floorList}
            roomList={roomList}
            sensorList={sensorList}
            selectedFloor={selectedFloor}
            setSelectedFloor={setGraphFloor}
            selectedRoom={selectedRoom}
            setSelectedRoom={setGraphRoom}
            selectedSensor={selectedSensor}
            setSelectedSensor={setSelectedSensor} />
          {(selectedFloor || selectedRoom || selectedSensor)
            && <TouchableOpacity onPress={clearMenuSelection}>
              <Text style={styles.resetFiltersText}>RESET ALL FILTERS</Text>
            </TouchableOpacity>}
        </View>
        <View style={styles.row}>
          <View style={styles.solidCircle} />
          <View style={styles.row}>
            <Text style={styles.sensorSelectionText}>
              {selectedSensor ? `Sensor ${selectedSensor.label}` : `Average for ${selectedRoom ?? selectedFloor ?? 'all sensors'}`}
            </Text>
            {(selectedFloor || selectedRoom || selectedSensor)
              && <TouchableOpacity onPress={clearCurrentSelection}>
                <DeleteIcon12 />
              </TouchableOpacity>}
          </View>
        </View>
        <Animated.View style={{ opacity: chartSectionOpacity }}>
          <View style={styles.row}>
            <Text style={styles.metricGraphTitle}>
              {`${metrics[selectedMetricIndex].fullTitle ?? metrics[selectedMetricIndex].title} (${metrics[selectedMetricIndex].unit})`}
            </Text>
          </View>
          <Animated.View style={{ opacity: chartOpacity }}>
            <AirQualityGraph
              showComparison={showComparison}
              selectedMetricIndex={selectedMetricIndex}
              data={graphData}
              dataCompared={graphDataCompared}
              currentMetricTitle={metrics[selectedMetricIndex].title}
              unit={metrics[selectedMetricIndex].unit}
            />
          </Animated.View>
          <AirQualitySafeLevelsSection units={metrics?.map((metric) => metric.unit)}
            index={selectedMetricIndex} />
        </Animated.View>
      </View>
      <View style={styles.metricValuesRow}>
        {metrics?.map((item, index) => <MetricSelectionButton title={item.title}
          selected={selectedMetricIndex === index}
          onPress={setMetricButtonSelectedIndex}
          index={index} />)}
      </View>
    </Animated.View> : <Animated.View style={[styles.scoreContent, { opacity: sectionOpacity }]}>
      <View style={styles.scoreCard}>
        <View style={styles.scoreCardTopSection}>
          <View style={styles.row}>
            <Text style={styles.scoreValue}>{overallScore ?? '-'}</Text>
            {showComparison
              && <Text style={styles.scoreValueCompared}>{overallScoreCompared ?? '-'}</Text>}
          </View>
        </View>
        <Text style={styles.scoreCardTitle}>Average happy score per metric</Text>
        <View style={styles.scoreCardItemsSection}>
          {metrics?.map((metric: MetricProps, index: number) => <MetricScoreCardItem
            metric={metric}
            score={scores[index]}
            scoreCompared={scoresCompared[index]}
            showComparison={showComparison}
          />)}
        </View>
      </View>
      <MetricInfoCardSection />
    </Animated.View>}
  </View>;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    borderRadius: 24,
    borderWidth: 1,
    borderColor: Colors.light.hereworksWhite300,
    paddingTop: 18,
    paddingBottom: 27,
    marginBottom: 24,
  },
  scoreContent: {
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  scoreCard: {
    width: 370,
    borderRadius: 16,
    padding: 24,
    marginEnd: 27,
    backgroundColor: '#D6F1FA',
  },
  scoreCardTopSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingBottom: 8,
    borderBottomWidth: 1,
    marginBottom: 35,
    borderBottomColor: Colors.light.hereworksBlue200,
  },
  scoreValue: {
    fontSize: 48,
    lineHeight: 46,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksEmeraldGreen,
    marginEnd: 12,
  },
  scoreValueCompared: {
    fontSize: 48,
    lineHeight: 46,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksYellow600,
  },
  scoreCardTitle: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
    marginBottom: 8,
  },
  scoreCardItemsSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
    flexWrap: 'wrap',
  },
  metricValuesRow: {
    flexDirection: 'row',
    marginHorizontal: 18,
    flexWrap: 'wrap',
  },
  metricGraphTitle: {
    marginTop: 20,
    fontSize: 18,
    lineHeight: 27,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
    marginEnd: 24,
  },
  parametersMainSection: {
    marginTop: 21,
    marginHorizontal: 24,
    marginBottom: 12,
    borderBottomWidth: 1.5,
    borderBottomColor: Colors.light.hereworksWhite500,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownHeaderSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  resetFiltersText: {
    fontSize: 12,
    lineHeight: 14.06,
    fontFamily: 'Roboto_500Medium',
    color: Colors.light.hereworksBlack500,
    textDecorationLine: 'underline',
    marginStart: 40,
  },
  solidCircle: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginEnd: 8,
    backgroundColor: Colors.light.hereworksDarkBlue,
  },
  sensorSelectionText: {
    fontSize: 14,
    lineHeight: 16.41,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
    marginEnd: 11,
  },
});

export default AirQualitySection;
