import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CoffeeMachine60 = (props: SvgProps) => (
  <Svg width={61} height={60} fill="none" {...props}>
    <Path
      d="M5.715 6v8a1 1 0 0 0 1 1h28.5a.5.5 0 0 1 .5.5V47a.5.5 0 0 1-.5.5h-28.5a1 1 0 0 0-1 1V54a1 1 0 0 0 1 1h48a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-48a1 1 0 0 0-1 1Z"
      stroke={props.color ?? '#fff'}
      strokeWidth={3}
      strokeLinecap="round"
    />
    <Path
      d="M13.215 32.5v-2a.5.5 0 0 1 .5-.5h11.5a.5.5 0 0 1 .5.5V43a2 2 0 0 1-2 2h-8.5a2 2 0 0 1-2-2v-3m0-7.5h-6.5a1 1 0 0 0-1 1V39a1 1 0 0 0 1 1h6.5m0-7.5V40M45.715 15v12.5M13.215 15v7.5h6.25m6.25-7.5v7.5h-6.25m0 0V25"
      stroke={props.color ?? '#fff'}
      strokeWidth={3}
      strokeLinecap="round"
    />
  </Svg>
);

export default CoffeeMachine60;
