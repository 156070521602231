import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ProblemArrowIcon = (props: SvgProps) => (
  <Svg
    width={8}
    height={14}
    {...props}
  >
    <Path
      d="M.293 12.293a1 1 0 1 0 1.414 1.414L.293 12.293ZM7 7l.707.707a1 1 0 0 0 0-1.414L7 7ZM1.707.293A1 1 0 0 0 .293 1.707L1.707.293Zm0 13.414 6-6-1.414-1.414-6 6 1.414 1.414Zm6-7.414-6-6L.293 1.707l6 6 1.414-1.414Z"
      fill={props.stroke ?? '#696D70'}
    />
  </Svg>
);

export default ProblemArrowIcon;
