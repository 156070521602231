import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CloudIcon = (props: SvgProps) => (
    <Svg
        width={55}
        height={19}
        fill="none"
        {...props}
    >
        <Path
            d="M21.685 0C10.78 0 2.685 12.667 0 19h57c-3.47-13.68-15.076-9.078-20.446-5.067C36.141 9.29 29.12 0 21.684 0Z"
            fill="#fff"
        />
    </Svg>
);

export default CloudIcon;
