import AsyncStorage from '@react-native-async-storage/async-storage';

const getOverallPressed = async (
  startDate,
  endDate,
  groupid = undefined,
  templateid = undefined,
) => {
  try {
    // const jwtToken = await AsyncStorage.getItem('token');
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('jwt', jwtToken ?? '');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a');
    requestHeaders.set('Content-Type', 'application/json');

    const response = await fetch(
      `https://${apiDomain}/button/overallpressed`,
      {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify({
          start_date: startDate,
          end_date: endDate,
          groupid,
          templateid,
        }),
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default getOverallPressed;
