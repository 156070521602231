/* eslint-disable no-nested-ternary */
import { processFontFamily } from 'expo-font';
import React, { useEffect, useState, FC } from 'react';
import { Text, View } from 'react-native';
import {
  VictoryLine,
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import Colors from '../constants/Colors';
import { GetMetricByDate } from '../requests/GetMetricByDate';
import { GetProcessedElectricity } from '../requests/GetProcessedElectricity';
import GetUtilitiesMetricByDate from '../requests/GetUtilitiesMetricByDate';
import { axisDateLabel, tickValSamples, tooltipDateLabel } from './axisUtils';

type Props = {
  dates: { start: any; end: any };
  compareDates: { start: any; end: any };
  metricName: string;
  title: string;
  interval: string;
  compareToggled: boolean;
  indexName?: string;
  thingId?: string;
  unit?: string;
};
const CompareLineGraphWeb: FC<Props> = ({
  dates,
  compareDates,
  metricName,
  title,
  interval,
  compareToggled,
  indexName = 'happyscore_flat_permin',
  thingId,
  unit,
}) => {
  const [graphData, setGraphData] = useState([]);
  const [compareGraphData, setCompareGraphData] = useState([]);
  const [xArray2, setXArray2] = useState([]);
  const [xArray, setXArray] = useState([]);
  const [tickVals, setTickVals] = useState([]);
  const [tickVals2, setTickVals2] = useState([]);
  const [maxVal, setMaxVal] = useState(10);

  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState(null);
  const pointsArray = [];
  const comparePointsArray = [];
  let point = {};
  let comparePoint = {};

  const Score = [];
  const Score2 = [];

  useEffect(() => {
    setXArray2(compareGraphData?.map((e) => e.name));
    setXArray(graphData?.map((e) => e.name));
    const tickArray = [...Array(graphData.length).keys()];
    const tickArray2 = [...Array(compareGraphData.length).keys()];
    setTickVals(tickValSamples(tickArray, 7));
    setTickVals2(tickValSamples(tickArray2, 7));
    let maxScore = 10;
    graphData.forEach((element) => {
      if (element.Score > 1) {
        maxScore = element.Score;
      }
    });
    setMaxVal(maxScore);
  }, [compareGraphData, graphData]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (metricName === 'emissions') {
          GetUtilitiesMetricByDate(
            dates,
            null,
          ).then((data) => {
            let index = 0;
            if (data !== undefined) {
              data[0]?.forEach((e) => {
                Score.push(e.score.value);
                point = {
                  Score: Math.floor(((e.score.value * 179) / 1000) * 10) / 10,
                  index,
                  date: e.key_as_string,
                };
                pointsArray.push(point);
                index += 1;
              });
              setGraphData(pointsArray);
            } else {
              setGraphData([]);
            }
          });
        } else if (metricName === 'data-unitsconsumed') {
          GetProcessedElectricity(metricName, dates, interval, indexName, thingId)
            ?.then((data) => {
              data?.forEach((e, idx) => {
                Score.push(e.score.value);
                point = {
                  Score: e.score.value
                    ? Math.floor(
                      e.score.value * 10,
                    ) / 10
                    : 0,
                  index: idx,
                  date: e.key_as_string,
                };
                pointsArray.push(point);
              });
              setGraphData(pointsArray);
            });
        } else {
          GetMetricByDate(metricName, dates, interval, indexName, thingId)
            ?.then((data) => {
              let index = 0;
              if ((metricName === 'data-kwh-total' || metricName === 'data-totalkwh') && data) {
                data?.forEach((e, idx) => {
                  if (index > 0) {
                    Score.push(e.score.value);
                    point = {
                      Score: e.score.value
                        ? Math.floor(
                          e.score.value - data[index - 1].score.value,
                        )
                        : 0,
                      index: idx - 1,
                      date: e.key_as_string,
                    };
                    pointsArray.push(point);
                  }
                  index += 1;
                });
              } else if ((metricName === 'data-unitsconsumed') && data) {
                data?.forEach((e, idx) => {
                  Score.push(e.score.value);
                  point = {
                    Score: e.score.value
                      ? Math.floor(
                        e.score.value,
                      )
                      : 0,
                    index: idx - 1,
                    date: e.key_as_string,
                  };
                  pointsArray.push(point);
                  index += 1;
                });
              } else if (data) {
                data?.forEach((e) => {
                  Score.push(e.score.value);
                  point = {
                    Score: Math.floor(e.score.value * 10) / 10,
                    index,
                    date: e.key_as_string,
                  };
                  pointsArray.push(point);
                  index += 1;
                });
              }
              setGraphData(pointsArray);
            });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(e, e.stack);
      }
    };
    getData();
  }, [interval, dates, thingId, metricName]);
  useEffect(() => {
    const getData2 = async () => {
      try {
        if (metricName === 'emissions') {
          GetUtilitiesMetricByDate(
            compareDates,
            null,
          ).then((data) => {
            let index = 0;
            if (data !== undefined) {
              data[0]?.forEach((e) => {
                Score.push(e.score.value);
                point = {
                  Score: Math.floor(((e.score.value * 179) / 1000) * 10) / 10,
                  index,
                  date: e.key_as_string,
                  compare: true,
                };
                comparePointsArray.push(point);
                index += 1;
              });
              setCompareGraphData(comparePointsArray);
            } else {
              setCompareGraphData([]);
            }
          });
        } else if (metricName === 'data-unitsconsumed') {
          GetProcessedElectricity(metricName, compareDates, interval, indexName, thingId)
            ?.then((data) => {
              data?.forEach((e, idx) => {
                Score.push(e.score.value);
                point = {
                  Score: e.score.value
                    ? Math.floor(
                      e.score.value * 10,
                    ) / 10
                    : 0,
                  index: idx,
                  date: e.key_as_string,
                  compare: true,
                };
                comparePointsArray.push(point);
              });
              setCompareGraphData(comparePointsArray);
            });
        } else {
          GetMetricByDate(metricName, compareDates, interval, indexName)
            ?.then((data) => {
              let index = 0;
              if (data) {
                data?.forEach((e) => {
                  Score2.push(e.score.value);
                  comparePoint = {
                    Score: Math.floor(e.score.value * 10) / 10,
                    index,
                    date: e.key_as_string,
                    compare: true,
                  };
                  comparePointsArray.push(comparePoint);
                  index += 1;
                });
              }
              setCompareGraphData(comparePointsArray);
            });
        }
      } catch (error) {
        // console.error(error);
      }
    };
    getData2();
  }, [interval, compareDates, thingId, metricName]);
  class CustomFlyout extends React.Component {
    render() {
      // @ts-ignore
      const { x, y, datum } = this.props;
      const containerY: number = Math.max(0, y - 70);
      const dateLabel: string = tooltipDateLabel(datum.compare ? compareGraphData
        : graphData, datum.index);
      const containerWidth: number = Math.max(100, dateLabel.length * 6);
      const containerX: number = Math.max(20, x - containerWidth + 22);
      return (
        <svg>
          <defs>
            <filter id="f3" x={-0.5} y={-1} width="200%" height="250%">
              <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0"
              />
              <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="4" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" result="colored" />
            </filter>
          </defs>
          <rect
            x={containerX}
            y={containerY}
            width={containerWidth}
            height={60}
            stroke="none"
            rx={7}
            fill="white"
            filter="url(#f3)"
          />
          <text
            x={containerX + 5}
            y={containerY + 15}
            fontFamily="sans-serif"
            fontSize={10}
            strokeWidth={0.3}
            fill={'#696D70'}
            stroke={'#696D70'}
          >
            {/* @ts-ignore */}
            {this.props.title}
          </text>
          <text
            x={containerX + 5}
            y={containerY + 31}
            fontFamily="sans-serif"
            fontSize={10}
            strokeWidth={0.3}
            fill={'#696D70'}
            stroke={'#696D70'}
          >
            {dateLabel}
          </text>
        </svg>
      );
    }
  }
  const chartTheme = {
    axis: {
      stroke: 'none',
      style: {
        stroke: '#696D70',
        tickLabels: {
          fill: '#696D70',
        },
        grid: { stroke: 'none' },
      },
    },
  };

  return (
    <View>
      <VictoryChart
        singleQuadrantDomainPadding={{ x: false }}
        width={890}
        height={485}
        theme={chartTheme}
        domainPadding={{ x: [40, 40], y: [0, 0] }}
        padding={{
          top: 75,
          left: 60,
          right: 50,
          bottom: 130,
        }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis
          dependentAxis
          domain={{ y: [0, maxVal] }}
          crossAxis={false}
          offsetX={60}
          tickCount={5}
          fixLabelOverlap={true}
          style={{
            axis: { stroke: 'none' },
            tickLabels: { fontSize: 14, padding: 5 },
            grid: { stroke: Colors.light.hereworksWhite500, strokeWidth: 1, pointerEvents: 'none' },
          }}
          tickLabelComponent={
            <VictoryLabel
              style={{
                fill: Colors.light.hereworksBlack400,
                fontSize: 14,
                strokeWidth: 0,
                fontFamily: processFontFamily('Poppins_400Regular'),
                textAnchor: 'end',
              }}
            />
          }
          label={unit}
        />
        <VictoryAxis
          name="axis1"
          fixLabelOverlap={true}
          tickValues={tickVals}
          tickFormat={(x) => xArray[x]}
          tickCount={Math.min(Math.max(1, tickVals.length), 7)}
          style={{
            axis: { stroke: 'none' },
          }}
          tickLabelComponent={
            <VictoryLabel
              dy={18}
              style={{
                fontSize: 14,
                strokeWidth: 0,
                stroke: Colors.light.hereworksBlack400,
                fontFamily: processFontFamily('Poppins_400Regular'),
              }}
              text={({ datum }) => axisDateLabel(graphData, datum)}
            />
          }
        />
        <VictoryLine
          x="index"
          y="Score"
          data={graphData}
          interpolation="linear"
          style={{
            data: { stroke: '#003A70', strokeWidth: 2 },
          }}
        />
        <VictoryScatter
          x="index"
          y="Score"
          data={graphData}
          labels={({ datum }) => datum.Score}
          size={({ active }) => (active ? 5 : graphData.length > 10 ? 1.5 : 3)}
          style={{
            data: { fill: '#003A70' },
          }}
          labelComponent={
            <VictoryTooltip
              style={{
                // @ts-ignore
                fill: ({ datum }) => (datum.Score > 65 ? '#025157' : '#C07E00'),
                stroke: ({ datum }) => (datum.Score > 65 ? '#025157' : '#C07E00'),
                strokeWidth: 1.1,
                fontSize: 14,
              }}
              dy={-5}
              // @ts-ignore
              flyoutComponent={<CustomFlyout title={title} />}
            />
          }
        />
        {compareToggled && (
          <VictoryLine
            x="index"
            y="Score"
            data={compareGraphData}
            interpolation="linear"
            style={{
              data: { stroke: Colors.light.hereworksYellow500, strokeWidth: 2 },
            }}
          />
        )}
        {compareToggled && (
          <VictoryScatter
            data={compareGraphData}
            x="index"
            y="Score"
            labels={({ datum }) => datum.Score}
            size={({ active }) => (active ? 5 : compareGraphData.length > 10 ? 1.5 : 4)}
            labelComponent={
              <VictoryTooltip
                style={{
                  // @ts-ignore
                  fill: ({ datum }) => (datum.Score > 65 ? '#025157' : '#C07E00'),
                  stroke: ({ datum }) => (datum.Score > 65 ? '#025157' : '#C07E00'),
                  strokeWidth: 1.1,
                  fontSize: 14,
                }}
                dy={-5}
                // @ts-ignore
                flyoutComponent={<CustomFlyout title={title} />}
              />
            }
            style={{
              data: { fill: Colors.light.hereworksYellow500 },
            }}
          />
        )}
        {compareToggled && (
          <VictoryAxis
            name="axis2"
            fixLabelOverlap={true}
            tickValues={tickVals2}
            tickCount={Math.min(Math.max(1, tickVals2.length), 7)}
            tickFormat={(x) => xArray2[x]}
            domainPadding={{ x: [0, 0], y: 20 }}
            style={{
              axis: { stroke: 'none' },
            }}
            tickLabelComponent={
              <VictoryLabel
                dy={33}
                style={{
                  fill: Colors.light.hereworksYellow500,
                  fontSize: 14,
                  strokeWidth: 0,
                  fontFamily: processFontFamily('Poppins_400Regular'),
                }}
                text={({ datum }) => axisDateLabel(compareGraphData, datum)}
              />
            }
          />
        )}
      </VictoryChart>
      {!graphData.length && (!compareToggled || !compareGraphData.length) && (
        <View
          style={{
            position: 'absolute',
            alignSelf: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Text
            style={{
              color: Colors.light.hereworksBlack400,
              fontFamily: 'Roboto_400Regular',
              fontSize: 16,
            }}
          >
            No data available
          </Text>
        </View>
      )}
    </View>
  );
};

export default CompareLineGraphWeb;
