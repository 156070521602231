import React, { useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Modal from 'react-native-modal';
import Moment from 'moment';
import { FontAwesome } from '@expo/vector-icons';
import * as Analytics from 'expo-firebase-analytics';
import BinIcon24 from '../components/24pxIcons/BinIcon24';
import DateComponent from '../components/DateComponent';
import EditIcon from '../components/EditIcon';
import SelectButtonTile from '../components/SelectButtonTile';
import UpArrow from '../components/UpArrow';
import Colors from '../constants/Colors';
import { RootTabScreenProps } from '../types';
import MonitorIcon from '../components/MonitorIcon';
import DeskIcon24 from '../components/24pxIcons/DeskIcon24';
import HygieneIcon24 from '../components/24pxIcons/HygieneIcon24';
import PrinterIcon24 from '../components/24pxIcons/PrinterIcon24';
import CoffeeMachine24 from '../components/24pxIcons/CoffeeMachine24';
import InternetIcon24 from '../components/24pxIcons/InternetIcon24';
import MeetingRoom24 from '../components/24pxIcons/MeetingRoom24';
import DownloadIcon from '../components/DownloadIcon';
import StopwatchIcon from '../components/StopwatchIcon';
// import DownArrow from '../components/DownArrow';
import CompareArrowsIcon from '../components/CompareArrowsIcon';
import ChildrenBarGraph from '../graphs/ChildrenBarGraph';
import ChildrenBarGraphWeb from '../graphs/ChildrenBarGraphWeb';
import FlagIcon from '../components/16pxIcons/FlagIcon';
import PageNumbers from '../components/PageNumbers';
import CircleChart from '../components/CircleChart';
import OverallButtonsPressedGraph from '../graphs/OverallButtonsPressedGraph';
import SmallStopwatchIcon from '../components/SmallStopwatchIcon';
import ReportsBarGraphWeb from '../graphs/ReportsBarGraphWeb';
import ReportsBarGraphMobile from '../graphs/ReportsBarGraphMobile';
import updateTemplateSelection from '../requests/UpdateTemplateSelection';
import getTemplateSelection from '../requests/GetTemplateSelection';
import getAssetStats from '../requests/GetAssetStats';
import getButtonInstances from '../requests/GetButtonInstances';
import OverallButtonsPressedGraphWeb from '../graphs/OverallButtonsPressedGraphWeb';
import getOverallPressed from '../requests/GetOverallPressed';
import getSolvedProblemLogs from '../requests/GetSolvedProblemLogs';
import getPriorityStats from '../requests/GetPriorityStats';
import getMostFrequentTickets from '../requests/GetMostFrequentTickets';
import getSolvedProblems from '../requests/GetSolvedProblems';
import SearchIcon from '../components/SearchIcon';
import getLocations from '../requests/GetLocations';

// eslint-disable-next-line no-unused-vars
export default function AnalyticsScreen({ navigation }: RootTabScreenProps<'Analytics'>) {
  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });
  const renderMobile = (): boolean => Platform.OS !== 'web' || dimensions.window?.width < 500 || dimensions.window?.height < 500;
  const [modalVisible, setModalVisible] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(null);
  const [numberSelected, setNumberSelected] = useState(0);
  const [groupDropdownVisible, setGroupDropdownVisible] = useState(false);
  const [childDropdownVisible, setChildDropdownVisible] = useState(false);
  const [selectedFloorDropdownVisible, setSelectedFloorDropdownVisible] = useState(false);
  const [buildingDropdownVisible, setBuildingDropdownVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [buttonsButtonSelected, setButtonsButtonSelected] = useState(true);
  const [reportsButtonSelected, setReportsButtonSelected] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [savedTemplateSelection, setSavedTemplateSelection] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [compareAssets, setCompareAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [filteredByFloor, setFilteredByFloor] = useState([]);
  const [instances, setInstances] = useState([]);
  const [refreshTemplates, setRefreshTemplates] = useState(true);
  const [templateGroups, setTemplateGroups] = useState([]);
  const [overallPressed, setOverallPressed] = useState([]);
  const [groupInstances, setGroupInstances] = useState([]);
  const [overallPressedCompare, setOverallPressedCompare] = useState([]);
  const [groupInstancesCompare, setGroupInstancesCompare] = useState([]);
  const [solvedProblemLogs, setSolvedProblemLogs] = useState(null);
  const [mostFrequentTickets, setMostFrequentTickets] = useState(null);
  const [priorityStats, setPriorityStats] = useState(null);
  const [filteredSolvedProblems, setFilteredSolvedProblems] = useState(null);
  const [pages] = useState([]);
  const [solvedSearchValue, setSolvedSearchValue] = useState('');
  const [refreshPages, setRefreshPages] = useState(0);
  const [locations, setLocations] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  let numberRendered = 0;

  const showExport = false;

  const [range, setRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: Moment().subtract(6, 'days').startOf('day').toDate(), end: new Date() });

  const [compareRange, setCompareRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: null, end: null });

  React.useEffect(() => {
    let listCount = 0;
    Object.keys(filteredSolvedProblems ?? []).forEach((room) => {
      if (selectedLocation !== null) {
        if (selectedLocation?.Room === room) {
          // eslint-disable-next-line max-len
          listCount = filteredSolvedProblems[room].filter((problem) => problem.title.includes(solvedSearchValue)).length;
        }
      } else {
        // eslint-disable-next-line max-len
        listCount += filteredSolvedProblems[room].filter((problem) => problem.title.includes(solvedSearchValue)).length;
      }
    });
    setNumberOfPages(Math.ceil(listCount / 6));
  }, [filteredSolvedProblems, refreshPages, selectedLocation]);

  React.useEffect(() => setRefreshTemplates(true), [range, compareRange]);

  React.useEffect(() => {
    if (refreshTemplates) {
      setRefreshTemplates(false);
      getTemplateSelection(range.start, range.end, compareRange.start, compareRange.end).then(
        (result) => {
          if (result?.groups) {
            setNumberSelected(result?.groups?.length);
            setSelectedTemplates(result?.groups?.splice(0, 6));
          }
        },
      );
    }
  }, [range, compareRange, refreshTemplates]);

  React.useEffect(() => {
    templates.forEach((template) => {
      selectedTemplates.forEach((selectedTemplate) => {
        if (selectedTemplate.id === template.Id) {
          template.selected = true;
        }
      });
    });
  }, [templates, selectedTemplates]);

  React.useEffect(() => {
    getButtonInstances().then((result) => {
      const groups = [];
      const newTemplates = [];
      result?.instances?.forEach((instance) => {
        if (groups.find((item) => item.name === instance.ButtonGroupName) === undefined) {
          const group = {
            name: instance.ButtonGroupName,
            id: instance.ButtonGroupId,
          };
          if (group.name !== undefined) {
            groups.push(group);
          }
        }

        if (newTemplates.find((item) => item.Name === instance.ButtonPrototypeName) === undefined) {
          const template = {
            GroupId: instance.ButtonGroupId,
            Name: instance.ButtonPrototypeName,
            Id: instance.ButtonPrototypeId,
          };
          if (template.Name !== undefined) {
            newTemplates.push(template);
          }
        }
      });
      setTemplates(newTemplates);
      setTemplateGroups(groups);
      setInstances(result.instances);
    });
  }, [range, compareRange]);

  React.useEffect(() => {
    if (range.end) {
      getOverallPressed(range.start, range.end, selectedGroupId, selectedChild?.Id).then(
        (result) => {
          setOverallPressed(result?.instances ?? []);
          setGroupInstances(result?.groupInstances ?? []);
        },
      );
    }

    if (compareRange.end) {
      getOverallPressed(
        compareRange.start,
        compareRange.end,
        selectedGroupId,
        selectedChild?.Id,
      ).then((result) => {
        setOverallPressedCompare(result?.instances ?? []);
        setGroupInstancesCompare(result?.groupInstances ?? []);
      });
    }
  }, [range, compareRange, selectedGroupId, selectedChild]);

  React.useEffect(() => {
    if (range.end) {
      getSolvedProblemLogs(
        range.start,
        range.end,
        null,
        null,
        selectedGroupId,
        selectedChild?.Id,
      ).then(
        (result) => {
          setSolvedProblemLogs(result);
        },
      );
    }
  }, [range, compareRange, selectedGroupId, selectedChild]);

  const clearSelectedTemplates = () => {
    const templatesWithSelectionCleared = [];
    let selectedCount = 0;
    templates?.forEach((item) => {
      const itemCopy = { ...item };
      itemCopy.selected = false;
      savedTemplateSelection.forEach((template) => {
        if (template?.id === item.id && template.selected) {
          itemCopy.selected = true;
          selectedCount += 1;
        }
      });
      templatesWithSelectionCleared.push(itemCopy);
    });
    setNumberSelected(selectedCount);
    setTemplates(templatesWithSelectionCleared);
  };

  // eslint-disable-next-line func-names
  const groupBy = function <TItem> (xs: TItem[], key: string): { [key: string]: TItem[] } {
    return xs?.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  React.useEffect(() => {
    if (range.end) {
      getSolvedProblems(range.start, range.end, compareRange.start, compareRange.end).then(
        (result) => {
          const problems = [];
          const problemCount = result?.groups?.solvedTicketsValue?.problem?.length ?? 0;
          // eslint-disable-next-line max-len
          const comparedProblemCount = result?.groups?.solvedTicketsValueComparison?.compareproblem?.length ?? 0;
          const list = problemCount > comparedProblemCount
            ? result?.groups?.solvedTicketsValue?.problem
            : result?.groups?.solvedTicketsValueComparison?.compareproblem;
          (list ?? []).forEach((problem, index) => problems.push({
            title: problem,
            problem: result?.groups?.solvedTicketsValue?.problem[index],
            priority: result?.groups?.solvedTicketsValue?.priority[index],
            timeontask: result?.groups?.solvedTicketsValue?.timeontask[index],
            room: result?.groups?.solvedTicketsValue?.room[index],
            floor: result?.groups?.solvedTicketsValue?.floor[index],
            comparepriority: result?.groups?.solvedTicketsValueComparison?.comparepriority[index],
            compareproblem: result?.groups?.solvedTicketsValueComparison?.compareproblem[index],
            comparetimeontask:
                result?.groups?.solvedTicketsValueComparison?.comparetimeontask[index],
            compareroom: result?.groups?.solvedTicketsValueComparison?.compareroom[index],
            SLA: result?.groups?.solvedTicketsValue?.SLA[index] > 0
              ? `${result?.groups?.solvedTicketsValue?.SLA[index]} hrs`
              : 'No SLA',
          }));

          const grouped = groupBy(problems, 'room');
          setFilteredSolvedProblems(grouped);
        },
      );
    }
  }, [range, compareRange]);

  React.useEffect(() => {
    if (range.end) {
      getMostFrequentTickets(range.start, range.end, compareRange.start, compareRange.end).then(
        (result) => {
          setMostFrequentTickets(result);
        },
      );
    }
  }, [range, compareRange]);

  React.useEffect(() => {
    if (range.end) {
      getPriorityStats(
        range.start,
        range.end,
        compareRange.start,
        compareRange.end,
        selectedGroupId,
        selectedChild?.Id,
      ).then((result) => {
        // console.log(result.groups);
        setPriorityStats(result?.groups);
      });
    }
  }, [range, compareRange, selectedGroupId, selectedChild]);

  React.useEffect(() => {
    getAssetStats(range.start, range.end).then((result) => {
      // console.log(result);
      const assetArray = [];
      result?.instances?.forEach((r) => {
        r.instances.forEach((i) => {
          const { value } = i.count;
          assetArray.push({ name: r.key, value: value ?? 0, id: i.key });
        });
      });
      assetArray?.sort((a, b) => b.value - a.value);
      assetArray?.forEach((asset, index) => {
        asset.key = index + 1;
      });
      setAssets(assetArray);
      setFilteredAssets(assetArray);
      const byFloor = Object.assign([], assetArray);
      setFilteredByFloor(byFloor);
      pages.splice(0);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < assetArray.length; i++) {
        // console.log(`${i} ${pages.length * 20}`);
        if (i > pages.length * 20) {
          pages.push(pages.length);
        }
      }
    });
  }, [range]);

  React.useEffect(() => {
    getAssetStats(compareRange.start, compareRange.end).then((result) => {
      // console.log(result);
      const assetArray = [];
      result?.instances?.forEach((r) => {
        r.instances.forEach((i) => {
          const { value } = i.count;
          assetArray.push({ name: r.key, value: value ?? 0, id: i.key });
        });
      });
      setCompareAssets(assetArray);
    });
  }, [compareRange]);

  React.useEffect(() => {
    getLocations().then((response) => {
      if (response?.locations?.length > 0) {
        setLocations(response.locations);
      }
    });
  }, []);

  const onConfirm = React.useCallback(
    (start, end) => {
      setRange({ start, end });
    },
    [setRange],
  );

  const onCompareConfirm = React.useCallback(
    (start, end) => {
      setCompareRange({ start, end });
    },
    [setCompareRange],
  );

  const scrollView = React.useRef<null | ScrollView>(null);

  const handleOnScroll = (event) => {
    setScrollOffset(event?.nativeEvent?.contentOffset?.y);
  };
  const handleScrollTo = (p) => {
    if (scrollView.current) {
      scrollView.current.scrollTo(p);
    }
  };

  const getIcon = (category: string) => {
    switch (category) {
      case 'Equipment':
        return <MonitorIcon color={Colors.light.hereworksLightBlue800} />;
      case 'Desks':
        return <DeskIcon24 />;
      case 'Toilet':
        return <HygieneIcon24 />;
      case 'Printer':
        return <PrinterIcon24 />;
      case 'Coffee machine':
        return <CoffeeMachine24 />;
      case 'Internet':
        return <InternetIcon24 />;
      case 'Meeting room':
        return <MeetingRoom24 />;
      case 'Bin':
        return <BinIcon24 />;
      default:
        return <MeetingRoom24 />;
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'urgent':
        return Colors.light.hereworksRed800;
      case 'high':
        return Colors.light.hereworksYellow900;
      case 'low':
        return Colors.light.hereworksGreen500;
      default:
        return Colors.light.hereworksBlue600;
    }
  };

  const renderGraphButtons = () => selectedChild && (
      <View
        style={{
          flexDirection: 'row',
          marginLeft: renderMobile() ? 0 : 24,
          marginTop: renderMobile() ? 24 : 0,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            setButtonsButtonSelected(true);
            if (!buttonsButtonSelected) {
              setReportsButtonSelected(false);
            }
            Analytics.logEvent('button_press', {
              screen: 'Analytics',
              name: 'Selected buttons button',
            });
          }}
          style={{
            backgroundColor: buttonsButtonSelected ? Colors.light.hereworksBlue500 : '#fff',
            borderColor: buttonsButtonSelected
              ? Colors.light.hereworksBlue500
              : Colors.light.hereworksBlue300,
            borderWidth: 1,
            marginRight: 8,
            paddingHorizontal: 12,
            paddingVertical: 4,
            borderRadius: 4,
          }}
        >
          <Text
            style={[
              styles.graphButtonText,
              {
                color: buttonsButtonSelected ? '#fff' : Colors.light.hereworksBlue400,
              },
            ]}
          >
            TEMPLATES
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setReportsButtonSelected(true);
            if (!reportsButtonSelected) {
              setButtonsButtonSelected(false);
            }
            Analytics.logEvent('button_press', {
              screen: 'Analytics',
              name: 'Selected reports button',
            });
          }}
          style={{
            backgroundColor: reportsButtonSelected ? Colors.light.hereworksBlue500 : '#fff',
            borderColor: reportsButtonSelected
              ? Colors.light.hereworksBlue500
              : Colors.light.hereworksBlue300,
            borderWidth: 1,
            marginRight: 8,
            paddingHorizontal: 12,
            paddingVertical: 4,
            borderRadius: 4,
          }}
        >
          <Text
            style={[
              styles.graphButtonText,
              {
                color: reportsButtonSelected ? '#fff' : Colors.light.hereworksBlue400,
              },
            ]}
          >
            TICKETS
          </Text>
        </TouchableOpacity>
      </View>
  );

  function getChangeColor(change) {
    if (change === 0) {
      return Colors.light.hereworksBlack400;
    }
    return change < 0 ? Colors.light.hereworksCheckGreen : Colors.light.hereworksCoral;
  }

  const buttonTile = (
    title: string,
    value: number,
    compareValue: number,
    change: number,
    key: number,
  ) => (
    <View
      key={key}
      style={[
        {
          borderRadius: 10,
          backgroundColor: '#fff',
          alignSelf: 'flex-start',
          marginTop: 8,
          marginLeft: renderMobile() ? 8 : 12,
          marginBottom: 16,
        },
        styles.shadow,
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          marginTop: 8,
          marginLeft: renderMobile() ? 8 : 16,
          alignItems: 'center',
        }}
      >
        {getIcon(title?.trim())}
        <Text
          style={{
            color: Colors.light.hereworksPrimaryGrey,
            marginHorizontal: 8,
            marginVertical: 9,
            fontSize: 14,
            fontFamily: 'Roboto_400Regular',
          }}
        >
          {title}
        </Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Text
          style={{
            color:
              compareRange.start !== null
                ? Colors.light.hereworksBlue500
                : Colors.light.hereworksPrimaryGrey,
            marginHorizontal: renderMobile() ? 8 : 10,
            marginVertical: 4,
            marginBottom: 8,
            fontFamily: 'Poppins_600SemiBold',
            fontSize: 48,
          }}
        >
          {value}
        </Text>
        {compareRange.start !== null && (
          <Text
            style={{
              color: Colors.light.hereworksYellow500,
              marginLeft: renderMobile() ? 3 : 0,
              marginVertical: 4,
              marginBottom: 8,
              fontFamily: 'Poppins_600SemiBold',
              fontSize: 48,
            }}
          >
            {compareValue}
          </Text>
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginHorizontal: renderMobile() ? 8 : 16,
          marginBottom: 8,
        }}
      >
        {change !== 0 && (
          <UpArrow
            color={change < 0 ? Colors.light.hereworksCheckGreen : Colors.light.hereworksCoral}
            style={
              change < 0
                ? { transform: [{ rotateZ: '180deg' }] }
                : { transform: [{ rotateZ: '0deg' }] }
            }
          />
        )}
        <Text
          style={{
            color: getChangeColor(change),
            marginVertical: 8,
            marginRight: 4,
            marginLeft: 2,
            fontSize: 14,
            fontFamily: 'Roboto_400Regular',
          }}
        >
          {change === 0 && '~'}
          {change}%
        </Text>
        <Text
          style={{
            color: Colors.light.hereworksPrimaryGrey,
            marginVertical: 8,
            marginRight: 8,
            fontSize: 14,
            fontFamily: 'Roboto_400Regular',
          }}
        >
          from yesterday
        </Text>
      </View>
    </View>
  );

  const compareDatesView = () => {
    if (Moment(range.start).isSame(range.end, 'date')) {
      return (
        <View style={{ marginHorizontal: 24, flexDirection: 'row' }}>
          <Text
            style={{
              color: Colors.light.hereworksBlack600,
              fontSize: 12,
              fontFamily: 'Roboto_500Medium',
            }}
          >
            {Moment(range.start).format('D MMM')}
          </Text>
          {compareRange.start && (
            <>
              <CompareArrowsIcon style={{ marginHorizontal: 10 }} />
              <Text
                style={{
                  color: Colors.light.hereworksYellow500,
                  fontSize: 12,
                  fontFamily: 'Roboto_500Medium',
                }}
              >
                {Moment(compareRange.start).format('D MMM')}
              </Text>
            </>
          )}
        </View>
      );
    }
    return (
      <View style={{ marginHorizontal: 24, flexDirection: 'row' }}>
        <Text
          style={{
            color: Colors.light.hereworksBlack600,
            fontSize: 12,
            fontFamily: 'Roboto_500Medium',
          }}
        >
          {Moment(range.start).format('D MMM')} {' - '}{' '}
          {Moment(range.end ?? range.start).format('D MMM')}
        </Text>
        {compareRange.start && (
          <>
            <CompareArrowsIcon style={{ marginHorizontal: 10 }} />
            <Text
              style={{
                color: Colors.light.hereworksYellow500,
                fontSize: 12,
                fontFamily: 'Roboto_500Medium',
              }}
            >
              {Moment(compareRange.start).format('D MMM')} {' - '}{' '}
              {Moment(compareRange.end ?? compareRange.start).format('D MMM')}
            </Text>
          </>
        )}
      </View>
    );
  };

  // const averageValueChange = (value: number) => {
  //   if (value < 0) {
  //     return (
  //       <View style={styles.averageChangeContainer}>
  //         <DownArrow color={Colors.light.hereworksGreen}
  //            style={{ transform: [{ scale: 0.8 }] }} />
  //         <Text style={[styles.averageValueChange, { color: Colors.light.hereworksGreen }]}>
  //           {value}%
  //         </Text>
  //       </View>
  //     );
  //   }
  //   return (
  //     <View style={styles.averageChangeContainer}>
  //       <UpArrow color={Colors.light.hereworksRed} style={{ transform: [{ scale: 0.8 }] }} />
  //       <Text style={[styles.averageValueChange, { color: Colors.light.hereworksRed }]}>
  //         +{value}%
  //       </Text>
  //     </View>
  //   );
  // };

  const renderChildrenCard = () => (
    <View
      style={[
        styles.commonCardStyle,
        {
          height: renderMobile() ? undefined : 520,
          zIndex: -1,
          marginBottom: 24,
        },
        renderMobile() ? { width: '100%', paddingHorizontal: 20 } : {},
      ]}
    >
      <Text
        style={{
          fontFamily: 'Poppins_400Regular',
          fontSize: 22,
          color: Colors.light.hereworksPrimaryGrey,
          marginLeft: renderMobile() ? 0 : 24,
          marginTop: 18,
          marginRight: renderMobile() ? 0 : 480,
        }}
      >
        Tickets raised per asset
      </Text>
      {compareDatesView()}
      <TouchableOpacity
        style={[
          styles.dropdownBox,
          {
            marginLeft: renderMobile() ? 0 : 17,
          },
          renderMobile()
            ? { width: '100%', zIndex: 0 }
            : { position: 'absolute', right: 24, top: 5 },
        ]}
        onPress={() => {
          setSelectedFloorDropdownVisible(!selectedFloorDropdownVisible);
          Analytics.logEvent('button_press', {
            screen: 'Analytics',
            name: `Floor dropdown ${selectedFloorDropdownVisible ? 'Close' : 'Open'}`,
          });
        }}
      >
        <Text style={styles.dropdownBoxText}>{selectedFloor ?? 'Choose floor'}</Text>
        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <FontAwesome
            size={20}
            style={{
              color: Colors.light.hereworksLightBlue,
              marginRight: 12,
            }}
            name={selectedFloorDropdownVisible ? 'angle-up' : 'angle-down'}
          />
        </View>
        {selectedFloorDropdownVisible && (
          <View style={[styles.dropdownView, renderMobile() ? { width: '100%', top: 50 } : {}]}>
            {FLOORS?.map((floor, key) => (
              <TouchableOpacity
                key={key}
                onPress={() => {
                  setSelectedFloor(floor.title);
                  if (floor.title === 'All') {
                    setFilteredByFloor(filteredAssets);
                  } else {
                    assets.forEach((asset) => {
                      const instance = instances.filter((i) => i.ButtonInstanceId === asset.id)[0];
                      asset.Floor = instance?.LocationFloor;
                    });
                    const filtered = filteredAssets.filter((asset) => asset.Floor === floor.title);
                    setFilteredByFloor(filtered);
                  }
                  // console.log(assets);
                  setSelectedFloorDropdownVisible(!selectedFloorDropdownVisible);
                  Analytics.logEvent('button_press', {
                    screen: 'Analytics',
                    name: `Selected floor: ${floor.title}`,
                  });
                }}
              >
                <Text style={styles.dropdownItemText}>{floor.title}</Text>
              </TouchableOpacity>
            ))}
          </View>
        )}
      </TouchableOpacity>
      {renderMobile() ? (
        <ScrollView horizontal={true} style={{ zIndex: -1 }}>
          <ChildrenBarGraph
            compareToggled={compareRange.start !== null}
            assets={filteredByFloor?.filter((asset) => instances?.filter((instance) => instance
              .ButtonInstanceId === asset.id)?.length)?.splice(0, 20)}
            compareAssets={compareAssets?.filter((asset) => instances?.filter((instance) => instance
              .ButtonInstanceId === asset.id)?.length)?.splice(0, 20)}
            instances={instances}
          />
        </ScrollView>
      ) : (
        <ChildrenBarGraphWeb
          compareToggled={compareRange.start !== null}
          assets={filteredByFloor?.filter((asset) => instances?.filter((instance) => instance
            .ButtonInstanceId === asset.id)?.length)?.splice(0, 20)}
          compareAssets={compareAssets?.filter((asset) => instances?.filter((instance) => instance
            .ButtonInstanceId === asset.id)?.length)?.splice(0, 20)}
          instances={instances}
          selectedGroup={selectedGroup}
          width={dimensions.window?.width}
          height={dimensions.window?.height}
        />
      )}
    </View>
  );

  const problemDivider = () => (
    <View
      style={{
        width: 1,
        height: 30,
        backgroundColor: Colors.light.hereworksWhite500,
      }}
    />
  );

  const headerDivider = () => (
    <View
      style={{
        width: 1,
        height: 15,
        backgroundColor: Colors.light.hereworksWhite500,
      }}
    />
  );

  const problemHeaders = () => (
    <View style={{ flexDirection: 'row', marginTop: renderMobile() ? 12 : 51 }}>
      <Text style={[styles.headerText, { marginLeft: renderMobile() ? 20 : 42, width: 173 }]}>
        TICKETS
      </Text>
      {headerDivider()}
      <Text style={[styles.headerText, { marginLeft: 16, width: 94 }]}>PRIORITY</Text>
      {headerDivider()}
      <Text style={[styles.headerText, { marginHorizontal: 16, width: 56 }]}>SLA TIME</Text>
      {headerDivider()}
      <Text style={[styles.headerText, { marginLeft: 16, marginRight: 14, width: 76 }]}>
        TIME ON TASK
      </Text>
      {headerDivider()}
      <Text
        style={[styles.headerText, { marginLeft: 16, marginRight: 6, width: 75 }]}
        numberOfLines={1}
      >
        LOCATION
      </Text>
    </View>
  );

  // eslint-disable-next-line max-len
  const renderProblem = (problem, key, compare = false) => (compare ? problem.comparetimeontask : problem.timeontask) && (
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: compare
            ? Colors.light.hereworksYellow50
            : Colors.light.hereworksWhite100,
          marginHorizontal: renderMobile() ? 0 : 20,
          marginBottom: 8,
          borderRadius: 10,
          alignItems: 'center',
        }}
      >
        <View style={{ width: 190, marginRight: 5 }}>
          <Text
            style={{
              fontFamily: 'Roboto_400Regular',
              fontSize: 14,
              color: Colors.light.hereworksBlack600,
              marginLeft: 18,
              marginTop: 18,
            }}
            numberOfLines={1}
          >
            {compare ? problem.compareproblem : problem.title}
          </Text>
          <Text
            style={{
              fontFamily: 'Roboto_500Medium',
              fontSize: 12,
              color: Colors.light.hereworksBlack600,
              marginLeft: 18,
              marginTop: 4,
              marginBottom: 8,
            }}
            numberOfLines={1}
          >
            {Moment(compare ? compareRange.start : range.start).format('D MMM')} {' - '}{' '}
            {Moment(compare ? compareRange.end : range.end ?? range.start).format('D MMM, YYYY')}
          </Text>
        </View>
        {problemDivider()}
        <View style={{ width: 110 }}>
          <View
            style={{
              borderWidth: 1,
              borderColor: getPriorityColor(problem.priority),
              borderRadius: 4,
              height: 22,
              alignSelf: 'flex-start',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 16,
            }}
          >
            <FlagIcon color={getPriorityColor(problem.priority)} style={{ marginLeft: 2 }} />
            <Text
              style={{
                fontFamily: 'Roboto_400Regular',
                fontSize: 14,
                lineHeight: 22,
                color: getPriorityColor(problem.priority),
                marginHorizontal: 4,
              }}
              numberOfLines={1}
            >
              {compare ? problem.comparepriority : problem.priority}
            </Text>
          </View>
        </View>
        {problemDivider()}
        <Text
          style={{
            fontFamily: 'Roboto_400Regular',
            fontSize: 14,
            color: Colors.light.hereworksBlack600,
            marginHorizontal: 16,
            marginVertical: 18,
            width: 58,
          }}
          numberOfLines={1}
        >
          {problem.SLA}
        </Text>
        {problemDivider()}
        <Text
          style={{
            fontFamily: 'Roboto_400Regular',
            fontSize: 14,
            color: Colors.light.hereworksBlack600,
            marginHorizontal: 16,
            marginVertical: 18,
            width: 76,
          }}
        >
          {compare ? problem.comparetimeontask ?? '00:00:00' : problem.timeontask ?? '00:00:00'}
        </Text>
        {problemDivider()}
        <Text
          style={{
            fontFamily: 'Roboto_400Regular',
            fontSize: 14,
            color: Colors.light.hereworksBlack600,
            marginHorizontal: 16,
            marginVertical: 18,
            width: 75,
          }}
          numberOfLines={1}
        >
          {problem.floor}
        </Text>
      </View>
  );

  const renderProblems = () => (
    <View style={{ zIndex: -1 }}>
      {problemHeaders()}
      {(selectedLocation === null ? Object.keys(filteredSolvedProblems ?? [])
        : [selectedLocation?.Room]).map((room, key) => {
        const skipNumber = 6 * (selectedPage - 1);
        return (
          <View key={key}>
            {filteredSolvedProblems[room]?.map((problem, index) => {
              numberRendered += 1;
              if (selectedLocation !== null && room !== selectedLocation?.Room) {
                return null;
              }
              if (numberRendered <= skipNumber) {
                return null;
              }
              if (numberRendered <= 6 * selectedPage) {
                if (!problem.title.toLowerCase().includes(solvedSearchValue.toLowerCase())) {
                  return null;
                }
                return (
                  <View key={`${problem.title} ${index}`}>
                    {index === 0 && (
                      <Text
                        style={{
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 16,
                          color: Colors.light.hereworksBlack600,
                          marginLeft: 24,
                          marginTop: 24,
                          marginBottom: 12,
                        }}
                      >
                        {room}
                      </Text>
                    )}
                    {renderProblem(problem, `${problem.title}`, false)}
                    {compareRange.start !== null
                      && renderProblem(problem, `${problem.title}`, true)}
                  </View>
                );
              }
              return null;
            })}
          </View>
        );
      })}
    </View>
  );

  const solvedTicketsBuilding = () => (
    <View style={renderMobile() ? { width: '100%', zIndex: 0 } : { marginLeft: 8 }}>
      <TouchableOpacity
        style={[
          styles.dropdownBox,
          {
            marginLeft: renderMobile() ? 0 : 17,
            width: renderMobile() ? '100%' : undefined,
          },
        ]}
        onPress={() => {
          setBuildingDropdownVisible(!buildingDropdownVisible);
          Analytics.logEvent('button_press', {
            screen: 'Analytics',
            name: `Building dropdown ${buildingDropdownVisible ? 'Close' : 'Open'}`,
          });
        }}
      >
        <Text style={styles.dropdownBoxText}>{selectedLocation?.Room ?? 'Choose location'}</Text>
        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <FontAwesome
            size={20}
            style={{
              color: Colors.light.hereworksLightBlue,
              marginRight: 12,
            }}
            name={buildingDropdownVisible ? 'angle-up' : 'angle-down'}
          />
        </View>
      </TouchableOpacity>
      {buildingDropdownVisible && (
          <View
            style={[
              renderMobile() ? styles.dropdownViewMobile : styles.dropdownView,
              renderMobile() ? { } : { marginBottom: 20, height: 200, marginLeft: 17 },
            ]}
          >
          <ScrollView
            nestedScrollEnabled={true}
            indicatorStyle={'black'}
          >
            <TouchableOpacity
              onPress={() => {
                setSelectedLocation(null);
                setBuildingDropdownVisible(!buildingDropdownVisible);
                Analytics.logEvent('button_press', {
                  screen: 'Analytics',
                  name: 'Selected All locations',
                });
              }}
            >
              <Text style={styles.dropdownItemText}>All locations</Text>
            </TouchableOpacity>
            {(locations ?? []).map((location, key) => (
              <TouchableOpacity
                key={key}
                onPress={() => {
                  if (location === 'All locations') {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(location);
                  }
                  setBuildingDropdownVisible(!buildingDropdownVisible);
                  Analytics.logEvent('button_press', {
                    screen: 'Analytics',
                    name: `Selected building ${location.Room}`,
                  });
                }}
              >
                <Text style={styles.dropdownItemText}>{location.Room}</Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
          </View>
      )}
    </View>
  );

  const solvedTicketsSearch = () => (
    <View style={[styles.searchContainer, { flex: renderMobile() ? undefined : 1 }]}>
      <TextInput
        // @ts-ignore
        style={[{ flex: 1 }, !renderMobile() ? { outlineStyle: 'none' } : {}]}
        placeholder="Search"
        value={solvedSearchValue}
        onChange={(text) => {
          setSolvedSearchValue(text.nativeEvent.text);
          setRefreshPages(refreshPages + 1);
        }}
        placeholderTextColor={Colors.light.hereworksBlack400}
      />
      <SearchIcon style={{ marginLeft: 14 }} />
    </View>
  );

  const renderSolvedProblems = () => (
    <View
      style={[
        styles.commonCardStyle,
        {
          zIndex: -1,
          marginBottom: 24,
          minHeight: 724,
        },
        renderMobile() ? { width: '100%', paddingHorizontal: 20 } : {},
      ]}
    >
      <Text
        style={{
          fontFamily: 'Poppins_400Regular',
          fontSize: 22,
          color: Colors.light.hereworksBlack600,
          marginLeft: renderMobile() ? 0 : 24,
          marginTop: 18,
        }}
      >
        Solved tickets
      </Text>
      {compareDatesView()}
      {!renderMobile() && (
        <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
          {solvedTicketsSearch()}
          {solvedTicketsBuilding()}
        </View>
      )}
      {renderMobile() && solvedTicketsSearch()}
      {renderMobile() && solvedTicketsBuilding()}
      {renderMobile() && (
        <ScrollView horizontal={true} style={{ zIndex: -1 }}>
          {renderProblems()}
        </ScrollView>
      )}
      {!renderMobile() && renderProblems()}
      {numberOfPages > 1 && (
        <View style={{
          alignItems: 'center', width: '100%', paddingBottom: 62, zIndex: -1,
        }}>
          <ScrollView
            horizontal={true}
            style={{
              zIndex: -1,
              position: 'absolute',
              left: 0,
              right: 0,
              width: '100%',
            }}
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: 'center',
            }}
          >
            <PageNumbers
              mobile={renderMobile()}
              numberOfPages={numberOfPages}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </ScrollView>
        </View>
      )}
    </View>
  );

  const renderLocationGroup = (compare: boolean) => (
    <View style={{ flex: 1 }}>
      <View
        style={{
          height: 1,
          marginHorizontal: renderMobile() ? 0 : 24,
          marginBottom: 16,
        }}
      />
      <View
        style={{
          borderRadius: 10,
          marginHorizontal: renderMobile() ? 0 : 24,
          marginBottom: 20,
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.locationHeader, { flex: 1, marginLeft: 8 }]}>#</Text>
          <Text style={[styles.locationHeader, { flex: 4, marginRight: 8 }]}>ROOM</Text>
          <Text style={[styles.locationHeader, { flex: 3 }]}>ASSET</Text>
        </View>
        <View style={{ marginHorizontal: 4, marginVertical: 8 }}>
          {mostFrequentTickets !== null
            && mostFrequentTickets.groups?.asset?.map((_, key) => (
              <View
                style={{
                  flexDirection: 'row',
                  margin: 4,
                  paddingBottom: 4,
                  borderColor: Colors.light.hereworksWhite500,
                  borderBottomWidth: 1,
                }}
                key={key}
              >
                <View style={{ flex: 1 }}>
                  <Text style={styles.locationValue}>{compare ? '' : key + 1}</Text>
                </View>
                <View style={{ flex: 4, marginRight: 8 }}>
                  <Text style={styles.locationValue}>{mostFrequentTickets.groups?.room[key]}</Text>
                </View>
                <View style={{ flex: 3 }}>
                  <Text style={styles.locationValue} numberOfLines={1}>
                    {mostFrequentTickets.groups?.asset[key]}
                  </Text>
                </View>
              </View>
            ))}
        </View>
      </View>
    </View>
  );

  const renderLocation = () => (
    <View
      style={[
        styles.commonCardStyle,
        {
          flex: renderMobile() ? 1 : undefined,
          zIndex: -2,
          marginBottom: 24,
          marginHorizontal: renderMobile() ? 0 : 24,
        },
        renderMobile() ? { width: '100%', paddingHorizontal: 20 } : {},
      ]}
    >
      <Text
        style={{
          fontFamily: 'Poppins_400Regular',
          fontSize: 22,
          color: Colors.light.hereworksBlack600,
          marginHorizontal: renderMobile() ? 0 : 24,
          marginTop: 18,
        }}
      >
        Most frequent tickets raised
      </Text>
      {compareDatesView()}
      <ScrollView horizontal={true} contentContainerStyle={{ flex: 1 }}>
        {renderLocationGroup(false)}
      </ScrollView>
    </View>
  );

  const renderPriorityDesc = (color, text, value, compareValue) => (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'stretch',
        marginBottom: 4,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
        <View
          style={{
            backgroundColor: color,
            width: 16,
            height: 16,
            borderRadius: 2,
            marginRight: 8,
          }}
        />
        <Text style={[styles.locationValue, { marginRight: 12 }]} numberOfLines={1}>
          {text}
        </Text>
      </View>
      <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
        <Text style={styles.priorityValues}>{value}%</Text>
        {compareRange.start !== null && (
          <>
            <Text style={styles.vsText}>VS</Text>
            <Text style={styles.priorityValues}>{compareValue}%</Text>
          </>
        )}
      </View>
    </View>
  );

  const renderPriorityStatistics = () => {
    const urgent = priorityStats?.urgent ?? 0;
    const high = priorityStats?.high ?? 0;
    const normal = priorityStats?.moderate ?? 0;
    const low = 100 - (priorityStats?.urgent ?? 0)
    - (priorityStats?.high ?? 0)
    - (priorityStats?.moderate ?? 0);
    const compareUrgent = priorityStats?.compareUrgent ?? 0;
    const compareHigh = priorityStats?.compareHigh ?? 0;
    const compareNormal = priorityStats?.compareModerate ?? 0;
    const compareLow = 100 - (priorityStats?.compareUrgent ?? 0)
    - (priorityStats?.compareHigh ?? 0)
    - (priorityStats?.compareModerate ?? 0);

    return (
      <View
        style={[
          styles.commonCardStyle,
          {
            zIndex: -1,
            marginBottom: 24,
            marginHorizontal: renderMobile() ? 0 : 24,
          },
          renderMobile() ? { width: '100%', paddingHorizontal: 20 } : { marginTop: 24 },
        ]}
      >
        <Text
          style={{
            fontFamily: 'Poppins_400Regular',
            fontSize: 22,
            color: Colors.light.hereworksBlack600,
            marginLeft: renderMobile() ? 0 : 24,
            marginTop: 18,
          }}
        >
          Priority statistics
        </Text>
        {compareDatesView()}
        <View style={{ flex: 1 }}>
          <View
            style={[
              {
                alignItems: 'center',
                justifyContent: 'center',
                marginVertical: 27,
              },
              renderMobile() ? { flex: 1 } : {},
            ]}
          >
            <CircleChart
              low={low}
              normal={normal}
              high={high}
              urgent={urgent}
              compareLow={compareLow}
              compareNormal={compareNormal}
              compareHigh={compareHigh}
              compareUrgent={compareUrgent}
              compareToggled={compareRange.start !== null}
            />
            <View
              style={{
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
                top: 94,
              }}
            >
              {compareRange.start === null && (
                <Text style={styles.priorityHours}>{priorityStats?.total}</Text>
              )}
              {compareRange.start !== null && (
                <Text style={styles.priorityCompareHours}>
                  {`${priorityStats?.total}/${priorityStats?.compareTotal}`}
                </Text>
              )}
              <Text
                style={[
                  styles.locationValue,
                  {
                    textAlign: 'center',
                  },
                ]}
                numberOfLines={1}
              >
                Tickets
              </Text>
            </View>
            {renderMobile() && (
              <View
                style={{
                  marginLeft: 0,
                  marginTop: 27,
                  marginBottom: 20,
                  alignSelf: 'stretch',
                }}
              >
                <Text style={[styles.locationValue, { marginBottom: 12 }]}>Problem solved</Text>
                {renderPriorityDesc(
                  Colors.light.hereworksRed500,
                  'Critical',
                  urgent,
                  compareUrgent,
                )}
                {renderPriorityDesc(Colors.light.hereworksYellow500, 'High', high, compareHigh)}
                {renderPriorityDesc(
                  Colors.light.hereworksBlue500,
                  'Moderate',
                  normal,
                  compareNormal,
                )}
                {renderPriorityDesc(Colors.light.hereworksBlue200, 'Low', low, compareLow)}
              </View>
            )}
          </View>
          {!renderMobile() && (
            <View
              style={{
                marginHorizontal: 20,
                marginVertical: 27,
                flex: 1,
              }}
            >
              <Text style={[styles.locationValue, { marginBottom: 12 }]}>Problem solved</Text>
              {renderPriorityDesc(Colors.light.hereworksRed500, 'Critical', urgent, compareUrgent)}
              {renderPriorityDesc(Colors.light.hereworksYellow500, 'High', high, compareHigh)}
              {renderPriorityDesc(Colors.light.hereworksBlue500, 'Moderate', normal, compareNormal)}
              {renderPriorityDesc(Colors.light.hereworksBlue200, 'Low', low, compareLow)}
            </View>
          )}
        </View>
      </View>
    );
  };

  const parseValue = (value) => {
    const val = Number.parseInt(value, 10);
    if (Number.isNaN(val)) {
      return 0;
    }
    return val;
  };

  const renderTimeOnTask = () => (
    <View
      style={[
        styles.commonCardStyle,
        {
          flex: renderMobile() ? 3 : undefined,
          marginVertical: 24,
          marginHorizontal: renderMobile() ? 0 : 24,
          paddingHorizontal: 20,
          zIndex: -1,
        },
        renderMobile() ? { width: '100%' } : {},
      ]}
    >
      <Text
        style={{
          fontFamily: 'Poppins_400Regular',
          fontSize: 22,
          color: Colors.light.hereworksPrimaryGrey,
          marginTop: 18,
          marginBottom: 23,
        }}
      >
        Time on task
      </Text>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
        {renderMobile() ? <SmallStopwatchIcon /> : <StopwatchIcon />}
        <View style={{ flexDirection: 'row', alignItems: 'flex-end', marginLeft: 16 }}>
          <Text style={renderMobile() ? styles.taskTimeValueMobile : styles.taskTimeValue}>
            {parseValue(solvedProblemLogs?.groups?.average.split(':')[0] ?? 0)}
          </Text>
          <Text style={styles.taskTimeDescription}>Hrs</Text>
          <Text style={renderMobile() ? styles.taskTimeValueMobile : styles.taskTimeValue}>
            {parseValue(solvedProblemLogs?.groups?.average.split(':')[1] ?? 0)}
          </Text>
          <Text style={styles.taskTimeDescription}>Min</Text>
        </View>
      </View>
      <Text style={styles.taskAverageText}>On average</Text>
      <View style={styles.divider} />
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1, marginBottom: 10 }}>
          <Text style={[styles.averageTitles, { color: Colors.light.hereworksGreen }]}>
            Fastest
          </Text>
          {parseValue(solvedProblemLogs?.groups?.fastest.split(':')[1] ?? 0) > 0 && (
            <View style={{ flexDirection: 'row', alignItems: 'flex-end', height: 32 }}>
              <Text style={styles.taskAverageValue}>
                {parseValue(solvedProblemLogs?.groups?.fastest.split(':')[0] ?? 0)}
              </Text>
              <Text style={[styles.taskAverageDescription, { marginRight: 4 }]}>Hrs</Text>
              <Text style={styles.taskAverageValue}>
                {parseValue(solvedProblemLogs?.groups?.fastest.split(':')[1] ?? 0)}
              </Text>
              <Text style={styles.taskAverageDescription}>Min</Text>
              {/* {averageValueChange(solvedProblemLogs?.groups?.fastestPercentageChange)} */}
            </View>
          )}
          {parseValue(solvedProblemLogs?.groups?.fastest.split(':')[1] ?? 0) === 0 && (
            <View style={{ flexDirection: 'row', alignItems: 'flex-end', height: 32 }}>
              <Text style={styles.taskAverageValue}>
                {parseValue(solvedProblemLogs?.groups?.fastest.split(':')[1] ?? 0)}
              </Text>
              <Text style={[styles.taskAverageDescription, { marginRight: 4 }]}>Min</Text>
              <Text style={styles.taskAverageValue}>
                {parseValue(solvedProblemLogs?.groups?.fastest.split(':')[2] ?? 0)}
              </Text>
              <Text style={styles.taskAverageDescription}>S</Text>
              {/* {averageValueChange(solvedProblemLogs?.groups?.fastestPercentageChange)} */}
            </View>
          )}
        </View>
        <View
          style={{
            width: 1,
            backgroundColor: Colors.light.hereworksWhite500,
            marginHorizontal: 16,
          }}
        />
        <View style={{ flex: 1 }}>
          <Text style={[styles.averageTitles, { color: Colors.light.hereworksRed }]}>Slowest</Text>
          <View style={{ flexDirection: 'row', alignItems: 'flex-end', height: 32 }}>
            <Text style={styles.taskAverageValue}>
              {parseValue(solvedProblemLogs?.groups?.slowest.split(':')[0] ?? 0)}
            </Text>
            <Text style={[styles.taskAverageDescription, { marginRight: 4 }]}>Hrs</Text>
            {parseValue(solvedProblemLogs?.groups?.slowest.split(':')[0] ?? 0) < 99 && (
              <>
                <Text style={styles.taskAverageValue}>
                  {parseValue(solvedProblemLogs?.groups?.slowest.split(':')[1] ?? 0)}
                </Text>
                <Text style={styles.taskAverageDescription}>Min</Text>
              </>
            )}
            {/* {averageValueChange(solvedProblemLogs?.groups?.slowestPercentageChange)} */}
          </View>
        </View>
      </View>
    </View>
  );

  const renderOverallTickets = () => (
    <View
      style={[
        styles.commonCardStyle,
        {
          marginVertical: renderMobile() ? 16 : 24,
          paddingTop: 18,
          zIndex: -1,
        },
      ]}
    >
      <Text
        style={{
          fontFamily: 'Poppins_400Regular',
          fontSize: 22,
          color: Colors.light.hereworksPrimaryGrey,
          marginHorizontal: 24,
        }}
      >
        {selectedGroup !== null
          && selectedChild === null
          && `${selectedGroup ?? ''} tickets raised`}
        {selectedChild !== null && `${selectedChild?.Name ?? ''} tickets raised`}
        {selectedGroup === null && selectedChild === null && 'Overall tickets raised'}
      </Text>
      {compareDatesView()}
      {renderMobile() && renderGraphButtons()}
      {!renderMobile() && (
        <View style={{ position: 'absolute', right: 24, top: 28 }}>{renderGraphButtons()}</View>
      )}
      {!renderMobile() && (
        <>
          {buttonsButtonSelected && (
            <OverallButtonsPressedGraphWeb
              data={overallPressed}
              compareData={overallPressedCompare}
              dates={range}
              compareDates={compareRange}
              metricName="happy_group_external_environment_local_happyscore_percent"
              title="External"
              interval="24h"
              compareToggled={compareRange.end !== null}
              width={dimensions.window?.width}
              height={dimensions.window?.height}
            />
          )}
          {!buttonsButtonSelected && (
            <ReportsBarGraphWeb
              data={groupInstances}
              compareData={groupInstancesCompare}
              compareToggled={compareRange.start !== null}
            />
          )}
        </>
      )}
      {renderMobile() && (
        <>
          {buttonsButtonSelected && (
            <OverallButtonsPressedGraph
              data={overallPressed}
              compareData={overallPressedCompare}
              dates={range}
              compareDates={compareRange}
              title="External"
              interval="24h"
              compareToggled={compareRange.end !== null}
            />
          )}
          {!buttonsButtonSelected && (
            <ReportsBarGraphMobile
              data={groupInstances}
              compareData={groupInstancesCompare}
              compareToggled={compareRange.start !== null}
            />
          )}
        </>
      )}
    </View>
  );

  const renderGroupDropdown = () => (
    <TouchableOpacity
      style={[styles.dropdownBox, renderMobile() ? { width: '100%', zIndex: 1 } : { zIndex: 1 }]}
      onPress={() => {
        setGroupDropdownVisible(!groupDropdownVisible);
        Analytics.logEvent('button_press', {
          screen: 'Analytics',
          name: `Group dropdown ${groupDropdownVisible ? 'Close' : 'Open'}`,
        });
      }}
    >
      <Text style={styles.dropdownBoxText}>{selectedGroup ?? 'All template groups'}</Text>
      <View style={{ flex: 1, alignItems: 'flex-end' }}>
        <FontAwesome
          size={20}
          style={{
            color: Colors.light.hereworksLightBlue,
            marginRight: 12,
          }}
          name={groupDropdownVisible ? 'angle-up' : 'angle-down'}
        />
      </View>
      {groupDropdownVisible && (
        <View
          style={[styles.dropdownView, renderMobile() ? { width: '100%', top: 50 } : { zIndex: 1 }]}
        >
          {templateGroups?.map((group, key) => (
            <TouchableOpacity
              key={key}
              onPress={() => {
                setSelectedGroup(group.name);
                setSelectedChild(null);
                setSelectedGroupId(group.id);
                assets.forEach((asset) => {
                  const instance = instances.filter((i) => i.ButtonInstanceId === asset.id)[0];
                  asset.ButtonGroupName = instance?.ButtonGroupName;
                });
                const filtered = assets.filter((asset) => asset.ButtonGroupName === group.name);
                setFilteredAssets(filtered);
                setFilteredByFloor(filtered);
                setSelectedFloor(null);
                setGroupDropdownVisible(!groupDropdownVisible);
                Analytics.logEvent('button_press', {
                  screen: 'Analytics',
                  name: `Selected group: ${group.name}`,
                });
              }}
            >
              <Text style={styles.dropdownItemText}>{group.name}</Text>
            </TouchableOpacity>
          ))}
        </View>
      )}
    </TouchableOpacity>
  );

  const exportButton = () => (
    <TouchableOpacity
      style={{
        borderRadius: 4,
        backgroundColor: Colors.light.hereworksLightBlue,
        alignSelf: 'flex-start',
        position: renderMobile() ? 'relative' : 'absolute',
        top: 0,
        right: 0,
        marginBottom: 24,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 24,
      }}
      onPress={() => {
        Analytics.logEvent('button_press', {
          screen: 'Analytics',
          name: 'Export',
        });
      }}
    >
      <Text style={{ color: '#fff', marginVertical: 8, marginRight: 8 }}>Export</Text>
      <DownloadIcon />
    </TouchableOpacity>
  );

  const renderSelectedTemplates = () => (
    <View>
      <ScrollView
        horizontal={true}
        style={{
          marginBottom: 20,
        }}
        contentContainerStyle={{ paddingRight: 12 }}
      >
        {selectedTemplates?.length > 0
          && !refreshTemplates
          && selectedTemplates?.map((item: any, key) => {
            const issueCount = item?.stats?.IssuesEver ?? 0;
            const compareValue = item?.compareStats?.IssuesEver ?? 0;
            return buttonTile(
              item.name,
              issueCount,
              compareValue,
              Math.floor(item?.percentageChange),
              key,
            );
          })}
        {refreshTemplates && (
          <ActivityIndicator size={'large'} color={Colors.light.hereworksLoading} />
        )}
        {/* {buttonTile('Equipment', 1, 12, 50, 1)}
        {buttonTile('Desk', 11, 12, 50, 2)}
        {buttonTile('Toilet', 22, -12, 50, 3)}
        {buttonTile('Printer', 2, 12, 50, 4)}
        {buttonTile('Bins', 22, 12, 50, 5)}
        {buttonTile('Coffee machine', 33, 12, 50, 6)}
        {buttonTile('Internet', 3, -2, 50, 7)}
        {buttonTile('Meeting room', 5, 12, 50, 8)}
        {buttonTile('Printer', 6, 12, 50, 9)}
        {buttonTile('Desk', 7, 12, 50, 10)} */}
      </ScrollView>
    </View>
  );

  const editButton = () => (
    <TouchableOpacity
      style={{
        borderRadius: 4,
        borderWidth: 1,
        borderColor: Colors.light.hereworksLightBlue,
        backgroundColor: '#fff',
        alignSelf: 'flex-start',
        marginBottom: 24,
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 24,
      }}
      onPress={() => {
        setModalVisible(!modalVisible);
        Analytics.logEvent('button_press', {
          screen: 'Analytics',
          name: 'Edit',
        });
      }}
    >
      <Text
        style={{
          color: Colors.light.hereworksLightBlue,
          marginVertical: 8,
          marginLeft: 24,
          marginRight: 10,
        }}
      >
        Edit
      </Text>
      <EditIcon />
    </TouchableOpacity>
  );

  const resetButton = () => (
    <>
      {(selectedGroup || selectedChild) && (
        <TouchableOpacity
          style={{
            marginLeft: renderMobile() ? 0 : 12,
            marginTop: 13,
            justifyContent: 'center',
            zIndex: -1,
          }}
          onPress={() => {
            setGroupDropdownVisible(false);
            setChildDropdownVisible(false);
            setButtonsButtonSelected(true);
            setSelectedGroup(null);
            setSelectedChild(null);
            setSelectedGroupId(null);
            setFilteredAssets(assets);
            setFilteredByFloor(assets);
            setButtonsButtonSelected(true);
            setReportsButtonSelected(false);
            Analytics.logEvent('button_press', {
              screen: 'Analytics',
              name: 'Reset Buttons Pressed Graph',
            });
          }}
        >
          <Text
            style={{
              color: Colors.light.hereworksPrimaryGrey,
              fontSize: 14,
              fontFamily: 'Poppins_400Regular',
              paddingBottom: 2,
            }}
          >
            Reset
          </Text>
          <View
            style={{ width: 39, height: 1, backgroundColor: Colors.light.hereworksPrimaryGrey }}
          />
        </TouchableOpacity>
      )}
    </>
  );

  const templateDropdown = () => (
    <View
      style={{
        marginLeft: renderMobile() ? 0 : 17,
      }}
    >
      <TouchableOpacity
        style={[styles.dropdownBox, renderMobile() ? { width: '100%', zIndex: 0 } : {}]}
        onPress={() => {
          if (templates.length > 0) {
            setChildDropdownVisible(!childDropdownVisible);
            Analytics.logEvent('button_press', {
              screen: 'Analytics',
              name: `Child dropdown ${childDropdownVisible ? 'Close' : 'Open'}`,
            });
          }
        }}
        disabled={templates.length === 0}
      >
        <Text style={styles.dropdownBoxText}>{selectedChild?.Name ?? 'All templates'}</Text>
        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          {templates.length > 0 && (
            <FontAwesome
              size={20}
              style={{
                color: Colors.light.hereworksLightBlue,
                marginRight: 12,
              }}
              name={childDropdownVisible ? 'angle-up' : 'angle-down'}
            />
          )}
        </View>
      </TouchableOpacity>
      {childDropdownVisible && (
        <ScrollView
          style={[
            styles.dropdownView,
            renderMobile() ? { width: '100%', top: 60 } : { height: 300 },
          ]}
        >
          {templates
            ?.filter((t) => t.GroupId === (selectedGroupId !== null ? selectedGroupId : t.GroupId))
            ?.map((button, key) => (
              <TouchableOpacity
                key={key}
                onPress={() => {
                  setSelectedChild(button);
                  assets.forEach((asset) => {
                    const instance = instances.filter((i) => i.ButtonInstanceId === asset.id)[0];
                    asset.templateid = instance?.ButtonPrototypeId;
                  });
                  const filtered = assets.filter((asset) => asset.templateid === button.Id);
                  setFilteredAssets(filtered);
                  setFilteredByFloor(filtered);
                  setSelectedFloor(null);
                  setChildDropdownVisible(!childDropdownVisible);
                  Analytics.logEvent('button_press', {
                    screen: 'Analytics',
                    name: `Selected child: ${button.Name}}`,
                  });
                }}
              >
                <Text style={styles.dropdownItemText}>{button.Name}</Text>
              </TouchableOpacity>
            ))}
        </ScrollView>
      )}
    </View>
  );

  const ticketsRaisedLabel = () => {
    if (Moment(range.start).isSame(range.end, 'date')) {
      return Moment(range.start).format('D MMM');
    }
    return (
      <>
        {compareRange.start === null
          ? `Tickets raised in period ${Moment(range.start).format('D MMM')} - ${Moment(
            range.end ?? range.start,
          ).format('D MMM')}`
          : `Compare tickets raised between periods ${Moment(range.start).format(
            'D MMM',
          )} - ${Moment(range.end ?? range.start).format('D MMM')} and ${Moment(
            compareRange.start,
          ).format('D MMM')} - ${Moment(compareRange.end ?? compareRange.start).format('D MMM')}`}
      </>
    );
  };

  const renderCards = () => (renderMobile() ? (
      <View>
        <Text
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontFamily: 'Poppins_400Regular',
            fontSize: 20,
            color: '#000',
          }}
        >
          Insights at a glance
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto_300Light',
            fontSize: 18,
            color: Colors.light.hereworksPrimaryGrey,
            marginBottom: 16,
          }}
        >
          {ticketsRaisedLabel()}
        </Text>
        {showExport && exportButton()}
        {renderSelectedTemplates()}
        {editButton()}
        <View style={{ zIndex: 1 }}>
          {renderGroupDropdown()}
          {templateDropdown()}
          {resetButton()}
        </View>
        <View
          style={{
            zIndex: -1,
          }}
        >
          {renderOverallTickets()}
        </View>
        {renderChildrenCard()}
        <View style={{ zIndex: -1, flex: 1 }}>
          {renderPriorityStatistics()}
          {renderTimeOnTask()}
          {renderSolvedProblems()}
          {renderLocation()}
        </View>
      </View>
  ) : (
      <View>
        <Text
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontFamily: 'Poppins_400Regular',
            fontSize: 20,
            color: '#000',
          }}
        >
          Insights at a glance
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto_300Light',
            fontSize: 18,
            color: Colors.light.hereworksPrimaryGrey,
            marginBottom: 16,
          }}
        >
          {ticketsRaisedLabel()}
        </Text>
        {showExport && exportButton()}
        {renderSelectedTemplates()}
        {editButton()}
        <Text
          style={{
            fontFamily: 'Poppins_400Regular',
            fontSize: 14,
            color: '#000',
            marginTop: 40,
          }}
        >
          Select template
        </Text>
        <View style={{ flexDirection: 'row', zIndex: 1 }}>
          {renderGroupDropdown()}
          {templateDropdown()}
          {resetButton()}
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              zIndex: -1,
              flex: 2,
              maxWidth: 738,
            }}
          >
            {renderOverallTickets()}
            {renderChildrenCard()}
            {renderSolvedProblems()}
          </View>
          <View style={{ zIndex: -1, flex: 1, maxWidth: 394 }}>
            {renderPriorityStatistics()}
            {renderTimeOnTask()}
            {renderLocation()}
          </View>
        </View>
      </View>
  ));

  Moment.updateLocale('en', {
    relativeTime: {
      s: '%d s',
      m: '%d m',
      h: '%d hr',
      hh: '%d hrs',
      d: '%d day',
      dd: '%d days',
    },
  });

  return (
    <View
      style={[
        styles.container,
        {
          alignItems: renderMobile() ? null : 'flex-start',
          paddingHorizontal: renderMobile() ? 24 : 30,
        },
      ]}
    >
      <Modal
        animationIn={renderMobile() ? 'slideInUp' : 'fadeIn'}
        animationOut={renderMobile() ? 'slideOutDown' : 'fadeOut'}
        style={{ flex: 1, marginVertical: 45, marginHorizontal: 10 }}
        isVisible={modalVisible}
        swipeDirection={['down']}
        onSwipeComplete={() => setModalVisible(!modalVisible)}
        onBackButtonPress={() => setModalVisible(!modalVisible)}
        propagateSwipe={true}
        scrollOffset={scrollOffset}
        scrollTo={handleScrollTo}
        scrollOffsetMax={200}
      >
        <Modal
          isVisible={loading}
          presentationStyle={'overFullScreen'}
          animationIn={'fadeIn'}
          animationOut={'fadeOut'}
          hasBackdrop={false}
          style={{ margin: 0 }}
        >
          <View style={{ flex: 1, justifyContent: 'center', backgroundColor: '#00000020' }}>
            <ActivityIndicator color={Colors.light.hereworksLoading} size={'large'} />
          </View>
        </Modal>
        <View style={{ flex: 1, backgroundColor: '#fff', borderRadius: 10 }}>
          <Text
            style={{
              fontSize: 20,
              fontFamily: 'Poppins_400Regular',
              color: Colors.light.hereworksPrimaryGrey,
              marginTop: 20,
              marginBottom: 6,
              alignSelf: 'center',
            }}
          >
            Choose templates to add
          </Text>
          <ScrollView
            scrollEventThrottle={1600}
            ref={(ref) => {
              scrollView.current = ref;
            }}
            onScroll={handleOnScroll}
          >
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
              {templates?.map((item: any, key: number) => (
                <SelectButtonTile
                  selected={item.selected}
                  title={item.Name}
                  numberSelected={numberSelected}
                  onSelect={() => {
                    item.selected = !item.selected;
                    setNumberSelected(templates?.filter((button: any) => button.selected).length);
                  }}
                  key={key}
                />
              ))}
            </View>
          </ScrollView>
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
            <TouchableOpacity
              style={{
                borderRadius: 5,
                backgroundColor: Colors.light.hereworksDarkBlue,
                alignSelf: 'flex-start',
                marginBottom: 24,
                borderColor: Colors.light.hereworksDarkBlue,
                borderWidth: 1,
              }}
              onPress={() => {
                setLoading(true);
                const groups = [];
                templates
                  ?.filter((button: any) => button.selected)
                  .forEach((button) => {
                    if (groups.findIndex((item) => item.GroupId === button.GroupId) === -1) {
                      groups.push({ GroupId: button.GroupId });
                    }
                  });

                groups.forEach((group) => {
                  const templateIds = [];
                  templates
                    ?.filter((button: any) => button.selected && button.GroupId === group.GroupId)
                    .forEach((item) => templateIds.push(item.Id));
                  group.templates = templateIds;
                });

                // console.log(groups);
                updateTemplateSelection(
                  groups,
                  range.start,
                  range.end,
                  compareRange.start,
                  compareRange.end,
                ).then(() => {
                  setLoading(false);
                  setModalVisible(!modalVisible);
                  setNumberSelected(
                    selectedTemplates?.filter((button: any) => button.selected).length,
                  );
                  setRefreshTemplates(true);
                  setSavedTemplateSelection(JSON.parse(JSON.stringify(templates)));
                });
                Analytics.logEvent('button_press', {
                  screen: 'Analytics',
                  name: 'Save button selection',
                });
              }}
            >
              <Text
                style={{
                  color: '#fff',
                  marginVertical: 8,
                  marginHorizontal: 24,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 16,
                }}
              >
                Save
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                borderRadius: 5,
                borderColor: Colors.light.hereworksDarkBlue,
                borderWidth: 1,
                backgroundColor: '#fff',
                alignSelf: 'flex-start',
                marginBottom: 24,
                marginLeft: 16,
              }}
              onPress={() => {
                clearSelectedTemplates();
                setModalVisible(!modalVisible);
                Analytics.logEvent('button_press', {
                  screen: 'Analytics',
                  name: 'Cancel button selection',
                });
              }}
            >
              <Text
                style={{
                  color: Colors.light.hereworksDarkBlue,
                  marginVertical: 8,
                  marginHorizontal: 24,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 16,
                }}
              >
                Cancel
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <View style={{ backgroundColor: 'transparent' }}>
        {renderMobile() && <Text style={styles.header}>Solve Analytics</Text>}
        <DateComponent
          renderDesktopView={!renderMobile()}
          compareEnabled
          onChange={onConfirm}
          onCompareChange={onCompareConfirm}
          onOpen={() => {}}
          onConfirm={() => {
            /** todo */
          }}
          startDate={range.start}
          endDate={range.end}
          compareStartDate={compareRange.start}
          compareEndDate={compareRange.end}
        />
      </View>
      {renderMobile() && (
        <ScrollView style={{ width: '100%', zIndex: -1 }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }} nestedScrollEnabled={true}>{renderCards()}</ScrollView>
      )}
      {!renderMobile() && (
        <ScrollView
          style={[
            {
              alignSelf: 'stretch',
              zIndex: -1,
            },
          ]}
        >
          {renderCards()}
        </ScrollView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.hereworksBackground,
    paddingTop: 150,
    marginTop: -90,
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  shadow: {
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.05,
    shadowRadius: 20,
    shadowColor: 'black',
    elevation: 5,
  },
  dropdownBox: {
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksPaleBlue900,
    borderRadius: 10,
    marginTop: 16,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    width: 227,
    backgroundColor: '#fff',
    zIndex: 1,
  },
  dropdownBoxText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
    marginVertical: 9.5,
    marginLeft: 12,
    marginRight: 30,
  },
  dropdownView: {
    position: 'absolute',
    top: 61,
    left: 0,
    zIndex: 5,
    width: 227,
    borderRadius: 10,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksPaleBlue900,
    backgroundColor: '#fff',
  },
  dropdownViewMobile: {
    width: '100%',
    height: 200,
    zIndex: 5,
    borderRadius: 10,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksPaleBlue900,
    backgroundColor: '#fff',
  },
  dropdownItemText: {
    marginLeft: 12,
    marginVertical: 9,
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
  },
  header: {
    fontSize: 24,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
  },
  graphButtonText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
  },
  taskTimeValue: {
    fontSize: 40,
    lineHeight: 46,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
    marginLeft: 10,
  },
  taskTimeValueMobile: {
    fontSize: 30,
    lineHeight: 38,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
    marginLeft: 10,
  },
  taskAverageValue: {
    fontSize: 30,
    lineHeight: 34,
    marginBottom: 2,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
  },
  taskTimeDescription: {
    fontSize: 14,
    lineHeight: 22,
    marginBottom: 6,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack600,
    marginLeft: 2,
  },
  taskAverageDescription: {
    fontSize: 14,
    lineHeight: 32,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack600,
    marginLeft: 2,
  },
  taskAverageText: {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack600,
    marginLeft: 88,
  },
  divider: {
    backgroundColor: Colors.light.hereworksWhite500,
    height: 1,
    marginHorizontal: 28,
    marginVertical: 30,
  },
  averageTitles: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    marginBottom: 4,
  },
  averageValueChange: {
    textAlign: 'right',
    fontSize: 14,
    fontFamily: 'Roboto_400Regular',
    lineHeight: 22,
  },
  averageChangeContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  headerText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    letterSpacing: 0.7,
    color: Colors.light.hereworksBlack400,
  },
  commonCardStyle: {
    borderColor: Colors.light.hereworksPrimaryGrey300,
    borderWidth: 1,
    borderRadius: 24,
    backgroundColor: '#fff',
  },
  locationHeader: {
    color: Colors.light.hereworksBlack400,
    fontSize: 10,
    fontFamily: 'Roboto_500Medium',
    lineHeight: 15,
    marginBottom: 2,
  },
  locationValue: {
    color: Colors.light.hereworksBlack600,
    fontSize: 14,
    fontFamily: 'Roboto_400Regular',
    lineHeight: 22,
  },
  priorityValues: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
    color: Colors.light.hereworksBlack600,
  },
  vsText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
    color: Colors.light.hereworksBlack400,
    marginHorizontal: 2,
  },
  priorityHours: {
    fontSize: 30,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
  },
  priorityCompareHours: {
    fontSize: 16,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
    marginBottom: 15,
  },
  searchContainer: {
    zIndex: 5,
    borderRadius: 10,
    borderWidth: 1.5,
    padding: 8,
    borderColor: Colors.light.hereworksBlue100,
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
  },
});

let FLOORS = [
  {
    title: 'All',
  },
  {
    title: 'Ground Floor',
  },
  {
    title: 'First Floor',
  },
  {
    title: 'Second Floor',
  },
];
