import React, { useState } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import {
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Pressable,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import * as Analytics from 'expo-firebase-analytics';
import FilterBox from '../FilterBox';
import Colors from '../../constants/Colors';
import DateComponent from '../DateComponent';
import Switch from '../Switch';

export default function TicketsFilter(props) {
  const maxHeight = useWindowDimensions().height;

  const [lowPriorityFilter, setLowPriorityFilter] = useState(props.lowPriorityFilter);
  const [medPriorityFilter, setMedPriorityFilter] = useState(props.medPriorityFilter);
  const [highPriorityFilter, setHighPriorityFilter] = useState(props.highPriorityFilter);
  const [criticalPriorityFilter, setCriticalPriorityFilter] = useState(
    props.criticalPriorityFilter,
  );

  const [doneFilter, setDoneFilter] = useState(props.doneFilter);
  const [inProgressFilter, setInProgressFilter] = useState(props.inProgressFilter);
  const [pausedFilter, setPausedFilter] = useState(props.pausedFilter);
  const [withoutStatusFilter, setWithoutStatusFilter] = useState(props.withoutStatusFilter);
  const [selectedAssignee, setSelectedAssignee] = useState(props.selectedAssignee);
  const [selectedAssetId, setSelectedAssetId] = useState(props.selectedAssetId);
  const [selectedUserGroupId, setSelectedUserGroupId] = useState(props.selectedUserGroupId);
  const [selectedLocation, setSelectedLocation] = useState(props.selectedLocation);

  const [filterAssigneeDropdown, setFilterAssigneeDropdown] = useState(false);
  const [assetIdDropdown, setAssetIdDropdown] = useState(false);
  const [userGroupIdDropdown, setUserGroupIdDropdown] = useState(false);
  const [locationDropdown, setLocationDropdown] = useState(false);
  const [userGroupSearchValue, setUserGroupSearchValue] = useState('');
  const [assetSearchValue, setAssetSearchValue] = useState('');
  const [showDatePeriods, setShowDatePeriods] = useState(false);
  const [compare, setCompare] = useState(false);

  const compareEnabled = false;

  const assigneeName = (clientIdEmail) => {
    let assignee = clientIdEmail;
    props.users.forEach((user) => {
      if (user.ClientIdEmail === clientIdEmail) {
        assignee = `${user.GivenName} ${user.FamilyName}`;
      }
    });
    return assignee;
  };

  const userGroupName = (userGroupId) => {
    let asset = userGroupId;
    props.userGroups.forEach((instance) => {
      if (instance.UserGroupId === userGroupId) {
        asset = instance.Name;
      }
    });
    return asset;
  };

  const locationName = (locationId) => {
    let location = locationId;
    props.locations.forEach((instance) => {
      if (instance.LocationId === locationId) {
        location = instance.Name;
      }
    });
    return location;
  };

  const assetName = (assetId) => {
    let asset = assetId;
    props.instances.forEach((instance) => {
      if (instance.ButtonInstanceId === assetId) {
        asset = instance.Name;
      }
    });
    return asset;
  };

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'TicketsFilter',
      name: title,
    });
  };

  const datePicker = () => (props.mobile ? (
    <View style={{ flex: 1, zIndex: 2 }}>
      <DateComponent
        onChange={props.onConfirm}
        onCompareChange={() => {}}
        onOpen={() => {}}
        renderDesktopView={!props.mobile}
        onConfirm={() => {}}
        startDate={props.range.start}
        endDate={props.range.end}
        selectedRange={props.selectedRange}
        setSelectedRange={props.setSelectedRange}
      />
    </View>
  ) : (
    <View
      style={{
        flex: 1,
        zIndex: 2,
        marginTop: 24,
      }}
    >
      <DateComponent
        onChange={props.onConfirm}
        onCompareChange={() => {}}
        onOpen={() => {}}
        renderDesktopView={!props.mobile}
        onConfirm={() => {}}
        startDate={props.range.start}
        endDate={props.range.end}
        selectedRange={props.selectedRange}
        setSelectedRange={(index) => props.setSelectedRange(index)}
      />
    </View>
  ));

  return (
    <Animatable.View
      style={[
        !props.mobile && styles.filterShadow,
        !props.mobile && { maxHeight: maxHeight - 200 },
        props.mobile ? styles.mobileFilterContainer : styles.filterContainer,
      ]}
      animation={'fadeIn'}
      useNativeDriver={true}
    >
      <View style={styles.filterDatePickerContainerWeb}>{!props.mobile && datePicker()}</View>
      <ScrollView nestedScrollEnabled style={styles.filterScrollView}>
        <Animatable.View animation={'fadeIn'}>
          {props.mobile && datePicker()}
          <View style={[styles.filterRow, { marginTop: 0 }]}>
            <Text style={styles.filterText}>Priority</Text>
            <View style={{ flexDirection: 'row', flex: 4 }}>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  onPress={() => {
                    setLowPriorityFilter(!lowPriorityFilter);
                  }}
                >
                  <FilterBox style={{ marginRight: 6 }} toggled={lowPriorityFilter} />
                </TouchableOpacity>
                <Text style={styles.filterText}>Low</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  onPress={() => {
                    setMedPriorityFilter(!medPriorityFilter);
                  }}
                >
                  <FilterBox style={{ marginRight: 6 }} toggled={medPriorityFilter} />
                </TouchableOpacity>
                <Text style={styles.filterText}>Moderate</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  onPress={() => {
                    setHighPriorityFilter(!highPriorityFilter);
                  }}
                >
                  <FilterBox style={{ marginRight: 6 }} toggled={highPriorityFilter} />
                </TouchableOpacity>
                <Text style={styles.filterText}>High</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  onPress={() => {
                    setCriticalPriorityFilter(!criticalPriorityFilter);
                  }}
                >
                  <FilterBox style={{ marginRight: 6 }} toggled={criticalPriorityFilter} />
                </TouchableOpacity>
                <Text style={styles.filterText}>Critical</Text>
              </View>
            </View>
          </View>
          <View style={styles.filterRow}>
            <View style={{ width: 91 }}>
              <Text style={styles.filterText}>Status</Text>
            </View>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  onPress={() => {
                    setDoneFilter(!doneFilter);
                  }}
                >
                  <FilterBox style={{ marginRight: 6 }} toggled={doneFilter} />
                </TouchableOpacity>
                <Text style={styles.filterText}>Done</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  onPress={() => {
                    setInProgressFilter(!inProgressFilter);
                  }}
                >
                  <FilterBox style={{ marginRight: 6 }} toggled={inProgressFilter} />
                </TouchableOpacity>
                <Text style={styles.filterText}>In progress</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  onPress={() => {
                    setPausedFilter(!pausedFilter);
                  }}
                >
                  <FilterBox style={{ marginRight: 6 }} toggled={pausedFilter} />
                </TouchableOpacity>
                <Text style={styles.filterText}>Paused</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  onPress={() => {
                    setWithoutStatusFilter(!withoutStatusFilter);
                  }}
                >
                  <FilterBox style={{ marginRight: 6 }} toggled={withoutStatusFilter} />
                </TouchableOpacity>
                <Text style={[styles.filterText, { marginRight: 0 }]}>Without status</Text>
              </View>
            </View>
          </View>
          <View style={styles.filterRow}>
            <View style={{ width: 91, height: 36, justifyContent: 'center' }}>
              <Text style={styles.filterText}>Assignee</Text>
            </View>
            <View style={{ flex: 3 }}>
              <TouchableOpacity
                style={styles.assigneeFilter}
                onPress={() => setFilterAssigneeDropdown(!filterAssigneeDropdown)}
              >
                <View style={{ flexDirection: 'row', flex: 2, alignItems: 'center' }}>
                  {selectedAssignee === '' && (
                    <Text style={styles.chooseAssigneeText}>Choose assignee</Text>
                  )}
                  {selectedAssignee !== '' && (
                    <Text style={[styles.chooseAssigneeText, { color: 'black' }]}>
                      {assigneeName(selectedAssignee)}
                    </Text>
                  )}
                  <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <FontAwesome
                      size={30}
                      style={{ color: Colors.light.hereworksPrimaryGrey800, marginRight: 12 }}
                      name={'angle-down'}
                    />
                  </View>
                </View>
              </TouchableOpacity>
              {filterAssigneeDropdown && (
                <ScrollView
                  style={{
                    width: '100%',
                    height: 200,
                    backgroundColor: '#fff',
                    borderWidth: 1.5,
                    borderColor: Colors.light.hereworksBlue100,
                    borderRadius: 10,
                  }}
                  nestedScrollEnabled={true}
                >
                  <Pressable
                    onPress={() => {
                      props.setProblems(props.allProblems);
                      setSelectedAssignee('');
                      setFilterAssigneeDropdown(false);
                    }}
                    style={(state) => [
                      {
                        paddingLeft: 10,
                        paddingVertical: 10,
                        borderRadius: 4,
                        marginVertical: 4,
                        backgroundColor: state.hovered ? Colors.light.hereworksWhite200 : undefined,
                      },
                    ]}
                  >
                    <Text style={styles.filterText}>ALL ASSIGNEES</Text>
                  </Pressable>
                  {props.users.map((user: any, key) => (
                    <Pressable
                      key={key}
                      onPress={() => {
                        setSelectedAssignee(user.ClientIdEmail);
                        setFilterAssigneeDropdown(false);
                      }}
                      style={(state) => [
                        {
                          paddingLeft: 10,
                          paddingVertical: 10,
                          borderRadius: 4,
                          marginVertical: 4,
                          backgroundColor: state.hovered
                            ? Colors.light.hereworksWhite200
                            : undefined,
                        },
                        selectedAssignee === user.ClientIdEmail && {
                          borderColor: Colors.light.hereworksBlue400,
                          borderWidth: 1,
                        },
                      ]}
                    >
                      <Text style={styles.filterText}>{assigneeName(user.ClientIdEmail)}</Text>
                    </Pressable>
                  ))}
                </ScrollView>
              )}
            </View>
          </View>
          <View style={styles.filterRow}>
            <View style={{ height: 36, justifyContent: 'center' }}>
              <Text style={styles.filterText}>Asset Name</Text>
            </View>
            <View style={{ flex: 3 }}>
              {!assetIdDropdown && (
                <TouchableOpacity
                  style={styles.assigneeFilter}
                  onPress={() => setAssetIdDropdown(!assetIdDropdown)}
                >
                  <View style={{ flexDirection: 'row', flex: 2, alignItems: 'center' }}>
                    <>
                      {selectedAssetId === '' && (
                        <Text style={styles.chooseAssigneeText}>Choose Asset</Text>
                      )}
                      {selectedAssetId !== '' && (
                        <Text
                          numberOfLines={1}
                          style={[styles.chooseAssigneeText, { color: 'black', marginRight: 26 }]}
                        >
                          {assetName(selectedAssetId)}
                        </Text>
                      )}
                      <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <FontAwesome
                          size={30}
                          style={{ color: Colors.light.hereworksPrimaryGrey800, marginRight: 12 }}
                          name={'angle-down'}
                        />
                      </View>
                    </>
                  </View>
                </TouchableOpacity>
              )}
              {assetIdDropdown && (
                <View style={styles.assigneeFilter}>
                  <TextInput
                    placeholder="Search"
                    // @ts-ignore
                    style={[{ marginLeft: 8 }, !props.mobile && { outlineStyle: 'none' }]}
                    placeholderTextColor={Colors.light.hereworksPrimaryGrey700}
                    onChangeText={(text) => setAssetSearchValue(text)}
                  />
                  <TouchableOpacity
                    style={{ flex: 1, alignItems: 'flex-end' }}
                    onPress={() => {
                      analytics('Close asset dropdown');
                      setAssetSearchValue('');
                      setAssetIdDropdown(false);
                    }}
                  >
                    <FontAwesome
                      size={30}
                      style={{ color: Colors.light.hereworksPrimaryGrey800, marginRight: 12 }}
                      name={'angle-up'}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
            {assetIdDropdown && (
              <ScrollView
                style={{
                  width: '100%',
                  height: 200,
                  backgroundColor: '#fff',
                  borderWidth: 1.5,
                  borderColor: Colors.light.hereworksBlue100,
                  borderRadius: 10,
                }}
                nestedScrollEnabled={true}
              >
                <Pressable
                  onPress={() => {
                    props.setProblems(props.allProblems);
                    setSelectedAssetId('');
                    setAssetSearchValue('');
                    setAssetIdDropdown(false);
                  }}
                  style={(state) => [
                    {
                      paddingLeft: 10,
                      paddingVertical: 10,
                      borderRadius: 4,
                      marginVertical: 4,
                      backgroundColor: state.hovered ? Colors.light.hereworksWhite200 : undefined,
                    },
                  ]}
                >
                  <Text style={styles.filterText}>ALL ASSETS</Text>
                </Pressable>
                {props.instances.map((instance: any, key) => {
                  if (instance.Name?.length === 0) {
                    return null;
                  }
                  if (
                    !instance.Name?.toLocaleLowerCase().includes(
                      assetSearchValue.toLocaleLowerCase(),
                    )
                  ) {
                    return null;
                  }
                  return (
                    <Pressable
                      key={key}
                      onPress={() => {
                        setSelectedAssetId(instance.ButtonInstanceId);
                        setAssetSearchValue('');
                        setAssetIdDropdown(false);
                      }}
                      style={(state) => [
                        {
                          paddingLeft: 10,
                          paddingVertical: 10,
                          borderRadius: 4,
                          marginVertical: 4,
                          backgroundColor: state.hovered
                            ? Colors.light.hereworksWhite200
                            : undefined,
                        },
                        selectedAssetId === instance.ButtonInstanceId && {
                          borderColor: Colors.light.hereworksBlue400,
                          borderWidth: 1,
                        },
                      ]}
                    >
                      <Text style={styles.filterText}>{instance.Name}</Text>
                    </Pressable>
                  );
                })}
              </ScrollView>
            )}
          </View>

          <View style={styles.filterRow}>
            <View style={{ height: 36, justifyContent: 'center' }}>
              <Text style={styles.filterText}>User Group</Text>
            </View>
            <View style={{ flex: 3 }}>
              {!userGroupIdDropdown && (
                <TouchableOpacity
                  style={styles.assigneeFilter}
                  onPress={() => setUserGroupIdDropdown(!userGroupIdDropdown)}
                >
                  <View style={{ flexDirection: 'row', flex: 2, alignItems: 'center' }}>
                    <>
                      {selectedUserGroupId === '' && (
                        <Text style={styles.chooseAssigneeText}>Choose User Group</Text>
                      )}
                      {selectedUserGroupId !== '' && (
                        <Text
                          numberOfLines={1}
                          style={[styles.chooseAssigneeText, { color: 'black', marginRight: 26 }]}
                        >
                          {userGroupName(selectedUserGroupId)}
                        </Text>
                      )}
                      <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <FontAwesome
                          size={30}
                          style={{ color: Colors.light.hereworksPrimaryGrey800, marginRight: 12 }}
                          name={'angle-down'}
                        />
                      </View>
                    </>
                  </View>
                </TouchableOpacity>
              )}
              {userGroupIdDropdown && (
                <View style={styles.assigneeFilter}>
                  <TextInput
                    placeholder="Search"
                    // @ts-ignore
                    style={[{ marginLeft: 8 }, !props.mobile && { outlineStyle: 'none' }]}
                    placeholderTextColor={Colors.light.hereworksPrimaryGrey700}
                    onChangeText={(text) => setUserGroupSearchValue(text)}
                  />
                  <TouchableOpacity
                    style={{ flex: 1, alignItems: 'flex-end' }}
                    onPress={() => setUserGroupIdDropdown(false)}
                  >
                    <FontAwesome
                      size={30}
                      style={{ color: Colors.light.hereworksPrimaryGrey800, marginRight: 12 }}
                      name={'angle-up'}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
            {userGroupIdDropdown && (
              <ScrollView
                style={{
                  width: '100%',
                  height: 200,
                  backgroundColor: '#fff',
                  borderWidth: 1.5,
                  borderColor: Colors.light.hereworksBlue100,
                  borderRadius: 10,
                }}
                nestedScrollEnabled={true}
              >
                <Pressable
                  onPress={() => {
                    props.setProblems(props.allProblems);
                    setSelectedUserGroupId('');
                    setUserGroupIdDropdown(false);
                  }}
                  style={(state) => [
                    {
                      paddingLeft: 10,
                      paddingVertical: 10,
                      borderRadius: 4,
                      marginVertical: 4,
                      backgroundColor: state.hovered ? Colors.light.hereworksWhite200 : undefined,
                    },
                  ]}
                >
                  <Text style={styles.filterText}>ALL USER GROUPS</Text>
                </Pressable>
                {props.userGroups.map((group: any, key) => {
                  if (group.Name.length === 0) {
                    return null;
                  }
                  if (
                    !group.Name.toLocaleLowerCase().includes(
                      userGroupSearchValue.toLocaleLowerCase(),
                    )
                  ) {
                    return null;
                  }
                  return (
                    <Pressable
                      key={key}
                      onPress={() => {
                        setSelectedUserGroupId(group.UserGroupId);
                        setUserGroupIdDropdown(false);
                      }}
                      style={(state) => [
                        {
                          paddingLeft: 10,
                          paddingVertical: 10,
                          borderRadius: 4,
                          marginVertical: 4,
                          backgroundColor: state.hovered
                            ? Colors.light.hereworksWhite200
                            : undefined,
                        },
                        selectedUserGroupId === group.UserGroupId && {
                          borderColor: Colors.light.hereworksBlue400,
                          borderWidth: 1,
                        },
                      ]}
                    >
                      <Text style={styles.filterText}>{group.Name}</Text>
                    </Pressable>
                  );
                })}
              </ScrollView>
            )}
          </View>

          <View style={styles.filterRow}>
            <View style={{ height: 36, justifyContent: 'center' }}>
              <Text style={styles.filterText}>Location</Text>
            </View>
            <View style={{ flex: 3 }}>
              <TouchableOpacity
                style={styles.assigneeFilter}
                onPress={() => setLocationDropdown(!locationDropdown)}
              >
                <View style={{ flexDirection: 'row', flex: 2, alignItems: 'center' }}>
                  {selectedLocation === '' && (
                    <Text style={styles.chooseAssigneeText}>Choose Location</Text>
                  )}
                  {selectedLocation !== '' && (
                    <Text
                      numberOfLines={1}
                      style={[styles.chooseAssigneeText, { color: 'black', marginRight: 26 }]}
                    >
                      {locationName(selectedLocation)}
                    </Text>
                  )}
                  <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <FontAwesome
                      size={30}
                      style={{ color: Colors.light.hereworksPrimaryGrey800, marginRight: 12 }}
                      name={'angle-down'}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            </View>
            {locationDropdown && (
              <ScrollView
                style={{
                  width: '100%',
                  height: 200,
                  backgroundColor: '#fff',
                  borderWidth: 1.5,
                  borderColor: Colors.light.hereworksBlue100,
                  borderRadius: 10,
                }}
                nestedScrollEnabled={true}
              >
                <Pressable
                  onPress={() => {
                    props.setProblems(props.allProblems);
                    setSelectedLocation('');
                    setLocationDropdown(false);
                  }}
                  style={(state) => [
                    {
                      paddingLeft: 10,
                      paddingVertical: 10,
                      borderRadius: 4,
                      marginVertical: 4,
                      backgroundColor: state.hovered ? Colors.light.hereworksWhite200 : undefined,
                    },
                  ]}
                >
                  <Text style={styles.filterText}>ALL LOCATIONS</Text>
                </Pressable>
                {props.locations.map((location: any, key) => {
                  if (location.Name.length === 0) {
                    return null;
                  }
                  return (
                    <Pressable
                      key={key}
                      onPress={() => {
                        setSelectedLocation(location.LocationId);
                        setLocationDropdown(false);
                      }}
                      style={(state) => [
                        {
                          paddingLeft: 10,
                          paddingVertical: 10,
                          borderRadius: 4,
                          marginVertical: 4,
                          backgroundColor: state.hovered
                            ? Colors.light.hereworksWhite200
                            : undefined,
                        },
                        selectedLocation === location.LocationId && {
                          borderColor: Colors.light.hereworksBlue400,
                          borderWidth: 1,
                        },
                      ]}
                    >
                      <Text style={styles.filterText}>{location.Name}</Text>
                    </Pressable>
                  );
                })}
              </ScrollView>
            )}
          </View>
        </Animatable.View>
        <View>
          {!filterAssigneeDropdown && (
            <>
              {compareEnabled && (
                <View
                  style={{
                    flex: 1,
                    height: 26,
                    marginBottom: 20,
                    marginRight: 13,
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Switch
                    isToggled={false}
                    onToggled={() => {
                      setCompare(!compare);
                    }}
                  />
                  <Text style={[styles.filterText, { marginRight: 70 }]}>Compare</Text>
                </View>
              )}
              {compare && (
                <Animatable.View animation={'fadeIn'}>
                  <TouchableOpacity
                    style={{
                      backgroundColor: Colors.light.hereworksLightBlue,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginHorizontal: 30,
                      marginTop: 14,
                      height: 34,
                      borderRadius: 6,
                    }}
                    onPress={() => {
                      setShowDatePeriods(!showDatePeriods);
                    }}
                  >
                    <Text
                      style={{
                        color: '#fff',
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 14,
                      }}
                    >
                      Pick a date range
                    </Text>
                  </TouchableOpacity>
                </Animatable.View>
              )}
            </>
          )}
        </View>
        <View
          style={{
            marginTop: 24,
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: '#fff',
              borderRadius: 4,
              borderWidth: 1,
              borderColor: Colors.light.hereworksLightBlue,
              alignItems: 'center',
              marginBottom: 8,
            }}
            onPress={() => {
              props.setFilterOpen(false);
              analytics('Cancel');
            }}
          >
            <Text
              style={{
                color: Colors.light.hereworksLightBlue,
                fontFamily: 'Roboto_400Regular',
                fontSize: 16,
                marginVertical: 8,
                marginHorizontal: 24,
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              backgroundColor: Colors.light.hereworksLightBlue,
              borderRadius: 4,
              alignItems: 'center',
            }}
            onPress={() => {
              props.setFilterOpen(false);
              props.setProblems([]);
              props.setAllProblems([]);
              props.setLoading(true);
              props.setSelectedProblem(undefined);
              props.setLowPriorityFilter(lowPriorityFilter);
              props.setMedPriorityFilter(medPriorityFilter);
              props.setHighPriorityFilter(highPriorityFilter);
              props.setCriticalPriorityFilter(criticalPriorityFilter);
              props.setDoneFilter(doneFilter);
              props.setInProgressFilter(inProgressFilter);
              props.setPausedFilter(pausedFilter);
              props.setWithoutStatusFilter(withoutStatusFilter);
              props.setSelectedAssignee(selectedAssignee);
              props.setSelectedAssetId(selectedAssetId);
              props.setSelectedUserGroupId(selectedUserGroupId);
              props.setSelectedLocation(selectedLocation);
              analytics('Apply');
            }}
          >
            <Text
              style={{
                color: '#fff',
                fontFamily: 'Roboto_400Regular',
                fontSize: 16,
                marginVertical: 8,
                marginHorizontal: 24,
              }}
            >
              Apply
            </Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </Animatable.View>
  );
}

const styles = StyleSheet.create({
  filterText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 18,
    marginBottom: 4,
    marginRight: 20,
    color: Colors.light.hereworksBlack600,
  },
  filterShadow: {
    shadowOffset: {
      width: 6,
      height: 6,
    },
    shadowOpacity: 0.2,
    shadowRadius: 20,
    shadowColor: 'black',
    elevation: 5,
  },
  filterContainer: {
    position: 'absolute',
    top: 66,
    right: 0,
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingBottom: 24,
  },
  mobileFilterContainer: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 10,
    zIndex: 2,
    paddingBottom: 24,
  },
  filterDatePickerContainerWeb: {
    paddingHorizontal: 24,
    zIndex: 2,
  },
  filterScrollView: {
    flexGrow: 1,
    paddingHorizontal: 24,
  },
  filterRow: {
    marginTop: 13,
  },
  assigneeFilter: {
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
    borderRadius: 10,
    height: 40,
    width: '100%',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    zIndex: 2,
  },
  chooseAssigneeText: {
    color: Colors.light.hereworksPrimaryGrey800,
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    marginLeft: 10,
  },
});
