import React, { ReactElement, useState } from 'react';
import {
  Pressable, StyleSheet, Text, View,
} from 'react-native';
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import Svg, { Polygon } from 'react-native-svg';
import { ActivityIndicator } from 'react-native-paper';
import EllipsisIconVertical from '../EllipsisIconVertical';
import Colors from '../../constants/Colors';
import MoveIcon from '../16pxIcons/MoveIcon';
import PriorityFlag from '../16pxIcons/PriorityFlag';
import RightArrow24 from '../24pxIcons/RightArrow24';
import deleteArea from '../../requests/DeleteArea';

type ContextType = {
  translateX: number;
  translateY: number;
};

export default function AreaView(props) {
  const [movingItem, setMovingItem] = useState(props.movingItem);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const translateXarea = useSharedValue(0);
  const translateYarea = useSharedValue(0);

  const panGestureArea = useAnimatedGestureHandler<PanGestureHandlerGestureEvent, ContextType>({
    onStart: (event, context) => {
      context.translateX = translateXarea.value;
      context.translateY = translateYarea.value;
    },
    onActive: (event, context) => {
      translateXarea.value = event.translationX + context.translateX;
      translateYarea.value = event.translationY + context.translateY;
    },
    onFinish: () => {
      const newAreas = Object.assign([], props.areas);
      let error = false;
      newAreas[props.index].points.forEach((point, i) => {
        if (point.number === i) {
          newAreas[props.index].points[i].x = point.x + translateXarea.value;
          newAreas[props.index].points[i].y = point.y + translateYarea.value;

          if (
            newAreas[props.index].points[i].x < 0
            || newAreas[props.index].points[i].y < 0
            || newAreas[props.index].points[i].x > props.imageWidth
            || newAreas[props.index].points[i].y > props.imageHeight
          ) {
            error = true;
            newAreas[props.index].points[i].x = point.x - translateXarea.value;
            newAreas[props.index].points[i].y = point.y - translateYarea.value;
          }
        }
      });
      if (error) {
        props.showAlert('Out of bounds!', true);
      } else {
        newAreas[props.index].top = props.getMinY(newAreas[props.index].points);
        newAreas[props.index].left = props.getMinX(newAreas[props.index].points);
        newAreas[props.index].right = props.getMaxX(newAreas[props.index].points);
        newAreas[props.index].bottom = props.getMaxY(newAreas[props.index].points);
        newAreas[props.index].polygon = props.getPointsString(newAreas[props.index].points);
        props.setAreas(newAreas);
        setMovingItem(false);
        props.setMovingItem(false);
        translateXarea.value = 0;
        translateYarea.value = 0;
      }
    },
  });

  const transformStyleArea = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: translateXarea.value,
      },
      {
        translateY: translateYarea.value,
      },
    ],
  }));

  return (
    <React.Fragment key={props.index}>
      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: '100%',
          },
          transformStyleArea,
        ]}
      >
        <Svg
          style={{
            zIndex: 2,
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <Polygon
            key={props.index}
            points={props.area.polygon}
            fill={Colors.light.hereworksBlue200}
            opacity={0.3}
          />
        </Svg>
      </Animated.View>
      <View
        style={{
          zIndex: 3,
          position: 'absolute',
          justifyContent: 'center',
          alignItems: 'center',
          top: props.area.top,
          left: props.area.left,
          width: props.area.right - props.area.left,
          height: props.area.bottom - props.area.top,
        }}
      >
        <Animated.View
          style={[{ flex: 1, justifyContent: 'center', alignItems: 'center' }, transformStyleArea]}
        >
          <View
            style={{
              backgroundColor: Colors.light.hereworksWhite400,
              borderColor: Colors.light.hereworksBlue100,
              borderWidth: 1,
              borderRadius: 12,
            }}
          >
            <View
              style={{
                paddingLeft: 8,
                paddingTop: 8,
                flexDirection: 'row',
                paddingBottom: 28,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderRadius: 4,
                  borderColor: props.area.highPriority
                    ? Colors.light.hereworksMediumYellow
                    : Colors.light.hereworksCheckGreen,
                  alignItems: 'center',
                  padding: 3,
                }}
              >
                <PriorityFlag
                  color={
                    props.area.highPriority
                      ? Colors.light.hereworksMediumYellow
                      : Colors.light.hereworksCheckGreen
                  }
                />
                <Text
                  style={{
                    color: props.area.highPriority
                      ? Colors.light.hereworksMediumYellow
                      : Colors.light.hereworksCheckGreen,
                    fontFamily: 'Roboto_500Medium',
                    fontSize: 12,
                    marginLeft: 2,
                  }}
                >
                  {props.area.highPriority ? 'High' : 'Normal'}
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: 'Poppins_500Medium',
                  color: Colors.light.hereworksBlack600,
                  fontSize: 14,
                  marginLeft: 8,
                }}
              >
                {props.area.name}
              </Text>
              {props.editMode && (
                <Pressable onPress={() => setMenuOpen(!menuOpen)}>
                  <EllipsisIconVertical />
                </Pressable>
              )}
              {!props.editMode && (
                <Pressable onPress={props.arrowPress}>
                  <RightArrow24 />
                </Pressable>
              )}
            </View>
            <PanGestureHandler onGestureEvent={panGestureArea}>
              <Pressable
                style={(state) => [
                  {
                    opacity: movingItem ? 1 : 0,
                    zIndex: state.hovered ? 10 : 3,
                    position: 'absolute',
                    bottom: -12,
                    right: -12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 4,
                    borderColor: Colors.light.hereworksBlue100,
                    borderWidth: 1,
                    borderRadius: 8,
                    backgroundColor: state.hovered
                      ? Colors.light.hereworksLightBlue
                      : Colors.light.hereworksWhite100,
                  },
                ]}
                disabled={!movingItem}
                onPressIn={() => {
                  setMovingItem(true);
                }}
              >
                {({ hovered }): ReactElement => (
                  <MoveIcon color={hovered ? '#fff' : Colors.light.hereworksBlue300} />
                )}
              </Pressable>
            </PanGestureHandler>
            {menuOpen && (
              <View
                style={{
                  position: 'absolute',
                  top: 40,
                  right: -60,
                  backgroundColor: '#fff',
                  borderRadius: 8,
                  borderColor: Colors.light.hereworksBlue200,
                  borderWidth: 1,
                  zIndex: 4,
                }}
              >
                <Pressable
                  onPress={() => {
                    setMenuOpen(false);
                    props.editArea();
                  }}
                >
                  <Text style={styles.menuText}>Edit area</Text>
                </Pressable>
                <Pressable
                  onPress={() => {
                    setMenuOpen(false);
                    props.setMovingItem(true);
                    setMovingItem(true);
                  }}
                >
                  <Text style={styles.menuText}>Move area</Text>
                </Pressable>
                <Pressable
                  onPress={() => {
                    const newAreas = Object.assign([], props.areas);
                    const areaToRemove = newAreas.splice(props.index, 1);
                    setLoading(true);
                    deleteArea(areaToRemove[0].locationId).then((response) => {
                      if (response.isError) {
                        props.showAlert(response.errorText, true);
                        setMenuOpen(false);
                      } else {
                        props.showAlert('Area removed successfully!');
                        props.setAreas(newAreas);
                        setMenuOpen(false);
                      }
                    });
                  }}
                >
                  <Text style={styles.menuText}>Remove area</Text>
                </Pressable>
                {loading && (
                  <View
                    style={{
                      position: 'absolute',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#00000040',
                    }}
                  >
                    <ActivityIndicator animating={true} />
                  </View>
                )}
              </View>
            )}
          </View>
        </Animated.View>
      </View>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  menuText: {
    fontFamily: 'Poppins_400Regular',
    paddingHorizontal: 12,
    paddingVertical: 9,
    color: Colors.light.hereworksBlack600,
    lineHeight: 21,
  },
});
