import AsyncStorage from '@react-native-async-storage/async-storage';

const addAsset = async (
  assetId,
  model,
  serial,
  manufacturer,
  description,
  template,
  customFields,
  name,
  location,
) => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('jwt', jwtToken ?? '');

    const customFieldsParsed = [];

    customFields?.forEach((field) => {
      if (!field.CustomName.toLowerCase().includes('model')
        && !field.CustomName.toLowerCase().includes('serial')
        && !field.CustomName.toLowerCase().includes('manu')
        && !field.CustomName.toLowerCase().includes('desc')
        && !field.CustomName.toLowerCase().includes('building')
        && !field.CustomName.toLowerCase().includes('floor')
        && !field.CustomName.toLowerCase().includes('room')
        && field.CustomName.toLowerCase() !== 'id'
        && field.CustomName.toLowerCase() !== 'name') {
        if (field.Id !== null) {
          customFieldsParsed.push({
            Id: field.Id, Name: field.CustomName, Value: field.CustomValue,
          });
        } else {
          customFieldsParsed.push({ Name: field.CustomName, Value: field.CustomValue });
        }
      }
    });

    const requestBody = JSON.stringify({
      assetId,
      ClientId: clientName,
      Name: name,
      ButtonPrototypeId: template,
      LocationId: location,
      Main: [
        { Name: 'Model', Value: model },
        { Name: 'Serial', Value: serial },
        { Name: 'Manufacturer', Value: manufacturer },
        { Name: 'Description', Value: description },
      ],
      CustomFields: customFieldsParsed,
    });

    const response = await fetch(
      `https://${apiDomain}/button/addasset`,
      {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
      },
    );
    return response;
  } catch (error) {
    return null;
  }
};

export default addAsset;
