import AsyncStorage from '@react-native-async-storage/async-storage';

const getTemplateGroups = async () => {
  const clientName = await AsyncStorage.getItem('clientName');
  const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
  const jwtToken = await AsyncStorage.getItem('token');

  // eslint-disable-next-line no-undef
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('clientid', clientName ?? 'hereworks');
  requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('jwt', jwtToken ?? '');

  return fetch(
    `https://${apiDomain}/button/buttongroups`,
    {
      method: 'GET',
      headers: requestHeaders,
    },
  ).then((res) => res?.json()?.then((json) => json))
    .catch(() => null);
};

export default getTemplateGroups;
