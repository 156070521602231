/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

// import { Card } from 'react-native-elements';
// eslint-disable-next-line object-curly-newline
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import BlueLineIcon from './BlueLineIcon';
import YellowLineIcon from './YellowLineIcon';
import PinkLineIcon from './PinkLineIcon';
import PurpleLineIcon from './PurpleLineIcon';
import LightBlueLineIcon from './LightBlueLineIcon';
import GreenLineIcon from './GreenLineIcon';
import TickedBoxIcon from './TickedBoxIcon';
import EmptyBoxIcon from './EmptyBoxIcon';
import BrownLineIcon from './BrownLineIcon';
import TealLineIcon from './TealLineIcon';

export default function DropdownMetricSelect({ selected, onSelect }) {
  // console.log(selected);
  const [externalToggle, setExternalToggle] = useState(
    selected.includes('external'),
  );
  const [internalToggle, setInternalToggle] = useState(
    selected.includes('internal'),
  );
  const [energyToggle, setEnergyToggle] = useState(selected.includes('energy'));
  const [peopleToggle, setPeopleToggle] = useState(selected.includes('people'));
  const [ITToggle, setITToggle] = useState(selected.includes('IT'));
  const [ergonomicsToggle, setErgonomicsToggle] = useState(
    selected.includes('ergonomics'),
  );
  const [amenitiesToggle, setAmenitiesToggle] = useState(
    selected.includes('amenities'),
  );
  const [occupancyToggle, setOccupancyToggle] = useState(
    selected.includes('occupancy'),
  );

  let checkedStrings = [];

  useEffect(() => {
    const listChecked = {
      internal: internalToggle,
      external: externalToggle,
      occupancy: occupancyToggle,
      energy: energyToggle,
      people: peopleToggle,
      IT: ITToggle,
      ergonomics: ergonomicsToggle,
      amenities: amenitiesToggle,
    };
    // console.log('checked effect: ' + JSON.stringify(listChecked));
    checkedStrings = Object.keys(listChecked).filter((k) => listChecked[k]);
    // console.log('strings: ' + checkedStrings);
    onSelect(checkedStrings);
  }, [
    internalToggle,
    externalToggle,
    occupancyToggle,
    energyToggle,
    peopleToggle,
    ITToggle,
    ergonomicsToggle,
    amenitiesToggle,
  ]);

  return (
    <View style={styles.dropDown}>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setInternalToggle(!internalToggle);
        }}
      >
        {internalToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <LightBlueLineIcon style={styles.item} />
      </TouchableOpacity>
      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setExternalToggle(!externalToggle);
        }}
      >
        {externalToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <PurpleLineIcon style={styles.item} />
      </TouchableOpacity>

      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setOccupancyToggle(!occupancyToggle);
        }}
      >
        {occupancyToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <BlueLineIcon style={styles.item} />
      </TouchableOpacity>

      <View style={styles.line}></View>

      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setEnergyToggle(!energyToggle);
        }}
      >
        {energyToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <PinkLineIcon style={styles.item} />
      </TouchableOpacity>
      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setPeopleToggle(!peopleToggle);
        }}
      >
        {peopleToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <GreenLineIcon style={styles.item} />
      </TouchableOpacity>
      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setITToggle(!ITToggle);
        }}
      >
        {ITToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <YellowLineIcon style={styles.item} />
      </TouchableOpacity>
      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setErgonomicsToggle(!ergonomicsToggle);
        }}
      >
        {ergonomicsToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <BrownLineIcon style={styles.item} />
      </TouchableOpacity>
      <View style={styles.line}></View>
      <TouchableOpacity
        style={styles.buttonStyle}
        onPress={() => {
          setAmenitiesToggle(!amenitiesToggle);
        }}
      >
        {amenitiesToggle ? <TickedBoxIcon /> : <EmptyBoxIcon />}
        <TealLineIcon style={styles.item} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonStyle: {
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    padding: 5,
    paddingLeft: 10,
    paddingHorizontal: 5,
  },
  line: {
    height: 1,
    width: '90%',
    backgroundColor: '#EAECEE',
    alignSelf: 'center',
  },
  dropDown: {
    flexDirection: 'column',
    borderRadius: 13,
    marginLeft: 80,
    backgroundColor: '#fff',
    width: 230,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.1,
    shadowRadius: 40,
    shadowColor: 'black',
    elevation: 5,
  },
  itemText: {
    flex: 1,
    marginLeft: 10,
    color: '#696D70',
  },
  item: {
    marginLeft: 10,
  },
  IconStyle: {
    marginLeft: 8,
  },
});
