import { FontAwesome } from '@expo/vector-icons';
import React, { useState } from 'react';
import {
  Image,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import Modal from 'react-native-modal';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../../constants/Colors';
import DeleteIcon16 from '../16pxIcons/DeleteIcon16';
import CloseModalIcon from '../CloseModalIcon';
import Style from '../../constants/Style';

export default function EditUser(props) {
  const [roleDropdownVisible, setRoleDropdownVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagsUpdated, setTagsUpdated] = useState(false);

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'EditUser',
      name: title,
    });
  };

  const renderUserIcon = (imageSource, facilityManager = false) => {
    const imageSize = props.mobile ? 80 : 100;
    return (
      <View
        style={{
          width: imageSize + 16,
          height: imageSize + 16,
          backgroundColor: facilityManager ? '#fff' : 'transparent',
          borderWidth: 1.5,
          borderColor: facilityManager ? Colors.light.hereworksBlue500 : 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: (imageSize + 16) / 2,
        }}
      >
        <Image
          style={{
            width: imageSize,
            height: imageSize,
            borderRadius: imageSize / 2,
            overflow: 'hidden',
          }}
          source={{
            uri: imageSource,
          }}
        />
      </View>
    );
  };

  const renderSaveCancel = () => (
    <View
      style={{
        flexDirection: 'row',
        marginVertical: 18,
      }}
    >
      <TouchableOpacity
        style={{
          backgroundColor: Colors.light.hereworksLightBlue,
          borderRadius: 4,
          marginRight: 12,
        }}
        onPress={() => {
          analytics('Save');
          props.setEditModalVisible(false);
        }}
      >
        <Text
          style={{
            color: '#fff',
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          Save
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          backgroundColor: '#fff',
          borderRadius: 4,
          borderWidth: 1,
          borderColor: Colors.light.hereworksLightBlue,
        }}
        onPress={() => {
          analytics('Cancel');
          props.setEditModalVisible(false);
        }}
      >
        <Text
          style={{
            color: Colors.light.hereworksLightBlue,
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          Cancel
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderTag = (title: string) => (
    <TouchableOpacity
      style={{
        backgroundColor: Colors.light.hereworksYellow50,
        borderColor: Colors.light.hereworksDarkYellow700,
        borderWidth: 1,
        borderRadius: 4,
        marginRight: 10,
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'center',
      }}
      onPress={() => {
        setTags(tags.filter((tag) => tag !== title));
        setTagsUpdated(!tagsUpdated);
        Analytics.logEvent('button_press', {
          screen: 'EditUser',
          name: 'Tag',
        });
      }}
    >
      <Text
        style={{
          color: Colors.light.hereworksDarkYellow700,
          fontFamily: 'Roboto_500Medium',
          fontSize: 12,
          lineHeight: 14,
          marginLeft: 8,
          marginVertical: 5.5,
        }}
      >
        {title}
      </Text>
      <DeleteIcon16 color={Colors.light.hereworksDarkYellow700} style={{ marginHorizontal: 4 }} />
    </TouchableOpacity>
  );

  return (
    <Modal
      isVisible={props.editModalVisible}
      style={{ alignItems: 'center' }}
      swipeDirection={props.mobile ? 'down' : null}
      onSwipeComplete={() => props.setEditModalVisible(false)}
      propagateSwipe={true}
      animationIn={props.mobile ? 'slideInUp' : 'fadeIn'}
      animationOut={props.mobile ? 'slideOutDown' : 'fadeOut'}
    >
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'position' : 'height'}>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
            backgroundColor: '#fff',
          }}
        >
          <TouchableOpacity
            onPress={() => {
              analytics('Close Modal');
              props.setEditModalVisible(false);
            }}
            style={Style.closeButton}
          >
            <CloseModalIcon />
          </TouchableOpacity>
          <Text
            style={{
              fontFamily: 'Poppins_600SemiBold',
              fontSize: props.mobile ? 20 : 28,
              marginBottom: 15,
              marginTop: 18,
              marginHorizontal: props.mobile ? 71 : 81.5,
              textAlign: 'center',
            }}
          >
            Give a role to the member
          </Text>
          {renderUserIcon(props.imageSource, props.facilityManager)}
          <Text
            style={{
              fontSize: props.mobile ? 16 : 20,
              fontFamily: 'Poppins_500Medium',
              color: Colors.light.hereworksBlack600,
              marginTop: 4,
              marginBottom: 16,
            }}
          >
            {props.user.name}
          </Text>
          <View style={{ alignSelf: 'stretch', marginHorizontal: props.mobile ? 16 : 40 }}>
            <Text style={styles.smallHeader}>Role</Text>
            <TouchableOpacity
              style={styles.dropdownBox}
              onPress={() => {
                setRoleDropdownVisible(!roleDropdownVisible);
                analytics(`Role dropdown ${roleDropdownVisible ? 'Close' : 'Open'}`);
              }}
            >
              <Text style={styles.dropdownBoxText}>{selectedRole ?? 'Choose role'}</Text>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <FontAwesome
                  size={20}
                  style={{
                    color: Colors.light.hereworksLightBlue,
                    marginRight: 12,
                  }}
                  name={roleDropdownVisible ? 'angle-up' : 'angle-down'}
                />
              </View>
              {roleDropdownVisible && (
                <View style={styles.dropdownView}>
                  {ROLES.map((role, key) => (
                    <TouchableOpacity
                      key={key}
                      onPress={() => {
                        setSelectedRole(role.title);
                        setRoleDropdownVisible(!roleDropdownVisible);
                        analytics(`Selected group: ${role.title}`);
                      }}
                    >
                      <Text style={styles.dropdownItemText}>{role.title}</Text>
                    </TouchableOpacity>
                  ))}
                </View>
              )}
            </TouchableOpacity>
            <Text style={styles.smallHeader}>Add tag</Text>
            <TextInput
              style={[
                styles.noteText,
                !props.mobile
                  ? {
                    // @ts-ignore
                    outlineStyle: 'none',
                  }
                  : {},
              ]}
              placeholderTextColor={Colors.light.hereworksBlack400}
              placeholder={'Enter tag'}
              onSubmitEditing={(text) => {
                const exTags = tags;
                exTags.push(text.nativeEvent.text);
                setTags(exTags);
                setTagsUpdated(!tagsUpdated);
              }}
            />
            <View
              style={{
                flexWrap: 'wrap',
                flexDirection: 'row',
                marginTop: 7,
              }}
            >
              {tags.map((tag, key) => (
                <View key={key}>{renderTag(tag)}</View>
              ))}
            </View>
          </View>
          {renderSaveCancel()}
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  noteText: {
    color: Colors.light.hereworksPrimaryGrey,
    alignSelf: 'stretch',
    fontFamily: 'Roboto_400Regular',
    alignItems: 'center',
    fontSize: 16,
    backgroundColor: Colors.light.hereworksWhite200,
    alignContent: 'center',
    marginVertical: 5,
    paddingVertical: 10,
    paddingHorizontal: 8,
    borderRadius: 6,
    textAlignVertical: 'top',
  },
  smallHeader: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
    alignSelf: 'flex-start',
  },
  dropdownBox: {
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksPaleBlue900,
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 16,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    zIndex: 1,
  },
  dropdownView: {
    position: 'absolute',
    width: '100%',
    top: 41,
    zIndex: 5,
    borderRadius: 10,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksPaleBlue900,
    backgroundColor: '#fff',
  },
  dropdownItemText: {
    marginLeft: 12,
    marginVertical: 9,
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
  },
  dropdownBoxText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.light.hereworksPrimaryGrey,
    marginVertical: 9.5,
    marginLeft: 12,
  },
});

let ROLES = [
  {
    title: 'Facility Manager',
  },
  {
    title: 'Software Engineer',
  },
];
