import React, { ReactElement, useState } from 'react';
import {
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Modal from 'react-native-modal';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../../constants/Colors';
import Style from '../../constants/Style';
import CloseModalIcon from '../CloseModalIcon';
import UploadIcon from '../24pxIcons/UploadIcon';
import SearchIcon from '../SearchIcon';
import GroupUserRow from './GroupUserRow';
import updateUserGroup from '../../requests/UpdateUserGroup';
import updateUserGroupUsers from '../../requests/UpdateUserGroupUsers';
import AlertModal from '../AlertModal';
import addUserGroup from '../../requests/AddUserGroup';

interface Props {
  title: any;
  description: any;
  mobile: any;
  modalVisible: any;
  users: any;
  groupId: any;
  // eslint-disable-next-line no-unused-vars
  setModalVisible(value: any): any;
  // eslint-disable-next-line no-unused-vars
  updatedGroup(newTitle: string, newDesc: string, count: number): void;
}

export type PressableState = Readonly<{
  hovered?: boolean;
}>;

const GroupEditModal = (props: Props) => {
  const [groupNameFocused, setGroupNameFocused] = useState(false);
  const [groupDescriptionFocused, setGroupDescriptionFocused] = useState(false);
  const [groupUserSearchValue, setAddGroupUserSearchValue] = useState('');
  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const [nameValue, setNameValue] = useState(props.title);
  const [descriptionValue, setDescriptonValue] = useState(props.description);
  const [users, setUsers] = useState(props.users ?? []);
  const [saving, setSaving] = useState(false);
  const [titleValid, setTitleValid] = useState(true);

  const maxTitleLength = 40;

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const showAlert = (title: string, error: boolean = true, message: string = '') => {
    setAlertTitle(title);
    setAlertError(error);
    setAlertMessage(message);
    setAlertVisible(true);
  };

  React.useEffect(() => {
    setUsers(props.users ?? []);
  }, [props.users]);
  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'GroupEditModal',
      name: title,
    });
  };

  React.useEffect(() => {
    setDescriptionHeight(Math.min(200, Math.max(68, descriptionHeight)));
  }, [descriptionHeight]);

  function validateTitle(title) {
    let valid = true;
    if (
      title === undefined
      || title === null
      || title?.trim().length === 0
      || title?.trim().length > maxTitleLength
    ) {
      valid = false;
      setTitleValid(false);
    } else if (
      props.title?.toLocaleLowerCase() !== 'admin'
      && title?.trim().toLocaleLowerCase() === 'admin'
    ) {
      valid = false;
      setTitleValid(false);
    } else {
      setTitleValid(true);
    }
    return valid;
  }

  const renderGroupDetails = () => (
    <View style={{ marginHorizontal: props.mobile ? 16 : 0 }}>
      <Text
        style={{
          marginBottom: props.mobile ? 19 : 32,
          marginRight: props.mobile ? 0 : 285,
          fontSize: props.mobile ? 16 : 20,
          fontFamily: 'Poppins_500Medium',
          color: Colors.light.hereworksBlack600,
        }}
      >
        Give a name of group
      </Text>
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity
          style={{
            height: 64,
            width: 64,
            backgroundColor: Colors.light.hereworksDarkBlue,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 32,
          }}
          onPress={() => {
            analytics('Upload Icon');
            showAlert('Feature coming soon!', false);
          }}
        >
          <UploadIcon />
        </TouchableOpacity>
        <View style={{ justifyContent: 'center', marginLeft: 16 }}>
          <Text
            style={{
              marginBottom: 8,
              fontFamily: 'Roboto_400Regular',
              fontSize: 16,
              color: Colors.light.hereworksBlack600,
            }}
          >
            Upload an image
          </Text>
          <TouchableOpacity
            onPress={() => {
              analytics('Upload');
              showAlert('Feature coming soon!', false);
            }}
          >
            <Text
              style={{
                fontFamily: 'Roboto_500Medium',
                fontSize: 12,
                color: Colors.light.hereworksBlue500,
              }}
            >
              Upload
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <Text style={styles.textBoxHeaders}>Group name</Text>
      <TextInput
        style={[
          styles.noteText,
          !props.mobile
            ? {
              // @ts-ignore
              outlineStyle: 'none',
            }
            : {},
          { borderColor: groupNameFocused ? Colors.light.hereworksBlue300 : '#fff' },
        ]}
        placeholderTextColor={Colors.light.hereworksBlack400}
        placeholder={'Group name'}
        onFocus={() => setGroupNameFocused(true)}
        onBlur={() => setGroupNameFocused(false)}
        value={nameValue ?? ''}
        onChangeText={(text) => {
          const valid = validateTitle(text);
          if (valid) {
            setNameValue(text);
          }
        }}
        disabled={props.title?.toLocaleLowerCase() === 'admin'}
      />
      {nameValue?.trim().length > maxTitleLength && (
        <Text style={[styles.validationText, { color: Colors.light.hereworksUrgentRed }]}>
          {`${nameValue?.trim().length ?? props.title?.trim().length ?? 0}/${maxTitleLength}`}
        </Text>
      )}
      <Text style={styles.textBoxHeaders}>Description</Text>
      <TextInput
        style={[
          styles.noteText,
          !props.mobile
            ? {
              // @ts-ignore
              outlineStyle: 'none',
            }
            : {},
          { borderColor: groupDescriptionFocused ? Colors.light.hereworksBlue300 : '#fff' },
          { minHeight: descriptionHeight },
        ]}
        placeholderTextColor={Colors.light.hereworksBlack400}
        placeholder={'Description'}
        onFocus={() => setGroupDescriptionFocused(true)}
        onBlur={() => setGroupDescriptionFocused(false)}
        value={descriptionValue ?? ''}
        onChangeText={(text) => {
          setDescriptonValue(text);
        }}
        multiline={true}
        scrollEnabled={false}
        onContentSizeChange={(event) => {
          setDescriptionHeight(event.nativeEvent.contentSize.height);
        }}
      />
    </View>
  );

  const renderGroupMembers = () => (
    <View
      style={{
        marginLeft: props.mobile ? 16 : 32,
        marginRight: props.mobile ? 16 : 0,
      }}
    >
      <Text
        style={{
          marginBottom: props.mobile ? 19 : 32,
          fontSize: props.mobile ? 16 : 20,
          fontFamily: 'Poppins_500Medium',
          color: Colors.light.hereworksBlack600,
        }}
      >
        Select members
      </Text>
      <View
        style={[
          styles.dropdownBox,
          { zIndex: 1, marginTop: 0, width: props.mobile ? undefined : 450 },
        ]}
      >
        <TextInput
          style={[
            styles.dropdownBoxText,
            !props.mobile && {
              // @ts-ignore
              outlineStyle: 'none',
            },
          ]}
          placeholder={'Search'}
          placeholderTextColor={Colors.light.hereworksBlack400}
          onChangeText={(text) => {
            setAddGroupUserSearchValue(text);
          }}
        />
        <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 15 }}>
          <SearchIcon />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderColor: Colors.light.hereworksBlue100,
          alignItems: 'center',
          paddingBottom: 8,
          marginTop: 12,
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto_500Medium',
            fontSize: 10,
            color: Colors.light.hereworksBlack400,
            flex: 1,
          }}
        >
          ALL USERS
        </Text>
        <Pressable
          onPress={() => {
            const updatedUsers = [...users];
            updatedUsers.forEach((user) => {
              user.selected = false;
              user.UserGroupIds?.forEach((group, index) => {
                if (group === props.groupId) {
                  user.deleted = true;
                  user.UserGroupIds.splice(index, 1);
                }
              });
            });
            setUsers(updatedUsers);
          }}
        >
          {({ hovered }: PressableState): ReactElement => (
            <View
              style={{
                backgroundColor: hovered ? Colors.light.hereworksRed : Colors.light.hereworksRed50,
                borderWidth: 1,
                borderColor: hovered ? Colors.light.hereworksRed : Colors.light.hereworksLightPink,
                borderRadius: 7,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 12,
                  color: hovered ? '#fff' : Colors.light.hereworksRed,
                  marginHorizontal: 16,
                  marginVertical: 7,
                }}
              >
                Remove all
              </Text>
            </View>
          )}
        </Pressable>
      </View>
      <View style={{ height: 269, marginTop: 10 }}>
        <ScrollView indicatorStyle={'black'} nestedScrollEnabled={true}>
          {(users ?? [])
            .filter((user) => user.FullName?.toLocaleLowerCase()
              .includes(groupUserSearchValue.toLocaleLowerCase()))
            .map((user) => (
              <View key={`${user.ClientIdEmail}${user.selected}`}>
                <GroupUserRow
                  name={`${user.GivenName} ${user.FamilyName}`}
                  icon={user.icon}
                  selected={user.selected ?? (user.UserGroupIds ?? []).indexOf(props.groupId) > -1}
                  setSelected={(selected) => {
                    if (selected) {
                      user.selected = selected;
                    } else {
                      user.selected = selected;
                      user.deleted = true;
                    }
                    const updatedUsers = [...users];
                    setUsers(updatedUsers);
                  }}
                />
              </View>
            ))}
        </ScrollView>
      </View>
    </View>
  );

  const renderSaveCancel = () => (
    <View
      style={{
        flexDirection: 'row',
        marginVertical: props.mobile ? 25 : 40,
      }}
    >
      <TouchableOpacity
        style={{
          backgroundColor: Colors.light.hereworksLightBlue,
          borderRadius: 4,
          marginRight: 12,
          alignItems: 'center',
          justifyContent: 'center',
          opacity: titleValid ? 1 : 0.4,
        }}
        disabled={!titleValid}
        onPress={() => {
          analytics('Save');
          if (titleValid) {
            setSaving(true);
            const userIds = [];
            const deletedUserIds = [];
            users.forEach((user) => {
              if (user.selected) {
                userIds.push(user.ClientIdEmail);
              } else if (user.deleted) {
                deletedUserIds.push(user.ClientIdEmail);
              }
            });
            if (props.title !== undefined) {
              updateUserGroup(props.groupId, nameValue?.trim(), descriptionValue).then(
                (response) => {
                  if (response?.isError) {
                    setSaving(false);
                    showAlert('Error', true, response.errorText);
                  } else {
                    updateUserGroupUsers(props.groupId, userIds, deletedUserIds, props.title).then(
                      (res) => {
                        if (res?.isError) {
                          setSaving(false);
                          showAlert('Error', true, response.errorText);
                        } else {
                          setSaving(false);
                          props.updatedGroup(
                            nameValue?.trim(),
                            descriptionValue,
                            users.filter(
                              (user) => user.selected
                                ?? (user.UserGroupIds ?? []).indexOf(props.groupId) > -1,
                            ).length,
                          );
                        }
                      },
                    );
                  }
                },
              );
            } else {
              addUserGroup(nameValue?.trim(), descriptionValue, userIds).then((res) => {
                if (res?.isError) {
                  setSaving(false);
                  showAlert('Error', true, res.errorText);
                } else {
                  setSaving(false);
                  props.updatedGroup(
                    nameValue?.trim(),
                    descriptionValue,
                    users.filter(
                      (user) => user.selected ?? (user.UserGroupIds ?? [])
                        .indexOf(props.groupId) > -1,
                    ).length,
                  );
                }
              });
            }
          }
        }}
      >
        <Text
          style={{
            color: saving ? Colors.light.hereworksLightBlue : '#fff',
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          Save
        </Text>
        {saving && <ActivityIndicator style={{ position: 'absolute' }} color="#fff" />}
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          backgroundColor: '#fff',
          borderRadius: 4,
          borderWidth: 1,
          borderColor: Colors.light.hereworksLightBlue,
        }}
        onPress={() => {
          analytics('Cancel');
          props.setModalVisible(false);
        }}
      >
        <Text
          style={{
            color: Colors.light.hereworksLightBlue,
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          Cancel
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderModalContent = () => (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'position' : 'height'}>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 10,
          backgroundColor: '#fff',
          marginTop: props.mobile ? 16 : 0,
        }}
      >
        <Text
          style={{
            marginTop: props.mobile ? 19 : 60,
            marginBottom: props.mobile ? 19 : 32,
            marginHorizontal: props.mobile ? 104 : 0,
            fontSize: props.mobile ? 20 : 28,
            fontFamily: 'Poppins_600SemiBold',
            color: Colors.light.hereworksBlack600,
          }}
        >
          {props.title === undefined ? 'Create group' : 'Edit group'}
        </Text>
        <View
          style={{
            flexDirection: props.mobile ? 'column' : 'row',
            marginHorizontal: props.mobile ? 0 : 40,
            alignSelf: 'stretch',
          }}
        >
          {renderGroupDetails()}
          {renderGroupMembers()}
        </View>
        {renderSaveCancel()}
        <TouchableOpacity
          onPress={() => {
            analytics('Close Modal');
            props.setModalVisible(false);
          }}
          style={Style.closeButton}
        >
          <CloseModalIcon />
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  );

  return (
    <Modal
      isVisible={props.modalVisible}
      style={{ alignItems: 'center' }}
      onBackButtonPress={() => props.setModalVisible(false)}
      propagateSwipe={true}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
    >
      {!props.mobile && renderModalContent()}
      {props.mobile && (
        <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1 }}>
          {renderModalContent()}
        </ScrollView>
      )}
      <AlertModal
        modalVisible={alertVisible}
        error={alertError}
        title={alertTitle}
        message={alertMessage}
        setModalVisible={setAlertVisible}
      />
    </Modal>
  );
};

const styles = StyleSheet.create({
  noteText: {
    color: Colors.light.hereworksBlack600,
    alignSelf: 'stretch',
    fontFamily: 'Roboto_400Regular',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: Platform.OS === 'ios' ? undefined : 24,
    backgroundColor: Colors.light.hereworksWhite300,
    borderWidth: 1,
    alignContent: 'center',
    marginVertical: 5,
    paddingVertical: 10,
    paddingHorizontal: 8,
    borderRadius: 5,
    textAlignVertical: 'top',
  },
  textBoxHeaders: {
    marginTop: 12,
    color: Colors.light.hereworksBlack400,
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
  },
  dropdownBox: {
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
    borderRadius: 10,
    marginTop: 24,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    zIndex: 2,
  },
  dropdownBoxText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    marginVertical: 9.5,
    marginLeft: 12,
    flex: 1,
  },
  validationText: {
    marginTop: 4,
    color: Colors.light.hereworksBlack300,
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 20,
  },
});

export default GroupEditModal;
