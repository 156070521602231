import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SmallStopwatchIcon = (props: SvgProps) => (
  <Svg width={34} height={34} fill="none" {...props}>
    <Path
      d="m32.25 6.43-3.083-3.084a.531.531 0 0 0-.752 0L27.25 4.512a.531.531 0 0 0 0 .752l3.084 3.083a.531.531 0 0 0 .751 0l1.166-1.166a.531.531 0 0 0 0-.75ZM26.498 9.099l2.294-2.294M17 32.941c7.628 0 13.813-6.184 13.813-13.812C30.813 11.5 24.628 5.316 17 5.316c-7.628 0-13.813 6.185-13.813 13.813C3.188 26.757 9.372 32.94 17 32.94Z"
      stroke="#121B23"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M15.406 5.407V2.13a.531.531 0 0 1 .531-.531h2.126a.531.531 0 0 1 .53.53v3.28M17 8.504v9.031M17 20.723a1.594 1.594 0 1 0 0-3.188 1.594 1.594 0 0 0 0 3.188ZM17 20.723v3.204"
      stroke="#121B23"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default SmallStopwatchIcon;
