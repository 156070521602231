import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PrinterIcon24 = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M17.09 16H20a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.91"
      stroke="#89A7CB"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M18 21H6l2-11h8l2 11ZM17 3H7v3h10V3Z"
      stroke="#89A7CB"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default PrinterIcon24;
