import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

interface Props extends SvgProps {
  add?: boolean;
}

const ImageIcon24 = (props: Props) => <Svg
  width='24'
  height='24'
  viewBox='0 0 24 24'
  fill='none'
  {...props}
>
  <G id='Image'>
    <Path
      id='primary-upstroke'
      d='M10.0781 8.29004H10.1781'
      stroke={props.color ?? '#3379BD'}
      strokeWidth='1.95'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      id='primary'
      d='M21 5V19C21 19.2652 20.8946 19.5196 20.7071 19.7071C20.5196 19.8946 20.2652 20 20 20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19V5C3 4.73478 3.10536 4.48043 3.29289 4.29289C3.48043 4.10536 3.73478 4 4 4H20C20.2652 4 20.5196 4.10536 20.7071 4.29289C20.8946 4.48043 21 4.73478 21 5ZM10.51 14.8L15.71 20H20C20.2652 20 20.5196 19.8946 20.7071 19.7071C20.8946 19.5196 21 19.2652 21 19V15.73L15.29 10L10.51 14.8ZM3 16.71V19C3 19.2652 3.10536 19.5196 3.29289 19.7071C3.48043 19.8946 3.73478 20 4 20H15.71L7.71 12L3 16.71Z'
      stroke={props.color ?? '#3379BD'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </G>
</Svg>;

export default ImageIcon24;
