import Moment from 'moment';
import { Dimensions, Platform } from 'react-native';

const isWeb: boolean = Platform.OS === 'web' && Dimensions.get('window').width > 500;

export const tickValSamples = (unfilteredArray: any[], dateTickCount: number) => dateTickCount
  && unfilteredArray
    .filter((value, index, array) => index === 0 || [...Array(dateTickCount).keys()]
      .map((item) => ((index > array.length / 2)
        ? Math.ceil(((array.length - 1) * item) / (dateTickCount - 1))
        : Math.floor(((array.length - 1) * item) / (dateTickCount - 1))))
      .includes(index));

const differenceHours = (axisData: { date: string }[]) => Math.abs(new Date(axisData[
  axisData.length - 1]?.date).valueOf() - new Date(axisData[0]?.date).valueOf()) / 3600000;

export const tooltipDateLabel = (axisData: { date: string }[], index: number, short?: boolean) => {
  const weekday: 'short' | 'long' = short ? 'short' : 'long';
  const month: 'short' | 'long' = weekday;
  const date = new Date(axisData[index]?.date);
  if (!axisData[index]?.date) return '-';
  const diff: number = differenceHours(axisData);
  if (isWeb) {
    if (diff <= 24) return Moment(date).format('MMM DD[, ]h:mm a');
    if (diff <= 168) return `${date.toLocaleString('en-us', { weekday })}: ${Moment(date).format('MMM DD[, ]h a')}`;
    return `${date.toLocaleString('en-us', { weekday })}: ${date.toLocaleString('default', { month })} ${date.getDate()}`;
  }
  if (diff <= 24) return Moment(date).format('h:mm a');
  if (diff <= 168) return Moment(date).format('MMM DD[, ] h a');
  return `${date.toLocaleString('en-us', { weekday: 'short' })}: ${Moment(date).format('MMM DD')}`;
};

export const axisDateLabel = (axisData: { date: string }[], index: number) => {
  if (!axisData[index]?.date) return '';
  const diff: number = differenceHours(axisData);
  if (isWeb) {
    if (diff < 24) return Moment(axisData[index].date).format('h a');
    if (diff < 144) return Moment(axisData[index].date).format('MMM DD - h a');
    return Moment(axisData[index].date).format('MMM DD');
  }
  if (diff < 24) return Moment(axisData[index].date).format('h:mm[\n] a');
  return Moment(axisData[index].date).format('MMM[\n]DD');
};

export const checkIfDatesIdentical = (start: Date, end: Date) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  return startDate.getDate() === endDate.getDate() && startDate.getMonth() === endDate.getMonth()
    && startDate.getFullYear() === endDate.getFullYear();
};

export const hideRepeatedSuffix = (index: number, indices: number[], values: string[]) => {
  if (index === 0) return values[indices[index]];
  const currentMonth: string = values[indices[index]]?.split('\n')[1];
  const prevMonth: string = values[indices[index - 1]]?.split('\n')[1];
  if (currentMonth === prevMonth) return values[indices[index]]?.split('\n')[0];
  return values[indices[index]];
};
