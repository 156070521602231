import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LeftArrow16 = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="M9 12 5 8l4-4"
      stroke="#0058AD"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LeftArrow16;
