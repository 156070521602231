import { BarCodeScanner } from 'expo-barcode-scanner';
import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import {
  Platform, StyleSheet, Text, TextInput, TouchableOpacity, View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Modal from 'react-native-modal';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import ScanIcon16 from './16pxIcons/ScanIcon16';
import CloseModalIcon from './CloseModalIcon';

interface CustomTextFieldProps {
  title: string,
  value: string,
  updateValue: Dispatch<SetStateAction<string>>,
  editable?: boolean,
  required?: boolean,
  scanner?: boolean,
  mobile?: boolean,
  secure?: boolean,
  errorText?: string,
  invalid?: boolean,
}

export default function CustomTextField({
  title, value, updateValue, editable = true, required = false, scanner = false, mobile,
  secure = false, errorText, invalid = false,
}: CustomTextFieldProps) {
  const [focused, setFocused] = useState(false);
  const [scannerOpen, setScannerOpen] = useState(false);
  const [cameraAccess, setCameraAccess] = useState(null);

  function getBorderColor() {
    if (invalid) {
      return Colors.light.hereworksRed500;
    }
    return focused ? Colors.light.hereworksBlue300 : '#fff';
  }

  const showScanner = () => {
    if (cameraAccess === null) {
      return <Text>Requesting for camera permission</Text>;
    }
    if (cameraAccess === false) {
      return <Text>No access to camera</Text>;
    }

    return (
      <BarCodeScanner
        onBarCodeScanned={scannerOpen ? handleBarCodeScanned : undefined}
        style={StyleSheet.absoluteFillObject}
      />
    );
  };

  const handleBarCodeScanned = ({ data }) => {
    setScannerOpen(false);
    updateValue(data);
  };

  useEffect(() => {
    if (mobile && scanner && scannerOpen && !cameraAccess) {
      BarCodeScanner.requestPermissionsAsync().then((newResponse) => {
        setCameraAccess(newResponse.granted);
      });
    }
  }, [scannerOpen]);

  const topSafeAreaInset = useSafeAreaInsets().top;

  return (
    <>
      {title && <Text style={styles.heading}>{`${title}${required ? ' *' : ''}`}</Text>}
      <View style={styles.textContainer}>
        <TextInput
          secureTextEntry={secure}
          style={[
            styles.inputText,
            !mobile
              ? {
                // @ts-ignore
                outlineStyle: 'none',
              }
              : {},
            {
              borderColor: getBorderColor(),
              color:
                editable ?? true
                  ? Colors.light.hereworksBlack600
                  : Colors.light.hereworksBlack100,
            },
          ]}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={value ?? ''}
          onChangeText={updateValue}
          editable={editable}
        />
        {mobile && scanner && (
          <>
            <TouchableOpacity
              onPress={() => setScannerOpen(true)}
              style={styles.detailedFilterButton}
            >
              <ScanIcon16 />
            </TouchableOpacity>
            {scannerOpen && (
              <Modal
                isVisible={scannerOpen}
                style={{ alignItems: 'center', margin: 0 }}
                onSwipeComplete={() => setScannerOpen(false)}
                swipeDirection={'down'}
                animationIn={'fadeIn'}
                animationOut={'fadeOut'}
              >
                {showScanner()}
                <TouchableOpacity
                  onPress={() => setScannerOpen(false)}
                  style={[Style.closeButton, { top: topSafeAreaInset ?? 32 }]}
                >
                  <CloseModalIcon />
                </TouchableOpacity>
              </Modal>
            )}
          </>
        )}
      </View>
      {invalid && (
        <Text style={styles.errorText}>
          {errorText ?? 'This field is required!'}
        </Text>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  textContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  heading: {
    fontSize: 16,
    lineHeight: 24,
    marginTop: 12,
    marginBottom: 4,
    alignSelf: 'flex-start',
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
  },
  inputText: {
    flex: 1,
    color: Colors.light.hereworksBlack600,
    alignSelf: 'stretch',
    fontFamily: 'Poppins_400Regular',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: Platform.OS === 'ios' ? undefined : 21,
    alignContent: 'center',
    backgroundColor: Colors.light.hereworksWhite200,
    paddingHorizontal: 8,
    paddingVertical: 10,
    borderRadius: 5,
    borderWidth: 1,
    minWidth: 1,
  },
  errorText: {
    color: Colors.light.hereworksRed500,
    fontFamily: 'Roboto_400Regular',
    marginTop: 4,
    fontSize: 12,
    lineHeight: 20,
    alignSelf: 'flex-start',
  },
  detailedFilterButton: {
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: '#323247',
    marginStart: 8,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    aspectRatio: 1,
    padding: 14,
  },
});
