/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "dminashboard-20220310162203-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://dminashboard-20220310162203-hostingbucket-dev.s3-website-eu-west-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "eu-west-1:c92577e8-437a-461d-a7b5-e8844a64c9a5",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_O6SoPKFbT",
    "aws_user_pools_web_client_id": "2rqvto3pqihkcsmfp01lmg3va",
    "oauth": {
        "domain": "hereworkapplication14ba64f2-14ba64f2-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "hereworksadmin://",
        "redirectSignOut": "hereworksadmin://",
        // for local
        // "redirectSignIn": "exp://192.168.0.8:19000",
        // "redirectSignOut": "exp://192.168.0.8:19000",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
