import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import Moment from 'moment';
import CompareArrowsIcon from './CompareArrowsIcon';
import Colors from '../constants/Colors';

interface CompareDatesViewProps {
  range,
  compareRange
}

const CompareDatesView = (props: CompareDatesViewProps) => <View style={styles.container}>
  <Text style={styles.text}>
    {Moment(props.range.start).format('D MMM')} {' - '}{' '}
    {Moment(props.range.end ?? props.range.start).format('D MMM')}
  </Text>
  <CompareArrowsIcon style={{ marginHorizontal: 10 }} />
  <Text style={[styles.text, styles.textCompared]}>
    {Moment(props.compareRange.start).format('D MMM')} {' - '}{' '}
    {Moment(props.compareRange.end ?? props.compareRange.start).format('D MMM')}
  </Text>
</View>;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: Colors.light.hereworksBlack600,
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_500Medium',
  },
  textCompared: {
    color: Colors.light.hereworksYellow500,
  },
});

export default CompareDatesView;
