import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import Colors from '../constants/Colors';
import OrionArrowIcon from './24pxIcons/OrionArrowIcon';

type Props = {
  setSelectedPage: any;
  selectedPage: number;
  numberOfPages: number;
  mobile: boolean;
};

const PageNumbers = (props: Props) => {
  const pageNumbers = [];
  for (let i = 1; i <= props.numberOfPages; i += 1) {
    pageNumbers.push(
      <TouchableOpacity onPress={() => props.setSelectedPage(i)} key={i}>
        <Text
          style={[
            styles.pageNumber,
            {
              color:
                props.selectedPage === i
                  ? Colors.light.hereworksBlue500
                  : Colors.light.hereworksBlack600,
            },
          ]}
        >
          {i}
        </Text>
      </TouchableOpacity>,
    );
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        marginVertical: 25,
        justifyContent: props.mobile ? 'flex-start' : 'center',
        alignItems: 'center',
      }}
    >
      <TouchableOpacity
        onPress={() => {
          if (props.selectedPage > 1) {
            props.setSelectedPage(props.selectedPage - 1);
          }
        }}
        style={{
          opacity: props.selectedPage > 1 ? 1 : 0.4,
        }}
        disabled={props.selectedPage === 1}
      >
        <OrionArrowIcon />
      </TouchableOpacity>
      {pageNumbers}
      <TouchableOpacity
        onPress={() => {
          props.setSelectedPage(props.selectedPage + 1);
        }}
        style={{
          opacity: props.selectedPage < pageNumbers.length ? 1 : 0.4,
        }}
        disabled={props.selectedPage === pageNumbers.length}
      >
        <OrionArrowIcon style={{ transform: [{ rotateZ: '180deg' }] }} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  pageNumber: {
    fontSize: 14,
    fontFamily: 'Roboto_400Regular',
    width: 32,
    height: 24,
    textAlign: 'center',
    lineHeight: 24,
  },
});

export default PageNumbers;
