import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const HomeIcon = (props: SvgProps) => (
    <Svg
        width={24}
        height={26}
        fill={props.color ?? 'white'}
        {...props}
    >
        <Path
            d="M12 1.333 1.5 9.5v12.833a2.333 2.333 0 0 0 2.333 2.334H8.5V14h7v10.667h4.667a2.333 2.333 0 0 0 2.333-2.334V9.5L12 1.333Z"
            stroke={props.color ?? '#919193'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default HomeIcon;
