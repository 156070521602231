import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CalendarIcon = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      d="M15.833 3.334H4.167c-.92 0-1.667.746-1.667 1.667v11.666c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.746 1.667-1.667V5.001c0-.92-.746-1.667-1.667-1.667ZM13.332 1.666v3.333M6.668 1.666v3.333M2.5 8.334h15"
      stroke={props.color ?? '#003A70'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default CalendarIcon;
