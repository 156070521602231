import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import Modal from 'react-native-modal';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import WarningIcon from './60pxIcons/WarningIcon';
import CloseModalIcon from './CloseModalIcon';

export default function DeleteModal(props) {
  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'DeleteModal',
      name: title,
    });
  };

  const renderDeleteCancel = () => (
    <View
      style={{
        flexDirection: 'row',
        marginBottom: 40,
      }}
    >
      <TouchableOpacity
        style={{
          backgroundColor: Colors.light.hereworksLightBlue,
          borderRadius: 4,
          marginRight: 12,
        }}
        onPress={() => {
          analytics('Delete');
          props.deleteAction();
          props.setModalVisible(false);
        }}
      >
        <Text
          style={{
            color: '#fff',
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          Delete
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          backgroundColor: '#fff',
          borderRadius: 4,
          borderWidth: 1,
          borderColor: Colors.light.hereworksLightBlue,
        }}
        onPress={() => {
          analytics('Cancel');
          props.setModalVisible(false);
        }}
      >
        <Text
          style={{
            color: Colors.light.hereworksLightBlue,
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          Cancel
        </Text>
      </TouchableOpacity>
    </View>
  );

  return (
    <Modal
      isVisible={props.modalVisible}
      style={{ alignItems: 'center' }}
      onSwipeComplete={() => props.setModalVisible(false)}
      swipeDirection={props.mobile ? 'down' : null}
      animationIn={props.mobile ? 'slideInUp' : 'fadeIn'}
      animationOut={props.mobile ? 'slideOutDown' : 'fadeOut'}
    >
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          borderRadius: 10,
          backgroundColor: '#fff',
        }}
      >
        <TouchableOpacity
          onPress={() => {
            analytics('Close Modal');
            props.setModalVisible(false);
          }}
          style={Style.closeButton}
        >
          <CloseModalIcon />
        </TouchableOpacity>
        <WarningIcon style={{ marginTop: 60 }} />
        <Text
          style={{
            fontSize: 28,
            fontFamily: 'Poppins_600SemiBold',
            color: Colors.light.hereworksBlack600,
            marginTop: 27,
            marginBottom: 12,
            textAlign: 'center',
          }}
        >
          {props.title}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginBottom: 27,
            marginHorizontal: 24,
          }}
        >
          <Text style={styles.description}>Do you want to delete&nbsp;</Text>
          <Text style={styles.nameStyle}>{props.name}</Text>
          {props.name !== undefined && <Text style={styles.description}>&nbsp;from the&nbsp;</Text>}
          <Text style={styles.nameStyle}>{props.groupName}</Text>
          <Text style={styles.description}>&nbsp;group?</Text>
        </View>
        {renderDeleteCancel()}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  description: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
  },
  nameStyle: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 14,
    color: Colors.light.hereworksDarkBlue,
  },
});
