/* eslint-disable object-curly-newline */
/* eslint-disable no-use-before-define */
/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import {
  NavigationContainer,
  DefaultTheme,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import {
  ActivityIndicator,
  ColorSchemeName,
  Dimensions,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Device from 'expo-device';
import { useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Analytics from 'expo-firebase-analytics';
import * as Notifications from 'expo-notifications';
import * as ExpoLinking from 'expo-linking';
import Colors from '../constants/Colors';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import HomeScreen from '../screens/HomeScreen';
import { RootStackParamList, RootTabParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import NotificationsScreen from '../screens/NotificationsScreen';
import AnalyticsScreen from '../screens/Analytics';
import CustomDrawer from '../components/CustomDrawer';
import OccupantsScreen from '../screens/OccupantsScreen';
import AmenitiesScreen from '../screens/AmenitiesScreen';
import ErgonomicsScreen from '../screens/ErgonomicsScreen';
import SignInScreen from '../screens/SignInScreen';
import CustomHeader from '../components/CustomHeader';
import HomeIcon from '../components/HomeIcon';
import BellIcon from '../components/BellIcon';
import InternalEnvironmentScreen from '../screens/InternalEnvironmentScreen';
import ExternalEnvironmentScreen from '../screens/ExternalEnvironmentScreen';
import MapScreen from '../screens/MapScreen';
import ITScreen from '../screens/ITScreen';
import EnergyScreen from '../screens/EnergyScreen';
import SatisfactionScreen from '../screens/SatisfactionScreen';
import ReportsScreen from '../screens/ReportsScreen';
import 'react-native-reanimated';
import FlagIcon from '../components/FlagIcon';
import TemplateMgmtScreen from '../screens/AssetMgmtScreenWeb';
import getIssues from '../requests/GetIssues';
import UserMgmtScreen from '../screens/UserMgmtScreen';
import EnergyScreenMobile from '../screens/EnergyScreenMobile';
import MenuScreen from '../screens/MenuScreen';
import DeleteIcon24 from '../components/24pxIcons/DeleteIcon24';
import MenuIcon from '../components/16pxIcons/MenuIcon';
import TemplateMgmtScreenMobile from '../screens/AssetMgmtScreenMobile';
import ProfileScreen from '../screens/ProfileScreen';
import getUsers from '../requests/GetUsers';

export default function Navigation({
  // eslint-disable-next-line no-unused-vars
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  const [isUserLoggedIn, setUserLoggedIn] = useState('initializing');

  const AuthenticationStack = createNativeStackNavigator();

  const navigationRef = useNavigationContainerRef();
  const routeNameRef = React.useRef('');

  // let myTheme = 'dark';

  useEffect(() => {
    Linking.addEventListener('url', () => checkAuthState());

    checkAuthState();
  }, [isUserLoggedIn]);

  // useEffect(() => {
  //   updateTheme();
  // }, []);

  // async function updateTheme() {
  //   AsyncStorage.getItem('theme').then((theme) => {
  //     myTheme = theme ?? 'dark';
  //   });
  // }

  async function checkAuthState() {
    try {
      await Auth.currentAuthenticatedUser()
        .then(() => {
          Auth.currentSession().then(async (res) => {
            const accessToken = res.getAccessToken();
            const jwt = accessToken.getJwtToken();
            const idToken = res.getIdToken();
            // eslint-disable-next-line no-console
            console.log(JSON.stringify(idToken.payload));
            const { email } = idToken.payload;
            // eslint-disable-next-line camelcase
            const { given_name } = idToken.payload;
            // eslint-disable-next-line camelcase
            const { family_name } = idToken.payload;
            await AsyncStorage.setItem('token', jwt);
            AsyncStorage.setItem('email', email);
            AsyncStorage.setItem(
              'name',
              // eslint-disable-next-line camelcase
              `${given_name ?? 'Anonymous'} ${family_name ?? ''}`,
            );
            // eslint-disable-next-line camelcase
            AsyncStorage.setItem('given_name', given_name ?? '');

            setUserLoggedIn('loggedIn');
          });
        })
        .catch();
    } catch (err) {
      setUserLoggedIn('loggedOut');
    }
  }

  const AuthenticationNavigator = () => (
    <AuthenticationStack.Navigator>
      <AuthenticationStack.Screen name="SignIn" options={{ headerShown: false }}>
        {(screenProps) => (
          // @ts-ignore
          <SignInScreen {...screenProps} />
        )}
      </AuthenticationStack.Screen>
      <AuthenticationStack.Screen name="SignUp" component={AnalyticsScreen} />
      <AuthenticationStack.Screen name="Home" component={HomeScreen} />
    </AuthenticationStack.Navigator>
  );

  if (isUserLoggedIn === 'initializing') {
    return (
      <ActivityIndicator style={{ flex: 1 }} size={'large'} color={Colors.light.hereworksLoading} />
    );
  }

  // if (isUserLoggedIn === 'loggedIn') {
  if (
    isUserLoggedIn !== 'loggedIn'
    // eslint-disable-next-line no-undef
    && !window.location?.origin?.includes('localhost')
  ) {
    return (
      <NavigationContainer>
        <AuthenticationNavigator />
      </NavigationContainer>
    );
  }

  return (
    <SafeAreaProvider>
      <NavigationContainer
        linking={{
          config: LinkingConfiguration.config,
          prefixes: [ExpoLinking.makeUrl('/')],
          subscribe(listener) {
            const onReceiveURL = ({ url }: { url: string }) => listener(url);

            const eventListenerSubscription = Linking.addEventListener('url', onReceiveURL);

            const subscription = Notifications.addNotificationResponseReceivedListener(() => {
              // @ts-ignore
              navigationRef.navigate('Notifications');
            });
            return () => {
              eventListenerSubscription.remove();
              subscription.remove();
            };
          },
        }}
        theme={DefaultTheme}
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.getCurrentRoute().name;
        }}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.getCurrentRoute().name;

          if (previousRouteName !== currentRouteName) {
            await Analytics.logEvent('screen_view', { screen: currentRouteName });
          }

          routeNameRef.current = currentRouteName;
        }}
      >
        {/* theme={myTheme === 'dark' ? DarkTheme : DefaultTheme}> */}
        <RootNavigator />
      </NavigationContainer>
    </SafeAreaProvider>
  );
}

/*
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();
const MenuStack = createNativeStackNavigator();

function HomeTabs() {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [refreshCount, setRefreshCount] = useState(0);
  const [email, setEmail] = useState('');
  const [groups, setGroups] = useState([]);

  const notificationListener = useRef();

  React.useEffect(() => {
    // @ts-ignore
    notificationListener.current = Notifications.addNotificationReceivedListener(() => {
      setRefreshCount(refreshCount + 1);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
    };
  }, [refreshCount]);

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AsyncStorage.getItem('email').then((e) => {
      setEmail(e);
    }) ?? '';
  }, []);

  React.useEffect(() => {
    getUsers().then((response) => {
      if (response.Users?.length > 0) {
        response.Users.forEach((user) => {
          if (user.Email === email) {
            setGroups(user?.UserGroupIds);
          }
        });
      }
    });
  }, [email]);

  useEffect(() => {
    if (groups.length > 0) {
      setLoading(true);
      getIssues(
        50,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        '',
        '',
        '',
        groups,
        null,
        null,
      ).then((response) => {
        if (response) {
          setNotificationsCount(
            response?.stats?.statuses?.reported ?? 0,
          );
          Notifications.setBadgeCountAsync(
            response?.stats?.statuses?.reported ?? 0,
          );
        }
      });
    }
    setLoading(false);
  }, [refreshCount, groups]);

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      safeAreaInsets={{ bottom: 0, top: 0 }}
      screenOptions={{
        header: () => null,
        tabBarActiveTintColor: Colors.light.hereworksDarkBlue,
        tabBarStyle: { height: 80, alignContent: 'center', justifyContent: 'center' },
        tabBarItemStyle: {
          height: 40,
          marginTop: 10,
          // justifyContent: 'center',
          // alignContent: 'center',
          // flex: 1,
        },
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: 'Home',
          tabBarIcon: ({ focused, color }) => {
            if (focused) {
              return <HomeIcon color={color} />;
            }

            return <HomeIcon />;
          },
        }}
      />
      <BottomTab.Screen
        name="Tickets"
        component={ReportsScreen}
        options={{
          title: 'Tickets',
          tabBarIcon: ({ focused, color }) => {
            if (focused) {
              return <FlagIcon fill={color} color={color} />;
            }

            return <FlagIcon color={color} />;
          },
        }}
      />
      <BottomTab.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{
          title: 'Notifications',
          tabBarIcon: ({ focused, color }) => {
            if (focused) {
              return <BellIcon fill={color} color={color} />;
            }
            if (loading) {
              return (
                <View>
                  <BellIcon />
                  <View
                    style={[
                      styles.notificationCountView,
                      notificationsCount > 99 ? { width: 26, right: -10 } : {},
                    ]}
                  >
                    <ActivityIndicator
                      size={'small'}
                      color={'#fff'}
                      animating={loading}
                      style={{ transform: [{ scale: 0.5 }] }}
                    />
                  </View>
                </View>
              );
            }
            if (!loading && notificationsCount > 0) {
              return (
                <View>
                  <BellIcon />
                  <View
                    style={[
                      styles.notificationCountView,
                      notificationsCount > 99 ? { width: 26, right: -10 } : {},
                    ]}
                  >
                    <Text style={styles.notificationText}>{notificationsCount}</Text>
                  </View>
                </View>
              );
            }

            return <BellIcon />;
          },
        }}
      />
      <BottomTab.Screen
        name="MenuStack"
        component={MenuScreen}
        options={{
          title: 'Menu',
          unmountOnBlur: true,
          tabBarIcon: () => <MenuIcon />,
        }}
      />
    </BottomTab.Navigator>
  );
}

function BottomTabNavigator() {
  // const colorScheme = useColorScheme();
  // eslint-disable-next-line no-unused-vars
  const [theme, updateTheme] = useState({ DefaultTheme });

  const window = Dimensions.get('window');
  const [dimensions, setDimensions] = React.useState({ window });

  React.useEffect(() => {
    const subscription: any = Dimensions.addEventListener(
      'change',
      // eslint-disable-next-line no-shadow
      ({ window }) => {
        setDimensions({ window });
      },
    );
    return () => subscription?.remove();
  }, []);

  // pointing local build @dev
  // AsyncStorage.setItem('apiDomain', 'api.dev.hereworks.tech');
  // AsyncStorage.setItem('clientName', 'devhereworks');
  // AsyncStorage.setItem('token', 'paste jwt here');

  if (dimensions.window?.width > 500 && dimensions.window.height > 500 && Device.brand === null) {
    const Drawer = createDrawerNavigator();

    return (
      <Drawer.Navigator
        initialRouteName="Home"
        defaultStatus={'open'}
        screenOptions={{
          drawerType: 'permanent',
          drawerStyle: {
            maxWidth: 252,
            borderRightWidth: 0,
          },
          headerLeft: () => null,
          header: (props) => <CustomHeader {...props} />,
          drawerItemStyle: { flex: 0 },
          headerStyle: {
            borderBottomWidth: 0,
            backgroundColor: '#FFFFFF',
          },
        }}
        drawerContent={(props) => (
          <CustomDrawer
            toggleDarkMode={() => {
              AsyncStorage.setItem('theme', 'light').then(() => {
                updateTheme({ DefaultTheme });
              });
            }}
            {...props}
          />
        )}
      >
        <Drawer.Screen name="Home" component={HomeScreen} />
        <Drawer.Screen name="Tickets" component={ReportsScreen} />
        <Drawer.Screen
          name="AssetManagement"
          options={{ title: 'Asset management' }}
          component={TemplateMgmtScreen}
        />
        <Drawer.Screen
          name="Analytics"
          component={AnalyticsScreen}
          options={{ title: 'Solve analytics' }}
        />
        <Drawer.Screen name="OccupantSatisfaction" component={SatisfactionScreen} />
        <Drawer.Screen name="BuildingOccupancy" component={OccupantsScreen} />
        <Drawer.Screen name="Amenities" component={AmenitiesScreen} />
        <Drawer.Screen name="Ergonomics" component={ErgonomicsScreen} />
        <Drawer.Screen name="Map" component={MapScreen} />
        <Drawer.Screen name="IT" component={ITScreen} />
        <Drawer.Screen name="Energy" component={EnergyScreen} />
        <Drawer.Screen
          name="ExternalEnvironment"
          component={ExternalEnvironmentScreen}
          options={{ title: 'External environment' }}
        />
        <Drawer.Screen
          name="InternalEnvironment"
          component={InternalEnvironmentScreen}
          options={{ title: 'Internal environment' }}
        />
        <Drawer.Screen name="UserManagement" component={UserMgmtScreen} />
        <Drawer.Screen name="Profile" component={ProfileScreen} />
      </Drawer.Navigator>
    );
  }

  return (
    <MenuStack.Navigator>
      <MenuStack.Screen name="HomeTabs" component={HomeTabs} options={{ headerShown: false }} />
      <MenuStack.Screen
        name="Menu"
        component={MenuScreen}
        options={({ navigation }) => ({
          headerBackVisible: false,
          header: () => (
            <View
              style={{
                backgroundColor: '#fff',
                flexDirection: 'row',
                paddingTop: 50,
                borderBottomWidth: 1,
                borderColor: Colors.light.hereworksBlue100,
                paddingBottom: 24,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Poppins_600SemiBold',
                  fontSize: 20,
                  lineHeight: 30,
                  marginLeft: 24,
                  flex: 1,
                }}
              >
                Menu
              </Text>
              <TouchableOpacity
                style={{ marginRight: 24 }}
                onPress={() => {
                  navigation.goBack();
                }}
              >
                <DeleteIcon24 color={Colors.light.hereworksBlue500} />
              </TouchableOpacity>
            </View>
          ),
        })}
      />
      <MenuStack.Screen name="Energy" component={EnergyScreenMobile} />
      <MenuStack.Screen name="Analytics" component={AnalyticsScreen} />
      <MenuStack.Screen name="UserManagement" component={UserMgmtScreen} />
      <MenuStack.Screen name="Map" component={MapScreen} />
      <MenuStack.Screen
        name="AssetManagement"
        component={TemplateMgmtScreenMobile}
        options={{ title: 'Asset management' }}
      />
    </MenuStack.Navigator>
  );
}

// function toggleDarkMode() {
//   if(this.colorScheme === 'dark'){
//     this.colorScheme = 'light';
//   } else {
//     this.colorScheme = 'dark';
//   }

// }

const styles = StyleSheet.create({
  notificationCountView: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: 'red',
    position: 'absolute',
    top: -4,
    right: -2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationText: {
    color: '#fff',
    fontFamily: 'Roboto_500Medium',
    fontSize: 11,
    textAlign: 'center',
  },
});
