import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useRoute } from '@react-navigation/native';
import {
  VictoryLine,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory';
import { processFontFamily } from 'expo-font';
import Colors from '../constants/Colors';
import { lineColorCompared } from '../components/Home/HomeGraphLineSelector';
import { axisDateLabel, tooltipDateLabel, tickValSamples } from './axisUtils';

interface AirQualityGraphProps {
  showComparison: boolean,
  selectedMetricIndex?: number,
  data: { date: string, value: number, index: number }[][],
  dataCompared: { date: string, value: number, index: number }[][],
  currentMetricTitle: string,
  unit: string,
}

const chartTheme = {
  axis: {
    stroke: 'none',
    style: {
      stroke: '#696D70',
      tickLabels: { fill: '#696D70' },
      grid: { stroke: 'none' },
    },
  },
};

const Line = (data: object[], stroke: string) => <VictoryLine data={data} style={{ data: { stroke, strokeWidth: 1.5 } }} x='index' y='value' />;

const XAxis = (tickVals: number[], tickFormat, dy: number, fill: string) => <VictoryAxis
  crossAxis={false}
  fixLabelOverlap
  style={{ axis: { stroke: 'none' }, tickLabels: { fontSize: 14 } }}
  tickValues={tickVals}
  tickFormat={tickFormat}
  tickLabelComponent={<VictoryLabel dy={dy} dx={-20}
    style={{
      fill,
      fontSize: 14,
      strokeWidth: 0,
      fontFamily: processFontFamily('Poppins_400Regular'),
      textAnchor: 'center',
    }} />} />;

const AirQualityGraph = ({
  showComparison, selectedMetricIndex, data, dataCompared, currentMetricTitle, unit,
}: AirQualityGraphProps) => {
  const route = useRoute().name;
  const [chartWidth, setChartWidth] = useState(1060);
  const avg = data[selectedMetricIndex].length > 0 ? Math.floor((data[selectedMetricIndex]
    .map((point) => point.value)
    .reduce((a, b) => a + b) / data[selectedMetricIndex].length) * 10) / 10 : 0;

  const domainLimit: number = 1.3 * Math.max(
    ...data[selectedMetricIndex].map((point) => point.value),
    ...dataCompared[selectedMetricIndex].map((point) => point.value),
    1,
  );

  class CustomFlyout extends React.Component {
    render() {
      // @ts-ignore
      const { x, y, datum } = this.props;
      const date: Date = data[selectedMetricIndex][datum.index]?.date
        ? new Date(data[selectedMetricIndex][datum.index]?.date) : null;
      const dateCompared: Date = dataCompared[selectedMetricIndex][datum.index]?.date
        ? new Date(dataCompared[selectedMetricIndex][datum.index]?.date) : null;
      const dateText: string = date ? tooltipDateLabel(data[selectedMetricIndex], datum.index) : '-';
      const dateComparedText: string = dateCompared ? `vs. ${tooltipDateLabel(dataCompared[selectedMetricIndex], datum.index)}` : null;
      const valueText: string = `${currentMetricTitle}: ${data[selectedMetricIndex][datum.index]?.value ? `${Math.floor(data[selectedMetricIndex][datum.index]?.value)} ${unit}` : '-'}`;
      const valueComparedText: string = `${currentMetricTitle} (compared): ${dataCompared[selectedMetricIndex][datum.index]?.value ? `${Math.floor(dataCompared[selectedMetricIndex][datum.index]?.value)} ${unit}` : '-'}`;
      const containerHeight: number = showComparison ? 96 : 56;
      const containerWidth: number = (showComparison ? 100 : 50)
        + Math.max(
          dateText.length,
          (showComparison && dataCompared[selectedMetricIndex][datum.index]?.date)
            ? dateComparedText.length : 0,
          valueText.length,
          valueComparedText.length,
        ) * 5.1;
      const containerX: number = x > containerWidth + 20 ? x - containerWidth - 10 : x + 10;
      const containerY: number = Math.max(Math.min(y - containerHeight, 210 - containerHeight), 10);
      return <svg>
        <defs>
          <filter id={`f3_${route}`} x={-0.5} y={-1} width='200%' height='250%'>
            <feOffset result='offOut' in='SourceGraphic' dx='0' dy='0' />
            <feColorMatrix result='matrixOut'
              in='offOut'
              type='matrix'
              values='0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0' />
            <feGaussianBlur result='blurOut' in='matrixOut' stdDeviation='4' />
            <feBlend in='SourceGraphic' in2='blurOut' mode='normal' result='colored' />
          </filter>
        </defs>
        {data[selectedMetricIndex][datum.index]?.value && <circle r={4}
          strokeWidth={3}
          stroke={Colors.light.hereworksDarkBlue}
          cx={x}
          cy={51 + 190
            * ((domainLimit - data[selectedMetricIndex][datum.index].value) / domainLimit)}
          fill='#FFF' />}
        {showComparison && dataCompared[selectedMetricIndex][datum.index]?.value && <circle r={4}
          strokeWidth={3}
          stroke={lineColorCompared}
          cx={x}
          cy={51 + 190
            * ((domainLimit - dataCompared[selectedMetricIndex][datum.index].value) / domainLimit)}
          fill='#FFF' />}
        <rect
          x={containerX}
          y={containerY}
          width={containerWidth}
          height={containerHeight}
          stroke={Colors.light.hereworksDarkBlue}
          rx={5}
          fill='white'
          filter={`url(#f3_${route})`} />
        {datum.date && <text
          x={containerX + 10}
          y={containerY + 20}
          fontFamily={processFontFamily('Poppins_500Medium')}
          fontSize={12}
          strokeWidth={0}
          fill={Colors.light.hereworksBlack600}>
          {dateText}
        </text>}
        {showComparison && dataCompared[selectedMetricIndex][datum.index]?.date && <text
          x={containerX + 10}
          y={containerY + 40}
          fontFamily={processFontFamily('Poppins_500Medium')}
          fontSize={12}
          strokeWidth={0}
          fill={Colors.light.hereworksBlack600}>
          {dateComparedText}
        </text>}
        <line x1={x}
          x2={x}
          y1={25}
          y2={270}
          stroke={Colors.light.hereworksBlue100}
          strokeDasharray='10,10'
          strokeWidth={1} />
        <circle r={6}
          cx={containerX + 15}
          cy={containerY + (showComparison ? 60.5 : 40.5)}
          fill={Colors.light.hereworksDarkBlue} />
        <text
          x={containerX + 30}
          y={containerY + (showComparison ? 65 : 45)}
          fontFamily={processFontFamily('Poppins_500Medium')}
          fontSize={12}
          strokeWidth={0}
          fill={Colors.light.hereworksBlack600}>
          {valueText}
        </text>
        {showComparison && <circle r={6}
          cx={containerX + 15}
          cy={containerY + 80.5}
          fill={lineColorCompared} />}
        {showComparison && <text
          x={containerX + 30}
          y={containerY + 85}
          fontFamily={processFontFamily('Poppins_500Medium')}
          fontSize={12}
          strokeWidth={0}
          fill={Colors.light.hereworksBlack600}>
          {valueComparedText}
        </text>}
      </svg>;
    }
  }

  return <View onLayout={(e) => setChartWidth(e.nativeEvent.layout.width)}
    style={styles.container}>
    <VictoryChart width={chartWidth}
      height={291}
      domainPadding={{ x: [0, 30] }}
      containerComponent={
        <VictoryVoronoiContainer labels={({ datum }) => datum}
          voronoiDimension='x'
          labelComponent={<CustomFlyout />} />}
      theme={chartTheme}>
      {XAxis(
        tickValSamples(
          [...Array(data[selectedMetricIndex].length).keys()],
          Math.min(7, Math.floor(7 * ((data[selectedMetricIndex]?.length ?? 1)
            / (dataCompared[selectedMetricIndex]?.length ?? 1)))),
        ),
        (t) => ((data[selectedMetricIndex].length > 0 && data[selectedMetricIndex][t]) ? axisDateLabel(data[selectedMetricIndex], t) : ''),
        20,
        Colors.light.hereworksBlack400,
      )}
      {showComparison && XAxis(
        tickValSamples(
          [...Array(dataCompared[selectedMetricIndex].length).keys()],
          Math.min(7, Math.floor(7 * ((dataCompared[selectedMetricIndex]?.length ?? 1)
            / (data[selectedMetricIndex]?.length ?? 1)))),
        ),
        (t) => ((dataCompared[selectedMetricIndex].length > 0 && dataCompared[selectedMetricIndex][t]) ? axisDateLabel(dataCompared[selectedMetricIndex], t) : ''),
        36,
        lineColorCompared,
      )}
      <VictoryAxis name='y-axis'
        tickCount={data[selectedMetricIndex].length > 0 ? 5 : 2}
        domain={{ y: [0, domainLimit] }}
        dependentAxis
        crossAxis={false}
        fixLabelOverlap={true}
        style={{
          axis: { stroke: 'none' },
          tickLabels: {
            fontSize: 14,
          },
          grid: { stroke: Colors.light.hereworksWhite500, strokeWidth: 1, pointerEvents: 'none' },
        }}
        containerComponent={<VictoryVoronoiContainer />}
        tickLabelComponent={<VictoryLabel style={{
          fill: Colors.light.hereworksBlack400,
          fontSize: 14,
          strokeWidth: 0,
          fontFamily: processFontFamily('Poppins_400Regular'),
        }}
          textAnchor='end'
          dx={-12} />} />
      {Line(data[selectedMetricIndex], Colors.light.hereworksDarkBlue)}
      {Line(data[selectedMetricIndex], '#0000')}
      {showComparison && Line(dataCompared[selectedMetricIndex], lineColorCompared)}
      <VictoryLine data={[
        { y: avg, x: 0 },
        { y: avg, x: Math.max(0, (data[selectedMetricIndex]?.length ?? 1) - 1), label: `${avg} avg` },
      ]}
        standalone={false}
        style={{
          data: {
            stroke: Colors.light.hereworksBlack300,
            strokeWidth: 1.5,
            strokeDasharray: 8,
          },
        }}
        labels={({ datum }) => datum.label}
        labelComponent={
          <VictoryLabel renderInPortal
            textAnchor='end'
            dx={68}
            dy={6}
            style={{
              color: Colors.light.hereworksBlack400,
              fontSize: 12,
              strokeWidth: 0,
              fontFamily: processFontFamily('Poppins_400Regular'),
            }}
          />} />
    </VictoryChart>
  </View>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 20,
  },
});

export default AirQualityGraph;
