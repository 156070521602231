import React, { useState } from 'react';
import {
  StyleSheet,
  Image,
  Dimensions,
  Platform,
  Animated,
  TouchableOpacity,
  Easing,
  ScrollView,
  Alert,
  View,
  BackHandler,
} from 'react-native';
import Moment from 'moment';
import { useFocusEffect } from '@react-navigation/native';
import {
  Circle, Defs, LinearGradient, Stop, Svg,
} from 'react-native-svg';
import * as Linking from 'expo-linking';
import { useCountUp } from 'use-count-up';
import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient';
import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Auth } from 'aws-amplify';
import Modal from 'react-native-modal';
import { FontAwesome } from '@expo/vector-icons';
import * as Application from 'expo-application';
import { Text } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import BoltIcon from '../components/BoltIcon';
import SunIcon from '../components/SunIcon';
import LeftArrow from '../components/LeftArrow';
import SmileIcon from '../components/SmileIcon';
import CoffeeIcon from '../components/CoffeeIcon';
import MonitorIcon from '../components/MonitorIcon';
import CheckCircle from '../components/CheckCircle';
import ChairIconDesktop from '../components/24pxIcons/ChairIconDesktop';
import GridIcon from '../components/24pxIcons/GridIcon';
import DownArrow from '../components/DownArrow';
import UpArrow from '../components/UpArrow';
import VictoryAreaChart from '../components/VictoryAreaChart';
import storePushToken from '../requests/StorePushToken';
import EllipsisIcon from '../components/EllipsisIcon';
import LogoutIcon from '../components/LogoutIcon';
import HappyDetailsGraph from '../graphs/HappyDetailsGraph';
import HomeScreenTopSection from '../components/Home/HomeScreenTopSection';
import BackgroundImage from '../components/BackgroundImage';
import { happyScoreMetrics } from '../graphs/HappyDetailsGraphWeb';
import getClientDetails from '../requests/GetClientDetails';
import { GetMetricByDateWithLive } from '../requests/GetMetricByDateWithLive';

const HappyBuilding = require('../assets/images/happy_building.png');
const MediumBuilding = require('../assets/images/medium_building.png');
const SadBuilding = require('../assets/images/sad_building.png');

// eslint-disable-next-line no-unused-vars
export default function HomeScreen({ navigation }: RootTabScreenProps<'Home'>) {
  // console.log('Home');

  const [happyScore, setHappyScore] = useState(0);
  const [groupCounts, setGroupCounts] = useState([
    {
      name: 'Internal Environment',
      id: 'int_env',
      percentage: null,
      today_vs_yesterday_diff: null,
      description: null,
    },
    {
      name: 'External Environment',
      id: 'ext_env',
      percentage: null,
      today_vs_yesterday_diff: null,
      description: null,
    },
    {
      name: 'IT',
      id: 'it',
      percentage: null,
      today_vs_yesterday_diff: null,
      description: null,
    },
    {
      name: 'Energy',
      id: 'energy',
      percentage: null,
      today_vs_yesterday_diff: null,
      description: null,
    },
    {
      name: 'Occupancy',
      id: 'occupancy',
      percentage: null,
      today_vs_yesterday_diff: null,
      description: null,
    },
    {
      name: 'Amenities',
      id: 'amenities',
      percentage: null,
      today_vs_yesterday_diff: null,
      description: null,
    },
    {
      name: 'Ergonomics',
      id: 'ergonomics',
      percentage: null,
      today_vs_yesterday_diff: null,
      description: null,
    },
    {
      name: 'Satisfaction',
      id: 'satisfaction',
      percentage: null,
      today_vs_yesterday_diff: null,
      description: null,
    },
  ]);
  const [name, setName] = useState('');
  const [client, setClient] = useState('');
  const url = Linking.useURL();

  React.useEffect(() => {
    if (!url?.length) return;
    const { reportId, clientId } = Linking.parse(url).queryParams;
    if (reportId) {
      // @ts-ignore
      navigation.navigate('Tickets', { reportId, clientId });
    }
  }, [url]);

  const { value, reset } = useCountUp({
    isCounting: true,
    end: happyScore,
    duration: 6,
    easing: 'easeOutCubic',
  });

  React.useEffect(() => {
    const newGroupCounts = [...groupCounts];
    happyScoreMetrics.forEach((metric: string, metricIndex: number) => GetMetricByDateWithLive(
      metric,
      { start: Moment().subtract(1, 'day').toDate(), end: new Date() },
    ).then((data: {
      buckets: { key_as_string: string, score: { value: number } }[], source: number,
    }) => {
      if (data && data?.buckets?.length > 0) {
        const latestValue: number = data?.buckets[
          data?.buckets?.length ?? 0 - 1]?.score?.value;
        const score = Math.round(data?.source);
        if (metricIndex === 0) {
          setHappyScore(score ?? 0);
        } else {
          newGroupCounts[metricIndex - 1].percentage = score;
          const yesterdayValue: number = data?.buckets[0]?.score?.value;
          const diff: number = 100 * ((latestValue - yesterdayValue) / yesterdayValue);
          newGroupCounts[metricIndex - 1].today_vs_yesterday_diff = Number.isFinite(diff)
            ? Math.floor(diff) : null;
          setGroupCounts(newGroupCounts);
        }
      }
    }));
    getClientDetails().then((response) => {
      Object.keys(response?.clientDetails?.happy_score_insights ?? []).forEach((item) => {
        const id = item.split('_')[0];
        if (id.includes('internal')) {
          newGroupCounts[0].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('external')) {
          newGroupCounts[1].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id === 'it') {
          newGroupCounts[2].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('energy')) {
          newGroupCounts[3].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('building')) {
          newGroupCounts[4].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('amen')) {
          newGroupCounts[5].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('ergo')) {
          newGroupCounts[6].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('occupant')) {
          newGroupCounts[7].description = response.clientDetails.happy_score_insights[item].text;
        }
        setGroupCounts(newGroupCounts);
      });
    });
  }, []);

  React.useEffect(() => {
    AsyncStorage.getItem('given_name').then((newName) => {
      setName(newName);
    });
    AsyncStorage.getItem('clientName').then((clientId) => {
      setClient(clientId);
    });
  }, []);

  React.useEffect(() => {
    if (happyScore > 0) {
      resetAnimation();
    }
  }, [happyScore]);

  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });

  const lowGradient = ['#BBC5CA', '#ECF0F2'];
  const mediumGradient = ['#EDDDBE', '#EBE9E4'];
  const highGradient = ['#BCE7F5', '#E6F3FA'];

  const lowCircleGradient = ['#E84498', '#D6006D'];
  const mediumCircleGradient = ['#FFC85F', '#F4A000'];
  const highCircleGradient = ['#85E1CF', '#2BC3A5'];

  const [fillValue] = useState(new Animated.Value(2 * Math.PI * 66));
  const [rectValue] = useState(new Animated.Value(0));
  const [offsetValue] = useState(new Animated.Value(0));
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState('Electricity');
  const [selectedMetricScore, setSelectedMetricScore] = useState(0);
  const [yesterdayDiff, setYesterdayDiff] = useState(0);
  const [happyColor, setHappyColor] = useState(Colors.light.hereworksPink);
  const [gradient, setGradient] = useState(lowGradient);
  const [circleGradient, setCircleGradient] = useState(lowCircleGradient);
  const [buildingImage, setBuildingImage] = useState(SadBuilding);
  const [logoutButtonVisible, setLogoutButtonVisible] = useState(false);
  const [description, setDescription] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const AnimatedCircle = Animated.createAnimatedComponent(Circle);

  const [apiDomain, setApiDomain] = useState('api.hereworks.tech');
  AsyncStorage.getItem('apiDomain').then((currentApi) => {
    setApiDomain(currentApi ?? 'api.hereworks.tech');
  });

  React.useEffect(() => {
    const currentValue = parseInt(value?.toString() ?? '', 10);
    // console.log('value: ' + currentValue);
    if (currentValue < 50) {
      setHappyColor(Colors.light.hereworksPink);
      setGradient(lowGradient);
      setCircleGradient(lowCircleGradient);
      setBuildingImage(SadBuilding);
    } else if (currentValue < 80) {
      setHappyColor(Colors.light.hereworksOrange);
      setGradient(mediumGradient);
      setCircleGradient(mediumCircleGradient);
      setBuildingImage(MediumBuilding);
    } else {
      setHappyColor(Colors.light.hereworksExtraDarkTeal);
      setGradient(highGradient);
      setCircleGradient(highCircleGradient);
      setBuildingImage(HappyBuilding);
    }
  }, [value]);

  useFocusEffect(
    React.useCallback(() => {
      setLogoutButtonVisible(false);
      const onBackPress = () => {
        if (showDetails) {
          setShowDetails(false);
          return true;
        }
        return false;
      };
      const subscription = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => subscription.remove();
    }, [showDetails, setShowDetails]),
  );

  React.useEffect(() => {
    if (Platform.OS === 'web') {
      return;
    }
    Animated.parallel([
      Animated.timing(offsetValue, {
        toValue: 2 * Math.PI * 62,
        duration: 6000,
        easing: Easing.bezier(0, 0, 0.2, 1),
        useNativeDriver: true,
      }),
      Animated.timing(fillValue, {
        toValue: 2 * Math.PI * 66 - (2 * Math.PI * 62 * happyScore) / 117,
        duration: 6000,
        useNativeDriver: true,
      }),
      Animated.timing(rectValue, {
        toValue: 8.55 * happyScore,
        duration: 6000,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  function handleDetailsButton() {
    setShowDetails(!showDetails);
  }
  async function getPushNotificationsToken() {
    if (Platform.OS !== 'web') {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        // Alert.alert('Failed to get push token for push notification!');
        return;
      }
      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: false,
          shouldSetBadge: false,
        }),
        // handleError: async (id, error) => console.log(`error: ${JSON.stringify(error)}`),
      });
      Notifications.addNotificationReceivedListener(() => {
        // console.log(n);
      });
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      if (token) {
        const deviceId = token;
        storePushToken(token, deviceId);
        // console.log('Your Push Notification Token is:', token);
      } else {
        // console.log('Failed', 'No token received');
      }

      if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
      }
    }
  }

  React.useEffect(() => {
    getPushNotificationsToken();
  }, []);

  const resetAnimation = () => {
    if (Platform.OS === 'web') {
      return;
    }
    reset();
    Animated.parallel([
      Animated.sequence([
        Animated.timing(fillValue, {
          toValue: 2 * Math.PI * 66,
          duration: 200,
          useNativeDriver: true,
        }),
        Animated.timing(fillValue, {
          toValue: 2 * Math.PI * 66 - (2 * Math.PI * 62 * happyScore) / 117,
          duration: 5000,
          easing: Easing.bezier(0, 0, 0.2, 1),
          useNativeDriver: true,
        }),
      ]),
      Animated.timing(rectValue, {
        toValue: 8.55 * happyScore,
        duration: 6000,
        useNativeDriver: true,
      }),
    ]).start();
  };

  function getHappyColor(score: number) {
    if (score < 50) {
      return Colors.light.hereworksPink;
    }

    if (score < 80) {
      return Colors.light.hereworksOrange;
    }

    return Colors.light.hereworksExtraDarkTeal;
  }

  const renderGroupScore = (
    title: string,
    score: number,
    diff: number,
    categoryDescription: string,
    icon: any,
  ) => (
    <TouchableOpacity
      style={[styles.happyItem, styles.boxShadow]}
      onPress={() => openModal(title, score, diff, categoryDescription)}
    >
      <Text style={styles.happyItemText} numberOfLines={1}>
        {title}
      </Text>
      <View style={styles.happyItemScore}>
        {icon}
        <Text style={[styles.happyItemScoreText, { color: getHappyColor(score) }]}>
          {score ? score.toFixed() : '-'}
        </Text>
        {diff < 0 && (
          <View style={styles.arrow}>
            <DownArrow />
          </View>
        )}
        {diff > 0 && (
          <View style={styles.arrow}>
            <UpArrow />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );

  const renderCircle = (fill: Animated.Value, blur: boolean = false) => (
    <Svg
      // @ts-ignore
      onClick={() => resetAnimation()}
      onPress={() => resetAnimation()}
      style={[
        styles.innerCircle,
        Platform.OS === 'ios'
          ? [Style.circleShadow, { shadowColor: happyColor }]
          : [{ shadowColor: happyColor }],
        // @ts-ignore
        Platform.OS === 'web' && blur ? { filter: 'blur(4px)' } : {},
      ]}
    >
      <Defs>
        <LinearGradient id="grad-1" x1="5" y1="0" x2="135" y2="0" gradientUnits="userSpaceOnUse">
          <Stop offset="0" stopColor={circleGradient[1]} stopOpacity="1" />
          <Stop offset="1" stopColor={circleGradient[0]} />
        </LinearGradient>
      </Defs>
      <AnimatedCircle
        cx="30"
        cy="-120"
        r={58}
        stroke={'url(#grad-1)'}
        fill="transparent"
        strokeWidth="8"
        strokeDasharray={2 * Math.PI * 62}
        strokeDashoffset={fillValue}
        rotation={120}
        originX="0"
        originY="0"
        strokeLinecap="round"
      />
    </Svg>
  );

  const groupCountIcon = (iconName: string, score: number) => {
    switch (iconName) {
      case 'ext_env':
        return <SunIcon color={getHappyColor(score)} />;
      case 'int_env':
        return <CoffeeIcon color={getHappyColor(score)} />;
      case 'energy':
        return <BoltIcon color={getHappyColor(score)} />;
      case 'satisfaction':
        return <SmileIcon color={getHappyColor(score)} />;
      case 'occupancy':
        return <GridIcon color={getHappyColor(score)} />;
      case 'it':
        return <MonitorIcon color={getHappyColor(score)} />;
      case 'amenities':
        return <CheckCircle color={getHappyColor(score)} />;
      case 'ergonomics':
        return <ChairIconDesktop color={getHappyColor(score)} />;
      default:
        return null;
    }
  };

  const openModal = (title: string, score: number, diff: number, categoryDescription: string) => {
    setModalVisible(true);
    setSelectedMetric(title);
    setSelectedMetricScore(score);
    setYesterdayDiff(diff);
    setDescription(categoryDescription);
    // console.log('openModal');
  };

  if (Platform.OS === 'web' && dimensions.window?.width > 500) {
    return <ScrollView style={styles.containerWeb}>
      <BackgroundImage />
      <HomeScreenTopSection />
    </ScrollView>;
  }

  return (
    <View style={styles.container}>
      {showDetails ? (
        <View
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: Colors.light.hereworksBackground,
            zIndex: 6,
          }}
        >
          <View
            style={{
              width: '100%',
              backgroundColor: '#fff',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                paddingBottom: 25,
                paddingTop: 60,
                marginLeft: 20,
                backgroundColor: '#fff',
                flex: 1,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setShowDetails(!showDetails);
                }}
              >
                <FontAwesome
                  size={33}
                  style={{ color: '#696D70', marginBottom: 1 }}
                  name="angle-left"
                />
              </TouchableOpacity>
            </View>

            <View style={{ flex: 2, backgroundColor: '#fff', marginLeft: -20 }}>
              <Text
                style={{
                  paddingBottom: 25,
                  paddingTop: 60,
                  fontWeight: 'bold',
                  fontSize: 18,
                  textAlign: 'center',
                  color: 'black',
                }}
              >
                Happy Score details
              </Text>
            </View>
            <View style={{ flex: 1 }}></View>
          </View>
          <HappyDetailsGraph
            metricName="happy_group_energy_usage_local_happyscore_percent"
            title="External Env."
            interval="24h"
          />
        </View>
      ) : null}

      <TouchableOpacity
        style={{
          position: 'absolute',
          right: 24,
          top: 60,
          zIndex: 5,
          alignItems: 'flex-end',
        }}
        hitSlop={{
          top: 20,
          bottom: 20,
          left: 50,
          right: 50,
        }}
        onPress={() => {
          setLogoutButtonVisible(!logoutButtonVisible);
        }}
      >
        <EllipsisIcon />
        {logoutButtonVisible && (
          <View style={{
            marginTop: 10,
            backgroundColor: Colors.light.hereworksBlue100,
            borderRadius: 6,
            overflow: 'hidden',
          }}>
            <TouchableOpacity
              style={{
                backgroundColor: '#fff',
                width: 120,
                height: 36,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() => {
                if (Platform.OS === 'web') {
                  // eslint-disable-next-line no-alert, no-undef
                  Auth.signOut();
                } else {
                  Alert.alert(
                    'Sign Out',
                    'Are you sure you want to sign out?',
                    [
                      {
                        text: 'Cancel',
                        style: 'cancel',
                        onPress: () => setLogoutButtonVisible(false),
                      },
                      { text: 'OK', onPress: () => Auth.signOut() },
                    ],
                    {
                      cancelable: true,
                    },
                  );
                }
              }}
            >
              <LogoutIcon />
              <Text
                style={{
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 14,
                  color: Colors.light.hereworksPrimaryGrey,
                  marginLeft: 8,
                }}
              >
                Logout
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                backgroundColor: '#fff',
                width: 120,
                height: 36,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 1,
              }}
              onPress={() => {
                if (Platform.OS === 'web') {
                  // eslint-disable-next-line no-alert, no-undef
                  alert(`Client: ${client}\nAPI: ${apiDomain}`);
                } else {
                  Alert.alert(
                    'App Information',
                    `App Version: ${Application.nativeApplicationVersion} (${Application.nativeBuildVersion})\nClient: ${client}\nAPI: ${apiDomain}`,
                  );
                }
                setLogoutButtonVisible(false);
              }}
            >
              <FontAwesome size={20} name='info-circle' />
              <Text
                style={{
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 14,
                  color: Colors.light.hereworksPrimaryGrey,
                  marginLeft: 8,
                }}
              >
                App Info
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </TouchableOpacity>
      <Modal
        animationIn={'slideInRight'}
        animationOut={'slideOutRight'}
        isVisible={modalVisible}
        onBackButtonPress={() => setModalVisible(false)}
        style={{ flex: 1, margin: 0 }}
      >
        <View style={[styles.modalContainer, { flex: 1 }]}>
          <View style={styles.modalHeader}>
            <TouchableOpacity
              onPress={() => setModalVisible(!modalVisible)}
              style={{ alignSelf: 'flex-start' }}
            >
              <LeftArrow style={{ alignSelf: 'flex-start', margin: 20 }} />
            </TouchableOpacity>
            <Text style={styles.selectedMetric}>{selectedMetric}</Text>
          </View>
          <ScrollView
            style={{
              flex: 1,
              width: '100%',
              backgroundColor: Colors.light.hereworksBackground,
            }}
            directionalLockEnabled={true}
          >
            <View style={styles.modalScoreContainer}>
              <Text style={[styles.modalScore, { color: getHappyColor(selectedMetricScore) }]}>
                {selectedMetricScore}{' '}
              </Text>
              <Text style={styles.modalScoreMax}>/100</Text>
            </View>
            <Text
              style={[styles.modalScoreImprovement, { color: getHappyColor(selectedMetricScore) }]}
            >
              {yesterdayDiff >= 0 ? '+' : ''}
              {yesterdayDiff}% from yesterday
            </Text>
            <HappyDetailsGraph
              metricName="happy_group_energy_usage_local_happyscore_percent"
              title="External Env."
              interval="24h"
            />
            <View style={[styles.aboutContainer, Style.boxShadow]}>
              <Text style={styles.heading}>About</Text>
              <Text style={styles.body}>{description}</Text>
            </View>
            {/* <View style={[styles.requestsContainer, Style.boxShadow]}>
                <Text style={styles.heading}>Occupants requests (2)</Text>
                <Text style={styles.body}>
                  Lorem ipsum dolor sit amet, cte adipiscing elit. Aliquam vel
                  dapibu.
                </Text>
              </View> */}
          </ScrollView>
        </View>
      </Modal>
      <Text style={styles.zero}>0</Text>
      <Text style={styles.hundred}>100</Text>
      <Text style={styles.name}>Hi {name}!</Text>
      <Text style={styles.description}>We're in a happy place today</Text>
      <View style={styles.linearGradientWrapper}>
        <ExpoLinearGradient colors={gradient} style={styles.linearGradient}>
          <Image source={buildingImage} style={styles.image} />
        </ExpoLinearGradient>
      </View>
      <ScrollView
        horizontal={true}
        style={styles.scrollView}
        showsHorizontalScrollIndicator={false}
      >
        {groupCounts?.length > 0
          && [...groupCounts].sort((prev, next) => prev.percentage - next.percentage)
            .map((groupCount: any) => (
              <View style={{ backgroundColor: 'transparent' }} key={groupCount.id}>
                {renderGroupScore(
                  groupCount.name,
                  groupCount.percentage,
                  groupCount.today_vs_yesterday_diff,
                  groupCount.description,
                  groupCountIcon(groupCount.id, groupCount.percentage),
                )}
              </View>
            ))}
        {/* For Local Testing */}
        {/* {renderGroupScore('Electricity', 10, -1, <BoltIcon color={happyColor} />)}
            {renderGroupScore('External Environment', 12, 1, <SunIcon color={happyColor} />)}
            {renderGroupScore('Internal Environment', 12, 0, <SunIcon color={happyColor} />)} */}
      </ScrollView>
      <View style={styles.happyScoreWrapper}>
        <Text style={[styles.happyScore, { color: happyColor }]}>{value}</Text>
      </View>
      <Svg
        // @ts-ignore
        onClick={() => resetAnimation()}
        onPress={() => resetAnimation()}
        style={styles.outerCircle}
      >
        <Circle
          cx="30"
          cy="-120"
          r={58}
          stroke={'white'}
          fill="transparent"
          strokeWidth="8"
          strokeDasharray={2 * Math.PI * 62}
          strokeDashoffset={2 * Math.PI * 66 - (2 * Math.PI * 62 * 85) / 100}
          rotation={120}
          originX="0"
          originY="0"
          strokeLinecap="round"
        />
      </Svg>
      {renderCircle(fillValue, true)}
      {renderCircle(fillValue)}
      <View style={styles.mobileGraphCard}>
        <VictoryAreaChart
          metricName="happy_group_energy_usage_local_happyscore_percent"
          interval="24h"
          onClick={() => handleDetailsButton()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containerWeb: {
    flex: 1,
    backgroundColor: Colors.light.hereworksBackground,
  },
  mobileGraphCard: {
    position: 'absolute',
    top: 300,
    left: 0,
    alignSelf: 'center',
    // width: 400,
    // height: 350,
    marginTop: 60,
    marginRight: 40,
    marginBottom: 50,
    borderRadius: 24,
    flex: 1,
    backgroundColor: 'transparent',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: Colors.light.hereworksBackground,
    flex: 1,
  },
  linearGradient: {
    paddingTop: 30,
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  image: {
    alignSelf: 'flex-end',
    marginLeft: 140,
    marginBottom: 20,
    zIndex: 2,
    height: 180,
    width: 199,
  },
  linearGradientWrapper: {
    width: 1400,
    height: 1400,
    top: -1048,
    borderRadius: 700,
    overflow: 'hidden',
  },
  happyItem: {
    width: 104,
    height: 60,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    marginRight: 16,
    paddingHorizontal: 14,
    borderRadius: 10,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  happyItemText: {
    color: Colors.light.hereworksPrimaryGrey800,
    alignSelf: 'flex-start',
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
  },
  happyItemScoreText: {
    alignSelf: 'center',
    fontFamily: 'Poppins_500Medium',
    fontSize: 20,
    marginLeft: 10,
  },
  happyScore: {
    fontFamily: 'Poppins_700Bold',
    fontSize: 46,
    justifyContent: 'center',
  },
  happyScoreWrapper: {
    position: 'absolute',
    top: 200,
    left: 65,
    width: 80,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  happyItemScore: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  name: {
    color: Colors.light.hereworksDarkBlue,
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 28,
    flex: 1,
    zIndex: 3,
    position: 'absolute',
    width: '100%',
    top: 60,
    left: 24,
  },
  description: {
    color: Colors.light.hereworksDarkBlue,
    fontFamily: 'Poppins_500Medium',
    fontSize: 14,
    flex: 1,
    zIndex: 3,
    left: 24,
    position: 'absolute',
    top: 99,
  },
  selectedMetric: {
    color: 'black',
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    flex: 1,
    textAlign: 'center',
    marginRight: 40,
  },
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 40,
    backgroundColor: 'white',
    justifyContent: 'center',
    width: '100%',
  },
  modalScoreContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    backgroundColor: Colors.light.hereworksBackground,
  },
  modalScore: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 64,
    textAlign: 'right',
  },
  modalScoreMax: {
    color: Colors.light.hereworksPrimaryGrey800,
    fontFamily: 'Poppins_400Regular',
    fontSize: 24,
    textAlign: 'center',
  },
  modalScoreImprovement: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 24,
  },
  scrollView: {
    position: 'absolute',
    top: 300,
    alignSelf: 'center',
    height: 80,
    width: '100%',
    paddingLeft: 16,
  },
  desktopScrollView: {
    marginTop: 104,
    alignSelf: 'center',
    height: 112,
    width: '100%',
    paddingLeft: 56,
    backgroundColor: 'transparent',
  },
  aboutContainer: {
    backgroundColor: 'white',
    borderRadius: 12,
    padding: 20,
    margin: 24,
  },
  requestsContainer: {
    backgroundColor: 'white',
    borderRadius: 12,
    padding: 20,
    marginHorizontal: 24,
  },
  heading: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 20,
    color: Colors.light.hereworksPrimaryGrey,
  },
  body: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey800,
  },
  outerCircle: {
    position: 'absolute',
    top: 142,
    left: 17,
    height: 160,
    width: 216,
  },
  innerCircle: {
    position: 'absolute',
    top: 142,
    left: 17,
    flex: 1,
    height: 145,
    width: 160,
    borderRadius: 58,
    zIndex: 2,
  },
  boxShadow: {
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    shadowColor: '#001F31',
    elevation: 5,
  },
  zero: {
    position: 'absolute',
    top: 285,
    left: 75,
    color: 'black',
    zIndex: 2,
    fontFamily: 'Poppins_500Medium',
    fontSize: 12,
  },
  hundred: {
    position: 'absolute',
    top: 285,
    left: 120,
    color: 'black',
    zIndex: 2,
    fontFamily: 'Poppins_500Medium',
    fontSize: 12,
  },
  scrollArrows: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: 'white',
    justifyContent: 'center',
    top: 40,
    position: 'absolute',
    marginLeft: 10,
  },
  arrow: {
    marginHorizontal: 6,
    backgroundColor: 'transparent',
  },
  appInfo: {
    color: Colors.light.hereworksLightBlue800,
    fontFamily: 'Poppins_400Regular',
    fontSize: 10,
    position: 'absolute',
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
});
