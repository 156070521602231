import React, { Dispatch, SetStateAction, useRef } from 'react';
import {
  StyleSheet, TouchableOpacity, Text, ScrollView,
} from 'react-native';
import { DraxProvider, DraxList } from 'react-native-drax';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';
import FloorItem, { Floor, FloorPriority } from './FloorItem';
import PlusIcon from '../../16pxIcons/PlusIcon';

interface FloorListProps {
  data: Floor[],
  edit: Dispatch<SetStateAction<number>>,
  // eslint-disable-next-line no-unused-vars
  setData: (data: Floor[]) => void,
}

const FloorList = ({ data, edit, setData }: FloorListProps) => {
  const scrollView = useRef<ScrollView>();

  return <ScrollView
    style={styles.container}
    ref={scrollView}
  >
    <GestureHandlerRootView style={styles.stretch}>
      <DraxProvider style={styles.stretch}>
        <DraxList
          {... { data }}
          CellRendererComponent={({ children }) => children}
          removeClippedSubviews={false}
          onItemReorder={({ fromIndex, toIndex }) => {
            const newData: Floor[] = data.slice();
            newData.splice(toIndex, 0, newData.splice(fromIndex, 1)[0]);
            setData(newData);
          }}
          renderItemContent={({ item, index }) => <FloorItem
            {...{ ...item, index }}
            edit={() => edit(index)}
            remove={() => setData(data?.filter((value, itemIndex) => itemIndex !== index))}
          />}
          itemStyles={{ draggingStyle: styles.draggingStyle }}
        />
      </DraxProvider>
    </GestureHandlerRootView>
    <TouchableOpacity
      style={[Style.row, styles.addFloorButton]}
      onPress={() => {
        const updatedFloors: Floor[] = [...data];
        const nextFloorNumber: number = 1 + (data
          ?.reduce((prev, current) => Math.max(prev, parseInt(current.Number, 10)), 0) ?? 0);
        updatedFloors.push({
          Name: `Floor ${nextFloorNumber}`,
          Number: `${nextFloorNumber}`,
          Priority: FloorPriority.NORMAL,
          plan: null,
          planSize: null,
          planFilename: null,
        });
        setData(updatedFloors);
        const timeout = setTimeout(() => {
          scrollView?.current?.scrollToEnd();
          clearTimeout(timeout);
        }, 500);
      }}
    >
      <Text style={styles.addFloorText}>Add floor</Text>
      <PlusIcon color={styles.addFloorText.color} />
    </TouchableOpacity>
  </ScrollView>;
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 32,
    paddingBottom: 12,
    flex: 1,
  },
  stretch: {
    alignSelf: 'stretch',
  },
  draggingStyle: {
    backgroundColor: Colors.light.hereworksBlack100,
    borderRadius: 8,
  },
  addFloorButton: {
    alignSelf: 'stretch',
    marginStart: 18,
    marginEnd: 24,
    borderColor: Colors.light.hereworksBlue500,
    borderWidth: 1,
    borderRadius: 4,
    shadowColor: '#323247',
    shadowOpacity: 0.06,
    shadowRadius: 2,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  addFloorText: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlue500,
    marginEnd: 8,
  },
});

export default FloorList;
