import React, { memo, useEffect, useState } from 'react';
import {
  ActivityIndicator, Image, Pressable, StyleSheet, Text, View,
} from 'react-native';
import Colors from '../../constants/Colors';
import OrionArrowIcon from '../24pxIcons/OrionArrowIcon';
import CheckBox24 from '../24pxIcons/CheckBox24';

const AssetRowMobile = (props) => {
  const [selected, setSelected] = useState(props.selected ?? false);
  const [switchToggled] = useState(true);
  const [imageKey, setImageKey] = useState(Date.now());
  const [loadingImage, setLoadingImage] = useState(true);

  const deleteEnabled = false;

  // eslint-disable-next-line global-require
  const Placeholder = require('../../assets/images/placeholder.png');

  useEffect(() => {
    setImageKey(Date.now());
  }, [props.instance]);

  const renderCardClosedView = () => (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
        },
      ]}
      accessibilityRole="link"
    >
      <View
        style={{
          width: 8,
          height: 60,
          zIndex: 1,
          backgroundColor: switchToggled
            ? Colors.light.hereworksGreen400
            : Colors.light.hereworksWhite600,
        }}
      />
      {deleteEnabled && (
        <Pressable
          style={{ justifyContent: 'center' }}
          onPress={() => {
            props.selectionChanged();
            setSelected(!selected);
          }}
        >
          <CheckBox24
            checkcolor={props.selected ?? selected ? Colors.light.hereworksBlue500 : null}
            color={
              props.selected ?? selected
                ? Colors.light.hereworksBlue500
                : Colors.light.hereworksBlue100
            }
            style={{ marginHorizontal: 16 }}
          />
        </Pressable>
      )}
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          marginRight: 46,
          marginLeft: 16,
        }}
      >
        <View
          style={{
            width: 80,
            height: 48,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            key={imageKey}
            source={
              props.instance.Images.length > 0
                ? {
                  uri:
                      props.instance.Images.length > 0
                        ? `${props.instance.Images[0]?.url}?time=${imageKey}`
                        : Placeholder,
                }
                : Placeholder
            }
            resizeMode={'cover'}
            onLoadEnd={() => setLoadingImage(false)}
            style={{ width: 80, height: 48, borderRadius: 4 }}
          />
          {props.instance.Images.length > 0 && loadingImage && (
            <ActivityIndicator style={{ position: 'absolute' }} />
          )}
        </View>
        <View style={{ flex: 1, alignItems: 'flex-start', justifyContent: 'center' }}>
          <Text style={styles.rowTextMobile}>{props.instance.Name}</Text>
        </View>
      </View>
    </View>
  );

  if (props.instance === null) return null;

  return (
    <Pressable
      style={({ pressed }) => [
        pressed
          ? { backgroundColor: Colors.light.hereworksWhite300 }
          : { backgroundColor: Colors.light.hereworksWhite100 },
        {
          borderRadius: 10,
          overflow: 'hidden',
          alignSelf: 'stretch',
          marginVertical: 6,
          flex: 1,
        },
      ]}
      onPress={props.selectAsset}
    >
      {renderCardClosedView()}
      <View
        style={{
          position: 'absolute',
          right: 24,
          top: 16,
        }}
      >
        <OrionArrowIcon
          style={[{ position: 'absolute', right: 0, transform: [{ rotateZ: '180deg' }] }]}
        />
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  rowText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
    textAlign: 'left',
    marginLeft: 30,
  },
  rowTextMobile: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    textAlign: 'left',
    marginLeft: 12,
  },
  rowSubText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    color: Colors.light.hereworksPrimaryGrey700,
  },
  mainDescText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 14,
    marginBottom: 8,
    color: Colors.light.hereworksBlack400,
  },
  buttonText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    marginVertical: 5,
    marginHorizontal: 12,
    color: '#fff',
  },
  raisedTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 18,
    lineHeight: 27,
    marginLeft: 24,
    color: Colors.light.hereworksBlack600,
    flex: 3,
  },
  mainValueText: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 14,
    lineHeight: 16,
    color: Colors.light.hereworksBlack600,
    marginBottom: 12,
  },
  ticketsValue: {
    fontSize: 34,
    lineHeight: 42,
    marginBottom: 9,
    fontFamily: 'Poppins_600SemiBold',
    color: '#fff',
    marginLeft: 24,
  },
  ticketsTitle: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Roboto_400Regular',
    color: '#fff',
    marginLeft: 4,
    marginBottom: 14,
  },
  dateButtonTitle: {
    marginVertical: 4,
    marginHorizontal: 12,
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
  },
  dateButton: {
    borderRadius: 4,
    marginHorizontal: 4,
    borderWidth: 1,
    borderColor: '#fff',
  },
});

export default memo(AssetRowMobile);
