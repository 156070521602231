import React, { useEffect, useState } from 'react';
import {
  StyleSheet, View, ScrollView, Animated, Easing,
} from 'react-native';
import Moment from 'moment';
import { RootTabScreenProps } from '../types';
import Colors from '../constants/Colors';
import BackgroundImage from '../components/BackgroundImage';
import YearlyParametersSection from '../components/InternalEnvironment/YearlyParametersSection';
import AirQualitySection from '../components/InternalEnvironment/AirQuality';
import TopSection, { TopSectionProps } from '../components/TopSection';

// eslint-disable-next-line no-unused-vars
export default function InternalEnvironmentScreen({
  // eslint-disable-next-line no-unused-vars
  navigation,
}: RootTabScreenProps<'InternalEnvironment'>) {
  const [range, setRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: Moment().subtract(6, 'days').startOf('day').toDate(), end: new Date() });

  const [compareRange, setCompareRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: null, end: null });

  const onConfirm = React.useCallback(
    (start, end) => {
      setRange({ start, end });
      // console.log(range);
    },
    [setRange],
  );

  const onCompareConfirm = React.useCallback(
    (start, end) => {
      setCompareRange({ start, end });
    },
    [setCompareRange],
  );

  const [happyScore] = useState(85);
  const [happyScoreCompared] = useState(60);
  const happyScoreAnimatedValue: Animated.Value = new Animated.Value(0);
  const happyScoreComparedAnimatedValue: Animated.Value = new Animated.Value(0);
  const happyScoreAnimation: Animated.CompositeAnimation = Animated.timing(
    happyScoreAnimatedValue,
    {
      duration: 25 * happyScore,
      useNativeDriver: true,
      easing: (time: number) => Easing.ease(time),
      toValue: happyScore,
    },
  );
  const happyScoreComparedAnimation: Animated.CompositeAnimation = Animated.timing(
    happyScoreComparedAnimatedValue,
    {
      duration: 25 * happyScoreCompared,
      useNativeDriver: true,
      easing: (time: number) => Easing.ease(time),
      toValue: happyScoreCompared,
    },
  );

  useEffect(() => {
    Animated.parallel([happyScoreAnimation, happyScoreComparedAnimation]).start();
  }, [compareRange.end]);

  const topSectionProps: TopSectionProps = {
    range, compareRange, onConfirm, onCompareConfirm, title: 'Internal Environment',
  };

  return <ScrollView style={styles.container}>
    <BackgroundImage />
    <TopSection {...topSectionProps} />
    <View style={styles.content}>
      <AirQualitySection dates={range}
        compareDates={compareRange}
        showComparison={compareRange.end !== null} />
      <YearlyParametersSection dates={range}
        compareDates={compareRange}
        showComparison={compareRange.end !== null} />
    </View>
  </ScrollView>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.light.hereworksBackground,
  },
  content: {
    paddingHorizontal: 40,
    maxWidth: 1228,
  },
});
