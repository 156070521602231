import * as React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';

const PriorityFlag = (props: SvgProps) => <Svg width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
  <G id='Flag 2'>
    <Path
      id='Vector 314'
      d='M8.28571 14H11.1429M14 14H11.1429M11.1429 9.8V2.6C11.1429 2.6 10.5714 2 8.85715 2C7.14286 2 6 3.2 4.28572 3.2C2.57143 3.2 2 2.6 2 2.6V9.8C2 9.8 2.57143 10.4 4.28572 10.4C6 10.4 7.14286 9.2 8.85715 9.2C9.78071 9.2 10.3726 9.37415 10.7221 9.5348C11.0213 9.67235 11.1429 9.8 11.1429 9.8ZM11.1429 9.8V11.9V14'
      stroke={props.color ?? '#ED364C'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </G>
</Svg>;

export default PriorityFlag;
