import React from 'react';
import {
  TouchableOpacity, Text, StyleSheet, FlexStyle,
} from 'react-native';
import Colors from '../constants/Colors';

interface ButtonProps {
  affirmative?: boolean,
  onPress?: () => void,
  title: string,
  disabled?: boolean,
  style?: FlexStyle,
  children?,
}

const Button = ({
  affirmative = false, onPress = null, title = '', disabled = false, style, children,
}: ButtonProps) => <TouchableOpacity
  disabled={disabled}
  onPress={onPress}
  style={[styles.button, affirmative && styles.affirmative, disabled && styles.disabled, style]}>
    <Text style={[styles.buttonText, affirmative && styles.affirmativeText]}>{title}</Text>
    {children}
  </TouchableOpacity>;

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 24,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    backgroundColor: '#FFF',
    borderColor: Colors.light.hereworksBlue500,
    shadowOpacity: 0.06,
    shadowRadius: 2,
    elevation: 2,
    shadowOffset: { width: 2, height: 4 },
    shadowColor: '#323247',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  buttonText: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlue500,
  },
  affirmative: {
    backgroundColor: Colors.light.hereworksBlue500,
    marginStart: 12,
  },
  affirmativeText: {
    color: '#FFF',
  },
  disabled: {
    opacity: 0.5,
  },
});

export default Button;
