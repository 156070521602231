import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const BinIcon = (props: SvgProps) => (
  <Svg
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <Path
      d="M6.875 2.375h2.25a1.125 1.125 0 0 0-2.25 0Zm-1.125 0a2.25 2.25 0 1 1 4.5 0h4.5a.562.562 0 1 1 0 1.125h-.634l-1.356 9.943a2.813 2.813 0 0 1-2.787 2.432H6.027a2.812 2.812 0 0 1-2.787-2.432L1.884 3.5H1.25a.563.563 0 1 1 0-1.125h4.5Zm1.125 3.938a.563.563 0 1 0-1.125 0v5.625a.562.562 0 1 0 1.125 0V6.311Zm2.813-.563a.562.562 0 0 1 .562.563v5.625a.562.562 0 1 1-1.125 0V6.311a.563.563 0 0 1 .563-.562Zm-5.333 7.54a1.687 1.687 0 0 0 1.672 1.46h3.946a1.688 1.688 0 0 0 1.673-1.46L12.98 3.5H3.02l1.335 9.79Z"
      fill="#323E48"
    />
  </Svg>
);

export default BinIcon;
