import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import {
  ActivityIndicator,
  Platform, StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import Modal from 'react-native-modal';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../constants/Colors';
import AlertIcon from './AlertIcon';
import ResolvedIcon from './ResolvedIcon';
import AlertIconMobile from './16pxIcons/AlertIconMobile';
import ResolvedIconMobile from './16pxIcons/ResolvedIconMobile';
import Style from '../constants/Style';

const mobile: boolean = !(Platform.OS === 'web');

interface AlertModalProps {
  modalVisible: boolean,
  setModalVisible: Dispatch<SetStateAction<boolean>>,
  title: string,
  message?: string | ReactElement,
  error?: boolean,
  action?: () => void,
  actionTitle?: string,
  closeTitle?: string,
  onDismiss?: () => void,
  loading?: boolean,
}

export default function AlertModal({
  modalVisible, setModalVisible, title, message, error = true, action, actionTitle, closeTitle,
  onDismiss, loading = false,
}: AlertModalProps) {
  const logAnalyticsEvent = (logTitle) => Analytics.logEvent('button_press', {
    screen: 'AlertModal',
    name: logTitle,
  });

  const renderClose = () => <View style={styles.closeButtonContainer}>
    <TouchableOpacity style={[styles.button, !(action && actionTitle) && styles.actionButton]}
      onPress={() => {
        logAnalyticsEvent('Close alert modal');
        if (action && !actionTitle) action();
        setModalVisible(false);
      }}>
      <Text style={[styles.buttonText, !(action && actionTitle) && styles.actionButtonText]}>
        {(action && actionTitle) ? 'Cancel' : closeTitle ?? 'Close'}
      </Text>
    </TouchableOpacity>
    {actionTitle && action && <TouchableOpacity style={[styles.button, styles.actionButton]}
      onPress={() => {
        logAnalyticsEvent(`Alert modal action: ${actionTitle}`);
        setModalVisible(false);
        action();
      }}>
      <Text style={[styles.buttonText, styles.actionButtonText]}>{actionTitle}</Text>
    </TouchableOpacity>}
  </View>;

  return loading ? <ActivityIndicator
    size='large'
    animating={loading}
    style={Style.activityIndicator}
  /> : <Modal isVisible={modalVisible}
    onDismiss={onDismiss}
    style={{ alignItems: 'center' }}
    onSwipeComplete={() => setModalVisible(false)}
    onBackButtonPress={() => setModalVisible(false)}
    onBackdropPress={() => setModalVisible(false)}
    swipeDirection={mobile ? 'down' : null}
    animationIn={mobile ? 'slideInUp' : 'fadeIn'}
    animationOut={mobile ? 'slideOutDown' : 'fadeOut'}>
    <View style={[styles.container, error && styles.containerError]}>
      <View style={[styles.iconContainer, error && styles.iconContainerError]}>
        {error && (mobile ? <AlertIconMobile /> : <AlertIcon />)}
        {!error && (mobile ? <ResolvedIconMobile /> : <ResolvedIcon />)}
      </View>
      <Text style={[styles.title, error && styles.titleError]}>{title}</Text>
      {message && <Text style={styles.description}>{message}</Text>}
      {renderClose()}
    </View>
  </Modal>;
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: mobile ? 16 : 40,
    margin: 10,
    borderWidth: 1,
    borderColor: Colors.light.hereworksGreen400,
    width: mobile ? 265 : 590,
    maxWidth: '95%',
    alignItems: 'center',
    shadowRadius: 20,
    shadowOffset: { width: 0, height: 8 },
    elevation: 8,
    shadowOpacity: 0.05,
    shadowColor: '#000',
  },
  containerError: {
    borderColor: Colors.light.hereworksRed500,
  },
  iconContainer: {
    width: mobile ? 40 : 64,
    height: mobile ? 40 : 64,
    borderRadius: 32,
    backgroundColor: Colors.light.hereworksGreen400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainerError: {
    backgroundColor: Colors.light.hereworksRed500,
  },
  title: {
    fontSize: mobile ? 20 : 28,
    lineHeight: mobile ? 24 : 34,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksGreen400,
    marginTop: mobile ? 24 : 32,
    textAlign: 'center',
    width: '100%',
  },
  titleError: {
    color: Colors.light.hereworksRed500,
  },
  description: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    color: Colors.light.hereworksBlack400,
    marginTop: mobile ? 12 : 27,
    textAlign: 'center',
    width: '100%',
  },
  closeButtonContainer: {
    marginTop: mobile ? 16 : 40,
    flexDirection: 'row',
  },
  button: {
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.06,
    shadowColor: '#323247',
    elevation: 4,
  },
  actionButton: {
    backgroundColor: Colors.light.hereworksLightBlue,
    marginStart: 12,
  },
  buttonText: {
    color: Colors.light.hereworksBlue500,
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    marginVertical: 8,
    marginHorizontal: 24,
  },
  actionButtonText: {
    color: '#FFF',
  },
});
