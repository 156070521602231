import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UpArrow = (props: SvgProps) => (
  <Svg width={12} height={14} fill="none" {...props}>
    <Path
      d="M6.53.47a.75.75 0 0 0-1.06 0L.697 5.243a.75.75 0 1 0 1.06 1.06L6 2.061l4.243 4.242a.75.75 0 0 0 1.06-1.06L6.53.47ZM5.25 13a.75.75 0 0 0 1.5 0h-1.5Zm0-12v12h1.5V1h-1.5Z"
      fill={props.color ?? '#323E48'}
    />
  </Svg>
);

export default UpArrow;
