import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ShareIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="m13.5 5 7.5 6.222-7.5 6.222v-3.11S6 14.333 3 19c0 0 1.5-9.333 10.5-10.889V5Z"
      stroke="#0058AD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ShareIcon;
