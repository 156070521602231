import {
  ActivityIndicator,
  Keyboard, Pressable, StyleSheet, Text, TextInput, View,
} from 'react-native';
import { useEffect, useState } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../constants/Colors';
import updateNote from '../requests/UpdateNote';

interface Props {
  id: any;
  value: any;
  problems: any;
}

const SendNote = (props: Props) => {
  const { id, value, problems } = props;

  const [noteValue, setNoteValue] = useState(value);
  const [noteSaved, setNoteSaved] = useState(false);
  const [savingNote, setSavingNote] = useState(false);

  useEffect(() => {
    setNoteValue(props.value);
  }, [id]);

  return (
    <View key={`${id}`}>
    <TextInput
      placeholder="Type your note here..."
      placeholderTextColor={Colors.light.hereworksPrimaryGrey700}
      value={noteValue ?? ''}
      onChangeText={(text) => {
        if (!savingNote) {
          setNoteValue(text);
        }
      }}
      multiline={true}
      // @ts-ignore
      style={[styles.noteText, { outlineStyle: 'none' }]}
      onBlur={() => {
        Keyboard.dismiss();
      }}
    />
    <Pressable
      onPress={() => {
        setSavingNote(true);
        updateNote(id, noteValue).then(() => {
          setSavingNote(false);
          setNoteSaved(true);
          // eslint-disable-next-line max-len
          problems.filter((problem) => problem.id === id)[0].notes = noteValue;
        });
      }}
      disabled={noteValue?.trim().length === undefined
        || noteValue?.trim().length === 0
        || savingNote}
      style={(state) => [{
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: state.pressed
        || noteValue?.trim().length === undefined
        || noteValue?.trim().length === 0
          ? Colors.light.hereworksBlue200
          : (state.hovered
            ? Colors.light.hereworksBlue400 : Colors.light.hereworksBlue500),
        borderRadius: 4,
        overflow: 'hidden',
        alignSelf: 'flex-end',
        marginRight: 10,
        marginVertical: 8,
      }]}>
      <Text style={styles.sendButton}>Send</Text>
      {savingNote && (
        <View
          style={{
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            position: 'absolute',
            backgroundColor: Colors.light.hereworksBlue500,
            justifyContent: 'center',
          }}
        >
          <ActivityIndicator color={'#fff'} size={'small'} />
        </View>
      )}
    </Pressable>
    {noteSaved && (
      <View
        style={{ flexDirection: 'row', justifyContent: 'flex-end', marginRight: 10 }}
      >
        <Text style={styles.noteSaveStatus}>Note sent to the reporter</Text>
        <View style={{ width: 20, marginLeft: 10 }}>
          <FontAwesome
            size={20}
            style={{ color: Colors.light.hereworksPrimaryGrey800 }}
            name={'check'}
          />
        </View>
      </View>
    )}
    </View>
  );
};

const styles = StyleSheet.create({
  noteSaveStatus: {
    alignSelf: 'flex-end',
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    color: Colors.light.hereworksPrimaryGrey700,
  },
  sendButton: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    color: '#fff',
    marginVertical: 5,
    marginHorizontal: 12,
  },
  noteText: {
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    height: 100,
    backgroundColor: Colors.light.hereworksBackground,
    marginVertical: 10,
    marginRight: 10,
    borderRadius: 6,
    paddingLeft: 14,
    paddingVertical: 12,
    textAlignVertical: 'top',
  },
});

export default SendNote;
