import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ThumbsUpIcon = (props: SvgProps) => (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <Path d="M4.66667 7.33304L4.10423 6.97513L4 7.13891V7.33304H4.66667ZM10 7.33304L9.40372 7.03491C9.30039 7.24156 9.31143 7.48699 9.43289 7.68353C9.55437 7.88008 9.76895 7.99971 10 7.99971V7.33304ZM19.3333 13.9997L19.8667 14.3997L20 14.222V13.9997H19.3333ZM0.666667 6.66637C0.298477 6.66637 0 6.96485 0 7.33304V19.3331C0 19.7012 0.298477 19.9997 0.666667 19.9997C1.03486 19.9997 1.33333 19.7012 1.33333 19.3331V7.33304C1.33333 6.96485 1.03486 6.66637 0.666667 6.66637ZM5.22911 7.69096L8.82592 2.03884L7.70103 1.323L4.10423 6.97513L5.22911 7.69096ZM11.2231 3.39611L9.40372 7.03491L10.5963 7.63119L12.4157 3.99239L11.2231 3.39611ZM10 7.99971H16.6667V6.66637H10V7.99971ZM18.6667 9.99971V13.9997H20V9.99971H18.6667ZM18.8 13.5997L15.6 17.8664L16.6667 18.6664L19.8667 14.3997L18.8 13.5997ZM14 18.6664H7.33333V19.9997H14V18.6664ZM5.33333 16.6664V7.33304H4V16.6664H5.33333ZM16.6667 7.99971C17.7712 7.99971 18.6667 8.89515 18.6667 9.99971H20C20 8.15876 18.5076 6.66637 16.6667 6.66637V7.99971ZM7.33333 18.6664C6.22876 18.6664 5.33333 17.7709 5.33333 16.6664H4C4 18.5073 5.49239 19.9997 7.33333 19.9997V18.6664ZM10.6991 1.59664C11.3171 1.96743 11.5454 2.75151 11.2231 3.39611L12.4157 3.99239C13.0496 2.72463 12.6005 1.18256 11.3851 0.453312L10.6991 1.59664ZM15.6 17.8664C15.2223 18.37 14.6295 18.6664 14 18.6664V19.9997C15.0492 19.9997 16.0372 19.5057 16.6667 18.6664L15.6 17.8664ZM8.82592 2.03884C9.22728 1.40813 10.0581 1.21201 10.6991 1.59664L11.3851 0.453312C10.1243 -0.303154 8.4904 0.0825651 7.70103 1.323L8.82592 2.03884Z"
            fill={props.color ?? '#3379BD'} />
    </Svg>
);

export default ThumbsUpIcon;
