import * as React from 'react';
import Svg, { SvgProps, Path, Circle } from 'react-native-svg';

const HygieneIcon24 = (props: SvgProps) => (
  <Svg width={25} height={24} fill="none" {...props}>
    <Path
      d="M9.533 22h5.7a1.2 1.2 0 0 0 0-2.4h-3.6M11.633 19.6h6.6a1.2 1.2 0 1 0 0-2.4h-6.6M11.633 17.2h9a1.2 1.2 0 1 0 0-2.4h-9"
      stroke="#89A7CB"
      strokeWidth={1.5}
    />
    <Path
      d="M9.533 22H4.915a2.582 2.582 0 0 1-2.582-2.582v-4.436a5.946 5.946 0 0 1 2.647-4.947L9.31 7.15a.268.268 0 0 1 .372.074c.63.945.595 2.185-.086 3.093l-1.229 1.638a.279.279 0 0 0 .223.446h9.943a1.2 1.2 0 0 1 0 2.4h-6.6"
      stroke="#89A7CB"
      strokeWidth={1.5}
    />
    <Circle cx={15.333} cy={7} r={2.25} stroke="#89A7CB" strokeWidth={1.5} />
    <Circle cx={20.833} cy={3.5} r={0.75} stroke="#89A7CB" strokeWidth={1.5} />
    <Circle cx={4.833} cy={4.5} r={0.75} stroke="#89A7CB" strokeWidth={1.5} />
  </Svg>
);

export default HygieneIcon24;
