/* eslint-disable camelcase */
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import React from 'react';
// import * as ScreenOrientation from 'expo-screen-orientation';
import 'react-native-gesture-handler';
import { Auth } from 'aws-amplify';
import {
  useFonts,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
  Poppins_900Black,
} from '@expo-google-fonts/poppins';
import { en, registerTranslation } from 'react-native-paper-dates';
import * as SplashScreen from 'expo-splash-screen';
import { DarkTheme, DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import {
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
} from '@expo-google-fonts/roboto';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from './aws-exports';
import Navigation from './navigation';
import useColorScheme from './hooks/useColorScheme';
import useCachedResources from './hooks/useCachedResources';
import Colors from './constants/Colors';

// Need manually add Intl polyfill for react-native app
import 'intl';
import 'intl/locale-data/jsonp/en';

const urlOpenerExpo = async (url: any, redirectUrl: any) => {
  const result = await WebBrowser.openAuthSessionAsync(url, redirectUrl);
  let newUrl = 'hereworksadmin://';
  if (result.type === 'success') {
    newUrl = result.url;
  }

  // console.log(result.type);
  // console.log(newUrl);
  if (result.type === 'success' && Platform.OS !== 'android') {
    WebBrowser.dismissBrowser();
    if (Platform.OS === 'ios') {
      return Linking.openURL(newUrl);
    }
  }
  return Linking.openURL(newUrl);
};

const updatedConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    urlOpener: urlOpenerExpo,
  },
};

// eslint-disable-next-line no-undef
if (window.location != null && !window.location.toString().includes('localhost')) {
  // eslint-disable-next-line no-undef
  updatedConfig.oauth.redirectSignIn = `${window.location.origin}/`;
  // eslint-disable-next-line no-undef
  updatedConfig.oauth.redirectSignOut = `${window.location.origin}/`;
  // eslint-disable-next-line no-undef
  config.oauth.redirectSignIn = `${window.location.origin}/`;
  // eslint-disable-next-line no-undef
  config.oauth.redirectSignOut = `${window.location.origin}/`;
}

let domain = '';
let poolId = '';
let clientId = '';

AsyncStorage.getItem('domain')
  .then((newDomain) => {
    domain = newDomain ?? '';
  })
  .then(() => AsyncStorage.getItem('poolId')
    .then((pool) => {
      poolId = pool ?? '';
    })
    .then(() => AsyncStorage.getItem('clientId').then((client) => {
      clientId = client ?? '';
      if (domain !== '' && poolId !== '' && clientId !== '') {
        // console.log(`aws ids: ${domain}${poolId}${clientId}`);
        config.aws_user_pools_id = poolId ?? '';
        config.aws_user_pools_web_client_id = clientId ?? '';
        config.oauth.domain = domain ?? '';
        updatedConfig.aws_user_pools_id = poolId ?? '';
        updatedConfig.aws_user_pools_web_client_id = clientId ?? '';
        updatedConfig.oauth.domain = domain ?? '';
      } else {
        // console.log(`no aws ids: ${domain}${poolId}${clientId}`);
      }

      if (Platform.OS === 'web') {
        Auth.configure(config);
      } else {
        Auth.configure(updatedConfig);
      }
    })));

const theme = {
  ...DarkTheme,
  // Specify custom property
  myOwnProperty: true,
  // Specify custom property in nested object
  colors: {
    myOwnColor: '#BADA55',
  },
};

if (Platform.OS === 'android') {
  // See https://github.com/expo/expo/issues/6536 for this issue.
  // eslint-disable-next-line no-underscore-dangle
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    // eslint-disable-next-line no-underscore-dangle
    (Intl as any).__disableRegExpRestore();
  }
}

export type ThemeOverride = typeof theme;

SplashScreen.preventAutoHideAsync();

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  // if (Platform.OS !== 'web') {
  //   ScreenOrientation.unlockAsync().catch();
  // }

  registerTranslation('en', en);

  const [fontsLoaded] = useFonts({
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
    Poppins_900Black,
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
  });

  if (!fontsLoaded) {
    return null;
  }

  // const currentConfig = Auth.configure();

  // console.log(currentConfig);

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <PaperProvider
      theme={{
        colors: {
          ...DefaultTheme.colors,
          primary: Colors.light.hereworksPrimaryGrey,
        },
        dark: false,
        roundness: 0,
        fonts: {
          regular: { fontFamily: 'Poppins_500Medium', fontWeight: '500' },
          medium: { fontFamily: 'Poppins_500Medium', fontWeight: '500' },
          light: { fontFamily: 'Poppins_500Medium', fontWeight: '500' },
          thin: { fontFamily: 'Poppins_500Medium', fontWeight: '500' },
        },
        animation: DefaultTheme.animation,
      }}
    >
      <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar style="dark" />
      </SafeAreaProvider>
    </PaperProvider>
  );
}
