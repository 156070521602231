import React, { useState } from 'react';
import {
  StyleSheet, Image, TouchableOpacity, Text, Dimensions, View,
} from 'react-native';

import { DrawerContentScrollView } from '@react-navigation/drawer';
import { FontAwesome } from '@expo/vector-icons';
import { DefaultTheme } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Colors from '../constants/Colors';
import ExpandableDrawer from './ExpandableDrawer';
import Link from './Link';
import BuildingIcon from './BuildingIcon';
import getLocations from '../requests/GetLocations';

interface Props {
  state: any;
  navigation: any;
  descriptors: any;
  toggleDarkMode: any;
}

const defaultLogoUri: string = 'https://s3.eu-west-1.amazonaws.com/insights.hereworks.tech/clientLogos/hereworks-logo.png';

const CustomDrawer = (props: Props) => {
  const [showBottomLogo] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [clientLogo, setClientLogo] = useState(defaultLogoUri);

  React.useEffect(() => {
    AsyncStorage.getItem('clientLogo').then((logo: string) => {
      if (logo?.length) setClientLogo(logo);
      else {
        AsyncStorage.getItem('clientName').then((name: string) => {
          if (name?.toLowerCase().includes('mastercard')) setClientLogo('https://s3.eu-west-1.amazonaws.com/insights.hereworks.tech/clientLogos/mastercard-hereworks.png');
          else if (name?.toLowerCase().includes('an post')) setClientLogo('https://s3.eu-west-1.amazonaws.com/insights.hereworks.tech/clientLogos/anpost-hereworks.png');
        });
      }
    });
    // AsyncStorage.getItem('clientName').then((clientName) => {
    //   if (clientName !== 'hereworks' && clientName !== 'devhereworks') {
    //     setShowBottomLogo(true);
    //   }
    // });
    getLocations().then((response) => {
      // @ts-ignore
      const buildingsList: string[] = [...new Set(
        response.locations?.map((location: { Building: string }) => location.Building)
          ?.filter((building: string) => building?.length > 0),
      )];
      setBuildings(buildingsList);
      setSelectedBuilding(buildingsList[0]);
    });
  }, []);
  const window = Dimensions.get('window');

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <View style={{ flex: 1, backgroundColor: Colors.light.hereworksBackground }}>
      <View style={styles.drawer}>
        <Image
          source={{
            uri: clientLogo,
          }}
          defaultSource={{ uri: defaultLogoUri }}
          style={styles.sideMenuProfileIcon}
          resizeMode={'contain'}
        />
      </View>
      <View style={styles.mainSideMenu}>
        <DrawerContentScrollView
          style={{
            padding: 0,
            margin: 0,
            height: 0,
            width: '100%',
          }}
          contentContainerStyle={{
            flex: 1,
          }}
          showsVerticalScrollIndicator={false}
          {...props}
        >
          <ExpandableDrawer navObj={props.navigation} initialState={props.state} />
        </DrawerContentScrollView>
        {buildings?.length > 0
          && <View style={{ borderTopWidth: 1, borderColor: Colors.light.hereworksWhite500 }}>
            <Link
              style={{
                borderRadius: 12,
                marginHorizontal: 12,
                marginVertical: 12,
              }}
            >
              <TouchableOpacity
                activeOpacity={0.8}
                style={{
                  marginLeft: 15,
                  paddingVertical: 11,
                  borderRadius: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                onPress={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                <BuildingIcon
                  color={Colors.light.hereworksLightBlue800}
                  style={{ marginRight: 10 }}
                />
                <Text>{selectedBuilding}</Text>
                {buildings?.length > 1 && <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 24 }}>
                  <FontAwesome
                    size={30}
                    style={{ color: Colors.light.hereworksPrimaryGrey800 }}
                    name={isExpanded ? 'angle-down' : 'angle-up'}
                  />
                </View>}
              </TouchableOpacity>
            </Link>
            {isExpanded && buildings?.map((building) => building !== selectedBuilding && <Link
              style={{ borderRadius: 12, marginHorizontal: 12, marginVertical: 12 }}>
              <TouchableOpacity
                activeOpacity={0.8}
                style={{
                  marginLeft: 15,
                  paddingVertical: 11,
                  borderRadius: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                onPress={() => {
                  setSelectedBuilding(building);
                  setIsExpanded(!isExpanded);
                }}
              >
                <BuildingIcon
                  color={Colors.light.hereworksLightBlue800}
                  style={{ marginRight: 10 }}
                />
                <Text>{building}</Text>
                <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 24 }}></View>
              </TouchableOpacity>
            </Link>)
            }
          </View>}
        {showBottomLogo && (
          <Image
            source={{
              uri: 'https://s3.eu-west-1.amazonaws.com/insights.hereworks.tech/clientLogos/hereworks-logo.png',
            }}
            style={styles.sideMenuProfileIcon}
            resizeMode={'contain'}
          />
        )}
      </View>
      <TouchableOpacity
        activeOpacity={0.8}
        style={{
          position: 'absolute',
          marginLeft: window.width - 50,
          marginTop: window.height - 50,
          flexDirection: 'row',
        }}
        onPress={() => {
          props.toggleDarkMode(DefaultTheme);
        }}
      >
        <FontAwesome name="moon-o" size={25} color={'transparent'} style={{ marginRight: 15 }} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  drawer: {
    height: 92,
    width: '100%',
    zIndex: 2,
  },
  sideMenuProfileIcon: {
    alignSelf: 'center',
    width: 200,
    height: 90,
  },
  iconStyle: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  customItem: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  username: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    marginLeft: 10,
    color: Colors.light.hereworksPrimaryGrey,
  },
  mainSideMenu: {
    backgroundColor: '#FFFFFF',
    flex: 1,
    shadowColor: '#FFF',
    shadowRadius: 40,
    shadowOpacity: 0.5,
    shadowOffset: { width: 10, height: 0 },
    elevation: 5,
  },
});

export default CustomDrawer;
