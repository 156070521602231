import React, { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Colors from '../constants/Colors';
import EquipmentIcon60 from './60pxIcons/EquipmentIcon60';
import BinIcon60 from './60pxIcons/BinIcon60';
import DeskIcon60 from './60pxIcons/DeskIcon60';
import HygieneIcon60 from './60pxIcons/HygieneIcon60';
import PrinterIcon from './PrinterIcon';
import CoffeeMachine60 from './60pxIcons/CoffeeMachine60';
import InternetIcon60 from './60pxIcons/InternetIcon60';
import MeetingRoom60 from './60pxIcons/MeetingRoom60';
import AlertModal from './AlertModal';

export default function SelectButtonTile({
  selected,
  title,
  numberSelected,
  onSelect,
}: {
  selected: boolean;
  title: string;
  numberSelected: number;
  onSelect(): void;
}) {
  const [isSelected, setIsSelected] = useState(selected);
  const [alertVisible, setAlertVisible] = useState(false);

  let activeColor = isSelected ? '#fff' : Colors.light.hereworksDarkBlue;

  if (numberSelected >= 6 && !isSelected) {
    activeColor = Colors.light.hereworksPaleBlue700;
  }

  const getIcon = (buttonTitle: string, color: string) => {
    switch (buttonTitle) {
      case 'Equipment':
        return <EquipmentIcon60 color={color} />;
      case 'Desks':
        return <DeskIcon60 color={color} />;
      case 'Toilet ':
        return <HygieneIcon60 color={color} />;
      case 'Printer':
        return <PrinterIcon color={color} />;
      case 'Coffee machine':
        return <CoffeeMachine60 color={color} />;
      case 'Internet':
        return <InternetIcon60 color={color} />;
      case 'Meeting room':
        return <MeetingRoom60 color={color} />;
      case 'Bin':
        return <BinIcon60 color={color} />;
      default:
        return <DeskIcon60 color={color} />;
    }
  };

  return (
    <TouchableOpacity
      style={{
        backgroundColor: isSelected ? Colors.light.hereworksLightBlue : '#fff',
        width: 113,
        margin: 2,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: isSelected ? '#fff' : Colors.light.hereworksPaleBlue700,
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      onPress={() => {
        if (numberSelected === 6 && !isSelected) {
          setAlertVisible(true);
        } else {
          setIsSelected(!isSelected);
          onSelect();
        }
      }}
    >
      <AlertModal error
        modalVisible={alertVisible}
        setModalVisible={setAlertVisible}
        title='Choose a maximum of 6' />
      <View style={{ marginTop: 16, marginBottom: 12 }}>{getIcon(title, activeColor)}</View>
      <Text
        style={{
          fontFamily: 'Roboto_500Medium',
          fontSize: 13,
          marginHorizontal: 8,
          marginBottom: 35,
          textAlign: 'center',
          color: activeColor,
        }}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
}
