/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, Text, Animated,
} from 'react-native';
import { useCountUp } from 'use-count-up';
import Moment from 'moment';
import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient';
import Colors from '../constants/Colors';
import CloudIcon from './CloudIcon';
import CompareArrowsIcon from './CompareArrowsIcon';
import ScoreCardBuilding from './ScoreCardBuilding';
import OverallButtonsPressedGraph from '../graphs/OverallButtonsPressedGraph';
import { GetMetricByDateWithLive } from '../requests/GetMetricByDateWithLive';
import { GetMetricAverage } from '../requests/GetMetricAverage';

type Props = {
  category: string;
  range?: any;
  compareRange?: any;
  happyMetric?: string;
};

// const HappyBuilding = require('../assets/images/happy_building.png');
// const MediumBuilding = require('../assets/images/medium_building.png');
// const SadBuilding = require('../assets/images/sad_building.png');

const ScoreCardMobile = (props: Props) => {
  const [happyScore, setHappyScore] = useState(0);
  const [lastMonthAverage, setLastMonthAverage] = useState(null);
  const [lastThreeMonthAverage, setLastThreeMonthAverage] = useState(null);
  const [allTimeAverage, setAllTimeAverage] = useState(null);
  const [yesterdayAverage, setYesterdayAverage] = useState(null);
  const [changeFromYesterday, setChangeFromYesterday] = useState(null);
  const [happyData, setHappyData] = useState([]);
  const [compareHappyData, setCompareHappyData] = useState([]);

  const { value } = useCountUp({
    isCounting: true,
    end: happyScore,
    duration: 6,
    easing: 'easeOutCubic',
  });

  const [happyColor, setHappyColor] = useState(Colors.light.hereworksPink);
  const [cardBackground, setCardBackground] = useState('#BBC5CA');
  // const [buildingImage, setBuildingImage] = useState(SadBuilding);
  const [cloudOneInset] = useState(new Animated.Value(0));
  const [cloudTwoInset] = useState(new Animated.Value(0));
  const [cloudThreeInset] = useState(new Animated.Value(0));
  const [cloudFourInset] = useState(new Animated.Value(-150));

  useEffect(() => {
    GetMetricAverage(
      'happy_group_energy_usage_local_happyscore_percent',
      { start: new Date(0), end: new Date() },
    ).then((output) => setAllTimeAverage(Number.isFinite(output) ? Math.floor(output) : null));
    GetMetricAverage(
      'happy_group_energy_usage_local_happyscore_percent',
      { start: Moment().subtract(1, 'month').toDate(), end: new Date() },
    ).then((output) => setLastMonthAverage(Number.isFinite(output)
      ? Math.floor(output) : null));
    GetMetricAverage(
      'happy_group_energy_usage_local_happyscore_percent',
      { start: Moment().subtract(3, 'month').toDate(), end: new Date() },
    ).then((output) => setLastThreeMonthAverage(Number.isFinite(output)
      ? Math.floor(output) : null));
  }, []);

  useEffect(() => {
    if (yesterdayAverage) {
      setChangeFromYesterday(Math.floor(100
        * ((happyScore - yesterdayAverage) / yesterdayAverage)));
    }
  }, [yesterdayAverage]);

  useEffect(() => {
    const getData = async () => {
      const Score = [];
      const timeLabels = [];
      let point = {};
      const pointsArray = [];

      try {
        GetMetricByDateWithLive(
          'happy_group_energy_usage_local_happyscore_percent',
          props.range,
          '24h',
          'happyscore_flat_permin',
        )?.then((data: {
          buckets: { key_as_string: string, score: { value: number } }[], source: number
        }) => {
          let index = 0;
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          const scoreValue: number = Math.round(data?.source);
          setHappyScore(Number.isFinite(scoreValue) ? scoreValue : 0);
          data?.buckets?.map((e: { key_as_string: string, score: { value: number } }) => {
            Score.push(e.score.value);
            const dateobj = new Date(e.key_as_string);
            const hourLabel = `${pad(dateobj.getDate())}`;
            timeLabels.push(hourLabel);
            point = {
              name: hourLabel,
              value: Math.floor(e.score.value * 10) / 10,
              index,
              date: dateobj,
            };
            pointsArray.push(point);
            index += 1;
          });
          setHappyData(pointsArray);
          if (!yesterdayAverage) {
            const yesterdayValue: number = data?.buckets
              ?.filter((e: { key_as_string: string }) => new Date(e.key_as_string).toDateString()
                === Moment().subtract(1, 'day').toDate().toDateString())[0].score.value;
            setYesterdayAverage(Number.isFinite(yesterdayValue) ? yesterdayValue : null);
          }
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(e, e.stack);
      }
    };
    getData();
  }, [props.range]);

  useEffect(() => {
    const getData = async () => {
      if (!props.compareRange.end) return;
      const Score = [];
      const timeLabels = [];
      let point = {};
      const pointsArray = [];

      try {
        GetMetricByDateWithLive(
          'happy_group_energy_usage_local_happyscore_percent',
          props.compareRange,
          '24h',
          'happyscore_flat_permin',
        )?.then((data: {
          buckets: { key_as_string: string, score: { value: number } }[], source: number
        }) => {
          let index = 0;
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          setHappyScore(Math.round(data?.source));
          data?.buckets?.map((e: { key_as_string: string, score: { value: number } }) => {
            Score.push(e.score.value);
            const dateobj = new Date(e.key_as_string);
            const hourLabel = `${pad(dateobj.getDate())}`;
            timeLabels.push(hourLabel);
            point = {
              name: hourLabel,
              value: Math.floor(e.score.value * 10) / 10,
              index,
              date: dateobj,
            };
            pointsArray.push(point);
            index += 1;
          });
          setCompareHappyData(pointsArray);
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(e, e.stack);
      }
    };
    getData();
  }, [props.compareRange]);

  React.useEffect(() => {
    const currentValue = parseInt(value?.toString() ?? '', 10);

    if (currentValue < 25) {
      setHappyColor(Colors.light.hereworksPink);
      // setBuildingImage(SadBuilding);
      setCardBackground('#BBC5CA');
    } else if (currentValue < 65) {
      setHappyColor(Colors.light.hereworksOrange);
      // setBuildingImage(MediumBuilding);
      setCardBackground('#EDDDBE');
    } else {
      setHappyColor(Colors.light.hereworksExtraDarkTeal);
      // setBuildingImage(HappyBuilding);
      setCardBackground('#D6F1FA');
    }
  }, [value]);

  React.useEffect(() => {
    Animated.parallel([
      Animated.timing(cloudOneInset, {
        toValue: 315,
        duration: 2000,
        useNativeDriver: true,
      }),
      Animated.timing(cloudTwoInset, {
        toValue: 285,
        duration: 4000,
        useNativeDriver: true,
      }),
      Animated.timing(cloudThreeInset, {
        toValue: 333,
        duration: 6000,
        useNativeDriver: true,
      }),
      Animated.timing(cloudFourInset, {
        toValue: -24,
        duration: 3000,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  const compareDatesView = () => (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: '#fff',
        marginLeft: 20,
      }}
    >
      <Text
        style={{
          color: Colors.light.hereworksBlack600,
          fontSize: 12,
          fontFamily: 'Roboto_500Medium',
        }}
      >
        {Moment(props.range.start).format('D MMM')} {' - '}{' '}
        {Moment(props.range.end ?? props.range.start).format('D MMM')}
      </Text>
      {props.compareRange.start && (
        <>
          <CompareArrowsIcon style={{ marginHorizontal: 10 }} />
          <Text
            style={{
              color: Colors.light.hereworksYellow500,
              fontSize: 12,
              fontFamily: 'Roboto_500Medium',
            }}
          >
            {Moment(props.compareRange.start).format('D MMM')} {' - '}{' '}
            {Moment(props.compareRange.end ?? props.compareRange.start).format('D MMM')}
          </Text>
        </>
      )}
    </View>
  );

  return (
    <View style={{ flexDirection: 'column' }}>
      <Text style={styles.overviewHeading}>Overview</Text>
      <View style={[styles.scoreCard, { backgroundColor: cardBackground }]}>
        <View>
          <View style={styles.happyScore}>
            <View style={styles.totalScoreWrapper}>
              <Text style={styles.totalScore}>Total score</Text>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: Colors.light.hereworksBlue200,
                  borderRadius: 16,
                  marginHorizontal: 8,
                  alignSelf: 'stretch',
                }}
              >
                <View style={{ height: 66, justifyContent: 'center', marginBottom: 8 }}>
                  <Text style={[styles.happyScoreValue, { color: happyColor }]} numberOfLines={1}>
                    {value}
                  </Text>
                </View>
                <Text style={styles.changeFrom}>
                  {(changeFromYesterday > 0 || changeFromYesterday === 0) && '+'}
                  {Number.isFinite(changeFromYesterday) ? changeFromYesterday : '-'}% from yesterday
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.averages}>
            <View style={styles.averageItem}>
              <Text style={styles.averageValue}>{allTimeAverage ?? '-'}</Text>
              <Text style={styles.subTitleAverage}>All time average</Text>
            </View>
            <View style={styles.averagesDivider} />
            <View style={styles.averageItem}>
              <Text style={styles.averageValue}>{lastMonthAverage ?? '-'}</Text>
              <Text style={styles.subTitleAverage}>Avg. last month</Text>
            </View>
            <View style={styles.averagesDivider} />
            <View style={styles.averageItem}>
              <Text style={styles.averageValue}>{lastThreeMonthAverage ?? '-'}</Text>
              <Text style={styles.subTitleAverage}>Avg. last 3 months</Text>
            </View>
          </View>
          <ExpoLinearGradient
            colors={[`${cardBackground}05`, cardBackground]}
            locations={[0, 1]}
            style={{
              position: 'absolute',
              height: 40,
              bottom: 0,
              right: 0,
              left: 0,
              marginBottom: 10,
              zIndex: 1,
            }}
          ></ExpoLinearGradient>
          <ScoreCardBuilding
            style={{
              zIndex: -1,
              alignSelf: 'flex-end',
              marginRight: 20,
              marginBottom: 10,
            }}
          />
          {/* <Image source={buildingImage} style={styles.buildingImageDesktop} /> */}
          <Animated.View
            style={{
              position: 'absolute',
              top: 24,
              left: 0,
              transform: [{ translateX: cloudOneInset }],
              zIndex: -1,
            }}
          >
            <CloudIcon />
          </Animated.View>
          <Animated.View
            style={{
              position: 'absolute',
              top: 81,
              left: 0,
              transform: [{ translateX: cloudTwoInset }],
              zIndex: -1,
            }}
          >
            <CloudIcon />
          </Animated.View>
          <Animated.View
            style={{
              position: 'absolute',
              top: 176,
              left: 0,
              transform: [{ translateX: cloudThreeInset }],
              zIndex: -1,
            }}
          >
            <CloudIcon />
          </Animated.View>
          <Animated.View
            style={{
              position: 'absolute',
              top: 324,
              left: 0,
              transform: [{ translateX: cloudFourInset }],
              zIndex: -1,
            }}
          >
            <CloudIcon />
          </Animated.View>
        </View>
        <View
          style={[
            {
              borderRadius: 10,
              marginTop: -10,
              backgroundColor: '#fff',
              zIndex: 1,
            },
          ]}
        >
          <Text
            style={{
              fontFamily: 'Poppins_400Regular',
              fontSize: 22,
              color: Colors.light.hereworksPrimaryGrey,
              marginLeft: 20,
              marginTop: 18,
            }}
          >
            History graph
          </Text>
          {compareDatesView()}
          {props.range !== undefined && <View />}
          <OverallButtonsPressedGraph
            dates={props.range}
            compareDates={props.compareRange}
            data={happyData}
            compareData={compareHappyData}
            interval="24h"
            compareToggled={props.compareRange.end !== null}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  scoreCard: {
    marginTop: 16,
    marginBottom: 32,
    borderRadius: 24,
    alignSelf: 'stretch',
  },
  averages: {
    marginVertical: 32,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    flex: 3,
    // width: '100%',
  },
  happyScore: {
    marginHorizontal: 16,
    backgroundColor: 'transparent',
    alignItems: 'center',
  },
  buildingImageDesktop: {
    alignSelf: 'center',
    zIndex: -1,
    marginTop: 20,
    height: 180,
    width: 194,
  },
  subTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey900,
  },
  subTitleAverage: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    lineHeight: 16.8,
    color: Colors.light.hereworksPrimaryGrey900,
  },
  subCount: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 24,
    color: Colors.light.hereworksDarkBlue,
    marginLeft: 20,
    marginRight: 8,
  },
  averageValue: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 24,
    justifyContent: 'flex-start',
    textAlign: 'left',
    textAlignVertical: 'top',
    marginBottom: 6,
    color: Colors.light.hereworksDarkBlue,
    alignSelf: 'flex-start',
  },
  happyScoreValue: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 60,
    alignSelf: 'center',
    textAlign: 'center',
    includeFontPadding: false,
    flex: 1,
  },
  totalScore: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 18,
    color: Colors.light.hereworksBlack500,
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 12,
  },
  changeFrom: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksExtraDarkTeal,
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: 12,
  },
  totalScoreWrapper: {
    alignSelf: 'stretch',
  },
  averagesDivider: {
    width: 1.5,
    backgroundColor: 'white',
    height: '100%',
  },
  averageItem: {
    marginHorizontal: 22,
    flex: 1,
  },
  overviewHeading: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 22,
    marginTop: 32,
    color: Colors.light.hereworksPrimaryGrey,
  },
});

export default ScoreCardMobile;
