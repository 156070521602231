import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const DownArrow24 = (props: SvgProps) => (
  <Svg width={24} height={25} fill="none" {...props}>
    <Path
      d="M6.75 9.5 12 14.75l5.25-5.25"
      stroke={props.color ?? '#0058AD'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default DownArrow24;
