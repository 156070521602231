import React from 'react';
import {
  View, StyleSheet, TouchableOpacity, Text,
} from 'react-native';
import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';
import FlagIcon16 from '../../16pxIcons/FlagIcon16';
import EditIcon24 from '../../24pxIcons/EditIcon24';
import DeleteIcon24 from '../../24pxIcons/DeleteIcon24';
import ImageIcon24 from '../../ImageIcon24';

export type LocationPriority = {
  title: string,
  color: string,
}

export const FLOOR_PRIORITY_OPTIONS: LocationPriority[] = [
  { title: 'High', color: Colors.light.hereworksOrange500 },
  { title: 'Medium', color: Colors.light.hereworksBlue600 },
];

// eslint-disable-next-line no-unused-vars, no-shadow
export enum FloorPriority { NORMAL = 1, HIGH = 0 }

export type Floor = {
  Name: string,
  Number: string,
  Priority: FloorPriority,
  plan: string,
  planSize: number,
  planFilename: string,
}

interface FloorItemProps extends Floor {
  index: number,
  edit: () => void,
  remove: () => void,
}

const FloorItem = ({
  Name, Number, Priority = FloorPriority.NORMAL, index,
  edit, remove, plan, planSize, planFilename,
}: FloorItemProps) => <View style={styles.container}>
    <Text style={styles.index}>{index + 1}</Text>
    <View style={styles.content}>
      <View style={[Style.row, styles.stretch]}>
        <View style={Style.row}>
          <Text style={styles.index}>{`FLR: ${Number ?? '-'}`}</Text>
          <View style={[
            Style.row, styles.priorityIndicator,
            { borderColor: FLOOR_PRIORITY_OPTIONS[Priority].color },
          ]}>
            <FlagIcon16 color={FLOOR_PRIORITY_OPTIONS[Priority].color} />
            <Text style={[styles.priorityText, { color: FLOOR_PRIORITY_OPTIONS[Priority].color }]}>
              {FLOOR_PRIORITY_OPTIONS[Priority].title}
            </Text>
          </View>
        </View>
        <View style={Style.row}>
          <TouchableOpacity
            style={styles.editIcon}
            onPress={edit}
          >
            <EditIcon24 />
          </TouchableOpacity>
          <TouchableOpacity onPress={remove}>
            <DeleteIcon24 />
          </TouchableOpacity>
        </View>
      </View>
      <Text style={styles.title}>{Name ?? '-'}</Text>
      <View style={styles.separator} />
      <View style={Style.row}>
        <View style={[Style.row, { flex: 1 }]}>
          <ImageIcon24
            color={plan ? Colors.light.hereworksBlue400 : styles.error.color}
            style={styles.editIcon}
          />
          <Text style={[styles.filename, !(plan && planFilename && planSize) && styles.error]}>
            {(plan && planFilename && planSize)
              ? `${planFilename ?? 'Unknown file'}` : 'Please upload a floor plan'}
          </Text>
        </View>
        {plan
          && <Text style={[styles.index, !(plan && planFilename && planSize) && styles.error]}>
            {`${planSize ?? '-'} MB`}
          </Text>}
      </View>
      <View style={[Style.row, styles.dotsRow]}>
        {[...Array(30).keys()].map((dotIndex: number) => <View
          style={styles.dot}
          key={`dot_first_row_${dotIndex}`}
        />)}
      </View>
      <View style={[Style.row, styles.dotsRow]}>
        {[...Array(30).keys()].map((dotIndex: number) => <View
          style={styles.dot}
          key={`dot_second_row_${dotIndex}`}
        />)}
      </View>
    </View>
  </View>;

const styles = StyleSheet.create({
  stretch: {
    alignSelf: 'stretch',
  },
  container: {
    marginEnd: 24,
    flexDirection: 'row',
    alignSelf: 'stretch',
    marginBottom: 8,
  },
  index: {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack400,
    textAlign: 'center',
    marginEnd: 10,
  },
  content: {
    padding: 16,
    paddingTop: 12,
    borderRadius: 10,
    borderColor: Colors.light.hereworksBlue100,
    backgroundColor: Colors.light.hereworksWhite100,
    flex: 1,
    borderWidth: 1,
  },
  title: {
    fontSize: 18,
    lineHeight: 22,
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_600SemiBold',
    marginTop: 8,
    marginBottom: 12,
  },
  separator: {
    alignSelf: 'stretch',
    height: 1,
    backgroundColor: Colors.light.hereworksBlue100,
    marginBottom: 12,
  },
  editIcon: {
    marginEnd: 8,
  },
  priorityIndicator: {
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 4,
    alignSelf: 'flex-end',
    backgroundColor: '#FFF',
  },
  priorityText: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_500Medium',
    marginStart: 2,
  },
  dotsRow: {
    overflow: 'hidden',
    flexShrink: 1,
    zIndex: -1,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 3,
    backgroundColor: Colors.light.hereworksDarkBlue100,
    marginHorizontal: 5,
    marginTop: 10,
  },
  filename: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
    marginEnd: 12,
    flex: 1,
  },
  error: {
    color: Colors.light.hereworksRed500,
  },
});

export default FloorItem;
