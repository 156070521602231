/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              HomeScreen: 'Home',
            },
          },
          Profile: {
            screens: {
              ProfileScreen: 'Profile',
            },
          },
          Notifications: {
            screens: {
              NotificationsScreen: 'Notifications',
            },
          },
          Analytics: {
            path: 'Analytics',
            screens: {
              AnalyticsScreen: 'Analytics',
            },
          },
          OccupantSatisfaction: {
            path: 'OccupantSatisfaction',
            screens: {
              AnalyticsScreen: 'Satisfaction',
            },
          },
          HappyScoreAnalytics: {
            path: 'HAPPY SCORE ANALYTICS',
            screens: {
              HomeScreen: 'HAPPY SCORE ANALYTICS',
            },
          },
          BuildingOccupancy: {
            path: 'BuildingOccupancy',
            screens: {
              OccupantsScreen: 'Occupancy',
            },
          },
          Amenities: {
            path: 'amenities',
            screens: {
              Amenities: 'Amenities',
            },
          },
          Ergonomics: {
            path: 'ergonomics',
            screens: {
              Ergonomics: 'Ergonomics',
            },
          },
          Map: {
            path: 'map',
            screens: {
              Map: 'Map',
            },
          },
          Energy: {
            path: 'energy',
            screens: {
              Energy: 'Energy',
            },
          },
          IT: {
            path: 'it',
            screens: {
              IT: 'IT',
            },
          },
          ExternalEnvironment: {
            path: 'ExternalEnvironment',
            screens: {
              ExternalEnvironment: 'ExternalEnvironment',
            },
          },
          InternalEnvironment: {
            path: 'InternalEnvironment',
            screens: {
              InternalEnvironment: 'InternalEnvironment',
            },
          },
          Tickets: {
            path: 'Tickets',
            screens: {
              Tickets: 'Tickets',
            },
          },
          AssetManagement: {
            path: 'AssetManagement',
            screens: {
              AssetManagement: 'AssetManagement',
            },
          },
          UserManagement: {
            path: 'UserManagement',
            screens: {
              UserManagement: 'UserManagement',
            },
          },
        },
      },
      Modal: 'modal',
      Home: '*',
    },
  },
};

export default linking;
