import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LeftArrow = (props: SvgProps) => (
    <Svg
        width={12}
        height={22}
        fill="none"
        {...props}
    >
        <Path
            d="M11.707 1.707A1 1 0 0 0 10.293.293l1.414 1.414ZM1 11l-.707-.707a1 1 0 0 0 0 1.414L1 11Zm9.293 10.707a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm0-21.414-10 10 1.414 1.414 10-10L10.293.293Zm-10 11.414 10 10 1.414-1.414-10-10-1.414 1.414Z"
            fill="#696D70"
        />
    </Svg>
);

export default LeftArrow;
