import React, {
  Dispatch, SetStateAction, useRef, useState,
} from 'react';
import {
  Text, StyleSheet, TouchableOpacity, View, ScrollView, FlexStyle,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Colors from '../../constants/Colors';
import BoltIconDesktop from '../24pxIcons/BoltIconDesktop';
import CoffeeIcon from '../CoffeeIcon';
import SunIcon from '../SunIcon';
import MonitorIcon from '../MonitorIcon';
import OccupancyIcon from '../24pxIcons/OccupancyIcon';
import CheckCircle from '../CheckCircle';
import SmileIcon from '../SmileIcon';
import ChairIconDesktop from '../24pxIcons/ChairIconDesktop';
import ThumbsUpIcon from '../24pxIcons/ThumbsUpIcon';
import LeftArrow from '../16pxIcons/LeftArrow';
import RightArrow from '../16pxIcons/RightArrow';

interface IconProps {
  selected: boolean,
  index: number,
}

const Icon = ({ selected, index }: IconProps) => {
  const iconProps = {
    color: selected ? Colors.light.hereworksYellow300 : Colors.light.hereworksBlue400,
  };
  if (index === 0) {
    return <ThumbsUpIcon {...iconProps} />;
  }
  if (index === 1) {
    return <CoffeeIcon {...iconProps} />;
  }
  if (index === 2) {
    return <SunIcon {...iconProps} />;
  }
  if (index === 3) {
    return <MonitorIcon {...iconProps} />;
  }
  if (index === 4) {
    return <BoltIconDesktop {...iconProps} />;
  }
  if (index === 5) {
    return <OccupancyIcon {...iconProps} />;
  }
  if (index === 6) {
    return <CheckCircle {...iconProps} />;
  }
  if (index === 7) {
    return <ChairIconDesktop {...iconProps} />;
  }
  if (index === 8) {
    return <SmileIcon {...iconProps} />;
  }
  return null;
};

interface TileProps {
  title: string,
  score: number,
  onPress: () => void,
  selected: boolean,
  index: number,
  style?: FlexStyle,
}

const Tile = ({
  title, score, onPress, selected, index, style,
}: TileProps) => <TouchableOpacity
  style={[styles.tile, styles.shadow, style]}
  onPress={onPress}
>
    {selected && <LinearGradient
      style={styles.gradientBackground}
      colors={['#186487', '#4CD4C4']}
      start={{ x: 0.5, y: 0 }}>
      <LinearGradient style={styles.gradientBackground}
        start={{ x: -0.2, y: 0 }}
        colors={['#70DAC5', '#0058AD', '#0058AD', '#70DAC5']} />
    </LinearGradient>}
    <View style={styles.content}>
      <Text style={[styles.title, selected && styles.titleSelected]}>{title}</Text>
      <View style={styles.horizontalContainer}>
        <Icon index={index} selected={selected} />
        <Text style={[styles.score, selected && styles.scoreSelected]}>{score ?? '-'}</Text>
      </View>
    </View>
  </TouchableOpacity>;

interface TileSelectorProps {
  data: { title: string, score: number, index: number }[],
  selectedIndex: number,
  setSelectedIndex: Dispatch<SetStateAction<number>>,
}

const TileSelector = ({
  data, selectedIndex, setSelectedIndex,
}: TileSelectorProps) => {
  const scrollView = useRef<ScrollView>(null);
  const [scrollViewWidth, setScrollViewWidth] = useState<number>(0);
  const [scrollContentWidth, setScrollContentWidth] = useState<number>(0);
  const [startButtonDisabled, setStartButtonDisabled] = useState<boolean>(true);
  const [endButtonDisabled, setEndButtonDisabled] = useState<boolean>(false);
  const collapsed: boolean = scrollContentWidth > scrollViewWidth;
  const tileWidth: number = Math.max(188, Math.min(329, scrollViewWidth * 0.19));
  const tileMargin: number = Math.max(6, Math.min(13, scrollViewWidth * 0.006));
  const tileData = [...data];

  return <View style={[styles.container, styles.horizontalContainer]}>
    {collapsed && <TouchableOpacity
      style={[styles.button, styles.shadow, startButtonDisabled && styles.buttonDisabled]}
      disabled={startButtonDisabled}
      onPress={() => scrollView.current?.scrollTo({ x: 0 })}
    >
      <LeftArrow />
    </TouchableOpacity>}
    <ScrollView
      style={[styles.scrollView, !collapsed && styles.scrollViewCollapsed]}
      horizontal
      showsHorizontalScrollIndicator={false}
      scrollEventThrottle={64}
      onScroll={(e) => {
        const offset: number = e.nativeEvent.contentOffset.x;
        setStartButtonDisabled(offset === 0);
        setEndButtonDisabled(offset + scrollViewWidth >= scrollContentWidth - 1);
      }}
      onContentSizeChange={(width) => setScrollContentWidth(width)}
      onLayout={(event) => setScrollViewWidth(event.nativeEvent.layout.width)}
      ref={(ref) => { scrollView.current = ref; }}
    >
      <Tile
        {...tileData?.filter((item) => item.index === 0)[0]}
        style={{ width: tileWidth, marginHorizontal: tileMargin }}
        key={`home_screen_top_section_tile_${0}`}
        onPress={() => setSelectedIndex(0)}
        selected={selectedIndex === 0}
      />
      {tileData?.sort((prev, next) => prev.score - next.score).map((item) => (item.index ? <Tile
        {...item}
        style={{ width: tileWidth, marginHorizontal: tileMargin }}
        key={`home_screen_top_section_tile_${item.index}`}
        onPress={() => setSelectedIndex(item.index)}
        selected={selectedIndex === item.index}
      /> : null))}
    </ScrollView>
    {collapsed && <TouchableOpacity
      style={[styles.button, styles.shadow, endButtonDisabled && styles.buttonDisabled]}
      disabled={endButtonDisabled}
      onPress={() => scrollView.current?.scrollToEnd()}
    >
      <RightArrow />
    </TouchableOpacity>}
  </View>;
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 40,
  },
  horizontalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shadow: {
    shadowOpacity: 0.1,
    shadowRadius: 20,
    shadowOffset: { width: 0, height: 10 },
    shadowColor: '#001F31',
  },
  scrollView: {
    paddingVertical: 18,
    marginHorizontal: 18,
    flex: 1,
  },
  scrollViewCollapsed: {
    marginStart: -6,
  },
  tile: {
    borderRadius: 12,
    height: 60,
    marginHorizontal: 6,
    backgroundColor: '#FFF',
  },
  gradientBackground: {
    borderRadius: 12,
    flex: 1,
    padding: 1,
  },
  content: {
    position: 'absolute',
    height: '100%',
    borderRadius: 12,
    paddingVertical: 10,
    paddingHorizontal: 14,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 12,
    lineHeight: 9.6,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack500,
  },
  titleSelected: {
    color: '#FFF',
  },
  score: {
    fontSize: 20,
    lineHeight: 16,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksBlue500,
    marginStart: 8,
  },
  scoreSelected: {
    color: Colors.light.hereworksYellow300,
  },
  buttonDisabled: {
    opacity: 0.2,
  },
});

export default TileSelector;
