const getPoolId = async (clientId: string) => {
  try {
    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    const response = await fetch(
      `https://api.hereworks.tech/happy/get-pool-id-for-organisation?clientid=${clientId}`,
      {
        method: 'GET',
        headers: requestHeaders,
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export default getPoolId;
