import React, { ReactElement, useState } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../../constants/Colors';
import EllipsisIcon from '../EllipsisIcon';
import AlertModal from '../AlertModal';
import AddTemplateGroupModal from './AddTemplateGroupModal';
import updateTemplateGroup from '../../requests/UpdateTemplateGroup';

export default function TemplateSideBarRow(props) {
  const [editGroupModalVisible, setEditGroupModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertError, setAlertError] = useState<boolean>(true);
  const [alertTitle, setAlertTitle] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string | ReactElement>('');
  const [alertAction, setAlertAction] = useState<() => void>(null);
  const [alertActionTitle, setAlertActionTitle] = useState<string>(null);

  const showAlert = (
    title: string,
    message: string | ReactElement,
    error: boolean = true,
    action: () => void = null,
    actionTitle: string = '',
  ) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertError(error);
    setAlertAction(() => action);
    setAlertActionTitle(actionTitle);
    setLoading(false);
    setAlertVisible(true);
  };

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'AssetManagement',
      name: title,
    });
  };

  const deleteTemplateGroup = () => {
    props.contextMenuClose();
    setLoading(true);
    const error = (errorText: string) => showAlert(
      'Error',
      errorText ?? 'Could not delete template group. Please try again later.',
    );

    updateTemplateGroup(null, props.group.ButtonPrototypeGroupId, true).then((res) => {
      if (res && !res.isError) {
        props.update(null, props.group.ButtonPrototypeGroupId, false, true);
        showAlert(
          'Template group successfully deleted',
          <>
            {'Template group '}
            <Text style={styles.boldText}>{props.group.Name}</Text>
            {' successfully deleted'}
          </>,
          false,
        );
      } else error(res?.errorText);
    })
      .catch(error);
  };

  return <>
    <TouchableOpacity
      onPress={props.onPress}
      style={[
        styles.container,
        props.selectedGroupID === props.group.ButtonPrototypeGroupId && styles.containerSelected,
      ]}
    >
      <Text style={[
        styles.sectionTitle,
        props.selectedGroupID === props.group.ButtonPrototypeGroupId
        && styles.sectionTitleSelected,
      ]}>
        {props.templateGroups?.filter((group) => group.ButtonPrototypeGroupId
          === props.group?.ButtonPrototypeGroupId)[0]?.Name}
      </Text>
      <TouchableOpacity
        hitSlop={{ left: 40 }}
        style={styles.ellipsis}
        onPress={() => {
          analytics('Ellipsis');
          if (props.currentContextMenu === props.group.ButtonPrototypeGroupId) {
            props.contextMenuClose();
          } else props.contextMenuOpen();
        }}
      >
        <EllipsisIcon fill={'#D9D9D9'} style={{ marginRight: 18 }} />
      </TouchableOpacity>
      {props.currentContextMenu === props.group.ButtonPrototypeGroupId && (
        <View style={styles.menu}>
          <TouchableOpacity
            style={styles.contextItem}
            onPress={() => {
              analytics('Edit Group');
              props.contextMenuClose();
              setEditGroupModalVisible(!editGroupModalVisible);
            }}
          >
            <Text style={styles.contextItemText}>Edit</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.contextItem}
            onPress={() => {
              analytics('Delete Group');
              showAlert(
                'Delete template group',
                <>
                  {'Are you sure you want to delete the template group '}
                  <Text style={styles.boldText}>{props.group.Name}</Text>
                </>,
                true,
                deleteTemplateGroup,
                'Delete',
              );
            }}
          >
            <Text style={styles.contextItemText}>Delete</Text>
          </TouchableOpacity>
        </View>
      )}
    </TouchableOpacity>
    <AddTemplateGroupModal
      isVisible={editGroupModalVisible}
      hide={() => setEditGroupModalVisible(false)}
      id={props.group.ButtonPrototypeGroupId}
      currentName={props.group.Name}
      update={props.update}
    />
    <AlertModal
      modalVisible={alertVisible}
      title={alertTitle}
      message={alertMessage}
      error={alertError}
      action={alertAction}
      actionTitle={alertActionTitle}
      {...{ loading }}
      setModalVisible={setAlertVisible}
    />
  </>;
}

const styles = StyleSheet.create({
  container: {
    zIndex: -1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerSelected: {
    backgroundColor: Colors.light.hereworksDarkBlue,
  },
  sectionTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    marginLeft: 24,
    marginRight: 50,
    marginVertical: 16,
    color: Colors.light.hereworksBlack500,
  },
  sectionTitleSelected: {
    color: '#FFF',
  },
  contextItem: {
    marginVertical: 9,
    backgroundColor: '#fff',
  },
  contextItemText: {
    marginLeft: 12,
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    lineHeight: 21,
  },
  boldText: {
    fontFamily: 'Roboto_700Bold',
  },
  ellipsis: {
    position: 'absolute',
    right: 0,
    justifyContent: 'center',
    height: '100%',
  },
  menu: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    right: 50,
    width: 87,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
    borderRadius: 8,
    backgroundColor: '#fff',
  },
});
