import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const FilterIcon16 = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="M3.917 2.75v8.167V2.75Zm0 8.167a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333ZM8 5.083v8.167-8.167ZM8 2.75a1.167 1.167 0 1 0 0 2.333A1.167 1.167 0 0 0 8 2.75Zm4.083 6.417v4.083-4.083Zm0-2.334V2.75v4.083Zm0 0a1.166 1.166 0 1 0 0 2.333 1.166 1.166 0 0 0 0-2.333Z"
      fill="#0058AD"
    />
    <Path
      d="M3.917 2.75v8.167M8 5.083v8.167m4.083-4.083v4.083m0-6.417V2.75m-8.166 8.167a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333ZM8 2.75a1.167 1.167 0 1 0 0 2.333A1.167 1.167 0 0 0 8 2.75Zm4.083 4.083a1.166 1.166 0 1 0 0 2.333 1.166 1.166 0 0 0 0-2.333Z"
      stroke="#0058AD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default FilterIcon16;
