import * as React from 'react';
import Svg, { SvgProps, Path, Rect } from 'react-native-svg';
import Colors from '../../constants/Colors';

interface IconProps extends SvgProps {
  isSelected?: boolean,
}

const InProgressIcon = (props: IconProps) => (
  <Svg width='43' height='43' viewBox='0 0 43 43' fill='none' {...props}>
    <Rect x='3.5' y='3.5' width='39' height='39' rx='19.5' fill={props.isSelected ? Colors.light.hereworksBlue50 : Colors.light.hereworksBlue500} />
    <Path d='M24.4128 23.5037C26.012 24.5667 28.6196 26.8735 28.6196 29.5272V30.3846C28.6196 31.9046 27.6477 32.0002 27.2628 32.0002L18.9918 32.0005C18.6368 32.0005 17.333 31.9333 17.333 30.3848V29.5272C17.333 26.8697 19.9872 24.5667 21.5828 23.5037C19.9871 22.4407 17.3862 20.1339 17.3862 17.4802V16.6157C17.3862 15.0957 18.3582 15 18.743 15H27.0075C27.3625 15 28.6663 15.0672 28.6663 16.6157V17.4802C28.6663 20.1377 26.0121 22.4407 24.4165 23.5037L24.4128 23.5037ZM23.9417 24.8465C23.9417 24.8465 23.3877 24.4674 22.9797 24.2017C22.5749 24.4674 22.0177 24.8465 22.0177 24.8465C20.7636 25.7608 18.6669 27.6349 18.6669 29.5344V30.3918C18.6623 30.4578 18.6621 30.5243 18.6667 30.5903H27.2795C27.2842 30.5243 27.2842 30.4578 27.2795 30.3918V29.5272C27.2927 27.6353 25.1957 25.7608 23.9417 24.8465ZM27.3392 17.4803V16.6158C27.3438 16.5498 27.3438 16.4833 27.3392 16.4173H18.7133C18.7087 16.4833 18.7087 16.5498 18.7133 16.6158V17.4803C18.7133 19.3795 20.81 21.254 22.0641 22.1682C22.0641 22.1682 22.6181 22.5473 23.0261 22.813C23.4308 22.5473 23.9881 22.1682 23.9881 22.1682C25.2424 21.2468 27.3392 19.3723 27.3392 17.4803Z' fill={props.isSelected ? Colors.light.hereworksBlue500 : '#FFF'} />
    <Rect x='3.5' y='3.5' width='39' height='39' rx='19.5' stroke={props.isSelected ? '#B0CBE6' : Colors.light.hereworksBlue500} />
  </Svg>
);

export default InProgressIcon;
