import AsyncStorage from '@react-native-async-storage/async-storage';

const editAssetNotes = async (
  assetId,
  notes,
) => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('jwt', jwtToken ?? '');

    const response = await fetch(
      `https://${apiDomain}/button/buttoninstances/notes`,
      {
        method: 'PUT',
        headers: requestHeaders,
        body: JSON.stringify({
          InstanceId: assetId,
          Note: notes,
        }),
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default editAssetNotes;
