import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import { Auth, Hub } from 'aws-amplify';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import getPoolId from '../requests/GetPoolId';
import config from '../aws-exports';
import OfficeIcon from '../components/OfficeIcon';
import LoginImage from '../components/LoginImage';

export default function SignInScreen() {
  const [text, setText] = useState(undefined);
  const [isErr, updateIsErr] = useState(false);
  const [unauth, setUnauth] = useState(false);

  const showOrganisation = true;

  useEffect(() => {
    AsyncStorage.getItem('clientName').then((name) => {
      if (!text) {
        setText(name);
      }
    });
  }, []);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
        case 'signOut':
          setUnauth(false);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          setUnauth(true);
          break;
        default:
          break;
      }
    });
  }, []);

  const onSubmit = async () => {
    const poolID = await getPoolId(text.toLocaleLowerCase());
    updateIsErr(!poolID || poolID?.isErr);
    if (poolID && !poolID.isErr) {
      AsyncStorage.setItem('clientId', poolID?.insightsClientId ?? '');
      AsyncStorage.setItem('poolId', poolID?.poolId ?? '');
      AsyncStorage.setItem('domain', poolID?.cognitoDomain ?? '');
      AsyncStorage.setItem('environment', poolID?.productionStatus ?? '');
      AsyncStorage.setItem('apiDomain', poolID?.apiDomain ?? 'api.hereworks.tech');
      AsyncStorage.setItem('clientName', text.toLocaleLowerCase());
      AsyncStorage.setItem('clientLogo', poolID?.imageUrl ?? '');
      Analytics.setUserProperty('clientName', text.toLocaleLowerCase());
      const updatedConfig = {
        ...config,
        aws_user_pools_id: poolID?.poolId,
        aws_user_pools_web_client_id: poolID?.appClientId,
      };

      updatedConfig.oauth.domain = poolID?.cognitoDomain;

      // console.log(JSON.stringify(updatedConfig));
      Auth.configure(updatedConfig);
      return poolID;
    }
    return null;
  };

  const renderMainView = () => (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'position' : 'padding'}>
      <View style={styles.ImageandLoginText}>
        <LoginImage />
        <Text style={styles.title}>Welcome to Hereworks Insights</Text>
        <View
          style={{
            width: 47,
            height: 2,
            backgroundColor: Colors.light.hereworksPrimaryGrey,
            marginTop: 10,
            marginBottom: 7,
          }}
        />
        <Text style={styles.description}>The Human Centric Approach to Smart Workplaces</Text>
        {showOrganisation && (
          <>
            <TextInput
              placeholder="Company Name"
              placeholderTextColor={Colors.light.hereworksBlack600}
              style={[styles.organisation, isErr && { borderColor: 'red' }]}
              autoCorrect={false}
              autoCapitalize="none"
              value={text?.toLocaleLowerCase()}
              onSubmitEditing={onSubmit}
              onChangeText={(currentText: string) => {
                updateIsErr(false);
                setText(currentText.toLocaleLowerCase());
              }}
            />
            {isErr && (
              <Text style={styles.errorText}>
                Organisation {text.toLocaleLowerCase()} not found!
              </Text>
            )}
            {unauth && (
              <Text style={styles.errorText}>
                Not authorized, please contact admin.
              </Text>
            )}
          </>
        )}
      </View>
      <View style={[{ alignItems: 'center' }, showOrganisation ? {} : { marginTop: 56 }]}>
        <TouchableOpacity
          onPress={() => {
            // console.log('Login pressed');
            onSubmit().then((poolID) => {
              if (poolID && !poolID?.isErr) {
                Auth.federatedSignIn({ customProvider: 'MckeonAzureProvider' });
              }
            });
          }}
          style={styles.button}
        >
          <Text style={styles.buttonText}>Login with Office 365</Text>
          <OfficeIcon />
        </TouchableOpacity>
      </View>
      <View style={{ alignItems: 'center', marginTop: 10 }}>
        <TouchableOpacity
          onPress={() => {
            // console.log('Login pressed');
            onSubmit().then((poolID) => {
              if (poolID && !poolID?.isErr) {
                Auth.federatedSignIn();
              }
            });
          }}
          style={styles.secondaryButton}
        >
          <Text style={styles.secondaryButtonText}>Login with Credentials</Text>
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  );

  if (Platform.OS !== 'web') {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
          padding: 22,
        }}
      >
        {renderMainView()}
      </View>
    );
  }

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        padding: 10,
      }}
    >
      <View
        style={[
          {
            justifyContent: 'center',
            alignSelf: 'center',
            borderRadius: 24,
            padding: 40,
            backgroundColor: 'white',
          },
          Style.boxShadow,
        ]}
      >
        {renderMainView()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'black',
  },
  ImageandLoginText: {
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Poppins_600SemiBold',
    textAlign: 'center',
    marginHorizontal: 40,
  },
  description: {
    fontFamily: 'Poppins_400Regular',
    alignItems: 'center',
    fontSize: 18,
    color: Colors.light.hereworksPrimaryGrey800,
    textAlign: 'center',
  },
  errorText: {
    fontFamily: 'Poppins_400Regular',
    alignItems: 'center',
    fontSize: 14,
    color: 'red',
    textAlign: 'center',
    marginTop: 15,
  },
  currentOrg: {
    fontFamily: 'Poppins_400Regular',
    alignItems: 'center',
    fontSize: 14,
    color: Colors.light.hereworksLightBlue,
    textAlign: 'center',
    marginTop: 15,
  },
  hereworksLogo: {
    resizeMode: 'contain',
    width: 200,
    height: 100,
    alignSelf: 'center',
  },
  button: {
    backgroundColor: Colors.light.hereworksLightBlue,
    width: '100%',
    padding: 10,
    marginTop: 20,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  secondaryButton: {
    backgroundColor: '#fff',
    borderWidth: 2,
    borderColor: Colors.light.hereworksLightBlue,
    width: '100%',
    paddingVertical: 7,
    marginTop: 16,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    paddingVertical: 7,
    marginHorizontal: 8,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
  },
  secondaryButtonText: {
    color: Colors.light.hereworksLightBlue,
    textAlign: 'center',
    paddingVertical: 7,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
  },
  organisation: {
    textAlign: 'center',
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    borderRadius: 10,
    fontFamily: 'Poppins_400Regular',
    width: '100%',
  },
});
