import * as React from 'react';
import Svg, { SvgProps, Path, Circle } from 'react-native-svg';

const HygieneIcon60 = (props: SvgProps) => (
  <Svg width={61} height={60} fill="none" {...props}>
    <Path
      d="M23.285 55h14.25a3 3 0 0 0 0-6h-9M28.535 49h16.5a3 3 0 0 0 0-6h-16.5M28.535 43h22.5a3 3 0 0 0 0-6h-22.5"
      stroke={props.color ?? '#fff'}
      strokeWidth={3}
    />
    <Path
      d="M23.285 55H11.496a6 6 0 0 1-4.992-2.672l-.211-.317a6 6 0 0 1-1.008-3.328V36.167a11 11 0 0 1 2.2-6.6l1.17-1.56a11 11 0 0 1 2.698-2.552l11.375-7.583a.67.67 0 0 1 .929.185 6.698 6.698 0 0 1-.215 7.734L20.135 30.2a.5.5 0 0 0 .4.8h25.25a3 3 0 0 1 0 6h-16.5"
      stroke={props.color ?? '#fff'}
      strokeWidth={3}
    />
    <Circle cx={37.785} cy={17.5} r={6} stroke={props.color ?? '#fff'} strokeWidth={3} />
    <Circle cx={51.285} cy={8} r={1.5} stroke={props.color ?? '#fff'} strokeWidth={3} />
    <Circle cx={12.285} cy={12} r={1.5} stroke={props.color ?? '#fff'} strokeWidth={3} />
  </Svg>
);

export default HygieneIcon60;
