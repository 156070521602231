import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Animated,
  Dimensions,
  Linking,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  FlatList,
  RefreshControl,
} from 'react-native';
import * as Analytics from 'expo-firebase-analytics';
import { ScrollView } from 'react-native-gesture-handler';
import * as Animatable from 'react-native-animatable';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';
import SearchIcon from '../components/SearchIcon';
import Colors from '../constants/Colors';
import getButtonInstances from '../requests/GetButtonInstances';
import getTemplateGroups from '../requests/GetTemplateGroups';
import { RootTabScreenProps } from '../types';
import AssetRow from '../components/AssetManagement/AssetRow';
import EllipsisIcon from '../components/EllipsisIcon';
import DownloadIcon from '../components/DownloadIcon';
import getTemplateSpreadsheet from '../requests/GetTemplateSpreadsheet';
import OrionArrowIcon from '../components/24pxIcons/OrionArrowIcon';
import CheckBox from '../components/16pxIcons/CheckBox';
import PlusIcon from '../components/16pxIcons/PlusIcon';
import TemplateGroupRowWeb from '../components/AssetManagement/TemplateGroupRowWeb';
import AddTemplateGroupModal from '../components/AssetManagement/AddTemplateGroupModal';
import TemplateSideBarRow from '../components/AssetManagement/TemplateSideBarRow';
import FilterIcon16 from '../components/16pxIcons/FilterIcon';
import FilterSearchBox from '../components/FilterSearchBox';
import AddAssetModal from '../components/AssetManagement/AddAssetModal';
import assetSearch from '../requests/AssetSearch';
import AlertModal from '../components/AlertModal';
import SearchResultsPane from '../components/AssetManagement/SearchResultsPane';
import BackIconSmall from '../components/12pxIcons/BackIconSmall';
import AddTemplateModal, { Template, TemplateGroup } from '../components/AssetManagement/AddTemplateModal';
import { UserGroup } from '../components/AssetManagement/AddTemplateModal/IssueRow';
import getUserGroups from '../requests/GetUserGroups';

export default function TemplateMgmtScreen({
  // eslint-disable-next-line no-unused-vars
  navigation, route,
}: RootTabScreenProps<'AssetManagement'>) {
  const [loading, setLoading] = useState(false);
  const [instancesLoading, setInstancesLoading] = useState(false);
  const [prototypesLoading, setPrototypesLoading] = useState(false);

  const [addTemplateModalVisible, setAddTemplateModalVisible] = useState(false);
  const [addAssetModalVisible, setAddAssetModalVisible] = useState(false);
  const [linkAssetsView, setLinkAssetsView] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [templateGroups, setTemplateGroups] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [rooms, setRooms] = useState(null);
  const [allInstances, setAllInstances] = useState([]);
  const [downloadLink, setDownloadLink] = useState(null);
  const [exportClicked, setExportClicked] = useState(null);

  const [selectedGroupID, setSelectedGroup] = useState<string>(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [assetFilterVisible, setAssetFilterVisible] = useState(false);
  const [roomSelected, setRoomSelected] = useState(false);
  const [assetCount, setAssetCount] = useState(0);
  const [allSelected, setAllSelected] = useState(null);
  const [sortById, setSortById] = useState(true);
  const [sortByFloor, setSortByFloor] = useState(null);
  const [sortByRoom, setSortByRoom] = useState(null);
  const [detailedFilterVisible, setDetailedFilterVisible] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterSerial, setFilterSerial] = useState('');
  const [filterModel, setFilterModel] = useState('');
  const [filterDescription, setFilterDescription] = useState('');
  const [filterManufacturer, setFilterManufacturer] = useState('');
  const [assetFilterLoading, setAssetFilterLoading] = useState(false);
  const [assetFilterResults, setAssetFilterResults] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [addGroupModalVisible, setAddGroupModalVisible] = useState(false);
  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });
  const [filterDrawerInset] = useState(new Animated.Value(300));
  const [name, updateName] = useState('Anonymous');
  const [templateSideBarContext, setTemplateSideBarContext] = useState(null);
  const [searchResultsPaneFocused, setSearchResultsPaneFocused] = useState(false);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);

  const assetSearchField = useRef<TextInput>(null);
  const templateSearchField = useRef<TextInput>(null);

  const showCheckbox = false;

  const renderMobile = (): boolean => Platform.OS !== 'web' || dimensions.window?.width < 500 || dimensions.window?.height < 500;

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [noDataAlertShown, setNoDataAlertShown] = useState(false);

  const showAlert = (title: string, message?: string, error: boolean = true) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertError(error);
    setAlertVisible(true);
  };

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'AssetManagement',
      name: title,
    });
  };

  // eslint-disable-next-line func-names
  const groupBy = function <TItem> (xs: TItem[], key: string): { [key: string]: TItem[] } {
    return xs?.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AsyncStorage.getItem('name').then((n) => {
      updateName(n);
    }) ?? 'Anonymous';
    navigation.addListener('blur', () => setDetailedFilterVisible(false));
  }, []);

  React.useEffect(() => {
    if (exportClicked) {
      getTemplateSpreadsheet().then((response) => {
        if (response) {
          setDownloadLink(response.spreadsheetUrl);
          Linking.openURL(response.spreadsheetUrl);
        }
      });
    }
  }, [exportClicked]);

  const noTemplatesFound = () => {
    if (!noDataAlertShown) {
      showAlert('Error', 'Could not load asset templates at the moment. Please try again later.');
      setNoDataAlertShown(true);
      setPrototypesLoading(false);
    }
  };

  const noAssetsFound = () => {
    if (!noDataAlertShown) {
      showAlert('Error', 'Could not load assets at the moment. Please try again later');
      setNoDataAlertShown(true);
      setInstancesLoading(false);
    }
  };

  React.useEffect(() => {
    getTemplateGroups().then((response) => {
      if (!response?.groups) noTemplatesFound();
      else {
        setTemplateGroups(response?.groups ?? []);
        setTemplates(response?.groups
          ?.reduce((prev, group) => {
            if (group?.buttonPrototypes?.length) return prev.concat(group.buttonPrototypes);
            return prev;
          }, []) ?? []);
        setPrototypesLoading(false);
      }
    }).catch(noTemplatesFound);
    getUserGroups().then((response: { UserGroups?: UserGroup[] }) => {
      if (response?.UserGroups?.length) {
        setUserGroups(response?.UserGroups
          ?.sort((a: UserGroup, b: UserGroup) => a.Name.localeCompare(b.Name)));
      }
    });
  }, []);

  React.useEffect(() => {
    loadAssets();
  }, []);

  React.useEffect(() => {
    let count = 0;
    allInstances.forEach((instance) => {
      if (roomSelected) {
        if (instance.LocationRoom === selectedRoom?.LocationRoom) {
          count += 1;
        }
      } else if (instance.ButtonPrototypeId === selectedId) {
        count += 1;
      }
    });
    setAssetCount(count);
  }, [selectedRoom, selectedId, instancesLoading, allInstances]);

  React.useEffect(() => {
    if (detailedFilterVisible) {
      Animated.timing(filterDrawerInset, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(filterDrawerInset, {
        toValue: 300,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  }, [detailedFilterVisible]);

  const resetAssetFilters = () => {
    setFilterName('');
    setFilterModel('');
    setFilterManufacturer('');
    setFilterDescription('');
    setFilterSerial('');
  };

  const clearAssetFilter = () => {
    setAssetFilterResults([]);
    setDetailedFilterVisible(false);
    resetAssetFilters();
  };

  const clearTabSelection = () => {
    setSelectedTitle('');
    setSelectedTemplate(null);
    setSelectedAsset(null);
    setSelectedGroup(null);
    setTemplateSideBarContext(null);
    setSelectedId(null);
    resetAssetFilters();
    clearAssetFilter();
    setAllSelected(false);
  };

  const loadAssets = () => getButtonInstances().then((response) => {
    if (response) {
      const groupRooms = groupBy(response.instances, 'LocationRoom');
      setRooms(groupRooms);
      if (response?.instances?.length > 0) {
        setAllInstances(response.instances);
      }
      setInstancesLoading(false);
    } else noAssetsFound();
  }).catch(noAssetsFound);

  const refresh = () => {
    setInstancesLoading(true);
    clearTabSelection();
    setAllInstances([]);
    loadAssets();
  };

  const deleteAsset = (assetId: string) => {
    let instances = [...allInstances];
    instances = instances.filter((asset) => asset.ButtonInstanceId !== assetId);
    setAllInstances(instances);
  };

  const updateAsset = (updatedAsset) => {
    let instances = [...allInstances];
    instances = instances.map((asset) => {
      if (asset.ButtonInstanceId === updatedAsset.ButtonInstanceId) {
        return updatedAsset;
      }
      return asset;
    });
    setAllInstances(instances);
  };

  useEffect(() => {
    // @ts-ignore
    const assetId: string = route?.params?.assetId;
    if (assetId && allInstances?.length) {
      setLinkAssetsView(true);
      const filteredAsset = allInstances
        ?.filter((asset: { ButtonInstanceId: string }) => asset.ButtonInstanceId === assetId)[0];
      if (filteredAsset) setSelectedAsset(filteredAsset);
      else showAlert('Error', 'Linked asset could not be found. It may have been deleted.');
    }
    // @ts-ignore
  }, [allInstances, route?.params?.assetId]);

  const updateTemplateLocally = (template: Template, add: boolean, groupID: string) => {
    const updatedTemplateGroups: TemplateGroup[] = [...templateGroups];
    const targetTemplateGroup: TemplateGroup = updatedTemplateGroups
      ?.filter((group: TemplateGroup) => group.ButtonPrototypeGroupId === groupID)[0];
    if (add) targetTemplateGroup.buttonPrototypes.push(template);
    else {
      targetTemplateGroup.buttonPrototypes = targetTemplateGroup.buttonPrototypes
        ?.map((t) => (t.id === template.id ? template : t));
    }
    setTemplateGroups(updatedTemplateGroups);
    setTemplates(updatedTemplateGroups
      ?.reduce((prev, group) => {
        if (group?.buttonPrototypes?.length) return prev.concat(group.buttonPrototypes);
        return prev;
      }, []) ?? []);
  };

  const applyAssetFilters = () => {
    setAssetFilterLoading(true);
    assetSearch(filterName, filterManufacturer, filterDescription, filterSerial, filterModel)
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            if (json.result?.length > 0) {
              setAssetFilterResults(
                json.result?.map(
                  (filteredInstance) => allInstances?.filter(
                    (instance) => instance.ButtonInstanceId === filteredInstance.ButtonInstanceId,
                  )[0],
                ),
              );
              setDetailedFilterVisible(false);
            } else {
              showAlert('Error', 'No assets found with the provided search values');
            }
          });
        } else {
          showAlert('Error', 'No assets found with the provided search values');
        }
        setAssetFilterLoading(false);
      })
      .catch(() => {
        showAlert('Error', 'Search could not be completed. Please try again');
        setAssetFilterLoading(false);
      });
  };

  if (prototypesLoading || instancesLoading) {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size={'large'} color={Colors.light.hereworksLoading} />
        </View>
      </View>
    );
  }

  const filterAssets = () => {
    let assetsToRender = allInstances;
    if (sortByFloor === true) {
      // eslint-disable-next-line max-len
      assetsToRender = allInstances?.sort((a, b) => a.LocationFloor?.localeCompare(b?.LocationFloor));
    } else if (sortByFloor === false) {
      // eslint-disable-next-line max-len
      assetsToRender = allInstances?.sort((a, b) => b.LocationFloor?.localeCompare(a?.LocationFloor));
    } else if (sortByRoom === true) {
      assetsToRender = allInstances?.sort((a, b) => a.LocationRoom?.localeCompare(b?.LocationRoom));
    } else if (sortByRoom === false) {
      assetsToRender = allInstances?.sort((a, b) => b.LocationRoom?.localeCompare(a?.LocationRoom));
    } else if (sortById === true) {
      // eslint-disable-next-line max-len
      assetsToRender = allInstances?.sort((a, b) => a.Name?.localeCompare(b.Name));
    } else if (sortById === false) {
      // eslint-disable-next-line max-len
      assetsToRender = allInstances?.sort((a, b) => b.Name?.localeCompare(a.Name));
    }
    if (selectedTemplate) {
      assetsToRender = assetsToRender?.filter((asset: { ButtonPrototypeId: string }) => asset
        ?.ButtonPrototypeId === selectedTemplate.id);
    } else if (selectedRoom) {
      assetsToRender = assetsToRender?.filter((asset: { LocationId: string }) => asset
        ?.LocationId === selectedRoom?.LocationId);
    }
    return assetsToRender;
  };

  const renderTemplate = (template: any) => {
    const selected: boolean = selectedTemplate?.id === template.id && !assetFilterResults?.length;
    return <TouchableOpacity
      onPress={() => {
        setSelectedTemplate(selectedTemplate?.id === template.id ? null : template);
        setSelectedAsset(null);
        setAllSelected(false);
        setSelectedId(selectedTemplate?.id === template.id ? null : template.id);
        setSelectedTitle(selectedTemplate?.id === template.id ? null : template.name);
        clearAssetFilter();
      }}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor:
          selected ? Colors.light.hereworksDarkBlue : 'transparent',
      }}
    >
      <Text
        style={[
          styles.sectionTitle,
          {
            color: selected ? '#fff' : Colors.light.hereworksBlack500,
          },
        ]}
      >
        {template.name}
      </Text>
      {!linkAssetsView && (
        <EllipsisIcon fill={'#D9D9D9'} style={{ position: 'absolute', right: 18 }} />
      )}
    </TouchableOpacity>;
  };

  const renderRoom = (room: any) => (
    <TouchableOpacity
      onPress={() => {
        setSelectedRoom(room[0]);
        setAllSelected(false);
        setSelectedTitle(room[0]?.LocationRoom);
      }}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor:
          selectedRoom?.LocationId === room[0]?.LocationId
            ? Colors.light.hereworksDarkBlue
            : 'transparent',
      }}
    >
      <Text
        style={[
          styles.sectionTitle,
          {
            color:
              selectedRoom?.LocationId === room[0]?.LocationId
                ? '#fff'
                : Colors.light.hereworksBlack500,
          },
        ]}
      >
        {room[0]?.LocationRoom}
      </Text>
      {!linkAssetsView && (
        <EllipsisIcon fill={'#D9D9D9'} style={{ position: 'absolute', right: 18 }} />
      )}
    </TouchableOpacity>
  );

  const renderTemplatesFromList = (templatesList) => templatesList
    ?.sort((a, b) => a.name.localeCompare(b.name))
    ?.map((prototype, key) => {
      if (prototype.deleted === true) {
        return null;
      }
      return (
        <View key={key}>
          {
            <TemplateGroupRowWeb
              {... {
                prototype, templateGroups, userGroups,
              }}
              update={updateTemplateLocally}
              setLinkAssetsView={() => {
                setLinkAssetsView(!linkAssetsView);
                clearAssetFilter();
                setSelectedTemplate(prototype);
                setRoomSelected(false);
                setSelectedAsset(null);
                setAllSelected(false);
              }}
              setSelectedTitle={() => setSelectedTitle(prototype.name)}
              setSelectedId={() => setSelectedId(prototype.id)}
              deleteAction={(id: string) => {
                const updatedTemplateGroups = [...templateGroups];
                updatedTemplateGroups.forEach((group) => {
                  const updatedTemplates = group.buttonPrototypes ?? [];
                  updatedTemplates.splice(updatedTemplates
                    .findIndex((currentTemplate) => currentTemplate.id === id), 1);
                });
                setTemplateGroups(updatedTemplateGroups);
                setTemplates(updatedTemplateGroups
                  ?.reduce((prev, group) => {
                    if (group?.buttonPrototypes?.length) return prev.concat(group.buttonPrototypes);
                    return prev;
                  }, []) ?? []);
              }}
            />
          }
        </View>
      );
    });

  const renderTemplates = () => {
    if (selectedGroupID === null) return renderTemplatesFromList(templates);
    return renderTemplatesFromList(templateGroups?.filter((group) => group.ButtonPrototypeGroupId
      === selectedGroupID)[0]?.buttonPrototypes ?? templates);
  };

  const renderSearchPane = () => (searchValue?.length ? <SearchResultsPane
    focused={searchResultsPaneFocused}
    blur={() => {
      (linkAssetsView ? assetSearchField : templateSearchField)?.current?.focus();
    }}
    searchValue={searchValue}
    assets={allInstances}
    templates={templates}
    templateGroups={templateGroups}
    selectTemplate={setSelectedTemplate}
    selectAsset={setSelectedAsset}
    selectTemplateGroup={setSelectedGroup}
    showAssets={setLinkAssetsView}
    setSearchValue={setSearchValue}
    selectedAsset={selectedAsset}
    selectedTemplate={selectedTemplate}
    selectedTemplateGroup={selectedGroupID}
  /> : null);

  const renderManageGroupsView = () => (
    <Animatable.View
      animation={'fadeIn'}
      style={{
        borderRadius: 24,
        marginVertical: 20,
        overflow: 'hidden',
        flexDirection: 'row',
        alignSelf: 'stretch',
        backgroundColor: '#fff',
        flex: 1,
      }}
    >
      {!renderMobile() && (
        <View
          style={{
            minWidth: 240,
            flex: 2,
            backgroundColor: Colors.light.hereworksBlue50,
          }}
        >
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Text
              style={{
                fontFamily: 'Roboto_500Medium',
                fontSize: 12,
                lineHeight: 20,
                color: Colors.light.hereworksBlack500,
                marginTop: 24,
                marginLeft: 24,
                marginBottom: 17,
              }}
            >
              TEMPLATE GROUPS
            </Text>
            <TouchableOpacity
              style={{
                backgroundColor: '#fff',
                borderWidth: 1,
                borderRadius: 4,
                borderColor: Colors.light.hereworksBlue500,
                padding: 4,
                marginRight: 18,
              }}
              onPress={() => {
                analytics('Add Template Group');
                setAddGroupModalVisible(!addGroupModalVisible);
              }}
            >
              <PlusIcon color={Colors.light.hereworksBlue500} />
            </TouchableOpacity>
            <AddTemplateGroupModal
              isVisible={addGroupModalVisible}
              hide={() => setAddGroupModalVisible(false)}
              update={(Name: string, ButtonPrototypeGroupId: string, create: boolean) => {
                const updatedTemplateGroups = [...templateGroups];
                if (create) {
                  updatedTemplateGroups
                    .push({ Name, ButtonPrototypeGroupId, buttonPrototypes: [] });
                } else {
                  updatedTemplateGroups?.forEach((group) => {
                    if (group.ButtonPrototypeGroupId === ButtonPrototypeGroupId) {
                      group.Name = Name;
                    }
                  });
                }
                setTemplateGroups(updatedTemplateGroups);
              }}
            />
            <AddTemplateModal
              {...{ templates, templateGroups, userGroups }}
              defaultTemplateGroupID={selectedGroupID}
              isVisible={addTemplateModalVisible}
              hide={() => setAddTemplateModalVisible(false)}
              update={updateTemplateLocally}
            />
          </View>
          <View
            style={{ backgroundColor: Colors.light.hereworksBlue100, height: 1, width: '100%' }}
          />
          <ScrollView>
            {templateGroups
              ?.sort((a, b) => a.Name.localeCompare(b.Name))
              ?.map((group: any, key: number) => (
                <View key={key} style={{ zIndex: key * -1 }}>
                  <TemplateSideBarRow
                    {...{ group, selectedGroupID, templateGroups }}
                    onPress={() => {
                      if (selectedGroupID === group.ButtonPrototypeGroupId) {
                        setSelectedGroup(null);
                      } else {
                        setSelectedGroup(group.ButtonPrototypeGroupId);
                      }
                    }}
                    deleteAction={() => setTemplateGroups(
                      templateGroups?.filter((i) => i.ButtonPrototypeGroupId
                        !== group.ButtonPrototypeGroupId),
                    )
                    }
                    contextMenuOpen={() => setTemplateSideBarContext(group.ButtonPrototypeGroupId)}
                    contextMenuClose={() => setTemplateSideBarContext(null)}
                    currentContextMenu={templateSideBarContext}
                    update={(newName: string, id: string, create?: boolean, toDelete?: boolean) => {
                      const updatedGroups = [...templateGroups];
                      if (create) {
                        updatedGroups?.push({ Name: newName, ButtonPrototypeGroupId: id });
                      } else if (toDelete) {
                        updatedGroups
                          .splice(updatedGroups.findIndex((templateGroup) => templateGroup
                            .ButtonPrototypeGroupId === id), 1);
                      } else {
                        updatedGroups?.forEach((templateGroup) => {
                          if (templateGroup.ButtonPrototypeGroupId === id) {
                            templateGroup.Name = newName;
                          }
                        });
                      }
                      setTemplateGroups(updatedGroups);
                    }}
                  />
                </View>
              ))}
          </ScrollView>
        </View>
      )}
      <View style={{ flex: 7 }}>
        <View style={styles.contentHeader}>
          <View style={styles.searchBox}>
            <SearchIcon style={{ marginLeft: 14 }} />
            <TextInput
              placeholder="Search"
              value={searchValue}
              style={styles.searchText}
              ref={(ref) => {
                templateSearchField.current = ref;
              }}
              onFocus={() => setSearchResultsPaneFocused(false)}
              onChangeText={(text) => {
                setSearchValue(text);
                setSearchResultsPaneFocused(false);
              }}
              onKeyPress={(e) => {
                // @ts-ignore
                if (e.code === 'ArrowDown') setSearchResultsPaneFocused(true);
              }}
            />
          </View>
          <TouchableOpacity
            style={styles.createButton}
            onPress={() => {
              analytics('Create new template');
              // showAlert('Feature coming soon!', null, false);
              setAddTemplateModalVisible(true);
            }}
          >
            <Text style={styles.createButtonTitle}>Add Template</Text>
            <PlusIcon />
          </TouchableOpacity>
        </View>
        {renderSearchPane()}
        <ScrollView
          style={{
            flex: 1,
          }}
        >
          <View style={{}}>
            <Text
              style={{
                fontFamily: 'Poppins_400Regular',
                fontSize: 22,
                color: Colors.light.hereworksBlack600,
                marginVertical: 24,
                marginLeft: 24,
              }}
            >
              {templateGroups[selectedGroupID]}
            </Text>
            <View style={{ marginHorizontal: 24 }}>
              <View style={{ flexDirection: 'row', alignSelf: 'stretch' }}>
                <View style={{ flex: 1, alignItems: 'flex-start' }}>
                  <Text style={styles.headerText}>TEMPLATES</Text>
                </View>
                {/* <View style={{ flex: 1, alignItems: 'center' }}>
                <Text style={styles.headerText}>NOTIFICATION TAGS</Text>
              </View> */}
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                  <Text style={styles.headerText}>ACTIONS</Text>
                </View>
              </View>
              <View style={[styles.divider, { marginTop: 8, marginBottom: 16 }]} />
            </View>
            {renderTemplates()}
          </View>
        </ScrollView>
      </View>
    </Animatable.View>
  );

  const renderItem = ({ item, index }, cardOpen: boolean = false) => (
    <View key={`${item.ButtonInstanceId}`}>
      <AssetRow
        updateAsset={updateAsset}
        deleteAsset={deleteAsset}
        setLoading={setLoading}
        instance={item}
        buttonKey={index}
        selected={allSelected ?? null}
        selectionChanged={() => {
          if (allSelected) {
            setAllSelected(false);
            setAllSelected(null);
          } else {
            setAllSelected(null);
          }
        }}
        cardOpen={cardOpen}
        name={name}
        templates={templates}
      />
    </View>
  );

  const addAsset = (asset) => {
    const instances = [...allInstances];
    instances.push(asset);
    setAllInstances(instances);
  };

  return (
    <View
      style={{
        paddingHorizontal: 40,
        backgroundColor: Colors.light.hereworksBackground,
        flex: 1,
        height: Dimensions.get('screen').height - 120,
      }}
    >
      <View style={{ marginTop: 30, flexDirection: 'row' }}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <TouchableOpacity
            onPress={() => {
              clearTabSelection();
              setLinkAssetsView(false);
            }}
            activeOpacity={!linkAssetsView ? 1 : undefined}
          >
            <Text
              style={{
                fontSize: 22,
                lineHeight: 33,
                fontFamily: 'Poppins_400Regular',
                color: !linkAssetsView
                  ? Colors.light.hereworksBlack600
                  : Colors.light.hereworksBlack300,
              }}
            >
              Templates
            </Text>
            {!linkAssetsView && (
              <View
                style={{ backgroundColor: Colors.light.hereworksBlack600, height: 2, marginTop: 4 }}
              />
            )}
          </TouchableOpacity>
          <TouchableOpacity
            style={{ marginLeft: 24 }}
            onPress={() => {
              clearTabSelection();
              setLinkAssetsView(true);
            }}
            activeOpacity={linkAssetsView ? 1 : undefined}
          >
            <Text
              style={{
                fontSize: 22,
                fontFamily: 'Poppins_400Regular',
                color: !linkAssetsView
                  ? Colors.light.hereworksBlack300
                  : Colors.light.hereworksBlack600,
              }}
            >
              Assets
            </Text>
            {linkAssetsView && (
              <View
                style={{ backgroundColor: Colors.light.hereworksBlack600, height: 2, marginTop: 4 }}
              />
            )}
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderColor: Colors.light.hereworksBlue500,
            backgroundColor: '#fff',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 24,
            marginRight: 24,
            width: 113,
          }}
          onPress={() => {
            Analytics.logEvent('button_press', {
              screen: 'Templates',
              name: 'Export',
            });
            if (!exportClicked) {
              setExportClicked(true);
            } else {
              Linking.openURL(downloadLink);
            }
          }}
          disabled={exportClicked && downloadLink === null}
        >
          {downloadLink === null && exportClicked && <ActivityIndicator size={'small'} />}
          {(!exportClicked || (exportClicked && downloadLink !== null)) && (
            <>
              <Text
                style={{
                  color: Colors.light.hereworksBlue500,
                  marginVertical: 8,
                  marginRight: 8,
                }}
              >
                Export
              </Text>
              <DownloadIcon color={Colors.light.hereworksBlue500} />
            </>
          )}
        </TouchableOpacity>
      </View>
      {!linkAssetsView && renderManageGroupsView()}
      {linkAssetsView && (
        <Animatable.View
          animation={'fadeIn'}
          style={{
            borderRadius: 24,
            marginVertical: 20,
            overflow: 'hidden',
            flexDirection: 'row',
            alignSelf: 'stretch',
            backgroundColor: '#fff',
            flex: 1,
          }}
        >
          {!renderMobile() && (
            <View
              style={{
                minWidth: 240,
                backgroundColor: Colors.light.hereworksBlue50,
                flex: 2,
              }}
            >
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: assetFilterVisible
                    ? Colors.light.hereworksBlue300
                    : Colors.light.hereworksBlue50,
                }}
                onPress={() => {
                  analytics(roomSelected ? 'BY ROOM' : 'BY TEMPLATE');
                  setAssetFilterVisible(!assetFilterVisible);
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Roboto_500Medium',
                    fontSize: 12,
                    lineHeight: 20,
                    color: assetFilterVisible ? '#fff' : Colors.light.hereworksBlack500,
                    marginTop: 24,
                    marginLeft: 24,
                    marginBottom: 17,
                  }}
                >
                  {roomSelected ? 'BY ROOM' : 'BY TEMPLATE'}
                </Text>
                <OrionArrowIcon
                  style={{
                    position: 'absolute',
                    right: 18,
                    transform: [{ rotateZ: assetFilterVisible ? '90deg' : '270deg' }],
                  }}
                  color={assetFilterVisible ? '#fff' : Colors.light.hereworksBlack500}
                />
              </TouchableOpacity>
              <View
                style={{ backgroundColor: Colors.light.hereworksBlue100, height: 1, width: '100%' }}
              >
                {assetFilterVisible && (
                  <View
                    style={{
                      backgroundColor: Colors.light.hereworksWhite200,
                      position: 'absolute',
                      top: 0,
                      zIndex: 1,
                      width: '100%',
                      borderTopWidth: 1,
                      borderBottomWidth: 1,
                      borderColor: Colors.light.hereworksBlue100,
                    }}
                  >
                    {roomSelected && (
                      <TouchableOpacity
                        style={{ paddingLeft: 24, paddingVertical: 13 }}
                        onPress={() => {
                          setRoomSelected(false);
                          setSelectedTemplate(null);
                          setSelectedAsset(null);
                          setAllSelected(false);
                          setSelectedId(null);
                          setSelectedRoom(null);
                          setAssetFilterVisible(false);
                          setSelectedTitle('');
                        }}
                      >
                        <Text>BY TEMPLATE</Text>
                      </TouchableOpacity>
                    )}
                    {!roomSelected && (
                      <TouchableOpacity
                        style={{ paddingLeft: 24, paddingVertical: 13 }}
                        onPress={() => {
                          setRoomSelected(true);
                          setSelectedTemplate(null);
                          setSelectedAsset(null);
                          setAllSelected(false);
                          setSelectedId(null);
                          setSelectedRoom(null);
                          setAssetFilterVisible(false);
                          setSelectedTitle('');
                        }}
                      >
                        <Text>BY ROOM</Text>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
              </View>
              <ScrollView
                nestedScrollEnabled
                style={{ zIndex: -1 }}
              >
                {!roomSelected
                  && (templates ?? [])?.map((template: any, key: number) => (
                    <View key={key} style={{ zIndex: -1 }}>
                      {renderTemplate(template)}
                    </View>
                  ))}
                {roomSelected
                  && Object.keys(rooms ?? [])
                    ?.sort()
                    ?.map((room: string, key: number) => {
                      if (rooms[room][0]?.LocationRoom === undefined) {
                        return null;
                      }
                      return (
                        <View key={key} style={{ zIndex: -1 }}>
                          {renderRoom(rooms[room])}
                        </View>
                      );
                    })}
              </ScrollView>
            </View>
          )}
          <View
            style={{
              flex: 7,
            }}
          >
            <View style={styles.contentHeader}>
              {(selectedAsset || selectedTemplate)
                && <View style={styles.contentHeaderButtonSection}>
                  <TouchableOpacity
                    style={styles.backButton}
                    onPress={() => {
                      if (assetFilterResults?.length) clearAssetFilter();
                      else if (selectedAsset) setSelectedAsset(null);
                      else if (selectedTemplate) {
                        setSelectedTemplate(null);
                        setSelectedAsset(null);
                        setSelectedTitle(null);
                        setSelectedId(null);
                      }
                    }}
                  >
                    <BackIconSmall />
                  </TouchableOpacity>
                  <Text style={styles.contentHeaderText}>ASSETS</Text>
                </View>}
              <View style={styles.searchBox}>
                <SearchIcon style={{ marginLeft: 14 }} />
                <TextInput
                  placeholder="Search"
                  value={searchValue}
                  style={styles.searchText}
                  onFocus={() => setSearchResultsPaneFocused(false)}
                  ref={(ref) => {
                    assetSearchField.current = ref;
                  }}
                  onChangeText={(text) => {
                    setSearchResultsPaneFocused(false);
                    setSearchValue(text);
                  }}
                  onKeyPress={(e) => {
                    // @ts-ignore
                    if (e.code === 'ArrowDown') setSearchResultsPaneFocused(true);
                  }}
                />
              </View>
              {!selectedAsset && <TouchableOpacity
                style={styles.createButton}
                onPress={() => {
                  analytics('Create new asset');
                  setAddAssetModalVisible(true);
                }}
              >
                <Text style={styles.createButtonTitle}>Add Asset</Text>
                <PlusIcon />
              </TouchableOpacity>}
            </View>
            {renderSearchPane()}
            <View style={{ height: '100%' }}>
              <AddAssetModal
                visible={addAssetModalVisible}
                setModalVisible={setAddAssetModalVisible}
                template={selectedTemplate ?? templates[0]}
                addAsset={addAsset}
              />
              {!selectedAsset && <View style={{ marginHorizontal: 24 }}>
                <TouchableOpacity
                  style={styles.filterButton}
                  onPress={() => {
                    analytics('Filter button');
                    setDetailedFilterVisible(!detailedFilterVisible);
                  }}
                >
                  <Text style={styles.filterButtonTitle}>Filter</Text>
                  <FilterIcon16 />
                </TouchableOpacity>
                {assetCount > 0 && (
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 28 }}>
                    <Text style={styles.selectedTitle}>
                      {assetFilterResults?.length ? 'Filter results' : selectedTitle}
                    </Text>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'flex-end',
                        flexDirection: 'row',
                        marginRight: 24,
                      }}
                    >
                      {(selectedRoom !== null && !assetFilterResults?.length) && (
                        <View>
                          <Text style={styles.headingSubTitle}>AREA</Text>
                          <Text style={styles.headingValue}>{selectedRoom?.LocationFloor}</Text>
                        </View>
                      )}
                      <View style={{ marginLeft: 32, alignItems: 'center' }}>
                        <Text style={styles.headingSubTitle}>ASSETS</Text>
                        <Text style={styles.headingValue}>
                          {assetFilterResults?.length || assetCount}
                        </Text>
                      </View>
                      {(selectedTemplate && !assetFilterResults?.length) ? <>
                        {selectedTemplate?.CreatedOn ? <View style={{ marginLeft: 32, alignItems: 'center' }}>
                          <Text style={styles.headingSubTitle}>CREATED ON</Text>
                          <Text style={styles.headingValue}>
                            {Moment(selectedTemplate?.CreatedOn).format('MMMM DD[, ]YYYY')}
                          </Text>
                        </View> : null}
                        {selectedTemplate?.ModifiedOn ? <View style={{ marginLeft: 32, alignItems: 'center' }}>
                          <Text style={styles.headingSubTitle}>CHANGED ON</Text>
                          <Text style={styles.headingValue}>
                            {Moment(selectedTemplate?.ModifiedOn).format('MMMM DD[, ]YYYY')}
                          </Text>
                        </View> : null}
                      </> : null}
                    </View>
                  </View>
                )}
                <View>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginVertical: 12,
                      alignSelf: 'stretch',
                      alignItems: 'center',
                    }}
                  >
                    {showCheckbox && <TouchableOpacity
                      onPress={() => {
                        setAllSelected(!allSelected);
                      }}
                    >
                      <CheckBox
                        checkcolor={allSelected ? null : '#fff'}
                        color={allSelected ? null : Colors.light.hereworksBlue100}
                        style={{ marginLeft: 26, marginRight: 14 }}
                      />
                    </TouchableOpacity>}
                    <View style={{ flexDirection: 'row', flex: 1 }}>
                      <View style={styles?.sortContainer}>
                        <TouchableOpacity
                          onPress={() => {
                            setSortById(!sortById);
                            setSortByFloor(null);
                            setSortByRoom(null);
                          }}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderRadius: 4,
                            backgroundColor:
                              sortById !== null ? Colors.light.hereworksWhite100 : '#fff',
                            borderColor:
                              sortById !== null ? Colors.light.hereworksWhite500 : '#fff',
                          }}
                        >
                          <OrionArrowIcon
                            style={{ transform: [{ rotateZ: sortById ? '90deg' : '270deg' }] }}
                          />
                          <Text style={styles.headerText}>ID</Text>
                        </TouchableOpacity>
                      </View>
                      <View style={styles?.sortContainer}>
                        <TouchableOpacity
                          onPress={() => {
                            setSortById(null);
                            setSortByFloor(!sortByFloor);
                            setSortByRoom(null);
                          }}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderRadius: 4,
                            backgroundColor:
                              sortByFloor !== null ? Colors.light.hereworksWhite100 : '#fff',
                            borderColor:
                              sortByFloor !== null ? Colors.light.hereworksWhite500 : '#fff',
                          }}
                        >
                          <OrionArrowIcon
                            style={{ transform: [{ rotateZ: sortByFloor ? '90deg' : '270deg' }] }}
                          />
                          <Text style={styles.headerText}>FLOOR</Text>
                        </TouchableOpacity>
                      </View>
                      <View style={styles?.sortContainer}>
                        <TouchableOpacity
                          onPress={() => {
                            setSortById(null);
                            setSortByFloor(null);
                            setSortByRoom(!sortByRoom);
                          }}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderRadius: 4,
                            backgroundColor:
                              sortByRoom !== null ? Colors.light.hereworksWhite100 : '#fff',
                            borderColor:
                              sortByRoom !== null ? Colors.light.hereworksWhite500 : '#fff',
                          }}
                        >
                          <OrionArrowIcon
                            style={{ transform: [{ rotateZ: sortByRoom ? '90deg' : '270deg' }] }}
                          />
                          <Text style={styles.headerText}>ROOM</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View>
                      <Text
                        style={[
                          styles.headerText,
                          {
                            flex: 1,
                            width: 96,
                            alignSelf: 'flex-end',
                            marginRight: 12,
                          },
                        ]}
                      >
                        ACTIONS
                      </Text>
                    </View>
                  </View>
                </View>
              </View>}
              <ScrollView style={{ paddingHorizontal: 24, paddingBottom: 90 }}>
                <View style={[styles.divider, { marginBottom: 24 }]} />
                {selectedAsset ? renderItem({ item: selectedAsset, index: 0 }, true) : <FlatList
                  refreshControl={<RefreshControl
                    refreshing={assetFilterLoading}
                    onRefresh={refresh}
                  />}
                  data={assetFilterResults.length > 0 ? assetFilterResults : filterAssets()}
                  renderItem={renderItem}
                  keyExtractor={(item, index) => String(index)}
                />}
              </ScrollView>
            </View>
          </View>
          <Animated.ScrollView
            style={[styles.filterContainer, { transform: [{ translateX: filterDrawerInset }] }]}
          >
            <View style={{ flex: 1 }}>
              <Text style={[styles.selectedTitle, { marginBottom: 24 }]}>Filter</Text>
              <FilterSearchBox
                title={'Name'}
                placeholder={'Enter name'}
                value={filterName}
                onChangeText={setFilterName}
              />
              <FilterSearchBox
                title={'Model'}
                placeholder={'Enter model'}
                value={filterModel}
                onChangeText={setFilterModel}
              />
              <FilterSearchBox
                title={'Serial number'}
                placeholder={'Enter serial number'}
                value={filterSerial}
                onChangeText={setFilterSerial}
              />
              <FilterSearchBox
                title={'Description'}
                placeholder={'Enter description'}
                value={filterDescription}
                onChangeText={setFilterDescription}
              />
              <FilterSearchBox
                title={'Manufacturer'}
                placeholder={'Enter manufacturer'}
                value={filterManufacturer}
                onChangeText={setFilterManufacturer}
              />
              <TouchableOpacity
                style={styles.resetAllFiltersButton}
                onPress={() => {
                  analytics('Reset All Button Pressed');
                  resetAssetFilters();
                }}
              >
                <Text style={styles.resetAllFiltersText}>RESET ALL FILTERS</Text>
              </TouchableOpacity>
              {assetFilterResults.length > 0 && (
                <TouchableOpacity
                  style={styles.resetAllFiltersButton}
                  onPress={() => {
                    analytics('Asset filters - Clear Search Results button pressed');
                    clearAssetFilter();
                  }}
                >
                  <Text style={styles.resetAllFiltersText}>CLEAR SEARCH RESULTS</Text>
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.assetFilterBottomSection}>
              <View style={styles.assetFilterButtons}>
                <TouchableOpacity
                  style={{
                    backgroundColor: Colors.light.hereworksBlue500,
                    borderRadius: 4,
                    borderColor: Colors.light.hereworksBlue500,
                    borderWidth: 1,
                    alignSelf: 'flex-start',
                    paddingVertical: 5,
                    paddingHorizontal: 12,
                  }}
                  onPress={() => {
                    if (
                      filterName.trim().length === 0
                      && filterModel.trim().length === 0
                      && filterSerial.trim().length === 0
                      && filterDescription.trim().length === 0
                      && filterManufacturer.trim().length === 0
                    ) {
                      showAlert('Warning', 'No filters set');
                    } else {
                      applyAssetFilters();
                    }
                  }}
                >
                  <Text
                    style={{
                      color: '#fff',
                      fontSize: 14,
                      lineHeight: 22,
                      fontFamily: 'Roboto_400Regular',
                    }}
                  >
                    Apply
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: '#fff',
                    borderColor: Colors.light.hereworksBlue500,
                    borderWidth: 1,
                    borderRadius: 4,
                    alignSelf: 'flex-start',
                    paddingVertical: 5,
                    paddingHorizontal: 12,
                    marginLeft: 8,
                  }}
                  onPress={() => {
                    setAssetFilterLoading(false);
                    setDetailedFilterVisible(false);
                  }}
                >
                  <Text
                    style={{
                      color: Colors.light.hereworksBlue500,
                      fontSize: 14,
                      lineHeight: 22,
                      fontFamily: 'Roboto_400Regular',
                    }}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
              {assetFilterLoading && (
                <ActivityIndicator
                  animating={assetFilterLoading}
                  size="large"
                />
              )}
            </View>
          </Animated.ScrollView>
        </Animatable.View>
      )}
      {loading && <ActivityIndicator
        size='large'
        style={styles.loadingIndicator}
      />}
      <AlertModal modalVisible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        error={alertError}
        setModalVisible={setAlertVisible} />
    </View>
  );
}

const styles = StyleSheet.create({
  sectionTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    marginLeft: 24,
    marginRight: 50,
    marginVertical: 16,
  },
  selectedTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 18,
    lineHeight: 27,
    color: Colors.light.hereworksBlack600,
  },
  headingSubTitle: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 11,
    lineHeight: 13,
    marginVertical: 4,
  },
  headingValue: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.light.hereworksBlack600,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.light.hereworksWhite500,
    width: '100%',
    marginTop: 0,
  },
  createButton: {
    borderRadius: 4,
    backgroundColor: Colors.light.hereworksBlue500,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 20,
    marginStart: 40,
  },
  usePresetButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksDarkGreen,
    flex: 1,
    marginRight: 15,
    marginLeft: 125,
    alignItems: 'center',
  },
  customizeButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksExtraDarkTeal700,
    flex: 1,
    marginLeft: 15,
    marginRight: 125,
    alignItems: 'center',
  },
  linkTagButton: {
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksDarkGreen,
    flex: 1,
    marginLeft: 15,
    marginRight: 125,
    alignItems: 'center',
  },
  createButtonTitle: {
    color: '#fff',
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    marginLeft: 24,
    marginRight: 10,
    marginVertical: 12,
  },
  usePresetButtonTitle: {
    color: '#fff',
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    marginHorizontal: 33,
    marginVertical: 12,
  },
  searchBox: {
    height: 40,
    flex: 1,
    borderRadius: 24,
    backgroundColor: '#fff',
    borderColor: Colors.light.hereworksPaleBlue900,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchText: {
    color: Colors.light.hereworksBlack600,
    marginLeft: 9,
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 16.4,
    outlineStyle: 'none',
  },
  modalContainer: {
    flex: 1,
    backgroundColor: '#00000050',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalView: {
    backgroundColor: '#fff',
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: 600,
    height: 600,
  },
  createHeader: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 30,
    marginHorizontal: 176,
    marginVertical: 60,
  },
  successHeader: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 30,
    marginHorizontal: 176,
    marginBottom: 20,
    marginTop: 50,
  },
  createCard: {
    flex: 1,
    backgroundColor: Colors.light.hereworksPaleBlue700,
    borderRadius: 10,
    margin: 15,
    alignItems: 'center',
    width: 140,
    height: 160,
  },
  newButtonTitle: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: '#000',
    marginBottom: 17,
    marginTop: 17,
  },
  backArrow: {
    marginRight: 7,
    marginTop: 43,
  },
  descriptionText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 18,
    lineHeight: 27,
    color: Colors.light.hereworksPrimaryGrey900,
  },
  radioButton: {
    backgroundColor: Colors.light.hereworksPaleBlue800,
    height: 40,
    width: 40,
    borderRadius: 20,
    marginVertical: 15,
    marginHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    backgroundColor: Colors.light.hereworksDarkBlue,
    height: 30,
    width: 30,
    borderRadius: 15,
  },
  selectRow: {
    backgroundColor: Colors.light.hereworksPrimaryGrey200,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    width: 327,
    marginBottom: 10,
  },
  selectRowText: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 18,
    color: '#000',
  },
  selectPresetButtons: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 50,
    marginTop: 30,
  },
  tabs: {
    flexDirection: 'row',
    marginTop: 17,
  },
  tabTitle: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    color: '#000',
    marginHorizontal: 4,
    marginBottom: 4,
    lineHeight: 19,
    textAlign: 'center',
  },
  rowText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
  },
  parentTitle: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 24,
    color: '#000',
    marginLeft: 24,
    marginTop: 24,
    marginBottom: 20,
  },
  parentTile: {
    width: 140,
    height: 60,
    marginBottom: 10,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: 10,
    justifyContent: 'center',
  },
  parentTileTitle: {
    marginLeft: 14,
    color: Colors.light.hereworksPrimaryGrey900,
    marginBottom: 4,
    fontSize: 11,
  },
  headerText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    lineHeight: 15,
    letterSpacing: 0.7,
    marginRight: 8,
    color: Colors.light.hereworksBlack400,
  },
  sortContainer: {
    flex: 1,
    alignItems: 'flex-start',
    marginLeft: 30,
  },
  filterContainer: {
    width: 300,
    position: 'absolute',
    height: '100%',
    zIndex: 5,
    right: 0,
    backgroundColor: '#fff',
    borderLeftWidth: 1,
    borderColor: Colors.light.hereworksBlue100,
    padding: 24,
  },
  filterButton: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    paddingHorizontal: 24,
    alignSelf: 'flex-start',
    marginTop: 20,
  },
  filterButtonTitle: {
    fontSize: 14,
    lineHeight: 22,
    marginVertical: 5,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlue500,
    marginRight: 8,
  },
  resetAllFiltersButton: {
    justifyContent: 'center',
    marginTop: 20,
    alignSelf: 'flex-start',
    borderBottomWidth: 1,
    borderColor: Colors.light.hereworksPrimaryGrey,
  },
  resetAllFiltersText: {
    color: Colors.light.hereworksPrimaryGrey,
    fontSize: 12,
    fontFamily: 'Roboto_500Medium',
  },
  assetFilterBottomSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  assetFilterButtons: {
    flexDirection: 'row',
  },
  loadingIndicator: {
    position: 'absolute',
    alignSelf: 'center',
    top: '40%',
  },
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 24,
    height: 62,
    borderBottomWidth: 1,
    borderBottomColor: Colors.light.hereworksBlue100,
  },
  backButton: {
    marginEnd: 12,
    width: 64,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    borderRadius: 4,
  },
  contentHeaderButtonSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginEnd: 40,
  },
  contentHeaderText: {
    fontSize: 12,
    lineHeight: 20,
    color: Colors.light.hereworksBlack500,
    fontFamily: 'Roboto_500Medium',
  },
});
