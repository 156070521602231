import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const AlertIcon = (props: SvgProps) => (
  <Svg width={6} height={27} fill="none" {...props}>
    <Path
      fill="#fff"
      fillRule="evenodd"
      d="M3 .332a2 2 0 0 1 2 2v13.333a2 2 0 0 1-4 0V2.332a2 2 0 0 1 2-2Z"
      clipRule="evenodd"
    />
    <Path fill="#fff" d="M5.667 23.665a2.667 2.667 0 1 1-5.334 0 2.667 2.667 0 0 1 5.334 0Z" />
  </Svg>
);
export default AlertIcon;
