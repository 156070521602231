import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const TickedBoxIcon = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" stroke={'#003A70'} {...props}>
    <Rect width={20} height={20} rx={4} fill="#fff" />
    <Rect width={20} height={20} rx={4} fill="#003A70" />
    <Path
      d="m5 11 3 3 8-8"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default TickedBoxIcon;
