import * as React from 'react';
import Svg, {
  SvgProps, G, Rect, Path, Defs, ClipPath,
} from 'react-native-svg';

interface Props extends SvgProps {
  checkcolor: string;
}

const CheckBox24 = (props: Props) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Rect width={24} height={24} rx={3} fill="#fff" />
      <Path
        d="m5 12 5 5 9-9"
        stroke={props.checkcolor ?? '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Rect x={0.5} y={0.5} width={23} height={23} rx={2.5} stroke={props.color ?? '#0058AD'} />
    <Defs>
      <ClipPath id="a">
        <Rect width={24} height={24} rx={3} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default CheckBox24;
