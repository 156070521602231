import Moment from 'moment';
import React, { useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import BackgroundImage from '../components/BackgroundImage';
import TopSection, { TopSectionProps } from '../components/TopSection';
import Colors from '../constants/Colors';

export default function ErgonomicsScreen() {
  const [range, setRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: Moment().subtract(6, 'days').startOf('day').toDate(), end: new Date() });

  const [compareRange, setCompareRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: null, end: null });

  const onConfirm = React.useCallback(
    (start, end) => {
      setRange({ start, end });
    },
    [setRange],
  );

  const onCompareConfirm = React.useCallback(
    (start, end) => {
      setCompareRange({ start, end });
    },
    [setCompareRange],
  );

  const topSectionProps: TopSectionProps = {
    range, compareRange, onConfirm, onCompareConfirm, title: 'Ergonomics',
  };

  return (
    <ScrollView style={styles.container}>
      <BackgroundImage />
      <TopSection {...topSectionProps} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.light.hereworksBackground,
  },
});
