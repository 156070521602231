import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const BinIcon60 = (props: SvgProps) => (
  <Svg width={61} height={60} fill="none" {...props}>
    <Path
      d="M46.074 13.203 43.695 51.84a4.102 4.102 0 0 1-4.02 4.02H18.84a4.102 4.102 0 0 1-4.02-4.02l-2.379-38.637m-4.511 0h42.656H7.93Zm13.125 0V9.84a3.2 3.2 0 0 1 3.199-3.2h10.008a3.2 3.2 0 0 1 3.199 3.2v3.363H21.055ZM37.46 19.766l-1.64 29.53m-6.562-29.53v29.53-29.53Zm-8.203 0 1.64 29.53-1.64-29.53Z"
      stroke={props.color ?? '#fff'}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default BinIcon60;
