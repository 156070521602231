import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const OrionArrowIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M13.25 6.5 8 11.75 13.25 17"
      stroke={props.color ?? '#0058AD'}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default OrionArrowIcon;
