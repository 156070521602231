import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ScanIcon = (props: SvgProps) => (
  <Svg width={80} height={80} fill="none" {...props}>
    <Path
      d="M15 32.5a2.5 2.5 0 0 0 2.5-2.5V17.5H30a2.5 2.5 0 0 0 0-5H15a2.5 2.5 0 0 0-2.5 2.5v15a2.5 2.5 0 0 0 2.5 2.5ZM50 17.5h12.5V30a2.5 2.5 0 0 0 5 0V15a2.5 2.5 0 0 0-2.5-2.5H50a2.5 2.5 0 0 0 0 5ZM30 62.5H17.5V50a2.5 2.5 0 0 0-5 0v15a2.5 2.5 0 0 0 2.5 2.5h15a2.5 2.5 0 0 0 0-5ZM65 47.5a2.5 2.5 0 0 0-2.5 2.5v12.5H50a2.5 2.5 0 0 0 0 5h15a2.5 2.5 0 0 0 2.5-2.5V50a2.5 2.5 0 0 0-2.5-2.5ZM75 37.5H5a2.5 2.5 0 0 0 0 5h70a2.5 2.5 0 0 0 0-5Z"
      fill="#323E48"
    />
  </Svg>
);

export default ScanIcon;
