import * as React from 'react';
import Svg, {
  SvgProps, G, Path, Defs, ClipPath,
} from 'react-native-svg';

const DeleteIcon12 = (props: SvgProps) => (
  <Svg width={12} height={12} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.712 3.348a.75.75 0 0 0-1.06-1.06L6 4.939 3.348 2.288a.75.75 0 1 0-1.06 1.06L4.939 6 2.288 8.65a.75.75 0 0 0 1.06 1.061L6 7.06l2.652 2.652a.75.75 0 0 0 1.06-1.06L7.061 6l2.651-2.652Z"
        fill="#8AB2D9"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h12v12H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default DeleteIcon12;
