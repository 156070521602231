import React, {
  FC, ReactElement, useRef, useState,
} from 'react';
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  Modal,
  View,
  FlexStyle,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../constants/Colors';

interface Props {
  label: string;
  dataDrop: Array<{ label: string; value: string }>;
  // eslint-disable-next-line no-unused-vars
  onSelect: (item: any) => void;
  selected: any | null;
  setSelected;
  style?: FlexStyle,
}

const DropdownSelector: FC<Props> = ({
  label, dataDrop, onSelect, selected, setSelected, style,
}) => {
  const DropdownButton = useRef();
  const [visible, setVisible] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(0);
  const [dropdownLeft, setDropdownLeft] = useState(0);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  const toggleDropdown = (): void => {
    // eslint-disable-next-line no-unused-expressions
    visible ? setVisible(false) : openDropdown();
  };

  const openDropdown = (): void => {
    // @ts-ignore
    DropdownButton?.current?.measure(
      (
        _fx: number,
        _fy: number,
        w: number,
        h: number,
        px: number,
        py: number,
      ) => {
        setDropdownTop(py + h + 10);
        setDropdownLeft(px);
        setDropdownWidth(w);
      },
    );

    setVisible(true);
  };

  const onItemPress = (item: any): void => {
    setSelected(item);
    onSelect(item);
    setVisible(false);
    // console.log(`itempress: ${JSON.stringify(item)}`);
  };

  const renderItem = ({ item }: any): ReactElement<any, any> => (
    <TouchableOpacity style={[styles.item, item === selected && styles.selectedOption]}
      onPress={() => onItemPress(item)}>
      <Text style={[styles.text, item === selected && styles.selectedText]}>
        {item?.label ?? item}
      </Text>
    </TouchableOpacity>
  );

  const renderDropdown = (): ReactElement<any, any> => <Modal visible={visible} transparent animationType="none">
    <TouchableOpacity onPress={() => setVisible(false)}>
      <FlatList style={[
        styles.dropdown, { top: dropdownTop, left: dropdownLeft, width: dropdownWidth },
      ]}
        data={dataDrop}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()} />
    </TouchableOpacity>
  </Modal>;

  return (
    <TouchableOpacity ref={DropdownButton} style={[styles.button, style]} onPress={toggleDropdown}>
      {renderDropdown()}
      <Text style={styles.text}>
        {(selected?.label ?? selected) ?? label}
      </Text>
      <View>
        <FontAwesome size={25}
          style={styles.icon}
          name={visible ? 'angle-up' : 'angle-down'} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    backgroundColor: '#fff',
    paddingHorizontal: 12,
    paddingVertical: 10,
  },
  selectedOption: {
    backgroundColor: '#003A70',
  },
  selectedText: {
    color: 'white',
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1,
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 4,
    minHeight: 40,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
  },
  text: {
    flex: 1,
    color: Colors.light.hereworksBlack600,
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Poppins_400Regular',
  },
  dropdown: {
    backgroundColor: '#fff',
    flex: 1,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
    borderRadius: 12,
    maxHeight: 200,
  },
  icon: {
    color: Colors.light.hereworksBlue500,
    marginBottom: 3,
  },
});

export default DropdownSelector;
