/* eslint-disable no-nested-ternary */
import { processFontFamily } from 'expo-font';
import React, { useEffect, useState } from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory-native';
import {
  useWindowDimensions, StyleSheet, Text, View, Dimensions,
} from 'react-native';
import Colors from '../constants/Colors';
import { GetMetricByDate } from '../requests/GetMetricByDate';
import CompareArrowsIcon from '../components/CompareArrowsIcon';
import {
  tickValSamples, tooltipDateLabel, axisDateLabel, hideRepeatedSuffix,
} from './axisUtils';
import { GetProcessedElectricity } from '../requests/GetProcessedElectricity';

const BarWithLineGraphMobile = (props) => {
  const [graphData, setGraphData] = useState([]);
  const [compareGraphData, setCompareGraphData] = useState([]);
  const [utilityData, setUtilityData] = useState([]);
  const [compareUtilityData, setCompareUtilityData] = useState([]);
  const [xArray2, setXArray2] = useState([]);
  const [xArray, setXArray] = useState([]);
  const [tickVals, setTickVals] = useState([]);
  const [tickVals2, setTickVals2] = useState([]);
  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });

  const pointsArray = [];
  const comparePointsArray = [];
  let point = {};
  let comparePoint = {};
  const timeLabels = [];
  const timeLabels2 = [];

  const Score = [];
  const Score2 = [];

  useEffect(() => {
    setXArray2(compareGraphData?.map((e, index) => axisDateLabel(compareGraphData, index)));
    setXArray(graphData?.map((e, index) => axisDateLabel(graphData, index)));
    const tickArray = [...Array(graphData.length).keys()];
    const tickArray2 = [...Array(compareGraphData.length).keys()];
    const tickCount = Math.min(
      Math.max(1, Math.floor(5 * (graphData.length / compareGraphData.length))),
      5,
    );
    const compareTickCount = Math.min(
      Math.max(1, Math.floor(5 * (compareGraphData.length / graphData.length))),
      5,
    );
    setTickVals(tickValSamples(tickArray, tickCount));
    setTickVals2(tickValSamples(tickArray2, compareTickCount));
  }, [compareGraphData, graphData]);

  useEffect(() => {
    const getData = async () => {
      try {
        GetMetricByDate(
          'happy_metric_total_building_occupancy_raw_value',
          props.dates,
          props.interval,
        )?.then((data) => {
          let index = 0;
          data?.forEach((e) => {
            Score.push(e.score.value);
            const hourLabel = axisDateLabel(data, index);
            timeLabels.push(hourLabel);
            point = {
              Score: Math.floor(e.score.value * 10) / 10,
              index,
              date: e.key_as_string,
              isCompare: false,
              x: index,
              y: index,
            };
            pointsArray.push(point);
            index += 1;
          });
          setGraphData(pointsArray);
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(e, e.stack);
      }
    };
    getData();
  }, [props.interval, props.dates]);

  useEffect(() => {
    const getData2 = async () => {
      try {
        GetMetricByDate(
          'happy_metric_total_building_occupancy_raw_value',
          props.compareDates,
          props.interval,
        )?.then((data) => {
          let index = 0;
          data?.forEach((e) => {
            Score2.push(e.score.value);
            const timeLabel = axisDateLabel(data, index);
            timeLabels2.push(timeLabel);
            comparePoint = {
              name: timeLabel,
              Score: Math.floor(e.score.value * 10) / 10,
              index,
              date: e.key_as_string,
              isCompare: true,
              x2: index,
            };
            comparePointsArray.push(comparePoint);
            index += 1;
          });
          setCompareGraphData(comparePointsArray);
        });
      } catch (error) {
        // console.error(error);
      }
    };
    getData2();
  }, [props.interval, props.compareDates]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (props.metricName === 'data-unitsconsumed') {
          GetProcessedElectricity(
            props.metricName,
            props.dates,
            props.interval,
            'hw-processeddata-electricity',
            props.thingId,
          )
            ?.then((data) => {
              const utilityArray = [];
              data?.forEach((e, idx) => {
                point = {
                  name: idx,
                  value: e.score.value
                    ? Math.floor(
                      (e.score.value ?? 0) * 10,
                    ) / 10
                    : 0,
                  index: idx,
                  x: idx,
                };
                utilityArray.push(point);
              });
              setUtilityData(utilityArray);
            });
        } else {
          GetMetricByDate(
            props.metricName,
            props.dates,
            props.interval,
            props.metricName === 'data-unitsconsumed'
              ? 'hw-processeddata-electricity'
              : 'happyscore_flat_permin',
          )?.then((data) => {
            let index = 0;
            const utilityArray = [];
            if (props.metricName === 'data-unitsconsumed') {
              data?.forEach((e, idx) => {
                point = {
                  name: idx,
                  value: e.score.value
                    ? Math.floor(e.score.value * 10) / 10
                    : 0,
                  targetValue: Math.floor(e.score.value * 10) / 10,
                  x: idx,
                };
                utilityArray.push(point);
                // console.log(point);
              });
            } else {
              data?.forEach((e) => {
                point = {
                  name: index,
                  value: Math.floor(e.score.value * 10) / 10,
                  targetValue: Math.floor(Math.random() * e.score.value),
                  x: index,
                };
                utilityArray.push(point);
                index += 1;
              });
            }
            setUtilityData(utilityArray);
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(e, e.stack);
      }
    };
    getData();
  }, [props.interval, props.dates, props.metricName]);

  useEffect(() => {
    const getData = async () => {
      if (props.compareDates.end !== null) {
        try {
          if (props.metricName === 'data-unitsconsumed') {
            GetProcessedElectricity(
              props.metricName,
              props.compareDates,
              props.interval,
              'hw-processeddata-electricity',
              props.thingId,
            )
              ?.then((data) => {
                const utilityArray = [];
                data?.forEach((e, idx) => {
                  point = {
                    name: idx,
                    value: e.score.value
                      ? Math.floor(
                        (e.score.value ?? 0) * 10,
                      ) / 10
                      : 0,
                    index: idx,
                    x: idx,
                  };
                  utilityArray.push(point);
                });
                setCompareUtilityData(utilityArray);
              });
          } else {
            GetMetricByDate(
              props.metricName,
              props.compareDates,
              props.interval,
              props.metricName === 'data-unitsconsumed'
                ? 'hw-processeddata-electricity'
                : 'happyscore_flat_permin',
            )
              ?.then((data) => {
                let index = 0;
                const utilityArray = [];
                if (props.metricName === 'data-unitsconsumed') {
                  data?.forEach((e, idx) => {
                    if (idx > 0) {
                      point = {
                        name: idx,
                        value: e.score.value
                          ? Math.floor(e.score.value * 10) / 10
                          : 0,
                        targetValue: Math.floor(e.score.value * 10) / 10,
                        x: idx,
                      };
                      utilityArray.push(point);
                    }
                    // console.log(point);
                  });
                } else {
                  data?.forEach((e) => {
                    point = {
                      name: index,
                      value: Math.floor(e.score.value * 10) / 10,
                      targetValue: Math.floor(e.score.value * 10) / 10,
                      x: index,
                    };
                    utilityArray.push(point);
                    index += 1;
                  });
                }
                setCompareUtilityData(utilityArray);
              });
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          // console.error(e, e.stack);
        }
      }
    };
    getData();
  }, [props.interval, props.compareDates, props.metricName]);

  class CustomFlyout extends React.Component {
    render() {
      // @ts-ignore
      const { x, y } = this.props;
      // @ts-ignore
      const { datum } = this.props;
      const graphWidth = (dimensions.window?.width ?? 0) - 142;
      // console.log(graphWidth);
      // console.log(`x: ${x}`);
      const left = x > graphWidth ? x - 130 : x - 50;
      const top = y > 240 ? y - 190 : y - 150;
      // console.log(this.props);
      if (props.compareToggled) {
        // todo
        return (
          <View
            style={{
              borderRadius: 8,
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              backgroundColor: '#fff',
              left,
              top,
              width: 142,
              shadowOffset: {
                width: 0,
                height: 12,
              },
              shadowOpacity: 0.1,
              shadowRadius: 40,
              shadowColor: 'black',
              elevation: 5,
            }}
          >
            <View
              style={{
                margin: 8,
                alignSelf: 'stretch',
                borderRadius: 4,
                backgroundColor: Colors.light.hereworksWhite100,
              }}
            >
              <View style={{ padding: 0, alignItems: 'flex-start' }}>
                <Text
                  style={{
                    color: Colors.light.hereworksPrimaryGrey800,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 12,
                    marginLeft: 8,
                    marginTop: 4,
                  }}
                >
                  {/* @ts-ignore */}
                  {tooltipDateLabel(graphData, datum.x)}
                </Text>
              </View>
              {!props.hideOccupancy
                && <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginVertical: 2,
                  }}
                >
                  <View
                    style={{
                      marginLeft: 8,
                      width: 12,
                      height: 12,
                      borderRadius: 6,
                      backgroundColor: Colors.light.hereworksPurple500,
                    }}
                  />
                  <Text style={styles.tooltipTitle}>{'Occ: '}</Text>
                  <Text style={styles.tooltipValue}>{graphData[datum.x]?.Score ?? '-'}</Text>
                </View>}
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginVertical: 2,
                }}
              >
                <View
                  style={{
                    marginLeft: 8,
                    width: 12,
                    height: 12,
                    borderRadius: 2,
                    backgroundColor: Colors.light.hereworksDarkBlue,
                  }}
                />
                <Text style={styles.tooltipTitle}>{'Utility: '}</Text>
                <Text style={styles.tooltipValue}>{utilityData[datum.x]?.value ?? '-'}</Text>
              </View>
              {/* <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginVertical: 2,
                }}
              >
                <View
                  style={{
                    marginLeft: 8,
                    width: 12,
                    height: 12,
                    borderRadius: 2,
                    backgroundColor: Colors.light.hereworksDarkBlue200,
                  }}
                />
                <Text style={styles.tooltipTitle}>{'Target Utility: '}</Text>
                <Text style={styles.tooltipValue}>{utilityData[datum.x]?.targetValue}</Text>
              </View> */}
            </View>
            <CompareArrowsIcon style={{ alignSelf: 'center' }} />
            <View
              style={{
                margin: 8,
                alignSelf: 'stretch',
                borderRadius: 4,
                backgroundColor: Colors.light.hereworksYellow50,
              }}
            >
              <View style={{ padding: 0, alignItems: 'flex-start' }}>
                <Text
                  style={{
                    color: Colors.light.hereworksPrimaryGrey800,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 12,
                    marginLeft: 8,
                    marginTop: 4,
                  }}
                >
                  {/* @ts-ignore */}
                  {tooltipDateLabel(compareGraphData, datum.x)}
                </Text>
              </View>
              {!props.hideOccupancy
                && <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginVertical: 2,
                  }}
                >
                  <View
                    style={{
                      marginLeft: 8,
                      width: 12,
                      height: 12,
                      borderRadius: 6,
                      backgroundColor: Colors.light.hereworksYellow800,
                    }}
                  />
                  <Text style={styles.tooltipTitle}>{'Occ: '}</Text>
                  <Text style={styles.tooltipValue}>{compareGraphData[datum.x ?? datum.x2]?.Score ?? '-'}</Text>
                </View>}
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginVertical: 2,
                }}
              >
                <View
                  style={{
                    marginLeft: 8,
                    width: 12,
                    height: 12,
                    borderRadius: 2,
                    backgroundColor: Colors.light.hereworksYellow600,
                  }}
                />
                <Text style={styles.tooltipTitle}>{'Utility: '}</Text>
                <Text style={styles.tooltipValue}>{compareUtilityData[datum.x ?? datum.x2]?.value ?? '-'}</Text>
              </View>
              {/* <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginVertical: 2,
                }}
              >
                <View
                  style={{
                    marginLeft: 8,
                    width: 12,
                    height: 12,
                    borderRadius: 2,
                    backgroundColor: Colors.light.hereworksYellow300,
                  }}
                />
                <Text style={styles.tooltipTitle}>{'Target Utility: '}</Text>
                <Text style={styles.tooltipValue}>{compareUtilityData[datum.x]?.targetValue}</Text>
              </View> */}
            </View>
          </View>
        );
      }

      return (
        <View
          style={{
            borderRadius: 8,
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            backgroundColor: '#fff',
            left,
            top,
            width: 142,
            shadowOffset: {
              width: 0,
              height: 12,
            },
            shadowOpacity: 0.1,
            shadowRadius: 40,
            shadowColor: 'black',
            elevation: 5,
          }}
        >
          <View style={{ padding: 0, alignItems: 'flex-start' }}>
            <Text
              style={{
                color: Colors.light.hereworksPrimaryGrey800,
                fontFamily: 'Poppins_500Medium',
                fontSize: 12,
                marginLeft: 8,
                marginTop: 4,
              }}
            >
              {/* @ts-ignore */}
              {tooltipDateLabel(graphData, datum.x)}
            </Text>
          </View>
          {!props.hideOccupancy
            && <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                marginVertical: 2,
              }}
            >
              <View
                style={{
                  marginLeft: 8,
                  width: 12,
                  height: 12,
                  borderRadius: 6,
                  backgroundColor: Colors.light.hereworksPurple500,
                }}
              />
              <Text
                style={{
                  color: Colors.light.hereworksBlack400,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 12,
                  marginLeft: 8,
                }}
              >
                {'Occ: '}
              </Text>
              <Text
                style={{
                  color: Colors.light.hereworksBlack600,
                  fontFamily: 'Roboto_500Medium',
                  fontSize: 12,
                  marginLeft: 4,
                }}
              >
                {graphData[datum.x]?.Score ?? '-'}
              </Text>
            </View>}
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              marginVertical: 2,
            }}
          >
            <View
              style={{
                marginLeft: 8,
                width: 12,
                height: 12,
                borderRadius: 2,
                backgroundColor: Colors.light.hereworksDarkBlue,
              }}
            />
            <Text
              style={{
                color: Colors.light.hereworksBlack400,
                fontFamily: 'Roboto_400Regular',
                fontSize: 12,
                marginLeft: 8,
              }}
            >
              {'Utility: '}
            </Text>
            <Text
              style={{
                color: Colors.light.hereworksBlack600,
                fontFamily: 'Roboto_500Medium',
                fontSize: 12,
                marginLeft: 4,
              }}
            >
              {utilityData[datum.x]?.value ?? '-'}
            </Text>
          </View>
          {/* <View
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              marginVertical: 2,
            }}
          >
            <View
              style={{
                marginLeft: 8,
                width: 12,
                height: 12,
                borderRadius: 2,
                backgroundColor: Colors.light.hereworksDarkBlue200,
              }}
            />
            <Text
              style={{
                color: Colors.light.hereworksBlack400,
                fontFamily: 'Roboto_400Regular',
                fontSize: 12,
                marginLeft: 8,
              }}
            >
              {'Target Utility: '}
            </Text>
            <Text
              style={{
                color: Colors.light.hereworksBlack600,
                fontFamily: 'Roboto_500Medium',
                fontSize: 12,
                marginLeft: 4,
              }}
            >
              {utilityData[datum.x]?.targetValue}
            </Text>
          </View> */}
        </View>
      );
    }
  }

  const chartWidth = useWindowDimensions().width - 60;

  return (
    <VictoryChart
      width={chartWidth}
      height={408}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={({ datum }) => datum}
          labelComponent={<CustomFlyout />}
        />
      }
      padding={{
        left: 60,
        bottom: 80,
        top: 50,
        right: 40,
      }}
    >
      <VictoryAxis
        dependentAxis
        domain={{
          x: [0, 100],
          y: [0, Math.max(...utilityData?.map((item) => item.value) ?? [], 1.2)],
        }}
        crossAxis={false}
        offsetX={70}
        tickCount={7}
        fixLabelOverlap={true}
        tickFormat={(t) => t.toString().substring(0, 7)}
        style={{
          axis: { stroke: 'none' },
          tickLabels: {
            fontSize: 14,
          },
          grid: { stroke: Colors.light.hereworksWhite500, strokeWidth: 1, pointerEvents: 'none' },
        }}
        tickLabelComponent={
          <VictoryLabel
            style={{
              fill: Colors.light.hereworksBlack400,
              fontSize: 14,
              strokeWidth: 0,
              fontFamily: processFontFamily('Poppins_400Regular'),
              textAnchor: 'end',
            }}
            dx={-10}
          />
        }
      />
      <VictoryAxis
        name="axis1"
        tickValues={tickVals}
        tickFormat={(value, index, array) => hideRepeatedSuffix(index, array, xArray)}
        fixLabelOverlap={true}
        style={{
          axis: { stroke: 'none' },
        }}
        tickLabelComponent={
          <VictoryLabel
            dx={-6}
            style={{
              fontSize: 14,
              textAnchor: 'start',
              strokeWidth: 0,
              stroke: Colors.light.hereworksBlack400,
              fontFamily: processFontFamily('Poppins_400Regular'),
            }}
          />
        }
      />
      {!props.compareToggled && (
        <VictoryGroup>
          <VictoryLine
            style={{
              data: {
                // eslint-disable-next-line max-len
                stroke: ({ active }) => (active ? Colors.light.hereworksDarkBlue : Colors.light.hereworksDarkBlue300),
              },
            }}
            x={'name'}
            y={'value'}
            data={utilityData}
          />
          <VictoryScatter
            x="name"
            y="value"
            data={utilityData}
            size={({ active }) => (active ? 5 : utilityData.length > 20 ? 2 : 3)}
            style={{
              data: { fill: Colors.light.hereworksDarkBlue },
            }}
          />
          {/* <VictoryLine
            style={{
              data: {
                // eslint-disable-next-line max-len
                stroke: ({ active }) => (active ? Colors.light.hereworksDarkBlue200 :
                  Colors.light.hereworksDarkBlue100),
              },
            }}
            x={'name'}
            y={'targetValue'}
            data={utilityData}
          /> */}
        </VictoryGroup>
      )}
      {props.compareToggled && (
        <VictoryGroup>
          <VictoryLine
            style={{
              data: {
                // eslint-disable-next-line max-len
                stroke: ({ active }) => (active ? Colors.light.hereworksDarkBlue : Colors.light.hereworksDarkBlue300),
              },
            }}
            x={'name'}
            y={'value'}
            data={utilityData}
          />
          {!props.hideOccupancy && (
            <VictoryLine
              style={{
                data: {
                  // eslint-disable-next-line max-len
                  stroke: ({ active }) => (active ? Colors.light.hereworksDarkBlue200 : Colors.light.hereworksDarkBlue100),
                },
              }}
              x={'name'}
              y={'value'}
              data={utilityData}
            />
          )}
          <VictoryLine
            style={{
              data: {
                // eslint-disable-next-line max-len
                stroke: ({ active }) => (active ? Colors.light.hereworksYellow600 : Colors.light.hereworksYellow300),
              },
            }}
            x={'name'}
            y={'value'}
            data={compareUtilityData}
          />
          {!props.hideOccupancy && (
            <VictoryLine
              style={{
                data: {
                  // eslint-disable-next-line max-len
                  stroke: ({ active }) => (active ? Colors.light.hereworksYellow300 : Colors.light.hereworksYellow100),
                },
              }}
              x={'name'}
              y={'value'}
              data={compareUtilityData}
            />
          )}
        </VictoryGroup>
      )}
      {!props.hideOccupancy && (
        <VictoryLine
          x="index"
          y="Score"
          data={graphData}
          interpolation="linear"
          style={{
            data: { stroke: Colors.light.hereworksPurple500, strokeWidth: 2 },
          }}
        />
      )}
      {!props.hideOccupancy && (
        <VictoryScatter
          name="mainScatter"
          x="index"
          y="Score"
          data={graphData}
          size={({ active }) => (active ? 5 : graphData.length > 20 ? 2 : 3)}
          style={{
            data: { fill: Colors.light.hereworksPurple500 },
          }}
        />
      )}
      {props.compareToggled && !props.hideOccupancy && (
        <VictoryLine
          x="index"
          y="Score"
          data={compareGraphData}
          interpolation="linear"
          style={{
            data: { stroke: Colors.light.hereworksYellow800, strokeWidth: 2 },
          }}
        />
      )}
      {props.compareToggled && !props.hideOccupancy && (
        <VictoryScatter
          name="compareScatter"
          data={compareGraphData}
          x="index"
          y="Score"
          size={({ active }) => (active ? 5 : compareGraphData.length > 20 ? 2 : 3)}
          style={{
            data: { fill: Colors.light.hereworksYellow800 },
          }}
        />
      )}
      {props.compareToggled && props.hideOccupancy !== false && (
        <VictoryAxis
          name="axis2"
          fixLabelOverlap={true}
          tickValues={tickVals2}
          tickFormat={(value, index, array) => hideRepeatedSuffix(index, array, xArray2)}
          domainPadding={{ x: [0, 0], y: 20 }}
          style={{
            axis: { stroke: 'none' },
          }}
          tickLabelComponent={
            <VictoryLabel
              dx={-6}
              dy={32}
              style={{
                fill: Colors.light.hereworksYellow500,
                textAnchor: 'start',
                fontSize: 14,
                strokeWidth: 0,
                fontFamily: processFontFamily('Poppins_400Regular'),
              }}
            />
          }
        />
      )}
    </VictoryChart>
  );
};
const styles = StyleSheet.create({
  tooltipTitle: {
    color: Colors.light.hereworksBlack400,
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    marginLeft: 8,
  },
  tooltipValue: {
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    marginLeft: 2,
  },
});

export default BarWithLineGraphMobile;
