import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LockIcon = (props: SvgProps) => (
  <Svg width="10" height="12" viewBox="0 0 10 12" fill="none" {...props}>
    <Path d="M7.28571 4.33333V3.22222C7.28571 2.63285 7.0449 2.06762 6.61624 1.65087C6.18759 1.23413 5.60621 1 5 1C4.39379 1 3.81241 1.23413 3.38376 1.65087C2.9551 2.06762 2.71429 2.63285 2.71429 3.22222V4.33333M8.42857 11H1.57143C1.41988 11 1.27453 10.9415 1.16737 10.8373C1.0602 10.7331 1 10.5918 1 10.4444V4.88889C1 4.74155 1.0602 4.60024 1.16737 4.49605C1.27453 4.39187 1.41988 4.33333 1.57143 4.33333H8.42857C8.58012 4.33333 8.72547 4.39187 8.83263 4.49605C8.9398 4.60024 9 4.74155 9 4.88889V10.4444C9 10.5918 8.9398 10.7331 8.83263 10.8373C8.72547 10.9415 8.58012 11 8.42857 11Z" stroke="#003A70" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>

);

export default LockIcon;
