import React, { useState } from 'react';
import {
  Animated,
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import * as Analytics from 'expo-firebase-analytics';
import { ActivityIndicator } from 'react-native-paper';
import Colors from '../../constants/Colors';
import DownArrow24 from '../24pxIcons/DownArrow24';
import EditIcon from '../EditIcon';
import MonitorIcon from '../MonitorIcon';
import UserRowMobile from './UserRowMobile';
import UserRowWeb from './UserRowWeb';
import GroupEditModal from './GroupEditModal';
import NotificationGroupCard from './NotificationGroupCard';
import getUsers from '../../requests/GetUsers';
import getNotificationGroups from '../../requests/GetNotificationGroups';
import AlertModal from '../AlertModal';

export default function UserGroupCard({
  title,
  description,
  groupId,
  userCount,
  allUsers,
  updateUsers,
  searchValue,
}: {
  title: string;
  description: string;
  groupId: string;
  userCount: number;
  allUsers: object[];
  updateUsers(): void;
  searchValue: string;
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [membersSelected, setMembersSelected] = useState(true);
  const [users, setUsers] = useState([]);
  const [notificationGroups, setNotificationGroups] = useState([]);
  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });
  const [usersLoading, setUsersLoading] = useState(true);
  const renderMobile = (): boolean => Platform.OS !== 'web' || dimensions.window?.width < 500 || dimensions.window?.height < 500;
  const [groupTitle, setGroupTitle] = useState(title);
  const [groupDesc, setGroupDesc] = useState(description);
  const [count, setCount] = useState(userCount);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');

  React.useEffect(() => {
    if (drawerOpen) {
      getUsers(groupId).then((response) => {
        if (response.Users?.length > 0) {
          setUsers(response.Users);
        }
        setUsersLoading(false);
      });

      getNotificationGroups(groupId).then((response) => {
        if (response.Prototypes?.length > 0) {
          setNotificationGroups(response.Prototypes);
        }
      });
    }
  }, [drawerOpen, usersLoading]);

  const showAlert = (newTitle: string, error: boolean = true) => {
    setAlertTitle(newTitle);
    setAlertError(error);
    setAlertVisible(true);
  };

  const renderUserIcon = (imageSource, facilityManager = false) => {
    if (imageSource === undefined) {
      return null;
    }
    return (
      <View
        style={{
          width: 48,
          height: 48,
          backgroundColor: facilityManager ? '#fff' : 'transparent',
          borderWidth: 1.5,
          borderColor: facilityManager ? Colors.light.hereworksBlue500 : 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 24,
          marginLeft: 14,
        }}
      >
        <Image
          style={styles.userPic}
          source={{
            uri: imageSource,
          }}
        />
      </View>
    );
  };

  const updatedGroup = (newTitle, newDesc, newCount) => {
    setModalVisible(false);
    setUsersLoading(true);
    setGroupTitle(newTitle);
    setGroupDesc(newDesc);
    setCount(newCount);
    setUsers([]);
    setUsersLoading(true);
    updateUsers();
    showAlert('Group saved successfully!', false);
  };

  const editGroupButton = () => (
    <TouchableOpacity
      style={{
        borderWidth: 1,
        borderColor: Colors.light.hereworksBlue500,
        borderRadius: 4,
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: renderMobile() ? 20 : 24,
        marginBottom: 24,
        marginTop: renderMobile() ? 0 : 24,
      }}
      onPress={() => {
        setModalVisible(true);
        Analytics.logEvent('button_press', {
          screen: 'UserMgmt',
          name: 'Edit group',
        });
      }}
    >
      <GroupEditModal
        modalVisible={modalVisible}
        mobile={renderMobile()}
        setModalVisible={setModalVisible}
        users={allUsers}
        title={groupTitle}
        description={groupDesc}
        groupId={groupId}
        updatedGroup={updatedGroup}
      />
      <Text
        style={{
          marginLeft: 24,
          marginRight: 8,
          marginVertical: 8,
          fontFamily: 'Roboto_400Regular',
          fontSize: 12,
          lineHeight: 20,
          color: Colors.light.hereworksBlue500,
        }}
      >
        Edit group
      </Text>
      <EditIcon style={{ marginRight: 24 }} />
    </TouchableOpacity>
  );

  return (
    <View style={[styles.groupCard, { marginHorizontal: renderMobile() ? 24 : 40 }]}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View
          style={{
            backgroundColor: Colors.light.hereworksDarkBlue,
            width: 48,
            height: 48,
            borderRadius: 24,
            marginLeft: 20,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <MonitorIcon color={'#fff'} />
        </View>
        <View style={{ flex: 1, marginVertical: 17 }}>
          <Text style={styles.groupName}>{groupTitle}</Text>
          <Text style={styles.groupDescription}>{groupDesc}</Text>
        </View>
        {!renderMobile() && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: 72,
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              {users.map((user, key) => (
                <View key={key}>{renderUserIcon(user.icon, user.facilityManager)}</View>
              ))}
              <View
                style={{
                  width: 48,
                  height: 48,
                  backgroundColor: 'transparent',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 8,
                }}
              >
                <View
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    backgroundColor: Colors.light.hereworksBlue50,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: 'Roboto_400Regular',
                      color: Colors.light.hereworksDarkBlue,
                    }}
                  >
                    {count ?? 0}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
        <TouchableOpacity
          style={{ position: 'absolute', right: 20 }}
          onPress={() => {
            setDrawerOpen(!drawerOpen);
            Analytics.logEvent('button_press', {
              screen: 'UserMgmt',
              name: `Drawer ${drawerOpen ? 'Close' : 'Open'}`,
            });
          }}
        >
          <DownArrow24
            style={[
              drawerOpen
                ? { transform: [{ rotateZ: '180deg' }] }
                : { transform: [{ rotateZ: '0deg' }] },
            ]}
          />
        </TouchableOpacity>
      </View>
      {renderMobile() && <View>{editGroupButton()}</View>}
      <Animated.View style={{ backgroundColor: 'transparent' }}>
        {drawerOpen && (
          <Animatable.View animation={'fadeIn'}>
            {usersLoading && <ActivityIndicator size={'large'} />}
            {!renderMobile() && (
              <View>
                <View
                  style={{
                    height: 1,
                    backgroundColor: Colors.light.hereworksWhite500,
                    marginBottom: 10,
                  }}
                />
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={styles.title}>{membersSelected ? 'Members' : 'Notifications'}</Text>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginRight: 24,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        backgroundColor: membersSelected ? Colors.light.hereworksBlue500 : '#fff',
                        borderColor: membersSelected
                          ? Colors.light.hereworksBlue500
                          : Colors.light.hereworksBlue300,
                        borderWidth: 1,
                        marginRight: 8,
                        paddingHorizontal: 12,
                        paddingVertical: 4,
                        borderRadius: 4,
                      }}
                      onPress={() => {
                        if (!membersSelected) {
                          setMembersSelected(true);
                        }
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'Roboto_500Medium',
                          fontSize: 12,
                          lineHeight: 20,
                          color: membersSelected ? '#fff' : Colors.light.hereworksBlue400,
                        }}
                      >
                        MEMBERS
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        backgroundColor: !membersSelected ? Colors.light.hereworksBlue500 : '#fff',
                        borderColor: !membersSelected
                          ? Colors.light.hereworksBlue500
                          : Colors.light.hereworksBlue300,
                        borderWidth: 1,
                        marginRight: 8,
                        paddingHorizontal: 12,
                        paddingVertical: 4,
                        borderRadius: 4,
                      }}
                      onPress={() => {
                        if (membersSelected) {
                          setMembersSelected(false);
                        }
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'Roboto_500Medium',
                          fontSize: 12,
                          lineHeight: 20,
                          color: !membersSelected ? '#fff' : Colors.light.hereworksBlue400,
                        }}
                      >
                        NOTIFICATIONS
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                {membersSelected && (
                  <View
                    style={{
                      flexDirection: 'row',
                      marginVertical: 12,
                      alignSelf: 'stretch',
                    }}
                  >
                    <Text style={[styles.headerText, { marginLeft: 40, flex: 1 }]}>MEMBER</Text>
                    <Text style={[styles.headerText, { marginLeft: 40, flex: 1 }]}>CONTACTS</Text>
                    <View style={{ flex: 1 }}>
                      <Text
                        style={[
                          styles.headerText,
                          {
                            alignSelf: 'flex-end',
                            marginRight: 28,
                          },
                        ]}
                      >
                        LAST ACTIVITY
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            )}
            {membersSelected
              && users
                // eslint-disable-next-line max-len
                ?.filter((user) => user.FullName?.toLowerCase().includes(searchValue.toLowerCase()))
                .map((user, userKey) => (
                  <View
                    key={userKey}
                    style={{
                      borderTopColor: Colors.light.hereworksWhite300,
                      borderTopWidth: renderMobile() ? 1 : 0,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        marginVertical: 2,
                        marginHorizontal: 24,
                        alignItems: 'center',
                        backgroundColor:
                          user.facilityManager && !renderMobile()
                            ? Colors.light.hereworksWhite100
                            : '#fff',
                        borderRadius: 10,
                      }}
                    >
                      {renderMobile() && (
                        <UserRowMobile
                          user={user}
                          groupName={groupTitle}
                          deleteAction={() => setUsers(users.filter((i) => i !== user))}
                        />
                      )}
                      {!renderMobile() && <UserRowWeb user={user} groupName={groupTitle} />}
                    </View>
                  </View>
                ))}
            {!renderMobile() && !membersSelected && (
              <View style={{ marginVertical: 20 }}>
                <ScrollView contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 24 }}>
                  {notificationGroups.map((notificationGroup) => {
                    let match = false;
                    notificationGroup.Issues.forEach((issue) => {
                      if (issue.IsRelatedToUserGroup) {
                        match = true;
                      }
                    });
                    if (!match) {
                      return null;
                    }
                    return (
                      <View key={`${notificationGroup.Id}`}>
                        <NotificationGroupCard
                          title={notificationGroup.Name}
                          issues={notificationGroup.Issues}
                        />
                      </View>
                    );
                  })}
                </ScrollView>
              </View>
            )}
            {!renderMobile() && membersSelected && editGroupButton()}
          </Animatable.View>
        )}
      </Animated.View>
      <AlertModal
        modalVisible={alertVisible}
        error={alertError}
        title={alertTitle}
        setModalVisible={setAlertVisible}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  groupCard: {
    backgroundColor: '#fff',
    alignSelf: 'stretch',
    marginVertical: 8,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: Colors.light.hereworksWhite300,
  },
  groupName: {
    fontSize: 18,
    fontFamily: 'Poppins_600SemiBold',
    marginLeft: 12,
    flex: 1,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Poppins_500Medium>',
    marginLeft: 24,
    color: Colors.light.hereworksBlack500,
  },
  userPic: {
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: 'hidden',
  },
  headerText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    lineHeight: 15,
    letterSpacing: 0.7,
    color: Colors.light.hereworksBlack400,
  },
  groupDescription: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    color: Colors.light.hereworksBlack600,
    marginLeft: 12,
    flex: 1,
  },
});
