import React, { ReactElement, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import * as Clipboard from 'expo-clipboard';
import Moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Modal from 'react-native-modal';
import QRCode from 'react-native-qrcode-svg';
import { FontAwesome } from '@expo/vector-icons';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../../constants/Colors';
import AddEditNote from '../AddEditNote';
import ZoomIcon from '../24pxIcons/ZoomIcon';
import CopyIcon from '../24pxIcons/CopyIcon';
import OrionArrowIcon from '../24pxIcons/OrionArrowIcon';
import EditIcon24 from '../24pxIcons/EditIcon24';
import BinIcon24 from '../24pxIcons/BinIcon24';
import EditAssetModal from './EditAssetModal';
import getAssetTickets from '../../requests/GetAssetTickets';
import AlertModal from '../AlertModal';
import retireAsset from '../../requests/RetireAsset';

export type PressableState = Readonly<{
  hovered?: boolean;
}>;

export default function AssetCardMobile(props) {
  const [loading, setLoading] = useState(false);
  const [switchToggled] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [qrCodeSelected, setQrCodeSelected] = useState(true);
  const [addNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [editNote, setEditNote] = useState(false);
  const [domain, setDomain] = useState('https://hereworks.tech/');
  const [editAssetVisible, setEditAssetVisible] = useState(false);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const [ticketCounts, setTicketCounts] = useState([0, 0, 0, 0]);
  const [name, setName] = useState(props.instance.Name ?? '');
  const [model, setModel] = useState(props.instance.Model ?? '');
  const [serial, setSerial] = useState(props.instance.Serial ?? '');
  const [manufacturer, setManufacturer] = useState(props.instance.Manufacturer ?? '');
  const [description, setDescription] = useState(props.instance.Description ?? '');
  const [template, setTemplate] = useState(props.instance.ButtonPrototypeName ?? '');
  const [building, setBuilding] = useState(props.instance.LocationBuilding ?? '');
  const [area, setArea] = useState(props.instance.LocationArea ?? '');
  const [floor, setFloor] = useState(props.instance.LocationFloor ?? '');
  const [room, setRoom] = useState(props.instance.LocationRoom ?? '');
  const [locationId, setLocationId] = useState(props.instance.LocationId ?? '');
  const [id] = useState(props.instance.ButtonInstanceId);
  const [loadingImage, setLoadingImage] = useState(true);
  const [customFields, setCustomFields] = useState(
    props.instance.CustomFields?.sort((previous, next) => {
      if (previous.CustomName < next.CustomName) {
        return -1;
      }
      if (previous.CustomName > next.CustomName) {
        return 1;
      }
      return 0;
    }),
  );
  const [image, setImage] = useState(
    props.instance.Images.length > 0 ? `${props.instance.Images[0]?.url}?time=${Date.now()}` : null,
  );

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertAction, setAlertAction] = useState(null);
  const [alertActionTitle, setAlertActionTitle] = useState('');

  const showAlert = (
    title: string,
    message?: string | ReactElement,
    error: boolean = true,
    action: () => void = null,
    actionTitle: string = '',
  ) => {
    setAlertError(error);
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertVisible(true);
    setAlertAction(() => action);
    setAlertActionTitle(actionTitle);
  };

  // eslint-disable-next-line global-require
  const Placeholder = require('../../assets/images/placeholder.png');

  AsyncStorage.getItem('environment').then((result) => {
    if (result === 'dev') {
      setDomain('https://dev.hereworks.tech/');
    } else if (result === 'demo') {
      setDomain('https://demo.hereworks.tech/');
    }
  });

  useEffect(() => {
    getAssetTickets(props.instance.ButtonInstanceId).then((response) => {
      const values = response.Tickets;
      setTicketCounts([values.day, values.week, values.month, values.year]);
    });
  }, []);

  const [notes] = useState(props.instance.Notes ?? []);

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'EditAssetModal',
      name: title,
    });
  };

  const renderNotes = () => (
    <View
      style={{
        borderColor: Colors.light.hereworksBlue200,
        borderLeftWidth: 1,
        paddingHorizontal: 24,
        flex: 1,
      }}
    >
      <AddEditNote
        modalVisible={addNoteModalVisible}
        notes={notes}
        setVisible={() => setAddNoteModalVisible(false)}
        setEditNote={() => setEditNote(true)}
        selectedNote={selectedNote}
        editNote={editNote}
        mobile={true}
        assetId={props.instance.ButtonInstanceId}
        name={props.name}
        refresh={props.refresh}
      />
      <View style={{ flex: 1, marginTop: 16, width: '100%' }}>
        {notes.map((note, key) => (
          <TouchableOpacity
            key={key}
            style={{
              justifyContent: 'center',
              marginVertical: 4,
            }}
            onPress={() => {
              analytics('Select note');
              setSelectedNote(note);
              setEditNote(false);
              setAddNoteModalVisible(!addNoteModalVisible);
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View
                style={{
                  backgroundColor: Colors.light.hereworksDarkBlue,
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  marginRight: 8,
                }}
              />
              <Text style={styles.noteTitleText} numberOfLines={1} ellipsizeMode="tail">
                {note.Title}
              </Text>
              <Text style={styles.noteDateText}>{Moment(note.UpdatedOn).format('DD.MM.YYYY')}</Text>
            </View>
            <Text style={styles.noteNameText}>{`by ${note.name_value}`}</Text>
          </TouchableOpacity>
        ))}
      </View>
      <TouchableOpacity
        style={{
          marginBottom: 16,
          backgroundColor: '#fff',
          borderRadius: 4,
          borderColor: Colors.light.hereworksBlue500,
          borderWidth: 1,
          alignSelf: 'flex-start',
        }}
        onPress={() => {
          analytics('Add note');
          setEditNote(false);
          setSelectedNote(null);
          setAddNoteModalVisible(!addNoteModalVisible);
        }}
      >
        <Text style={[styles.secondaryButtonText, { color: Colors.light.hereworksBlue500 }]}>
          Add note
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderTabs = () => (
    <View
      style={{
        justifyContent: 'flex-end',
        borderColor: Colors.light.hereworksBlue100,
        borderBottomWidth: 1,
      }}
    >
      <ScrollView
        horizontal={true}
        contentContainerStyle={{
          alignSelf: 'flex-end',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
        }}
      >
        <TouchableOpacity
          style={{
            backgroundColor: selectedTab === 0 ? Colors.light.hereworksBlue500 : '#fff',
            borderRadius: 4,
            borderColor: Colors.light.hereworksBlue500,
            borderWidth: 1,
            zIndex: 2,
            marginLeft: 16,
            marginVertical: 12,
          }}
          onPress={() => {
            analytics('Asset Info');
            setSelectedTab(0);
          }}
        >
          <Text
            style={[
              styles.secondaryButtonText,
              {
                color: selectedTab === 0 ? '#fff' : Colors.light.hereworksBlue500,
              },
            ]}
          >
            ASSET INFO
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.tabButton,
            {
              backgroundColor: selectedTab === 1 ? Colors.light.hereworksBlue500 : '#fff',
            },
          ]}
          onPress={() => {
            analytics('Asset Tags');
            setSelectedTab(1);
          }}
        >
          <Text
            style={[
              styles.secondaryButtonText,
              {
                color: selectedTab === 1 ? '#fff' : Colors.light.hereworksBlue500,
              },
            ]}
          >
            ASSET TAGS
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.tabButton,
            {
              backgroundColor: selectedTab === 2 ? Colors.light.hereworksBlue500 : '#fff',
            },
          ]}
          onPress={() => {
            analytics('Location');
            setSelectedTab(2);
          }}
        >
          <Text
            style={[
              styles.secondaryButtonText,
              { color: selectedTab === 2 ? '#fff' : Colors.light.hereworksBlue500 },
            ]}
          >
            LOCATION
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.tabButton,
            {
              backgroundColor: selectedTab === 3 ? Colors.light.hereworksBlue500 : '#fff',
            },
          ]}
          onPress={() => {
            analytics('Additional Info');
            setSelectedTab(3);
          }}
        >
          <Text
            style={[
              styles.secondaryButtonText,
              { color: selectedTab === 3 ? '#fff' : Colors.light.hereworksBlue500 },
            ]}
          >
            ADDITIONAL INFO
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.tabButton,
            {
              backgroundColor: selectedTab === 4 ? Colors.light.hereworksBlue500 : '#fff',
            },
          ]}
          onPress={() => {
            analytics('Notes');
            setSelectedTab(4);
          }}
        >
          <Text
            style={[
              styles.secondaryButtonText,
              { color: selectedTab === 4 ? '#fff' : Colors.light.hereworksBlue500 },
            ]}
          >
            NOTES
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.tabButton,
            {
              backgroundColor: selectedTab === 5 ? Colors.light.hereworksBlue500 : '#fff',
            },
          ]}
          onPress={() => {
            analytics('Tickets');
            setSelectedTab(5);
          }}
        >
          <Text
            style={[
              styles.secondaryButtonText,
              { color: selectedTab === 5 ? '#fff' : Colors.light.hereworksBlue500 },
            ]}
          >
            TICKETS
          </Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );

  const renderMainView = () => (
    <View
      style={{
        flex: 1,
        borderTopWidth: 1,
        borderColor: Colors.light.hereworksBlue100,
      }}
    >
      <Modal
        isVisible={modalVisible}
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        onBackButtonPress={() => setModalVisible(false)}
        useNativeDriver={true}
      >
        <TouchableOpacity
          onPress={() => {
            analytics('Modal Image');
            setModalVisible(!modalVisible);
          }}
          style={{ height: '100%', flex: 1 }}
        >
          <Image
            key={Date.now()}
            source={
              image === null
                ? Placeholder
                : {
                  uri: image,
                }
            }
            resizeMode={'center'}
            style={{ width: undefined, height: '100%' }}
          />
        </TouchableOpacity>
      </Modal>
      {renderTabs()}
      <View style={{ flex: 1 }}>
        {selectedTab === 0 && (
          <View>
            <View style={{ marginLeft: 24, marginTop: 16, flexDirection: 'row' }}>
              <Pressable
                style={{ marginBottom: 12, marginRight: 12 }}
                onPress={() => {
                  analytics('Thumbnail Image');
                  setModalVisible(true);
                }}
                disabled={image === null}
              >
                {({ hovered }: PressableState): ReactElement => (
                  <View
                    style={{
                      opacity: hovered ? 0.5 : 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Image
                      key={Date.now()}
                      source={
                        image === null
                          ? Placeholder
                          : {
                            uri: image,
                          }
                      }
                      resizeMode={'cover'}
                      onLoadEnd={() => setLoadingImage(false)}
                      style={{ width: 125, height: 76 }}
                    />
                    {loadingImage && <ActivityIndicator style={{ position: 'absolute' }} />}
                    {image !== null && (
                      <ZoomIcon
                        style={{
                          position: 'absolute',
                          bottom: 4,
                          right: 4,
                          borderRadius: 3,
                          backgroundColor: hovered
                            ? Colors.light.hereworksBlue500
                            : `${Colors.light.hereworksBlack300}05`,
                        }}
                      />
                    )}
                  </View>
                )}
              </Pressable>
              <View>
                <Text style={styles.mainDescTextMobile}>Serial number</Text>
                <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
                  {serial}
                </Text>
                <Text style={styles.mainDescTextMobile}>Model number</Text>
                <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
                  {model}
                </Text>
              </View>
            </View>
            <View style={{ marginLeft: 24, marginTop: 16 }}>
              <Text style={styles.mainDescTextMobile}>Description</Text>
              <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
                {description}
              </Text>
              <Text style={styles.mainDescTextMobile}>Asset ID</Text>
              <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
                {props.instance.ButtonInstanceId}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.mainDescTextMobile}>Manufacturer</Text>
                  <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
                    {manufacturer}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.mainDescTextMobile}>Template</Text>
                  <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
                    {template}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
        {selectedTab === 1 && (
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                marginVertical: 14,
                marginHorizontal: 14,
                borderWidth: 1,
                borderRadius: 12,
                borderColor: Colors.light.hereworksBlue200,
                backgroundColor: '#fff',
                flex: 1,
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                <View
                  style={{
                    margin: 12,
                    borderWidth: 1,
                    borderRadius: 12,
                    borderColor: Colors.light.hereworksBlue200,
                    flexDirection: 'row',
                    flex: 1,
                  }}
                >
                  <View
                    style={{
                      margin: 12,
                      padding: 12,
                      borderWidth: 1,
                      borderRadius: 8,
                      borderColor: Colors.light.hereworksBlue200,
                      width: 90,
                      alignItems: 'center',
                    }}
                  >
                    {qrCodeSelected && (
                      <QRCode
                        value={`${`${domain}${props.instance.ButtonInstanceId}`}?ss=QR`}
                        size={50}
                      />
                    )}
                    {!qrCodeSelected && <FontAwesome name={'wifi'} size={50} />}
                  </View>
                  <View style={{ justifyContent: 'center', marginLeft: 14 }}>
                    <Text style={styles.activatedText}>
                      {`Status: ${switchToggled ? 'ACTIVE' : 'INACTIVE'}`}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  borderColor: Colors.light.hereworksBlue200,
                  borderTopWidth: 1,
                  paddingHorizontal: 8,
                  paddingVertical: 12,
                }}
              >
                <Text style={styles.activatedText}>GENERATED QR LINK</Text>
                <TouchableOpacity
                  style={{
                    borderColor: Colors.light.hereworksBlue100,
                    borderWidth: 1,
                    borderRadius: 5,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    analytics('Copy Asset URL');
                    const url: string = `${`${domain}${props.instance.ButtonInstanceId}`}?ss=${qrCodeSelected ? 'QR' : 'NFC'}`;
                    Clipboard.setStringAsync(url);
                    showAlert(
                      'Copied to clipboard!',
                      url,
                      false,
                      null,
                      null,
                    );
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'Roboto_400Regular',
                      color: Colors.light.hereworksBlack600,
                      fontSize: 14,
                      padding: 8,
                      marginRight: 30,
                    }}
                    numberOfLines={1}
                    >{`${`${domain}${props.instance.ButtonInstanceId}`}?ss=${
                      qrCodeSelected ? 'QR' : 'NFC'
                    }`}</Text>
                  <CopyIcon style={{ position: 'absolute', right: 4 }} />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingHorizontal: 12,
                  paddingTop: 8,
                  marginBottom: 12,
                  borderTopWidth: 1,
                  borderColor: Colors.light.hereworksBlue100,
                }}
              >
                <TouchableOpacity
                  style={styles.circleButton}
                  onPress={() => {
                    setQrCodeSelected(!qrCodeSelected);
                  }}
                >
                  <OrionArrowIcon />
                </TouchableOpacity>
                <Text
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    fontFamily: 'Roboto_500Medium',
                    fontSize: 12,
                    color: Colors.light.hereworksBlue400,
                  }}
                >
                  {qrCodeSelected ? 'QR CODE' : 'NFC TAG'}
                </Text>
                <TouchableOpacity
                  style={styles.circleButton}
                  onPress={() => {
                    setQrCodeSelected(!qrCodeSelected);
                  }}
                >
                  <OrionArrowIcon style={{ transform: [{ rotateZ: '180deg' }] }} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
        {selectedTab === 2 && (
          <View style={{ marginLeft: 24, marginTop: 16 }}>
            <Text style={styles.mainDescTextMobile}>Building</Text>
            <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
              {building}
            </Text>
            <Text style={styles.mainDescTextMobile}>Floor</Text>
            <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
              {floor}
            </Text>
            <Text style={styles.mainDescTextMobile}>Room</Text>
            <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
              {room}
            </Text>
            {props.instance.CreatedOn && (
              <>
                <Text style={styles.mainDescTextMobile}>Date added</Text>
                <Text style={styles.mainValueTextMobile}>
                  {Moment(props.instance.CreatedOn).format('DD.MM.YYYY')}
                </Text>
              </>
            )}
          </View>
        )}
        {selectedTab === 3 && (
          <View style={{ marginLeft: 24, marginTop: 16 }}>
            {
              // eslint-disable-next-line max-len
              customFields?.map((field, index) => {
                if (
                  field.CustomName.toLowerCase().includes('serial')
                  || field.CustomName.toLowerCase().includes('manu')
                  || field.CustomName.toLowerCase().includes('desc')
                  || field.CustomName.toLowerCase().includes('model')
                  || field.CustomName.toLowerCase().includes('building')
                  || field.CustomName.toLowerCase().includes('floor')
                  || field.CustomName.toLowerCase().includes('room')
                  || field.CustomName.toLowerCase() === 'name'
                ) {
                  return null;
                }

                let title = field.CustomName;
                if (title.includes('Date')) {
                  title = `${title.split('Date')[0]} Date`;
                } else if (title.includes('date')) {
                  title = `${title.split('date')[0]} Date]`;
                }
                if (title.includes('Name')) {
                  title = `${title.split('Name')[0]} Name`;
                } else if (title.includes('name')) {
                  title = `${title.split('name')[0]} Name`;
                }
                if (title.includes('Number')) {
                  title = `${title.split('Number')[0]} Number`;
                } else if (title.includes('number')) {
                  title = `${title.split('number')[0]} Number`;
                }
                if (title.includes('Address')) {
                  title = `${title.split('Address')[0]} Address ${title.split('Address')[1]}`;
                } else if (title.includes('address')) {
                  title = `${title.split('address')[0]} Address ${title.split('address')[1]}`;
                }
                return (
                  <View key={index}>
                    <Text style={styles.mainDescTextMobile} numberOfLines={1} ellipsizeMode="tail">
                      {title}
                    </Text>
                    <Text style={styles.mainValueTextMobile} numberOfLines={1} ellipsizeMode="tail">
                      {field.CustomValue}
                    </Text>
                  </View>
                );
              })
            }
          </View>
        )}
        {selectedTab === 4 && renderNotes()}
        {selectedTab === 5 && (
          <View style={{ marginLeft: 24, marginTop: 16 }}>
            <Text style={styles.mainDescTextMobile}>No tickets</Text>
          </View>
        )}
      </View>
    </View>
  );

  const TicketsRaisedFilterButton = (label: string, index: number) => (
    <TouchableOpacity
      key={`ticketsRaisedFilterButton_${index}`}
      style={[
        styles.ticketsCountFilterButton,
        selectedFilterIndex === index && styles.ticketsCountSelectedButton,
      ]}
      onPress={() => setSelectedFilterIndex(index)}
    >
      <Text
        style={[
          styles.ticketsCountFilterButtonText,
          selectedFilterIndex === index && styles.ticketsCountSelectedText,
        ]}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {label}
      </Text>
    </TouchableOpacity>
  );

  return (
    <ScrollView>
      <>
        <View style={styles.container}>
          <View
            style={{
              width: 17,
              height: '100%',
              zIndex: 1,
              backgroundColor:
                props.selected ?? switchToggled
                  ? Colors.light.hereworksGreen400
                  : Colors.light.hereworksWhite600,
            }}
          />
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.assetTitleSub}>Asset's Name</Text>
                <Text style={styles.assetTitleMobile} numberOfLines={1} ellipsizeMode="tail">
                  {name}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <TouchableOpacity
                  style={[
                    styles.editDeleteButton,
                    {
                      borderColor: Colors.light.hereworksBlue200,
                      marginRight: 8,
                    },
                  ]}
                  onPress={() => {
                    setEditAssetVisible(true);
                  }}
                >
                  <EditIcon24 color={Colors.light.hereworksBlue300} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.editDeleteButton,
                    {
                      borderColor: Colors.light.hereworksRed300,
                      marginRight: 16,
                    },
                  ]}
                  onPress={() => {
                    showAlert(
                      'Delete the asset?',
                      <Text>
                        Do you want to delete the asset - <Text style={styles.alertAssetTitle}>
                          {name}
                        </Text>?</Text>,
                      true,
                      () => {
                        setLoading(true);
                        retireAsset(props.instance.ButtonInstanceId).then((response) => {
                          setLoading(false);
                          if (response?.isError) {
                            showAlert('Error', response.errorText);
                          } else {
                            showAlert(
                              'Success',
                              'Asset deleted successfully.',
                              false,
                              () => props.deleteAsset(id),
                            );
                          }
                        }).catch(() => setLoading(false));
                      },
                      'Delete',
                    );
                  }}
                >
                  <BinIcon24 color={Colors.light.hereworksRed300} />
                </TouchableOpacity>
              </View>
            </View>
            {renderMainView()}
          </View>
          <EditAssetModal
            mobile={true}
            asset={props.instance}
            visible={editAssetVisible}
            setModalVisible={setEditAssetVisible}
            name={name}
            model={model}
            serial={serial}
            manufacturer={manufacturer}
            description={description}
            template={template}
            building={building}
            area={area}
            floor={floor}
            room={room}
            locationId={locationId}
            customFields={customFields}
            updateCustomFields={(fields) => setCustomFields(fields)}
            updateName={(text) => setName(text)}
            updateModel={(text) => setModel(text)}
            updateSerial={(text) => setSerial(text)}
            updateManufacturer={(text) => setManufacturer(text)}
            updateDescription={(text) => setDescription(text)}
            updateTemplate={(text) => setTemplate(text)}
            updateBuilding={(text) => setBuilding(text)}
            updateArea={(text) => setArea(text)}
            updateFloor={(text) => setFloor(text)}
            updateRoom={(text) => setRoom(text)}
            updateLocationId={(text) => setLocationId(text)}
            updateImage={(newImage) => setImage(newImage)}
            id={id}
            updateAsset={props.updateAsset}
          />
        </View>
        <View style={styles.ticketsAgainstAssetContainer}>
          <Text style={styles.ticketsRaisedText}>Tickets raised against asset</Text>
          <View style={styles.ticketsRaisedMainSection}>
            <View style={styles.ticketsCountContainer}>
              <Text style={styles.ticketsCountValue}>{ticketCounts[selectedFilterIndex]}</Text>
              <Text style={styles.ticketsCountLabel}>Tickets</Text>
            </View>
            <View style={styles.ticketsCountFilterSection}>
              {['DAY', 'WEEK', 'MONTH', 'YEAR'].map((value, index) => TicketsRaisedFilterButton(value, index))}
            </View>
          </View>
        </View>
        {loading && <ActivityIndicator size='large' style={styles.loadingIndicator} />}
        <AlertModal modalVisible={alertVisible}
          error={alertError}
          title={alertTitle}
          action={alertAction}
          actionTitle={alertActionTitle}
          message={alertMessage}
          setModalVisible={setAlertVisible} />
      </>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    marginVertical: 16,
    marginHorizontal: 24,
    borderRadius: 12,
    overflow: 'hidden',
    minHeight: 340,
    backgroundColor: Colors.light.hereworksWhite100,
  },
  noteTitleText: {
    flex: 1,
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 14,
    color: Colors.light.hereworksBlack600,
  },
  noteDateText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 14,
    color: Colors.light.hereworksBlack400,
  },
  noteNameText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 11,
    lineHeight: 17,
    color: Colors.light.hereworksBlack400,
    marginTop: 2,
  },
  mainDescTextMobile: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 14,
    marginBottom: 4,
    color: Colors.light.hereworksBlack400,
  },
  assetTitleMobile: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    lineHeight: 24,
    marginLeft: 24,
    marginBottom: 8,
    marginRight: 8,
    color: Colors.light.hereworksBlack600,
  },
  mainValueTextMobile: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 14,
    color: Colors.light.hereworksBlack600,
    marginBottom: 12,
    paddingEnd: 16,
  },
  secondaryButtonText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
    height: 20,
    marginVertical: 4,
    marginHorizontal: 12,
  },
  activatedText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 11,
    lineHeight: 13,
    marginBottom: 4,
    marginRight: 8,
    color: Colors.light.hereworksBlack400,
  },
  tabButton: {
    borderRadius: 4,
    borderColor: Colors.light.hereworksBlue500,
    borderWidth: 1,
    zIndex: 2,
    marginLeft: 8,
    marginVertical: 12,
  },
  editDeleteButton: {
    borderColor: Colors.light.hereworksRed300,
    borderRadius: 4,
    borderWidth: 1,
    height: 32,
    width: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleButton: {
    borderRadius: 16,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: Colors.light.hereworksBlue500,
    borderWidth: 1,
  },
  assetTitleSub: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 12,
    lineHeight: 18,
    marginTop: 12,
    marginLeft: 24,
    color: Colors.light.hereworksBlack400,
  },
  assetTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 18,
    lineHeight: 27,
    marginLeft: 24,
    marginBottom: 12,
    color: Colors.light.hereworksBlack600,
  },
  ticketsAgainstAssetContainer: {
    flex: 1,
    borderRadius: 12,
    backgroundColor: Colors.light.hereworksWhite100,
    marginHorizontal: 24,
  },
  ticketsRaisedText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    lineHeight: 24,
    color: Colors.light.hereworksBlack600,
    padding: 16,
  },
  ticketsRaisedMainSection: {
    flexDirection: 'row',
    backgroundColor: Colors.light.hereworksBlue500,
    borderBottomStartRadius: 12,
    borderBottomEndRadius: 12,
  },
  ticketsCountContainer: {
    flexDirection: 'row',
    width: '40%',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'flex-end',
  },
  ticketsCountValue: {
    fontSize: 34,
    lineHeight: 42,
    fontFamily: 'Poppins_600SemiBold',
    color: '#fff',
    paddingTop: 2,
    marginEnd: 4,
  },
  ticketsCountLabel: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    lineHeight: 16,
    paddingBottom: 8,
    color: 'white',
  },
  ticketsCountFilterSection: {
    flex: 1,
    backgroundColor: Colors.light.hereworksBlue400,
    borderBottomEndRadius: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  ticketsCountFilterButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    margin: 4,
  },
  ticketsCountFilterButtonText: {
    alignSelf: 'center',
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingTop: 8,
    paddingBottom: 6,
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    lineHeight: 14,
    color: 'white',
  },
  ticketsCountSelectedButton: {
    backgroundColor: 'white',
  },
  ticketsCountSelectedText: {
    color: Colors.light.hereworksBlue400,
  },
  alertAssetTitle: {
    fontFamily: 'Roboto_700Bold',
  },
  loadingIndicator: {
    position: 'absolute',
    top: '40%',
    alignSelf: 'center',
  },
});
