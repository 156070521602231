import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const InfoIcon = (props: SvgProps) => (
  <Svg width={30} height={30} fill="none" {...props}>
    <Path
      d="M15 26.25c6.213 0 11.25-5.037 11.25-11.25S21.213 3.75 15 3.75 3.75 8.787 3.75 15 8.787 26.25 15 26.25Z"
      stroke="#121B23"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.063 14.063H15v6.562h.938"
      stroke="#121B23"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="M14.86 11.438a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" fill="#121B23" />
  </Svg>
);

export default InfoIcon;
