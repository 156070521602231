/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  StyleSheet, Text, ScrollView, TouchableOpacity, View,
} from 'react-native';
import Moment from 'moment';
import * as Analytics from 'expo-firebase-analytics';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Colors from '../constants/Colors';
import { RootTabScreenProps } from '../types';
import CompareArrowsIcon from '../components/CompareArrowsIcon';
import SelectUtility from '../components/SelectUtility';
import BarWithLineGraphWeb from '../graphs/BarWithLineGraphWeb';
import TotalBarGraphWeb from '../graphs/TotalBarGraphWeb';
import CompareLineGraphWeb from '../graphs/CompareLineGraphWeb';
import GetUtilitiesMetricByDate from '../requests/GetUtilitiesMetricByDate';
import TopSection from '../components/TopSection';
import BackgroundImage from '../components/BackgroundImage';
import DropdownSelector from '../components/DropdownSelector';
import getSensors from '../requests/GetSensors';
import getClientDetails from '../requests/GetClientDetails';

export default function EnergyScreen({
  // eslint-disable-next-line no-unused-vars
  navigation,
}: RootTabScreenProps<'Energy'>) {
  const [range, setRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: Moment.utc().subtract(6, 'days').startOf('day').toDate(), end: Moment.utc().endOf('day').toDate() });

  const [compareRange, setCompareRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: null, end: null });

  const onConfirm = React.useCallback(
    (start, end) => {
      setRange({ start, end });
      // console.log(range);
    },
    [setRange],
  );

  function pad(n) {
    return n < 10 ? `0${n}` : n;
  }

  const toDate = (dateString) => {
    const dateobj = new Date(dateString);
    const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
    return hourLabel;
  };

  const [electricityTotal, setElectricityTotal] = useState(0);
  const [gasTotal, setGasTotal] = useState(0);
  const [waterTotal, setWaterTotal] = useState(0);
  const [emissionsTotal, setEmissionsTotal] = useState(0);

  const [electricityCompareTotal, setElectricityCompareTotal] = useState(0);
  const [gasCompareTotal, setGasCompareTotal] = useState(0);
  const [waterCompareTotal, setWaterCompareTotal] = useState(0);
  const [emissionsCompareTotal, setEmissionsCompareTotal] = useState(0);

  const [pointsCount, setPointsCount] = useState(1);
  const [comparePointsCount, setComparePointsCount] = useState(0);
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [meterList, setMeterList] = useState([]);
  const [client, setClient] = useState('hereworks');
  const [electricityTarget, setElectricityTarget] = useState(105);
  const [waterTarget, setWaterTarget] = useState(115);
  const [gasTarget, setGasTarget] = useState(280);
  const [co2Target, setCo2Target] = useState(40);

  const showTargets = false;

  const electricityData = [];
  const gasData = [];
  const waterData = [];
  const emissionsData = [];

  const electricityCompareData = [];
  const gasCompareData = [];
  const waterCompareData = [];
  const emissionsCompareData = [];

  AsyncStorage.getItem('clientName').then((c) => {
    setClient(c);
  });

  const indexButtonData = [
    {
      value: 'Occupancy',
      metric: 'data-peoplecount',
      indexName: 'hw-rawdata-buildingoccupancy',
    },
    { value: 'Electricity', metric: 'data-unitsconsumed', indexName: 'hw-processeddata-electricity' },
    { value: 'Water', metric: 'happy_metric_water_raw_value', indexName: 'happyscore_flat_permin' },
    { value: 'Gas', metric: 'happy_metric_gas_raw_value', indexName: 'happyscore_flat_permin' },
  ];

  // Reading Data when the time frame is changed
  useEffect(() => {
    GetUtilitiesMetricByDate(
      range,
      selectedMeter?.value ?? null,
    ).then((data) => {
      if (data?.length > 0 && data[0]) {
        // prettier-ignore
        // ELECTRICITY
        data[0]?.map((e) => {
          const date = toDate(e.key_as_string);
          const point = { day: '', electricity: 0 };
          point.day = date;
          point.electricity = e.score.value
            ? Math.floor(e.score.value * 10) / 10 : 0;
          electricityData.push(point);

          const emissionsPoint = {
            day: date,
            emissions: (point.electricity * 179) / 1000,
          };

          emissionsData.push(emissionsPoint);
        });
        // GAS
        data[1]?.map((e, idx) => {
          const date = toDate(e.key_as_string);
          const point = {
            day: date,
            gas: e.score.value ? e.score.value : 0,
          };
          if (idx > 0) {
            gasData.push(point);
          }
        });
        // WATER
        data[2]?.map((e, idx) => {
          const date = toDate(e.key_as_string);
          const point = {
            day: date,
            water: e.score.value ? e.score.value : 0,
          };
          if (idx > 0) {
            waterData.push(point);
          }
        });
        let electricitySum = 0;
        electricityData.forEach((point) => {
          electricitySum += point.electricity;
        });
        if (selectedMeter === null) {
          setElectricityTotal(Math.floor(electricitySum * 10) / 10);
        }
        let gasSum = 0;
        const startDate = Moment(range.start);
        const endDate = Moment(range.end);
        setPointsCount(endDate.diff(startDate, 'days') + 1);
        gasData.forEach((point) => {
          gasSum += point.gas;
        });
        setGasTotal(gasSum);
        let waterSum = 0;
        waterData.forEach((point) => {
          waterSum += point.water;
        });
        setWaterTotal(waterSum);
        let emissionsSum = 0;
        emissionsData.forEach((point) => {
          emissionsSum += point.emissions;
        });
        setEmissionsTotal(Math.round(100 * emissionsSum) / 100);
      }
    });
  }, [range, selectedMeter, client]);

  // Reading Data when the time frame is changed
  useEffect(() => {
    GetUtilitiesMetricByDate(
      compareRange,
      selectedMeter?.value ?? null,
    ).then((data) => {
      if (data?.length > 0 && data[0] !== undefined) {
        // prettier-ignore
        // ELECTRICITY
        data[0]?.map((e) => {
          const date = toDate(e.key_as_string);
          const point = { day: '', electricity: 0 };
          point.day = date;
          point.electricity = e.score.value
            ? Math.floor(
              e.score.value * 10,
            ) / 10
            : 0;
          electricityCompareData.push(point);

          const emissionsPoint = {
            day: date,
            emissions: (point.electricity * 179) / 1000,
          };

          emissionsCompareData.push(emissionsPoint);
        });
        // GAS
        data[1]?.map((e, idx) => {
          const date = toDate(e.key_as_string);
          const point = {
            day: date,
            gas: e.score.value ? e.score.value : 0,
          };
          if (idx > 0) {
            gasCompareData.push(point);
          }
        });
        // WATER
        data[2]?.map((e, idx) => {
          const date = toDate(e.key_as_string);
          const point = {
            day: date,
            water: e.score.value ? e.score.value : 0,
          };
          if (idx > 0) {
            waterCompareData.push(point);
          }
        });
        let electricitySum = 0;
        setComparePointsCount(electricityCompareData?.length);
        electricityCompareData.forEach((point) => {
          electricitySum += point.electricity;
        });
        if (selectedMeter === null) {
          setElectricityCompareTotal(Math.floor(electricitySum * 10) / 10);
        }
        let gasSum = 0;
        setComparePointsCount(gasCompareData?.length);
        gasCompareData.forEach((point) => {
          gasSum += point.gas;
        });
        setGasCompareTotal(gasSum);
        let waterSum = 0;
        waterCompareData.forEach((point) => {
          waterSum += point.water;
        });
        setWaterCompareTotal(waterSum);
        let emissionsSum = 0;
        emissionsCompareData.forEach((point) => {
          emissionsSum += point.emissions;
        });
        setEmissionsCompareTotal(Math.floor(emissionsSum));
      }
    });
  }, [compareRange, selectedMeter, client]);

  useEffect(() => {
    getSensors().then((response) => {
      if (response?.sensors?.length > 0) {
        const newMeters = [];
        newMeters.push({ label: 'All meters', value: null });
        response?.sensors.forEach((sensor) => {
          if (sensor.type?.toLowerCase().includes('hw-electrical-meter-mastercard')) {
            // TODO: setting label to ID for now, should be .name
            newMeters.push({ label: sensor.id, value: sensor.id });
          }
        });
        setMeterList(newMeters);
      }
    });
  }, []);

  useEffect(() => {
    getClientDetails().then((response) => {
      (response?.clientDetails?.building_daily_targets ?? []).forEach((item) => {
        if (item.name === 'electricity') {
          setElectricityTarget(item.target);
        } else if (item.name === 'water') {
          setWaterTarget(item.target);
        } else if (item.name === 'gas') {
          setGasTarget(item.target);
        } else if (item.name === 'co2') {
          setCo2Target(item.target);
        }
      });
    });
  });

  const [utility, setUtility] = useState('Occupancy');
  const [metric, setMetric] = useState(null);
  const [prevMetric, setPrevMetric] = useState(null);
  const [prevValue, setPrevValue] = useState('Electricity');
  const [utilityGraphVisible, setUtilityGraphVisible] = useState(true);
  const [waterGraphVisible, setWaterGraphVisible] = useState(true);
  const [elecGraphVisible, setElecGraphVisible] = useState(true);
  const [gasGraphVisible, setGasGraphVisible] = useState(true);
  const [emissionsGraphVisible, setEmissionsGraphVisible] = useState(true);

  const selectUtility = (
    selectedUtility: string,
    selectedMetric: string,
    prevOption: string,
    prevVal: string,
  ) => {
    setUtility(selectedUtility);
    setMetric(selectedMetric);
    setPrevMetric(prevOption);
    setPrevValue(prevVal);
  };

  const onCompareConfirm = React.useCallback(
    (start, end) => {
      setCompareRange({ start, end });
    },
    [setCompareRange],
  );

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'EnergyScreen',
      name: title,
    });
  };

  const compareDatesView = () => (
    <View
      style={{
        marginLeft: 24,
        flexDirection: 'row',
        backgroundColor: '#fff',
      }}
    >
      <Text
        style={{
          color: Colors.light.hereworksBlack600,
          fontSize: 12,
          fontFamily: 'Roboto_500Medium',
        }}
      >
        {Moment(range.start).format('D MMM')} {' - '}{' '}
        {Moment(range.end ?? range.start).format('D MMM')}
      </Text>
      {compareRange.start && (
        <>
          <CompareArrowsIcon style={{ marginHorizontal: 10 }} />
          <Text
            style={{
              color: Colors.light.hereworksYellow500,
              fontSize: 12,
              fontFamily: 'Roboto_500Medium',
            }}
          >
            {Moment(compareRange.start).format('D MMM')} {' - '}{' '}
            {Moment(compareRange.end ?? compareRange.start).format('D MMM')}
          </Text>
        </>
      )}
    </View>
  );

  const renderGraphInsightsButtons = (graphVisible, setGraphVisible) => (
    <View
      style={{
        flexDirection: 'row',
        marginVertical: 24,
        position: 'absolute',
        right: 24,
        top: 0,
        backgroundColor: 'transparent',
      }}
    >
      <TouchableOpacity
        style={{
          backgroundColor: graphVisible ? Colors.light.hereworksLightBlue : '#fff',
          borderRadius: 4,
          marginRight: 8,
          borderWidth: 1,
          borderColor: graphVisible ? '#fff' : Colors.light.hereworksLightBlue,
        }}
        onPress={() => {
          analytics('GRAPH');
          if (!graphVisible) {
            setGraphVisible(!graphVisible);
          }
        }}
      >
        <Text
          style={{
            color: graphVisible ? '#fff' : Colors.light.hereworksLightBlue,
            fontFamily: 'Roboto_500Medium',
            fontSize: 12,
            marginVertical: 8,
            marginHorizontal: 12,
          }}
        >
          GRAPH
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          backgroundColor: graphVisible ? '#fff' : Colors.light.hereworksLightBlue,
          borderRadius: 4,
          borderWidth: 1,
          borderColor: graphVisible ? Colors.light.hereworksLightBlue : '#fff',
        }}
        onPress={() => {
          analytics('INSIGHTS');
          if (graphVisible) {
            setGraphVisible(!graphVisible);
          }
        }}
      >
        <Text
          style={{
            color: graphVisible ? Colors.light.hereworksLightBlue : '#fff',
            fontFamily: 'Roboto_500Medium',
            fontSize: 12,
            marginVertical: 8,
            marginHorizontal: 12,
          }}
        >
          INSIGHTS
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderInsights = () => (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: '#fff',
        flex: 2,
        margin: 18,
      }}
    >
      <View style={{ backgroundColor: '#fff', flex: 1 }}>
        <View style={styles.insightsTile}>
          <Text style={styles.insightsHeader}>Insight 1</Text>
          <Text style={styles.insightDesc}>Data not available</Text>
        </View>
        <View style={styles.insightsTile}>
          <Text style={styles.insightsHeader}>Insight 2</Text>
          <Text style={styles.insightDesc}>Data not available</Text>
        </View>
      </View>
      <View style={{ backgroundColor: '#fff', flex: 1 }}>
        <View style={styles.insightsTile}>
          <Text style={styles.insightsHeader}>Insight 3</Text>
          <Text style={styles.insightDesc}>Data not available</Text>
        </View>
        <View style={styles.insightsTile}>
          <Text style={styles.insightsHeader}>Insight 4</Text>
          <Text style={styles.insightDesc}>Data not available</Text>
        </View>
      </View>
    </View>
  );

  const renderTotalWidget = (
    title,
    total,
    compareTotal,
    unit,
    target,
    compareTarget,
    descA,
    descB,
  ) => (
    <View style={styles.graphCard}>
      <Text style={styles.cardHeader}>{title}</Text>
      {compareDatesView()}
      <View style={{ flexDirection: 'row', backgroundColor: 'transparent' }}>
        <View style={{ backgroundColor: 'transparent', flexDirection: 'row' }}>
          <View style={{ backgroundColor: 'transparent' }}>
            <Text
              style={{
                marginLeft: 24,
                marginTop: 27,
                fontSize: 14,
                lineHeight: 22,
                fontFamily: 'Roboto_400Regular',
                color: Colors.light.hereworksBlack400,
              }}
            >
              Target
            </Text>
            <View
              style={{
                flexDirection: 'row',
                backgroundColor: 'transparent',
                marginLeft: 24,
                alignItems: 'flex-end',
              }}
            >
              <Text
                style={{
                  fontSize: 34,
                  fontFamily: 'Poppins_600SemiBold',
                  color: Colors.light.hereworksDarkBlue,
                }}
              >
                {target === 0 ? 100 : target}
              </Text>
              <Text style={{ marginBottom: 8, marginLeft: 4 }}>{unit}</Text>
            </View>
          </View>
          <View style={{ backgroundColor: 'transparent' }}>
            <Text
              style={{
                marginLeft: 24,
                marginTop: 27,
                fontSize: 14,
                lineHeight: 22,
                fontFamily: 'Roboto_400Regular',
                color: Colors.light.hereworksBlack400,
              }}
            >
              Period total
            </Text>
            <View
              style={{
                flexDirection: 'row',
                backgroundColor: 'transparent',
                marginLeft: 24,
                alignItems: 'flex-end',
              }}
            >
              <Text
                style={{
                  fontSize: 34,
                  fontFamily: 'Poppins_600SemiBold',
                  color:
                    total < target ? Colors.light.hereworksGreen : Colors.light.hereworksRed500,
                }}
              >
                {Math.floor(total)}
              </Text>
              <Text style={{ marginBottom: 8, marginLeft: 4 }}>{unit}</Text>
            </View>
          </View>
        </View>
        {compareRange.end !== null && (
          <View style={{ backgroundColor: 'transparent', flexDirection: 'row' }}>
            <View style={{ backgroundColor: 'transparent' }}>
              <Text
                style={{
                  marginLeft: 24,
                  marginTop: 27,
                  fontSize: 14,
                  lineHeight: 22,
                  fontFamily: 'Roboto_400Regular',
                  color: Colors.light.hereworksBlack400,
                }}
              >
                Target
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  backgroundColor: 'transparent',
                  marginLeft: 24,
                  alignItems: 'flex-end',
                }}
              >
                <Text
                  style={{
                    fontSize: 34,
                    fontFamily: 'Poppins_600SemiBold',
                    color: Colors.light.hereworksYellow600,
                  }}
                >
                  {compareTarget}
                </Text>
                <Text style={{ marginBottom: 8, marginLeft: 4 }}>{unit}</Text>
              </View>
            </View>
            <View style={{ backgroundColor: 'transparent' }}>
              <Text
                style={{
                  marginLeft: 24,
                  marginTop: 27,
                  fontSize: 14,
                  lineHeight: 22,
                  fontFamily: 'Roboto_400Regular',
                  color: Colors.light.hereworksBlack400,
                }}
              >
                Period total
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  backgroundColor: 'transparent',
                  marginLeft: 24,
                  alignItems: 'flex-end',
                }}
              >
                <Text
                  style={{
                    fontSize: 34,
                    fontFamily: 'Poppins_600SemiBold',
                    color:
                      compareTotal < compareTarget
                        ? Colors.light.hereworksGreen
                        : Colors.light.hereworksRed500,
                  }}
                >
                  {Math.floor(compareTotal)}
                </Text>
                <Text style={{ marginBottom: 8, marginLeft: 4 }}>{unit}</Text>
              </View>
            </View>
          </View>
        )}
      </View>
      <View style={{ flexDirection: 'row', backgroundColor: 'transparent' }}>
        <TotalBarGraphWeb
          compareToggled={compareRange.end !== null}
          total={total}
          target={target}
          compareTotal={compareTotal}
        />
        <View
          style={{
            width: 518,
            backgroundColor: 'transparent',
            marginRight: 20,
            marginBottom: 20,
            zIndex: -2,
          }}
        >
          <Text style={styles.totalDesc}>{descA}</Text>
          <Text style={styles.totalDesc}>{descB}</Text>
        </View>
      </View>
    </View>
  );

  const renderTargets = () => (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: 'transparent',
        borderColor: Colors.light.hereworksWhite500,
        borderTopWidth: 1.5,
        borderBottomWidth: 1.5,
        paddingVertical: 12,
        marginHorizontal: 24,
        marginBottom: 12,
      }}
    >
      <View
        style={{
          backgroundColor: 'transparent',
          flex: 1,
          borderColor: Colors.light.hereworksWhite500,
          borderRightWidth: 1.5,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            marginBottom: 8,
            backgroundColor: 'transparent',
            alignItems: 'center',
          }}
        >
          <View style={[styles.circleDot, { backgroundColor: Colors.light.hereworksGreen300 }]} />
          <Text style={styles.targetTitles}>Ideal</Text>
          <Text style={[styles.targetTitles, { flex: 1, textAlign: 'right', marginRight: 32 }]}>
            {'< 2400 kWh'}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: 'transparent',
            alignItems: 'center',
          }}
        >
          <View style={[styles.circleDot, { backgroundColor: Colors.light.hereworksBlue500 }]} />
          <Text style={styles.targetTitles}>Good</Text>
          <Text style={[styles.targetTitles, { flex: 1, textAlign: 'right', marginRight: 32 }]}>
            {'2400-2500 kWh'}
          </Text>
        </View>
      </View>
      <View style={{ backgroundColor: 'transparent', flex: 1, marginLeft: 32 }}>
        <View
          style={{
            flexDirection: 'row',
            marginBottom: 8,
            backgroundColor: 'transparent',
            alignItems: 'center',
          }}
        >
          <View style={[styles.circleDot, { backgroundColor: Colors.light.hereworksYellow800 }]} />
          <Text style={styles.targetTitles}>Tolerable</Text>
          <Text style={[styles.targetTitles, { flex: 1, textAlign: 'right' }]}>
            {'2500-2700 kWh'}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: 'transparent',
            alignItems: 'center',
          }}
        >
          <View style={[styles.circleDot, { backgroundColor: Colors.light.hereworksRed500 }]} />
          <Text style={styles.targetTitles}>Bad</Text>
          <Text style={[styles.targetTitles, { flex: 1, textAlign: 'right' }]}>
            {'2700-3000 kWh'}
          </Text>
        </View>
      </View>
    </View>
  );

  const topSectionProps = {
    range,
    compareRange,
    onCompareConfirm,
    onConfirm,
    title: 'Energy',
  };

  return (
    <ScrollView style={{ alignSelf: 'stretch', zIndex: -1 }}>
      <View style={styles.container}>
        <BackgroundImage />
        <TopSection {...topSectionProps}>
          <View style={{ marginHorizontal: -30 }}>
            <CompareLineGraphWeb
              dates={range}
              compareDates={compareRange}
              metricName="happy_group_energy_usage_local_happyscore_percent"
              title="Energy"
              interval="24h"
              compareToggled={compareRange.end !== null}
            />
          </View>
        </TopSection>
        <View style={styles.content}>
          <View style={styles.graphCard}>
            {renderGraphInsightsButtons(utilityGraphVisible, setUtilityGraphVisible)}
            <Text style={styles.cardHeader}>Utility vs occupancy</Text>
            {compareDatesView()}
            {utilityGraphVisible && (
              <>
                <Text
                  style={{
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 22,
                    color: Colors.light.hereworksPrimaryGrey,
                    marginLeft: 24,
                    marginTop: 18,
                    marginBottom: 4,
                  }}
                >
                  Overall utility
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto_500Medium',
                    fontSize: 12,
                    color: Colors.light.hereworksBlack400,
                    marginLeft: 24,
                    marginTop: 4,
                    marginBottom: 4,
                  }}
                >
                  You can choose only two parameters at once
                </Text>
                <View
                  style={{ backgroundColor: 'transparent', marginVertical: 12, marginLeft: 20 }}
                >
                  <SelectUtility
                    data={indexButtonData}
                    compareToggled={compareRange.end !== null}
                    onSelect={(value, metricValue, prevOption, prevVal) => {
                      selectUtility(value, metricValue, prevOption, prevVal);
                    }}
                  />
                </View>
                <BarWithLineGraphWeb
                  dates={range}
                  compareDates={compareRange}
                  metricName={metric ?? 'data-peoplecount'}
                  title="Occupancy vs. Utilities"
                  interval="24h"
                  compareToggled={compareRange.end !== null}
                  selectedUtility={utility}
                  prevMetric={prevMetric ?? 'data-unitsconsumed'}
                  prevValue={prevValue}
                />
                {showTargets && renderTargets()}
              </>
            )}
            {!utilityGraphVisible && renderInsights()}
          </View>
          <Text style={styles.sectionHeading}>Water</Text>
          {renderTotalWidget(
            'Water consumption',
            waterTotal,
            waterCompareTotal,
            'litres',
            pointsCount * waterTarget,
            comparePointsCount * waterTarget,
            'Water Consumption is measured by a water meter as the utility enters the building.\nWater consumption is generally closely related to the occupancy of the building so keep an eye on the correlation between water usage and the number of people in the building to spot anomalies.',
            'Report leaking taps, toilets and showers to the maintenance department. Do not leave any equipment that uses water running when not in use. Put on dishwashers with a full load and do not overfill kettles. Only use hot water when necessary to save energy.',
          )}
          <View style={styles.graphCard}>
            <Text style={styles.cardHeader}>Water</Text>
            {renderGraphInsightsButtons(waterGraphVisible, setWaterGraphVisible)}
            {compareDatesView()}
            {waterGraphVisible && (
              <CompareLineGraphWeb
                dates={range}
                compareDates={compareRange}
                metricName="happy_metric_water_raw_value"
                title="Water"
                interval="24h"
                compareToggled={compareRange.end !== null}
                unit='litres'
              />
            )}
            {!waterGraphVisible && renderInsights()}
          </View>
          {renderTotalWidget(
            'Electricity overall usage',
            electricityTotal,
            electricityCompareTotal,
            'kWh',
            pointsCount * electricityTarget,
            comparePointsCount * electricityTarget,
            'Electricity is measured by smart meters. Meters can be placed on the main incoming supply to the building or area. Depending on how the electricity is distributed in the building, meters can also be placed per floor or per service (Lighting, General Services HVAC etc.)',
            'Switching off equipment when not in use is the key to saving on Electricity Usage. Air Conditioning generally uses the most electricity in offices. Appropriate Lighting Controls and LED fittings are best practice. Use the eco setting on all appliances. Ensure Audio Visual Equipment (Displays & Projectors) are switched off when not in use. For example, The power-saving mode on a photocopier can save up to 92% of its typical energy use.',
          )}
          <View style={styles.graphCard}>
            <Text style={styles.cardHeader}>Electricity</Text>
            {renderGraphInsightsButtons(elecGraphVisible, setElecGraphVisible)}
            {compareDatesView()}
            {elecGraphVisible && (
              <>
                <View style={{ margin: 24 }}>
                  <DropdownSelector
                    label={'All meters'}
                    selected={selectedMeter}
                    setSelected={setSelectedMeter}
                    onSelect={(item: any) => {
                      setSelectedMeter(item);
                    }}
                    dataDrop={meterList}
                  />
                </View>
                <CompareLineGraphWeb
                  dates={range}
                  compareDates={compareRange}
                  metricName={'data-unitsconsumed'}
                  indexName="hw-processeddata-electricity"
                  title="Electricity"
                  interval="24h"
                  thingId={selectedMeter?.value}
                  compareToggled={compareRange.end !== null}
                  unit="kW h"
                />
              </>
            )}
            {!elecGraphVisible && renderInsights()}
          </View>
          {renderTotalWidget(
            'Gas overall usage',
            gasTotal,
            gasCompareTotal,
            'litres',
            pointsCount * gasTarget,
            comparePointsCount * gasTarget,
            'Gas usage is measured by Gas meters as the utility enters the building.',
            'Keep windows and doors closed when the heating is on. Report areas that are too warm or too cold to the maintenance team. Report any defective windows and doors. Do not block radiators and heaters.',
          )}
          <View style={styles.graphCard}>
            <Text style={styles.cardHeader}>Gas</Text>
            {renderGraphInsightsButtons(gasGraphVisible, setGasGraphVisible)}
            {compareDatesView()}
            {gasGraphVisible && (
              <CompareLineGraphWeb
                dates={range}
                compareDates={compareRange}
                metricName="happy_metric_gas_raw_value"
                title="Gas"
                interval="24h"
                compareToggled={compareRange.end !== null}
                unit='litres'
              />
            )}
            {!gasGraphVisible && renderInsights()}
          </View>
          {renderTotalWidget(
            'CO2 emissions overall usage',
            emissionsTotal,
            emissionsCompareTotal,
            'kg',
            Math.round(pointsCount * co2Target * 10) / 10,
            Math.round(comparePointsCount * co2Target * 10) / 10,
            'CO2 (Carbon Dioxide) emissions are calculated by combining all of the CO2 producing sources in the building (Gas, Electricity, Oil etc) and multiplying by the number of kg’s of CO2 produced per unit. For electricity the average Fuel Mix is used (provide by your electricity supplier) to calculate how many kg’s of CO2 are produced per kw/hr.',
            'Reducing your use of Electricity, Gas and Oil will reduce your CO2 emissions. CO2 emissions can also be offset by using renewable energy sources such as solar & wind.',
          )}
          <View style={styles.graphCard}>
            <Text style={styles.cardHeader}>CO2 emissions</Text>
            {renderGraphInsightsButtons(emissionsGraphVisible, setEmissionsGraphVisible)}
            {compareDatesView()}
            {emissionsGraphVisible && (
              <CompareLineGraphWeb
                dates={range}
                compareDates={compareRange}
                metricName="emissions"
                title="CO2 emissions"
                interval="24h"
                compareToggled={compareRange.end !== null}
                unit="kg"
              />
            )}
            {!emissionsGraphVisible && renderInsights()}
          </View>
          {/* <View style={styles.graphCard}>
              <UtilitiesGraph />
            </View> */}
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    backgroundColor: Colors.light.hereworksBackground,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
  },
  content: {
    backgroundColor: 'transparent', // Colors.light.hereworksBackground,
    paddingHorizontal: 24,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  graphCard: {
    marginVertical: 40,
    marginEnd: 16,
    borderRadius: 24,
    backgroundColor: 'white',
    maxWidth: 1164,
  },
  sectionHeading: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 22,
    color: Colors.light.hereworksPrimaryGrey,
  },
  legendTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    color: Colors.light.hereworksBlack500,
    marginLeft: 24,
  },
  targetTitles: {
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
  },
  circleDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 8,
  },
  insightsTile: {
    backgroundColor: Colors.light.hereworksWhite100,
    borderRadius: 16,
    margin: 6,
  },
  insightsHeader: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksBlack600,
    margin: 16,
  },
  insightDesc: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    lineHeight: 18,
    color: Colors.light.hereworksBlack600,
    marginHorizontal: 16,
    marginBottom: 50,
  },
  totalDesc: {
    backgroundColor: Colors.light.hereworksWhite100,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue200,
    borderRadius: 16,
    padding: 16,
    margin: 4,
  },
  cardHeader: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 18,
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 24,
    marginTop: 27,
    marginBottom: 13,
  },
});
