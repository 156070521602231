import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PenIcon16 = (props: SvgProps) => (
  <Svg
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <Path
      stroke={props.color ?? '#fff'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.42 12.58c-.263.263-.395.395-.445.547a.667.667 0 0 0 0 .412c.05.153.182.285.446.549l.491.491c.264.264.396.396.549.446a.667.667 0 0 0 .412 0c.152-.05.284-.182.548-.446l3.158-3.158c.264-.264.396-.396.446-.548a.667.667 0 0 0 0-.412c-.05-.153-.182-.285-.446-.549l-.491-.491c-.264-.264-.396-.396-.549-.446a.666.666 0 0 0-.135-.028c-.05-.006.075.008-.04.022a2.198 2.198 0 0 0-.293.083.647.647 0 0 0-.165.095l-.24.186-.866-4.332c-.048-.242-.072-.363-.131-.461a.666.666 0 0 0-.208-.217c-.097-.063-.216-.092-.456-.15L2 2m0 0 2.174 9.005c.057.24.086.36.15.456.054.085.129.156.216.208.098.059.22.083.461.131l4.332.867M2 2l6.667 6.667m0 0v.754l.666-.754h-.666Z"
    />
  </Svg>
);
export default PenIcon16;
