import AsyncStorage from '@react-native-async-storage/async-storage';
import { Issue } from '../components/AssetManagement/AddTemplateModal/IssueRow';
import { Field } from '../components/AssetManagement/AddTemplateModal/FieldsList';

const updateTemplate = async (
  Name: string,
  GroupId: string,
  GroupName: string,
  Fields: Field[],
  issues: Issue[],
  id?: string,
) => {
  const clientName = await AsyncStorage.getItem('clientName');
  const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
  const jwtToken = await AsyncStorage.getItem('token');

  // eslint-disable-next-line no-undef
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('clientid', clientName ?? 'hereworks');
  requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('jwt', jwtToken ?? '');

  const Issues: Issue[] = [...issues];

  const body: any = {
    Name, GroupId, GroupName, Issues, Fields,
  };
  if (id) {
    body.ButtonPrototypeId = id;
  }

  return fetch(
    `https://${apiDomain}/button/buttonprototypes`,
    {
      method: id ? 'PUT' : 'POST',
      headers: requestHeaders,
      body: JSON.stringify(body),
    },
  ).then((res) => (res ? res.json().then((json) => json) : null));
};

export default updateTemplate;
