import AsyncStorage from '@react-native-async-storage/async-storage';
/* eslint-disable import/prefer-default-export */
export const GetMetricAverage = async (
  metric: string,
  dates: { start: Date; end: Date },
  index: string = 'happyscore_flat_permin',
  thingId: string = null,
  thingRoom: string = null,
  thingFloor: string = null,
) => {
  const clientName = await AsyncStorage.getItem('clientName');
  const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
  const jwtToken = await AsyncStorage.getItem('token');

  const dateRange = dates;

  let filter: object = thingId ? {
    match: {
      'thing-id.keyword': thingId,
    },
  } : null;
  filter = filter ?? (thingRoom ? {
    wildcard: {
      'thing-room.keyword': { value: `*${thingRoom}*` },
    },
  } : null);
  filter = filter ?? (thingFloor ? {
    match: {
      'thing-floor.keyword': thingFloor,
    },
  } : null);

  // eslint-disable-next-line no-undef
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('clientid', clientName ?? 'hereworks');
  requestHeaders.set('jwt', jwtToken ?? '');
  requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
  requestHeaders.set('Content-Type', 'application/json');

  return fetch(
    `https://${apiDomain}/button/oscomms`,
    {
      headers: requestHeaders,
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        size: 0,
        index,
        query: {
          bool: {
            must: {
              range: {
                timestamp: {
                  gte: dateRange.start == null ? 'now-7d/d' : dateRange.start,
                  lte: dateRange.end == null ? 'now/d' : dateRange.end,
                },
              },
            },
            filter,
          },
        },
        aggs: {
          average: {
            avg: {
              field: metric,
            },
          },
        },
      }),
    },
  ).then((res) => (res ? res.json()
    .then((json) => json?.aggregations?.average?.value) : null)).catch(() => null);
};
