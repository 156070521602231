import React from 'react';
import {
  View, StyleSheet, Text, ScrollView,
} from 'react-native';
import Colors from '../../../constants/Colors';

interface MetricInfoCardProps {
  title: string,
  description: string,
}

const MetricInfoCard = (props: MetricInfoCardProps) => <View style={styles.metricInfoCard}>
  <Text style={styles.metricInfoCardTitle}>{props.title}</Text>
  <Text style={styles.metricInfoCardDescription}>{props.description}</Text>
</View>;

const MetricInfoCardSection = () => <ScrollView horizontal>
  <View style={styles.metricInfoCardColumn}>
    <MetricInfoCard title='PM2.5' description='PM2.5 is a grouping of particles with a diameter of 2.5µm or less. PM2.5’s small size allows it to bypass many of our body’s defenses. Unlike larger (and more visible) dust particles, PM2.5 can permeate membranous tissue and travel into the bloodstream and lungs. Short term exposure has been linked to throat irritation, coughing, and difficulty breathing. More serious, long-term health effects can include respiratory problems, heart disease, and cancer.' />
    <MetricInfoCard title='Noise' description='Sound Pressure is measured in dB. All spaces have some degree of ambient background noise from building services and exterior & interior sources. When the sum of these noise sources exceeds comfortable levels, the space may not function as intended. For instance, elevated levels of background noise can diminish the perception of spoken word, which reduces critical listening ability and task performance. Studies indicate that employees are unable to habituate to noise in office environments over time and office noise can create stress and disrupt performance on more complex cognitive tasks.' />
  </View>
  <View style={styles.metricInfoCardColumn}>
    <MetricInfoCard title='CO2' description='Indoor exposure to excessive carbon dioxide levels can impair performance and decision-making. At levels above 1000ppm, which is not uncommon indoors, you will start to experience fatigue, sleepiness and may struggle to concentrate. With prolonged exposure above these levels you may develop a headache and feel physically uncomfortable. One of the largest sources of carbon dioxide in an office building is our bodies breathing.' />
    <MetricInfoCard title='TVOC' description={'TVOC - Total Volatile Organic Compounds\nVOCs are molecules that evaporate at normal indoor atmospheric conditions, many of which are irritating or carcinogenic, or can cause headaches, fatigue, nausea, or allergic reactions sources of harmful VOCs include paints and solvents, cleaners and disinfectants, pesticides, and air fresheners'} />
  </View>
</ScrollView>;

const styles = StyleSheet.create({
  metricInfoCardColumn: {
    marginEnd: 12,
    marginBottom: 6,
  },
  metricInfoCard: {
    flex: 1,
    width: 600,
    borderRadius: 16,
    padding: 16,
    marginBottom: 12,
    backgroundColor: Colors.light.hereworksWhite100,
  },
  metricInfoCardTitle: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksBlack600,
    marginBottom: 16,
  },
  metricInfoCardDescription: {
    fontSize: 12,
    lineHeight: 18,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack500,
  },
});

export default MetricInfoCardSection;
