import { processFontFamily } from 'expo-font';
import React, { useEffect, useState } from 'react';
import {
  Platform, StyleSheet, Text, View,
} from 'react-native';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory-native';
import Colors from '../constants/Colors';

const ChildrenBarGraph = (props) => {
  const [graphContainerWidth, setGraphContainerWidth] = useState(400);
  const [tickVals, setTickVals] = useState([]);

  useEffect(() => {
    let length = 20;
    if (props.assets.length > 0) {
      length = props.assets.length;
    }
    const tickArray = [...Array(length).keys()];
    setTickVals(tickArray);
  }, [props.compareAssets, props.assets]);

  class CustomFlyout extends React.Component {
    render() {
      // @ts-ignore
      const { x, y, datum } = this.props;
      this.state = { tooltipWidth: 165 };
      // @ts-ignore
      const left = Math.min(x + 24, graphContainerWidth - this.state.tooltipWidth - 20);
      const top = Math.max(10, Math.min(y - 150, Platform.OS === 'ios' ? 135 : 95));
      const instance = props.instances?.filter((i) => i.ButtonInstanceId === datum.id)[0];
      const name = instance?.Name.slice(0, 14) ?? '';
      const id = instance?.ButtonInstanceId.slice(0, 12) ?? '-';
      const template = instance?.ButtonGroupName ?? '-';
      const floor = instance?.LocationFloor ?? '-';
      const room = instance?.LocationRoom ?? '-';

      return (
        <View onLayout={(e) => this.setState({ tooltipWidth: e.nativeEvent.layout.width })}
          style={{
            borderRadius: 8,
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            backgroundColor: '#fff',
            left,
            top,
            alignSelf: 'flex-start',
            maxWidth: 165,
            shadowOffset: {
              width: 0,
              height: 12,
            },
            shadowOpacity: 0.1,
            shadowRadius: 40,
            shadowColor: 'black',
            elevation: 5,
          }}
        >
          <View style={{ padding: 0, alignItems: 'flex-start' }}>
            <Text style={styles.tooltipText}>
              {/* @ts-ignore */}
              {name}
            </Text>
            <Text style={styles.tooltipText}>
              {/* @ts-ignore */}
              {`ID: ${id}`}
            </Text>
          </View>
          <View
            style={{
              margin: 8,
              alignSelf: 'stretch',
              borderRadius: 4,
              backgroundColor: Colors.light.hereworksWhite100,
            }}
          >
            <Text style={styles.tooltipText}>
              {/* @ts-ignore */}
              {`Scans: ${datum.value}`}
            </Text>
            <Text style={styles.tooltipText}>
              {/* @ts-ignore */}
              {`Template: ${template}`}
            </Text>
            <Text style={styles.tooltipText}>
              {/* @ts-ignore */}
              {`Floor: ${floor}`}
            </Text>
            <Text style={styles.tooltipText}>
              {/* @ts-ignore */}
              {`Location: ${room}`}
            </Text>
          </View>
        </View>
      );
    }
  }

  return (
    <View onLayout={(e) => setGraphContainerWidth(e.nativeEvent.layout.width)}>
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => datum}
            labelComponent={<CustomFlyout />}
          />
        }
        width={966}
        height={375}
        domain={{ x: [0, 20] }}
        domainPadding={{ x: [20, 20], y: [0, 0] }}
      >
        <VictoryAxis
          disableInlineStyles
          fixLabelOverlap={true}
          dependentAxis
          tickCount={10}
          style={{
            axis: { stroke: 'none' },
            tickLabels: {
              stroke: Colors.light.hereworksBlack400,
              fontFamily: processFontFamily('Poppins_400Regular'),
              fontSize: 14,
              strokeWidth: 0,
            },
            grid: {
              stroke: Colors.light.hereworksWhite100,
              strokeWidth: 2,
              pointerEvents: 'none',
            },
          }}
          tickLabelComponent={<VictoryLabel dx={-16} />}
        />
        <VictoryAxis
          disableInlineStyles
          fixLabelOverlap={true}
          axisLabelComponent={
            <VictoryLabel style={{ stroke: Colors.light.hereworksYellow500, fontSize: 12 }} />
          }
          tickCount={20}
          tickValues={tickVals}
          tickFormat={(t) => t + 1}
          style={{
            axis: { stroke: 'none' },
            tickLabels: {
              stroke: Colors.light.hereworksBlack400,
              fontFamily: processFontFamily('Poppins_400Regular'),
              fontSize: 14,
              strokeWidth: 0,
            },
          }}
        />
        {!props.compareToggled && (
          <VictoryBar
            style={{
              data: {
                // eslint-disable-next-line max-len
                fill: ({ active }) => (active ? Colors.light.hereworksDarkBlue200 : Colors.light.hereworksDarkBlue),
              },
            }}
            x={'name'}
            y={'value'}
            data={props.assets}
            barWidth={33}
            cornerRadius={{ top: 2 }}
          />
        )}
        {props.compareToggled && (
          <VictoryGroup offset={18}>
            <VictoryBar
              style={{ data: { fill: Colors.light.hereworksDarkBlue } }}
              x={'name'}
              y={'value'}
              data={props.assets}
              barWidth={14}
              cornerRadius={{ top: 2 }}
            />
            <VictoryBar
              style={{ data: { fill: Colors.light.hereworksYellow500 } }}
              x={'name'}
              y={'value'}
              data={props.compareAssets}
              barWidth={14}
              cornerRadius={{ top: 2 }}
            />
          </VictoryGroup>
        )}
      </VictoryChart>
    </View>
  );
};

const styles = StyleSheet.create({
  tooltipText: {
    color: Colors.light.hereworksPrimaryGrey800,
    fontFamily: 'Poppins_500Medium',
    fontSize: 12,
    marginLeft: 8,
    marginTop: 4,
  },
});

export default ChildrenBarGraph;
