import * as React from 'react';
import Svg, { SvgProps, Path, Rect } from 'react-native-svg';
import Colors from '../../constants/Colors';

interface IconProps extends SvgProps {
  isSelected?: boolean,
}

const CompletedIcon = (props: IconProps) => (
  <Svg width='43' height='43' viewBox='0 0 43 43' fill='none' {...props}>
    <Rect x='3.5' y='3.5' width='39' height='39' rx='19.5' fill={props.isSelected ? Colors.light.hereworksGreen50 : Colors.light.hereworksGreen500} />
    <Path d='M14.5 23.8158L20.2857 29.5L32.5 17.5' stroke={props.isSelected ? Colors.light.hereworksGreen500 : '#FFF'} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <Rect x='3.5' y='3.5' width='39' height='39' rx='19.5' stroke={props.isSelected ? Colors.light.hereworksGreen100 : Colors.light.hereworksGreen500} />
  </Svg>

);

export default CompletedIcon;
