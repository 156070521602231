import React, { useRef } from 'react';
import { Pressable } from 'react-native';

import { useHover } from 'react-native-web-hooks';
import Colors from '../constants/Colors';

export default function ReportListItem({
  children,
  style,
  onPress,
  isSelected,
  index,
  status,
}: {
  children: any;
  style?: any;
  onPress: any;
  isSelected: boolean;
  index: any;
  status: string;
}) {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <Pressable
      accessibilityRole="link"
      key={index}
      ref={ref}
      style={({ pressed }) => [
        isHovered || pressed
          ? { backgroundColor: Colors.light.hereworksHover }
          : { backgroundColor: '#fff' },
        isSelected && status === 'inprogress' && { backgroundColor: Colors.light.hereworksBlue500 },
        isSelected && status === 'paused' && { backgroundColor: Colors.light.hereworksYellow400 },
        isSelected && status === 'completed' && { backgroundColor: Colors.light.hereworksGreen400 },
        isSelected && (status !== 'inprogress' && status !== 'paused' && status !== 'completed')
        && { backgroundColor: Colors.light.hereworksDarkBlue },
        style,
      ]}
      onPress={onPress}
    >
      {children}
    </Pressable>
  );
}
