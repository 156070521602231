import * as React from 'react';
import Svg, { SvgProps, Path, Rect } from 'react-native-svg';

const FlagIcon = (props: SvgProps) => (
    <Svg width="43" height="43" viewBox="0 0 43 43" fill="none" {...props}>
        <Rect x="3.5" y="3.5" width="39" height="39" rx="19.5" fill="white" />
        <Path d="M15.5 26.5V14.5C15.5 14.5 16.5 13.5 19.5 13.5C22.5 13.5 24.5 15.5 27.5 15.5C30.5 15.5 31.5 14.5 31.5 14.5V26.5C31.5 26.5 30.5 27.5 27.5 27.5C24.5 27.5 22.5 25.5 19.5 25.5C17.8838 25.5 16.848 25.7902 16.2364 26.058C15.7128 26.2872 15.5 26.5 15.5 26.5ZM15.5 26.5V33.5" stroke="#003A70" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <Rect x="3.5" y="3.5" width="39" height="39" rx="19.5" stroke="#B6B9BC" />
    </Svg>

);

export default FlagIcon;
