/* eslint-disable array-callback-return */
import { FontAwesome } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';

// import { getHappyScoreGroupCounts } from "../requests/GetHappyScoreGroupCounts";
// import { Card } from 'react-native-elements';
// eslint-disable-next-line object-curly-newline
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import { Defs, LinearGradient, Stop } from 'react-native-svg';
import {
  VictoryAxis, VictoryChart, VictoryScatter, VictoryArea,
} from 'victory-native';

const VictoryAreaChart = ({
  interval, index = 'happyscore_flat_permin', metricName, onClick,
}) => {
  //  <VictoryAreaChart
  //    metricName="happy_group_energy_usage_local_happyscore_percent"
  //    title="External Env."
  //    interval="24h"
  //  />;
  // const [avg, setAvg] = useState(30);
  const [dataFormat, setDataFormat] = useState([]);
  // eslint-disable-next-line no-unused-vars
  // const [interval, setInterval] = useState(interval);
  // eslint-disable-next-line no-unused-vars
  const [selectedTimeFrame, setTimeFrame] = useState('7d');

  // let interval = "6h";

  const formatted = [];
  let point = {};
  const timeLabels = [];
  const Score = [];

  // eslint-disable-next-line no-unused-vars
  const buttondata = [
    { value: '1D', timeString: '1d' },
    { value: '1W', timeString: '7d' },
    { value: '1M', timeString: '30d' },
    { value: '3M', timeString: '90d' },
    { value: '6M', timeString: '180d' },
    { value: 'Y', timeString: '365d' },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        fetch('https://hqzse54ic7.execute-api.eu-west-1.amazonaws.com/firstDeploy/', {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            size: 0,
            index,
            query: {
              bool: {
                must: {
                  range: {
                    timestamp: {
                      gte: `now-${selectedTimeFrame}`,
                      lte: 'now',
                    },
                  },
                },
              },
            },
            aggs: {
              byhour: {
                date_histogram: {
                  field: 'timestamp',
                  interval,
                  min_doc_count: 0,
                },
                aggs: {
                  Score: {
                    avg: { field: metricName },
                  },
                },
              },
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(`data: ${JSON.stringify(data)}`);

            data.aggregations.byhour.buckets.map((e) => {
              Score.push(e.Score.value);
              function pad(n) {
                return n < 10 ? `0${n}` : n;
              }
              const dateobj = new Date(e.key_as_string);
              const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
              // console.log({ hourLabel });
              timeLabels.push(hourLabel);
              point = {
                name: hourLabel,
                Score: Math.floor(e.Score.value * 10) / 10,
              };
              formatted.push(point);
            });

            setDataFormat(formatted);
            // console.log(`formatted: ${JSON.stringify(timeLabels)}`);

            // const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
            // setAvg(Number(arrAvg(Score).toFixed(0)));
          });
      } catch (error) {
        // console.error(error);
      }
    };
    getData();
  }, [selectedTimeFrame, interval]);
  // ==================================================================================

  const chartTheme = {
    axis: {
      stroke: 'none',
      style: {
        // fixLabelOverlap: true,
        stroke: '#696D70',
        tickLabels: {
          fill: '#696D70',
        },
        grid: { stroke: 'none' },
      },
    },
  };
  return (
    <View>
      {/* <Card containerStyle={{ borderRadius: 24, width: 900 }}> */}
      <View
        style={{
          alignSelf: 'center',
          // width: '99%',
          flexDirection: 'row',
        }}
      >
        {/* //======================================================================== */}

        <View
          style={{
            flex: 1,
            marginLeft: 20,
            // alignItems: 'left',
            justifyContent: 'center',
          }}
        >
          {/* <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
            {' '}
            {props.title}
          </Text> */}
        </View>
        {/* <View
          style={{
            flex: 1,
            paddingTop: 15,
            // alignSelf: "center",
            alignItems: 'center',
          }}
        >
          <VictorySmallTimeSelector
            data={buttondata}
            onSelect={(value, timeString) => handleClick(value, timeString)}
          />
        </View> */}
        <View
          style={{
            flex: 1,
          }}
        ></View>
      </View>
      <VictoryChart
        theme={chartTheme}
        // style={{ alignSelf: 'center' }}
        height={250}
        domainPadding={{ x: [20, -20], y: 20 }}
      >
        <Defs>
          <LinearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <Stop offset="0%" stopColor="#003A70" stopOpacity={0.2} />

            <Stop offset="100%" stopColor="#003A70" stopOpacity={0} />
          </LinearGradient>
        </Defs>

        <VictoryArea
          style={{
            data: { fill: 'url(#myGradient)', stroke: '#003A70' },
          }}
          data={dataFormat}
          x="name"
          y="Score"
          interpolation="catmullRom"
        />
        <VictoryScatter
          data={dataFormat}
          x="name"
          y="Score"
          style={{
            data: { fill: '#003A70' },
          }}
          size={({ active }) => (active ? 8 : 3)}
        />
        <VictoryAxis
          // dependentAxis
          // tickFormat={(dataFormat) => dataFormat}
          // label="date"
          fixLabelOverlap={true}
          style={{
            axis: { stroke: 'none' },
            tickLabels: { fontSize: 12, padding: 5 },
          }}
        />
        <VictoryAxis
          dependentAxis
          // label="score"
          fixLabelOverlap={true}
          // data={dataFormat}
          // label={'name'}
          // x="name"
          // y="Score"
          // tickValues={dataFormat}
          style={{
            axis: { stroke: 'none' },
            // ticks: { stroke: 'red' },
            tickLabels: { fontSize: 12, padding: 0 },
          }}
          // domain={{ x: [0, 100], y: [0, 1] }}
        />
        {/* </VictoryGroup> */}
      </VictoryChart>
      {/* </Card> */}
      <TouchableOpacity
        style={styles.buttonBlue}
        onPress={() => {
          onClick();
        }}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Text style={styles.buttonBlueText}>Check More Details</Text>
          <FontAwesome
            size={23}
            style={{
              color: '#fff',
              marginBottom: 2,
              flex: 1,
              marginLeft: 10,
            }}
            name={'angle-right'}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};
const styles = StyleSheet.create({
  buttonBlue: {
    backgroundColor: '#003A70',
    padding: 3,
    borderRadius: 150,
    width: 150,
    justifyContent: 'center',
    height: 27,
    alignSelf: 'center',
  },
  buttonBlueText: {
    color: 'white',
    alignSelf: 'center',
    fontSize: 13,
    marginLeft: 5,
  },
});
export default VictoryAreaChart;
