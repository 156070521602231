import * as React from 'react';
import Svg, { SvgProps, Rect } from 'react-native-svg';

interface Props extends SvgProps {
  toggled?: boolean;
}

const FilterBox = (props: Props) => (
  <Svg
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <Rect
      x={0.5}
      y={0.5}
      width={17}
      height={17}
      rx={2.5}
      fill={props.toggled ? '#2173C4' : '#EFF2F3'}
      stroke={props.toggled ? '#2173C4' : '#2173C4'}
    />
  </Svg>
);

export default FilterBox;
