import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UserIcon16 = (props: SvgProps) => (
  <Svg width={17} height={16} fill="none" {...props}>
    <Path
      d="M10.917 4.667a2.667 2.667 0 1 1-5.334 0 2.667 2.667 0 0 1 5.334 0ZM10.25 10h-4a3.333 3.333 0 0 0-3.333 3.333v0a.667.667 0 0 0 .667.667h9.333a.666.666 0 0 0 .667-.667A3.333 3.333 0 0 0 10.25 10Z"
      stroke="#0058AD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default UserIcon16;
