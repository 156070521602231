import React from 'react';
import { StyleSheet, View } from 'react-native';
import Style from '../constants/Style';

export default function NewSwitch({
  isToggled,
  style,
  color,
}: {
  isToggled: boolean;
  style: any;
  color?: string;
}) {
  return (
    <View style={style}>
      <View
        style={[
          styles.switchContainer,
          isToggled
            ? {
              backgroundColor: color,
            }
            : { backgroundColor: '#C9CED0' },
        ]}
      >
        <View
          style={[
            styles.switchSelection,
            isToggled ? { alignSelf: 'flex-end', right: 1 } : { left: 1 },
            Style.boxShadow,
          ]}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  switchContainer: {
    height: 20,
    width: 35,
    borderRadius: 10,
  },
  switchSelection: {
    height: 18,
    width: 18,
    borderRadius: 9,
    backgroundColor: '#fff',
    top: 1,
  },
});
