import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UndoIcon = (props: SvgProps) => (
  <Svg
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <Path
      stroke="#0058AD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.001 9.334 2.668 6m0 0 3.333-3.333M2.668 6h4.267c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748c.436.856.436 1.976.436 4.216v.934"
    />
  </Svg>
);
export default UndoIcon;
