import AsyncStorage from '@react-native-async-storage/async-storage';
import { Floor, FLOOR_PRIORITY_OPTIONS } from '../components/Map/SetFloorsModal/FloorItem';

const updateFloorPlan = async (ParentId: string, floors: Floor[]) => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('jwt', jwtToken ?? '');

    const body = {
      ParentId,
      Floor: floors.map((floor: Floor) => ({
        Name: floor.Name,
        Number: floor.Number,
        Priority: FLOOR_PRIORITY_OPTIONS[floor.Priority].title,
        Svg: floor.plan,
        imageType: floor.planFilename?.split('.').pop(),
        imageName: floor.planFilename,
        imageSize: floor.planSize,
      })),
    };

    const response = await fetch(
      `https://${apiDomain}/button/locations/floor`,
      {
        method: 'POST',
        headers: requestHeaders,
        // eslint-disable-next-line no-undef
        body: new Blob([JSON.stringify(body)], { type: 'text/plain' }),
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default updateFloorPlan;
