import { FontAwesome } from '@expo/vector-icons';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  Platform,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  TextInput,
  NativeEventEmitter,
  RefreshControl,
  KeyboardAvoidingView,
  FlatList,
  Pressable,
  StatusBar,
} from 'react-native';
import * as Linking from 'expo-linking';
import * as Clipboard from 'expo-clipboard';
import Moment from 'moment';
import * as Analytics from 'expo-firebase-analytics';
import Modal from 'react-native-modal';
import * as Animatable from 'react-native-animatable';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import CompletedIcon from '../components/40pxIcons/CompletedIcon';
import LocationIcon from '../components/30pxIcons/LocationIcon';
import NoTaskIcon from '../components/NoTaskIcon';
import NoteIcon from '../components/NoteIcon';
import PriorityIcon from '../components/PriorityIcon';
import ProblemArrowIcon from '../components/ProblemArrowIcon';
import ReportTimeIcon from '../components/ReportTimeIcon';
import SearchIcon from '../components/SearchIcon';
import Colors from '../constants/Colors';
import { RootTabScreenProps } from '../types';
import LeftArrow from '../components/LeftArrow';
import getIssues from '../requests/GetIssues';
import Style from '../constants/Style';
import EllipsisIcon from '../components/EllipsisIcon';
import ResumeTriangleIcon from '../components/ResumeTriangleIcon';
import updateIssue from '../requests/UpdateIssue';
import PauseIcon from '../components/PauseIcon';
import BinIcon from '../components/BinIcon';
import AvatarIcon from '../components/AvatarIcon';
import deleteIssue from '../requests/DeleteIssue';
import ReportListItem from '../components/ReportListItem';
import InfoIcon from '../components/30pxIcons/InfoIcon';
import TimerFrom from '../components/TimerFrom';
import ResolvedIcon from '../components/16pxIcons/ResolvedIcon';
import ProceedIcon from '../components/16pxIcons/ProceedIcon';
import SLATimeIcon from '../components/24pxIcons/SLATimeIcon';
import AssigneeIcon from '../components/24pxIcons/AssigneeIcon';
import updateAssignee from '../requests/UpdateAssignee';
import StartedOnIcon24 from '../components/24pxIcons/StartedOnIcon';
import ReopenedOnIcon24 from '../components/24pxIcons/ReopenedOnIcon';
import CompletedOnIcon24 from '../components/24pxIcons/CompletedOnIcon';
import getUsers from '../requests/GetUsers';
import DeleteIcon24 from '../components/24pxIcons/DeleteIcon24';
import getButtonInstances from '../requests/GetButtonInstances';
import getLocations from '../requests/GetLocations';
import FilterIcon16 from '../components/16pxIcons/FilterIcon';
import getUserGroups from '../requests/GetUserGroups';
import DeleteIcon12 from '../components/12pxIcons/DeleteIcon12';
import PausedStatusIcon from '../components/40pxIcons/PausedStatusIcon';
import InProgressIcon from '../components/40pxIcons/InProgressIcon';
import FlagIcon from '../components/40pxIcons/FlagIcon';
import AlertModal from '../components/AlertModal';
import GridIcon from '../components/24pxIcons/GridIcon';
import SendNote from '../components/SendNote';
import RightArrow12 from '../components/12pxIcons/RightArrow12';
import ShareTicketPopup from '../components/Tickets/ShareTicketPopup';
import TicketsFilter from '../components/Tickets/TicketsFilter';

const isWeb: boolean = Platform.OS === 'web' && Dimensions.get('window').width > 500;

export type PressableState = Readonly<{
  hovered?: boolean;
}>;

// eslint-disable-next-line no-unused-vars
export default function ReportsScreen({ route, navigation }: RootTabScreenProps<'Tickets'>) {
  const [clientName, setClientName] = useState<string>(null);
  const [problems, setProblems] = useState([]);
  const [allProblems, setAllProblems] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState(undefined);

  const [filterOpen, setFilterOpen] = React.useState(false);

  const [lowPriorityFilter, setLowPriorityFilter] = React.useState(false);
  const [medPriorityFilter, setMedPriorityFilter] = React.useState(false);
  const [highPriorityFilter, setHighPriorityFilter] = React.useState(false);
  const [criticalPriorityFilter, setCriticalPriorityFilter] = React.useState(false);

  const [doneFilter, setDoneFilter] = React.useState(false);
  const [inProgressFilter, setInProgressFilter] = React.useState(false);
  const [pausedFilter, setPausedFilter] = React.useState(false);
  const [withoutStatusFilter, setWithoutStatusFilter] = React.useState(false);
  const [selectedAssignee, setSelectedAssignee] = React.useState('');
  const [selectedAssetId, setSelectedAssetId] = React.useState('');
  const [selectedUserGroupId, setSelectedUserGroupId] = React.useState('');
  const [selectedLocation, setSelectedLocation] = React.useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [newReports, setNewReports] = useState(false);
  const [noteValue, setNoteValue] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const [shareDropdownShown, setShareDropdownShown] = useState(false);
  const [shareConfirmationShown, setShareConfirmationShown] = useState(false);

  const [scrollOffset, setScrollOffset] = useState(0);
  const [otherExpanded, setOtherExpanded] = useState(false);
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [users, setUsers] = useState([]);
  const [instances, setInstances] = useState([]);
  const [locations, setLocations] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [assigneeSearchValue, setAssigneeSearchValue] = useState('');

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertAction, setAlertAction] = useState(null);
  const [alertActionTitle, setAlertActionTitle] = useState(null);
  const [alertCloseTitle, setAlertCloseTitle] = useState(null);
  const [selectedRange, setSelectedRange] = useState(1);
  const [paramReportId, setParamReportId] = useState(undefined);

  const [SLATitleWidth, setSLATitleWidth] = useState(60);

  const showAlert = (
    title: string,
    message?: string | ReactElement,
    error: boolean = true,
    action: () => void = null,
    actionTitle: string = null,
    closeTitle: string = null,
  ) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertError(error);
    setAlertAction(() => action);
    setAlertActionTitle(actionTitle);
    setAlertCloseTitle(closeTitle);
    setAlertVisible(true);
  };

  const topSafeAreaInset = useSafeAreaInsets().top;
  const bottomSafeAreaInset = useSafeAreaInsets().bottom;

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'Reports',
      name: title,
    });
  };

  const [range, setRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: null, end: null });

  const onConfirm = React.useCallback(
    (start, end) => {
      setRange({ start, end });
    },
    [setRange],
  );

  const handleTap = () => {
    if (modalVisible) {
      setModalVisible(false);
    }
    flatList?.current?.scrollToOffset({ animated: true, offset: 0 });
  };

  navigation.addListener('tabPress', handleTap);

  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });

  const [email, updateEmail] = useState('');
  const [myClientIdEmail, setMyClientIdEmail] = useState('');

  React.useEffect(() => {
    const emitter = Platform.OS === 'web' ? new NativeEventEmitter() : null;
    AsyncStorage.getItem('clientName').then(setClientName);
    emitter?.addListener('event.newupdates', () => {
      setNewReports(true);
    });
    const unsubscribe = navigation.addListener('blur', () => {
      setFilterOpen(false);
    });
    if (Platform.OS === 'android') StatusBar.setBarStyle('dark-content');
    return unsubscribe;
  }, []);

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AsyncStorage.getItem('email').then((n) => {
      updateEmail(n);
    }) ?? '';
  }, []);

  React.useEffect(() => {
    // @ts-ignore
    if (route?.params?.reportId || route?.params?.assetId) resetAllFilters();
    // @ts-ignore
    const assetId = route.params?.assetId;
    if (assetId !== undefined) {
      setSelectedAssetId(assetId ?? '');
    }

    if (assetId !== undefined) {
      setSelectedProblem(undefined);
    }

    // @ts-ignore
    if (route?.params?.reportId) {
      // @ts-ignore
      setParamReportId(route?.params?.reportId);
    }

    if (!loading) {
      setSelectedProblem(undefined);
      setLoading(true);
    }
  }, [route]);

  React.useEffect(() => {
    const problemMissing: boolean = problems
      ?.filter((problem) => problem.id === paramReportId)[0] === undefined;

    if (!loading && problemMissing && paramReportId !== undefined) {
      if (anyFilterApplied()) {
        resetAllFilters();
      }
      setSelectedProblem(undefined);
    }

    if (!loading && paramReportId && clientName && !problemMissing) {
      // @ts-ignore
      const clientId: string = route.params?.clientId;
      if (clientName.toLowerCase() !== clientId?.toLowerCase()) {
        showAlert('Error with ticket', 'The specified ticket is not from this organization.');
        return;
      }
      const selected = problems?.filter((problem) => problem.id === paramReportId)[0];
      if (selected) {
        setSelectedProblem(problems?.filter((problem) => problem.id === paramReportId)[0]);
        setModalVisible(true);
      }
    }
  }, [paramReportId, loading, clientName]);

  React.useEffect(() => {
    getUserGroups().then((response) => {
      if (response?.UserGroups?.length > 0) {
        setUserGroups(response.UserGroups);
      }
    });
  }, []);

  const emitter = Platform.OS === 'web' ? new NativeEventEmitter() : null;

  const scrollView = React.useRef<null | ScrollView>(null);
  const flatList = React.useRef<null | FlatList>(null);

  const handleOnScroll = (event) => {
    setScrollOffset(event?.nativeEvent?.contentOffset?.y);
  };
  const handleScrollTo = (p) => {
    if (scrollView.current) {
      scrollView.current.scrollTo(p);
    }
  };

  React.useEffect(() => {
    getIssues(
      500,
      lowPriorityFilter,
      medPriorityFilter,
      highPriorityFilter,
      criticalPriorityFilter,
      doneFilter,
      inProgressFilter,
      pausedFilter,
      withoutStatusFilter,
      selectedAssignee,
      selectedLocation,
      selectedAssetId,
      [selectedUserGroupId],
      range.start,
      range.end,
    ).then((response) => {
      if (response && loading) {
        setProblems(response.issues);
        setAllProblems(response.issues);
        if (selectedProblem !== undefined) {
          // @ts-ignore
          const reportId = route.params?.reportId;
          const setProblem = problems.filter((problem) => problem.id === selectedProblem.id)[0];
          if (setProblem !== undefined && reportId === undefined) {
            setSelectedProblem(setProblem);
          }
        }
        setLoading(false);
        setShowSpinner(false);
      }
    });
  }, [loading]);

  useEffect(() => {
    setProblems(
      (allProblems ?? []).filter(
        (e) => e.name?.toLowerCase().includes(searchValue?.toLowerCase())
          || e.id?.toLowerCase().includes(searchValue?.toLowerCase()),
      ),
    );
  }, [searchValue, loading]);

  useEffect(() => {
    setShareConfirmationShown(false);
    setShareDropdownShown(false);
  }, [selectedProblem]);

  React.useEffect(() => {
    getUsers().then((response) => {
      if (response.Users?.length > 0) {
        setUsers(response.Users);
        response.Users.forEach((user) => {
          if (user.Email === email) {
            // Alert.alert(user.ClientIdEmail);
            setMyClientIdEmail(user.ClientIdEmail);
          }
        });
      }
    });
  }, [email]);

  React.useEffect(() => {
    getButtonInstances().then((result) => {
      if (result.instances?.length > 0) {
        setInstances(result.instances.sort((a, b) => a?.Name?.localeCompare(b.Name)));
      }
    });
  }, []);

  React.useEffect(() => {
    getLocations().then((response) => {
      if (response?.locations?.length > 0) {
        setLocations(response.locations);
      }
    });
  }, []);

  function getPriorityText(priority: string) {
    switch (priority) {
      case 'low':
        return 'P4 - Low';
      case 'moderate':
        return 'P3 - Moderate';
      case 'high':
        return 'P2 - High';
      case 'critical':
        return 'P1 - Critical';
      default:
        return 'P4 - Low';
    }
  }

  function getPriorityColor(priority: string) {
    switch (priority) {
      case 'low':
        return Colors.light.hereworksCheckGreen;
      case 'moderate':
        return Colors.light.hereworksLightBlue;
      case 'high':
        return Colors.light.hereworksMediumYellow;
      case 'critical':
        return Colors.light.hereworksUrgentRed;
      default:
        return 'transparent';
    }
  }

  const parseSlaTime = (hours) => {
    if (hours <= 0) {
      return 'No SLA Set';
    }
    Moment.updateLocale('en', {
      relativeTime: {
        s: '%d seconds',
        m: '%d minute',
        h: '%d hour',
        hh: '%d hours',
        d: '%d day',
        dd: '%d days',
      },
    });
    if (hours < 24) {
      return Moment.duration(hours, 'hours').humanize();
    }
    if (hours % 24 === 0) {
      return hours / 24 === 1 ? '1 day' : `${hours / 24} days`;
    }
    return `${hours} hours`;
  };

  const renderStatusButtons = () => (
    <>
      {selectedProblem.status === 'reported' && (
        <>
          <TouchableOpacity
            style={[
              styles.statusButton,
              {
                backgroundColor: Colors.light.hereworksDarkBlue,
                borderColor: Colors.light.hereworksDarkBlue,
                paddingHorizontal: 35,
              },
            ]}
            onPress={() => {
              analytics('Start Resolving - Mobile');
              setShowSpinner(true);
              updateIssue(selectedProblem.id, selectedProblem.assignee ?? myClientIdEmail)
                .then((response) => {
                  if (!response) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else if (response?.isError) {
                    showAlert('Error', response.errorText);
                  } else if (!response.issue) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else if (response?.issue?.status === 'inprogress') {
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].status = 'inprogress';
                    // eslint-disable-next-line max-len
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].assignee = response?.issue?.assignee;
                    // eslint-disable-next-line max-len
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].startedon = Moment().toString();
                    selectedProblem.startedon = Moment().toString();
                    selectedProblem.status = 'inprogress';
                  }
                  setShowSpinner(false);
                  emitter?.emit('event.updatenotifications', {});
                })
                .catch(() => {
                  showAlert(
                    'Error',
                    'Unable to update ticket status at the moment. Please try again later.',
                  );
                  setShowSpinner(false);
                });
            }}
          >
            <ProceedIcon />
            <Text style={[styles.resolveText, { color: '#fff' }]}>Start</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              analytics('Checkmark - Mobile');
              setShowSpinner(true);
              updateIssue(selectedProblem.id, myClientIdEmail, true)
                .then((response) => {
                  if (!response) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else if (response?.isError) {
                    showAlert('Error', response.errorText);
                  } else if (!response.issue) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  }
                  setLoading(true);
                  emitter?.emit('event.updatenotifications', {});
                  setShowSpinner(false);
                })
                .catch(() => {
                  showAlert(
                    'Error',
                    'Unable to update ticket status at the moment. Please try again later.',
                  );
                  setShowSpinner(false);
                });
            }}
            style={[
              styles.statusButton,
              {
                backgroundColor: Colors.light.hereworksGreen500,
                marginLeft: 8,
                borderColor: Colors.light.hereworksGreen500,
              },
            ]}
          >
            <ResolvedIcon color={'#fff'} />
            <Text style={[styles.resolveText, { color: '#fff' }]}>Resolve</Text>
          </TouchableOpacity>
        </>
      )}
      {selectedProblem.status === 'inprogress' && (
        <>
          <TouchableOpacity
            style={styles.resumeButtonMobile}
            onPress={() => {
              analytics('Pause - Mobile');
              setShowSpinner(true);
              updateIssue(
                selectedProblem.id,
                selectedProblem.assignee ?? myClientIdEmail,
                false,
                true,
              )
                .then((response) => {
                  if (!response) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else if (response?.isError) {
                    showAlert('Error', response.errorText);
                  }
                  if (response?.issue?.status === 'paused') {
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].status = 'paused';
                    // eslint-disable-next-line max-len
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].pausedon = Moment().toString();
                    selectedProblem.pausedon = Moment().toString();
                  }
                  setShowSpinner(false);
                  emitter?.emit('event.updatenotifications', {});
                })
                .catch(() => {
                  showAlert(
                    'Error',
                    'Unable to update ticket status at the moment. Please try again later.',
                  );
                  setShowSpinner(false);
                });
            }}
          >
            <PauseIcon />
            <TimerFrom startDate={selectedProblem.startedon} style={styles.resumeButtonText} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              analytics('In Progress - Resolve');
              setShowSpinner(true);
              updateIssue(selectedProblem.id, selectedProblem.assignee ?? myClientIdEmail, true)
                .then((response) => {
                  if (!response) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else if (response?.isError) {
                    showAlert('Error', response.errorText);
                  } else if (!response.issue) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else {
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].status = 'completed';
                    // eslint-disable-next-line max-len
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].completedon = Moment().toString();
                    problems.filter(
                      (problem) => problem.id === selectedProblem.id,
                    )[0].SolvedInTime = response?.issue?.SolvedInTime;
                  }
                  setShowSpinner(false);
                  setLoading(true);
                  emitter?.emit('event.updatenotifications', {});
                })
                .catch(() => {
                  showAlert(
                    'Error',
                    'Unable to update ticket status at the moment. Please try again later.',
                  );
                  setShowSpinner(false);
                });
            }}
            disabled={showSpinner}
            style={[
              styles.statusButton,
              {
                backgroundColor: Colors.light.hereworksGreen500,
                borderColor: Colors.light.hereworksGreen500,
              },
            ]}
          >
            <ResolvedIcon color={'#fff'} />
            <Text style={[styles.resolveText, { color: '#fff' }]}>Resolve</Text>
          </TouchableOpacity>
        </>
      )}
      {selectedProblem.status === 'paused' && (
        <>
          <TouchableOpacity
            style={styles.pauseButtonMobile}
            onPress={() => {
              analytics('Resume - Mobile');
              setShowSpinner(true);
              updateIssue(
                selectedProblem.id,
                selectedProblem.assignee ?? myClientIdEmail,
                false,
                false,
                true,
              )
                .then((response) => {
                  if (!response) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else if (response?.isError) {
                    showAlert('Error', response.errorText);
                  } else if (!response.issue) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  }
                  if (response?.issue?.status === 'inprogress') {
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].status = 'inprogress';
                    // eslint-disable-next-line max-len
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].startedon = Moment(response?.issue?.startedon).toString();
                    selectedProblem.startedon = Moment(response?.issue?.startedon).toString();
                  }
                  setShowSpinner(false);
                  emitter?.emit('event.updatenotifications', {});
                })
                .catch(() => {
                  showAlert(
                    'Error',
                    'Unable to update ticket status at the moment. Please try again later.',
                  );
                  setShowSpinner(false);
                });
            }}
          >
            <ResumeTriangleIcon color={Colors.light.hereworksPrimaryGrey} />
            <Text style={styles.pauseButtonText}>
              {Moment.duration(
                Moment(new Date(selectedProblem.pausedon)).diff(
                  Moment(new Date(selectedProblem.startedon)),
                ),
              ).asHours() < 24
                ? Moment.utc(
                  Moment(new Date(selectedProblem.pausedon)).diff(
                    Moment(new Date(selectedProblem.startedon)),
                  ),
                ).format('HH:mm:ss')
                : `${Moment(new Date(selectedProblem.pausedon)).diff(
                  Moment(new Date(selectedProblem.startedon)),
                  'days',
                )} days`}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              analytics('Paused Checkmark - Mobile');
              updateIssue(selectedProblem.id, myClientIdEmail, true)
                .then((response) => {
                  if (!response) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else if (response?.isError) {
                    showAlert('Error', response.errorText);
                  } else if (!response.issue) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else {
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].status = 'completed';
                    // eslint-disable-next-line max-len
                    problems.filter((problem) => problem.id === selectedProblem.id)[0].completedon = Moment().toString();
                    problems.filter(
                      (problem) => problem.id === selectedProblem.id,
                    )[0].SolvedInTime = response?.issue?.SolvedInTime;
                  }
                  setLoading(true);
                  emitter?.emit('event.updatenotifications', {});
                  setShowSpinner(false);
                })
                .catch(() => {
                  showAlert(
                    'Error',
                    'Unable to update ticket status at the moment. Please try again later.',
                  );
                  setShowSpinner(false);
                });
            }}
            style={[
              styles.statusButton,
              {
                backgroundColor: Colors.light.hereworksGreen500,
                borderColor: Colors.light.hereworksGreen500,
              },
            ]}
          >
            <ResolvedIcon color={'#fff'} />
            <Text style={[styles.resolveText, { color: '#fff' }]}>Resolve</Text>
          </TouchableOpacity>
        </>
      )}
      {selectedProblem.status === 'completed' && (
        <View style={[{ flexDirection: 'row', justifyContent: 'flex-start' }]}>
          <View
            style={[
              styles.statusButton,
              {
                backgroundColor: Colors.light.hereworksGreen100,
                borderColor: Colors.light.hereworksGreen50,
              },
            ]}
          >
            <ResolvedIcon />
            <Text style={styles.resolveText}>Resolved</Text>
          </View>
          <TouchableOpacity
            onPress={() => {
              analytics('Reopen');
              updateIssue(
                selectedProblem.id,
                selectedProblem.assignee ?? myClientIdEmail,
                false,
                false,
                false,
                true,
              )
                .then((response) => {
                  if (!response) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  } else if (response?.isError) {
                    showAlert('Error', response.errorText);
                  } else if (!response.issue) {
                    showAlert(
                      'Error',
                      'Unable to update ticket status at the moment. Please try again later.',
                    );
                  }
                  setLoading(true);
                  emitter?.emit('event.updatenotifications', {});
                  setShowSpinner(false);
                })
                .catch(() => {
                  showAlert(
                    'Error',
                    'Unable to update ticket status at the moment. Please try again later.',
                  );
                  setShowSpinner(false);
                });
            }}
            style={[
              styles.statusButton,
              {
                backgroundColor: Colors.light.hereworksBlue500,
                marginLeft: 8,
                borderColor: Colors.light.hereworksBlue500,
              },
            ]}
          >
            <ProceedIcon />
            <Text style={[styles.resolveText, { color: '#fff' }]}>Reopen</Text>
          </TouchableOpacity>
        </View>
      )}
    </>
  );

  const renderMobile = (): boolean => Platform.OS !== 'web' || dimensions.window?.width < 500 || dimensions.window?.height < 500;

  const generateColor = (length) => {
    const randomColor = Math.floor((1 / length) * 16777215)
      .toString(16)
      .padStart(6, '0');
    return `#${randomColor}`;
  };

  const assigneeName = (clientIdEmail) => {
    let assignee = clientIdEmail;
    users.forEach((user) => {
      if (user.ClientIdEmail === clientIdEmail) {
        assignee = `${user.GivenName} ${user.FamilyName}`;
      }
    });
    return assignee;
  };

  const assetName = (assetId) => {
    let asset = assetId;
    instances.forEach((instance) => {
      if (instance.ButtonInstanceId === assetId) {
        asset = instance.Name;
      }
    });
    return asset;
  };

  const userGroupName = (userGroupId) => {
    let asset = userGroupId;
    userGroups.forEach((instance) => {
      if (instance.UserGroupId === userGroupId) {
        asset = instance.Name;
      }
    });
    return asset;
  };

  const locationName = (locationId) => {
    let location = locationId;
    locations.forEach((instance) => {
      if (instance.LocationId === locationId) {
        location = instance.Name;
      }
    });
    return location;
  };

  const assigneeIcon = (text) => (
    <View
      style={{
        width: 24,
        height: 24,
        borderRadius: 12,
        marginRight: 8,
        backgroundColor: generateColor(text.length),
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text
        style={{
          color: '#fff',
          fontFamily: 'Roboto_400Regular',
          fontSize: 8,
        }}
      >
        {text.split(' ').map((word, index) => {
          if (index > 1) {
            return '';
          }
          return word.charAt(0).toUpperCase();
        })}
      </Text>
    </View>
  );

  const renderGroupTag = (groupId, key) => {
    let groupName = null;
    userGroups.forEach((group) => {
      if (group.UserGroupId === groupId) {
        groupName = group.Name;
      }
    });

    if (groupName === null) {
      return null;
    }

    return (
      <View
        style={{
          backgroundColor: Colors.light.hereworksDarkBlue,
          borderRadius: 6,
          marginRight: 6,
          marginBottom: 6,
          flexShrink: 1,
        }}
        key={key}
      >
        <Text
          style={{
            fontFamily: 'Roboto_400Regular',
            fontSize: 14,
            color: '#fff',
            padding: 4,
            flex: 1,
          }}
        >
          {groupName}
        </Text>
      </View>
    );
  };

  const renderAssignee = () => (
    <>
      {selectedProblem.assignee && (
        <View style={styles.problemSingleGroup}>
          <View style={styles.problemSubHeadingContainer}>
            <View style={styles.assigneeAvatarContainer}>
              {!selectedProblem.assigneeImage && <AssigneeIcon />}
              {selectedProblem.assigneeImage && (
                <Image
                  style={styles.assigneeAvatar}
                  source={{ uri: selectedProblem.assigneeImage }}
                />
              )}
            </View>
            <Text style={styles.problemSubHeading}>Assignee</Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'stretch' }}>
            {assigneeIcon(assigneeName(selectedProblem.assignee))}
            <Text style={[styles.problemDetail, { flex: 1 }]}>
              {assigneeName(selectedProblem.assignee)}
            </Text>
            {selectedProblem.status !== 'completed' && (
              <TouchableOpacity
                onPress={() => {
                  selectedProblem.assignee = null;
                  problems.filter((p) => p.id === selectedProblem.id)[0].assignee = null;
                  setShowAssigneeDropdown(!showAssigneeDropdown);
                }}
              >
                <DeleteIcon24 />
              </TouchableOpacity>
            )}
          </View>
        </View>
      )}
      {!selectedProblem.assignee && (
        <Pressable
          onPress={() => setShowAssigneeDropdown(!showAssigneeDropdown)}
          style={(state) => [
            styles.problemSingleGroup,
            {
              zIndex: 10,
              backgroundColor: state.hovered ? Colors.light.hereworksWhite200 : undefined,
              borderColor: state.hovered ? Colors.light.hereworksBlue500 : undefined,
            },
          ]}
        >
          {({ hovered }: PressableState): ReactElement => (
            <>
              <View style={styles.problemSubHeadingContainer}>
                <View>
                  <AssigneeIcon add />
                </View>
                <Text
                  style={[
                    styles.problemSubHeading,
                    { color: hovered ? Colors.light.hereworksBlue500 : undefined },
                  ]}
                >
                  Assignee
                </Text>
              </View>
              {showAssigneeDropdown && (
                <View
                  style={{
                    position: 'absolute',
                    top: 55,
                    left: 0,
                    right: 0,
                    height: 200,
                    backgroundColor: '#fff',
                    borderWidth: 1,
                    borderColor: Colors.light.hereworksBlue100,
                    borderRadius: 4,
                    zIndex: 5,
                  }}
                >
                  <View
                    style={{
                      backgroundColor: Colors.light.hereworksBlue100,
                      width: 12,
                      height: 12,
                      borderRadius: 4,
                      position: 'absolute',
                      top: -6,
                      left: 22,
                      zIndex: 1,
                      transform: [{ rotateZ: '45deg' }],
                    }}
                  />
                  <TouchableOpacity
                    style={{
                      backgroundColor: '#fff',
                      zIndex: 2,
                      borderRadius: 12,
                      flexDirection: 'row',
                      paddingLeft: 10,
                      paddingVertical: 8,
                      alignItems: 'center',
                    }}
                    activeOpacity={1}
                  >
                    <SearchIcon />
                    <TextInput
                      style={{
                        fontFamily: 'Roboto_400Regular',
                        color: Colors.light.hereworksBlack400,
                        fontSize: 14,
                        // @ts-ignore
                        outlineStyle: 'none',
                      }}
                      placeholderTextColor={Colors.light.hereworksPrimaryGrey700}
                      placeholder="Search"
                      onChangeText={(text) => setAssigneeSearchValue(text)}
                    />
                  </TouchableOpacity>
                  <ScrollView style={{ zIndex: 2 }}>
                    {users.map((user: any, key) => {
                      if (
                        !user.FullName?.toLowerCase().includes(assigneeSearchValue.toLowerCase())
                      ) {
                        return null;
                      }
                      return (
                        <Pressable
                          key={key}
                          style={(state) => [
                            {
                              paddingLeft: 10,
                              paddingVertical: 8,
                              borderRadius: 4,
                              backgroundColor: state.hovered
                                ? Colors.light.hereworksWhite200
                                : '#fff',
                            },
                          ]}
                          onPress={() => {
                            // eslint-disable-next-line max-len
                            problems.filter((p) => p.id === selectedProblem.id)[0].assignee = user.ClientIdEmail;
                            setShowSpinner(true);
                            updateAssignee(selectedProblem.id, user.ClientIdEmail).then(() => {
                              setLoading(true);
                            });
                            setShowAssigneeDropdown(false);
                          }}
                        >
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            {assigneeIcon(user.FullName)}
                            <View style={{ justifyContent: 'center', flex: 1 }}>
                              <Text style={styles.filterText}>
                                {user.GivenName || user.FamilyName
                                  ? `${user.GivenName ?? ''} ${user.FamilyName ?? ''}`
                                  : user.FullName ?? ''}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              flexWrap: 'wrap',
                              flexDirection: 'row',
                              flex: 1,
                              marginTop: 4,
                              marginLeft: 32,
                            }}
                          >
                            {user.UserGroupIds?.length > 0
                              // eslint-disable-next-line max-len
                              && user.UserGroupIds.map((group, gKey) => renderGroupTag(group, gKey))}
                          </View>
                        </Pressable>
                      );
                    })}
                  </ScrollView>
                </View>
              )}
              <Text style={styles.problemDetail}>{selectedProblem.assignee}</Text>
            </>
          )}
        </Pressable>
      )}
    </>
  );

  const renderAssigneeMobile = () => (
    <>
      {selectedProblem.assignee && (
        <View
          style={[
            styles.problemSubGroupMobile,
            { flexDirection: 'column', alignItems: 'flex-start' },
          ]}
        >
          <View style={{ flex: 1, flexDirection: 'row', marginTop: 16 }}>
            <View style={styles.assigneeAvatarContainer}>
              {!selectedProblem.assigneeImage && <AssigneeIcon />}
              {selectedProblem.assigneeImage && (
                <Image
                  style={styles.assigneeAvatar}
                  source={{ uri: selectedProblem.assigneeImage }}
                />
              )}
            </View>
            <Text style={styles.problemSubHeading}>Assignee</Text>
          </View>
          <View style={{ flex: 1, alignSelf: 'stretch' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {assigneeIcon(assigneeName(selectedProblem.assignee))}
              <Text style={[styles.problemDetailMobile, { flex: 1 }]}>
                {assigneeName(selectedProblem.assignee)}
              </Text>
              {selectedProblem.status !== 'completed' && (
                <TouchableOpacity
                  onPress={() => {
                    selectedProblem.assignee = null;
                    problems.filter((p) => p.id === selectedProblem.id)[0].assignee = null;
                    setShowAssigneeDropdown(!showAssigneeDropdown);
                  }}
                >
                  <DeleteIcon24 />
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
      )}
      {!selectedProblem.assignee && (
        <View
          style={{
            backgroundColor: 'white',
            borderRadius: 8,
            marginHorizontal: 20,
          }}
        >
          <Pressable
            onPress={() => {
              setAssigneeSearchValue('');
              setShowAssigneeDropdown(!showAssigneeDropdown);
            }}
            style={(state) => [
              styles.problemSubGroupMobile,
              {
                marginHorizontal: 0,
                marginVertical: 0,
                zIndex: 10,
                backgroundColor: state.pressed ? Colors.light.hereworksWhite200 : undefined,
                borderColor: state.pressed ? Colors.light.hereworksBlue500 : undefined,
              },
            ]}
          >
            {({ hovered }: PressableState): ReactElement => (
              <>
                <View style={styles.problemSubHeadingContainer}>
                  <View>
                    <AssigneeIcon add />
                  </View>
                  <Text
                    style={[
                      styles.problemSubHeading,
                      { color: hovered ? Colors.light.hereworksBlue500 : undefined },
                    ]}
                  >
                    Assignee
                  </Text>
                </View>
                <Text style={styles.problemDetail}>{selectedProblem.assignee}</Text>
              </>
            )}
          </Pressable>
          {showAssigneeDropdown && (
            <View
              style={{
                height: 184,
                backgroundColor: '#fff',
                borderWidth: 1,
                borderColor: Colors.light.hereworksBlue100,
                borderRadius: 4,
                zIndex: 5,
                marginHorizontal: 20,
              }}
            >
              <View
                style={{
                  backgroundColor: Colors.light.hereworksBlue100,
                  width: 12,
                  height: 12,
                  borderRadius: 4,
                  position: 'absolute',
                  top: -6,
                  left: 10,
                  zIndex: 1,
                  transform: [{ rotateZ: '45deg' }],
                }}
              />
              <View
                style={{
                  backgroundColor: '#fff',
                  zIndex: 2,
                  borderRadius: 12,
                  flexDirection: 'row',
                  paddingLeft: 10,
                  paddingTop: 4,
                  alignItems: 'center',
                }}
              >
                <SearchIcon />
                <TextInput
                  style={{
                    fontFamily: 'Roboto_400Regular',
                    color: Colors.light.hereworksBlack400,
                    fontSize: 14,
                  }}
                  placeholderTextColor={Colors.light.hereworksPrimaryGrey700}
                  placeholder="Search"
                  onChangeText={(text) => setAssigneeSearchValue(text)}
                />
              </View>
              <ScrollView style={{ zIndex: 2 }} nestedScrollEnabled={true} indicatorStyle={'black'}>
                {users.map((user: any, key) => {
                  if (!user.FullName?.toLowerCase().includes(assigneeSearchValue.toLowerCase())) {
                    return null;
                  }
                  return (
                    <Pressable
                      key={key}
                      style={(state) => [
                        {
                          paddingLeft: 10,
                          paddingVertical: 8,
                          borderRadius: 4,
                          backgroundColor: state.hovered ? Colors.light.hereworksWhite200 : '#fff',
                        },
                      ]}
                      onPress={() => {
                        analytics('Selected assignee');
                        // eslint-disable-next-line max-len
                        problems.filter((p) => p.id === selectedProblem.id)[0].assignee = user.ClientIdEmail;
                        setShowSpinner(true);
                        updateAssignee(selectedProblem.id, user.ClientIdEmail).then(() => {
                          setLoading(true);
                        });
                        setShowAssigneeDropdown(false);
                        setAssigneeSearchValue('');
                      }}
                    >
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        {assigneeIcon(user.FullName)}
                        <View style={{ justifyContent: 'center', flex: 1 }}>
                          <Text
                            style={styles.filterText}
                          >{`${user.GivenName} ${user.FamilyName}`}</Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexWrap: 'wrap',
                          flexDirection: 'row',
                          marginTop: 4,
                        }}
                      >
                        {user?.UserGroupIds?.length > 0
                          && user.UserGroupIds.map((group, gKey) => renderGroupTag(group, gKey))}
                      </View>
                    </Pressable>
                  );
                })}
              </ScrollView>
            </View>
          )}
        </View>
      )}
    </>
  );

  const assigneeFullName = (assigneeEmail: string) => {
    const user = users?.filter(
      (item) => item?.Email === assigneeEmail || assigneeEmail.includes(item?.Email),
    )[0];
    return user?.FullName;
  };

  const renderSharePane = () => (
    <View style={styles.sharePane}>
      <Text style={styles.shareTicketText}>
        {'TICKET ID: '}
        <Text style={{ color: Colors.light.hereworksDarkBlue }}>{selectedProblem.id}</Text>
      </Text>
      <TouchableOpacity onPress={() => setShareDropdownShown(!shareDropdownShown)}>
        <EllipsisIcon fill={Colors.light.hereworksDarkBlue300} />
      </TouchableOpacity>
      <ShareTicketPopup visible={shareConfirmationShown} />
      {shareDropdownShown && (
        <View style={styles.shareDropdownPane}>
          <TouchableOpacity
            hitSlop={{
              top: 10,
              left: 10,
              right: 10,
              bottom: 0,
            }}
            style={styles.shareDropdownButton}
            onPress={() => {
              setShareDropdownShown(false);
              Clipboard.setStringAsync(
                Linking.makeUrl(`Tickets?clientId=${clientName}&reportId=${selectedProblem.id}`),
              );
              setShareConfirmationShown(true);
              const timeout = setTimeout(() => {
                setShareConfirmationShown(false);
                clearTimeout(timeout);
              }, 3000);
            }}
          >
            <Text style={styles.shareDropdownText}>Share</Text>
          </TouchableOpacity>
          <TouchableOpacity
            hitSlop={{
              top: 0,
              left: 10,
              right: 10,
              bottom: 10,
            }}
            style={styles.shareDropdownButton}
            onPress={() => {
              setShareDropdownShown(false);
              showAlert(
                'Delete the Ticket',
                <Text numberOfLines={2}>
                  Do you want to delete the ticket -{' '}
                  <Text numberOfLines={1} style={styles.alertBoldText}>
                    {` "${selectedProblem.name}"`}
                  </Text>
                  ?
                </Text>,
                true,
                deleteTicket,
                'Delete',
              );
            }}
          >
            <Text style={styles.shareDropdownText}>Delete</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );

  const renderSelectedProblemWeb = () => (selectedProblem !== undefined ? (
      <Animatable.View
        style={[styles.shadow, styles.selectedProblemContainer]}
        animation={'fadeIn'}
      >
        {renderSharePane()}
        {selectedProblem?.name !== '' && (
          <ScrollView style={{ flex: 3 }}>
            <View style={styles.problemHeaderContainer}>
              <View style={{ flex: 2 }}>
                <Text style={styles.fullProblemTitle}>
                  {selectedProblem.fullTitle === '' || selectedProblem.fullTitle === undefined
                    ? selectedProblem.name
                    : selectedProblem.fullTitle}
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {!selectedProblem.reporterImage
                    && (selectedProblem.reporter && assigneeFullName(selectedProblem.reporter) ? (
                      assigneeIcon(assigneeFullName(selectedProblem.reporter))
                    ) : (
                      <AvatarIcon />
                    ))}
                  {selectedProblem.reporterImage && (
                    <Image
                      style={styles.reporterAvatar}
                      source={{ uri: selectedProblem.reporterImage }}
                    />
                  )}
                  {selectedProblem.reporter && (
                    <Text style={styles.reporterText}>
                      Reported by: {selectedProblem.reporter?.split(/[^\w\sá]/)[0]}
                    </Text>
                  )}
                  {!selectedProblem.reporter && (
                    <Text style={styles.reporterText}>Reported anonymously</Text>
                  )}
                </View>
                <View style={styles.problemButtons}>{renderStatusButtons()}</View>
                {moreOptionsVisible && (
                  <View
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 2,
                    }}
                  >
                    {renderMoreOptions()}
                  </View>
                )}
              </View>
            </View>
            <View style={styles.problemDivider} />
            <View style={[styles.problemSubGroup, { zIndex: 5 }]}>
              {selectedProblem.images && (
                <>
                  <Modal
                    isVisible={imageModalVisible}
                    animationIn={'fadeIn'}
                    animationOut={'fadeOut'}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        analytics('Close image modal');
                        setImageModalVisible(!imageModalVisible);
                      }}
                      style={{ height: '100%', flex: 1 }}
                    >
                      <Image
                        source={{
                          uri: selectedProblem.images[0]?.filename,
                        }}
                        resizeMode={'center'}
                        style={{ width: undefined, height: '100%' }}
                      />
                    </TouchableOpacity>
                  </Modal>
                  <TouchableOpacity
                    onPress={() => {
                      analytics('Open image modal');
                      setImageModalVisible(!imageModalVisible);
                    }}
                    style={[styles.problemSingleGroup, { flex: 1 }]}
                  >
                    <Image
                      source={{
                        uri: selectedProblem.images[0]?.filename,
                      }}
                      resizeMode={'cover'}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 8,
                      }}
                    />
                  </TouchableOpacity>
                </>
              )}
              {selectedProblem['instance-name'] && (
                <View style={styles.problemSingleGroup}>
                  <View style={[styles.alignedRow, { alignSelf: 'stretch' }]}>
                    <View style={styles.problemSubHeadingContainer}>
                      <GridIcon color={Colors.light.hereworksBlack600} />
                      <Text style={styles.problemSubHeading}>Asset</Text>
                    </View>
                    <TouchableOpacity
                      // @ts-ignore
                      onPress={() => navigation.navigate('AssetManagement', {
                        assetId: selectedProblem.buttoninstanceid ?? '-',
                      })
                      }
                    >
                      <RightArrow12 />
                    </TouchableOpacity>
                  </View>
                  <Text style={styles.problemDetail}>{selectedProblem['instance-name']}</Text>
                  <Text style={[styles.problemDetail, { marginTop: -8 }]}>
                    {`ID: ${selectedProblem.buttoninstanceid ?? '-'}`}
                  </Text>
                </View>
              )}
              <View style={styles.problemSingleGroup}>
                <View style={styles.problemSubHeadingContainer}>
                  <LocationIcon />
                  <Text style={styles.problemSubHeading}>Location</Text>
                </View>
                <Text style={styles.problemDetail}>
                  {`${
                    selectedProblem['location-floor']
                      ? `${selectedProblem['location-floor']}, `
                      : ''
                  }${selectedProblem['location-room'] ?? ''}`}
                </Text>
              </View>
              <View style={styles.problemSingleGroup}>
                <View style={styles.problemSubHeadingContainer}>
                  <PriorityIcon />
                  <Text style={styles.problemSubHeading}>Priority</Text>
                </View>
                <Text style={styles.problemDetail}>
                  {getPriorityText(selectedProblem.priority)}
                </Text>
              </View>
              <View style={styles.problemSingleGroup}>
                <View style={styles.problemSubHeadingContainer}>
                  <ReportTimeIcon />
                  <Text style={styles.problemSubHeading}>Report time</Text>
                </View>
                <Text style={styles.problemDetail}>
                  {Moment(new Date(selectedProblem.createdon)).format('llll')}
                </Text>
              </View>
              {renderAssignee()}
              {selectedProblem.completedon && (
                <View style={[styles.problemSingleGroup, { zIndex: 4 }]}>
                  <View style={styles.problemSubHeadingContainer}>
                    <CompletedOnIcon24 />
                    <Text style={[styles.problemSubHeading, styles.completedColor]}>
                      Completed on
                    </Text>
                  </View>
                  {selectedProblem.initalCompletedTime && (
                    <Text style={[styles.problemDetail, styles.completedColor]}>
                      {Moment(new Date(selectedProblem.initalCompletedTime)).format('llll')}
                    </Text>
                  )}
                  {selectedProblem.completedon && (
                    <Text style={[styles.problemDetail, styles.completedColor]}>
                      {Moment(new Date(selectedProblem.completedon)).format('llll')}
                    </Text>
                  )}
                </View>
              )}
              <View style={[styles.problemSingleGroup, { zIndex: 4 }]}>
                <View style={styles.alignedRow}>
                  <View
                    onLayout={(e) => setSLATitleWidth(e.nativeEvent.layout.width)}
                    style={{ flexDirection: 'row' }}
                  >
                    <SLATimeIcon />
                    <Text style={styles.problemSubHeading}>SLA time: </Text>
                  </View>
                  <Text style={[styles.problemDetail, styles.slaTimeText]}>
                    {parseSlaTime(selectedProblem.SLA) ?? 'No SLA Set'}
                  </Text>
                </View>
                {selectedProblem.SolvedInTime && selectedProblem.status === 'completed' && (
                  <View style={{ flexDirection: 'row' }}>
                    <Text
                      style={[
                        styles.problemDetail,
                        styles.slaTimeDescriptionWeb,
                        { width: SLATitleWidth },
                      ]}
                    >
                      Solved in:
                    </Text>
                    <View>
                      {selectedProblem.SolvedInTime && (
                        <Text
                          style={[
                            styles.problemDetail,
                            styles.slaTimeText,
                            selectedProblem.SolvedInTime.hours <= selectedProblem.SLA
                              ? styles.completedColor
                              : styles.exceededColor,
                            { marginTop: 12 },
                          ]}
                        >
                          {Moment.duration({
                            hours: selectedProblem.SolvedInTime.hours,
                            minutes: selectedProblem.SolvedInTime.minutes,
                            seconds: selectedProblem.SolvedInTime.seconds,
                          }).humanize()}
                        </Text>
                      )}
                    </View>
                  </View>
                )}
              </View>
              {selectedProblem.startedon && (
                <View style={[styles.problemSingleGroup, { zIndex: 4 }]}>
                  <View style={styles.problemSubHeadingContainer}>
                    <StartedOnIcon24 />
                    <Text style={styles.problemSubHeading}>Started on</Text>
                  </View>
                  {selectedProblem.initalStartedTime && (
                    <Text style={styles.problemDetail}>
                      {Moment(new Date(selectedProblem.initalStartedTime)).format('llll')}
                    </Text>
                  )}
                  <Text style={styles.problemDetail}>
                    {Moment(new Date(selectedProblem.startedon)).format('llll')}
                  </Text>
                </View>
              )}
              {selectedProblem.initalStartedTime && (
                <View style={[styles.problemSingleGroup, { zIndex: 4 }]}>
                  <View style={styles.problemSubHeadingContainer}>
                    <ReopenedOnIcon24 />
                    <Text style={styles.problemSubHeading}>Reopened on</Text>
                  </View>
                  {selectedProblem.initalStartedTime && (
                    <Text style={styles.problemDetail}>
                      {Moment(new Date(selectedProblem.startedon)).format('llll')}
                    </Text>
                  )}
                </View>
              )}
            </View>
            <View style={[styles.problemSubGroup, { zIndex: 4, marginTop: 22 }]}>
              <View style={{ flex: 1 }}>
                <View style={[styles.problemSubHeadingContainer, { alignItems: 'center' }]}>
                  <NoteIcon />
                  <Text style={[styles.problemSubHeading, { flex: 0 }]}>Note</Text>
                  <Text
                    style={[styles.noteSaveStatus, { flex: 1, marginLeft: 8, alignSelf: 'center' }]}
                  >
                    {' (this will be sent to the reporter)'}
                  </Text>
                </View>
                <SendNote id={selectedProblem.id} value={noteValue} problems={problems} />
              </View>
            </View>
          </ScrollView>
        )}
      </Animatable.View>
  ) : (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          margin: 30,
        }}
      >
        <NoTaskIcon />
        <Text style={styles.nothingHereTitle}>There`s nothing here.</Text>
        <Text style={styles.nothingHereDescription}>
          Click on a ticket on the left-hand side to view details.
        </Text>
      </View>
  ));

  const getStartedOnDate = (startedon) => Moment(new Date(startedon)).format('llll');

  const renderSelectedProblemMobile = () => selectedProblem !== undefined && (
      <KeyboardAvoidingView
        style={styles.selectedProblemContainerMobile}
        behavior={Platform.OS === 'ios' ? 'position' : 'padding'}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          ref={(ref) => {
            scrollView.current = ref;
          }}
          onScroll={handleOnScroll}
          scrollEventThrottle={16}
          contentContainerStyle={{ paddingBottom: 100, flexGrow: 1 }}
          nestedScrollEnabled={true}
        >
          {renderSharePane()}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 20,
              marginBottom: 8,
              marginTop: 14,
            }}
          >
            {!selectedProblem.reporterImage && <AvatarIcon />}
            {selectedProblem.reporterImage && (
              <Image
                style={styles.reporterAvatar}
                source={{ uri: selectedProblem.reporterImage }}
              />
            )}
            {selectedProblem.reporter && (
              <Text style={styles.reporterText}>
                Reported by: {selectedProblem.reporter?.split(/[^\w\sá]/)[0]}
              </Text>
            )}
            {!selectedProblem.reporter && (
              <Text style={styles.reporterText}>Reported anonymously</Text>
            )}
          </View>
          {selectedProblem?.name.length > 50 && (
            <TouchableOpacity
              style={[
                styles.problemSubGroupMobile,
                {
                  height: otherExpanded ? undefined : 200,
                },
              ]}
              onPress={() => setOtherExpanded(!otherExpanded)}
            >
              <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                  <View style={styles.problemSubHeadingContainer}>
                    <InfoIcon />
                    <Text style={styles.problemSubHeading}>Description</Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <Text
                    style={[styles.problemDetail, { marginRight: 16, marginBottom: 23 }]}
                    numberOfLines={otherExpanded ? undefined : 7}
                  >
                    {selectedProblem?.name}
                  </Text>
                </View>
              </View>
              <FontAwesome
                size={24}
                style={{
                  color: Colors.light.hereworksBlack400,
                  position: 'absolute',
                  bottom: 10,
                  right: 19,
                }}
                name={otherExpanded ? 'angle-up' : 'angle-down'}
              />
            </TouchableOpacity>
          )}
          {selectedProblem.images && (
            <>
              <Modal
                isVisible={imageModalVisible}
                animationIn={'fadeIn'}
                animationOut={'fadeOut'}
                useNativeDriver={true}
              >
                <TouchableOpacity
                  onPress={() => {
                    analytics('Close image modal');
                    setImageModalVisible(!imageModalVisible);
                  }}
                  style={{ height: '100%', flex: 1 }}
                >
                  <Image
                    source={{
                      uri: selectedProblem.images[0]?.filename,
                    }}
                    resizeMode={'center'}
                    style={{ width: undefined, height: '100%' }}
                  />
                </TouchableOpacity>
              </Modal>
              <TouchableOpacity
                onPress={() => {
                  analytics('Open image modal');
                  setImageModalVisible(!imageModalVisible);
                }}
                style={[styles.problemSubGroupMobile, { height: 114, paddingHorizontal: 0 }]}
              >
                <Image
                  source={{
                    uri: selectedProblem.images[0]?.filename,
                  }}
                  resizeMode={'cover'}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: 8,
                  }}
                />
              </TouchableOpacity>
            </>
          )}
          {selectedProblem['instance-name'] && (
            <View style={styles.problemSubGroupMobile}>
              <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <GridIcon color={Colors.light.hereworksBlack600} />
                  <View style={{ justifyContent: 'center' }}>
                    <View>
                      <Text
                        style={{
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 18,
                          lineHeight: 23,
                          color: Colors.light.hereworksBlack600,
                          marginStart: 8,
                        }}
                      >
                        Asset
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={{ alignSelf: 'center' }}
                      onPress={() => {
                        // @ts-ignore
                        navigation.navigate('AssetManagement', {
                          assetId: selectedProblem.buttoninstanceid ?? '-',
                        });
                        setModalVisible(false);
                      }}
                    >
                      <RightArrow12 />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.problemDetail}>{selectedProblem['instance-name'] ?? ''}</Text>
                  <Text style={[styles.problemDetail, { marginTop: -8 }]}>{`ID: ${
                    selectedProblem.buttoninstanceid ?? '-'
                  }`}</Text>
                </View>
              </View>
            </View>
          )}
          <View style={styles.problemSubGroupMobile}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <LocationIcon />
                  <Text style={styles.problemSubHeading}>Location</Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.problemDetail}>{`${selectedProblem['location-floor'] ?? ''}, ${
                  selectedProblem['location-room'] ?? ''
                }`}</Text>
              </View>
            </View>
          </View>
          <View style={styles.problemSubGroupMobile}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <PriorityIcon />
                  <Text style={styles.problemSubHeading}>Priority</Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.problemDetailMobile}>
                  {getPriorityText(selectedProblem.priority)}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.problemSubGroupMobile}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <ReportTimeIcon />
                  <Text style={styles.problemSubHeading}>Report time</Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.problemDetailMobile}>
                  {Moment(selectedProblem.createdon).format('llll')}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.problemSubGroupMobile}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={[styles.problemSubHeadingContainer, { flex: 1 }]}>
                  <SLATimeIcon />
                  <View style={{ flex: 1, justifyContent: 'center' }}>
                    <Text style={[styles.problemSubHeading, { flex: 0 }]}>SLA time: </Text>
                    {selectedProblem.SolvedInTime && selectedProblem.status === 'completed' && (
                      <Text
                        style={[
                          styles.problemDetail,
                          styles.slaTimeDescription,
                          { marginStart: styles.problemSubHeading.marginLeft },
                        ]}
                      >
                        Solved in:
                      </Text>
                    )}
                  </View>
                </View>
              </View>
              <View style={{ flex: 1, justifyContent: 'center', paddingTop: 3 }}>
                <View
                  style={{
                    flex: 1,
                    justifyContent:
                      selectedProblem.SolvedInTime && selectedProblem.status === 'completed'
                        ? 'flex-end'
                        : 'center',
                  }}
                >
                  <Text style={[styles.problemDetailMobile]}>
                    {parseSlaTime(selectedProblem.SLA) ?? 'No SLA Set'}
                  </Text>
                </View>
                {selectedProblem.SolvedInTime && selectedProblem.status === 'completed' && (
                  <View style={{ flex: 1, paddingTop: 3 }}>
                    <Text
                      style={[
                        styles.problemDetail,
                        styles.slaTimeText,
                        selectedProblem.SolvedInTime.hours <= selectedProblem.SLA
                          ? styles.completedColor
                          : styles.exceededColor,
                      ]}
                    >
                      {Moment.duration({
                        hours: selectedProblem.SolvedInTime.hours,
                        minutes: selectedProblem.SolvedInTime.minutes,
                        seconds: selectedProblem.SolvedInTime.seconds,
                      }).humanize()}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>
          {selectedProblem.startedon && (
            <View style={styles.problemSubGroupMobile}>
              <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                  <View style={styles.problemSubHeadingContainer}>
                    <StartedOnIcon24 />
                    <Text style={styles.problemSubHeading}>Started on</Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  {selectedProblem.initalStartedTime && (
                    <Text style={[styles.problemDetailMobile, styles.extraDateLabel]}>
                      {Moment(new Date(selectedProblem.initalStartedTime)).format('llll')}
                    </Text>
                  )}
                  <Text style={styles.problemDetailMobile}>
                    {getStartedOnDate(selectedProblem.startedon)}
                  </Text>
                </View>
              </View>
            </View>
          )}
          {(selectedProblem.completedon || selectedProblem.initalCompletedTime) && (
            <View style={styles.problemSubGroupMobile}>
              <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                  <View style={styles.problemSubHeadingContainer}>
                    <CompletedOnIcon24 />
                    <Text style={[styles.problemSubHeading, styles.completedColor]}>
                      Completed on
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  {selectedProblem.initalCompletedTime && (
                    <Text
                      style={[
                        styles.problemDetailMobile,
                        styles.completedColor,
                        styles.extraDateLabel,
                      ]}
                    >
                      {Moment(selectedProblem.initalCompletedTime).format('llll')}
                    </Text>
                  )}
                  {selectedProblem.completedon && (
                    <Text style={[styles.problemDetailMobile, styles.completedColor]}>
                      {Moment(selectedProblem.completedon).format('llll')}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          )}
          {selectedProblem.initalStartedTime && (
            <View style={styles.problemSubGroupMobile}>
              <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                  <View style={styles.problemSubHeadingContainer}>
                    <ReopenedOnIcon24 />
                    <Text style={styles.problemSubHeading}>Reopened on</Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.problemDetailMobile}>
                    {Moment(selectedProblem.startedon).format('llll')}
                  </Text>
                </View>
              </View>
            </View>
          )}
          {renderAssigneeMobile()}
          <View style={[styles.problemSubGroupMobile, { height: undefined }]}>
            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <View style={styles.problemSubHeadingContainer}>
                  <NoteIcon />
                  <Text style={styles.problemSubHeading}>Note</Text>
                </View>
              </View>
              <View style={{ flex: 2 }}>
                <SendNote id={selectedProblem.id} value={noteValue} problems={problems} />
              </View>
            </View>
          </View>
          {/* </TouchableOpacity> */}
        </ScrollView>
        <View style={[Style.boxShadow, styles.problemButtonsMobile]}>
          {renderStatusButtons()}
          {showSpinner && (
            <View
              style={{
                position: 'absolute',
                alignSelf: 'center',
                height: '100%',
                width: '100%',
                borderRadius: 32,
                backgroundColor: '#00000070',
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator size={20} color="#fff" />
            </View>
          )}
        </View>
      </KeyboardAvoidingView>
  );

  const renderItem = ({ item }) => {
    const isSelected: boolean = Platform.OS === 'web' && item.id === selectedProblem?.id;
    return (
      <ReportListItem
        onPress={() => {
          analytics('Problem Selected');
          setSelectedProblem(item);
          setNoteValue(item.notes);
          setModalVisible(true);
          setShowAssigneeDropdown(false);
        }}
        isSelected={isSelected}
        style={[
          styles.shadow,
          styles.listProblemItem,
          { marginHorizontal: !renderMobile() ? 30 : 20 },
        ]}
        index={item.id}
        status={item.status}
      >
        <View
          style={[styles.priorityCircle, { backgroundColor: getPriorityColor(item.priority) }]}
        />
        {item.status === 'inprogress' && (
          <InProgressIcon style={styles.checkmarkStyle} isSelected={isSelected} />
        )}
        {item.status === 'paused' && (
          <PausedStatusIcon style={styles.checkmarkStyle} isSelected={isSelected} />
        )}
        {item.status === 'completed' && (
          <CompletedIcon style={styles.checkmarkStyle} isSelected={isSelected} />
        )}
        {item.status !== 'inprogress'
          && item.status !== 'paused'
          && item.status !== 'completed' && <FlagIcon style={styles.checkmarkStyle} />}
        <View style={{ flex: 1, marginRight: 32 }}>
          <Text
            numberOfLines={1}
            style={[
              styles.problemTitle,
              isSelected && item.status !== 'paused' && { color: '#FFF' },
            ]}
          >
            {item.name}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={[
                styles.problemArea,
                isSelected && item.status !== 'paused' && { color: '#FFF' },
              ]}
              numberOfLines={1}
            >
              {`${item['location-room'] ?? ''} | ${item['location-floor'] ?? ''}`}
            </Text>
            {!renderMobile() && (
              <Text
                style={[
                  styles.problemTime,
                  isSelected && item.status !== 'paused' && { color: '#FFF' },
                ]}
              >
                {Moment(item.createdon).calendar()}
              </Text>
            )}
          </View>
        </View>
        <ProblemArrowIcon
          stroke={isSelected && item.status !== 'paused' ? '#FFF' : undefined}
          style={{ position: 'absolute', right: 20 }}
        />
      </ReportListItem>
    );
  };

  const renderFilterTag = (title, value, onPress) => (
    <View
      style={{
        flexDirection: 'row',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: Colors.light.hereworksBlue100,
        backgroundColor: Colors.light.hereworksWhite100,
        alignSelf: 'flex-start',
        paddingVertical: 4,
        paddingHorizontal: 12,
        marginRight: 4,
        marginBottom: 4,
        alignItems: 'center',
      }}
    >
      <Text
        style={{
          color: Colors.light.hereworksBlack400,
          fontFamily: 'Roboto_400Regular',
          fontSize: 12,
          lineHeight: 14,
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          color: Colors.light.hereworksBlack600,
          fontFamily: 'Roboto_400Regular',
          fontSize: 12,
          lineHeight: 14,
        }}
      >
        {value}
      </Text>
      <TouchableOpacity
        onPress={() => {
          applyFilters();
          onPress();
        }}
      >
        <DeleteIcon12 style={{ marginLeft: 12 }} />
      </TouchableOpacity>
    </View>
  );

  const anyFilterApplied = () => lowPriorityFilter
    || medPriorityFilter
    || highPriorityFilter
    || criticalPriorityFilter
    || doneFilter
    || withoutStatusFilter
    || inProgressFilter
    || pausedFilter
    || selectedAssignee !== ''
    || selectedLocation !== ''
    || selectedAssetId !== ''
    || selectedUserGroupId !== ''
    || range.end !== null;

  const applyFilters = () => {
    setProblems([]);
    setAllProblems([]);
    setLoading(true);
  };

  const resetAllFilters = () => {
    // @ts-ignore
    if (route.params?.assetId !== undefined) {
      navigation.navigate('Tickets');
    }
    // @ts-ignore
    navigation.setParams({ reportId: undefined });
    setSelectedProblem(undefined);
    setLowPriorityFilter(false);
    setMedPriorityFilter(false);
    setHighPriorityFilter(false);
    setCriticalPriorityFilter(false);
    setDoneFilter(false);
    setWithoutStatusFilter(false);
    setInProgressFilter(false);
    setPausedFilter(false);
    setSelectedAssignee('');
    setSelectedLocation('');
    setSelectedAssetId('');
    setSelectedUserGroupId('');
    setRange({ start: null, end: null });
    applyFilters();
  };

  const renderFilterTags = () => (
    <View
      style={{
        marginLeft: renderMobile() ? 20 : 30,
        marginRight: 53,
        marginTop: 12,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'center',
      }}
    >
      {lowPriorityFilter
        && renderFilterTag('Priority: ', 'Low', () => {
          setLowPriorityFilter(false);
        })}
      {medPriorityFilter
        && renderFilterTag('Priority: ', 'Moderate', () => {
          setMedPriorityFilter(false);
        })}
      {highPriorityFilter
        && renderFilterTag('Priority: ', 'High', () => {
          setHighPriorityFilter(false);
        })}
      {criticalPriorityFilter
        && renderFilterTag('Priority: ', 'Critical', () => {
          setCriticalPriorityFilter(false);
        })}
      {doneFilter
        && renderFilterTag('Status: ', 'Done', () => {
          setDoneFilter(false);
        })}
      {inProgressFilter
        && renderFilterTag('Status: ', 'In Progress', () => {
          setInProgressFilter(false);
        })}
      {pausedFilter
        && renderFilterTag('Status: ', 'Paused', () => {
          setPausedFilter(false);
        })}
      {withoutStatusFilter
        && renderFilterTag('Status: ', 'Without status', () => {
          setWithoutStatusFilter(false);
        })}
      {selectedAssignee !== ''
        && renderFilterTag('Assignee: ', assigneeName(selectedAssignee), () => {
          setSelectedAssignee('');
        })}
      {selectedAssetId !== ''
        && renderFilterTag('Asset: ', assetName(selectedAssetId), () => {
          setSelectedAssetId('');
        })}
      {selectedUserGroupId !== ''
        && renderFilterTag('User Group: ', userGroupName(selectedUserGroupId), () => {
          setSelectedUserGroupId('');
        })}
      {selectedLocation !== ''
        && renderFilterTag('Location: ', locationName(selectedLocation), () => {
          setSelectedLocation('');
        })}
      {range.end !== null
        && renderFilterTag(
          'Date: ',
          Moment(range.start).isSame(range.end, 'date')
            ? `${Moment(range.start).format('ll')}`
            : `${Moment(range.start).format('ll')} - ${Moment(range.end).format('ll')}`,
          () => {
            setRange({ start: null, end: null });
            setProblems([]);
            setAllProblems([]);
            setLoading(true);
          },
        )}
      {anyFilterApplied() && (
        <TouchableOpacity
          style={styles.resetAllFiltersButton}
          onPress={() => {
            analytics('Reset All Button Pressed');
            resetAllFilters();
          }}
        >
          <Text style={styles.resetAllFiltersText}>RESET ALL FILTERS</Text>
        </TouchableOpacity>
      )}
    </View>
  );

  const renderList = () => (
    <View style={{ zIndex: -1, flex: 1 }}>
      {newReports && (
        <TouchableOpacity
          style={{
            backgroundColor: Colors.light.hereworksPaleBlue800,
            height: 80,
            marginLeft: 30,
            marginRight: 53,
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={() => {
            setLoading(true);
            setNewReports(false);
          }}
        >
          <Text style={{ color: '#000', fontFamily: 'Roboto_400Regular', fontSize: 16 }}>
            There are new tickets. Click here to load them.
          </Text>
        </TouchableOpacity>
      )}
      {renderFilterTags()}
      {renderMobile() && (
        <FlatList
          data={problems}
          renderItem={renderItem}
          ref={(ref) => {
            flatList.current = ref;
          }}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={() => {
                setProblems([]);
                setAllProblems([]);
                setRefreshing(true);
                setLoading(true);
              }}
            />
          }
          keyExtractor={(item, index) => String(index)}
        />
      )}
      {!renderMobile() && (
        <ScrollView
          style={{ zIndex: -1 }}
          contentContainerStyle={{ marginTop: 4 }}
          scrollEventThrottle={16}
          ref={(ref) => {
            scrollView.current = ref;
          }}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={() => {
                setRefreshing(true);
                setLoading(true);
              }}
            />
          }
        >
          <FlatList
            data={problems}
            renderItem={renderItem}
            initialNumToRender={500}
            keyExtractor={(item, index) => String(index)}
          />
        </ScrollView>
      )}
    </View>
  );

  const renderFilter = () => (
    <View style={{ alignItems: 'flex-end' }}>
    <TicketsFilter
      mobile={renderMobile()}
      onConfirm={onConfirm}
      range={range}
      selectedRange={selectedRange}
      setSelectedRange={setSelectedRange}
      users={users}
      allProblems={allProblems}
      instances={instances}
      locations={locations}
      setLoading={setLoading}
      setFilterOpen={setFilterOpen}
      setProblems={setProblems}
      setAllProblems={setAllProblems}
      setSelectedProblem={setSelectedProblem}
      userGroups={userGroups}
      lowPriorityFilter={lowPriorityFilter}
      setLowPriorityFilter={setLowPriorityFilter}
      medPriorityFilter={medPriorityFilter}
      setMedPriorityFilter={setMedPriorityFilter}
      highPriorityFilter={highPriorityFilter}
      setHighPriorityFilter={setHighPriorityFilter}
      criticalPriorityFilter={criticalPriorityFilter}
      setCriticalPriorityFilter={setCriticalPriorityFilter}
      doneFilter={doneFilter}
      setDoneFilter={setDoneFilter}
      inProgressFilter={inProgressFilter}
      setInProgressFilter={setInProgressFilter}
      pausedFilter={pausedFilter}
      setPausedFilter={setPausedFilter}
      withoutStatusFilter={withoutStatusFilter}
      setWithoutStatusFilter={setWithoutStatusFilter}
      selectedAssignee={selectedAssignee}
      setSelectedAssignee={setSelectedAssignee}
      selectedAssetId={selectedAssetId}
      setSelectedAssetId={setSelectedAssetId}
      selectedUserGroupId={selectedUserGroupId}
      setSelectedUserGroupId={setSelectedUserGroupId}
      selectedLocation={selectedLocation}
      setSelectedLocation={setSelectedLocation}
    />
    </View>
  );

  const renderMobileFilter = () => (
    <Modal
      isVisible={true}
      style={{ margin: 0, backgroundColor: '#fff' }}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      onBackButtonPress={() => setFilterOpen(false)}
      statusBarTranslucent
    >
      <ScrollView
        style={{
          flex: 1,
          marginTop: topSafeAreaInset + 16,
          paddingBottom: bottomSafeAreaInset,
        }}
      >
        <Text
          style={{
            marginLeft: 24,
            fontFamily: 'Poppins_500Medium',
            fontSize: 16,
            color: Colors.light.hereworksBlack600,
          }}
        >
          Filter
        </Text>
        {renderFilter()}
      </ScrollView>
    </Modal>
  );

  const renderWebSearchAndFilter = () => (
    <>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 18 }}>
        <View style={styles.searchBox}>
          <SearchIcon style={{ marginLeft: 14 }} />
          <TextInput
            placeholder="Search tickets"
            // @ts-ignore
            style={[styles.searchText, !renderMobile() ? { outlineStyle: 'none' } : {}]}
            onChangeText={(text) => {
              setSearchValue(text);
            }}
            onFocus={() => {
              if (filterOpen) {
                setFilterOpen(false);
              }
            }}
          />
        </View>
        <View style={{ flex: 1, marginRight: 63, alignItems: 'flex-end' }}>
          <TouchableOpacity
            style={styles.filterButton}
            onPress={() => {
              analytics('Filter button');
              setFilterOpen(!filterOpen);
            }}
          >
            <Text style={styles.filterButtonTitle}>Filter</Text>
            <FilterIcon16 />
          </TouchableOpacity>
        </View>
      </View>
      {filterOpen && (
        <>
          <TouchableOpacity
            style={{
              position: 'absolute',
              backgroundColor: 'transparent',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
            onPress={() => setFilterOpen(false)}
          />
          {renderFilter()}
        </>
      )}
    </>
  );

  const renderMobileSearchAndFilter = () => (
    <View>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 60 }}>
        <View style={{ flex: 1, flexDirection: 'row', marginRight: 20 }}>
          <Text style={styles.header}>Tickets</Text>
          <View style={{ flex: 1, zIndex: 2 }}>
            <TouchableOpacity
              style={[styles.filterButton, { marginTop: 20 }]}
              onPress={() => {
                analytics('Filter button');
                setFilterOpen(!filterOpen);
              }}
            >
              <Text style={styles.filterButtonTitle}>Filter</Text>
              <FilterIcon16 />
            </TouchableOpacity>
          </View>
          {filterOpen && renderMobileFilter()}
        </View>
      </View>
      <View style={styles.searchBoxMobile}>
        <SearchIcon style={{ marginLeft: 14 }} />
        <TextInput
          placeholder="Search tickets"
          placeholderTextColor={Colors.light.hereworksPrimaryGrey700}
          // @ts-ignore
          style={[styles.searchText, !renderMobile() ? { outlineStyle: 'none' } : {}]}
          onChangeText={(text) => {
            setProblems(
              allProblems?.filter((e) => e.name?.toLowerCase().includes(text.toLowerCase())),
            );
          }}
        />
      </View>
    </View>
  );

  const deleteTicket = () => {
    analytics('Delete ticket');
    setShowSpinner(true);
    deleteIssue(selectedProblem.id)
      .then((response) => {
        if (response?.errorText) {
          showAlert('Error', response?.errorText);
        } else {
          showAlert(
            'Ticket successfully deleted',
            <Text numberOfLines={2}>
              Ticket{' '}
              <Text style={styles.alertBoldText} numberOfLines={1}>
                {`"${selectedProblem.name}"`}
              </Text>{' '}
              - successfully deleted
            </Text>,
            false,
            null,
            null,
            'Okay',
          );
          setSelectedProblem(undefined);
          setLoading(true);
          setModalVisible(false);
        }
        setShowSpinner(false);
        emitter?.emit('event.updatenotifications', {});
      })
      .catch(() => setShowSpinner(false));
  };

  const renderMoreOptions = () => (
    <View
      style={[
        styles.settingsModal,
        Platform.OS === 'android' ? { elevation: 50 } : {},
        renderMobile() ? { top: 105, right: 20 } : { top: 50, right: 20 },
      ]}
    >
      <TouchableOpacity
        style={styles.modalItem}
        onPress={() => {
          setMoreOptionsVisible(!moreOptionsVisible);
          showAlert(
            'Delete the Ticket',
            <Text numberOfLines={2}>
              Do you want to delete the ticket -{' '}
              <Text numberOfLines={1} style={styles.alertBoldText}>
                {` "${selectedProblem.name}"`}
              </Text>
              ?
            </Text>,
            true,
            deleteTicket,
            'Delete',
          );
        }}
      >
        <View style={{ width: 32, alignItems: 'center' }}>
          <BinIcon />
        </View>
        <Text style={styles.modalText}>Delete ticket</Text>
      </TouchableOpacity>
    </View>
  );

  const modalHideAnimationDuration: number = 300;

  const renderModal = () => (
    <Modal
      animationIn={'slideInRight'}
      animationOut={'slideOutRight'}
      isVisible={modalVisible}
      swipeDirection={'right'}
      onSwipeComplete={() => setModalVisible(false)}
      onBackButtonPress={() => setModalVisible(false)}
      animationOutTiming={modalHideAnimationDuration}
      statusBarTranslucent={true}
      propagateSwipe={true}
      // useNativeDriver={true}
      backdropColor={Colors.light.hereworksBackground}
      style={{ flex: 1, margin: 0, backgroundColor: Colors.light.hereworksBackground }}
      scrollTo={handleScrollTo}
      scrollOffset={scrollOffset}
      scrollOffsetMax={200}
    >
      {/* <View style={{ flex: 1, backgroundColor: Colors.light.hereworksBackground }}> */}
      <View style={styles.modalHeaderContainer}>
        <TouchableOpacity
          onPress={() => {
            analytics('Close modal');
            setModalVisible(!modalVisible);
          }}
          style={{ marginRight: 10 }}
          hitSlop={{
            top: 30,
            bottom: 40,
            left: 50,
            right: 50,
          }}
        >
          <LeftArrow />
        </TouchableOpacity>
        <Text style={styles.modalProblemTitle}>
          {selectedProblem?.name.length > 50 ? 'Other' : selectedProblem?.name}
        </Text>
        <Modal
          isVisible={moreOptionsVisible}
          style={{
            margin: 0,
          }}
          hasBackdrop={false}
          animationIn={'fadeIn'}
          animationOut={'fadeOut'}
          onBackButtonPress={() => setMoreOptionsVisible(false)}
        >
          <TouchableOpacity
            style={{ flex: 1 }}
            onPress={() => {
              analytics('More options');
              setMoreOptionsVisible(!moreOptionsVisible);
            }}
          >
            {renderMoreOptions()}
          </TouchableOpacity>
        </Modal>
      </View>
      {renderMobile() ? renderSelectedProblemMobile() : renderSelectedProblemWeb()}
      {/* </View> */}
    </Modal>
  );

  return (
    <View
      style={{
        flex: 2,
        backgroundColor: Colors.light.hereworksBackground,
        paddingTop: 90,
        marginTop: -90,
        flexDirection: 'row',
      }}
    >
      {renderMobile() && renderModal()}
      <View style={{ flex: 1, zIndex: 1 }}>
        {!renderMobile() && renderWebSearchAndFilter()}
        {renderMobile() && renderMobileSearchAndFilter()}
        {renderList()}
      </View>
      {!renderMobile() && (
        <View style={{ flex: 1, paddingVertical: 30, paddingEnd: 30 }}>
          {renderSelectedProblemWeb()}
        </View>
      )}
      <Modal
        isVisible={(loading || showSpinner) && !refreshing && !renderMobile()}
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        presentationStyle={'overFullScreen'}
        hasBackdrop={false}
        style={{ margin: 0 }}
      >
        <View style={{ flex: 1, justifyContent: 'center', backgroundColor: 'transparent' }}>
          <ActivityIndicator size={'large'} color={Colors.light.hereworksLoading} />
        </View>
      </Modal>
      <AlertModal
        modalVisible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        error={alertError}
        action={alertAction}
        actionTitle={alertActionTitle}
        closeTitle={alertCloseTitle}
        setModalVisible={setAlertVisible}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  searchBox: {
    marginLeft: 30,
    height: 40,
    width: 240,
    borderRadius: 24,
    backgroundColor: Colors.light.hereworksBackground,
    marginRight: 12,
    borderColor: Colors.light.hereworksPaleBlue900,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchBoxMobile: {
    marginHorizontal: 20,
    height: 40,
    borderRadius: 8,
    backgroundColor: Colors.light.hereworksBackground,
    marginTop: 13,
    borderColor: Colors.light.hereworksPaleBlue900,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: -1,
  },
  searchText: {
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 9,
  },
  noteText: {
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    height: 100,
    backgroundColor: Colors.light.hereworksBackground,
    marginVertical: 10,
    marginRight: 10,
    borderRadius: 6,
    paddingLeft: 14,
    paddingVertical: 12,
    textAlignVertical: 'top',
  },
  reporterText: {
    color: Colors.light.hereworksPrimaryGrey800,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    marginLeft: 6,
  },
  listProblemItem: {
    marginVertical: 6,
    height: 80,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
  },
  problemTitle: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 14,
    paddingRight: 38,
  },
  modalProblemTitle: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 18,
    color: 'black',
    marginLeft: 14,
    marginRight: 14,
    textAlign: 'center',
    flex: 1,
  },
  fullProblemTitle: {
    flex: 1,
    fontFamily: 'Poppins_400Regular',
    fontSize: 22,
    lineHeight: 29,
    color: Colors.light.hereworksPrimaryGrey,
    marginTop: 30,
    marginBottom: 14,
  },
  problemArea: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
    marginHorizontal: 14,
  },
  problemDetail: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey900,
    marginVertical: 12,
  },
  problemDetailMobile: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey900,
    marginRight: 12,
  },
  problemTime: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    lineHeight: 18.75,
    color: Colors.light.hereworksBlack500,
    marginLeft: 10,
  },
  checkmarkStyle: {
    marginLeft: 20,
  },
  pausedStyle: {
    marginLeft: 20,
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    backgroundColor: Colors.light.hereworksPaleBlue700,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlack100,
  },
  selectedProblemContainer: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 8,
    paddingBottom: 12,
  },
  selectedProblemContainerMobile: {
    flex: 1,
    backgroundColor: Colors.light.hereworksBackground,
  },
  nothingHereTitle: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 20,
    color: Colors.light.hereworksDarkBlue800,
  },
  nothingHereDescription: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksDarkBlue800,
    textAlign: 'center',
    marginHorizontal: 150,
  },
  priorityCircle: {
    position: 'absolute',
    top: 9,
    left: 9,
    width: 14,
    height: 14,
    borderRadius: 7,
  },
  shadow: {
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.05,
    shadowRadius: 20,
    shadowColor: 'black',
    elevation: 5,
  },
  problemSubHeading: {
    flex: 1,
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 18,
    lineHeight: 23,
    color: Colors.light.hereworksBlack600,
    marginLeft: 8,
  },
  problemSubHeadingContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  problemSubGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: 16,
    justifyContent: 'space-between',
  },
  problemSingleGroup: {
    alignItems: 'flex-start',
    flex: 1,
    minWidth: '40%',
    maxWidth: '49%',
    margin: 8,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBackground,
    padding: 16,
    backgroundColor: Colors.light.hereworksBackground,
  },
  problemSubGroupMobile: {
    height: 100,
    flexDirection: 'row',
    marginHorizontal: 20,
    marginVertical: 6,
    paddingHorizontal: 20,
    backgroundColor: 'white',
    alignItems: 'center',
    borderRadius: 8,
  },
  problemDivider: {
    height: 2,
    marginTop: 14,
    marginBottom: 16,
    zIndex: -1,
    backgroundColor: Colors.light.hereworksPaleBlue900,
  },
  problemHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 24,
    justifyContent: 'center',
  },
  problemButtons: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 16,
    marginBottom: 6,
    alignItems: 'center',
    flexDirection: 'row',
  },
  problemButtonsMobile: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 32,
    backgroundColor: 'white',
    position: 'absolute',
    padding: 7,
    bottom: 24,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue100,
  },
  filterText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 18,
    marginBottom: 4,
    marginRight: 20,
    color: Colors.light.hereworksBlack600,
  },
  roleText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 10,
    color: Colors.light.hereworksBlack400,
  },
  assigneeAvatarContainer: {
    width: 30,
    height: 30,
    borderRadius: 15,
    overflow: 'hidden',
  },
  assigneeAvatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    overflow: 'hidden',
  },
  reporterAvatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    overflow: 'hidden',
  },
  header: {
    fontSize: 32,
    marginLeft: 20,
    fontFamily: 'Poppins_600SemiBold',
  },
  modalHeaderContainer: {
    flexDirection: 'row',
    height: 50,
    marginTop: 50,
    alignItems: 'center',
    marginHorizontal: 20,
    backgroundColor: Colors.light.hereworksBackground,
  },
  resumeButtonMobile: {
    marginRight: 8,
    flexDirection: 'row',
    backgroundColor: Colors.light.hereworksPaleBlue800,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    paddingLeft: 24,
    paddingRight: 29,
  },
  pauseButtonMobile: {
    marginRight: 8,
    flexDirection: 'row',
    backgroundColor: Colors.light.hereworksYellow700,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    paddingLeft: 24,
    paddingRight: 29,
  },
  resumeButtonText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: '#2173C4',
    marginLeft: 15,
  },
  pauseButtonText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 15,
  },
  resolveText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksGreen400,
    marginLeft: 8,
  },
  modalText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    color: Colors.light.hereworksPrimaryGrey800,
  },
  modalItem: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 1,
  },
  settingsModal: {
    height: 36,
    width: 120,
    zIndex: 1,
    backgroundColor: '#fff',
    borderRadius: 8,
    overflow: 'hidden',
    position: 'absolute',
  },
  noteSaveStatus: {
    alignSelf: 'flex-end',
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    color: Colors.light.hereworksPrimaryGrey700,
  },
  statusButton: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 34,
    paddingVertical: 11,
    paddingHorizontal: 16,
    borderWidth: 1,
  },
  extraDateLabel: {
    marginBottom: 6,
  },
  completedColor: {
    color: Colors.light.hereworksGreen,
  },
  exceededColor: {
    color: Colors.light.hereworksUrgentRed,
  },
  filterButtonTitle: {
    fontSize: 14,
    lineHeight: 22,
    marginVertical: 5,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlue500,
    marginRight: 8,
  },
  filterButton: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    alignSelf: 'flex-end',
  },
  resetAllFiltersButton: {
    justifyContent: 'center',
    alignSelf: 'center',
    borderBottomWidth: 1,
    borderColor: Colors.light.hereworksPrimaryGrey,
  },
  resetAllFiltersText: {
    color: Colors.light.hereworksPrimaryGrey,
    fontSize: 12,
    fontFamily: 'Roboto_500Medium',
  },
  slaTimeContent: {
    marginTop: 12,
    flexDirection: 'row',
  },
  slaTimeDescription: {
    fontFamily: 'Roboto_500Medium',
    marginVertical: 0,
  },
  slaTimeDescriptionWeb: {
    marginTop: 12,
    marginBottom: 0,
  },
  slaTimeText: {
    marginVertical: 0,
  },
  alertBoldText: {
    fontFamily: 'Roboto_700Bold',
  },
  alignedRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sharePane: {
    height: 40,
    paddingVertical: 8,
    paddingHorizontal: 24,
    borderTopEndRadius: 10,
    backgroundColor: isWeb ? Colors.light.hereworksWhite200 : undefined,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    zIndex: 2,
  },
  shareTicketText: {
    fontSize: 12,
    lineHeight: 18,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksBlack400,
  },
  shareDropdownPane: {
    position: 'absolute',
    backgroundColor: '#FFF',
    borderColor: Colors.light.hereworksBlue100,
    borderWidth: 1.5,
    end: 20,
    top: 45,
    borderRadius: 12,
    zIndex: 1,
  },
  shareDropdownButton: {
    paddingVertical: 10,
    paddingHorizontal: 12,
    width: '100%',
  },
  shareDropdownText: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksPrimaryGrey,
    textAlign: 'center',
  },
});
