import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ScanIcon16 = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="M5 2H2v3m0 6v3h3m6 0h3v-3m0-6V2h-3M3 8h10"
      stroke="#0058AD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ScanIcon16;
