import { FontAwesome } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import * as Analytics from 'expo-firebase-analytics';
import { ActivityIndicator } from 'react-native-paper';
import * as Animatable from 'react-native-animatable';
import UserIcon16 from '../components/16pxIcons/UserIcon16';
import EditIcon from '../components/EditIcon';
import SearchIcon from '../components/SearchIcon';

import UserGroupCard from '../components/UserManagement/UserGroupCard';
import Colors from '../constants/Colors';
import { RootTabScreenProps } from '../types';
import GroupEditModal from '../components/UserManagement/GroupEditModal';
import UserRowWeb from '../components/UserManagement/UserRowWeb';
import getUserGroups from '../requests/GetUserGroups';
import getUsers from '../requests/GetUsers';
import AlertModal from '../components/AlertModal';

// eslint-disable-next-line no-unused-vars
export default function UserMgmtScreen({ navigation }: RootTabScreenProps<'UserManagement'>) {
  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });
  const renderMobile = (): boolean => Platform.OS !== 'web' || dimensions.window?.width < 500 || dimensions.window?.height < 500;

  const [searchDropdownVisible, setSearchDropdownVisible] = useState(false);
  const [searchDropdownSelection, setSearchDropdownSelection] = useState('Users');
  const [searchValue, setSearchValue] = useState('');

  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [sortDropdownSelection, setSortDropdownSelection] = useState('Sort');

  const [manageGroupsSelected] = useState(true);
  const [addGroupsModalVisible, setAddGroupsModalVisible] = useState(false);

  const [allUsers, setAllUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  const [loading, setLoading] = useState(true);
  const [updateUsers, setUpdateUsers] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');

  const showAlert = (title: string, error: boolean = true) => {
    setAlertTitle(title);
    setAlertError(error);
    setAlertVisible(true);
  };

  useEffect(() => {
    getUsers().then((response) => {
      if (response.Users?.length > 0) {
        setAllUsers(response.Users);
      }
    });
  }, [updateUsers]);

  useEffect(() => {
    getUserGroups().then((response) => {
      setLoading(false);
      if (response?.UserGroups?.length > 0) {
        setUserGroups(response.UserGroups);
      }
    });
  }, [loading]);

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'UserMgmt',
      name: title,
    });
  };

  const renderSortDropdown = () => (
    <View style={[styles.dropdownView, renderMobile() ? { width: '100%', top: 50 } : {}]}>
      <TouchableOpacity
        onPress={() => {
          setSortDropdownSelection('by alphabet A-Z');
          setSortDropdownVisible(!sortDropdownVisible);
        }}
      >
        <Text style={styles.dropdownItemText}>by alphabet A-Z</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setSortDropdownSelection('by alphabet Z-A');
          setSortDropdownVisible(!sortDropdownVisible);
        }}
      >
        <Text style={styles.dropdownItemText}>by alphabet Z-A</Text>
      </TouchableOpacity>
    </View>
  );

  const renderSearchDropdown = () => (
    <View style={[styles.dropdownView, renderMobile() ? { width: '100%', top: 50 } : {}]}>
      <TouchableOpacity
        onPress={() => {
          setSearchDropdownSelection('Users');
          setSearchDropdownVisible(!searchDropdownVisible);
        }}
      >
        <Text style={styles.dropdownItemText}>Users</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setSearchDropdownSelection('Groups');
          setSearchDropdownVisible(!searchDropdownVisible);
        }}
      >
        <Text style={styles.dropdownItemText}>Groups</Text>
      </TouchableOpacity>
    </View>
  );

  const renderAddButtons = () => (
    <View
      style={{
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'flex-end',
        marginHorizontal: renderMobile() ? 24 : 40,
      }}
    >
      {!renderMobile() && (
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: 22,
              lineHeight: 33,
              fontFamily: 'Poppins_400Regular',
              color: manageGroupsSelected
                ? Colors.light.hereworksBlack600
                : Colors.light.hereworksBlack300,
            }}
          >
            User management
          </Text>
        </View>
      )}
      <TouchableOpacity
        style={[
          styles.buttonCommon,
          {
            borderColor: Colors.light.hereworksLightBlue,
            backgroundColor: Colors.light.hereworksLightBlue,
            flex: renderMobile() ? 1 : null,
            paddingHorizontal: renderMobile() ? 0 : 24,
          },
        ]}
        onPress={() => {
          setAddGroupsModalVisible(!addGroupsModalVisible);
          analytics('Add groups');
        }}
      >
        <Text
          style={{
            color: '#fff',
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            lineHeight: 24,
            marginVertical: 8,
            marginRight: 8,
          }}
          numberOfLines={1}
        >
          Add groups
        </Text>
        <EditIcon color={'#fff'} />
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.buttonCommon,
          {
            borderColor: Colors.light.hereworksLightBlue,
            backgroundColor: '#fff',
            marginLeft: 24,
            flex: renderMobile() ? 1 : null,
            paddingHorizontal: renderMobile() ? 0 : 24,
          },
        ]}
        onPress={() => {
          showAlert('Feature coming soon!', false);
          // setModalVisible(!modalVisible);
          // Analytics.logEvent('button_press', {
          //   screen: 'Analytics',
          //   name: 'Edit',
          // });
        }}
      >
        <Text
          style={{
            color: Colors.light.hereworksLightBlue,
            fontFamily: 'Roboto_400Regular',
            fontSize: renderMobile() ? 16 : 18,
            lineHeight: renderMobile() ? 24 : 26,
            marginVertical: 8,
            marginRight: 8,
          }}
          numberOfLines={1}
        >
          Add users
        </Text>
        <UserIcon16 />
      </TouchableOpacity>
    </View>
  );

  const renderGroups = () => (
    <View style={{ zIndex: 1, alignSelf: 'stretch' }}>
      {loading && (
        <ActivityIndicator
          color={Colors.light.hereworksLoading}
          size={'large'}
          style={{ marginTop: 40 }}
        />
      )}
      {userGroups
        .filter((group) => {
          let compareValue = group.Name;
          if (searchDropdownSelection === 'Groups') {
            compareValue = searchValue ?? group.Name;
          }
          return group.Name?.toLowerCase().includes(compareValue.toLowerCase());
        })
        .sort((a, b) => {
          if (sortDropdownSelection === 'by alphabet A-Z') {
            return a.Name.localeCompare(b.Name);
          }
          return b.Name.localeCompare(a.Name);
        })
        .map((group) => (
          <Animatable.View
            key={`${group.UserGroupId}`}
            style={{ alignSelf: 'stretch', zIndex: 1 }}
            animation={'fadeIn'}
          >
            <UserGroupCard
              title={group.Name}
              description={group.Description}
              groupId={group.UserGroupId}
              userCount={group.UsersCount}
              allUsers={allUsers}
              updateUsers={() => setUpdateUsers(!updateUsers)}
              searchValue={searchDropdownSelection === 'Users' ? searchValue : ''}
            />
          </Animatable.View>
        ))}
    </View>
  );

  const renderUsers = () => (
    <View style={{ marginHorizontal: renderMobile() ? 24 : 40, marginBottom: 20 }}>
      <Text
        style={{
          fontFamily: 'Poppins_400Regular',
          fontSize: 22,
          lineHeight: 33,
          color: Colors.light.hereworksBlack600,
          marginTop: 40,
          marginBottom: 35,
        }}
      >
        Users
      </Text>
      <View
        style={{
          flexDirection: 'row',
          marginVertical: 12,
          alignSelf: 'stretch',
        }}
      >
        <Text style={[styles.headerText, { marginLeft: 50, flex: 1 }]}>MEMBER</Text>
        <Text style={[styles.headerText, { marginLeft: 40, flex: 1 }]}>CONTACTS</Text>
        <View style={{ flex: 1 }}>
          <Text
            style={[
              styles.headerText,
              {
                alignSelf: 'flex-end',
                marginRight: 28,
              },
            ]}
          >
            LAST ACTIVITY
          </Text>
        </View>
      </View>
      <View
        style={{
          backgroundColor: '#fff',
          paddingHorizontal: 24,
          borderRadius: 10,
          borderWidth: 1,
          borderColor: Colors.light.hereworksWhite300,
          paddingBottom: 24,
        }}
      >
        {allUsers
          ?.map((user) => {
            const modifiedUser = { ...user };
            if (!user?.FullName) {
              modifiedUser.FullName = `${user?.GivenName ?? ''} ${user?.FamilyName ?? ''}`;
            }
            return modifiedUser;
          })
          ?.filter((group) => {
            let compareValue = group.FullName;
            if (searchDropdownSelection === 'Users') {
              compareValue = searchValue ?? group.FullName;
            }
            return (
              !(searchValue || searchValue?.length)
              || group.FullName?.toLowerCase().includes(compareValue.toLowerCase())
            );
          })
          ?.sort((a, b) => {
            if (sortDropdownSelection === 'by alphabet Z-A') {
              return b.FullName.localeCompare(a.FullName);
            }
            return a.FullName.localeCompare(b.FullName);
          })
          ?.map((user, key) => (
            <View
              key={key}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                borderBottomWidth: 1,
                borderColor: Colors.light.hereworksWhite500,
                paddingBottom: 4,
              }}
            >
              <Text>{key + 1}</Text>
              <UserRowWeb user={user} Name={''} />
            </View>
          ))}
      </View>
    </View>
  );

  const updatedGroup = () => {
    setAddGroupsModalVisible(false);
    setLoading(true);
    showAlert('Group added successfully!', false);
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <GroupEditModal
        modalVisible={addGroupsModalVisible}
        mobile={renderMobile()}
        setModalVisible={setAddGroupsModalVisible}
        updatedGroup={updatedGroup}
        users={allUsers}
        title={undefined}
        description={undefined}
        groupId={undefined}
      />
      {!renderMobile() && renderAddButtons()}
      {renderMobile() && (
        <ScrollView style={{ width: '100%' }} contentContainerStyle={styles.scrollContainer}>
          <View style={{ marginBottom: 8, alignSelf: 'stretch', zIndex: 5 }}>
            <Text
              style={{
                marginLeft: 24,
                marginTop: 12,
                fontSize: 24,
                fontFamily: 'Poppins_600SemiBold',
                color: Colors.light.hereworksBlack600,
                alignSelf: 'flex-start',
              }}
            >
              User management
            </Text>
            <Text
              style={{
                marginLeft: 24,
                marginTop: 32,
                marginBottom: 16,
                fontSize: 20,
                fontFamily: 'Poppins_400Regular',
                color: Colors.light.hereworksBlack600,
              }}
            >
              Manage groups
            </Text>
            {renderAddButtons()}
            <TouchableOpacity
              style={[styles.dropdownBox, { marginHorizontal: renderMobile() ? 24 : 40 }]}
              onPress={() => setSearchDropdownVisible(!searchDropdownVisible)}
            >
              <Text style={styles.dropdownBoxText}>{searchDropdownSelection}</Text>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <FontAwesome
                  size={20}
                  style={{
                    color: Colors.light.hereworksLightBlue,
                    marginRight: 12,
                  }}
                  name={searchDropdownVisible ? 'angle-up' : 'angle-down'}
                />
              </View>
              {searchDropdownVisible && renderSearchDropdown()}
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.dropdownBox,
                { zIndex: 1, marginHorizontal: renderMobile() ? 24 : 40 },
              ]}
            >
              <TextInput
                style={styles.dropdownBoxText}
                placeholder={'Search'}
                placeholderTextColor={Colors.light.hereworksBlack400}
                onChangeText={(text) => {
                  setSearchValue(text);
                }}
              />
              <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 15 }}>
                <SearchIcon />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.dropdownBox, { marginHorizontal: renderMobile() ? 24 : 40 }]}
              onPress={() => setSortDropdownVisible(!sortDropdownVisible)}
            >
              <Text style={styles.dropdownBoxText}>{sortDropdownSelection}</Text>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <FontAwesome
                  size={20}
                  style={{
                    color: Colors.light.hereworksLightBlue,
                    marginRight: 12,
                  }}
                  name={sortDropdownVisible ? 'angle-up' : 'angle-down'}
                />
              </View>
              {sortDropdownVisible && renderSortDropdown()}
            </TouchableOpacity>
            {renderGroups()}
          </View>
        </ScrollView>
      )}
      {!renderMobile() && manageGroupsSelected && (
        <View style={{ alignSelf: 'stretch', backgroundColor: Colors.light.hereworksBackground, flex: 1 }}>
          <View style={{ flexDirection: 'row', alignSelf: 'stretch', zIndex: 4 }}>
            <View
              style={[styles.dropdownBox, { flex: 1, marginHorizontal: renderMobile() ? 24 : 40 }]}
            >
              <TouchableOpacity
                style={{ flexDirection: 'row', alignItems: 'center' }}
                onPress={() => setSearchDropdownVisible(!searchDropdownVisible)}
              >
                <View
                  style={{
                    flex: 1,
                    alignItems: 'flex-end',
                    marginLeft: 12,
                    marginRight: 4,
                  }}
                >
                  <FontAwesome
                    size={20}
                    style={{
                      color: Colors.light.hereworksLightBlue,
                      marginRight: 12,
                    }}
                    name={searchDropdownVisible ? 'angle-up' : 'angle-down'}
                  />
                </View>
                <Text style={[styles.dropdownBoxText, { marginLeft: 0, marginRight: 100 }]}>
                  {searchDropdownSelection}
                </Text>
                <View
                  style={{ width: 1, height: 24, backgroundColor: Colors.light.hereworksWhite500 }}
                />
                {searchDropdownVisible && renderSearchDropdown()}
              </TouchableOpacity>
              <TextInput
                // @ts-ignore
                style={[styles.dropdownBoxText, { outlineStyle: 'none', marginLeft: 18 }]}
                placeholder={'Search'}
                onChangeText={(text) => {
                  setSearchValue(text);
                }}
              />
              <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 15 }}>
                <SearchIcon />
              </View>
            </View>
            <TouchableOpacity
              style={[
                styles.dropdownBox,
                {
                  height: 40,
                  justifyContent: 'flex-start',
                  marginRight: 40,
                  width: 227,
                },
              ]}
              onPress={() => setSortDropdownVisible(!sortDropdownVisible)}
            >
              <Text style={[styles.dropdownBoxText, { marginLeft: 12, marginRight: 10 }]}>
                {sortDropdownSelection}
              </Text>
              <View
                style={{
                  alignItems: 'flex-end',
                  marginRight: 12,
                }}
              >
                <FontAwesome
                  size={20}
                  style={{
                    color: Colors.light.hereworksLightBlue,
                  }}
                  name={sortDropdownVisible ? 'angle-up' : 'angle-down'}
                />
              </View>
              {sortDropdownVisible && renderSortDropdown()}
            </TouchableOpacity>
          </View>
          <ScrollView>
            {renderGroups()}
            {renderUsers()}
          </ScrollView>
        </View>
      )}
      <AlertModal
        modalVisible={alertVisible}
        error={alertError}
        title={alertTitle}
        setModalVisible={setAlertVisible} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    backgroundColor: Colors.light.hereworksBackground,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: Colors.light.hereworksBackground,
    paddingTop: 130,
    marginTop: -90,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  dropdownBox: {
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
    borderRadius: 10,
    marginTop: 24,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    zIndex: 2,
  },
  dropdownBoxText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    marginVertical: 9.5,
    marginLeft: 12,
    flex: 1,
  },
  searchBoxText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    marginVertical: 9.5,
    marginLeft: 0,
    width: '100%',
    zIndex: -1,
  },
  dropdownItemText: {
    marginLeft: 12,
    marginVertical: 9,
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
  },
  dropdownView: {
    position: 'absolute',
    top: 61,
    left: 0,
    right: 0,
    zIndex: 5,
    borderRadius: 10,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksPaleBlue900,
    backgroundColor: '#fff',
  },
  buttonCommon: {
    borderRadius: 4,
    borderWidth: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    lineHeight: 15,
    letterSpacing: 0.7,
    color: Colors.light.hereworksBlack400,
  },
});
