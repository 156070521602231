import React, { Dispatch, SetStateAction } from 'react';
import { View, StyleSheet } from 'react-native';
import DropdownSelector from '../../DropdownSelector';

interface AirQualityDropdownSelectorProps {
  selectedFloor: string,
  setSelectedFloor: Dispatch<SetStateAction<string>>,
  selectedRoom: string,
  setSelectedRoom: Dispatch<SetStateAction<string>>,
  selectedSensor: string,
  setSelectedSensor: Dispatch<SetStateAction<string>>,
  floorList: { label: string, value: string }[],
  roomList: { label: string, value: string }[],
  sensorList: { label: string, value: string }[],
}

export const transformRoomName = (originalRoomName: string) => {
  let room = originalRoomName?.toLowerCase().split(' ')[0].split('room')[0].split('office')[0];
  room = room?.replace(/[^a-zA-Z0-9 ]/g, '');
  return room;
};

const AirQualityDropdownSelector = ({
  selectedFloor, setSelectedFloor, selectedRoom, setSelectedRoom, selectedSensor, setSelectedSensor,
  floorList, roomList, sensorList,
}: AirQualityDropdownSelectorProps) => <View style={styles.container}>
    <DropdownSelector label={'All floors'}
      style={styles.separation}
      selected={selectedFloor}
      setSelected={setSelectedFloor}
      onSelect={(item: string) => setSelectedFloor(item)}
      dataDrop={floorList} />
    <DropdownSelector label={'All rooms'}
      style={styles.separation}
      selected={selectedRoom}
      setSelected={setSelectedRoom}
      onSelect={(item: string) => setSelectedRoom(item)}
      dataDrop={roomList} />
    <DropdownSelector label={'All sensors'}
      selected={selectedSensor}
      setSelected={setSelectedSensor}
      onSelect={(item: string) => setSelectedSensor(item)}
      dataDrop={sensorList} />
  </View>;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  separation: {
    marginEnd: 8,
  },
});

export default AirQualityDropdownSelector;
