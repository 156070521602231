import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const OfficeIcon = (props: SvgProps) => (
  <Svg width={25} height={30} fill="none" {...props}>
    <Path
      d="m24.828 27.5-.003-.001V2.576L15.965 0 .04 6.027 0 6.035l.002 18.02 5.435-2.143V7.25l10.529-2.537-.002 21.556L.011 24.056l15.954 5.938V30l8.86-2.472V27.5h.003Z"
      fill="#fff"
    />
  </Svg>
);

export default OfficeIcon;
