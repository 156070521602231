import * as React from 'react';
import Svg, { SvgProps, Rect } from 'react-native-svg';

const PauseIcon = (props: SvgProps) => (
  <Svg
    width={9}
    height={12}
    fill="none"
    {...props}
  >
    <Rect width={3} height={12} rx={1} fill="#2173C4" />
    <Rect x={6} width={3} height={12} rx={1} fill="#2173C4" />
  </Svg>
);

export default PauseIcon;
