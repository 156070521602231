import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const TickIcon = (props: SvgProps) => (
  <Svg width={12} height={9} fill='none' {...props}>
    <Path
      d='m1.332 4 3.333 3.333 6-6'
      stroke={props.color ?? '#0058AD'}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default TickIcon;
