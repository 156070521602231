import {
  Platform, Pressable, StyleSheet, Text, View,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import Modal from 'react-native-modal';
import { ReactElement, useState } from 'react';
import Moment from 'moment';
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import Colors from '../../constants/Colors';
import Style from '../../constants/Style';
import TemplateIcon30 from '../30pxIcons/TemplateIcon30';
import MoveIcon from '../16pxIcons/MoveIcon';

type ContextType = {
  translateX: number;
  translateY: number;
};

interface Props {
  name: any;
  timestamp: any;
  hovered: boolean;
  editMode?: boolean;
  asset?: any;
  movingItem?: boolean;
  setMovingItem?: any;
}

const Asset = (props: Props) => {
  const {
    name, hovered, timestamp, asset,
  } = props;

  const [movingItem, setMovingItem] = useState(props.movingItem);

  const translateXsen = useSharedValue(asset?.GeoX);
  const translateYsen = useSharedValue(asset?.GeoY);

  const panGestureSen = useAnimatedGestureHandler<PanGestureHandlerGestureEvent, ContextType>({
    onStart: (event, context) => {
      context.translateX = translateXsen.value;
      context.translateY = translateYsen.value;
    },
    onActive: (event, context) => {
      translateXsen.value = event.translationX + context.translateX;
      translateYsen.value = event.translationY + context.translateY;
    },
    onFinish: () => {
      setMovingItem(false);
      props.setMovingItem(false);
    },
  });

  const transformStyleSen = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: translateXsen.value,
      },
      {
        translateY: translateYsen.value,
      },
    ],
  }));

  const [modalVisible, setModalVisible] = useState(false);

  const isMobile = (): boolean => Platform.OS !== 'web';

  const assetInfo = () => (
    <Animatable.View
      animation={'fadeIn'}
      style={[
        Style.boxShadow,
        {
          backgroundColor: '#fff',
          width: 280,
          marginHorizontal: 8,
          padding: 16,
          borderRadius: 10,
          zIndex: -1,
        },
      ]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={styles.buttonText}>{name}</Text>
      </View>
      <Text
        style={{
          fontFamily: 'Poppins_500Medium',
          fontSize: 14,
          lineHeight: 20,
          marginTop: 8,
          marginBottom: 4,
          color: Colors.light.hereworksBlack500,
        }}
      >
        Asset info
      </Text>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
        <Text
          style={{
            fontFamily: 'Poppins_400Regular',
            fontSize: 12,
            color: Colors.light.hereworksBlack400,
          }}
        >
          Last modified:
        </Text>
        <Text
          style={{
            fontFamily: 'Poppins_500Medium',
            fontSize: 12,
            color: Colors.light.hereworksBlack500,
            flex: 1,
            textAlign: 'right',
          }}
        >
          {Moment(timestamp).format('h:mm a, D MMM')}
        </Text>
      </View>
    </Animatable.View>
  );

  const assetIcon = () => (
    <View
      style={[
        {
          backgroundColor: '#fff',
          width: movingItem ? 44 : 32,
          height: movingItem ? 44 : 32,
          borderRadius: movingItem ? 22 : 16,
          borderWidth: movingItem ? 2 : 0,
          borderColor: '#fff',
          alignItems: 'center',
          justifyContent: 'center',
        },
        Style.circleShadow,
      ]}
    >
      <TemplateIcon30 />
    </View>
  );

  if (isMobile()) {
    return (
      <>
        <Pressable
          style={{ position: 'absolute' }}
          onPress={() => {
            if (isMobile()) {
              setModalVisible(true);
            }
          }}
        >
          <Animatable.View
            style={{
              backgroundColor: 'transparent',
              flexDirection: 'row',
            }}
          >
            {assetIcon()}
            {hovered && assetInfo()}
          </Animatable.View>
        </Pressable>
        <Modal
          isVisible={modalVisible}
          animationIn={'slideInUp'}
          animationOut={'slideOutDown'}
          swipeDirection={'down'}
          onSwipeComplete={() => setModalVisible(false)}
          onBackButtonPress={() => setModalVisible(false)}
          onBackdropPress={() => setModalVisible(false)}
          style={{
            margin: 0,
            flex: 1,
            alignItems: 'center',
          }}
        >
          {assetInfo()}
        </Modal>
      </>
    );
  }

  if (props.editMode) {
    return (
      <PanGestureHandler onGestureEvent={movingItem ? panGestureSen : null}>
        <Animated.View
          style={[{ position: 'absolute' }, transformStyleSen]}
        >
          <Animatable.View animation={'fadeInLeft'}>
          <Pressable style={{}}>
            {movingItem && assetIcon()}
            {!movingItem && <Animatable.View animation={'bounceIn'}>
            {assetIcon()}
              </Animatable.View>}
            {movingItem && <Pressable
                style={(state) => [
                  {
                    opacity: movingItem ? 1 : 0,
                    zIndex: state.hovered ? 10 : 3,
                    position: 'absolute',
                    bottom: -12,
                    right: -12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 4,
                    borderColor: Colors.light.hereworksBlue100,
                    borderWidth: 1,
                    borderRadius: 8,
                    backgroundColor: Colors.light.hereworksWhite100,
                  },
                ]}
                disabled={!movingItem}
                onPressIn={() => {
                  setMovingItem(true);
                }}
              >
                {(): ReactElement => (
                  <MoveIcon />
                )}
              </Pressable>}
          </Pressable>
          </Animatable.View>
        </Animated.View>
      </PanGestureHandler>
    );
  }
  return (
    <Animatable.View
      style={{
        position: 'absolute',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        top: asset.top,
        left: asset.left,
      }}
      animation={'fadeInLeft'}
    >
      {assetIcon()}
      {hovered && assetInfo()}
    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  assetScore: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 36,
    color: Colors.light.hereworksRed500,
    flex: 1,
  },
  assetRowTitle: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    color: Colors.light.hereworksBlack400,
    marginLeft: 12,
  },
  assetRowValue: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    textAlign: 'right',
    flex: 1,
  },
  buttonText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksBlack600,
    paddingHorizontal: 10,
  },
});

export default Asset;
