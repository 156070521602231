import React, { useEffect } from 'react';
import { StyleSheet, Animated, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Svg, {
  Defs, G, Mask, Path, Rect, Stop,
} from 'react-native-svg';
import { useRoute } from '@react-navigation/native';

const cloudOneInset = new Animated.Value(0);
const cloudTwoInset = new Animated.Value(0);
const cloudThreeInset = new Animated.Value(0);
const cloudFourInset = new Animated.Value(0);
const cloudFiveInset = new Animated.Value(0);

const BackgroundImage = () => {
  const routeName = useRoute().name.toLowerCase();
  useEffect(() => {
    Animated.parallel([
      Animated.timing(cloudOneInset, {
        toValue: 416,
        duration: 416 * 5,
        useNativeDriver: true,
      }),
      Animated.timing(cloudTwoInset, {
        toValue: 177,
        duration: 177 * 5,
        useNativeDriver: true,
      }),
      Animated.timing(cloudThreeInset, {
        toValue: 217,
        duration: 217 * 5,
        useNativeDriver: true,
      }),
      Animated.timing(cloudFourInset, {
        toValue: 28,
        duration: 28 * 5,
        useNativeDriver: true,
      }),
      Animated.timing(cloudFiveInset, {
        toValue: 448,
        duration: 448 * 5,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  return (
    <LinearGradient colors={['#BCE7F5', '#FAF8FB']} style={styles.background}>
      <View style={routeName !== 'home' && styles.sectionClouds}>
        <Animated.View style={[styles.cloud1, { start: cloudOneInset }]}>
          <Svg width="57" height="19" viewBox="0 0 57 19" fill="none">
            <Path d="M21.6848 0C10.7804 0 2.68478 12.6667 0 19L57 19C53.5304 5.32 41.9239 9.92222 36.5543 13.9333C36.1413 9.28889 29.1196 0 21.6848 0Z" fill="white" />
          </Svg>
        </Animated.View>
        <Animated.View style={[styles.cloud2, { start: cloudTwoInset }]}>
          <Svg width="57" height="19" viewBox="0 0 57 19" fill="none">
            <Path d="M35.3152 0C46.2196 0 54.3152 12.6667 57 19L0 19C3.46957 5.32 15.0761 9.92222 20.4457 13.9333C20.8587 9.28889 27.8804 0 35.3152 0Z" fill="white" />
          </Svg>
        </Animated.View>
        <Animated.View style={[styles.cloud3, { start: cloudThreeInset }]}>
          <Svg width="194" height="65" viewBox="0 0 194 65" fill="none">
            <Path d="M120.196 0C157.309 0 184.862 43.3333 194 65L0 65C11.8087 18.2 51.3116 33.9444 69.587 47.6667C70.9928 31.7778 94.8913 0 120.196 0Z" fill="white" />
          </Svg>
        </Animated.View>
        <Animated.View style={[styles.cloud4, { start: cloudFourInset }]}>
          <Svg width="57" height="19" viewBox="0 0 57 19" fill="none">
            <Path d="M21.6848 0C10.7804 0 2.68478 12.6667 0 19L57 19C53.5304 5.32 41.9239 9.92222 36.5543 13.9333C36.1413 9.28889 29.1196 0 21.6848 0Z" fill="white" />
          </Svg>
        </Animated.View>
        <Animated.View style={[styles.cloud5, { start: cloudFiveInset }]}>
          <Svg width="58" height="19" viewBox="0 0 58 19" fill="none">
            <Path d="M22.632 0C11.7277 0 3.63205 12.6667 0.947266 19H57.9473C54.4777 5.32 42.8712 9.92222 37.5016 13.9333C37.0886 9.28889 30.0668 0 22.632 0Z" fill="white" />
          </Svg>
        </Animated.View>
      </View>
      <Svg width={583} height={353} viewBox="0 0 583 353" fill="none" style={[styles.building, routeName !== 'home' && styles.sectionBuilding]}>
        <Mask id={`mask0_9912_396020_${routeName}`}
          // @ts-ignore
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse" x="0" y="0" width="583" height="353">
          <Rect width="582.537" height="353" fill={`url(#paint0_linear_9912_396020_${routeName})`} />
        </Mask>
        <G mask={`url(#mask0_9912_396020_${routeName})`}>
          <Path d="M29.7998 312.574H291.226C291.226 259.578 265.69 270.697 265.107 290.117C264.057 284.316 259.278 282.615 256.369 283.175C254.424 283.55 252.874 287.93 253.262 294.904C250.738 288.488 243.873 279.238 236.659 278.286C217.532 275.764 210.54 290.34 209.776 300.652C208.288 299.611 206.517 299.003 204.615 299.003C202.78 299.003 201.063 299.569 199.607 300.546C197.943 294.498 192.79 290.092 186.691 290.092C179.843 290.092 174.194 295.645 173.326 302.832C171.502 300.919 168.664 299.686 165.47 299.686C160.931 299.686 157.1 302.179 155.907 305.583C154.3 305.065 152.348 304.763 150.243 304.763C149.429 304.763 148.633 304.809 147.874 304.893C148.165 304.021 148.327 303.082 148.327 302.097C148.327 297.596 144.971 293.949 140.836 293.949C137.574 293.949 134.804 296.215 133.773 299.375C133.052 297.986 131.965 297.101 130.75 297.101C129.126 297.101 127.73 298.685 127.129 300.945C126.159 300.407 125.064 300.103 123.903 300.103C119.979 300.103 116.794 303.564 116.794 307.833C116.794 307.854 116.798 307.872 116.798 307.889C113.112 304.351 107.578 302.097 101.386 302.097C98.1083 302.097 95.0171 302.734 92.2827 303.856C92.8422 299.682 91.6065 295.825 88.7756 294.044C85.9541 292.272 82.2833 293.025 79.2484 295.613C75.269 286.065 66.4341 279.423 56.169 279.423C48.8475 279.423 29.7998 287.159 29.7998 312.574Z" fill="#18853C" />
          <Path d="M40.3982 114.809H217.213V121.517H40.3982V114.809Z" fill="#DFE4E8" />
          <Path d="M40.4012 121.096H217.216V312.514H40.4012V121.096Z" fill="#F0F5F9" />
          <Path d="M145.765 306.484H217.213V312.515H145.765V306.484Z" fill="#DFE4E8" />
          <Path d="M40.4008 306.484H67.7803V312.515H40.4008V306.484Z" fill="#DFE4E8" />
          <Path d="M217.213 121.096H280.622V312.514H217.213V121.096Z" fill="#DADFE3" />
          <Path d="M217.213 306.484H280.622V312.515H217.213V306.484Z" fill="#B8C0C9" />
          <Path d="M67.7778 81.8359H120.331V312.515H67.7778V81.8359Z" fill="#02629E" />
          <Path d="M67.7803 82.1641H81.7578V312.517H67.7803V82.1641Z" fill="#0270AB" />
          <Path d="M94.0551 82.1641H101.044V312.517H94.0551V82.1641Z" fill="#0270AB" />
          <Path d="M67.7778 80.8594H120.331V86.6742H67.7778V80.8594Z" fill="#00579C" />
          <Path d="M67.7778 107.91H120.331V111.095H67.7778V107.91Z" fill="#004679" />
          <Path d="M67.7778 164.023H120.331V167.209H67.7778V164.023Z" fill="#004679" />
          <Path d="M67.7778 220.139H120.331V223.324H67.7778V220.139Z" fill="#004679" />
          <Path d="M67.7798 276.254H120.333V279.439H67.7798V276.254Z" fill="#004679" />
          <G opacity="0.1">
            <G opacity="0.1">
              <Path opacity="0.1" d="M120.333 208.144L67.7339 260.792L67.7332 241.176L120.332 188.527L120.333 208.144Z" fill="#F0F5F9" />
            </G>
          </G>
          <Path d="M120.332 83.1113H145.766V312.513H120.332V83.1113Z" fill="#04486B" />
          <Path d="M120.332 80.8594H145.766V86.6742H120.332V80.8594Z" fill="#00355E" />
          <Path d="M120.334 107.91H145.768V111.095H120.334V107.91Z" fill="#05568D" />
          <Path d="M120.332 164.023H145.766V167.209H120.332V164.023Z" fill="#05568D" />
          <Path d="M120.334 220.139H145.768V223.324H120.334V220.139Z" fill="#05568D" />
          <Path d="M120.332 276.254H145.766V279.439H120.332V276.254Z" fill="#05568D" />
          <G opacity="0.1">
            <G opacity="0.1">
              <Path opacity="0.1" d="M120.331 188.531V208.146L145.766 171.041V151.426L120.331 188.531Z" fill="#F0F5F9" />
            </G>
          </G>
          <Path d="M217.213 114.809H280.622V121.096H217.213V114.809Z" fill="#B8C0C9" />
          <Path d="M154.624 274.02H208.354V306.481H154.624V274.02Z" fill="#02629E" />
          <G opacity="0.1">
            <G opacity="0.1">
              <Path opacity="0.1" d="M174.133 306.479H154.625L168.205 274.018H187.712L174.133 306.479Z" fill="#F0F5F9" />
            </G>
          </G>
          <G opacity="0.1">
            <G opacity="0.1">
              <Path opacity="0.1" d="M194.775 306.479H188.279L201.859 274.018H208.354L194.775 306.479Z" fill="#F0F5F9" />
            </G>
          </G>
          <Path d="M154.627 279.84H208.356V281.404H154.627V279.84Z" fill="#004679" />
          <Path d="M172.098 274.018H170.533V306.479H172.098V274.018Z" fill="#004679" />
          <Path d="M192.447 274.018H190.881V306.479H192.447V274.018Z" fill="#004679" />
          <Path d="M154.625 132.471H176.855V137.623H154.625V132.471Z" fill="#0773A4" />
          <Path d="M154.625 146.012H176.855V151.164H154.625V146.012Z" fill="#0773A4" />
          <Path d="M154.625 159.551H176.855V164.703H154.625V159.551Z" fill="#0773A4" />
          <Path d="M154.625 173.092H176.855V178.244H154.625V173.092Z" fill="#0773A4" />
          <Path d="M154.627 186.627H176.857V191.779H154.627V186.627Z" fill="#0773A4" />
          <Path d="M154.625 200.172H176.855V205.324H154.625V200.172Z" fill="#0773A4" />
          <Path d="M154.625 213.709H176.855V218.861H154.625V213.709Z" fill="#0773A4" />
          <Path d="M154.625 227.25H176.855V232.402H154.625V227.25Z" fill="#0773A4" />
          <Path d="M154.625 240.789H176.855V245.941H154.625V240.789Z" fill="#0773A4" />
          <Path d="M154.625 254.332H176.855V259.484H154.625V254.332Z" fill="#0773A4" />
          <Path d="M186.124 132.471H208.354V137.623H186.124V132.471Z" fill="#0773A4" />
          <Path d="M186.126 146.012H208.356V151.164H186.126V146.012Z" fill="#0773A4" />
          <Path d="M186.123 159.551H208.354V164.703H186.123V159.551Z" fill="#0773A4" />
          <Path d="M186.123 173.092H208.354V178.244H186.123V173.092Z" fill="#0773A4" />
          <Path d="M186.124 186.629H208.354V191.781H186.124V186.629Z" fill="#0773A4" />
          <Path d="M186.126 200.172H208.356V205.324H186.126V200.172Z" fill="#0773A4" />
          <Path d="M186.126 213.709H208.356V218.861H186.126V213.709Z" fill="#0773A4" />
          <Path d="M186.124 227.25H208.354V232.402H186.124V227.25Z" fill="#0773A4" />
          <Path d="M186.124 240.787H208.354V245.939H186.124V240.787Z" fill="#0773A4" />
          <Path d="M186.123 254.332H208.354V259.484H186.123V254.332Z" fill="#0773A4" />
          <Path d="M42.9505 132.471H65.1807V137.623H42.9505V132.471Z" fill="#0773A4" />
          <Path d="M42.9525 146.012H65.1826V151.164H42.9525V146.012Z" fill="#0773A4" />
          <Path d="M42.9525 159.551H65.1826V164.703H42.9525V159.551Z" fill="#0773A4" />
          <Path d="M42.9525 173.092H65.1826V178.244H42.9525V173.092Z" fill="#0773A4" />
          <Path d="M42.9525 186.627H65.1826V191.779H42.9525V186.627Z" fill="#0773A4" />
          <Path d="M42.9525 200.172H65.1826V205.324H42.9525V200.172Z" fill="#0773A4" />
          <Path d="M42.9525 213.709H65.1826V218.861H42.9525V213.709Z" fill="#0773A4" />
          <Path d="M42.9505 227.25H65.1807V232.402H42.9505V227.25Z" fill="#0773A4" />
          <Path d="M42.9525 240.787H65.1826V245.939H42.9525V240.787Z" fill="#0773A4" />
          <Path d="M42.9525 254.332H65.1826V259.484H42.9525V254.332Z" fill="#0773A4" />
          <Path d="M42.9525 274.018H65.1826V306.479H42.9525V274.018Z" fill="#02629E" />
          <Path d="M42.9503 274.018H48.2178V306.479H42.9503V274.018Z" fill="#0270AB" />
          <Path d="M226.972 129.986H236.513V297.276H226.972V129.986Z" fill="#04486B" />
          <Path d="M226.972 142.676H236.513V146.284H226.972V142.676Z" fill="#05568D" />
          <Path d="M226.972 177.361H236.513V180.969H226.972V177.361Z" fill="#05568D" />
          <Path d="M226.972 212.057H236.513V215.663H226.972V212.057Z" fill="#05568D" />
          <Path d="M226.972 246.742H236.513V250.35H226.972V246.742Z" fill="#05568D" />
          <Path d="M226.972 281.428H236.513V285.035H226.972V281.428Z" fill="#05568D" />
          <Path d="M244.305 129.986H253.845V297.276H244.305V129.986Z" fill="#04486B" />
          <Path d="M244.305 142.676H253.845V146.284H244.305V142.676Z" fill="#05568D" />
          <Path d="M244.303 177.363H253.843V180.971H244.303V177.363Z" fill="#05568D" />
          <Path d="M244.305 212.057H253.845V215.663H244.305V212.057Z" fill="#05568D" />
          <Path d="M244.305 246.742H253.845V250.35H244.305V246.742Z" fill="#05568D" />
          <Path d="M244.303 281.428H253.843V285.035H244.303V281.428Z" fill="#05568D" />
          <Path d="M261.323 129.986H270.862V297.276H261.323V129.986Z" fill="#04486B" />
          <Path d="M261.323 142.676H270.862V146.284H261.323V142.676Z" fill="#05568D" />
          <Path d="M261.323 177.361H270.862V180.969H261.323V177.361Z" fill="#05568D" />
          <Path d="M261.323 212.057H270.862V215.663H261.323V212.057Z" fill="#05568D" />
          <Path d="M261.323 246.742H270.862V250.35H261.323V246.742Z" fill="#05568D" />
          <Path d="M261.325 281.428H270.864V285.035H261.325V281.428Z" fill="#05568D" />
          <Path d="M224.016 297.518H226.469V312.576H224.016V297.518Z" fill="#6E331B" />
          <Path d="M229.926 280.537C229.926 292.565 227.83 302.316 225.243 302.316C222.657 302.316 220.561 292.565 220.561 280.537C220.561 268.508 222.657 258.758 225.243 258.758C227.83 258.758 229.926 268.508 229.926 280.537Z" fill="#18853C" />
          <Path d="M49.4302 297.518H51.8828V312.576H49.4302V297.518Z" fill="#6E331B" />
          <Path d="M55.3389 280.537C55.3389 292.565 53.2432 302.316 50.6565 302.316C48.0697 302.316 45.974 292.565 45.974 280.537C45.974 268.508 48.0697 258.758 50.6565 258.758C53.2432 258.758 55.3389 268.508 55.3389 280.537Z" fill="#18853C" />
          <Path d="M245.731 297.518H248.184V312.576H245.731V297.518Z" fill="#6E331B" />
          <Path d="M251.639 280.537C251.639 292.565 249.543 302.316 246.956 302.316C244.37 302.316 242.274 292.565 242.274 280.537C242.274 268.508 244.37 258.758 246.956 258.758C249.543 258.758 251.639 268.508 251.639 280.537Z" fill="#18853C" />
          <Path d="M267.442 297.518H269.896V312.576H267.442V297.518Z" fill="#6E331B" />
          <Path d="M273.351 280.537C273.351 292.565 271.255 302.316 268.669 302.316C266.083 302.316 263.986 292.565 263.986 280.537C263.986 268.506 266.083 258.756 268.669 258.756C271.255 258.756 273.351 268.506 273.351 280.537Z" fill="#18853C" />
          <Path d="M293.41 320.834H27.6119C25.3271 320.834 23.4756 318.982 23.4756 316.699C23.4756 314.414 25.3271 312.562 27.6119 312.562H293.41C295.695 312.562 297.547 314.414 297.547 316.699C297.547 318.982 295.695 320.834 293.41 320.834Z" fill="#1C9A46" />
          <Path d="M259.158 305.043C255.005 305.043 251.64 308.411 251.64 312.563H266.678C266.678 308.411 263.312 305.043 259.158 305.043Z" fill="#1C9A46" />
          <Path d="M269.357 308.344C267.027 308.344 265.138 310.231 265.138 312.563H273.577C273.577 310.231 271.686 308.344 269.357 308.344Z" fill="#1C9A46" />
          <Path d="M277.447 310.453C276.282 310.453 275.338 311.398 275.338 312.562H279.556C279.556 311.398 278.612 310.453 277.447 310.453Z" fill="#1C9A46" />
          <Path d="M284.532 308.629C282.363 308.629 280.603 310.389 280.603 312.559H288.461C288.461 310.389 286.702 308.629 284.532 308.629Z" fill="#1C9A46" />
          <Path d="M45.12 307.564C42.6258 307.564 40.6025 309.802 40.6025 312.562H49.6387C49.6387 309.802 47.6155 307.564 45.12 307.564Z" fill="#1C9A46" />
          <Path d="M50.6572 309.574C49.007 309.574 47.668 310.912 47.668 312.563H53.6465C53.6465 310.912 52.3061 309.574 50.6572 309.574Z" fill="#1C9A46" />
          <Path d="M56.8397 306.996C54.7038 306.996 52.9717 309.486 52.9717 312.56H60.7091C60.7091 309.486 58.977 306.996 56.8397 306.996Z" fill="#1C9A46" />
          <Path d="M146.599 306.436C143.161 306.436 140.374 309.222 140.374 312.661H152.826C152.826 309.222 150.038 306.436 146.599 306.436Z" fill="#1C9A46" />
          <Path d="M155.226 309.545C153.507 309.545 152.113 310.939 152.113 312.658H158.337C158.337 310.939 156.945 309.545 155.226 309.545Z" fill="#1C9A46" />
          <Path d="M307.586 41.1211H343.533V354.241H307.586V41.1211Z" fill="#DADFE3" />
          <Path d="M242.162 41.1211H307.586V354.241H242.162V41.1211Z" fill="#F0F5F9" />
          <Path d="M242.162 33.4629H307.586V41.1233H242.162V33.4629Z" fill="#DFE4E8" />
          <Path d="M307.586 33.4629H343.533V41.1233H307.586V33.4629Z" fill="#B8C0C9" />
          <Path d="M263.323 73.2148H286.422V109.053H263.323V73.2148Z" fill="#02629E" />
          <Path d="M263.324 73.2148H269.528V109.053H263.324V73.2148Z" fill="#0270AB" />
          <Path d="M263.323 109.053H286.422V114.61H263.323V109.053Z" fill="#B8C0C9" />
          <Path d="M263.323 141.145H286.422V176.983H263.323V141.145Z" fill="#02629E" />
          <Path d="M263.324 141.145H269.528V176.983H263.324V141.145Z" fill="#0270AB" />
          <Path d="M263.323 176.982H286.422V182.54H263.323V176.982Z" fill="#B8C0C9" />
          <Path d="M263.323 209.076H286.422V244.914H263.323V209.076Z" fill="#02629E" />
          <Path d="M263.324 209.076H269.528V244.914H263.324V209.076Z" fill="#0270AB" />
          <Path d="M263.323 244.914H286.422V250.471H263.323V244.914Z" fill="#B8C0C9" />
          <Path d="M263.323 277.004H286.422V312.842H263.323V277.004Z" fill="#02629E" />
          <Path d="M263.324 277.004H269.528V312.842H263.324V277.004Z" fill="#0270AB" />
          <Path d="M263.323 312.848H286.422V318.403H263.323V312.848Z" fill="#B8C0C9" />
          <Path d="M242.162 346.484H307.586V354.24H242.162V346.484Z" fill="#D0DAE4" />
          <Path d="M440.588 41.1211H476.536V354.241H440.588V41.1211Z" fill="#DADFE3" />
          <Path d="M375.166 41.1211H440.588V354.241H375.166V41.1211Z" fill="#F0F5F9" />
          <Path d="M375.166 33.4629H440.588V41.1233H375.166V33.4629Z" fill="#DFE4E8" />
          <Path d="M440.588 33.4629H476.536V41.1233H440.588V33.4629Z" fill="#B8C0C9" />
          <Path d="M396.329 73.2148H419.428V109.053H396.329V73.2148Z" fill="#02629E" />
          <Path d="M396.328 73.2148H402.532V109.053H396.328V73.2148Z" fill="#0270AB" />
          <Path d="M396.329 109.053H419.428V114.61H396.329V109.053Z" fill="#B8C0C9" />
          <Path d="M396.329 141.145H419.428V176.983H396.329V141.145Z" fill="#02629E" />
          <Path d="M396.328 141.145H402.532V176.983H396.328V141.145Z" fill="#0270AB" />
          <Path d="M396.329 176.982H419.428V182.54H396.329V176.982Z" fill="#B8C0C9" />
          <Path d="M396.329 209.076H419.428V244.914H396.329V209.076Z" fill="#02629E" />
          <Path d="M396.328 209.076H402.532V244.914H396.328V209.076Z" fill="#0270AB" />
          <Path d="M396.329 244.914H419.428V250.471H396.329V244.914Z" fill="#B8C0C9" />
          <Path d="M396.329 277.004H419.428V312.842H396.329V277.004Z" fill="#02629E" />
          <Path d="M396.328 277.004H402.532V312.842H396.328V277.004Z" fill="#0270AB" />
          <Path d="M396.329 312.848H419.428V318.403H396.329V312.848Z" fill="#B8C0C9" />
          <Path d="M375.166 346.484H440.588V354.24H375.166V346.484Z" fill="#D0DAE4" />
          <Path d="M440.588 346.484H476.536V354.24H440.588V346.484Z" fill="#B8C0C9" />
          <Path d="M307.586 48.5996H375.166V354.24H307.586V48.5996Z" fill="#02629E" />
          <Path d="M307.586 48.5996H325.559V354.785H307.586V48.5996Z" fill="#0270AB" />
          <Path d="M341.375 48.5996H350.36V354.785H341.375V48.5996Z" fill="#0270AB" />
          <Path d="M307.586 41.1211H375.166V48.5986H307.586V41.1211Z" fill="#00579C" />
          <Path d="M307.586 91.1309H375.166V95.2267H307.586V91.1309Z" fill="#004679" />
          <Path d="M307.586 163.293H375.166V167.389H307.586V163.293Z" fill="#004679" />
          <Path d="M307.586 235.451H375.166V239.547H307.586V235.451Z" fill="#004679" />
          <Path d="M307.586 307.611H375.166V311.707H307.586V307.611Z" fill="#004679" />
          <G opacity="0.1">
            <G opacity="0.1">
              <Path opacity="0.1" d="M375.166 220.027L307.525 287.73L307.526 262.506L375.167 194.803L375.166 220.027Z" fill="#F0F5F9" />
            </G>
          </G>
          <G opacity="0.1">
            <G opacity="0.1">
              <Path opacity="0.1" d="M375.164 159.704L307.524 227.406L307.525 48.6011L375.165 48.6018L375.164 159.704Z" fill="#F0F5F9" />
            </G>
          </G>
        </G>
        <Defs>
          <linearGradient id={`paint0_linear_9912_396020_${routeName}`} x1="291.268" y1="0" x2="291.268" y2="353" gradientUnits="userSpaceOnUse">
            <Stop offset="0.396418" />
            <Stop offset="1" stop-opacity="0" />
          </linearGradient>
        </Defs>
      </Svg>
      <LinearGradient colors={['#FFF0', '#FFFF']} style={styles.building} />
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  background: {
    width: '100%',
    aspectRatio: 2,
    height: 552,
    position: 'absolute',
    paddingStart: 28,
  },
  cloud1: {
    top: 53,
    position: 'absolute',
  },
  cloud2: {
    top: 91,
    position: 'absolute',
  },
  cloud3: {
    top: 97,
    position: 'absolute',
  },
  cloud4: {
    top: 208,
    position: 'absolute',
  },
  cloud5: {
    top: 262,
    position: 'absolute',
    zIndex: 10,
  },
  building: {
    position: 'absolute',
    start: 29,
    top: 151,
  },
  sectionBuilding: {
    top: 258,
  },
  sectionClouds: {
    top: 108,
  },
});

export default BackgroundImage;
