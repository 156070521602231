import React, { useState } from 'react';
// eslint-disable-next-line object-curly-newline
import { View, Text, StyleSheet, Pressable } from 'react-native';
import Colors from '../constants/Colors';
import TickIcon from './TickIcon';

export default function SelectUtility({ data, onSelect, compareToggled }) {
  const [userOption, setUserOption] = useState(data[0].value);
  const [prevOption, setPrevOption] = useState(data[1].value);
  const [nowMetric, setMetric] = useState(data[0].metric);

  const selectHandler = (value, metric) => {
    if (value !== userOption) {
      onSelect(value, metric, nowMetric, userOption);
      setPrevOption(userOption);
      setUserOption(value);
      setMetric(metric);
    }
  };

  return (
    <View style={{ flexDirection: 'row' }}>
      {data.map((item) => {
        let legendColor = Colors.light.hereworksBlack500;
        let compareLegendColor = Colors.light.hereworksBlack200;
        if (item.value === userOption) {
          legendColor = Colors.light.hereworksPurple500;
          compareLegendColor = Colors.light.hereworksYellow800;
        } else if (item.value === prevOption) {
          legendColor = Colors.light.hereworksDarkBlue;
          compareLegendColor = Colors.light.hereworksYellow500;
        }
        return (
          <Pressable
            style={
              item.value === userOption || item.value === prevOption
                ? styles.selected
                : styles.unselected
            }
            onPress={() => selectHandler(item.value, item.metric)}
            key={item.value}
          >
            <View
              style={{
                backgroundColor: legendColor,
                borderRadius: 2,
                width: 24,
                height: 2,
                marginLeft: 12,
              }}
            />
            {compareToggled && (
              <View
                style={{
                  backgroundColor: compareLegendColor,
                  borderRadius: 2,
                  width: 24,
                  height: 2,
                  marginLeft: 4,
                }}
              />
            )}
            <Text
              style={
                item.value === userOption || item.value === prevOption
                  ? styles.selectedText
                  : styles.unselectedText
              }
            >
              {item.value}
            </Text>
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 2,
                borderWidth: 1,
                borderColor:
                  item.value === userOption || item.value === prevOption
                    ? Colors.light.hereworksDarkBlue
                    : Colors.light.hereworksBlack100,
                marginRight: 8,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {(item.value === userOption || item.value === prevOption) && <TickIcon />}
            </View>
          </Pressable>
        );
      })}
    </View>
  );
}
const styles = StyleSheet.create({
  option: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center',
  },
  unselected: {
    backgroundColor: Colors.light.hereworksBlack50,
    borderColor: Colors.light.hereworksBlack100,
    borderWidth: 1.5,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 4,
    flexDirection: 'row',
  },
  selected: {
    backgroundColor: Colors.light.hereworksWhite100,
    borderColor: Colors.light.hereworksBlue50,
    borderWidth: 1.5,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 4,
    flexDirection: 'row',
  },
  selectedText: {
    color: Colors.light.hereworksBlack500,
    alignSelf: 'center',
    fontSize: 15,
    marginHorizontal: 20,
    marginVertical: 11,
    fontFamily: 'Poppins_400Regular',
  },
  unselectedText: {
    color: Colors.light.hereworksBlack300,
    alignSelf: 'center',
    fontSize: 15,
    marginHorizontal: 20,
    marginVertical: 11,
    fontFamily: 'Poppins_400Regular',
  },
});
