import React, { useRef } from 'react';
import { View } from 'react-native';

import { useHover } from 'react-native-web-hooks';
import Colors from '../constants/Colors';

export default function Link({ children, style }: { children: any; style?: any }) {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <View
      accessibilityRole="link"
      ref={ref}
      style={[isHovered && { backgroundColor: Colors.light.hereworksPrimaryGrey300 }, style]}
    >
      {children}
    </View>
  );
}
