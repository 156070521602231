import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const YellowLineIcon = (props: SvgProps) => (
  <Svg width={183} height={16} fill="none" {...props}>
    <Path
      d="M134 8.196h48"
      stroke="#F5B335"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="m158 14.196-5.196-9h10.392l-5.196 9Z" fill="#F5B335" />
    <Path
      d="M22.016 3.832v8.364h-1.092V3.832h1.092Zm7.01 0v.888h-2.28v7.476h-1.093V4.72h-2.292v-.888h5.664Z"
      fill="#696D70"
    />
    <Path
      d="M13.333 2H2.667c-.737 0-1.334.597-1.334 1.333V10c0 .736.597 1.333 1.334 1.333h10.666c.737 0 1.334-.597 1.334-1.333V3.333c0-.736-.597-1.333-1.334-1.333Z"
      fill="#003A70"
      stroke="#003A70"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.333 14h5.334M8 11.334V14"
      stroke="#003A70"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default YellowLineIcon;
