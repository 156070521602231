import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const NoNewIcon = (props: SvgProps) => (
  <Svg width={112} height={112} fill="none" {...props}>
    <Path
      d="m92.579 79.378.38-.126-.38.127.001.002.003.008.01.03.04.12.152.456.543 1.625 1.648 4.937 1.653 4.952.55 1.644.155.468.043.127.011.034.003.01.001.002v.001l.38-.125-.376.134c.64 1.793-.826 3.824-2.729 3.782H65.668l-.066.319c-3.782 18.192-29.475 18.204-33.264 0l-.067-.319H3.281a2.882 2.882 0 0 1-2.734-3.79v-.002l.002-.002.003-.01.011-.034.043-.127.156-.468.55-1.646 1.654-4.957 1.65-4.94.543-1.627.152-.456.04-.12.01-.03.003-.008v-.002l-.379-.128.38.127a12.572 12.572 0 0 1 8.793-8.223l.3-.077V56.046c0-8.656 4.635-26.755 24.055-32.904l.579-.184-.396-.46C31.379 14.02 37.658.35 48.966.448h.007C60.283.35 66.56 14.017 59.244 22.499l-.396.46.578.182c18.67 5.911 24.056 23.304 24.056 32.904V71.066l.3.078a12.581 12.581 0 0 1 8.797 8.235ZM56.632 13.886v-.015c0-4.225-3.436-7.662-7.662-7.662-4.225 0-7.662 3.437-7.662 7.662v.015c.383 10.132 14.94 10.135 15.324 0ZM20.221 56.03v14.707H77.72v-.4h-.4H77.719V56.046l-.007-.001h.007-.4l.4-.015c-1.426-38.056-56.051-38.1-57.498 0Zm18.483 41.556h-.549l.168.522c1.443 4.504 5.669 7.774 10.647 7.774 4.978 0 9.205-3.27 10.647-7.774l.168-.522H38.704ZM7.453 91.297l-.176.527H90.663l-.176-.527-3.374-10.095a6.843 6.843 0 0 0-6.512-4.703H17.339a6.84 6.84 0 0 0-6.508 4.69L7.453 91.297Z"
      fill="#8E8EA4"
      stroke="#fff"
      strokeWidth={0.8}
    />
    <Path
      d="m58.04 49.043.005.005.006.006c.425.393.936.604 1.463.604s1.037-.212 1.461-.604l-.272-.294.273.293c2.804-2.614 6.683 1.246 4.087 4.062a7.785 7.785 0 0 1-5.55 2.297h-.018c-1.644.075-3.819-.713-5.172-1.961-.672-.62-1.109-1.322-1.21-2.05-.1-.712.11-1.514.861-2.375a2.881 2.881 0 0 1 4.066.017ZM39.888 49.054l.001-.001c2.804-2.614 6.683 1.246 4.087 4.062a7.785 7.785 0 0 1-5.55 2.297h-.018c-1.644.075-3.818-.713-5.172-1.961-.672-.62-1.109-1.322-1.21-2.05-.1-.712.11-1.514.861-2.375a2.881 2.881 0 0 1 4.066.017l.005.005.006.006c.426.393.936.604 1.463.604s1.037-.212 1.461-.604ZM81.831 17.375l.415-.622h-1.637c-3.825-.149-3.825-5.615 0-5.763h7.02a2.881 2.881 0 0 1 2.397 4.48L86.4 20.912l-.415.622h1.638c3.824.148 3.824 5.614 0 5.762h-7.021a2.881 2.881 0 0 1-2.397-4.48l3.627-5.441ZM105.146 13.883l-.414.622h3.977c3.824.148 3.824 5.615 0 5.762H99.346c-2.261.026-3.67-2.608-2.396-4.475l.003-.004 5.968-8.957.414-.622h-3.984a2.881 2.881 0 0 1 0-5.763h9.37c2.262-.026 3.671 2.608 2.396 4.475l-.002.004-5.969 8.958Z"
      fill="#8E8EA4"
      stroke="#fff"
      strokeWidth={0.8}
    />
  </Svg>
);

export default NoNewIcon;
