import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const EllipsisIconVertical = (props: SvgProps) => (
  <Svg
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <Path
      fill="#2D5882"
      fillRule="evenodd"
      d="M12 17.5a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm0-7a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm2-5a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z"
      clipRule="evenodd"
    />
  </Svg>
);

export default EllipsisIconVertical;
