import React, { useEffect, useState } from 'react';
import {
  Alert,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Colors from '../constants/Colors';
import { RootTabScreenProps } from '../types';
import FlagIcon from '../components/FlagIcon';
import BoltIconDesktop from '../components/24pxIcons/BoltIconDesktop';
import MapIcon from '../components/MapIcon';
import GridIcon from '../components/24pxIcons/GridIcon';
import MonitorIcon from '../components/MonitorIcon';
import CheckCircle from '../components/CheckCircle';
import SunIcon from '../components/SunIcon';
import ChairIcon from '../components/ChairIcon';
import CoffeeIcon from '../components/CoffeeIcon';
import SmileIcon from '../components/SmileIcon';
import SearchIcon from '../components/SearchIcon';
import AvatarIcon from '../components/AvatarIcon';
import PeopleIcon24 from '../components/24pxIcons/PeopleIcon24';
import AlertModal from '../components/AlertModal';
import ComingSoon from '../components/ComingSoon';

// eslint-disable-next-line no-unused-vars
export default function MenuScreen({ route, navigation }: RootTabScreenProps<'MenuStack'>) {
  const [name, setName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isDev, setIsDev] = useState(false);
  const scanned = true;

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');

  const showAlert = (title: string, error: boolean = true) => {
    setAlertTitle(title);
    setAlertError(error);
    setAlertVisible(true);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AsyncStorage.getItem('name').then((n) => {
      setName(n);
    }) ?? '';

    AsyncStorage.getItem('environment').then((env) => {
      setIsDev(env === 'dev');
    });
  }, []);

  return (
    <View
      style={{
        justifyContent: 'flex-start',
        backgroundColor: '#fff',
        paddingTop: 40,
        flex: 1,
      }}
    >
      {scanned && (
        <>
          <ScrollView
            contentContainerStyle={{}}
            style={{ marginHorizontal: 24, alignSelf: 'stretch' }}
          >
            <View style={styles.searchContainer}>
              <TextInput
                style={styles.searchBoxText}
                placeholder={'Search'}
                placeholderTextColor={Colors.light.hereworksBlack400}
                onChangeText={setSearchValue}
              />
              <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 15 }}>
                <SearchIcon />
              </View>
            </View>
            {'solve analytics'.includes(searchValue.trim().toLowerCase()) && (
              <View style={{ marginBottom: 9 }}>
                <Text style={styles.sectionHeader}>ANALYTICS</Text>
                <TouchableOpacity
                  style={styles.buttonStyle}
                  onPress={() => {
                    navigation.navigate('Analytics');
                  }}
                >
                  <FlagIcon color={Colors.light.hereworksDarkBlue} />
                  <Text style={styles.buttonTitle}>{'Solve Analytics'}</Text>
                </TouchableOpacity>
              </View>
            )}
            <View style={{ flexDirection: 'row' }}>
              {'energy'.includes(searchValue.trim().toLowerCase()) && (
                <View style={{ flex: 1, marginRight: 15 }}>
                  <TouchableOpacity
                    style={styles.buttonStyle}
                    onPress={() => {
                      navigation.navigate('Energy');
                    }}
                  >
                    <BoltIconDesktop color={Colors.light.hereworksBlue400} />
                    <Text style={styles.buttonTitle}>{'Energy'}</Text>
                  </TouchableOpacity>
                </View>
              )}
              {'occupancy'.includes(searchValue.trim().toLowerCase()) && (
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={styles.buttonStyle}
                    onPress={() => {
                      showAlert('Feature coming soon!', false);
                    }}
                  >
                    <GridIcon color={Colors.light.hereworksBlue400} />
                    <Text style={styles.buttonTitle}>{'Occupancy'}</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
            <View style={{ flexDirection: 'row' }}>
              {'it'.includes(searchValue.trim().toLowerCase()) && (
                <View style={{ flex: 1, marginRight: 15 }}>
                  <TouchableOpacity
                    style={styles.buttonStyle}
                    onPress={() => {
                      showAlert('Feature coming soon!', false);
                    }}
                  >
                    <MonitorIcon color={Colors.light.hereworksBlue400} />
                    <Text style={styles.buttonTitle}>{'IT'}</Text>
                  </TouchableOpacity>
                </View>
              )}
              {'amenities'.includes(searchValue.trim().toLowerCase()) && (
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={styles.buttonStyleYellow}
                    onPress={() => {
                      showAlert('Feature coming soon!', false);
                    }}
                  >
                    <CheckCircle color={Colors.light.hereworksBlue400} />
                    <Text style={styles.buttonTitle}>{'Amenities'}</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
            <View style={{ flexDirection: 'row' }}>
              {'external environment'.includes(searchValue.trim().toLowerCase()) && (
                <View style={{ flex: 1, marginRight: 15 }}>
                  <TouchableOpacity
                    style={styles.buttonStyle}
                    onPress={() => {
                      showAlert('Feature coming soon!', false);
                    }}
                  >
                    <SunIcon color={Colors.light.hereworksBlue400} />
                    <Text style={styles.buttonTitle}>{'External environment'}</Text>
                  </TouchableOpacity>
                </View>
              )}
              {'ergonomics'.includes(searchValue.trim().toLowerCase()) && (
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={styles.buttonStyleYellow}
                    onPress={() => {
                      showAlert('Feature coming soon!', false);
                    }}
                  >
                    <ChairIcon color={Colors.light.hereworksYellow500} />
                    <Text style={styles.buttonTitle}>{'Ergonomics'}</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
            <View style={{ flexDirection: 'row' }}>
              {'internal environment'.includes(searchValue.trim().toLowerCase()) && (
                <View style={{ flex: 1, marginRight: 15 }}>
                  <TouchableOpacity
                    style={styles.buttonStyle}
                    onPress={() => {
                      showAlert('Feature coming soon!', false);
                    }}
                  >
                    <CoffeeIcon color={Colors.light.hereworksBlue400} />
                    <Text style={styles.buttonTitle}>{'Internal environment'}</Text>
                  </TouchableOpacity>
                </View>
              )}
              {'satisfaction'.includes(searchValue.trim().toLowerCase()) && (
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={styles.buttonStyleYellow}
                    onPress={() => {
                      showAlert('Feature coming soon!', false);
                    }}
                  >
                    <SmileIcon color={Colors.light.hereworksBlue400} />
                    <Text style={styles.buttonTitle}>{'Satisfaction'}</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
            {'map'.includes(searchValue.trim().toLowerCase()) && (
              <View style={{ marginBottom: 9 }}>
                <Text style={styles.sectionHeader}>MAP</Text>
                <TouchableOpacity
                  style={styles.buttonStyle}
                  onPress={() => {
                    if (isDev) {
                      navigation.navigate('Map');
                    } else {
                      showAlert('Feature coming soon!', false);
                    }
                  }}
                >
                  <MapIcon color={Colors.light.hereworksBlue400} />
                  <Text style={styles.buttonTitle}>{'Map'}</Text>
                </TouchableOpacity>
              </View>
            )}
            <View style={{ marginBottom: 9 }}>
              {('templates'.includes(searchValue.trim().toLowerCase())
                || 'users'.includes(searchValue.trim().toLowerCase())) && (
                <Text style={styles.sectionHeader}>MANAGEMENT</Text>
              )}
              <View style={{ flexDirection: 'row' }}>
                {('templates'.includes(searchValue.trim().toLowerCase())
                  || 'assets'.includes(searchValue.trim().toLowerCase())) && (
                  <View style={{ flex: 1, marginRight: 15 }}>
                    <TouchableOpacity
                      style={styles.buttonStyle}
                      onPress={() => {
                        navigation.navigate('AssetManagement');
                      }}
                    >
                      <FlagIcon color={Colors.light.hereworksDarkBlue} />
                      <Text style={styles.buttonTitle}>{'Assets'}</Text>
                    </TouchableOpacity>
                  </View>
                )}
                {'users'.includes(searchValue.trim().toLowerCase()) && (
                  <View style={{ flex: 1 }}>
                    <TouchableOpacity
                      style={styles.buttonStyle}
                      onPress={() => {
                        if (isDev) {
                          navigation.navigate('UserManagement');
                        } else {
                          ComingSoon();
                        }
                      }}
                    >
                      <PeopleIcon24 />
                      <Text style={styles.buttonTitle}>{'Users'}</Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </ScrollView>
          <TouchableOpacity
            style={{
              padding: 24,
              flexDirection: 'row',
              borderTopWidth: 1,
              borderColor: Colors.light.hereworksBlue100,
            }}
            onPress={() => Alert.alert('Profile Feature coming soon!')}
          >
            <AvatarIcon />
            <View style={{ marginLeft: 12 }}>
              <Text
                style={{
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 16,
                  color: Colors.light.hereworksBlack600,
                }}
              >
                {name}
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 10,
                  color: Colors.light.hereworksBlack300,
                }}
              >
                View profile
              </Text>
            </View>
          </TouchableOpacity>
        </>
      )}
      <AlertModal
        modalVisible={alertVisible}
        error={alertError}
        title={alertTitle}
        setModalVisible={setAlertVisible}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  buttonStyle: {
    paddingLeft: 10,
    backgroundColor: Colors.light.hereworksWhite100,
    borderRadius: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue100,
    height: 44,
  },
  buttonStyleYellow: {
    padding: 10,
    backgroundColor: Colors.light.hereworksYellow50,
    borderRadius: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.light.hereworksYellow200,
  },
  sectionHeader: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    color: Colors.light.hereworksBlack300,
    lineHeight: 18,
    marginBottom: 8,
    marginTop: 16,
  },
  buttonTitle: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    marginLeft: 10,
    flexWrap: 'wrap',
    flex: 1,
  },
  searchContainer: {
    zIndex: 5,
    borderRadius: 10,
    borderWidth: 1.5,
    paddingVertical: 8,
    borderColor: Colors.light.hereworksBlue100,
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
  },
  searchBoxText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 12,
    flex: 1,
  },
});
