import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ImageBackground,
  Animated as RNAnimated,
  Platform,
  Pressable,
  ScrollView,
} from 'react-native';
import {
  gestureHandlerRootHOC,
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
  TapGestureHandler,
  TapGestureHandlerGestureEvent,
} from 'react-native-gesture-handler';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import Moment from 'moment';
import Svg, { Line, Polygon } from 'react-native-svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Analytics from 'expo-firebase-analytics';
import Modal from 'react-native-modal';
import Colors from '../constants/Colors';
import PlusIcon from '../components/16pxIcons/PlusIcon';
import getFloorPlan from '../requests/GetFloorPlan';
import { RootTabScreenProps } from '../types';
import MinusIcon from '../components/16pxIcons/MinusIcon';
import ShareIcon from '../components/24pxIcons/ShareIcon';
import AlertModal from '../components/AlertModal';
import getButtonInstances from '../requests/GetButtonInstances';
import Sensor from '../components/Map/Sensor';
import GetSensorData from '../requests/GetSensorData';
// import EditIcon24 from '../components/24pxIcons/EditIcon24';
import EditIcon from '../components/EditIcon';
import DeleteIcon16 from '../components/16pxIcons/DeleteIcon16';
import PenIcon16 from '../components/16pxIcons/PenIcon16';
import SetFloorsModal from '../components/Map/SetFloorsModal';
import UndoIcon from '../components/16pxIcons/UndoIcon';
import CustomTextField from '../components/CustomTextField';
import getFloorData from '../requests/GetFloorData';
import getLocations from '../requests/GetLocations';
import { Floor } from '../components/Map/SetFloorsModal/FloorItem';
import AreaView from '../components/Map/AreaView';
import PriorityFlag from '../components/16pxIcons/PriorityFlag';
import LeftArrow from '../components/16pxIcons/LeftArrow';
import MapPoint from '../components/Map/MapPoint';
import RightArrow24 from '../components/24pxIcons/RightArrow24';
import TemplateIcon30 from '../components/30pxIcons/TemplateIcon30';
import addArea from '../requests/AddArea';
import getAreas from '../requests/GetAreas';
import Asset from '../components/Map/Asset';

type ContextType = {
  translateX: number;
  translateY: number;
};

export type PressableState = Readonly<{
  hovered?: boolean;
}>;

// eslint-disable-next-line no-unused-vars
export default function MapScreen({ navigation }: RootTabScreenProps<'Map'>) {
  useEffect(() => {
    getFloorPlan().then(() => {
      // setFloorPlans(floorPlans);
    });
  }, []);

  const groundFloor = 'https://hereworks.tech/floorplans/hereworks/Hereworks-Ashbourne-Floorplan-Ground-Floor-15Nov2021.png';
  const firstFloor = 'https://hereworks.tech/floorplans/hereworks/Hereworks-Ashbourne-Floorplan-First-Floor-15Nov2021.png';

  const hereworksFloorPlans = [groundFloor, firstFloor];

  const [floors, setFloors] = useState([]);
  const [floorPlan, setFloorPlan] = useState(groundFloor);
  const [floorPlans, setFloorPlans] = useState(hereworksFloorPlans);
  const [floorPlanModalVisible, setFloorPlanModalVisible] = useState<boolean>(false);

  const scale = useRef(new RNAnimated.Value(1)).current;
  const negativeScale = useRef(new RNAnimated.Value(1)).current;

  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);

  const [imageWidth, setImageWidth] = useState(1275);
  const [imageHeight, setImageHeight] = useState(1169);

  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const [zoomInDisabled, setZoomInDisabled] = useState(false);

  const [sensors, setSensors] = useState([]);
  const [assets, setAssets] = useState([]);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');
  const [movingItem, setMovingItem] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [drawMode, setDrawMode] = useState(false);

  const [isDev, setIsDev] = useState(false);

  const [drawPoints, setDrawPoints] = useState([]);
  const [redoPoints, setRedoPoints] = useState([]);

  const [areaComplete, setAreaComplete] = useState(false);
  const [areas, setAreas] = useState([]);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [areaName, setAreaName] = useState('');
  const [parentId, setParentId] = useState<string>(null);
  const [floorData, setFloorData] = useState<Floor[]>([]);
  const [priorityVisible, setPriorityVisible] = useState(false);
  const [highPriority, setHighPriority] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerInset] = useState(new RNAnimated.Value(0));

  const isMobile = (): boolean => Platform.OS !== 'web';

  useEffect(() => {
    if (parentId) {
      getFloorData(parentId).then((res) => {
        const locations = res?.locations;
        setFloorData(
          locations?.map((location) => ({
            ...{ location },
            planFilename: location.Svg,
          })),
        );
      });
    }
  }, [parentId]);

  const showAlert = (title: string, error: boolean = true) => {
    setAlertError(error);
    setAlertTitle(title);
    setAlertVisible(true);
  };

  const [range] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: Moment().subtract(3, 'hours').toDate(), end: Moment().endOf('day').toDate() });

  useEffect(() => {
    AsyncStorage.getItem('clientName').then((name) => {
      if (name === 'mastercard') {
        setFloors([0]);
        setFloorPlans([
          'https://s3.eu-west-1.amazonaws.com/hereworks.tech/website_root/floorplans/mastercard/mastercard_second_floor.jpg',
        ]);
        setFloorPlan(
          'https://s3.eu-west-1.amazonaws.com/hereworks.tech/website_root/floorplans/mastercard/mastercard_second_floor.jpg',
        );
        setImageWidth(1191);
        setImageHeight(842);
      }
    });
    getLocations('Building').then((res) => {
      if (!parentId) {
        if (res?.locations?.length > 0) {
          setFloors(res?.locations);
          setParentId(res?.locations[0]?.LocationId);
        }
      }
    });
  }, []);

  useEffect(() => {
    const sensorsWithMapData = [];
    getButtonInstances().then((response) => {
      if (response?.instances?.length > 0) {
        response?.instances.forEach(async (instance) => {
          let hasXY = false;
          instance.CustomFields.forEach((field) => {
            if (field.CustomName === 'GeoX') {
              instance.GeoX = Number.parseInt(field.CustomValue, 10);
              if (instance.GeoX > 0) {
                hasXY = true;
              }
            }
            if (field.CustomName === 'GeoY') {
              instance.GeoY = Number.parseInt(field.CustomValue, 10);
              if (instance.GeoY > 0) {
                hasXY = true;
              }
            }
            if (field.CustomName === 'HereworksThingId') {
              instance.thingId = field.CustomValue;
            }
          });
          if (hasXY) {
            const data = await GetSensorData(range, instance.thingId);
            instance.temp = Math.round((data['data-temperature'] ?? 0) * 100) / 100;
            instance.humidity = Math.round((data['data-humidity'] ?? 0) * 100) / 100;
            instance.co2 = Math.round((data['data-co2'] ?? 0) * 100) / 100;
            instance.tvoc = Math.round((data['data-voc'] ?? 0) * 100) / 100;
            instance.pm25 = Math.round((data['data-pm25'] ?? 0) * 100) / 100;
            instance.spl = Math.round((data['data-spl'] ?? 0) * 100) / 100;
            instance.lux = Math.round((data['data-lux'] ?? 0) * 100) / 100;
            instance.score = Math.round((data['data-score'] ?? 0) * 100) / 100;
            instance.timestamp = data.timestamp;
            // console.log(data);
            sensorsWithMapData.push(instance);
            setSensors([...sensorsWithMapData]);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    getAreas(parentId).then((response) => {
      const newAreas = [];
      response?.locations?.forEach((location) => {
        newAreas.push({
          name: location.Name,
          top: location.Top,
          bottom: location.Bottom,
          left: location.Left,
          right: location.Right,
          polygon: location.Polygon,
          highPriority: location.IsHighPriority,
          points: location.Points.map((point) => ({
            x: point.X,
            y: point.Y,
            number: point.Number,
          })),
          parentId,
          locationId: location.LocationId,
        });
      });
      setAreas(newAreas);
    });
  }, [parentId]);

  useEffect(() => {
    AsyncStorage.getItem('environment').then((env) => {
      setIsDev(env === 'dev' || env === 'demo');
    });
  }, []);

  useEffect(() => {
    if (drawerOpen) {
      RNAnimated.timing(drawerInset, {
        toValue: 400,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else {
      RNAnimated.timing(drawerInset, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  }, [drawerOpen]);

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'Map',
      name: title,
    });
  };

  const panGesture = useAnimatedGestureHandler<PanGestureHandlerGestureEvent, ContextType>({
    onStart: (event, context) => {
      if (!movingItem) {
        context.translateX = translateX.value;
        context.translateY = translateY.value;
      }
    },
    onActive: (event, context) => {
      if (!movingItem) {
        translateX.value = event.translationX + context.translateX;
        translateY.value = event.translationY + context.translateY;
      }
    },
  });

  const tapGesture = useAnimatedGestureHandler<TapGestureHandlerGestureEvent, ContextType>({
    onStart: (event) => {
      let clash = false;
      drawPoints.forEach((point) => {
        if (Math.hypot(point.x - event.x, point.y - event.y) < 20) {
          clash = true;
        }
      });
      if (!clash) {
        setDrawPoints([...drawPoints, { x: event.x, y: event.y, number: drawPoints.length }]);
      }
    },
  });

  const transformStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: translateX.value,
      },
      {
        translateY: translateY.value,
      },
    ],
  }));

  if (floorPlans.length > 0) {
    // TODO: hook up here
  }

  const renderFloorButton = (floor, key) => (
    <TouchableOpacity
      style={[styles.zoomButton, { borderWidth: floorPlan === floorPlans[floor] ? 1 : 0 }]}
      disabled={floorPlan === floorPlans[floor]}
      onPress={() => {
        analytics(`Floor: ${floor}`);
        RNAnimated.spring(scale, { toValue: 1, useNativeDriver: false }).start();
        RNAnimated.spring(negativeScale, { toValue: 1, useNativeDriver: false }).start();
        setZoomInDisabled(false);
        setZoomOutDisabled(false);
        setFloorPlan(floorPlans[floor]);
        setParentId(floors[key]?.LocationId);
        setDrawPoints([]);
        setRedoPoints([]);
      }}
      key={key}
    >
      <Text
        style={[
          styles.buttonText,
          {
            color:
              floorPlan === floorPlans[floor]
                ? Colors.light.hereworksBlue500
                : Colors.light.hereworksBlack600,
          },
        ]}
      >
        {floor + 1}
      </Text>
    </TouchableOpacity>
  );

  function getGestureEvent() {
    return drawMode ? null : panGesture;
  }

  function getPointsString(points) {
    let pointsString = '';
    points.forEach(
      // eslint-disable-next-line no-return-assign
      (point, index) => (pointsString += `${index === 0 ? '' : ', '}${point.x} ${point.y}`),
    );
    return pointsString;
  }

  function getMinY(points) {
    let minY = points[0].y;
    points.forEach((point) => {
      if (point.y < minY) {
        minY = point.y;
      }
    });

    return minY;
  }

  function getMinX(points) {
    let minX = points[0].x;
    points.forEach((point) => {
      if (point.x < minX) {
        minX = point.x;
      }
    });

    return minX;
  }

  function getMaxY(points) {
    let maxY = points[0].y;
    points.forEach((point) => {
      if (point.y > maxY) {
        maxY = point.y;
      }
    });

    return maxY;
  }

  function getMaxX(points) {
    let maxX = points[0].x;
    points.forEach((point) => {
      if (point.x > maxX) {
        maxX = point.x;
      }
    });

    return maxX;
  }
  const zoomInButton = () => (
    <TouchableOpacity
      style={[styles.zoomButton, { opacity: zoomInDisabled || drawMode ? 0.5 : 1 }]}
      onPress={() => {
        analytics('Zoom in');
        if (Number(JSON.stringify(scale)) > 1.7) {
          setZoomInDisabled(true);
          setZoomOutDisabled(false);
        } else {
          setZoomInDisabled(false);
          setZoomOutDisabled(false);
        }
        RNAnimated.parallel([
          RNAnimated.timing(scale, {
            toValue: Number(JSON.stringify(scale)) + 0.2,
            duration: 500,
            useNativeDriver: false,
          }),
          RNAnimated.timing(negativeScale, {
            toValue: Number(JSON.stringify(negativeScale)) - 0.1,
            duration: 500,
            useNativeDriver: false,
          }),
        ]).start();
      }}
      disabled={zoomInDisabled || drawMode}
    >
      <PlusIcon color={Colors.light.hereworksBlue500} />
    </TouchableOpacity>
  );

  const zoomOutButton = () => (
    <TouchableOpacity
      style={[styles.zoomButton, { opacity: zoomOutDisabled || drawMode ? 0.5 : 1 }]}
      onPress={() => {
        analytics('Zoom out');
        if (Number(JSON.stringify(scale)) < 0.5) {
          setZoomInDisabled(false);
          setZoomOutDisabled(true);
        } else {
          setZoomInDisabled(false);
          setZoomOutDisabled(false);
        }
        RNAnimated.spring(scale, {
          toValue: Number(JSON.stringify(scale)) - 0.2,
          useNativeDriver: false,
        }).start();
        RNAnimated.spring(negativeScale, {
          toValue: Number(JSON.stringify(negativeScale)) + 0.1,
          useNativeDriver: false,
        }).start();
      }}
      disabled={zoomOutDisabled || drawMode}
    >
      <MinusIcon />
    </TouchableOpacity>
  );

  const drawLines = () => (
    <Svg
      style={{
        zIndex: 1,
        width: '100%',
        height: '100%',
        // backgroundColor: 'yellow',
        // opacity: 0.2,
      }}
    >
      {drawPoints.map((point, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <Line
              key={index}
              x1={drawPoints[index - 1].x}
              x2={point.x}
              y1={drawPoints[index - 1].y}
              y2={point.y}
              stroke={drawMode ? Colors.light.hereworksOrange500 : Colors.light.hereworksBlue200}
              strokeWidth={drawMode ? 2 : 1}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          )}
          <Polygon
            points={getPointsString(drawPoints)}
            fill={Colors.light.hereworksBlue200}
            opacity={0.2}
          />
        </React.Fragment>
      ))}
    </Svg>
  );

  const mainView = () => (
    <View style={isMobile() ? styles.mobileContainer : styles.container}>
      <View style={{ flex: 1, width: '100%' }}>
        <View
          style={{
            borderBottomWidth: 1,
            borderColor: Colors.light.hereworksBlue100,
            backgroundColor: '#fff',
          }}
        >
          <Text
            style={{
              paddingTop: 24,
              paddingBottom: 16,
              paddingLeft: 24,
              fontSize: 18,
              fontFamily: 'Poppins_600SemiBold',
              color: Colors.light.hereworksBlue500,
            }}
          >
            {floorPlan === floorPlans[0] ? 'Ground Floor' : 'First floor'}
          </Text>
        </View>
        {editMode && (
          <View
            style={{
              backgroundColor: 'transparent',
              justifyContent: 'center',
              alignItems: 'flex-start',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 24,
              right: 0,
              zIndex: 2,
            }}
            pointerEvents={'box-none'}
          >
            {!drawMode && (
              <RNAnimated.View
                style={{
                  borderWidth: 1,
                  borderColor: Colors.light.hereworksBlue500,
                  borderRadius: 48,
                  marginLeft: 24,
                  backgroundColor: '#fff',
                  transform: [{ translateX: drawerInset }],
                }}
              >
                <TouchableOpacity
                  style={[
                    styles.zoomButton,
                    {
                      backgroundColor: Colors.light.hereworksBlue500,
                      margin: 4,
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                    },
                  ]}
                  onPress={() => {
                    analytics('Pen icon');
                    setDrawMode(true);
                    RNAnimated.parallel([
                      RNAnimated.timing(scale, {
                        toValue: 1,
                        duration: 500,
                        useNativeDriver: false,
                      }),
                      RNAnimated.timing(negativeScale, {
                        toValue: 1,
                        duration: 500,
                        useNativeDriver: false,
                      }),
                    ]).start();
                    translateX.value = 0;
                    translateY.value = 0;
                  }}
                >
                  <PenIcon16 />
                </TouchableOpacity>
              </RNAnimated.View>
            )}
            {drawMode && (
              <RNAnimated.View
                style={{
                  borderWidth: 1,
                  borderColor: areaComplete
                    ? Colors.light.hereworksGreen400
                    : Colors.light.hereworksBlack100,
                  borderRadius: 48,
                  marginLeft: 24,
                  backgroundColor: '#fff',
                  transform: [{ translateX: drawerInset }],
                }}
              >
                <TouchableOpacity
                  style={[
                    styles.zoomButton,
                    {
                      backgroundColor: areaComplete
                        ? Colors.light.hereworksGreen400
                        : Colors.light.hereworksBlack100,
                      borderColor: areaComplete
                        ? Colors.light.hereworksGreen400
                        : Colors.light.hereworksBlack100,
                      margin: 4,
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                    },
                  ]}
                  disabled={!areaComplete}
                  onPress={() => {
                    analytics('Plus icon');
                    setCreateModalVisible(true);
                  }}
                >
                  <PlusIcon />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.zoomButton,
                    {
                      backgroundColor: '#fff',
                      margin: 4,
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                      opacity: drawPoints.length === 0 ? 0.2 : 1,
                    },
                  ]}
                  onPress={() => {
                    analytics('Undo');
                    const newArr = Object.assign([], drawPoints);
                    const newRedo = [...redoPoints];
                    newRedo.push(newArr[newArr.length - 1]);
                    setRedoPoints(newRedo);
                    newArr.splice(newArr.length - 1, 1);
                    setDrawPoints(newArr);
                  }}
                  disabled={drawPoints.length === 0}
                >
                  <UndoIcon />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.zoomButton,
                    {
                      backgroundColor: '#fff',
                      margin: 4,
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                      opacity: redoPoints.length === 0 ? 0.2 : 1,
                      transform: [{ rotateY: '180deg' }],
                    },
                  ]}
                  onPress={() => {
                    analytics('Redo');
                    const newArr = Object.assign([], drawPoints);
                    newArr.push(redoPoints.pop());
                    setDrawPoints(newArr);
                  }}
                  disabled={redoPoints.length === 0}
                >
                  <UndoIcon />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.zoomButton,
                    {
                      backgroundColor: Colors.light.hereworksRed50,
                      margin: 4,
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                      borderColor: Colors.light.hereworksLightPink,
                    },
                  ]}
                  onPress={() => {
                    analytics('Cancel');
                    setDrawPoints([]);
                    setDrawMode(false);
                  }}
                >
                  <DeleteIcon16 color={Colors.light.hereworksRed500} />
                </TouchableOpacity>
              </RNAnimated.View>
            )}
          </View>
        )}
        <View
          style={{
            backgroundColor: 'transparent',
            justifyContent: 'center',
            alignItems: 'flex-end',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 24,
            zIndex: 5,
          }}
          pointerEvents={'box-none'}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: Colors.light.hereworksBlue500,
              borderRadius: 48,
              marginLeft: 24,
              backgroundColor: '#fff',
            }}
          >
            {zoomInButton()}
            {zoomOutButton()}
            <View style={{ borderColor: Colors.light.hereworksBlue100, borderTopWidth: 1 }}>
              <TouchableOpacity
                style={styles.zoomButton}
                onPress={() => showAlert('Feature coming soon!', false)}
              >
                <ShareIcon />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: 'transparent',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 24,
            zIndex: 5,
          }}
          pointerEvents={'box-none'}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: Colors.light.hereworksBlue500,
              borderRadius: 48,
              marginLeft: 24,
              marginBottom: 16,
              paddingHorizontal: 14,
              backgroundColor: '#fff',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text style={styles.buttonText}>Floor</Text>
            {floors.map((floor, key) => renderFloorButton(key, key))}
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <View
            style={[
              {
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                zIndex: -1,
              },
            ]}
          >
            <View
              style={{
                backgroundColor: '#fff',
                borderRightWidth: 1,
                borderColor: Colors.light.hereworksBlue100,
                width: 400,
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                left: 0,
                height: '100%',
                flex: 1,
              }}
            >
              <View
                style={{
                  alignSelf: 'stretch',
                  borderBottomWidth: 1,
                  borderColor: Colors.light.hereworksBlue100,
                  marginBottom: 4,
                }}
              >
                <Text
                  style={{
                    color: Colors.light.hereworksBlack400,
                    fontFamily: 'Roboto_500Medium',
                    paddingLeft: 24,
                    paddingTop: 16,
                    paddingBottom: 12,
                    lineHeight: 20,
                  }}
                >
                  ASSETS
                </Text>
              </View>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: Colors.light.hereworksBlue100,
                  borderRadius: 8,
                  paddingLeft: 10,
                  marginHorizontal: 24,
                  alignSelf: 'stretch',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TemplateIcon30 />
                <View
                  style={{
                    flex: 1,
                    paddingVertical: 12,
                    borderRightWidth: 1,
                    marginLeft: 8,
                    borderColor: Colors.light.hereworksBlue100,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 14,
                      color: Colors.light.hereworksBlack600,
                      lineHeight: 21,
                    }}
                  >
                    Asset 1
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 12,
                      color: Colors.light.hereworksBlack400,
                    }}
                  >
                    Asset 1
                  </Text>
                </View>
                <Pressable
                  style={(state) => [{ paddingHorizontal: 10, opacity: state.hovered ? 0.5 : 1 }]}
                  onPress={() => {
                    setMovingItem(true);
                    setAssets([
                      {
                        name: 'Asset 1',
                        GeoY: 120,
                        GeoX: 20,
                        moving: true,
                      },
                    ]);
                  }}
                >
                  <RightArrow24 />
                </Pressable>
              </View>
            </View>
          </View>
          <RNAnimated.ScrollView style={{ transform: [{ translateX: drawerInset }] }}>
            <ScrollView
              horizontal={true}
              style={{ backgroundColor: '#fff' }}
              contentContainerStyle={{ paddingRight: drawerOpen ? 400 : 0 }}
            >
              <TapGestureHandler onGestureEvent={drawMode ? tapGesture : null}>
                <Animated.View
                  style={{
                    zIndex: -1,
                    flex: 1,
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <PanGestureHandler onGestureEvent={getGestureEvent()}>
                    <Animated.View
                      style={[
                        {
                          zIndex: -1,
                          flex: 1,
                          height: '100%',
                          width: '100%',
                        },
                        transformStyle,
                      ]}
                    >
                      <RNAnimated.View
                        style={[
                          {
                            transform: [{ scale }],
                          },
                        ]}
                      >
                        <ImageBackground
                          style={{
                            width: imageWidth,
                            height: imageHeight,
                            borderWidth: editMode ? 8 : 0,
                            borderColor: '#000',
                            // @ts-ignore
                            cursor: drawMode ? 'crosshair' : 'auto',
                          }}
                          resizeMode={'cover'}
                          source={{
                            uri: floorPlan,
                          }}
                        >
                          {drawLines()}
                          {areas.map((area, index) => {
                            if (area.parentId !== parentId) {
                              return null;
                            }
                            return (
                              <React.Fragment key={index}>
                                <AreaView
                                  index={index}
                                  area={area}
                                  areas={areas}
                                  movingItem={movingItem}
                                  setMovingItem={setMovingItem}
                                  editArea={() => {
                                    const newAreas = Object.assign([], areas);
                                    const areaToEdit = newAreas.splice(index, 1);
                                    setDrawPoints(areaToEdit[0]?.points ?? []);
                                    setDrawMode(true);
                                    setAreaName(areaToEdit[0]?.name ?? '');
                                    setHighPriority(areaToEdit[0]?.highPriority ?? false);
                                    setAreas(newAreas);
                                  }}
                                  setAreas={setAreas}
                                  imageWidth={imageWidth}
                                  imageHeight={imageHeight}
                                  getMinX={getMinX}
                                  getMinY={getMinY}
                                  getMaxX={getMaxX}
                                  getMaxY={getMaxY}
                                  getPointsString={getPointsString}
                                  showAlert={showAlert}
                                  editMode={editMode}
                                  arrowPress={() => setDrawerOpen(true)}
                                />
                              </React.Fragment>
                            );
                          })}
                          {!drawerOpen
                            && sensors.map((sensor, key) => {
                              if (sensor.LocationId !== parentId) {
                                return null;
                              }
                              if (editMode) {
                                return (
                                  <View style={{ zIndex: 1, position: 'absolute' }} key={key}>
                                    <Pressable
                                      style={(state) => [
                                        {
                                          position: 'absolute',
                                          zIndex: state.hovered ? 10 : 1,
                                        },
                                      ]}
                                      key={key}
                                    >
                                      {({ hovered }: PressableState): ReactElement => (
                                        <RNAnimated.View
                                          key={key}
                                          style={{
                                            zIndex: hovered ? 10 : -1,
                                            transform: [{ scale: negativeScale }],
                                          }}
                                        >
                                          <Sensor
                                            name={sensor.Name}
                                            temp={sensor.temp}
                                            humidity={sensor.humidity}
                                            co2={sensor.co2}
                                            tvoc={sensor.tvoc}
                                            pm25={sensor.pm25}
                                            spl={sensor.spl}
                                            lux={sensor.lux}
                                            score={sensor.score}
                                            timestamp={sensor.timestamp}
                                            hovered={hovered}
                                            editMode={true}
                                            sensor={sensor}
                                            movingItem={movingItem}
                                          />
                                        </RNAnimated.View>
                                      )}
                                    </Pressable>
                                  </View>
                                );
                              }

                              return (
                                <Pressable
                                  style={(state) => [
                                    {
                                      position: 'absolute',
                                      zIndex: state.hovered ? 10 : 1,
                                      top: sensor.GeoY,
                                      left: sensor.GeoX,
                                    },
                                  ]}
                                  key={key}
                                >
                                  {({ hovered }: PressableState): ReactElement => (
                                    <RNAnimated.View
                                      key={key}
                                      style={{
                                        zIndex: hovered ? 10 : -1,
                                        transform: [{ scale: negativeScale }],
                                      }}
                                    >
                                      <Sensor
                                        name={sensor.Name}
                                        temp={sensor.temp}
                                        humidity={sensor.humidity}
                                        co2={sensor.co2}
                                        tvoc={sensor.tvoc}
                                        pm25={sensor.pm25}
                                        spl={sensor.spl}
                                        lux={sensor.lux}
                                        score={sensor.score}
                                        timestamp={sensor.timestamp}
                                        hovered={hovered}
                                      />
                                    </RNAnimated.View>
                                  )}
                                </Pressable>
                              );
                            })}
                          {assets.map((asset, key) => (
                            <Pressable
                              style={(state) => [
                                {
                                  position: 'absolute',
                                  zIndex: state.hovered ? 10 : 1,
                                },
                              ]}
                              key={key}
                            >
                              <Asset
                                asset={asset}
                                name={asset.name}
                                timestamp={Moment()}
                                hovered={false}
                                editMode={true}
                                movingItem={true}
                              />
                            </Pressable>
                          ))}
                          {drawMode
                            && drawPoints.map((point, index) => (
                              <React.Fragment key={index}>
                                <MapPoint
                                  point={point}
                                  index={index}
                                  drawPoints={drawPoints}
                                  imageWidth={imageWidth}
                                  imageHeight={imageHeight}
                                  setDrawPoints={setDrawPoints}
                                  setAreaComplete={setAreaComplete}
                                  showAlert={showAlert}
                                />
                              </React.Fragment>
                            ))}
                        </ImageBackground>
                      </RNAnimated.View>
                    </Animated.View>
                  </PanGestureHandler>
                </Animated.View>
              </TapGestureHandler>
            </ScrollView>
            <Pressable
              style={(state) => [
                {
                  height: 56,
                  width: 24,
                  position: 'absolute',
                  left: 0,
                  top: 24,
                  backgroundColor: state.hovered
                    ? Colors.light.hereworksPaleBlue
                    : Colors.light.hereworksDarkBlue,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                },
              ]}
              onPress={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <LeftArrow
                color="#fff"
                style={{ transform: [{ rotateZ: drawerOpen ? '0deg' : '180deg' }] }}
              />
            </Pressable>
          </RNAnimated.ScrollView>
        </View>
      </View>
      <View
        style={{
          height: 100,
          width: '100%',
          backgroundColor: '#fff',
          borderTopWidth: 1,
          borderColor: Colors.light.hereworksBlue100,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View style={{ marginLeft: 24 }}>
          <Text
            style={{
              fontFamily: 'Roboto_500Medium',
              fontSize: 12,
              lineHeight: 20,
              height: 20,
              marginBottom: 8,
              color: Colors.light.hereworksBlack500,
            }}
          >
            SENSOR DATA
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              style={{
                backgroundColor: '#fff',
                borderRadius: 4,
                borderColor: Colors.light.hereworksBlue500,
                borderWidth: 1,
                zIndex: 2,
                marginRight: 8,
              }}
              onPress={() => {
                analytics('OCCUPANCY');
                showAlert('Feature coming soon!', false);
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto_500Medium',
                  fontSize: 12,
                  lineHeight: 20,
                  height: 20,
                  marginVertical: 4,
                  marginHorizontal: 12,
                  color: Colors.light.hereworksBlue500,
                }}
              >
                OCCUPANCY
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                backgroundColor: Colors.light.hereworksBlue500,
                borderRadius: 4,
                borderColor: Colors.light.hereworksBlue500,
                borderWidth: 1,
                zIndex: 2,
              }}
              onPress={() => {
                analytics('INTERNAL ENVIRONMENT');
                // setSelectedTab(1);
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto_500Medium',
                  fontSize: 12,
                  lineHeight: 20,
                  height: 20,
                  marginVertical: 4,
                  marginHorizontal: 12,
                  color: '#fff',
                }}
              >
                INTERNAL ENVIRONMENT
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            marginLeft: 24,
            width: 1,
            height: 56,
            backgroundColor: Colors.light.hereworksWhite500,
          }}
        />
        <View style={{ marginLeft: 24 }}>
          <Text
            style={{
              fontFamily: 'Roboto_500Medium',
              fontSize: 12,
              lineHeight: 20,
              height: 20,
              marginBottom: 8,
              color: Colors.light.hereworksBlack500,
            }}
          >
            ASSETS & TICKETS
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              style={{
                backgroundColor: '#fff',
                borderRadius: 4,
                borderColor: Colors.light.hereworksBlue500,
                borderWidth: 1,
                zIndex: 2,
                marginRight: 8,
              }}
              onPress={() => {
                analytics('TICKETS');
                showAlert('Feature coming soon!', false);
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto_500Medium',
                  fontSize: 12,
                  lineHeight: 20,
                  height: 20,
                  marginVertical: 4,
                  marginHorizontal: 12,
                  color: Colors.light.hereworksBlue500,
                }}
              >
                TICKETS
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                backgroundColor: '#fff',
                borderRadius: 4,
                borderColor: Colors.light.hereworksBlue500,
                borderWidth: 1,
                zIndex: 2,
              }}
              onPress={() => {
                analytics('ASSETS');
                showAlert('Feature coming soon!', false);
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto_500Medium',
                  fontSize: 12,
                  lineHeight: 20,
                  height: 20,
                  marginVertical: 4,
                  marginHorizontal: 12,
                  color: Colors.light.hereworksBlue500,
                }}
              >
                ASSETS
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        {Platform.OS === 'web' && (
          <TouchableOpacity
            style={styles.floorPlanButton}
            onPress={() => setFloorPlanModalVisible(true)}
          >
            <Text style={styles.floorPlanText}>Update floor plan</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );

  const Wrapper = gestureHandlerRootHOC(() => <>{mainView()}</>);

  return (
    <View
      style={{
        backgroundColor: Colors.light.hereworksBackground,
        flex: 1,
      }}
    >
      {!isMobile() && (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontSize: 22,
              lineHeight: 33,
              marginLeft: 40,
              marginTop: 48,
              marginBottom: 32,
              fontFamily: 'Poppins_400Regular',
              color: Colors.light.hereworksBlack600,
              flex: 1,
            }}
          >
            Map
          </Text>
          {isDev && (
            <TouchableOpacity
              style={[
                styles.buttonCommon,
                {
                  borderColor: Colors.light.hereworksLightBlue,
                  backgroundColor: editMode
                    ? Colors.light.hereworksDarkBlue
                    : Colors.light.hereworksLightBlue,
                  alignSelf: 'center',
                  marginRight: 40,
                },
              ]}
              onPress={() => {
                setEditMode(!editMode);
                if (editMode && drawMode) {
                  setDrawMode(false);
                  setDrawPoints([]);
                }
                analytics('Map editor');
              }}
            >
              <Text
                style={{
                  color: '#fff',
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 16,
                  lineHeight: 24,
                  marginVertical: 8,
                  marginRight: 8,
                }}
                numberOfLines={1}
              >
                {editMode ? 'Close editor' : 'Map editor'}
              </Text>
              {editMode ? <DeleteIcon16 color={'#fff'} /> : <EditIcon color={'#fff'} />}
            </TouchableOpacity>
          )}
        </View>
      )}
      {Platform.OS === 'android' ? <Wrapper /> : mainView()}
      <AlertModal
        modalVisible={alertVisible}
        error={alertError}
        title={alertTitle}
        setModalVisible={setAlertVisible}
      />
      <SetFloorsModal
        isVisible={floorPlanModalVisible}
        hide={() => setFloorPlanModalVisible(false)}
        parentId={parentId}
        defaultFloorData={floorData}
      />
      <Modal
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        isVisible={createModalVisible}
        // isVisible={true} // for testing
        style={{ alignItems: 'center' }}
      >
        <View
          style={{
            backgroundColor: '#fff',
            borderRadius: 10,
            padding: 24,
            alignItems: 'center',
            minWidth: 492,
          }}
        >
          <Text style={styles.title}>Creating an area</Text>
          {priorityVisible && (
            <View
              style={{
                backgroundColor: 'transparent',
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            />
          )}
          <View style={{ alignSelf: 'stretch', zIndex: 2 }}>
            <CustomTextField
              title={'Area name'}
              required
              errorText="Please enter area name"
              value={areaName}
              updateValue={(name) => {
                setAreaName(name);
              }}
            />
            {priorityVisible && (
              <View
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              />
            )}
            <View
              style={{
                position: 'absolute',
                right: 10,
                bottom: 10,
                zIndex: 2,
              }}
            >
              <Pressable onPress={() => setPriorityVisible(!priorityVisible)}>
                <View
                  style={{
                    borderWidth: 1,
                    borderRadius: 4,
                    borderColor: highPriority
                      ? Colors.light.hereworksMediumYellow
                      : Colors.light.hereworksCheckGreen,
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 3,
                  }}
                >
                  <PriorityFlag
                    color={
                      highPriority
                        ? Colors.light.hereworksMediumYellow
                        : Colors.light.hereworksCheckGreen
                    }
                  />
                  <Text
                    style={{
                      color: highPriority
                        ? Colors.light.hereworksMediumYellow
                        : Colors.light.hereworksCheckGreen,
                      fontFamily: 'Roboto_500Medium',
                      fontSize: 12,
                      marginLeft: 2,
                    }}
                  >
                    {highPriority ? 'High' : 'Normal'}
                  </Text>
                </View>
              </Pressable>
              {priorityVisible && (
                <View
                  style={{
                    position: 'absolute',
                    top: 40,
                    right: -10,
                    backgroundColor: '#fff',
                    width: 140,
                    zIndex: 2,
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: Colors.light.hereworksBlue100,
                  }}
                >
                  <Pressable
                    onPress={() => {
                      analytics('Set Normal Priority');
                      setHighPriority(false);
                      setPriorityVisible(false);
                    }}
                  >
                    <Text style={styles.priorityText}>Normal</Text>
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      analytics('Set High Priority');
                      setHighPriority(true);
                      setPriorityVisible(false);
                    }}
                  >
                    <Text style={styles.priorityText}>High</Text>
                  </Pressable>
                </View>
              )}
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 40, zIndex: -1 }}>
            <Pressable
              onPress={() => {
                setAreaName('');
                setCreateModalVisible(false);
              }}
              style={{
                backgroundColor: '#fff',
                borderRadius: 4,
                borderWidth: 1,
                borderColor: Colors.light.hereworksBlue500,
                marginRight: 8,
              }}
            >
              <Text
                style={{
                  color: Colors.light.hereworksBlue500,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 16,
                  lineHeight: 24,
                  marginHorizontal: 24,
                  paddingVertical: 8,
                  textAlign: 'center',
                }}
              >
                Cancel
              </Text>
            </Pressable>
            <Pressable
              onPress={() => {
                const newAreas = Object.assign([], areas);
                const area = {
                  name: areaName,
                  top: getMinY(drawPoints),
                  left: getMinX(drawPoints),
                  right: getMaxX(drawPoints),
                  bottom: getMaxY(drawPoints),
                  points: drawPoints,
                  polygon: getPointsString(drawPoints),
                  highPriority,
                  parentId,
                };
                addArea(
                  area.name,
                  area.top,
                  area.left,
                  area.right,
                  area.bottom,
                  area.points,
                  area.polygon,
                  area.highPriority,
                  parentId,
                );
                newAreas.push(area);
                setAreas(newAreas);
                setDrawPoints([]);
                setDrawMode(false);
                setCreateModalVisible(false);
                setAreaName('');
              }}
              disabled={areaName.toString().trim().length < 1}
              style={{
                backgroundColor: Colors.light.hereworksBlue500,
                borderRadius: 4,
                borderWidth: 1,
                borderColor: Colors.light.hereworksBlue500,
                opacity: areaName.toString().trim().length < 1 ? 0.3 : 1,
              }}
            >
              <Text
                style={{
                  color: '#fff',
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 16,
                  lineHeight: 24,
                  marginHorizontal: 24,
                  paddingVertical: 8,
                  textAlign: 'center',
                }}
              >
                Add area
              </Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    marginHorizontal: 40,
    overflow: 'hidden',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue100,
    marginBottom: 20,
    flex: 1,
  },
  mobileContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    margin: 16,
    overflow: 'hidden',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlack100,
    flex: 1,
  },
  title: {
    fontSize: 20,
    margin: 40,
    fontWeight: 'bold',
    color: 'black',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  zoomButton: {
    margin: 8,
    backgroundColor: '#fff',
    width: 32,
    height: 32,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    justifyContent: 'center',
    alignItems: 'center',
  },
  desktopModalContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E5E5E550',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    zIndex: 3,
    borderRadius: 24,
    flex: 1,
  },
  desktopModal: {
    backgroundColor: Colors.light.background,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 4,
    borderRadius: 24,
    width: '80%',
    padding: 20,
  },
  buttonText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksBlack600,
    paddingHorizontal: 10,
  },
  buttonCommon: {
    borderRadius: 4,
    borderWidth: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
  },
  floorPlanButton: {
    paddingVertical: 8,
    paddingHorizontal: 24,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.light.hereworksBlue500,
    borderRadius: 4,
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.06,
    shadowColor: '#323247',
    position: 'absolute',
    end: 24,
  },
  floorPlanText: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Roboto_400Regular',
    color: '#FFF',
  },
  priorityText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksBlack600,
    paddingHorizontal: 12,
    paddingVertical: 9,
  },
});
