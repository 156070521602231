import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const EditIcon24 = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M21 21H3M19.88 7 11 15.83 7 17l1.17-4 8.88-8.88A2.09 2.09 0 0 1 20 4a2.091 2.091 0 0 1-.12 3v0Z"
      stroke={props.color ?? '#0058AD'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default EditIcon24;
