import AsyncStorage from '@react-native-async-storage/async-storage';

const addArea = async (
  name,
  top,
  left,
  right,
  bottom,
  points,
  polygon,
  priority,
  parentLocationId,
) => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = (await AsyncStorage.getItem('apiDomain')) ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('jwt', jwtToken ?? '');

    const parsedPoints = [];

    points.forEach((point) => parsedPoints.push({ X: point.x, Y: point.y, Number: point.number }));

    const response = await fetch(`https://${apiDomain}/button/locations/area`, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify({
        Area: {
          Name: name,
          Top: top,
          Left: left,
          Right: right,
          Bottom: bottom,
          Points: parsedPoints,
          Polygon: polygon,
          Priority: priority,
        },
        ParentId: parentLocationId,
      }),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default addArea;
