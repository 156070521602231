import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const StopwatchIcon = (props: SvgProps) => (
  <Svg width={57} height={57} fill="none" {...props}>
    <Path
      d="m54.067 10.78-5.17-5.17a.89.89 0 0 0-1.26 0l-1.954 1.955a.89.89 0 0 0 0 1.26l5.17 5.17a.89.89 0 0 0 1.26 0l1.954-1.955a.89.89 0 0 0 0-1.26ZM44.422 15.254l3.846-3.846M28.5 55.225c12.789 0 23.156-10.368 23.156-23.157C51.656 19.28 41.29 8.912 28.5 8.912c-12.789 0-23.156 10.367-23.156 23.156 0 12.79 10.367 23.157 23.156 23.157Z"
      stroke="#121B23"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M25.828 9.064V3.568a.89.89 0 0 1 .89-.89h3.563a.89.89 0 0 1 .89.89v5.496M28.5 14.256v15.14M28.5 34.74a2.672 2.672 0 1 0 0-5.344 2.672 2.672 0 0 0 0 5.344ZM28.5 34.74v5.372"
      stroke="#121B23"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default StopwatchIcon;
