import React, { useEffect, useMemo, useState } from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Animated,
  Easing,
  Dimensions,
} from 'react-native';
import Moment from 'moment';
import { useRoute } from '@react-navigation/native';
import Colors from '../constants/Colors';
import DownloadIcon from './DownloadIcon';
import DateComponent from './DateComponent';
import HappyScoreIndicator from './HappyScoreIndicator';
import SectionHeader from './SectionHeader';
import TopSectionInsights from './TopSectionInsights';
import HappyDetailsGraphWeb, { PointProps, happyScoreMetrics } from '../graphs/HappyDetailsGraphWeb';
import getClientDetails from '../requests/GetClientDetails';
import { GetMetricByDateWithLive } from '../requests/GetMetricByDateWithLive';

const defaultAnimationDuration: number = 300;

const animation = (
  animatedValue: Animated.Value,
  toValue: number,
  duration: number = defaultAnimationDuration,
): Animated.CompositeAnimation => Animated.timing(animatedValue, {
  duration,
  useNativeDriver: true,
  easing: Easing.ease,
  toValue,
});

export interface TopSectionProps {
  onConfirm;
  onCompareConfirm;
  range: { start: Date; end: Date };
  compareRange: { start: Date; end: Date };
  still?: boolean;
  title?: string;
}

const TopSection = ({
  onConfirm, onCompareConfirm, range, compareRange, still, title,
}: TopSectionProps) => {
  const route = useRoute();
  const routeName: string = route.name?.toLowerCase();
  const showExport = false;
  const [graphData, setGraphData] = useState([[], [], [], [], [], [], [], [], []]);
  const [graphCompareData, setGraphCompareData] = useState([[], [], [], [], [], [], [], [], []]);
  const [graphIndex, setGraphIndex] = useState(-1);
  const [score, setScore] = useState(null);
  const [average, setAverage] = useState(null);
  const [yesterdayScore, setYesterdayScore] = useState(null);
  const [scoreCompared, setScoreCompared] = useState(null);
  const [scoreAnimatedValue] = useState(new Animated.Value(0));
  const [scoreComparedAnimatedValue] = useState(new Animated.Value(0));
  const [sectionHeaderDefaultShown, setSectionHeaderDefaultShown] = useState(true);
  const [defaultContentShown, setDefaultContentShown] = useState(sectionHeaderDefaultShown);
  const [topSectionHeight] = useState(new Animated.Value(327));
  const [happyScoreIndicatorSize] = useState(new Animated.Value(1));
  const [happyScoreIndicatorOffset] = useState(new Animated.Value(-60));
  const [topSectionMarginStart] = useState(new Animated.Value(460));
  const [sectionHeaderPaddingStart] = useState(new Animated.Value(172));
  const [graphContainerHeight] = useState(new Animated.Value(0));
  const [graphContainerMarginTop] = useState(new Animated.Value(0));
  const [graphContainerOpacity] = useState(new Animated.Value(0));
  const [description, setDescription] = useState('');
  const scoreAnimation: Animated.CompositeAnimation = Animated.parallel([
    Animated.timing(scoreAnimatedValue, {
      duration: 1000,
      useNativeDriver: true,
      easing: (time: number) => Easing.ease(time),
      toValue: score,
    }),
    Animated.timing(scoreComparedAnimatedValue, {
      duration: 1000,
      useNativeDriver: true,
      easing: (time: number) => Easing.ease(time),
      toValue: scoreCompared,
    }),
  ]);

  useEffect(() => {
    if (routeName.includes('internal')) setGraphIndex(1);
    else if (routeName.includes('external')) setGraphIndex(2);
    else if (routeName === 'it') setGraphIndex(3);
    else if (routeName.includes('energy')) setGraphIndex(4);
    else if (routeName.includes('building')) setGraphIndex(5);
    else if (routeName.includes('amen')) setGraphIndex(6);
    else if (routeName.includes('ergo')) setGraphIndex(7);
    else if (routeName.includes('satis')) setGraphIndex(8);
  }, []);

  useEffect(() => {
    getClientDetails().then((response) => {
      let match = false;
      Object.keys(response?.clientDetails?.happy_score_insights ?? []).forEach((item) => {
        const split = item.split('_');
        const id = split[0];
        if (!match && routeName.includes(id) && (routeName === 'it' || id !== 'it')) {
          match = true;
          setDescription(response.clientDetails.happy_score_insights[item].text);
        }
      });
    });
  }, []);

  const updateGraphData = (start: Date, end: Date, compare: boolean = false) => {
    if (compare && !compareRange.end) return;
    const newGraphData: object[][] = [[], [], [], [], [], [], [], [], []];
    if (compare) setGraphCompareData(newGraphData);
    else setGraphData(newGraphData);
    GetMetricByDateWithLive(happyScoreMetrics[graphIndex], { start, end })
      .then((data: {
        buckets: { key_as_string: string, score: { value: number } }[], source: number,
      }) => {
        const values: number[] = data?.buckets?.map(
          (bucket: { score: { value: number } }) => bucket?.score?.value,
        ) ?? [];
        const averageValue: number = values.reduce((prev, next) => prev + next, 0)
          / values.length;
        if (compare) setScoreCompared(Number.isFinite(averageValue) ? averageValue : null);
        else setAverage(Number.isFinite(averageValue) ? averageValue : null);
        if (!score) {
          // eslint-disable-next-line no-underscore-dangle
          const todayScore: number = data?.source;
          setScore(Number.isFinite(todayScore) ? todayScore : null);
        }
        if (!yesterdayScore) {
          const yesterdayValue: number = data?.buckets
            ?.filter((e: { key_as_string: string }) => new Date(e.key_as_string).toDateString() === Moment().subtract(1, 'day').toDate().toDateString())[0].score.value;
          setYesterdayScore(Number.isFinite(yesterdayValue) ? yesterdayValue : null);
        }
        data?.buckets?.forEach((e: {
          score: { value: number }, key_as_string: string
        }, index: number) => {
          const point: PointProps = {
            value: Math.floor(e.score.value),
            date: e.key_as_string,
            index,
          };
          newGraphData[graphIndex].push(point);
        });
        if (compare) setGraphCompareData([...newGraphData]);
        else setGraphData([...newGraphData]);
      });
  };

  useMemo(() => {
    if (graphIndex > 0) updateGraphData(range.start, range.end, false);
  }, [new Date(range.start)?.toDateString(), new Date(range.end)?.toDateString(), graphIndex]);
  useMemo(() => {
    if (graphIndex > 0) updateGraphData(compareRange.start, compareRange.end, true);
  }, [new Date(compareRange.start)?.toDateString(), new Date(compareRange.end)?.toDateString(),
    graphIndex]);

  useEffect(() => {
    const topSectionHeightAnimation: Animated.CompositeAnimation = animation(
      topSectionHeight,
      sectionHeaderDefaultShown ? 327 : 71,
    );
    const happyScoreIndicatorSizeAnimation: Animated.CompositeAnimation = animation(
      happyScoreIndicatorSize,
      sectionHeaderDefaultShown ? 1 : 0.59,
    );
    const happyScoreIndicatorOffsetAnimation: Animated.CompositeAnimation = animation(
      happyScoreIndicatorOffset,
      sectionHeaderDefaultShown ? -60 : -80,
    );
    const topSectionMarginStartAnimation: Animated.CompositeAnimation = animation(
      topSectionMarginStart,
      sectionHeaderDefaultShown
        ? 500 * Math.min(1, (Dimensions.get('window').width - 252) / 1188) - 40
        : 42,
    );
    const sectionHeaderPaddingStartAnimation: Animated.CompositeAnimation = animation(
      sectionHeaderPaddingStart,
      sectionHeaderDefaultShown ? 172 : 102,
    );
    const graphContainerHeightAnimation: Animated.CompositeAnimation = animation(
      graphContainerHeight,
      sectionHeaderDefaultShown ? 0 : 290,
    );
    const graphContainerMarginTopAnimation: Animated.CompositeAnimation = animation(
      graphContainerMarginTop,
      sectionHeaderDefaultShown ? 0 : 16,
    );
    const graphContainerOpacityAnimation: Animated.CompositeAnimation = animation(
      graphContainerOpacity,
      sectionHeaderDefaultShown ? 0 : 1,
    );
    Animated.parallel([
      topSectionHeightAnimation,
      happyScoreIndicatorSizeAnimation,
      happyScoreIndicatorOffsetAnimation,
      topSectionMarginStartAnimation,
      sectionHeaderPaddingStartAnimation,
      graphContainerHeightAnimation,
      graphContainerMarginTopAnimation,
      graphContainerOpacityAnimation,
    ]).start();
    const timeout = setTimeout(() => {
      setDefaultContentShown(sectionHeaderDefaultShown);
      clearTimeout(timeout);
    }, defaultAnimationDuration);
  }, [sectionHeaderDefaultShown]);

  useEffect(() => scoreAnimation.start(), [score, scoreCompared]);

  return (
    <View style={styles.container}>
      <View style={styles.headerSection}>
        <DateComponent
          renderDesktopView
          compareEnabled
          onChange={onConfirm}
          onCompareChange={onCompareConfirm}
          onOpen={() => { }}
          onConfirm={() => { }}
          startDate={range.start}
          endDate={range.end}
          compareStartDate={compareRange.start}
          compareEndDate={compareRange.end}
        />
        {showExport && <TouchableOpacity style={styles.exportButton} onPress={() => { }}>
          <Text style={styles.exportButtonTitle}>Export</Text>
          <DownloadIcon color="#FFF" />
        </TouchableOpacity>}
      </View>
      {/* <Text style={styles.title}>Overview</Text> */}
      <View style={styles.contentWrapper}>
        <Animated.View
          style={[
            styles.contentContainer,
            { height: topSectionHeight, marginStart: topSectionMarginStart },
          ]}
        >
          <SectionHeader
            defaultShown={sectionHeaderDefaultShown}
            style={{ paddingStart: sectionHeaderPaddingStart }}
            setDefaultShown={setSectionHeaderDefaultShown}
            title1="Insights"
            title2={title ? `${title} Graph` : 'Graph'}
            button1="INSIGHTS"
            button2="GRAPH"
          />
          {sectionHeaderDefaultShown && defaultContentShown && (
            <TopSectionInsights
              average={average}
              yesterdayScore={yesterdayScore}
              todayScore={score}
              description={description}
            />
          )}
          <HappyScoreIndicator
            style={[
              styles.happyScoreIndicator,
              {
                transform: [{ scale: happyScoreIndicatorSize }],
                top: happyScoreIndicatorOffset,
                start: happyScoreIndicatorOffset,
              },
            ]}
            happyScoreAnimatedValue={scoreAnimatedValue}
            happyScoreValue={score}
            happyScoreComparedAnimatedValue={scoreComparedAnimatedValue}
            happyScoreComparedValue={scoreCompared}
            showComparison={!!compareRange.end}
            noValue={!score}
            noValueCompared={!scoreCompared}
            still={still}
          />
        </Animated.View>
        <Animated.View
          style={[
            styles.graphContainer,
            {
              height: graphContainerHeight,
              marginTop: graphContainerMarginTop,
              opacity: graphContainerOpacity,
            },
          ]}
        >
          {!sectionHeaderDefaultShown && !defaultContentShown && (
            <HappyDetailsGraphWeb
              graphIndex={graphIndex}
              graphData={graphData}
              graphCompareData={graphCompareData}
              showComparison={!!compareRange.end} />
          )}
        </Animated.View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 30,
    marginBottom: 40,
    marginHorizontal: 40,
    zIndex: 2,
  },
  contentWrapper: {
    maxWidth: 1148,
    marginTop: 30,
  },
  contentContainer: {
    height: 327,
    backgroundColor: '#FFF',
    borderRadius: 24,
    paddingTop: 17,
    paddingBottom: 27,
  },
  headerSection: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24,
    zIndex: 2,
  },
  exportButton: {
    borderRadius: 4,
    backgroundColor: Colors.light.hereworksBlue500,
    flexDirection: 'row',
    alignItems: 'center',
    paddingStart: 24,
    paddingEnd: 20,
    height: 38,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: Colors.light.hereworksBlue500,
    shadowOpacity: 0.06,
  },
  exportButtonTitle: {
    color: '#FFF',
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    marginRight: 10,
  },
  title: {
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_400Regular',
    fontSize: 22,
    lineHeight: 33,
    marginBottom: 16,
  },
  graphContainer: {
    borderRadius: 24,
    paddingStart: 24,
    backgroundColor: '#FFF',
    borderColor: Colors.light.hereworksWhite300,
    borderWidth: 1,
  },
  happyScoreIndicator: {
    top: -60,
    start: -60,
  },
});

export default TopSection;
