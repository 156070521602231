import * as React from 'react';
import Svg, { SvgProps, Circle } from 'react-native-svg';

const EllipsisIcon = (props: SvgProps) => (
  <Svg
    width={26}
    height={6}
    fill="none"
    {...props}
  >
    <Circle cx={3} cy={3} r={3} fill={props.fill ?? '#4A4F53'} />
    <Circle cx={13} cy={3} r={3} fill={props.fill ?? '#4A4F53'} />
    <Circle cx={23} cy={3} r={3} fill={props.fill ?? '#4A4F53'} />
  </Svg>
);

export default EllipsisIcon;
