import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const MinusIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 8a.75.75 0 0 1-.75.75H2a.75.75 0 0 1 0-1.5h12a.75.75 0 0 1 .75.75Z"
      fill="#0058AD"
    />
  </Svg>
);

export default MinusIcon;
