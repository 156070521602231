import React, {
  Dispatch, SetStateAction, useRef, useState,
} from 'react';
import {
  View, Text, ScrollView, TouchableOpacity, StyleSheet,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Colors from '../../constants/Colors';
import LockIcon from '../16pxIcons/LockIcon';
import TickIcon from '../TickIcon';
import LeftArrow from '../16pxIcons/LeftArrow';
import RightArrow from '../16pxIcons/RightArrow';

export const lineColors = [
  Colors.light.hereworksPurple500,
  Colors.light.hereworksDarkBlue,
  Colors.light.hereworksGreen400,
  Colors.light.hereworksGreen500,
  Colors.light.hereworksOrange500,
  Colors.light.hereworksBlue500,
  Colors.light.hereworksPink400,
  Colors.light.hereworksGreen200,
  Colors.light.hereworksOrange200,
];
export const lineColorCompared = Colors.light.hereworksYellow500;
export const lineColorComparedHappy = Colors.light.hereworksRed500;

type Tile = {
  lineShown: boolean,
  title: string,
  score: number,
  index: number,
}

interface HomeGraphLineSelectorProps {
  data: Tile[],
  setData: Dispatch<SetStateAction<object>>,
  showComparison: boolean,
}

const HomeGraphLineSelector = ({
  data, setData, showComparison,
}: HomeGraphLineSelectorProps) => {
  const scrollView = useRef<ScrollView>(null);
  const tileData: Tile[] = [...data];
  const homeTile: Tile = tileData.shift();
  const [startButtonDisabled, setStartButtonDisabled] = useState<boolean>(true);
  const [endButtonDisabled, setEndButtonDisabled] = useState<boolean>(false);
  return <View>
    <View style={[styles.row, styles.topSection]}>
      <View>
        {/* <Text style={styles.title}>{`Happy score and ${options[selectedLineIndex]}`}</Text> */}
        <Text style={styles.description}>
          Choose categories to compare to the overall Happy score
        </Text>
      </View>
      {showComparison && <View style={styles.row}>
        {/* <View style={[styles.line, { backgroundColor: lineColorCompared }]} /> */}
        {/* <Text style={[styles.optionText, styles.optionTextSelected]}>
          {`${options[selectedLineIndex]} (compared)`}
        </Text> */}
        <View style={[styles.line, { backgroundColor: lineColorComparedHappy }]} />
        <Text style={[styles.optionText, styles.optionTextSelected]}>
          Happy score (compared)
        </Text>
      </View>}
    </View>
    <View style={[styles.row, styles.bottomSection]}>
      <TouchableOpacity
        style={[styles.arrowButton, startButtonDisabled && styles.buttonDisabled]}
        disabled={startButtonDisabled}
        onPress={() => scrollView.current?.scrollTo({ x: 0 })}
      >
        <LeftArrow color={Colors.light.hereworksBlue500} />
      </TouchableOpacity>
      <View style={[styles.row, styles.scrollViewContainer]}>
        <ScrollView
          horizontal
          contentContainerStyle={styles.scrollViewContent}
          showsHorizontalScrollIndicator={false}
          onScroll={(e) => {
            const offset: number = e.nativeEvent.contentOffset.x;
            setStartButtonDisabled(offset === 0);
            setEndButtonDisabled(offset + e.nativeEvent.layoutMeasurement.width
              >= e.nativeEvent.contentSize.width - 1);
          }}
          ref={(ref) => { scrollView.current = ref; }}
        >
          {[homeTile, ...tileData.sort((prev: Tile, next: Tile) => prev.score - next.score)]
            .map((item) => {
              const lineIndex: number = item.index;
              const selected = lineIndex === 0 || data[lineIndex].lineShown;
              return <TouchableOpacity style={[styles.option, selected && styles.optionSelected]}
                activeOpacity={lineIndex === 0 ? 1 : undefined}
                onPress={() => {
                  if (lineIndex !== 0) {
                    const newData = [...data];
                    newData[lineIndex].lineShown = !data[lineIndex].lineShown;
                    setData(newData);
                  }
                }}>
                <View style={[styles.line, selected
                  && { backgroundColor: lineColors[lineIndex] }]} />
                <Text style={[styles.optionText, selected && styles.optionTextSelected]}>
                  {item.title}
                </Text>
                <View style={[
                  styles.checkBox,
                  data[lineIndex].lineShown && styles.checkBoxSelected,
                  lineIndex === 0 && styles.checkBoxLocked,
                ]}>
                  {lineIndex !== 0 && data[lineIndex].lineShown && <TickIcon />}
                  {lineIndex === 0 && <LockIcon />}
                </View>
              </TouchableOpacity>;
            })}
        </ScrollView>
        <LinearGradient style={styles.gradient}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={['#FFF', '#FFF0']} />
        <LinearGradient style={[styles.gradient, styles.end]}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={['#FFF0', '#FFF']} />
      </View>
      <TouchableOpacity
        style={[styles.arrowButton, endButtonDisabled && styles.buttonDisabled]}
        disabled={endButtonDisabled}
        onPress={() => scrollView.current?.scrollToEnd()}
      >
        <RightArrow color={Colors.light.hereworksBlue500} />
      </TouchableOpacity>
    </View>
  </View>;
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topSection: {
    justifyContent: 'space-between',
    paddingEnd: 24,
    marginBottom: 12,
  },
  bottomSection: {
    marginEnd: 24,
  },
  title: {
    marginBottom: 4,
    fontSize: 18,
    lineHeight: 27,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
  },
  description: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_500Medium',
    color: Colors.light.hereworksBlack400,
  },
  scrollViewContainer: {
    flex: 1,
  },
  scrollViewContent: {
    paddingHorizontal: 10,
  },
  option: {
    marginEnd: 8,
    padding: 8,
    borderRadius: 7,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlack100,
    backgroundColor: Colors.light.hereworksBlack50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionSelected: {
    borderColor: Colors.light.hereworksBlue50,
    backgroundColor: Colors.light.hereworksWhite100,
  },
  line: {
    width: 24,
    height: 2,
    borderRadius: 2,
    backgroundColor: Colors.light.hereworksBlack500,
    marginEnd: 6,
  },
  optionText: {
    fontSize: 12,
    lineHeight: 9.6,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack300,
    marginEnd: 12,
  },
  optionTextSelected: {
    color: Colors.light.hereworksBlack500,
  },
  checkBox: {
    width: 16,
    height: 16,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlack100,
    backgroundColor: Colors.light.hereworksBlack50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkBoxSelected: {
    borderColor: Colors.light.hereworksBlue500,
    backgroundColor: '#FFF',
  },
  checkBoxLocked: {
    borderColor: Colors.light.hereworksDarkBlue100,
    backgroundColor: Colors.light.hereworksDarkBlue50,
  },
  arrowButton: {
    marginHorizontal: 12,
  },
  gradient: {
    height: '100%',
    width: 10,
    position: 'absolute',
  },
  end: {
    end: 0,
  },
  buttonDisabled: {
    opacity: 0.2,
  },
});

export default HomeGraphLineSelector;
