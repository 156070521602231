import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const NoteIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M14 19c3.771 0 5.657 0 6.828-1.172C22 16.657 22 14.771 22 11c0-3.771 0-5.657-1.172-6.828C19.657 3 17.771 3 14 3h-4C6.229 3 4.343 3 3.172 4.172 2 5.343 2 7.229 2 11c0 3.771 0 5.657 1.172 6.828.653.654 1.528.943 2.828 1.07"
      stroke={props.color ?? '#323E48'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14 19c-1.236 0-2.598.5-3.84 1.145-1.999 1.037-2.998 1.556-3.49 1.225-.492-.33-.399-1.355-.212-3.404L6.5 17.5"
      stroke={props.color ?? '#323E48'}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default NoteIcon;
