import React, { ReactElement, useEffect, useState } from 'react';
import {
  Animated,
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as Animatable from 'react-native-animatable';
import QRCode from 'react-native-qrcode-svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';
import Modal from 'react-native-modal';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../../constants/Colors';
import DeleteIcon24 from '../24pxIcons/DeleteIcon24';
import CheckBox from '../16pxIcons/CheckBox';
import DownArrow24 from '../24pxIcons/DownArrow24';
import ZoomIcon from '../24pxIcons/ZoomIcon';
import AddEditNote from '../AddEditNote';
import EditIcon24 from '../24pxIcons/EditIcon24';
import OrionArrowIcon from '../24pxIcons/OrionArrowIcon';
import CheckBox24 from '../24pxIcons/CheckBox24';
import EditAssetModal from './EditAssetModal';
import getAssetTickets from '../../requests/GetAssetTickets';
import AlertModal from '../AlertModal';
import retireAsset from '../../requests/RetireAsset';
import ReportIssueIcon from '../12pxIcons/ReportIssueIcon';
import ReportIssueModal from './ReportIssueModal';

export type PressableState = Readonly<{
  hovered?: boolean;
}>;

export default function AssetRow(props) {
  const [cardOpen, setCardOpen] = useState(props.cardOpen);
  const [selected, setSelected] = useState(props.selected ?? false);
  const [switchToggled] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [qrCodeSelected, setQrCodeSelected] = useState(true);
  const [domain, setDomain] = useState('https://hereworks.tech/');
  const [modalVisible, setModalVisible] = useState(false);
  const [addNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [editNote, setEditNote] = useState(false);
  const [daySelected, setDaySelected] = useState(true);
  const [weekSelected, setWeekSelected] = useState(false);
  const [monthSelected, setMonthSelected] = useState(false);
  const [yearSelected, setYearSelected] = useState(false);
  const [editAssetVisible, setEditAssetVisible] = useState(false);
  const [ticketCounts, setTicketCounts] = useState([0, 0, 0, 0]);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const [name, setName] = useState(props.instance.Name ?? '');
  const [model, setModel] = useState(props.instance.Model ?? '');
  const [serial, setSerial] = useState(props.instance.Serial ?? '');
  const [manufacturer, setManufacturer] = useState(props.instance.Manufacturer ?? '');
  const [description, setDescription] = useState(props.instance.Description ?? '');
  const [template, setTemplate] = useState(props.instance.ButtonPrototypeName ?? '');
  const [building, setBuilding] = useState(props.instance.LocationBuilding ?? '');
  const [area, setArea] = useState(props.instance.LocationArea ?? '');
  const [floor, setFloor] = useState(props.instance.LocationFloor ?? '');
  const [room, setRoom] = useState(props.instance.LocationRoom ?? '');
  const [locationId, setLocationId] = useState(props.instance.LocationId ?? '');
  const [id] = useState(props.instance.ButtonInstanceId);
  const [customFields, setCustomFields] = useState(
    props.instance.CustomFields?.sort((previous, next) => {
      if (previous.CustomName < next.CustomName) {
        return -1;
      }
      if (previous.CustomName > next.CustomName) {
        return 1;
      }
      return 0;
    }),
  );
  const [image, setImage] = useState(
    props.instance.Images?.length > 0
      ? `${props.instance.Images[0]?.url}?time=${Date.now()}`
      : null,
  );

  const [notes] = useState(props.instance.Notes ?? []);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertAction, setAlertAction] = useState(null);
  const [alertActionTitle, setAlertActionTitle] = useState('');
  const [reportIssueModalVisible, setReportIssueModalVisible] = useState(false);

  const showCheckbox = false;

  const showAlert = (
    title: string,
    message?: string | ReactElement,
    error: boolean = true,
    action: () => void = null,
    actionTitle: string = null,
  ) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertError(error);
    setAlertVisible(true);
    setAlertAction(() => action);
    setAlertActionTitle(actionTitle);
  };

  const navigation = useNavigation();

  AsyncStorage.getItem('environment').then((result) => {
    if (result === 'dev') {
      setDomain('https://dev.hereworks.tech/');
    } else if (result === 'demo') {
      setDomain('https://demo.hereworks.tech/');
    }
  });

  useEffect(() => {
    if (cardOpen) {
      getAssetTickets(props.instance.ButtonInstanceId).then((response) => {
        const values = response?.Tickets;
        setTicketCounts([
          values?.day ?? 0,
          values?.week ?? 0,
          values?.month ?? 0,
          values?.year ?? 0,
        ]);
      });
    }
  }, [cardOpen]);

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'AssetManagement',
      name: title,
    });
  };

  const rowDivider = () => (
    <View
      style={{
        backgroundColor: Colors.light.hereworksWhite500,
        width: 1,
        height: 30,
      }}
    />
  );

  const renderCardClosedView = () => {
    if (props.mobile) {
      return (
        <Animatable.View animation={'fadeIn'} style={{ flex: 1 }}>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
            }}
            onPress={() => {
              analytics('Open card');
              setCardOpen(!cardOpen);
            }}
          >
            <View
              style={{
                width: 8,
                height: 60,
                zIndex: 1,
                backgroundColor: switchToggled
                  ? Colors.light.hereworksGreen400
                  : Colors.light.hereworksWhite600,
              }}
            />
            <TouchableOpacity
              style={{ justifyContent: 'center' }}
              onPress={() => {
                props.selectionChanged();
                setSelected(!selected);
              }}
            >
              <CheckBox24
                checkcolor={props.selected ?? selected ? Colors.light.hereworksBlue500 : null}
                color={
                  props.selected ?? selected
                    ? Colors.light.hereworksBlue500
                    : Colors.light.hereworksBlue100
                }
                style={{ marginHorizontal: 16 }}
              />
            </TouchableOpacity>
            <View
              style={{
                flexDirection: 'row',
                flex: 1,
                marginRight: 46,
              }}
            >
              <Image
                source={{
                  uri:
                    image
                    ?? 'https://s3.eu-west-1.amazonaws.com/devinsights.hereworks.tech/images/desk.png',
                }}
                resizeMode={'cover'}
                style={{ width: 80, height: 48, borderRadius: 4 }}
              />
              <View style={{ flex: 1, alignItems: 'flex-start', justifyContent: 'center' }}>
                <Text style={styles.rowTextMobile} selectable>
                  {props.instance.Name}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        </Animatable.View>
      );
    }

    return (
      <Animatable.View animation={'fadeIn'} style={{ flex: 1 }}>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
          }}
          onPress={() => {
            analytics('Open card');
            setCardOpen(!cardOpen);
          }}
        >
          <View
            style={{
              width: 8,
              height: 60,
              zIndex: 1,
              backgroundColor: switchToggled
                ? Colors.light.hereworksGreen400
                : Colors.light.hereworksWhite600,
            }}
          />
          {showCheckbox && <TouchableOpacity
            style={{ justifyContent: 'center' }}
            onPress={() => {
              props.selectionChanged();
              setSelected(!selected);
            }}
          >
            <CheckBox
              checkcolor={props.selected ?? selected ? null : '#fff'}
              color={props.selected ?? selected ? null : Colors.light.hereworksBlue100}
              style={{ marginLeft: 18, marginRight: 14 }}
            />
          </TouchableOpacity>}
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              marginRight: 96,
            }}
          >
            {showCheckbox && rowDivider()}
            <View style={{ flex: 2, alignItems: 'flex-start' }}>
              <Text selectable style={styles.rowText}>
                {name?.length > 0 ? name : props.instance.Name}
              </Text>
            </View>
            {rowDivider()}
            <View style={{ flex: 2, alignItems: 'flex-start' }}>
              <Text selectable style={styles.rowText}>
                {floor?.length > 0 ? floor : props.instance.LocationFloor}
              </Text>
            </View>
            {rowDivider()}
            <View style={{ flex: 2, alignItems: 'flex-start' }}>
              <Text selectable style={styles.rowText}>
                {room?.length > 0 ? room : props.instance.LocationRoom}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </Animatable.View>
    );
  };

  const renderMainView = () => (
    <View style={styles.mainInformationContainer}>
      <Modal isVisible={modalVisible} animationIn={'fadeIn'} animationOut={'fadeOut'}>
        <TouchableOpacity
          onPress={() => {
            analytics('Close image modal');
            setModalVisible(!modalVisible);
          }}
          style={{ height: '100%', flex: 1 }}
        >
          <Image
            source={{
              uri:
                image
                ?? 'https://s3.eu-west-1.amazonaws.com/devinsights.hereworks.tech/images/desk.png',
            }}
            resizeMode={'center'}
            style={{ width: undefined, height: '100%' }}
          />
        </TouchableOpacity>
      </Modal>
      <View>
        <Pressable
          style={{ marginBottom: 12 }}
          onPress={() => {
            analytics('Open image modal');
            setModalVisible(true);
          }}
        >
          {({ hovered }: PressableState): ReactElement => (
            <View style={{ opacity: hovered ? 0.5 : 1 }}>
              <Image
                source={{
                  uri:
                    image
                    ?? 'https://s3.eu-west-1.amazonaws.com/devinsights.hereworks.tech/images/desk.png',
                }}
                resizeMode={'cover'}
                style={{ width: 108, height: 108 }}
              />
              <ZoomIcon
                style={{
                  position: 'absolute',
                  bottom: 4,
                  right: 4,
                  borderRadius: 3,
                  backgroundColor: hovered
                    ? Colors.light.hereworksBlue500
                    : `${Colors.light.hereworksBlack300}05`,
                }}
              />
            </View>
          )}
        </Pressable>
      </View>
      <View style={styles.labelsContainer}>
        <View style={styles.labelSetHalfSize}>
          <Text style={styles.mainDescText}>Description</Text>
          <Text selectable style={styles.mainValueText}>
            {description}
          </Text>
        </View>
        <View style={styles.labelSetQuarterSize}>
          <Text style={styles.mainDescText}>Area</Text>
          <Text selectable style={styles.mainValueText}>
            {area}
          </Text>
        </View>
        <View style={styles.labelSetQuarterSize}>
          <Text style={styles.mainDescText}>Model</Text>
          <Text selectable style={styles.mainValueText}>
            {model}
          </Text>
        </View>
        <View style={styles.labelSetHalfSize}>
          <Text style={styles.mainDescText}>Asset ID</Text>
          <Text selectable style={styles.mainValueText}>
            {id}
          </Text>
        </View>
        <View style={styles.labelSetQuarterSize}>
          <Text style={styles.mainDescText}>Floor</Text>
          <Text selectable style={styles.mainValueText}>
            {floor}
          </Text>
        </View>
        <View style={styles.labelSetQuarterSize}>
          <Text style={styles.mainDescText}>Serial number</Text>
          <Text selectable style={styles.mainValueText}>
            {serial}
          </Text>
        </View>
        <View style={styles.labelSetHalfSize}>
          <Text style={styles.mainDescText}>Template</Text>
          <Text selectable style={styles.mainValueText}>
            {template}
          </Text>
        </View>
        <View style={styles.labelSetQuarterSize}>
          <Text style={styles.mainDescText}>Room</Text>
          <Text selectable style={styles.mainValueText}>
            {room}
          </Text>
        </View>
        <View style={styles.labelSetQuarterSize}>
          <Text style={styles.mainDescText}>Manufacturer</Text>
          <Text selectable style={styles.mainValueText}>
            {manufacturer}
          </Text>
        </View>
      </View>
      {/* {props.instance.CreatedOn && <View style={{ marginLeft: 24, marginTop: 16, flex: 1 }}>
          <Text style={styles.mainDescText}>Date added</Text>
          <Text style={styles.mainValueText}>
            {Moment(props.instance.CreatedOn).format('DD.MM.YYYY')}
          </Text>
        </View>}
        <View style={{ marginLeft: 24, marginTop: 16, flex: 1 }}>
          <Text style={styles.mainDescText}>IP address</Text>
          <Text style={styles.mainValueText}>192.168.55.22</Text>
          <Text style={styles.mainDescText}>MAC address</Text>
          <Text style={styles.mainValueText}>00:00:5e:00:53:af</Text>
          <Text style={styles.mainDescText}>Port number</Text>
          <Text style={styles.mainValueText}>255</Text>
          <Text style={styles.mainDescText}>Warranty Expiry Date</Text>
          <Text style={styles.mainValueText}>{Moment().format('DD.MM.YYYY')}</Text>
        </View> */}
    </View>
  );

  const renderNotes = (mobile) => (
    <View
      style={{
        borderColor: Colors.light.hereworksBlue200,
        borderLeftWidth: 1,
        paddingHorizontal: 24,
        flex: props.mobile ? 1 : 3,
      }}
    >
      <AddEditNote
        modalVisible={addNoteModalVisible}
        notes={notes}
        setVisible={() => setAddNoteModalVisible(false)}
        setEditNote={() => setEditNote(true)}
        selectedNote={selectedNote}
        editNote={editNote}
        mobile={mobile}
        assetId={props.instance.ButtonInstanceId}
        name={props.name}
        refresh={() => {}}
      />
      <View style={{ height: 180 }}>
        <ScrollView
          style={{
            flex: 1,
            marginTop: 16,
            width: '100%',
          }}
        >
          {notes.map((note, key) => (
            <TouchableOpacity
              key={key}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginVertical: 4,
                marginTop: 8,
                borderColor: Colors.light.hereworksWhite500,
                borderTopWidth: key > 0 ? 1 : 0,
              }}
              onPress={() => {
                analytics('Edit note');
                setSelectedNote(note);
                setEditNote(false);
                setAddNoteModalVisible(!addNoteModalVisible);
              }}
            >
              <View
                style={{
                  backgroundColor: Colors.light.hereworksDarkBlue,
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  marginRight: 8,
                }}
              />
              <View style={{ flex: 1, marginTop: 8 }}>
                <View style={{ flexDirection: 'row', flex: 1 }}>
                  <Text selectable style={styles.noteTitleText} numberOfLines={1}>
                    {note.Title}
                  </Text>
                  <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <Text selectable style={styles.noteDateText}>
                      {Moment(note.UpdatedOn).format('DD.MM.YYYY')}
                    </Text>
                  </View>
                </View>
                <Text selectable style={styles.noteNameText}>{`by ${note.name_value}`}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
      <TouchableOpacity
        style={{
          marginVertical: 16,
          backgroundColor: '#fff',
          borderRadius: 4,
          borderColor: Colors.light.hereworksBlue500,
          borderWidth: 1,
          alignSelf: 'flex-start',
        }}
        onPress={() => {
          analytics('Add note');
          setEditNote(false);
          setSelectedNote(null);
          setAddNoteModalVisible(!addNoteModalVisible);
        }}
      >
        <Text style={[styles.secondaryButtonText, { color: Colors.light.hereworksBlue500 }]}>
          Add note
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderMainDetails = () => (
    <View style={styles.assetHeaderButtonSection}>
      <TouchableOpacity
        style={styles.detailedFilterButton}
        onPress={() => setReportIssueModalVisible(true)}
      >
        <ReportIssueIcon />
      </TouchableOpacity>
      <View style={styles.assetHeaderButtonSectionDivider} />
      <TouchableOpacity
        style={[
          styles.secondaryButton,
          {
            backgroundColor: selectedTab === 0 ? Colors.light.hereworksBlue500 : '#fff',
          },
        ]}
        onPress={() => {
          analytics('Main tab');
          setSelectedTab(0);
        }}
      >
        <Text
          style={[
            styles.secondaryButtonText,
            { color: selectedTab !== 0 ? Colors.light.hereworksBlue500 : '#fff' },
          ]}
        >
          MAIN
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.secondaryButton,
          {
            backgroundColor: selectedTab !== 0 ? Colors.light.hereworksBlue500 : '#fff',
            marginRight: 78,
          },
        ]}
        onPress={() => {
          analytics('Details tab');
          setSelectedTab(1);
        }}
      >
        <Text
          style={[
            styles.secondaryButtonText,
            { color: selectedTab === 0 ? Colors.light.hereworksBlue500 : '#fff' },
          ]}
        >
          DETAILS
        </Text>
      </TouchableOpacity>
    </View>
  );

  return (
    <Animated.View
      style={{
        marginBottom: 12,
        alignItems: 'center',
      }}
    >
      <View
        style={{
          backgroundColor: Colors.light.hereworksWhite100,
          borderRadius: 10,
          overflow: 'hidden',
          alignSelf: 'stretch',
          flex: 1,
        }}
      >
        {!cardOpen && renderCardClosedView()}
        {cardOpen && (
          <Animatable.View animation={'fadeIn'} style={{ flexDirection: 'row', flex: 1 }}>
            <View
              style={{
                width: 17,
                height: '100%',
                zIndex: 1,
                backgroundColor:
                  props.selected || switchToggled
                    ? Colors.light.hereworksGreen400
                    : Colors.light.hereworksWhite600,
              }}
            />
            <View style={{ flex: 1 }}>
              {!props.mobile && (
                <View style={styles.assetRowHeader}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.assetTitleSub}>Asset's Name</Text>
                      <Text selectable style={styles.assetTitle} numberOfLines={1}>
                        {name}
                      </Text>
                    </View>
                    <Pressable
                      style={{ marginHorizontal: 8 }}
                      onPress={() => {
                        analytics('Edit asset');
                        setEditAssetVisible(!editAssetVisible);
                      }}
                    >
                      {({ hovered }: PressableState): ReactElement => (
                        <View
                          style={{
                            backgroundColor: hovered
                              ? Colors.light.hereworksBlue500
                              : 'transparent',
                            borderRadius: 4,
                          }}
                        >
                          <EditIcon24 color={hovered ? '#fff' : null} />
                        </View>
                      )}
                    </Pressable>
                  </View>
                  {renderMainDetails()}
                </View>
              )}
              {selectedTab === 1 && (
                <>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginBottom: 12,
                    }}
                  >
                    <View
                      style={{
                        marginVertical: 16,
                        marginHorizontal: 24,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: Colors.light.hereworksBlue200,
                        backgroundColor: '#fff',
                        width: 461,
                      }}
                    >
                      <View style={{ flexDirection: 'row' }}>
                        <View
                          style={{
                            flex: 1,
                            margin: 12,
                            borderWidth: 1,
                            borderRadius: 12,
                            borderColor: Colors.light.hereworksBlue200,
                            flexDirection: 'row',
                          }}
                        >
                          <View
                            style={{
                              paddingHorizontal: 19,
                              paddingVertical: 17,
                              borderRightWidth: 1,
                              borderColor: Colors.light.hereworksBlue200,
                              width: 124,
                            }}
                          >
                            {qrCodeSelected && (
                              <QRCode
                                value={`${`${domain}${props.instance.ButtonInstanceId}`}?ss=QR`}
                                size={70}
                              />
                            )}
                            {!qrCodeSelected && <FontAwesome name={'wifi'} size={70} />}
                          </View>
                          <View style={{ justifyContent: 'center', marginLeft: 24 }}>
                            <Text style={styles.activatedText}>
                              {'Status: '}
                              {switchToggled ? 'ACTIVE' : 'INACTIVE'}
                            </Text>
                            {/* <TouchableOpacity
                              onPress={() => {
                                analytics('Toggle switch');
                                setSwitchToggled(!switchToggled);
                              }}
                            >
                              <NewSwitch
                                isToggled={switchToggled}
                                color={Colors.light.hereworksGreen400}
                                style={{ backgroundColor: 'transparent' }}
                              />
                            </TouchableOpacity> */}
                          </View>
                        </View>
                        <View style={{ justifyContent: 'center' }}>
                          <TouchableOpacity
                            style={{
                              marginLeft: 12,
                              marginRight: 24,
                              backgroundColor: qrCodeSelected
                                ? Colors.light.hereworksBlue500
                                : '#fff',
                              borderRadius: 4,
                              borderColor: Colors.light.hereworksBlue500,
                              borderWidth: 1,
                            }}
                            onPress={() => {
                              if (!qrCodeSelected) {
                                analytics('Set QR selected');
                                setQrCodeSelected(!qrCodeSelected);
                              }
                            }}
                          >
                            <Text
                              style={[
                                styles.secondaryButtonText,
                                { color: !qrCodeSelected ? Colors.light.hereworksBlue500 : '#fff' },
                              ]}
                            >
                              QR CODE
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={{
                              marginLeft: 12,
                              marginRight: 24,
                              marginTop: 8,
                              backgroundColor: !qrCodeSelected
                                ? Colors.light.hereworksBlue500
                                : '#fff',
                              borderRadius: 4,
                              borderColor: Colors.light.hereworksBlue500,
                              borderWidth: 1,
                            }}
                            onPress={() => {
                              if (qrCodeSelected) {
                                analytics('Set NFC selected');
                                setQrCodeSelected(!qrCodeSelected);
                              }
                            }}
                          >
                            <Text
                              style={[
                                styles.secondaryButtonText,
                                { color: qrCodeSelected ? Colors.light.hereworksBlue500 : '#fff' },
                              ]}
                            >
                              NFC TAG
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View
                        style={{
                          borderColor: Colors.light.hereworksBlue200,
                          borderTopWidth: 1,
                          paddingHorizontal: 12,
                          paddingVertical: 16,
                        }}
                      >
                        <Text style={styles.activatedText}>GENERATED QR LINK</Text>
                        <Text
                          numberOfLines={1}
                          selectable
                        >{`${`${domain}${props.instance.ButtonInstanceId}`}?ss=${
                          qrCodeSelected ? 'QR' : 'NFC'
                        }`}</Text>
                      </View>
                    </View>
                    {renderNotes(false)}
                  </View>
                </>
              )}
              {selectedTab === 0 && renderMainView()}
              {selectedTab === 0 && (
                <>
                  <View style={styles.section}>
                    <Text style={styles.sectionTitle}>ADDITIONAL INFO</Text>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                      {customFields?.map((field, index) => {
                        if (
                          field.CustomName.toLowerCase().includes('model')
                          || field.CustomName.toLowerCase().includes('serial')
                          || field.CustomName.toLowerCase().includes('manu')
                          || field.CustomName.toLowerCase().includes('desc')
                          || field.CustomName.toLowerCase().includes('building')
                          || field.CustomName.toLowerCase().includes('floor')
                          || field.CustomName.toLowerCase().includes('room')
                          || field.CustomName.toLowerCase() === 'name'
                        ) {
                          return null;
                        }
                        let title = field.CustomName;
                        if (title.includes('Date')) {
                          title = `${title.split('Date')[0]} Date`;
                        } else if (title.includes('date')) {
                          title = `${title.split('date')[0]} Date]`;
                        }
                        if (title.includes('Name')) {
                          title = `${title.split('Name')[0]} Name`;
                        } else if (title.includes('name')) {
                          title = `${title.split('name')[0]} Name`;
                        }
                        if (title.includes('Number')) {
                          title = `${title.split('Number')[0]} Number`;
                        } else if (title.includes('number')) {
                          title = `${title.split('number')[0]} Number`;
                        }
                        if (title.includes('Address')) {
                          title = `${title.split('Address')[0]} Address ${
                            title.split('Address')[1]
                          }`;
                        } else if (title.includes('address')) {
                          title = `${title.split('address')[0]} Address ${
                            title.split('address')[1]
                          }`;
                        }
                        return (
                          <View style={styles.labelSetThirdSize} key={index}>
                            <Text selectable style={styles.mainDescText}>
                              {title}
                            </Text>
                            <Text selectable style={styles.mainValueText}>
                              {field.CustomValue}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </>
              )}
            </View>
            <EditAssetModal
              asset={props.instance}
              visible={editAssetVisible}
              setModalVisible={setEditAssetVisible}
              name={name}
              model={model}
              serial={serial}
              manufacturer={manufacturer}
              description={description}
              template={template}
              building={building}
              area={area}
              floor={floor}
              room={room}
              locationId={locationId}
              customFields={customFields}
              updateCustomFields={(fields) => setCustomFields(fields)}
              updateName={(text) => setName(text)}
              updateModel={(text) => setModel(text)}
              updateSerial={(text) => setSerial(text)}
              updateManufacturer={(text) => setManufacturer(text)}
              updateDescription={(text) => setDescription(text)}
              updateTemplate={(text) => setTemplate(text)}
              updateBuilding={(text) => setBuilding(text)}
              updateArea={(text) => setArea(text)}
              updateFloor={(text) => setFloor(text)}
              updateRoom={(text) => setRoom(text)}
              updateLocationId={(text) => setLocationId(text)}
              updateImage={(newImage) => setImage(newImage)}
              id={id}
              updateAsset={props.updateAsset}
            />
          </Animatable.View>
        )}
        <TouchableOpacity
          style={{
            position: 'absolute',
            right: props.mobile ? 24 : 50,
            top: 16,
          }}
          onPress={() => setCardOpen(!cardOpen)}
        >
          {!props.mobile && (
            <DownArrow24 style={cardOpen && { transform: [{ rotateZ: '180deg' }] }} />
          )}
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            position: 'absolute',
            right: 20,
            top: 18,
          }}
          onPress={() => {
            showAlert(
              'Delete the asset?',
              <Text>
                Do you want to delete the asset - <Text style={styles.alertAssetTitle}>{name}</Text>
                ?
              </Text>,
              true,
              () => {
                props.setLoading(true);
                retireAsset(props.instance.ButtonInstanceId)
                  .then((response) => {
                    props.setLoading(false);
                    if (response?.isError) {
                      showAlert('Error', response.errorText);
                    } else {
                      showAlert('Success', 'Asset deleted successfully.', false, props.deleteAsset(id));
                    }
                  })
                  .catch(() => props.setLoading(false));
              },
              'Delete',
            );
          }}
        >
          <DeleteIcon24 />
        </TouchableOpacity>
      </View>
      {cardOpen && !props.mobile && (
        <Animatable.View
          animation={'fadeIn'}
          style={{
            backgroundColor: Colors.light.hereworksWhite100,
            borderRadius: 10,
            height: 78,
            marginTop: 24,
            alignSelf: 'stretch',
            alignItems: 'center',
            flexDirection: 'row',
            overflow: 'hidden',
          }}
        >
          <View style={{ marginLeft: 24, flex: 3 }}>
            <Text style={styles.raisedTitle}>Tickets raised against asset</Text>
            <TouchableOpacity
              onPress={() => {
                analytics('View log');
                // @ts-ignore
                navigation.navigate('Tickets', { assetId: props.instance.ButtonInstanceId });
              }}
              style={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <Text
                style={{
                  color: Colors.light.hereworksBlue500,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 14,
                  lineHeight: 22,
                }}
              >
                View log
              </Text>
              <OrionArrowIcon style={{ transform: [{ rotateZ: '180deg' }] }} />
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 6,
              borderRadius: 10,
              backgroundColor: Colors.light.hereworksBlue500,
              height: 60,
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.ticketsValue}>{ticketCounts[selectedFilterIndex]}</Text>
              <Text style={styles.ticketsTitle}>Tickets</Text>
            </View>
            <View
              style={{
                paddingHorizontal: 24,
                height: 60,
                backgroundColor: Colors.light.hereworksBlue400,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TouchableOpacity
                style={[
                  styles.dateButton,
                  {
                    backgroundColor: daySelected ? '#fff' : Colors.light.hereworksBlue400,
                  },
                ]}
                onPress={() => {
                  analytics('DAY');
                  if (!daySelected) {
                    setDaySelected(true);
                    setSelectedFilterIndex(0);
                  }
                  setWeekSelected(false);
                  setMonthSelected(false);
                  setYearSelected(false);
                }}
              >
                <Text
                  style={[
                    styles.dateButtonTitle,
                    {
                      color: daySelected ? Colors.light.hereworksBlue500 : '#fff',
                    },
                  ]}
                >
                  DAY
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.dateButton,
                  {
                    backgroundColor: weekSelected ? '#fff' : Colors.light.hereworksBlue400,
                  },
                ]}
                onPress={() => {
                  analytics('WEEK');
                  if (!weekSelected) {
                    setWeekSelected(true);
                    setSelectedFilterIndex(1);
                  }
                  setDaySelected(false);
                  setMonthSelected(false);
                  setYearSelected(false);
                }}
              >
                <Text
                  style={[
                    styles.dateButtonTitle,
                    {
                      color: weekSelected ? Colors.light.hereworksBlue500 : '#fff',
                    },
                  ]}
                >
                  WEEK
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.dateButton,
                  {
                    backgroundColor: monthSelected ? '#fff' : Colors.light.hereworksBlue400,
                  },
                ]}
                onPress={() => {
                  analytics('MONTH');
                  if (!monthSelected) {
                    setMonthSelected(true);
                    setSelectedFilterIndex(2);
                  }
                  setDaySelected(false);
                  setWeekSelected(false);
                  setYearSelected(false);
                }}
              >
                <Text
                  style={[
                    styles.dateButtonTitle,
                    {
                      color: monthSelected ? Colors.light.hereworksBlue500 : '#fff',
                    },
                  ]}
                >
                  MONTH
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.dateButton,
                  {
                    backgroundColor: yearSelected ? '#fff' : Colors.light.hereworksBlue400,
                  },
                ]}
                onPress={() => {
                  analytics('YEAR');
                  if (!yearSelected) {
                    setYearSelected(true);
                    setSelectedFilterIndex(3);
                  }
                  setDaySelected(false);
                  setWeekSelected(false);
                  setMonthSelected(false);
                }}
              >
                <Text
                  style={[
                    styles.dateButtonTitle,
                    {
                      color: yearSelected ? Colors.light.hereworksBlue500 : '#fff',
                    },
                  ]}
                >
                  YEAR
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </Animatable.View>
      )}
      <AlertModal
        modalVisible={alertVisible}
        error={alertError}
        title={alertTitle}
        action={alertAction}
        actionTitle={alertActionTitle}
        message={alertMessage}
        setModalVisible={setAlertVisible}
      />
      <ReportIssueModal
        isVisible={reportIssueModalVisible}
        hide={() => setReportIssueModalVisible(false)}
        name={name}
        template={
          props.templates?.filter(
            (prototype: { id: string }) => props.instance.ButtonPrototypeId === prototype.id,
          )[0]
        }
        id={id}
      />
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  assetRowHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: Colors.light.hereworksBlue100,
    borderBottomWidth: 1,
    height: 69,
    paddingHorizontal: 24,
  },
  mainInformationContainer: {
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue200,
    backgroundColor: 'white',
    borderRadius: 12,
    marginVertical: 16,
    marginHorizontal: 24,
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 4,
    flexDirection: 'row',
  },
  section: {
    marginHorizontal: 24,
    marginBottom: 4,
  },
  sectionTitle: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 14,
    color: Colors.light.hereworksBlack400,
    width: '100%',
    paddingBottom: 2,
    marginBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: Colors.light.hereworksBlue200,
  },
  sectionLabelContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rowText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksPrimaryGrey,
    textAlign: 'left',
    marginLeft: 30,
  },
  rowTextMobile: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    textAlign: 'left',
    marginLeft: 12,
  },
  rowSubText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    color: Colors.light.hereworksPrimaryGrey700,
  },
  activatedText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 11,
    lineHeight: 13,
    marginBottom: 4,
    marginRight: 8,
    color: Colors.light.hereworksBlack400,
  },
  labelsContainer: {
    marginLeft: 16,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  labelSetQuarterSize: {
    flexBasis: '25%',
  },
  labelSetThirdSize: {
    flexBasis: '33%',
  },
  labelSetHalfSize: {
    flexBasis: '50%',
  },
  mainDescText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 14,
    marginBottom: 8,
    color: Colors.light.hereworksBlack400,
  },
  mainDescTextMobile: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 14,
    marginBottom: 4,
    color: Colors.light.hereworksBlack400,
  },
  noteDateText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 14,
    color: Colors.light.hereworksBlack400,
  },
  noteNameText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 11,
    lineHeight: 17,
    color: Colors.light.hereworksBlack400,
    marginTop: 2,
  },
  noteTitleText: {
    flex: 1,
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 14,
    color: Colors.light.hereworksBlack600,
  },
  buttonText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    marginVertical: 5,
    marginHorizontal: 12,
    color: '#fff',
  },
  secondaryButton: {
    borderRadius: 4,
    borderColor: Colors.light.hereworksBlue500,
    borderWidth: 1,
    zIndex: 2,
    marginEnd: 8,
  },
  secondaryButtonText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
    height: 20,
    marginVertical: 4,
    marginHorizontal: 12,
  },
  assetTitleSub: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 12,
    lineHeight: 18,
    marginTop: 12,
    color: Colors.light.hereworksBlack400,
  },
  assetTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 18,
    lineHeight: 27,
    marginBottom: 12,
    color: Colors.light.hereworksBlack600,
  },
  assetTitleMobile: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    lineHeight: 24,
    marginLeft: 24,
    marginBottom: 8,
    color: Colors.light.hereworksBlack600,
  },
  raisedTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 18,
    lineHeight: 27,
    color: Colors.light.hereworksBlack600,
  },
  mainValueText: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 14,
    lineHeight: 16,
    color: Colors.light.hereworksBlack600,
    marginBottom: 12,
  },
  mainValueTextMobile: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 14,
    color: Colors.light.hereworksBlack600,
    marginBottom: 12,
  },
  ticketsValue: {
    fontSize: 34,
    lineHeight: 42,
    marginBottom: 9,
    fontFamily: 'Poppins_600SemiBold',
    color: '#fff',
    marginLeft: 24,
  },
  ticketsTitle: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Roboto_400Regular',
    color: '#fff',
    marginLeft: 4,
    marginBottom: 14,
  },
  dateButtonTitle: {
    marginVertical: 4,
    marginHorizontal: 12,
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
  },
  dateButton: {
    borderRadius: 4,
    marginHorizontal: 4,
    borderWidth: 1,
    borderColor: '#fff',
  },
  circleButton: {
    borderRadius: 16,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: Colors.light.hereworksBlue500,
    borderWidth: 1,
  },
  tabButton: {
    borderRadius: 4,
    borderColor: Colors.light.hereworksBlue500,
    borderWidth: 1,
    zIndex: 2,
    marginLeft: 8,
    marginVertical: 12,
  },
  alertAssetTitle: {
    fontFamily: 'Roboto_700Bold',
  },
  detailedFilterButton: {
    backgroundColor: '#fff',
    padding: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    marginEnd: 12,
  },
  assetHeaderButtonSectionDivider: {
    width: 1,
    marginEnd: 12,
  },
  assetHeaderButtonSection: {
    height: 28,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
});
