import React from 'react';
import {
  View, StyleSheet, Text, ScrollView,
} from 'react-native';
import Colors from '../constants/Colors';

interface TopSectionInsightsProps {
  average: number;
  yesterdayScore: number;
  todayScore: number;
  description?: string;
}

const TopSectionInsights = ({
  average, yesterdayScore, todayScore, description,
}: TopSectionInsightsProps) => {
  const percentageChange: number | null = Math.floor(
    100 * ((todayScore - yesterdayScore) / yesterdayScore),
  );
  const difference: string = Number.isFinite(percentageChange)
    ? `${percentageChange > 0 ? '+' : ''}${percentageChange}%`
    : '-';
  return (
    <View style={styles.defaultContentContainer}>
      <View style={styles.scoreSectionContainer}>
        <Text style={styles.scoreSectionValue}>{average ? Math.floor(average) : '-'}</Text>
        <Text style={styles.scoreSectionTitle}>average score</Text>
        <View style={styles.scoreSectionItemSeparator} />
        <Text style={styles.scoreSectionValue}>{difference}</Text>
        <Text style={styles.scoreSectionTitle}>difference from yesterday</Text>
      </View>
      <ScrollView style={styles.insightsSection}>
        <Text style={styles.insightsTitle}>Building Insights</Text>
        <View style={styles.insightsTextContainer}>
          <Text style={styles.insightsText}>{description}</Text>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  defaultContentContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  scoreSectionContainer: {
    width: 132,
    marginStart: 24,
    marginEnd: 16,
    borderRadius: 16,
    padding: 16,
    backgroundColor: Colors.light.hereworksWhite100,
    borderWidth: 1,
    borderColor: Colors.light.hereworksWhite300,
    justifyContent: 'flex-end',
  },
  scoreSectionValue: {
    fontSize: 24,
    lineHeight: 24,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksDarkBlue,
    marginBottom: 8,
  },
  scoreSectionTitle: {
    fontSize: 12,
    lineHeight: 14.4,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
  },
  scoreSectionItemSeparator: {
    width: 64,
    height: 1.5,
    backgroundColor: Colors.light.hereworksWhite500,
    marginVertical: 16,
  },
  insightsSection: {
    flex: 1,
    padding: 16,
    borderRadius: 16,
    backgroundColor: Colors.light.hereworksWhite100,
    borderWidth: 1,
    borderColor: Colors.light.hereworksWhite300,
    marginEnd: 24,
  },
  insightsTitle: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
  },
  insightsTextContainer: {
    backgroundColor: '#FFF',
    borderColor: Colors.light.hereworksWhite500,
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginTop: 8,
    flex: 1,
  },
  insightsText: {
    fontSize: 12,
    lineHeight: 22,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
  },
});

export default TopSectionInsights;
