import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const EditIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="M14 14H2m11.253-9.333-5.92 5.886-2.666.78.78-2.666 5.92-5.92a1.393 1.393 0 0 1 1.966-.08 1.394 1.394 0 0 1-.08 2v0Z"
      stroke={props.color ?? '#2173C4'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default EditIcon;
