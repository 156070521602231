import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CoffeeMachine24 = (props: SvgProps) => (
  <Svg width={25} height={24} fill="none" {...props}>
    <Path
      d="M2.666 3v2a1 1 0 0 0 1 1h10.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-10.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-18a1 1 0 0 0-1 1Z"
      stroke="#89A7CB"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <Path
      d="M5.666 13v-.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5V16a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v0m0-3h-2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2m0-3v3M18.666 6v5M5.666 6v3h2.5m2.5-3v3h-2.5m0 0v1"
      stroke="#89A7CB"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default CoffeeMachine24;
