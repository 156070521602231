import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const DeskIcon24 = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M3 18V7h12m6 11v-5.5M15 7h6v5.5M15 7v5.5m0 5.5v-5.5m0 0h6"
      stroke="#89A7CB"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default DeskIcon24;
