import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const BuildingIcon = (props: SvgProps) => (
    <Svg
        width={28}
        height={28}
        fill={'none'}
        {...props}
    >
        <Path
            d="M3 25.407v-13.2h4.4m0 0v13.2m0-13.2v-8.8h13.2v8.8m0 13.2v-13.2m-8.8 13.2v-8.8h4.4v8.8m4.4-13.2H25v13.2m-13.2-17.6v4.4h4.4v-4.4h-4.4Z"
            stroke={props.color ?? '#919193'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default BuildingIcon;
