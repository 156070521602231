import React from 'react';
import {
  Platform, StyleSheet, Text, TextInput, TouchableOpacity, View,
} from 'react-native';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../constants/Colors';
import SearchIcon from './SearchIcon';

export default function FilterSearchBox(props) {
  const analytics = (title) => {
    Analytics.logEvent('button_press', {
      screen: 'FilterSearchBox',
      name: title,
    });
  };

  const filterHeader = (title) => (
    <View
      style={{
        flexDirection: 'row',
        alignSelf: 'stretch',
      }}
    >
      <Text style={styles.filterHeading}>{title}</Text>
      <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center' }}>
        {props.value !== '' && (
          <TouchableOpacity
            style={{
              justifyContent: 'center',
              zIndex: -1,
            }}
            onPress={() => {
              analytics(`Reset ${title} Button Pressed`);
              props.onChangeText('');
            }}
          >
            <Text
              style={{
                color: Colors.light.hereworksPrimaryGrey,
                fontSize: 11,
                fontFamily: 'Roboto_500Medium',
                borderBottomWidth: 1,
                borderColor: Colors.light.hereworksPrimaryGrey,
              }}
            >
              RESET
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );

  const searchBox = (placeholder) => (
    <View style={styles.filterSearchBox}>
      <TextInput
        // @ts-ignore
        style={[styles.inputText, !props.mobile ? { outlineStyle: 'none' } : {}]}
        placeholder={placeholder}
        value={props.value}
        onChangeText={props.onChangeText}
        placeholderTextColor={Colors.light.hereworksBlack400}
      />
      <SearchIcon />
    </View>
  );

  return (
    <>
      {filterHeader(props.title)}
      {searchBox(props.placeholder)}
    </>
  );
}

const styles = StyleSheet.create({
  filterSearchBox: {
    height: 40,
    borderRadius: 10,
    backgroundColor: '#fff',
    borderColor: Colors.light.hereworksBlue100,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: 3,
    marginBottom: 8,
    paddingHorizontal: 8,
  },
  filterHeading: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
  },
  inputText: {
    color: Colors.light.hereworksBlack600,
    alignSelf: 'stretch',
    fontFamily: 'Poppins_400Regular',
    alignItems: 'center',
    flex: 1,
    fontSize: 14,
    lineHeight: Platform.OS === 'ios' ? undefined : 21,
    alignContent: 'center',
  },
});
