import { Alert, Platform } from 'react-native';

export default function ComingSoon() {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line no-alert, no-undef
    alert('Feature coming soon!');
  } else {
    Alert.alert('Feature coming soon!');
  }
}
