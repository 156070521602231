import React, { useState } from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity,
} from 'react-native';
import { FileUploader } from 'react-drag-drop-files';
import DownloadIcon16 from '../../16pxIcons/DownloadIcon16';
import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';
import ImageIcon16 from '../../16pxIcons/ImageIcon16';
import DeleteIcon16 from '../../16pxIcons/DeleteIcon16';
import AlertModal from '../../AlertModal';

const MAX_SIZE_MB: number = 2;

interface FloorPlanBoxProps {
  plan: string,
  planSize: number,
  planFilename: string,
  // eslint-disable-next-line no-unused-vars
  updatePlan: (newPlan: string) => void,
  // eslint-disable-next-line no-unused-vars
  updatePlanSize: (newSize: number) => void,
  // eslint-disable-next-line no-unused-vars
  updatePlanFilename: (newFilename: string) => void,
  onPress: () => void,
}

const FloorPlanBox = ({
  plan, planSize, planFilename, updatePlan, updatePlanSize, updatePlanFilename, onPress,
}: FloorPlanBoxProps) => {
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>();

  const showAlert = (message: string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };

  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.onload = (e) => updatePlan(e.target.result.toString());

  return <View style={styles.wrapper}>
    <Text style={styles.title}>Upload floor plan</Text>
    <FileUploader
      types={['png', 'jpeg', 'jpg']}
      maxSize={MAX_SIZE_MB}
      onTypeError={showAlert}
      onSizeError={showAlert}
      handleChange={(file) => {
        onPress();
        updatePlanSize(Math.ceil(file.size / 100000) / 10);
        updatePlanFilename(file.name);
        reader.readAsDataURL(file);
      }}
    >
      <View style={styles.container}>
        <View style={styles.icon}>
          <DownloadIcon16 />
        </View>
        <Text style={styles.dragAndDropTitle}>Drag and drop</Text>
        <View style={Style.row}>
          <Text style={styles.dragAndDropDescription}>or </Text>
          <Text style={[styles.dragAndDropDescription, styles.selectFileText]}>
            select .jpg or .png file
          </Text>
          <Text style={styles.dragAndDropDescription}> from computer</Text>
        </View>
      </View>
    </FileUploader>
    {plan && <View style={[Style.row, styles.currentPlanBox]}>
      <View style={Style.row}>
        <ImageIcon16 />
        <Text style={styles.fileName}>{planFilename ?? 'Unknown file'}</Text>
      </View>
      <View style={Style.row}>
        <Text>{`${planSize} MB`}</Text>
        <TouchableOpacity
          style={styles.deleteIcon}
          onPress={() => updatePlan(null)}
        >
          <DeleteIcon16 color={Colors.light.hereworksBlue100} />
        </TouchableOpacity>
      </View>
    </View>}
    <AlertModal
      modalVisible={alertVisible}
      setModalVisible={setAlertVisible}
      title='Error'
      message={alertMessage}
    />
  </View>;
};

const styles = StyleSheet.create({
  wrapper: {
    zIndex: -1,
  },
  title: {
    fontSize: 20,
    lineHeight: 30,
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Roboto_500Medium',
    marginBottom: 12,
  },
  container: {
    paddingVertical: 24,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.light.hereworksBlue100,
    backgroundColor: Colors.light.hereworksWhite100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    padding: 16,
    backgroundColor: Colors.light.hereworksBlue50,
    height: 64,
    aspectRatio: 1,
    borderRadius: 32,
    marginBottom: 12,
  },
  dragAndDropTitle: {
    fontSize: 18,
    lineHeight: 27,
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_600SemiBold',
    textAlign: 'center',
    marginBottom: 8,
  },
  dragAndDropDescription: {
    color: Colors.light.hereworksBlack400,
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Poppins_500Medium',
    textAlign: 'center',
  },
  selectFileText: {
    color: Colors.light.hereworksBlue600,
  },
  currentPlanBox: {
    marginTop: 12,
    paddingVertical: 11,
    paddingHorizontal: 8,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.light.hereworksBlue100,
    backgroundColor: Colors.light.hereworksWhite100,
  },
  fileName: {
    fontSize: 12,
    lineHeight: 18,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
    marginHorizontal: 8,
  },
  fileSize: {
    color: Colors.light.hereworksBlack400,
  },
  deleteIcon: {
    marginStart: 8,
  },
});

export default FloorPlanBox;
