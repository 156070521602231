/* eslint-disable no-nested-ternary */
import { processFontFamily } from 'expo-font';
import Moment from 'moment';
import React, { useEffect, useState, FC } from 'react';
import {
  VictoryLine,
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryGroup,
} from 'victory';
import { Text, View } from 'react-native';
import Colors from '../constants/Colors';
import { GetMetricByDate } from '../requests/GetMetricByDate';
import { GetProcessedElectricity } from '../requests/GetProcessedElectricity';
import { axisDateLabel, tooltipDateLabel, tickValSamples } from './axisUtils';

type Props = {
  dates: { start: any; end: any };
  compareDates: { start: any; end: any };
  metricName: string;
  title: string;
  interval: string;
  compareToggled: boolean;
  selectedUtility: string;
  prevMetric: string;
  prevValue: string;
};
const BarWithLineGraphWeb: FC<Props> = ({
  dates,
  compareDates,
  metricName,
  interval,
  compareToggled,
  selectedUtility,
  prevMetric,
  prevValue,
}) => {
  const [graphData, setGraphData] = useState([]);
  const [compareGraphData, setCompareGraphData] = useState([]);
  const [utilityData, setUtilityData] = useState([]);
  const [compareUtilityData, setCompareUtilityData] = useState([]);
  const [tickVals, setTickVals] = useState([]);
  const [tickVals2, setTickVals2] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState(null);
  const pointsArray = [];
  const comparePointsArray = [];
  let point = {};
  let comparePoint = {};

  const Score = [];
  const Score2 = [];

  const getIndexName = (metric) => {
    switch (metric) {
      case 'data-unitsconsumed':
        return 'hw-processeddata-electricity';
      case 'data-peoplecount':
        return 'hw-rawdata-buildingoccupancy';
      default: return 'happyscore_flat_permin';
    }
  };

  useEffect(() => {
    const tickArray = [...Array(graphData.length).keys()];
    const tickArray2 = [...Array(compareGraphData.length).keys()];
    const tickArrayUtil = [];
    setTickVals(tickValSamples(tickArray, 7));
    setTickVals2(tickValSamples(tickArray2, 7));
    utilityData.forEach((i) => tickArrayUtil.push(i.value));
  }, [compareGraphData, graphData]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (metricName === 'data-unitsconsumed') {
          GetProcessedElectricity(
            metricName,
            dates,
            interval,
            getIndexName(metricName),
          ).then((data) => {
            if (data !== undefined) {
              data?.forEach((e, idx) => {
                Score.push(e.score.value);
                point = {
                  value: Math.floor(e.score.value * 10) / 10,
                  index: idx,
                  date: e.key_as_string,
                  isCompare: false,
                  x: idx,
                  y: idx,
                };
                pointsArray.push(point);
              });
            }
            setGraphData(pointsArray);
          });
        } else {
          GetMetricByDate(
            metricName,
            dates,
            interval,
            getIndexName(metricName),
          )?.then((data) => {
            if ((metricName === 'data-kwh-total' || metricName === 'data-totalkwh') && data !== undefined) {
              data?.forEach((e, idx) => {
                if (idx > 0) {
                  const dateobj = new Date(e.key_as_string);
                  point = {
                    name: idx,
                    value: e.score.value
                      ? Math.floor(
                        e.score.value - data[idx - 1].score.value,
                      )
                      : 0,
                    targetValue: Math.floor(
                      Math.random()
                      * (e.score.value - data[idx - 1].score.value),
                    ),
                    x: idx,
                    dateLabel: `${Moment(dateobj).format('MMM')} ${Moment(dateobj).format('DD')}`,
                    index: idx - 1,
                  };
                  pointsArray.push(point);
                }
              });
            } else if (data !== undefined) {
              data?.forEach((e, idx) => {
                Score.push(e.score.value);
                point = {
                  value: Math.floor(e.score.value * 10) / 10,
                  index: idx,
                  date: e.key_as_string,
                  isCompare: false,
                  x: idx,
                  y: idx,
                };
                // console.log(point);
                pointsArray.push(point);
              });
            }
            setGraphData(pointsArray);
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(e, e.stack);
      }
    };
    getData();
  }, [interval, dates, metricName, prevMetric]);

  useEffect(() => {
    const getData2 = async () => {
      try {
        if (prevMetric === 'data-unitsconsumed') {
          GetProcessedElectricity(
            prevMetric,
            compareDates,
            interval,
            getIndexName(prevMetric),
          ).then((data) => {
            if (data !== undefined) {
              data?.forEach((e, idx) => {
                Score2.push(e.score.value);
                point = {
                  value: Math.floor(e.score.value * 10) / 10,
                  index: idx,
                  date: e.key_as_string,
                  isCompare: true,
                  x: idx,
                  y: idx,
                };
                // console.log(point);
                comparePointsArray.push(point);
              });
            }
            setCompareGraphData(comparePointsArray);
          });
        } else {
          GetMetricByDate(
            prevMetric,
            compareDates,
            interval,
            getIndexName(prevMetric),
          )?.then((data) => {
            let index = 0;
            if ((prevMetric === 'data-kwh-total' || prevMetric === 'data-totalkwh') && data !== undefined) {
              data?.forEach((e, idx) => {
                Score2.push(e.score.value);
                if (idx > 0) {
                  const dateobj = new Date(e.key_as_string);
                  point = {
                    name: idx,
                    value: e.score.value
                      ? Math.floor(
                        e.score.value - data[idx - 1].score.value,
                      )
                      : 0,
                    targetValue: Math.floor(
                      Math.random()
                      * (e.score.value - data[idx - 1].score.value),
                    ),
                    isCompare: true,
                    dateLabel: `${Moment(dateobj).format('MMM')} ${Moment(dateobj).format('DD')}`,
                    index: idx,
                  };
                  comparePointsArray.push(point);
                }
              });
            } else if (data !== undefined) {
              data?.forEach((e) => {
                Score2.push(e.score.value);
                comparePoint = {
                  value: Math.floor(e.score.value * 10) / 10,
                  index,
                  date: e.key_as_string,
                  isCompare: true,
                };
                comparePointsArray.push(comparePoint);
                index += 1;
              });
            }
            setCompareGraphData(comparePointsArray);
          });
        }
      } catch (error) {
        // console.error(error);
      }
    };
    getData2();
  }, [interval, compareDates, metricName, prevMetric]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (prevMetric === 'data-unitsconsumed') {
          const index = 0;
          const utilityArray = [];
          GetProcessedElectricity(
            prevMetric,
            dates,
            interval,
            'hw-processeddata-electricity',
          ).then((data) => {
            if (data !== undefined) {
              data?.forEach((e, idx) => {
                point = {
                  name: index,
                  value: Math.floor(e.score.value * 10) / 10,
                  targetValue: Math.floor(Math.random() * e.score.value),
                  x: index,
                  index: idx,
                };
                utilityArray.push(point);
              });
            }
            setUtilityData(utilityArray);
          });
        } else {
          GetMetricByDate(
            prevMetric,
            dates,
            interval,
            getIndexName(prevMetric),
          )?.then((data) => {
            const index = 0;
            const utilityArray = [];
            if ((prevMetric === 'data-kwh-total' || prevMetric === 'data-totalkwh') && data !== undefined) {
              data?.forEach((e, idx) => {
                if (idx > 0) {
                  const dateobj = new Date(e.key_as_string);
                  point = {
                    name: idx,
                    value: e.score.value
                      ? Math.floor(
                        e.score.value - data[idx - 1].score.value,
                      )
                      : 0,
                    targetValue: Math.floor(
                      Math.random()
                      * (e.score.value - data[idx - 1].score.value),
                    ),
                    x: idx,
                    index: idx - 1,
                    dateLabel: `${Moment(dateobj).format('MMM')} ${Moment(dateobj).format('DD')}`,
                  };
                  utilityArray.push(point);
                }
              });
            } else if (data !== undefined) {
              data?.forEach((e, idx) => {
                point = {
                  name: index,
                  value: Math.floor(e.score.value * 10) / 10,
                  targetValue: Math.floor(Math.random() * e.score.value),
                  x: index,
                  index: idx,
                };
                utilityArray.push(point);
              });
            }
            setUtilityData(utilityArray);
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(e, e.stack);
      }
    };
    getData();
  }, [interval, dates, prevMetric]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (prevMetric === 'data-unitsconsumed') {
          const utilityArray = [];
          GetProcessedElectricity(
            prevMetric,
            compareDates,
            interval,
            'hw-processeddata-electricity',
          ).then((data) => {
            if (data !== undefined) {
              data?.forEach((e, idx) => {
                Score2.push(e.score.value);
                point = {
                  value: Math.floor(e.score.value * 10) / 10,
                  index: idx,
                  date: e.key_as_string,
                  isCompare: true,
                  x: idx,
                  y: idx,
                };
                // console.log(point);
                utilityArray.push(point);
              });
            }
            setCompareUtilityData(utilityArray);
          });
        } else {
          GetMetricByDate(
            prevMetric,
            compareDates,
            interval,
            getIndexName(prevMetric),
          )?.then((data) => {
            let index = 0;
            const utilityArray = [];
            if ((prevMetric === 'data-kwh-total' || prevMetric === 'data-totalkwh') && data !== undefined) {
              data?.forEach((e, idx) => {
                if (idx > 0) {
                  point = {
                    name: idx,
                    value: e.score.value
                      ? Math.floor(
                        e.score.value - data[idx - 1].score.value,
                      )
                      : 0,
                    targetValue: Math.floor(
                      Math.random()
                      * (e.score.value - data[idx - 1].score.value),
                    ),
                  };
                  utilityArray.push(point);
                }
              });
            } else if (data !== undefined) {
              data?.forEach((e) => {
                point = {
                  name: index,
                  value: Math.floor(e.score.value * 10) / 10,
                  targetValue: Math.floor(Math.random() * e.score.value),
                  x: index,
                  y: index,
                  index,
                };
                utilityArray.push(point);
                index += 1;
              });
            }
            setCompareUtilityData(utilityArray);
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(e, e.stack);
      }
    };
    getData();
  }, [interval, compareDates, prevMetric]);
  class CustomFlyout extends React.Component {
    render() {
      // @ts-ignore
      const { x, y } = this.props;
      // @ts-ignore
      const { datum } = this.props;
      // console.log(y);
      // console.log(datum.value);
      // console.log(this.props);
      const containerY: number = Math.max(4, y - 114);
      let containerX: number = x < 170 ? x + 75 : x - 115;
      if (compareToggled) {
        containerX = Math.min(660, Math.max(20, x - 165));
        return (
          <svg>
            <defs>
              <filter id="f3" x={-0.5} y={-1} width="200%" height="250%">
                <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
                <feColorMatrix
                  result="matrixOut"
                  in="offOut"
                  type="matrix"
                  values="0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0"
                />
                <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="4" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" result="colored" />
              </filter>
            </defs>
            {/* <line
              x1={x}
              y1={-100}
              x2={x}
              y2={500}
              stroke={Colors.light.hereworksDarkBlue}
              strokeDasharray={10}
            />
            <line
              x1={0}
              y1={y}
              x2={x + 1000}
              y2={y}
              stroke={Colors.light.hereworksDarkBlue}
              strokeDasharray={10}
            /> */}
            <rect
              x={containerX}
              y={containerY}
              width={330}
              height={104}
              stroke={Colors.light.hereworksDarkBlue}
              rx={5}
              fill="white"
              filter="url(#f3)"
            />
            <rect
              x={containerX + 12}
              y={containerY + 8}
              width={133}
              height={88}
              rx={4}
              fill={Colors.light.hereworksWhite100}
            />
            <rect
              x={containerX + 188}
              y={containerY + 8}
              width={133}
              height={88}
              rx={4}
              fill={Colors.light.hereworksYellow50}
            />
            <text
              x={containerX + 16}
              y={containerY + 24}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={'#696D70'}
              stroke={'#696D70'}
            >
              {tooltipDateLabel(graphData, datum.index, true)}
            </text>
            <text
              x={containerX + 32}
              y={containerY + 40}
              fontFamily="sans-serif"
              fontSize={12}
              strokeWidth={0.3}
              fill={'#696D70'}
              stroke={'#696D70'}
            >
              {/* @ts-ignore */}
              {`${selectedUtility}: ${graphData[datum.index]?.value ?? '-'}`}
            </text>
            <circle
              fill={Colors.light.hereworksPurple500}
              r={6}
              cx={containerX + 22}
              cy={containerY + 37}
            />
            <circle
              fill={Colors.light.hereworksDarkBlue}
              r={6}
              cx={containerX + 22}
              cy={containerY + 57}
            />
            <text
              x={containerX + 32}
              y={containerY + 60}
              fontFamily="sans-serif"
              fontSize={12}
              strokeWidth={0.3}
              fill={'#696D70'}
              stroke={'#696D70'}
            >
              {/* @ts-ignore */}
              {`${prevValue}: ${utilityData[datum.index]?.value ?? '-'}`}
            </text>
            {/* <circle
              fill={Colors.light.hereworksDarkBlue200}
              r={6}
              cx={x < 100 ? x - 35 : x - 143}
              cy={y - 37}
            /> */}
            {/* <text
              x={x < 100 ? x - 35 : x - 133}
              y={y - 34}
              fontFamily="sans-serif"
              fontSize={12}
              strokeWidth={0.3}
              fill={'#696D70'}
              stroke={'#696D70'}
            > */}
            {/* @ts-ignore */}
            {/* {`Target Utility: ${utilityData[datum.x]?.targetValue}`} */}
            {/* </text> */}
            <svg width={16} height={16} fill="none" x={containerX + 157} y={containerY + 47}>
              <path
                d="M9 12H2m7 0-3 3m3-3L6 9M7 5h7M7 5l3 3M7 5l3-3"
                stroke="#2173C4"
                strokeWidth={1.5}
                strokeLinecap="round"
              />
            </svg>
            <text
              x={containerX + 192}
              y={containerY + 24}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={'#696D70'}
              stroke={'#696D70'}
            >
              {/* @ts-ignore */}
              {tooltipDateLabel(compareGraphData, datum.index, true)}
            </text>
            <text
              x={containerX + 212}
              y={containerY + 40}
              fontFamily="sans-serif"
              fontSize={12}
              strokeWidth={0.3}
              fill={'#696D70'}
              stroke={'#696D70'}
            >
              {/* @ts-ignore */}
              {`${selectedUtility}: ${compareGraphData[datum.index]?.value ?? '-'}`}
            </text>
            <circle fill={Colors.light.hereworksYellow800} r={6}
              cx={containerX + 202} cy={containerY + 37} />
            <circle fill={Colors.light.hereworksYellow500}
              r={6} cx={containerX + 202} cy={containerY + 57} />
            <text
              x={containerX + 212}
              y={containerY + 60}
              fontFamily="sans-serif"
              fontSize={12}
              strokeWidth={0.3}
              fill={'#696D70'}
              stroke={'#696D70'}
            >
              {/* @ts-ignore */}
              {`${prevValue}: ${compareUtilityData[datum.index]?.value ?? '-'}`}
            </text>
            {/* <circle fill={Colors.light.hereworksDarkBlue200}
             r={6} cx={x + 37} cy={y - 37} /> */}
            {/* <text
              x={x + 47}
              y={y - 34}
              fontFamily="sans-serif"
              fontSize={12}
              strokeWidth={0.3}
              fill={'#696D70'}
              stroke={'#696D70'}
            > */}
            {/* @ts-ignore */}
            {/* {`Target Utility: ${compareUtilityData[datum.x]?.targetValue}`} */}
            {/* </text> */}
          </svg>
        );
      }
      return (
        <svg>
          <defs>
            <filter id="f3" x={-0.5} y={-1} width="200%" height="250%">
              <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0"
              />
              <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="4" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" result="colored" />
            </filter>
          </defs>
          {/* todo: ask about lines -> */}
          {/* <line
            x1={x}
            y1={-100}
            x2={x}
            y2={500}
            stroke={Colors.light.hereworksDarkBlue}
            strokeDasharray={10}
          />
          <line
            x1={0}
            y1={y}
            x2={x + 1000}
            y2={y}
            stroke={Colors.light.hereworksDarkBlue}
            strokeDasharray={10}
          /> */}
          <rect
            x={containerX - 60}
            y={containerY}
            width={157}
            height={104}
            stroke={Colors.light.hereworksDarkBlue}
            rx={5}
            fill="white"
            filter="url(#f3)"
          />
          <text
            x={containerX - 55}
            y={containerY + 29}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={'#696D70'}
            stroke={'#696D70'}
          >
            {tooltipDateLabel(graphData, datum.index, true)}
          </text>
          <text
            x={containerX - 35}
            y={containerY + 45}
            fontFamily="sans-serif"
            fontSize={12}
            strokeWidth={0.3}
            fill={'#696D70'}
            stroke={'#696D70'}
          >
            {/* @ts-ignore */}
            {`${selectedUtility}: ${graphData[datum.index]?.value ?? '-'}`}
          </text>
          <circle
            fill={Colors.light.hereworksPurple500}
            r={6}
            cx={containerX - 45}
            cy={containerY + 42}
          />
          <circle
            fill={Colors.light.hereworksDarkBlue}
            r={6}
            cx={containerX - 45}
            cy={containerY + 62}
          />
          <text
            x={containerX - 35}
            y={containerY + 65}
            fontFamily="sans-serif"
            fontSize={12}
            strokeWidth={0.3}
            fill={'#696D70'}
            stroke={'#696D70'}
          >
            {/* @ts-ignore */}
            {`${prevValue}: ${utilityData[datum.index]?.value ?? '-'}`}
          </text>
          {/* <circle
            fill={Colors.light.hereworksDarkBlue200}
            r={6}
            cx={x < 100 ? x - 35 : x - 63}
            cy={y - 42}
          /> */}
          {/* <text
            x={x < 100 ? x - 35 : x - 53}
            y={y - 39}
            fontFamily="sans-serif"
            fontSize={12}
            strokeWidth={0.3}
            fill={'#696D70'}
            stroke={'#696D70'}
          > */}
          {/* @ts-ignore */}
          {/* {`Target Utility: ${utilityData[datum.x]?.targetValue}`} */}
          {/* </text> */}
        </svg>
      );
    }
  }
  const chartTheme = {
    axis: {
      stroke: 'none',
      style: {
        stroke: '#696D70',
        tickLabels: {
          fill: '#696D70',
        },
        grid: { stroke: 'none' },
      },
    },
  };
  return (
    <View>
      <VictoryChart
        singleQuadrantDomainPadding={{ x: false }}
        width={1000}
        height={425}
        theme={chartTheme}
        domainPadding={{ x: [20, 20], y: [0, 0] }}
        padding={{
          top: 10,
          left: 80,
          right: 100,
          bottom: 60,
        }}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => datum}
            labelComponent={<CustomFlyout />}
          />
        }
      >
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          offsetX={60}
          tickCount={9}
          fixLabelOverlap={true}
          style={{
            axis: { stroke: 'none' },
            tickLabels: {
              fontSize: 14,
              marginRight: 15,
            },
          }}
          tickLabelComponent={
            <VictoryLabel dx={10}
              style={{
                fill: Colors.light.hereworksBlack400,
                fontSize: 14,
                strokeWidth: 0,
                fontFamily: processFontFamily('Poppins_400Regular'),
                textAnchor: 'end',
              }} />}
          label={selectedUtility}
          axisLabelComponent={
            <VictoryLabel
              // dx={-430}
              labelPlacement="parallel"
              dy={-5}
              style={{
                fill: Colors.light.hereworksBlack200,
                fontSize: 14,
                strokeWidth: 0,
                fontFamily: processFontFamily('Poppins_400Regular'),
              }}
            />
          }
        />
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          offsetX={1000}
          tickCount={9}
          fixLabelOverlap={true}
          style={{
            axis: { stroke: 'none' },
            tickLabels: {
              fontSize: 14,
              marginRight: 15,
            },
            grid: { stroke: Colors.light.hereworksWhite500, strokeWidth: 1, pointerEvents: 'none' },
          }}
          tickLabelComponent={
            <VictoryLabel
              style={{
                fill: Colors.light.hereworksBlack400,
                fontSize: 14,
                strokeWidth: 0,
                fontFamily: processFontFamily('Poppins_400Regular'),
                textAnchor: 'end',
              }}
              dx={-60}
            />
          }
          label={prevValue}
          axisLabelComponent={<VictoryLabel dy={-5} dx={100}
            style={{
              fill: Colors.light.hereworksBlack200,
              fontSize: 14,
              strokeWidth: 0,
              fontFamily: processFontFamily('Poppins_400Regular'),
            }} />} />
        <VictoryAxis
          name="axis1"
          tickValues={tickVals}
          fixLabelOverlap={true}
          tickFormat={(x) => axisDateLabel(graphData, x)}
          tickCount={7}
          style={{
            axis: { stroke: 'none' },
          }}
          axisLabelComponent={
            <VictoryLabel
              dx={-430}
              dy={-5}
              style={{
                stroke: Colors.light.hereworksBlack400,
                fontSize: 14,
                strokeWidth: 0,
                fontFamily: processFontFamily('Poppins_400Regular'),
              }}
            />
          }
          tickLabelComponent={
            <VictoryLabel
              dy={18}
              style={{
                fontSize: 14,
                strokeWidth: 0,
                stroke: Colors.light.hereworksBlack400,
                fontFamily: processFontFamily('Poppins_400Regular'),
              }}
            />
          }
        />
        {!compareToggled && (
          <VictoryLine
            style={{
              data: {
                // eslint-disable-next-line max-len
                stroke: ({ active }) => (active ? Colors.light.hereworksDarkBlue : Colors.light.hereworksDarkBlue300),
                strokeWidth: 2,
              },
            }}
            x={'index'}
            y={'value'}
            data={utilityData}
          />
        )}
        {!compareToggled && (
          <VictoryScatter
            x="index"
            y="value"
            data={utilityData}
            size={({ active }) => (active ? 5 : graphData.length > 10 ? 1.5 : 3)}
            style={{
              data: { fill: Colors.light.hereworksDarkBlue },
            }}
          />
        )}
        {compareToggled && (
          <VictoryGroup standalone={true}>
            <VictoryLine
              style={{
                data: {
                  // eslint-disable-next-line max-len
                  stroke: ({ active }) => (active ? Colors.light.hereworksDarkBlue : Colors.light.hereworksDarkBlue300),
                  strokeWidth: 2,
                },
              }}
              x={'index'}
              y={'value'}
              data={utilityData}
            />
            <VictoryLine
              style={{
                data: {
                  stroke: Colors.light.hereworksYellow500,
                  strokeWidth: 2,
                },
              }}
              x={'index'}
              y={'value'}
              data={compareUtilityData}
            />
          </VictoryGroup>
        )}
        <VictoryLine
          x="index"
          y="value"
          data={graphData}
          interpolation="linear"
          style={{
            data: { stroke: Colors.light.hereworksPurple500, strokeWidth: 2 },
          }}
        />
        <VictoryScatter
          name="mainScatter"
          x="index"
          y="value"
          data={graphData}
          size={({ active }) => (active ? 5 : graphData.length > 10 ? 1.5 : 3)}
          style={{
            data: { fill: Colors.light.hereworksPurple500 },
          }}
        />
        {compareToggled && (
          <VictoryLine
            x="index"
            y="value"
            data={compareGraphData}
            interpolation="linear"
            style={{
              data: { stroke: Colors.light.hereworksYellow800, strokeWidth: 2 },
            }}
          />
        )}
        {compareToggled && (
          <VictoryScatter
            name="compareScatter"
            data={compareGraphData}
            x="index"
            y="value"
            size={({ active }) => (active ? 5 : graphData.length > 10 ? 1.5 : 3)}
            style={{
              data: { fill: Colors.light.hereworksYellow800 },
            }}
          />
        )}
        {compareToggled && (
          <VictoryScatter
            data={compareUtilityData}
            x="index"
            y="value"
            size={({ active }) => (active ? 5 : graphData.length > 10 ? 1.5 : 3)}
            style={{
              data: { fill: Colors.light.hereworksYellow500 },
            }}
          />
        )}
        {compareToggled && (
          <VictoryScatter
            data={utilityData}
            x="index"
            y="value"
            size={({ active }) => (active ? 5 : graphData.length > 10 ? 1.5 : 3)}
            style={{
              data: { fill: Colors.light.hereworksDarkBlue },
            }}
          />
        )}
        {compareToggled && (
          <VictoryAxis
            name="axis2"
            fixLabelOverlap={true}
            tickValues={tickVals2}
            tickFormat={(x) => axisDateLabel(compareGraphData, x)}
            tickCount={7}
            domainPadding={{ x: [0, 0], y: 20 }}
            style={{
              axis: { stroke: 'none' },
            }}
            axisLabelComponent={
              <VictoryLabel
                dx={-430}
                dy={11}
                style={{
                  fill: Colors.light.hereworksYellow500,
                  fontSize: 14,
                  strokeWidth: 0,
                  fontFamily: processFontFamily('Poppins_400Regular'),
                }}
              />
            }
            tickLabelComponent={
              <VictoryLabel
                dy={33}
                style={{
                  fill: Colors.light.hereworksYellow500,
                  fontSize: 14,
                  strokeWidth: 0,
                  fontFamily: processFontFamily('Poppins_400Regular'),
                }}
              />
            }
          />
        )}
      </VictoryChart>
      {!graphData.length && !utilityData.length
        && (!compareToggled || (!compareGraphData.length && !compareUtilityData.length))
        && (
          <View
            style={{
              position: 'absolute',
              alignSelf: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Text
              style={{
                color: Colors.light.hereworksBlack400,
                fontFamily: 'Roboto_400Regular',
                fontSize: 16,
              }}
            >
              No data available
            </Text>
          </View>
        )}
    </View>
  );
};

export default BarWithLineGraphWeb;
