import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LinkArrow = (props: SvgProps) => (
    <Svg width="6" height="11" viewBox="0 0 6 11" fill="none" {...props}>
        <Path d="M1 9.5L5 5.5L1 1.5" stroke="#0058AD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
);

export default LinkArrow;
