import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

const InternetIcon60 = (props: SvgProps) => (
  <Svg width={60} height={60} fill="none" {...props}>
    <Circle cx={30} cy={30} r={26.5} stroke={props.color ?? '#fff'} strokeWidth={3} />
    <Path
      d="M39.5 30c0 7.593-1.212 14.398-3.118 19.25-.955 2.431-2.055 4.296-3.2 5.529C32.04 56.009 30.964 56.5 30 56.5c-.963 0-2.04-.49-3.183-1.721-1.144-1.233-2.244-3.098-3.199-5.529C21.712 44.398 20.5 37.594 20.5 30s1.212-14.398 3.118-19.25c.955-2.431 2.055-4.296 3.2-5.529C27.96 3.991 29.036 3.5 30 3.5c.963 0 2.04.49 3.183 1.721 1.144 1.233 2.244 3.098 3.199 5.528C38.288 15.602 39.5 22.407 39.5 30ZM4 23h52.5M4 37h52.5"
      stroke={props.color ?? '#fff'}
      strokeWidth={3}
    />
  </Svg>
);

export default InternetIcon60;
