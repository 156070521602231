import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const FilterIconMobile16 = (props: SvgProps) => (
    <Svg width={12} height={12} fill="none" {...props}>
        <Path
            d="M1.91667 0.75V8.91667V0.75ZM1.91667 8.91667C1.68592 8.91667 1.46036 8.98509 1.2685 9.11329C1.07664 9.24148 0.92711 9.42369 0.838808 9.63687C0.750505 9.85005 0.727402 10.0846 0.772418 10.3109C0.817434 10.5372 0.928548 10.7451 1.09171 10.9083C1.25487 11.0715 1.46275 11.1826 1.68906 11.2276C1.91537 11.2726 2.14995 11.2495 2.36313 11.1612C2.57631 11.0729 2.75852 10.9234 2.88671 10.7315C3.01491 10.5396 3.08333 10.3141 3.08333 10.0833C3.08333 9.77391 2.96042 9.47717 2.74163 9.25838C2.52283 9.03958 2.22609 8.91667 1.91667 8.91667ZM6 3.08333V11.25V3.08333ZM6 0.75C5.76926 0.75 5.54369 0.818424 5.35184 0.946619C5.15998 1.07481 5.01044 1.25702 4.92214 1.4702C4.83384 1.68338 4.81073 1.91796 4.85575 2.14427C4.90077 2.37058 5.01188 2.57846 5.17504 2.74162C5.3382 2.90479 5.54608 3.0159 5.7724 3.06092C5.99871 3.10593 6.23328 3.08283 6.44646 2.99453C6.65964 2.90622 6.84185 2.75669 6.97005 2.56483C7.09824 2.37297 7.16667 2.14741 7.16667 1.91667C7.16667 1.60725 7.04375 1.3105 6.82496 1.09171C6.60617 0.872916 6.30942 0.75 6 0.75ZM10.0833 7.16667V11.25V7.16667ZM10.0833 4.83333V0.75V4.83333ZM10.0833 4.83333C9.85259 4.83333 9.62703 4.90176 9.43517 5.02995C9.24331 5.15815 9.09378 5.34036 9.00547 5.55354C8.91717 5.76672 8.89407 6.00129 8.93908 6.22761C8.9841 6.45392 9.09521 6.6618 9.25838 6.82496C9.42154 6.98812 9.62942 7.09923 9.85573 7.14425C10.082 7.18927 10.3166 7.16616 10.5298 7.07786C10.743 6.98956 10.9252 6.84002 11.0534 6.64816C11.1816 6.45631 11.25 6.23074 11.25 6C11.25 5.69058 11.1271 5.39383 10.9083 5.17504C10.6895 4.95625 10.3928 4.83333 10.0833 4.83333Z"
            fill="#0058AD"
        />
        <Path
            d="M1.91667 0.75V8.91667M6 3.08333V11.25M10.0833 7.16667V11.25M10.0833 4.83333V0.75M1.91667 8.91667C1.68592 8.91667 1.46036 8.98509 1.2685 9.11329C1.07664 9.24148 0.92711 9.42369 0.838808 9.63687C0.750505 9.85005 0.727402 10.0846 0.772418 10.3109C0.817434 10.5372 0.928548 10.7451 1.09171 10.9083C1.25487 11.0715 1.46275 11.1826 1.68906 11.2276C1.91537 11.2726 2.14995 11.2495 2.36313 11.1612C2.57631 11.0729 2.75852 10.9234 2.88671 10.7315C3.01491 10.5396 3.08333 10.3141 3.08333 10.0833C3.08333 9.77391 2.96042 9.47717 2.74163 9.25838C2.52283 9.03958 2.22609 8.91667 1.91667 8.91667ZM6 0.75C5.76926 0.75 5.54369 0.818424 5.35184 0.946619C5.15998 1.07481 5.01044 1.25702 4.92214 1.4702C4.83384 1.68338 4.81073 1.91796 4.85575 2.14427C4.90077 2.37058 5.01188 2.57846 5.17504 2.74162C5.3382 2.90479 5.54608 3.0159 5.7724 3.06092C5.99871 3.10593 6.23328 3.08283 6.44646 2.99453C6.65964 2.90622 6.84185 2.75669 6.97005 2.56483C7.09824 2.37297 7.16667 2.14741 7.16667 1.91667C7.16667 1.60725 7.04375 1.3105 6.82496 1.09171C6.60617 0.872916 6.30942 0.75 6 0.75ZM10.0833 4.83333C9.85259 4.83333 9.62703 4.90176 9.43517 5.02995C9.24331 5.15815 9.09378 5.34036 9.00547 5.55354C8.91717 5.76672 8.89407 6.00129 8.93908 6.22761C8.9841 6.45392 9.09521 6.6618 9.25838 6.82496C9.42154 6.98812 9.62942 7.09923 9.85573 7.14425C10.082 7.18927 10.3166 7.16616 10.5298 7.07786C10.743 6.98956 10.9252 6.84002 11.0534 6.64816C11.1816 6.45631 11.25 6.23074 11.25 6C11.25 5.69058 11.1271 5.39383 10.9083 5.17504C10.6895 4.95625 10.3928 4.83333 10.0833 4.83333Z"
            stroke="#0058AD"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default FilterIconMobile16;
