import React, { ReactElement, useState } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import * as Analytics from 'expo-firebase-analytics';
import Colors from '../../constants/Colors';
import DeleteIcon24 from '../24pxIcons/DeleteIcon24';
import EditIcon24 from '../24pxIcons/EditIcon24';
import TemplateIcon from '../40pxIcons/TemplateIcon';
import AlertModal from '../AlertModal';
import AddTemplateModal from './AddTemplateModal';
import deleteTemplate from '../../requests/DeleteTemplate';

export default function TemplateGroupRowWeb(props) {
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertError, setAlertError] = useState<boolean>(true);
  const [alertTitle, setAlertTitle] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string | ReactElement>('');
  const [alertAction, setAlertAction] = useState<() => void>(null);
  const [alertActionTitle, setAlertActionTitle] = useState<string>(null);

  const showAlert = (
    title: string,
    message: string | ReactElement,
    error: boolean = true,
    action: () => void = null,
    actionTitle: string = '',
  ) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertError(error);
    setAlertAction(() => action);
    setAlertActionTitle(actionTitle);
    setLoading(false);
    setAlertVisible(true);
  };

  const attemptDeletion = () => {
    setLoading(true);
    const error = (text?: string) => showAlert(
      'Error',
      text ?? 'Could not delete template. Please try again later.',
    );

    deleteTemplate(props.prototype.id).then((res: any) => {
      if (res && !res.isError) {
        props.deleteAction(props.prototype.id);
        showAlert(
          'Template successfully deleted',
          <>
            {'Template '}
            <Text style={styles.boldText}>{props.prototype.name}</Text>
            {' successfully deleted'}
          </>,
          false,
        );
      } else error(res?.errorText);
    })
      .catch(error);
  };

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'AssetManagement',
      name: title,
    });
  };

  return (
    <>
      <TouchableOpacity
        style={styles.qrCodeView}
        onPress={() => {
          analytics(`Prototype: ${props.prototype.id}`);
          props.setLinkAssetsView();
          props.setSelectedTitle();
          props.setSelectedId();
        }}
      >
        <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}>
          <TemplateIcon />
          <Text style={styles.qrCodeTitle}>{props.prototype.name}</Text>
        </View>
        {/* <View
          style={{
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: Colors.light.hereworksYellow100,
              borderColor: Colors.light.hereworksYellow500,
              borderWidth: 1,
              borderRadius: 4,
            }}
            onPress={() => {
              Analytics.logEvent('button_press', {
                screen: 'AssetMgmt',
                name: 'Tag',
              });
            }}
          >
            <Text
              style={{
                color: Colors.light.hereworksYellow900,
                fontFamily: 'Roboto_500Medium',
                fontSize: 12,
                lineHeight: 14,
                marginHorizontal: 8,
                marginVertical: 5,
              }}
            >
              TAG
            </Text>
          </TouchableOpacity>
        </View> */}
        <View style={{ flexDirection: 'row', flex: 1 }}></View>
        <TouchableOpacity
          style={{
            position: 'absolute',
            right: 50,
          }}
          onPress={() => {
            analytics('Edit');
            setEditModalVisible(true);
          }}
        >
          <EditIcon24 />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            position: 'absolute',
            right: 20,
          }}
          onPress={() => {
            analytics('Delete');
            showAlert(
              'Delete template',
              <>
                {'Are you sure you want to delete the template '}
                <Text style={styles.boldText}>{props.prototype.name}</Text>
              </>,
              true,
              attemptDeletion,
              'Delete',
            );
          }}
        >
          <DeleteIcon24 />
        </TouchableOpacity>
      </TouchableOpacity>
      <AddTemplateModal
        userGroups={props.userGroups}
        templateGroups={props.templateGroups}
        isVisible={editModalVisible}
        hide={() => setEditModalVisible(false)}
        templateToUpdate={props.prototype}
        update={props.update}
      />
      <AlertModal
        modalVisible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        error={alertError}
        action={alertAction}
        actionTitle={alertActionTitle}
        {...{ loading }}
        setModalVisible={setAlertVisible}
      />
    </>
  );
}
const styles = StyleSheet.create({
  qrCodeTitle: {
    color: Colors.light.hereworksPrimaryGrey,
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 16,
    marginLeft: 12,
  },
  qrCodeView: {
    paddingHorizontal: 18,
    paddingVertical: 19,
    borderRadius: 10,
    marginVertical: 2,
    marginHorizontal: 20,
    backgroundColor: Colors.light.hereworksWhite100,
    alignItems: 'center',
    flexDirection: 'row',
  },
  boldText: {
    fontFamily: 'Roboto_700Bold',
  },
});
