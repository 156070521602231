import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ChairIcon = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path d="M15.523 14.875H4.477c-.678 0-1.227-.55-1.227-1.227V11.5h13.5v2.148c0 .678-.55 1.227-1.227 1.227ZM13.273 9.25H6.727C6.05 9.25 5.5 8.7 5.5 8.023V2.227C5.5 1.55 6.05 1 6.727 1h6.546c.678 0 1.227.55 1.227 1.227v5.796c0 .678-.55 1.227-1.227 1.227Z" />
    <Path
      d="M6.625 19H10m0 0h3.375M10 19v-4.125m0 0h5.523c.678 0 1.227-.55 1.227-1.227V11.5M10 14.875H4.477c-.678 0-1.227-.55-1.227-1.227V11.5m0 0h13.5m-13.5 0v-1.023c0-.678-.55-1.227-1.227-1.227H1m15.75 2.25v-1.023c0-.678.55-1.227 1.227-1.227H19m-12.273 0h6.546c.678 0 1.227-.55 1.227-1.227V2.227C14.5 1.55 13.95 1 13.273 1H6.727C6.05 1 5.5 1.55 5.5 2.227v5.796c0 .678.55 1.227 1.227 1.227Z"
      stroke={props.color ?? '#D6006D'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ChairIcon;
