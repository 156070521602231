import React, { useState } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Moment from 'moment';
import * as Analytics from 'expo-firebase-analytics';
import { ScrollView } from 'react-native-gesture-handler';
import Colors from '../constants/Colors';
import CalendarIcon from './CalendarIcon';
import CompareArrowsIcon from './CompareArrowsIcon';
import CalendarPicker from '../CalendarPicker';
import NewSwitch from './NewSwitch';

function customDayHeaderStylesCallback() {
  return {
    textStyle: {
      color: Colors.light.hereworksPrimaryGrey700,
      fontSize: 10,
      fontFamily: 'Roboto_500Medium',
    },
  };
}

interface Props {
  // eslint-disable-next-line no-unused-vars
  onChange: (start: any, end: any) => void;
  // eslint-disable-next-line no-unused-vars
  onCompareChange?: (start: any, end: any) => void;
  // eslint-disable-next-line no-unused-vars
  onConfirm: () => void;
  // eslint-disable-next-line no-unused-vars
  onOpen: (open: any) => void;
  startDate: any;
  compareStartDate?: any;
  endDate: any;
  compareEndDate?: any;
  renderDesktopView?: boolean;
  compareEnabled?: boolean;
  selectedRange?: number;
  // eslint-disable-next-line no-unused-vars
  setSelectedRange?: (range: any) => void;
}

export default function DateComponent(props: Props) {
  const [selectedRangeIndex, setSelectedRangeIndex] = useState(props.selectedRange ?? 1);
  const [daySelected, setDaySelected] = useState(selectedRangeIndex === 0);
  const [weekSelected, setWeekSelected] = useState(selectedRangeIndex === 1);
  const [monthSelected, setMonthSelected] = useState(selectedRangeIndex === 2);
  const [threeMonthsSelected, setThreeMonthsSelected] = useState(selectedRangeIndex === 3);
  const [sixMonthsSelected, setSixMonthsSelected] = useState(selectedRangeIndex === 4);
  const [yearSelected, setYearSelected] = useState(selectedRangeIndex === 5);
  const [customSelected, setCustomSelected] = useState(selectedRangeIndex === 6);

  const [dateSelected, setDateSelected] = useState(false);
  const [compareToggled, setCompareToggled] = useState(false);
  const [selectingCompare, setSelectingCompare] = useState(false);
  const [compareApplied, setCompareApplied] = useState(false);

  const [startDateToApply, setStartDateToApply] = useState(props.startDate);
  const [endDateToApply, setEndDateToApply] = useState(props.endDate);

  const [compareStartDateToApply, setCompareStartDateToApply] = useState(
    props.compareStartDate ?? new Date(),
  );
  const [compareEndDateToApply, setCompareEndDateToApply] = useState(
    props.compareEndDate ?? new Date(),
  );

  const navigation = useNavigation();
  navigation.addListener('blur', () => setDateSelected(false));

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'DateComponent',
      name: title,
    });
  };

  const getRangeBackground = (selected) => {
    if (compareToggled && selectingCompare) {
      return selected ? Colors.light.hereworksYellow500 : '#fff';
    }

    return selected ? Colors.light.hereworksBlue500 : '#fff';
  };

  const getRangeBorder = (selected) => {
    if (compareToggled && selectingCompare) {
      return selected ? Colors.light.hereworksYellow500 : Colors.light.hereworksBlack300;
    }

    return selected ? Colors.light.hereworksBlue500 : Colors.light.hereworksPaleBlue900;
  };

  const getRangeTextColor = (selected) => {
    if (compareToggled && selectingCompare) {
      return selected ? Colors.light.hereworksBlack600 : Colors.light.hereworksBlack300;
    }

    return selected ? '#fff' : Colors.light.hereworksBlue400;
  };

  const rangeText = (selected, title) => (
    <Text
      style={[
        styles.dateRangeText,
        {
          color: getRangeTextColor(selected),
        },
      ]}
    >
      {title}
    </Text>
  );

  const renderRangePickers = () => (
    <View
      style={{
        flexDirection: props.renderDesktopView ? 'column' : 'row',
        borderRadius: 8,
        marginHorizontal: 24,
        justifyContent: 'space-between',
      }}
    >
      <TouchableOpacity
        style={[
          styles.dateRangeButton,
          {
            backgroundColor: getRangeBackground(daySelected),
            borderColor: getRangeBorder(daySelected),
            width: props.renderDesktopView ? 124 : undefined,
            marginRight: props.renderDesktopView ? undefined : 8,
          },
        ]}
        onPress={() => {
          if (!daySelected) {
            setWeekSelected(false);
            setMonthSelected(false);
            setThreeMonthsSelected(false);
            setSixMonthsSelected(false);
            setYearSelected(false);
            setCustomSelected(false);
            setDaySelected(true);
            setStartDateToApply(Moment.utc().startOf('day').toDate());
            setEndDateToApply(Moment.utc().endOf('day'));
            if (compareToggled) {
              setCompareStartDateToApply(Moment.utc().subtract(1, 'day').startOf('day'));
              setCompareEndDateToApply(Moment.utc().subtract(1, 'day').endOf('day'));
            }
          }
          analytics('1D');
        }}
      >
        {rangeText(daySelected, 'DAY')}
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.dateRangeButton,
          {
            backgroundColor: getRangeBackground(weekSelected),
            borderColor: getRangeBorder(weekSelected),
            width: props.renderDesktopView ? 124 : undefined,
            marginRight: props.renderDesktopView ? undefined : 8,
          },
        ]}
        onPress={() => {
          if (!weekSelected) {
            setDaySelected(false);
            setMonthSelected(false);
            setThreeMonthsSelected(false);
            setSixMonthsSelected(false);
            setYearSelected(false);
            setCustomSelected(false);
            setWeekSelected(true);
            setStartDateToApply(Moment.utc().subtract(6, 'days').startOf('day').toDate());
            setEndDateToApply(Moment.utc().endOf('day'));
            if (compareToggled) {
              setCompareStartDateToApply(Moment.utc().subtract(6, 'days').startOf('day'));
              setCompareEndDateToApply(Moment.utc().endOf('day'));
            }
          }
          analytics('1W');
        }}
      >
        {rangeText(weekSelected, 'LAST WEEK')}
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.dateRangeButton,
          {
            backgroundColor: getRangeBackground(monthSelected),
            borderColor: getRangeBorder(monthSelected),
            width: props.renderDesktopView ? 124 : undefined,
            marginRight: props.renderDesktopView ? undefined : 8,
          },
        ]}
        onPress={() => {
          if (!monthSelected) {
            setDaySelected(false);
            setWeekSelected(false);
            setThreeMonthsSelected(false);
            setSixMonthsSelected(false);
            setYearSelected(false);
            setCustomSelected(false);
            setMonthSelected(true);
            setStartDateToApply(Moment.utc().subtract(1, 'month').startOf('day').toDate());
            setEndDateToApply(Moment.utc().endOf('day'));
            if (compareToggled) {
              setCompareStartDateToApply(Moment.utc().subtract(1, 'month').startOf('day'));
              setCompareEndDateToApply(Moment.utc().endOf('day'));
            }
          }
          analytics('1M');
        }}
      >
        {rangeText(monthSelected, 'LAST MONTH')}
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.dateRangeButton,
          {
            backgroundColor: getRangeBackground(threeMonthsSelected),
            borderColor: getRangeBorder(threeMonthsSelected),
            width: props.renderDesktopView ? 124 : undefined,
            marginRight: props.renderDesktopView ? undefined : 8,
          },
        ]}
        onPress={() => {
          if (!threeMonthsSelected) {
            setDaySelected(false);
            setWeekSelected(false);
            setMonthSelected(false);
            setSixMonthsSelected(false);
            setYearSelected(false);
            setCustomSelected(false);
            setThreeMonthsSelected(true);
            setStartDateToApply(Moment.utc().subtract(3, 'months').startOf('day').toDate());
            setEndDateToApply(Moment.utc().endOf('day'));
            if (compareToggled) {
              setCompareStartDateToApply(Moment.utc().subtract(3, 'months').startOf('day'));
              setCompareEndDateToApply(Moment.utc().endOf('day'));
            }
          }
          analytics('3M');
        }}
      >
        {rangeText(threeMonthsSelected, 'LAST 3 MONTHS')}
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.dateRangeButton,
          {
            backgroundColor: getRangeBackground(sixMonthsSelected),
            borderColor: getRangeBorder(sixMonthsSelected),
            width: props.renderDesktopView ? 124 : undefined,
            marginRight: props.renderDesktopView ? undefined : 8,
          },
        ]}
        onPress={() => {
          if (!sixMonthsSelected) {
            setDaySelected(false);
            setWeekSelected(false);
            setMonthSelected(false);
            setThreeMonthsSelected(false);
            setYearSelected(false);
            setCustomSelected(false);
            setSixMonthsSelected(true);
            setStartDateToApply(Moment.utc().subtract(6, 'months').startOf('day').toDate());
            setEndDateToApply(Moment.utc().endOf('day'));
            if (compareToggled) {
              setCompareStartDateToApply(Moment.utc().subtract(6, 'months').startOf('day'));
              setCompareEndDateToApply(Moment.utc().endOf('day'));
            }
          }
          analytics('6M');
        }}
      >
        {rangeText(sixMonthsSelected, 'LAST 6 MONTHS')}
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.dateRangeButton,
          {
            backgroundColor: getRangeBackground(yearSelected),
            borderColor: getRangeBorder(yearSelected),
            width: props.renderDesktopView ? 124 : undefined,
            marginRight: props.renderDesktopView ? undefined : 8,
          },
        ]}
        onPress={() => {
          if (!yearSelected) {
            setDaySelected(false);
            setWeekSelected(false);
            setMonthSelected(false);
            setThreeMonthsSelected(false);
            setSixMonthsSelected(false);
            setCustomSelected(false);
            setYearSelected(true);
            setStartDateToApply(Moment.utc().subtract(1, 'year').startOf('day').toDate());
            setEndDateToApply(Moment.utc().endOf('day'));
            if (compareToggled) {
              setCompareStartDateToApply(Moment.utc().subtract(1, 'year').startOf('day'));
              setCompareEndDateToApply(Moment.utc().endOf('day'));
            }
          }
          analytics('1Y');
        }}
      >
        {rangeText(yearSelected, 'LAST YEAR')}
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.dateRangeButton,
          {
            backgroundColor: getRangeBackground(customSelected),
            borderColor: getRangeBorder(customSelected),
            width: props.renderDesktopView ? 124 : undefined,
            marginRight: props.renderDesktopView ? undefined : 8,
          },
        ]}
        onPress={() => {
          if (!customSelected) {
            setDaySelected(false);
            setWeekSelected(false);
            setMonthSelected(false);
            setThreeMonthsSelected(false);
            setSixMonthsSelected(false);
            setYearSelected(false);
            setCustomSelected(true);
          }
          analytics('Custom');
        }}
      >
        {rangeText(customSelected, 'CUSTOM')}
      </TouchableOpacity>
    </View>
  );

  const renderCalendar = () => (
    <CalendarPicker
      restrictMonthNavigation
      onDateChange={(change, type) => {
        let newStart = null;
        let newEnd = null;
        if (sixMonthsSelected) {
          if (type === 'START_DATE') {
            newStart = Moment.utc(change).subtract(6, 'months').startOf('day').toISOString();
            newEnd = change.endOf('day').toISOString();
          }
        } else if (threeMonthsSelected) {
          if (type === 'START_DATE') {
            newStart = Moment.utc(change).subtract(3, 'months').startOf('day').toISOString();
            newEnd = change.endOf('day').toISOString();
          }
        } else if (monthSelected) {
          if (type === 'START_DATE') {
            newStart = Moment.utc(change).subtract(1, 'month').startOf('day').toISOString();
            newEnd = change.endOf('day').toISOString();
          }
        } else if (weekSelected) {
          if (type === 'START_DATE') {
            newStart = Moment.utc(change).subtract(6, 'days').startOf('day').toISOString();
            newEnd = change.endOf('day').toISOString();
          }
        } else if (yearSelected) {
          if (type === 'START_DATE') {
            newStart = Moment.utc(change).subtract(365, 'days').startOf('day').toISOString();
            newEnd = change.endOf('day').toISOString();
          }
        } else if (daySelected) {
          newStart = Moment.utc(change).startOf('day').toISOString();
          newEnd = change?.endOf('day').toISOString();
        } else if (change && type === 'END_DATE') {
          newStart = selectingCompare ? compareStartDateToApply : startDateToApply;
          newEnd = change.endOf('day').toISOString();
        } else if (change) {
          newStart = Moment.utc(change).startOf('day').toISOString();
          newEnd = null;
        }

        if (selectingCompare && change) {
          setCompareStartDateToApply(newStart);
          setCompareEndDateToApply(newEnd);
        } else if (change) {
          setStartDateToApply(newStart);
          setEndDateToApply(newEnd);
        }
      }}
      allowRangeSelection={!daySelected}
      textStyle={{
        color: Colors.light.hereworksDarkBlue900,
        fontFamily: 'Roboto_500Medium',
        fontSize: 14,
      }}
      width={280}
      height={319}
      selectedStartDate={selectingCompare ? compareStartDateToApply : startDateToApply}
      selectedEndDate={selectingCompare ? compareEndDateToApply : endDateToApply}
      selectedDayStyle={{
        backgroundColor: selectingCompare
          ? Colors.light.hereworksYellow500
          : Colors.light.hereworksLightBlue,
      }}
      selectedRangeStartStyle={{
        backgroundColor: selectingCompare
          ? Colors.light.hereworksYellow500
          : Colors.light.hereworksLightBlue,
      }}
      selectedRangeEndStyle={{
        backgroundColor: selectingCompare
          ? Colors.light.hereworksYellow500
          : Colors.light.hereworksLightBlue,
      }}
      selectedDayTextStyle={{
        color: '#000',
        fontFamily: 'Roboto_500Medium',
        fontSize: 14,
      }}
      selectedRangeStartTextStyle={{
        color: selectingCompare ? '#000' : '#fff',
        fontFamily: 'Roboto_500Medium',
        fontSize: 14,
      }}
      selectedRangeEndTextStyle={{
        color: selectingCompare ? '#000' : '#fff',
        fontFamily: 'Roboto_500Medium',
        fontSize: 14,
      }}
      selectedRangeStyle={{
        backgroundColor: selectingCompare
          ? Colors.light.hereworksYellow700
          : Colors.light.hereworksBlue50,
      }}
      customDayHeaderStyles={customDayHeaderStylesCallback}
      maxDate={Date.now()}
      minDate={Moment('2021-01-01').valueOf()}
      initialDate={props.endDate ?? undefined}
    />
  );

  const renderApplyCancel = () => {
    const applyDisabled = (!startDateToApply || !endDateToApply) || (compareToggled
      && (!compareStartDateToApply || !compareEndDateToApply));
    return <View
      style={{
        flexDirection: 'row',
      }}
    >
      <TouchableOpacity
        style={{
          backgroundColor: Colors.light.hereworksLightBlue,
          borderRadius: 4,
          marginRight: 12,
          opacity: applyDisabled ? 0.5 : undefined,
        }}
        disabled={applyDisabled}
        onPress={() => {
          let rangeIndex = 1;
          if (daySelected) {
            rangeIndex = 0;
          } else if (weekSelected) {
            rangeIndex = 1;
          } else if (monthSelected) {
            rangeIndex = 2;
          } else if (threeMonthsSelected) {
            rangeIndex = 3;
          } else if (sixMonthsSelected) {
            rangeIndex = 4;
          } else if (yearSelected) {
            rangeIndex = 5;
          } else if (customSelected) {
            rangeIndex = 6;
          }
          setSelectedRangeIndex(rangeIndex);
          if (props.setSelectedRange !== undefined) {
            props.setSelectedRange(rangeIndex);
          }
          props.onChange(startDateToApply, endDateToApply);
          if (compareToggled) props.onCompareChange(compareStartDateToApply, compareEndDateToApply);
          else props.onCompareChange(null, null);
          setCompareApplied(compareToggled);
          props.onConfirm();
          props.onOpen(!dateSelected);
          setDateSelected(!dateSelected);
          analytics('Apply');
        }}
      >
        <Text
          style={{
            color: '#fff',
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          Apply
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          backgroundColor: '#fff',
          borderRadius: 4,
          borderWidth: 1,
          borderColor: Colors.light.hereworksLightBlue,
        }}
        onPress={() => {
          props.onOpen(!dateSelected);
          setDateSelected(!dateSelected);
          setDaySelected(selectedRangeIndex === 0);
          setWeekSelected(selectedRangeIndex === 1);
          setMonthSelected(selectedRangeIndex === 2);
          setThreeMonthsSelected(selectedRangeIndex === 3);
          setSixMonthsSelected(selectedRangeIndex === 4);
          setYearSelected(selectedRangeIndex === 5);
          setCustomSelected(selectedRangeIndex === 6);
          setStartDateToApply(props.startDate);
          setEndDateToApply(props.endDate);
          setCompareStartDateToApply(props.compareStartDate);
          setCompareEndDateToApply(props.compareEndDate);
          setCompareToggled(compareApplied);
          setSelectingCompare(compareApplied);
          analytics('Cancel');
        }}
      >
        <Text
          style={{
            color: Colors.light.hereworksLightBlue,
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          Cancel
        </Text>
      </TouchableOpacity>
    </View>;
  };

  const renderFirstDateText = (color?: string) => {
    if (daySelected) {
      return (
        <Text style={[styles.dateText, { color: color ?? '#000' }]}>
          {Moment(startDateToApply ?? new Date()).format('D MMM YYYY')}
        </Text>
      );
    }

    if (!endDateToApply || !startDateToApply) {
      return <Text style={[styles.dateText, { color: color ?? '#000' }]}>Choose date</Text>;
    }

    return (
      <Text style={[styles.dateText, { color: color ?? '#000' }]}>
        {Moment(startDateToApply ?? new Date()).format('D MMM YYYY')} -{' '}
        {endDateToApply && Moment(endDateToApply).format('D MMM YYYY')}
        {!endDateToApply && Moment(new Date()).format('D MMM YYYY')}
      </Text>
    );
  };

  const renderCompareDateText = (color?: string) => {
    if (daySelected) {
      return (
        <Text style={[styles.dateText, { color: color ?? '#000' }]}>
          {Moment(compareStartDateToApply ?? new Date()).format('D MMM YYYY')}
        </Text>
      );
    }

    if (!compareEndDateToApply || !compareStartDateToApply) {
      return <Text style={[styles.dateText, { color: color ?? '#000' }]}>Choose date</Text>;
    }

    return (
      <Text style={[styles.dateText, { color: color ?? '#000' }]}>
        {Moment(compareStartDateToApply ?? new Date()).format('D MMM YYYY')} -{' '}
        {Moment(compareEndDateToApply ?? new Date()).format('D MMM YYYY')}
      </Text>
    );
  };

  const renderCalendarIcon = () => (
    <TouchableOpacity
      style={{
        backgroundColor: dateSelected ? Colors.light.hereworksLightBlue : '#fff',
        width: 40,
        height: 40,
        borderWidth: 1.5,
        borderColor: dateSelected
          ? Colors.light.hereworksLightBlue
          : Colors.light.hereworksPaleBlue900,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onPress={() => {
        props.onOpen(!dateSelected);
        setDateSelected(!dateSelected);
        analytics('Calendar Icon');
      }}
    >
      <CalendarIcon color={dateSelected ? '#fff' : '#003A70'} />
    </TouchableOpacity>
  );

  const renderCompareIcon = () => (
    <TouchableOpacity
      style={{
        backgroundColor: '#fff',
        width: 40,
        height: 40,
        borderWidth: 1.5,
        borderColor: dateSelected
          ? Colors.light.hereworksLightBlue
          : Colors.light.hereworksPaleBlue900,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onPress={() => {
        props.onOpen(!dateSelected);
        setDateSelected(!dateSelected);
        analytics('Compare');
      }}
    >
      <CompareArrowsIcon color={dateSelected ? '#fff' : Colors.light.hereworksLightBlue} />
    </TouchableOpacity>
  );

  const renderCompareToggleButton = () => (
    <>
      {props.compareEnabled && (
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            marginLeft: 24,
            marginTop: 14,
            marginBottom: props.renderDesktopView ? 0 : 18,
          }}
          onPress={() => {
            if (compareToggled) {
              setCompareStartDateToApply(null);
              setCompareEndDateToApply(null);
              setSelectingCompare(false);
            } else {
              setSelectingCompare(true);
            }
            setCompareToggled(!compareToggled);
            analytics(compareToggled ? 'Close comparison' : 'Compare dates');
          }}
        >
          <Text
            style={{
              color: Colors.light.hereworksBlack400,
              fontFamily: 'Roboto_400Regular',
              fontSize: 11,
            }}
          >
            {compareToggled ? 'COMPARE ON' : 'COMPARE OFF'}
          </Text>
          <NewSwitch
            isToggled={compareToggled}
            color={
              selectingCompare ? Colors.light.hereworksYellow500 : Colors.light.hereworksBlue500
            }
            style={{ position: 'absolute', right: 17 }}
          />
        </TouchableOpacity>
      )}
    </>
  );

  const renderFirstDate = () => (
    <View style={{ marginBottom: 16, marginHorizontal: 24, marginTop: 24 }}>
      {props.compareEnabled && (
        <Text
          style={{
            color: Colors.light.hereworksPrimaryGrey700,
            fontFamily: 'Roboto_500Medium',
            fontSize: 12,
            marginBottom: 4,
          }}
        >
          Date 1
        </Text>
      )}
      <TouchableOpacity
        style={{
          height: 40,
          borderWidth: 1.5,
          borderColor: selectingCompare
            ? Colors.light.hereworksLightBlue700
            : Colors.light.hereworksLightBlue,
          borderRadius: 10,
          marginTop: 4,
        }}
        onPress={() => {
          if (selectingCompare) {
            setSelectingCompare(false);
          }
          analytics('First date');
        }}
      >
        {renderFirstDateText()}
      </TouchableOpacity>
    </View>
  );

  const renderCompareDate = () => (
    <>
      {compareToggled && (
        <View style={{ marginBottom: 12, marginHorizontal: 24 }}>
          <Text
            style={{
              color: Colors.light.hereworksPrimaryGrey700,
              fontFamily: 'Roboto_500Medium',
              fontSize: 12,
              marginBottom: 4,
            }}
          >
            Date 2
          </Text>
          <TouchableOpacity
            style={{
              height: 40,
              borderWidth: 1.5,
              borderColor: selectingCompare
                ? Colors.light.hereworksYellow500
                : Colors.light.hereworksYellow700,
              borderRadius: 10,
              marginTop: 4,
            }}
            onPress={() => {
              if (!selectingCompare) {
                setSelectingCompare(true);
              }
              if (props.compareStartDate) {
                setCompareStartDateToApply(Moment(props.compareStartDate));
                setCompareEndDateToApply(Moment(props.compareEndDate ?? props.compareStartDate));
              }
              analytics('Compare date');
            }}
          >
            {renderCompareDateText()}
          </TouchableOpacity>
        </View>
      )}
    </>
  );

  if (props.renderDesktopView) {
    return (
      <View style={{ backgroundColor: 'transparent', marginBottom: 24 }}>
        <View style={{ flexDirection: 'row' }}>
          {renderCalendarIcon()}
          <TouchableOpacity
            style={{
              flex: 1,
              borderWidth: 1.5,
              borderColor: dateSelected
                ? Colors.light.hereworksLightBlue
                : Colors.light.hereworksPaleBlue900,
              borderRadius: 10,
              marginLeft: 12,
              backgroundColor: dateSelected ? Colors.light.hereworksLightBlue : '#fff',
            }}
            onPress={() => {
              props.onOpen(!dateSelected);
              setDateSelected(!dateSelected);
              analytics('First date desktop');
            }}
          >
            {renderFirstDateText(dateSelected ? '#fff' : '#000')}
          </TouchableOpacity>
          {compareToggled && (
            <>
              <View style={{ marginLeft: 14, justifyContent: 'center' }}>
                <CompareArrowsIcon />
              </View>
              <TouchableOpacity
                style={{
                  borderWidth: 1.5,
                  borderColor: dateSelected
                    ? Colors.light.hereworksLightBlue
                    : Colors.light.hereworksPaleBlue900,
                  borderRadius: 10,
                  marginLeft: 12,
                  backgroundColor: dateSelected ? Colors.light.hereworksLightBlue : '#fff',
                }}
                onPress={() => {
                  props.onOpen(!dateSelected);
                  setDateSelected(!dateSelected);
                  analytics('Compare date desktop');
                }}
              >
                {renderCompareDateText(dateSelected ? '#fff' : '#000')}
              </TouchableOpacity>
            </>
          )}
        </View>
        {dateSelected && (
          <View style={{ position: 'absolute', top: 52, marginBottom: 24 }}>
            <View
              style={{
                borderWidth: 1.5,
                borderColor: Colors.light.hereworksLightBlue,
                backgroundColor: '#fff',
                borderRadius: 16,
                flexDirection: 'row',
                zIndex: 1,
                marginBottom: 24,
                flex: 1,
              }}
            >
              <View style={{ borderRightWidth: 1, borderColor: Colors.light.hereworksWhite500 }}>
                <View
                  style={{
                    backgroundColor: 'transparent',
                    borderBottomWidth: 1,
                    borderColor: Colors.light.hereworksWhite500,
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      color: Colors.light.hereworksBlack500,
                      fontFamily: 'Roboto_500Medium',
                      fontSize: 12,
                      marginLeft: 24,
                      marginTop: 24,
                      marginBottom: 12,
                    }}
                  >
                    RANGE
                  </Text>
                </View>
                {renderRangePickers()}
                {renderCompareToggleButton()}
              </View>
              <View style={{ flex: 1 }}>
                {renderFirstDate()}
                {renderCompareDate()}
                {renderCalendar()}
                <View
                  style={{
                    marginHorizontal: 24,
                    marginTop: 16,
                    marginBottom: 24,
                    height: 36,
                    justifyContent: 'flex-end',
                    flex: 1,
                  }}
                >
                  {renderApplyCancel()}
                </View>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }

  // Render Mobile
  return (
    <ScrollView
      style={{ backgroundColor: 'transparent', marginVertical: 10 }}
      contentContainerStyle={{ paddingBottom: dateSelected ? 50 : 4 }}
    >
      <View style={{ flexDirection: 'row' }}>
        {compareToggled ? renderCompareIcon() : renderCalendarIcon()}
        <TouchableOpacity
          style={[
            styles.dateFilter,
            {
              marginLeft: 8,
              borderWidth: 1.5,
              borderColor: dateSelected
                ? Colors.light.hereworksLightBlue
                : Colors.light.hereworksPaleBlue900,
            },
          ]}
          onPress={() => {
            props.onOpen(!dateSelected);
            setDateSelected(!dateSelected);
            analytics('First date mobile');
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {renderFirstDateText()}
          </View>
        </TouchableOpacity>
      </View>
      {compareToggled && (
        <TouchableOpacity
          style={[
            styles.dateFilter,
            {
              marginTop: 4,
              marginLeft: 48,
              borderWidth: 1.5,
              borderColor: dateSelected
                ? Colors.light.hereworksLightBlue
                : Colors.light.hereworksPaleBlue900,
            },
          ]}
          onPress={() => {
            props.onOpen(!dateSelected);
            setDateSelected(!dateSelected);
            analytics('Compare date mobile');
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {renderCompareDateText()}
          </View>
        </TouchableOpacity>
      )}
      {dateSelected && (
        <View
          style={{
            borderWidth: 1.5,
            borderColor: Colors.light.hereworksLightBlue,
            backgroundColor: '#fff',
            marginTop: 12,
            paddingTop: 12,
            borderRadius: 8,
          }}
        >
          {props.compareEnabled && renderFirstDate()}
          {selectingCompare && renderCompareDate()}
          {renderCalendar()}
          {!selectingCompare && renderCompareDate()}
          <View
            style={{
              backgroundColor: 'transparent',
              borderTopWidth: 1,
              borderColor: Colors.light.hereworksWhite500,
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                color: Colors.light.hereworksBlack500,
                fontFamily: 'Roboto_500Medium',
                fontSize: 12,
                marginLeft: 24,
                marginTop: 16,
                marginBottom: 10,
              }}
            >
              RANGE
            </Text>
          </View>
          <ScrollView horizontal={true}>{renderRangePickers()}</ScrollView>
          <View style={{ borderBottomWidth: 1, borderColor: Colors.light.hereworksWhite500 }}>
            {renderCompareToggleButton()}
          </View>
          <View
            style={{
              marginHorizontal: 18,
              marginBottom: 24,
              marginTop: 16,
              height: 36,
            }}
          >
            {renderApplyCancel()}
          </View>
        </View>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  dateFilter: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    borderRadius: 6,
    height: 40,
  },
  dateText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    marginHorizontal: 30,
    marginVertical: 8,
    textAlign: 'center',
    flex: 1,
  },
  dateRangeButton: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: 6,
    borderWidth: 1.5,
    marginVertical: 4,
  },
  dateRangeText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    marginHorizontal: 12,
    marginVertical: 4,
    lineHeight: 20,
  },
});
