import * as React from 'react';
import Svg, {
  SvgProps, G, Rect, Path, Defs, ClipPath,
} from 'react-native-svg';

interface Props extends SvgProps {
  checkcolor: string;
}

const CheckBox = (props: Props) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Rect width={16} height={16} rx={3} fill="#fff" />
      <Path
        d="m3.333 7.999 3.333 3.333 6-6"
        stroke={props.checkcolor ?? '#0058AD'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Rect x={0.5} y={0.5} width={15} height={15} rx={2.5} stroke={props.color ?? '#0058AD'} />
    <Defs>
      <ClipPath id="a">
        <Rect width={16} height={16} rx={3} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default CheckBox;
