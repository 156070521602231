import {
  Platform, Pressable, StyleSheet, Text, View,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import Modal from 'react-native-modal';
import { useState } from 'react';
import Moment from 'moment';
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
// import { FontAwesome } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import SensorIcon from '../24pxIcons/SensorIcon';
import Style from '../../constants/Style';

type ContextType = {
  translateX: number;
  translateY: number;
};

interface Props {
  name: any;
  temp: any;
  humidity: any;
  co2: any;
  tvoc: any;
  pm25: any;
  spl: any;
  lux: any;
  score: any;
  timestamp: any;
  hovered: boolean;
  editMode?: boolean;
  sensor?: any;
  movingItem?: boolean;
}

export type PressableState = Readonly<{
  pressed: boolean;
  hovered?: boolean;
}>;

const Sensor = (props: Props) => {
  const {
    name,
    temp,
    hovered,
    humidity,
    co2,
    tvoc,
    pm25,
    spl,
    lux,
    score,
    timestamp,
    sensor,
    movingItem,
  } = props;

  const translateXsen = useSharedValue(sensor?.GeoX);
  const translateYsen = useSharedValue(sensor?.GeoY);

  const panGestureSen = useAnimatedGestureHandler<PanGestureHandlerGestureEvent, ContextType>({
    onStart: (event, context) => {
      context.translateX = translateXsen.value;
      context.translateY = translateYsen.value;
    },
    onActive: (event, context) => {
      translateXsen.value = event.translationX + context.translateX;
      translateYsen.value = event.translationY + context.translateY;
    },
  });

  const transformStyleSen = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: translateXsen.value,
      },
      {
        translateY: translateYsen.value,
      },
    ],
  }));

  const showScore = false;

  const [modalVisible, setModalVisible] = useState(false);

  const isMobile = (): boolean => Platform.OS !== 'web';

  const innerColors: string[] = [
    Colors.light.hereworksGreen400,
    Colors.light.hereworksYellow500,
    Colors.light.hereworksRed500,
  ];

  const outerColors: string[] = [
    Colors.light.hereworksGreen50,
    Colors.light.hereworksYellow50,
    Colors.light.hereworksRed50,
  ];

  const getSensorColor = (sensorScore) => {
    if (sensorScore < 80) {
      return Colors.light.hereworksRed500;
    }

    return Colors.light.hereworksGreen400;
  };

  const getSecondarySensorColor = (sensorScore) => {
    if (sensorScore < 80) {
      return Colors.light.hereworksRed50;
    }

    return Colors.light.hereworksGreen50;
  };

  const getInnerColor = (below, above, value) => {
    if (below !== null && value < below) {
      return innerColors[1];
    }
    if (above !== null && value > above) {
      return innerColors[2];
    }
    return innerColors[0];
  };

  const getOuterColor = (below, above, value) => {
    if (value < below) {
      return outerColors[0];
    }
    if (value > above) {
      return outerColors[2];
    }
    return outerColors[1];
  };

  const circle = (innerColor, outerColor) => (
    <View
      style={{
        width: 16,
        height: 16,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: outerColor,
      }}
    >
      <View
        style={{
          width: 8,
          height: 8,
          borderRadius: 4,
          backgroundColor: innerColor,
        }}
      />
    </View>
  );

  const sensorRow = (belowThreshold, aboveThreshold, title, desc, value) => (
    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 8 }}>
      {circle(
        getInnerColor(belowThreshold, aboveThreshold, value),
        getOuterColor(belowThreshold, aboveThreshold, value),
      )}
      <Text style={styles.sensorRowTitle}>{title}</Text>
      <Text style={styles.sensorRowValue}>{desc}</Text>
    </View>
  );

  const sensorInfo = () => (
    <Animatable.View
      animation={'fadeIn'}
      style={[
        Style.boxShadow,
        {
          backgroundColor: '#fff',
          width: 280,
          marginHorizontal: 8,
          padding: 16,
          borderRadius: 10,
          zIndex: -1,
        },
      ]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {circle(getSensorColor(score), getSecondarySensorColor(score))}
        <Text style={styles.buttonText}>{name}</Text>
      </View>
      {showScore && (
        <View
          style={{
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderColor: Colors.light.hereworksBlue200,
            marginBottom: 24,
          }}
        >
          <Text style={[styles.sensorScore, { color: getSensorColor(score) }]}>{score}</Text>
          <View style={{ flexDirection: 'row', marginTop: 4 }}>
            <Text
              style={{
                fontFamily: 'Poppins_400Regular',
                fontSize: 12,
                color: Colors.light.hereworksBlack400,
                marginRight: 8,
              }}
            >
              Score:
            </Text>
            <Text
              style={{
                fontFamily: 'Poppins_500Medium',
                fontSize: 12,
                color: Colors.light.hereworksBlack600,
              }}
            >
              {score < 80 ? 'Bad' : 'Good'}
            </Text>
          </View>
        </View>
      )}
      <Text
        style={{
          fontFamily: 'Poppins_500Medium',
          fontSize: 14,
          lineHeight: 20,
          marginTop: 8,
          marginBottom: 4,
          color: Colors.light.hereworksBlack500,
        }}
      >
        Overall area data
      </Text>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
        <Text
          style={{
            fontFamily: 'Poppins_400Regular',
            fontSize: 12,
            color: Colors.light.hereworksBlack400,
          }}
        >
          Last reading:
        </Text>
        <Text
          style={{
            fontFamily: 'Poppins_500Medium',
            fontSize: 12,
            color: Colors.light.hereworksBlack500,
            flex: 1,
            textAlign: 'right',
          }}
        >
          {Moment(timestamp).format('h:mm a, D MMM')}
        </Text>
      </View>
      {sensorRow(18, 23, 'Temperature', `${temp} °C`, temp)}
      {sensorRow(30, 60, 'Humidity', `${humidity}%`, humidity)}
      {sensorRow(null, 1500, 'CO₂', `${co2} ppm`, co2)}

      {sensorRow(null, 660, 'TVOCs', `${tvoc} ppb`, tvoc)}
      {sensorRow(null, 35.5, 'PM 2.5', `${pm25} µg/m³`, pm25)}
      {sensorRow(null, 80, 'Noise', `${spl} dB`, spl)}
      {sensorRow(null, null, 'Light', `${lux} lx`, lux)}
    </Animatable.View>
  );

  const sensorIcon = () => (
    <View
      style={[
        {
          backgroundColor: getSensorColor(score),
          width: 44,
          height: 44,
          borderRadius: 22,
          borderWidth: 2,
          borderColor: '#fff',
          alignItems: 'center',
          justifyContent: 'center',
          transform: [{ scale: 0.7 }],
        },
        Style.circleShadow,
      ]}
    >
      <SensorIcon />
    </View>
  );

  if (isMobile()) {
    return (
      <>
        <Pressable
          style={{ position: 'absolute' }}
          onPress={() => {
            if (isMobile()) {
              setModalVisible(true);
            }
          }}
        >
          <Animatable.View
            style={{
              backgroundColor: 'transparent',
              flexDirection: 'row',
            }}
          >
            {sensorIcon()}
            {hovered && sensorInfo()}
          </Animatable.View>
        </Pressable>
        <Modal
          isVisible={modalVisible}
          animationIn={'slideInUp'}
          animationOut={'slideOutDown'}
          swipeDirection={'down'}
          onSwipeComplete={() => setModalVisible(false)}
          onBackButtonPress={() => setModalVisible(false)}
          onBackdropPress={() => setModalVisible(false)}
          style={{
            margin: 0,
            flex: 1,
            alignItems: 'center',
          }}
        >
          {sensorInfo()}
        </Modal>
      </>
    );
  }

  if (props.editMode) {
    return (
      <PanGestureHandler onGestureEvent={movingItem ? panGestureSen : null}>
        <Animated.View style={[transformStyleSen]}>
          <Pressable
            style={(state) => [
              {
                position: 'absolute',
                zIndex: state.hovered ? 10 : 1,
              },
            ]}
          >
            {sensorIcon()}
            {/* {movingItem && <FontAwesome name="arrows" />} */}
          </Pressable>
        </Animated.View>
      </PanGestureHandler>
    );
  }
  return (
    <View style={{ position: 'absolute' }}>
      <Animatable.View
        style={{
          backgroundColor: 'transparent',
          flexDirection: 'row',
        }}
      >
        {sensorIcon()}
        {hovered && sensorInfo()}
      </Animatable.View>
    </View>
  );
};

const styles = StyleSheet.create({
  sensorScore: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 36,
    color: Colors.light.hereworksRed500,
    flex: 1,
  },
  sensorRowTitle: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 22,
    color: Colors.light.hereworksBlack400,
    marginLeft: 12,
  },
  sensorRowValue: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    textAlign: 'right',
    flex: 1,
  },
  buttonText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksBlack600,
    paddingHorizontal: 10,
  },
});

export default Sensor;
