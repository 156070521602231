import AsyncStorage from '@react-native-async-storage/async-storage';

const getUserGroups = async () => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('jwt', jwtToken ?? '');
    requestHeaders.set('Content-Type', 'application/json');

    const response = await fetch(
      `https://${apiDomain}/button/usergroups`,
      {
        method: 'GET',
        headers: requestHeaders,
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default getUserGroups;
