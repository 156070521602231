import React, { useEffect, useState } from 'react';
import {
  Image, Text, StyleSheet, TouchableOpacity, View, ScrollView,
} from 'react-native';
import { Auth } from 'aws-amplify';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Analytics from 'expo-firebase-analytics';
import DownArrow24 from '../components/24pxIcons/DownArrow24';
import Colors from '../constants/Colors';
import { RootTabScreenProps } from '../types';
import CustomTextField from '../components/CustomTextField';
// import NotificationSettingRow from '../components/NotificationSettingRow';
import NotificationGroupCard from '../components/UserManagement/NotificationGroupCard';
import ProfileIcon60 from '../components/60pxIcons/ProfileIcon60';
import AlertModal from '../components/AlertModal';

// eslint-disable-next-line no-unused-vars
export default function ProfileScreen({ navigation }: RootTabScreenProps<'Profile'>) {
  const [selected, setSelected] = useState(0);
  const [client, setClient] = useState('Unknown');
  const [apiDomain, setApiDomain] = useState('api.hereworks.tech');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [passwordModalDesc, setPasswordModalDesc] = useState('');
  const [passwordModalTitle, setPasswordModalTitle] = useState('');
  const [passwordModalError, setPasswordModalError] = useState(false);

  const showMyGroups = false;

  const showAlert = (title: string, error: boolean = true) => {
    setPasswordModalError(error);
    setPasswordModalTitle(title);
    setPasswordModalVisible(true);
  };

  AsyncStorage.getItem('apiDomain').then((currentApi) => {
    setApiDomain(currentApi ?? 'api.hereworks.tech');
  });

  useEffect(() => {
    AsyncStorage.getItem('clientName').then((name) => {
      if (name) {
        setClient(name);
      }
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AsyncStorage.getItem('email').then((n) => {
      setEmail(n);
    }) ?? '';
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AsyncStorage.getItem('name').then((n) => {
      setFullName(n);
    }) ?? '';
  }, []);

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'Profile',
      name: title,
    });
  };

  const renderSidebarButton = (title, index) => (
    <TouchableOpacity
      style={selected === index ? styles.selectedButton : styles.unselectedButton}
      onPress={() => {
        analytics(`Sidebar: ${title}`);
        setSelected(index);
      }}
    >
      <Text style={selected === index ? styles.selectedText : styles.unselectedText}>{title}</Text>
      <DownArrow24
        color={selected === index ? '#fff' : Colors.light.hereworksBlue500}
        style={{ transform: [{ rotateZ: '270deg' }], position: 'absolute', right: 18 }}
      />
    </TouchableOpacity>
  );

  const renderSaveButton = (save, disabled = false) => (
    <TouchableOpacity
      style={{
        backgroundColor: Colors.light.hereworksBlue500,
        borderRadius: 4,
        paddingVertical: 8,
        paddingHorizontal: 24,
        marginTop: 40,
        alignSelf: 'flex-start',
        opacity: disabled ? 0.3 : 1,
      }}
      onPress={save}
      disabled={disabled}
    >
      <Text
        style={{
          color: '#fff',
          fontFamily: 'Roboto_400Regular',
          fontSize: 16,
          lineHeight: 24,
        }}
      >
        Save
      </Text>
    </TouchableOpacity>
  );

  const renderUserIcon = (imageSource, facilityManager = false) => (
    <View
      style={{
        width: 48,
        height: 48,
        backgroundColor: facilityManager ? '#fff' : 'transparent',
        borderWidth: 1.5,
        borderColor: facilityManager ? Colors.light.hereworksBlue500 : 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 24,
      }}
    >
      <Image
        style={styles.smallUserPic}
        source={{
          uri: imageSource,
        }}
      />
    </View>
  );

  const renderUser = (user) => (
    <>
      {renderUserIcon(user.icon, user.facilityManager)}
      <View style={{ marginLeft: 12, justifyContent: 'center' }}>
        <Text
          style={{
            fontSize: 16,
            lineHeight: 24,
            marginBottom: 4,
            fontFamily: 'Poppins_600SemiBold',
            color: Colors.light.hereworksBlack600,
          }}
        >
          {user.name}
        </Text>
        <Text
          style={{
            fontSize: 12,
            lineHeight: 20,
            marginBottom: 4,
            fontFamily: 'Roboto_500Medium',
            color: Colors.light.hereworksBlack400,
          }}
        >
          {user.role}
        </Text>
      </View>
    </>
  );

  const renderUploadButton = () => (
    <TouchableOpacity
      style={{
        backgroundColor: Colors.light.hereworksBlue500,
        borderRadius: 4,
        paddingVertical: 8,
        paddingHorizontal: 12,
        marginTop: 16,
        alignSelf: 'flex-start',
      }}
      onPress={() => showAlert('Feature coming soon!', false)}
    >
      <Text
        style={{
          color: '#fff',
          fontFamily: 'Roboto_400Regular',
          fontSize: 14,
          lineHeight: 22,
        }}
      >
        Upload new
      </Text>
    </TouchableOpacity>
  );

  const renderDeleteButton = () => (
    <TouchableOpacity
      style={{
        backgroundColor: '#fff',
        borderRadius: 4,
        paddingVertical: 6,
        paddingHorizontal: 10,
        marginTop: 16,
        marginLeft: 8,
        alignSelf: 'flex-start',
        borderWidth: 1,
        borderColor: Colors.light.hereworksRed100,
      }}
      onPress={() => showAlert('Feature coming soon!', false)}
    >
      <Text
        style={{
          color: Colors.light.hereworksRed100,
          fontFamily: 'Roboto_400Regular',
          fontSize: 14,
          lineHeight: 22,
        }}
      >
        Delete
      </Text>
    </TouchableOpacity>
  );

  const passwordRequirement = (text) => (
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 4,
    }}>
      <View style={{
        width: 4,
        height: 4,
        borderRadius: 2,
        backgroundColor: Colors.light.hereworksBlue500,
      }} />
      <Text style={{
        marginLeft: 8,
        fontFamily: 'Roboto_400Regular',
        fontSize: 12,
        lineHeight: 16,
        color: Colors.light.hereworksBlack400,
      }}>{text}</Text>
    </View>
  );

  const isInvalid = () => {
    if (newPassword.length > 0 && confirmNewPassword.length > 0) {
      if (newPassword !== confirmNewPassword) { return true; }
    }
    return false;
  };

  const renderMain = () => {
    switch (selected) {
      case 0: {
        return (
          <View style={styles.mainContainer}>
            <Text style={styles.mainTitle}>Profile information</Text>
            <Text>{`Client ID: ${client}`}</Text>
            <ScrollView
              style={{ marginBottom: 24 }}
              contentContainerStyle={{ flexGrow: 1, paddingBottom: 40 }}
            >
              <View style={{ alignSelf: 'flex-start', width: '50%', minWidth: 400 }}>
                <View style={{ flexDirection: 'row', marginTop: 24 }}>
                  {/* <Image
                    style={styles.userPic}
                    source={{
                      uri: 'https://s3.eu-west-1.amazonaws.com/devinsights.hereworks.tech/userpics/User5.png',
                    }}
                  /> */}
                  <View style={styles.userPic}>
                    <ProfileIcon60 />
                  </View>
                  <View style={{ marginLeft: 32, justifyContent: 'center' }}>
                    <Text style={styles.uploadAvatarTitle}>Upload avatar</Text>
                    <View style={{ flexDirection: 'row' }}>
                      {renderUploadButton()}
                      {renderDeleteButton()}
                    </View>
                  </View>
                </View>
                <CustomTextField title={'Full name'} value={fullName} updateValue={() => { }} />
                <CustomTextField title={'Email'} value={email} updateValue={() => { }} />
                {/* <CustomTextField title={'Phone'} updateValue={() => {}} /> */}
              </View>
              {renderSaveButton(() => {
                analytics('Save profile');
                showAlert('Feature coming soon!', false);
              })}
            </ScrollView>
          </View>
        );
      }
      case 1: {
        return (
          <View style={styles.mainContainer}>
            <Text style={styles.mainTitle}>Change Password</Text>
            <ScrollView
              style={{ marginBottom: 24 }}
              contentContainerStyle={{ flexGrow: 1, paddingBottom: 40 }}
            >
              <View style={{ flexDirection: 'row' }}>
              <View style={{ alignSelf: 'flex-start', width: '50%', minWidth: 400 }}>
                <CustomTextField
                  secure
                  title={'Old Password'}
                  value={oldPassword}
                  updateValue={setOldPassword}
                />
                <CustomTextField
                  secure
                  title={'New Password'}
                  value={newPassword}
                  updateValue={setNewPassword}
                />
                <CustomTextField
                  secure
                  title={'Confirm New Password'}
                  value={confirmNewPassword}
                  updateValue={setConfirmNewPassword}
                  errorText='Passwords must match!'
                  invalid={isInvalid()}
                />
              </View>
              <View style={{
                marginLeft: 58,
                backgroundColor: Colors.light.hereworksWhite200,
                padding: 16,
                borderColor: Colors.light.hereworksWhite500,
                borderRadius: 16,
                flex: 1,
              }}>
                <Text style={{
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 18,
                  lineHeight: 27,
                  color: Colors.light.hereworksBlack600,
                  marginBottom: 10,
                }}>
                  Password must have:
                </Text>
                {passwordRequirement('At least 8 characters')}
                {passwordRequirement('At least 1 uppercase')}
                {passwordRequirement('At least 1 lowercase')}
                {passwordRequirement('At least 1 number')}
              </View>
              </View>
              {renderSaveButton(
                () => {
                  analytics('Save password');
                  if (!isInvalid()) {
                    Auth.currentAuthenticatedUser()
                    // eslint-disable-next-line max-len
                      .then((user) => Auth.changePassword(user, oldPassword, newPassword).then((data) => {
                        setPasswordModalTitle('Success!');
                        setPasswordModalDesc(JSON.stringify(data));
                        setPasswordModalVisible(true);
                        setNewPassword('');
                        setOldPassword('');
                      }))
                      .catch((err) => {
                        setPasswordModalTitle('Something went wrong!');
                        setPasswordModalDesc(err?.message ?? JSON.stringify(err));
                        setPasswordModalError(true);
                        setPasswordModalVisible(true);
                      });
                  }
                },
                isInvalid()
                || newPassword.length === 0
                || confirmNewPassword.length === 0
                || oldPassword.length === 0,
              )}
            </ScrollView>
          </View>
        );
      }
      case 3: {
        if (!showMyGroups) {
          return (
            <View style={styles.mainContainer}>
              <Text style={styles.mainTitle}>Coming soon!</Text>
            </View>
          );
        }
        return (
          <View style={styles.mainContainer}>
            <Text style={styles.mainTitle}>My groups</Text>
            <ScrollView
              style={{
                marginVertical: 24,
              }}
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: 16,
                paddingTop: 16,
                marginBottom: 40,
                backgroundColor: Colors.light.hereworksWhite100,
                borderRadius: 10,
              }}
            >
              <View style={{ flexDirection: 'row', marginBottom: 16 }}>
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 18,
                      lineHeight: 27,
                      color: Colors.light.hereworksBlack600,
                    }}
                  >
                    {TEST_USERS.groupName}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto_400Regular',
                      fontSize: 16,
                      lineHeight: 24,
                      color: Colors.light.hereworksBlack400,
                      marginTop: 4,
                    }}
                  >
                    {TEST_USERS.building}
                  </Text>
                </View>
                {renderUser(TEST_USERS.users[0])}
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 4 }}>
                  <Text style={styles.description}>NOTIFICATIONS</Text>
                  <NotificationGroupCard
                    title="Software"
                    issues={['Licence expired', 'Driver issue', 'No internet']}
                  />
                  <NotificationGroupCard
                    title="Hardware"
                    issues={["Monitor won't connect", 'Keyboard broken', 'Mouse broken']}
                  />
                </View>
                <View
                  style={{
                    width: 1,
                    backgroundColor: Colors.light.hereworksWhite500,
                    marginHorizontal: 12,
                  }}
                />
                <View style={{ flex: 3 }}>
                  <Text style={styles.description}>MEMBERS</Text>
                  {TEST_USERS.users.map((user, key) => (
                    <View key={key} style={{ marginTop: 16, flexDirection: 'row' }}>
                      {renderUser(user)}
                    </View>
                  ))}
                </View>
              </View>
            </ScrollView>
          </View>
        );
      }
      // case 5: {
      //   return (
      //     <View style={styles.mainContainer}>
      //       <Text style={styles.mainTitle}>Notification settings</Text>
      //       <ScrollView
      //         style={{ marginBottom: 24 }}
      //         contentContainerStyle={{ flexGrow: 1, paddingBottom: 40 }}
      //       >
      //         <Text style={styles.sectionTitle}>Building notifications</Text>
      //         <NotificationSettingRow title="Building 1" toggled={true} />
      //         <NotificationSettingRow title="Building 2" toggled={true} />
      //         <NotificationSettingRow title="Building 3" toggled={true} />
      //         <NotificationSettingRow title="Building 4" toggled={true} />
      //         <NotificationSettingRow title="Building 5" toggled={true} />
      //         <NotificationSettingRow title="Building 6" toggled={true} />
      //         <Text style={styles.sectionTitle}>General settings</Text>
      //         <NotificationSettingRow
      //           title="Summary notification"
      //           subTitle="Receive daily summary notifications"
      //           toggled={true}
      //         />
      //         <NotificationSettingRow title="Active push notification" toggled={true} />
      //         <NotificationSettingRow
      //           title="Reminders"
      //           subTitle="Remind about pending tickets"
      //           toggled={true}
      //         />
      //         {renderSaveButton()}
      //       </ScrollView>
      //     </View>
      //   );
      // }
      default: {
        return (
          <View style={styles.mainContainer}>
            <Text style={styles.mainTitle}>Coming soon!</Text>
          </View>
        );
      }
    }
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          position: 'absolute',
          flexDirection: 'row',
          right: 40,
          top: 24,
        }}
      >
        <Text style={styles.appInfo}>
          Client: {client}
          {'\n'}
          API: {apiDomain}
        </Text>
        <TouchableOpacity
          style={{
            borderColor: Colors.light.hereworksBlue400,
            borderWidth: 1,
            borderRadius: 8,
            padding: 8,
          }}
          onPress={() => Auth.signOut()}
        >
          <Text
            style={{
              fontFamily: 'Poppins_500Medium',
              fontSize: 16,
              color: Colors.light.hereworksBlue500,
            }}
          >
            Logout
          </Text>
        </TouchableOpacity>
      </View>
      <Text style={styles.title}>My profile</Text>
      <View style={styles.contentContainer}>
        <AlertModal
          modalVisible={passwordModalVisible}
          title={passwordModalTitle}
          message={passwordModalDesc}
          error={passwordModalError}
          setModalVisible={setPasswordModalVisible}
        />
        <View style={{ width: 240, marginTop: 24 }}>
          {renderSidebarButton('Profile information', 0)}
          {renderSidebarButton('Password', 1)}
          {/* {renderSidebarButton('Office equipment', 2)}
          {renderSidebarButton('My groups', 3)}
          {renderSidebarButton('Tickets', 4)}
          {renderSidebarButton('Notification', 5)} */}
        </View>
        <View style={styles.separator} />
        {renderMain()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingVertical: 32,
    paddingHorizontal: 40,
    backgroundColor: Colors.light.hereworksBackground,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    backgroundColor: '#fff',
    borderRadius: 24,
    paddingHorizontal: 8,
  },
  title: {
    fontSize: 32,
    lineHeight: 48,
    marginBottom: 24,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
  },
  mainTitle: {
    fontSize: 22,
    lineHeight: 33,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
  },
  separator: {
    marginLeft: 14,
    backgroundColor: Colors.light.hereworksBlue100,
    width: 1,
    height: '100%',
  },
  selectedButton: {
    backgroundColor: Colors.light.hereworksBlue500,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 11,
  },
  unselectedButton: {
    paddingHorizontal: 12,
    paddingVertical: 11,
  },
  selectedText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: '#fff',
  },
  unselectedText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack500,
  },
  sectionTitle: {
    fontSize: 18,
    lineHeight: 27,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
    marginTop: 16,
    marginBottom: 8,
  },
  mainContainer: {
    margin: 24,
    flex: 1,
    height: '100%',
  },
  userPic: {
    width: 120,
    height: 120,
    borderRadius: 60,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  uploadAvatarTitle: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    lineHeight: 24,
    color: Colors.light.hereworksBlack400,
  },
  description: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 22,
    color: Colors.light.hereworksBlack400,
  },
  smallUserPic: {
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: 'hidden',
  },
  appInfo: {
    color: Colors.light.hereworksLightBlue800,
    fontFamily: 'Poppins_400Regular',
    fontSize: 10,
    marginRight: 8,
  },
});

const TEST_USERS = {
  groupName: 'Cleaning 1',
  building: 'Hereworks HQ',
  users: [
    {
      name: 'John Ivanchenko',
      email: 'John12@hereworks.com',
      lastActive: 'Today, 09:01',
      icon: 'https://s3.eu-west-1.amazonaws.com/devinsights.hereworks.tech/userpics/UserJohn.png',
      facilityManager: true,
      role: 'FACILITY MANAGER',
    },
    {
      name: 'Richard Lionheart',
      email: 'richard12@hereworks.com',
      lastActive: 'Yesterday, 20:21',
      icon: 'https://s3.eu-west-1.amazonaws.com/devinsights.hereworks.tech/userpics/User0.png',
      role: 'CLEANER',
    },
    {
      name: 'Rob Williams',
      email: 'Wiliamrob12@hereworks.com',
      lastActive: 'Yesterday, 19:20',
      icon: 'https://s3.eu-west-1.amazonaws.com/devinsights.hereworks.tech/userpics/User1.png',
      role: 'CLEANER',
    },
    {
      name: 'Robert Secondson',
      email: 'Robert55@hereworks.com',
      lastActive: 'Yesterday, 19:20',
      icon: 'https://s3.eu-west-1.amazonaws.com/devinsights.hereworks.tech/userpics/User2.png',
      role: 'CLEANER',
    },
    {
      name: 'James George',
      email: 'James12@hereworks.com',
      lastActive: 'Yesterday, 19:20',
      icon: 'https://s3.eu-west-1.amazonaws.com/devinsights.hereworks.tech/userpics/User3.png',
      role: 'CLEANER',
    },
  ],
};
