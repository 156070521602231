import * as React from 'react';
import Svg, {
  SvgProps, G, Path, Defs, ClipPath,
} from 'react-native-svg';

const LogoutIcon = (props: SvgProps) => (
  <Svg width={18} height={18} fill="none" {...props}>
    <G clipPath="url(#a)" fill="#323E48">
      <Path d="M8.97 16.475H2.243a.748.748 0 0 1-.748-.748V2.272c0-.412.335-.747.748-.747H8.97a.747.747 0 1 0 0-1.495H2.243A2.245 2.245 0 0 0 0 2.272v13.455a2.245 2.245 0 0 0 2.243 2.243H8.97a.747.747 0 1 0 0-1.495Z" />
      <Path d="m17.777 8.468-4.545-4.485a.747.747 0 1 0-1.05 1.064l3.249 3.206H6.727a.747.747 0 1 0 0 1.495h8.704l-3.248 3.205a.747.747 0 1 0 1.05 1.064l4.544-4.485a.749.749 0 0 0 0-1.064Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h18v18H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default LogoutIcon;
