import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const EquipmentIcon60 = (props: SvgProps) => (
  <Svg width={61} height={60} fill="none" {...props}>
    <Path
      d="M50.857 7.5h-40a5 5 0 0 0-5 5v25a5 5 0 0 0 5 5h40a5 5 0 0 0 5-5v-25a5 5 0 0 0-5-5ZM20.857 52.5h20M30.857 42.5v10"
      stroke={props.color ?? '#fff'}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default EquipmentIcon60;
