import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const MeetingRoom60 = (props: SvgProps) => (
  <Svg width={61} height={60} fill="none" {...props}>
    <Path
      d="M5.143 17.5 7.52 34.141a1 1 0 0 0 .99.859h14.133M56.145 17.5l-2.378 16.641a1 1 0 0 1-.99.859H38.644M46.645 35v7.5m0 0-6.25 2.5m6.25-2.5 6.25 2.5M14.643 35v7.5m0 0 6.25 2.5m-6.25-2.5L8.393 45M14.393 25h16.25m16.25 0h-16.25m0 0v17.5"
      stroke={props.color ?? '#fff'}
      strokeWidth={3}
      strokeLinecap="round"
    />
  </Svg>
);

export default MeetingRoom60;
