import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const StartedOnIcon24 = (props: SvgProps) => (
    <Svg width={24} height={24} fill="none" {...props}>
        <Path d="M13 6.83325H11V12.8333H17V10.8333H13V6.83325Z" fill="#121B23" />
        <Path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20V22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12H20C20 7.58172 16.4183 4 12 4Z" fill="#121B23" />
        <Path d="M22 18.5L15.25 22.3971L15.25 14.6029L22 18.5Z" fill="#121B23" />
    </Svg>
);

export default StartedOnIcon24;
