import React, { useState } from 'react';
import {
  StyleSheet, Text, ScrollView, TouchableOpacity,
} from 'react-native';
import Moment from 'moment';
import * as Analytics from 'expo-firebase-analytics';
import { View } from '../components/Themed';
import Colors from '../constants/Colors';
import { RootTabScreenProps } from '../types';
import CompareArrowsIcon from '../components/CompareArrowsIcon';
import DateComponent from '../components/DateComponent';
import ScoreCardMobile from '../components/ScoreCardMobile';
import SelectUtility from '../components/SelectUtility';
import BarWithLineGraphMobile from '../graphs/BarWithLineGraphMobile';

export default function EnergyScreenMobile({
  // eslint-disable-next-line no-unused-vars
  navigation,
}: RootTabScreenProps<'Energy'>) {
  const [range, setRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: Moment().subtract(6, 'days').startOf('day').toDate(), end: new Date() });

  const [compareRange, setCompareRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: null, end: null });

  const onConfirm = React.useCallback(
    (start, end) => {
      setRange({ start, end });
      // console.log(range);
    },
    [setRange],
  );

  const onCompareConfirm = React.useCallback(
    (start, end) => {
      setCompareRange({ start, end });
    },
    [setCompareRange],
  );

  const [utility, setUtility] = useState('Occupancy');
  const [metric, setMetric] = useState(null);
  const [vsGraphVisible, setVsGraphVisible] = useState(true);
  const [waterGraphVisible, setWaterGraphVisible] = useState(true);
  const [elecGraphVisible, setElecGraphVisible] = useState(true);

  const selectUtility = (selectedUtility: string, selectedMetric: string) => {
    setUtility(selectedUtility);
    setMetric(selectedMetric);
  };

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'EnergyScreen',
      name: title,
    });
  };

  const indexButtonData = [
    {
      value: 'Occupancy',
      metric: 'happy_metric_total_building_occupancy_raw_value',
    },
    { value: 'Electricity', metric: 'data-kwh-total', indexName: 'hw-rawdata-electricity' },
    { value: 'Water', metric: 'happy_metric_water_raw_value' },
    { value: 'Gas', metric: 'happy_metric_gas_raw_value' },
  ];

  const compareDatesView = () => (
    <View
      style={{
        marginLeft: 20,
        flexDirection: 'row',
        backgroundColor: '#fff',
      }}
    >
      <Text
        style={{
          color: Colors.light.hereworksBlack600,
          fontSize: 12,
          fontFamily: 'Roboto_500Medium',
        }}
      >
        {Moment(range.start).format('D MMM')} {' - '}{' '}
        {Moment(range.end ?? range.start).format('D MMM')}
      </Text>
      {compareRange.start && (
        <>
          <CompareArrowsIcon style={{ marginHorizontal: 10 }} />
          <Text
            style={{
              color: Colors.light.hereworksYellow500,
              fontSize: 12,
              fontFamily: 'Roboto_500Medium',
            }}
          >
            {Moment(compareRange.start).format('D MMM')} {' - '}{' '}
            {Moment(compareRange.end ?? compareRange.start).format('D MMM')}
          </Text>
        </>
      )}
    </View>
  );

  const renderGraphInsightsButtons = (graphVisible, setGraphVisible) => (
    <View
      style={{
        flexDirection: 'row',
        marginLeft: 20,
        marginTop: 12,
        backgroundColor: 'transparent',
      }}
    >
      <TouchableOpacity
        style={{
          backgroundColor: graphVisible ? Colors.light.hereworksLightBlue : '#fff',
          borderRadius: 4,
          marginRight: 12,
          borderWidth: 1,
          borderColor: graphVisible ? '#fff' : Colors.light.hereworksLightBlue,
        }}
        onPress={() => {
          analytics('GRAPH');
          if (!graphVisible) {
            setGraphVisible(!graphVisible);
          }
        }}
      >
        <Text
          style={{
            color: graphVisible ? '#fff' : Colors.light.hereworksLightBlue,
            fontFamily: 'Roboto_500Medium',
            fontSize: 12,
            marginVertical: 8,
            marginHorizontal: 12,
          }}
        >
          GRAPH
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          backgroundColor: graphVisible ? '#fff' : Colors.light.hereworksLightBlue,
          borderRadius: 4,
          borderWidth: 1,
          borderColor: graphVisible ? Colors.light.hereworksLightBlue : '#fff',
        }}
        onPress={() => {
          analytics('INSIGHTS');
          if (graphVisible) {
            setGraphVisible(!graphVisible);
          }
        }}
      >
        <Text
          style={{
            color: graphVisible ? Colors.light.hereworksLightBlue : '#fff',
            fontFamily: 'Roboto_500Medium',
            fontSize: 12,
            marginVertical: 8,
            marginHorizontal: 12,
          }}
        >
          INSIGHTS
        </Text>
      </TouchableOpacity>
    </View>
  );

  const renderInsights = () => (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: '#fff',
        flex: 2,
        margin: 10,
      }}
    >
      <View style={{ backgroundColor: '#fff', flex: 1 }}>
        <View style={styles.insightsTile}>
          <Text style={styles.insightsHeader}>Insight 1</Text>
          <Text style={styles.insightDesc}>Data not available</Text>
        </View>
        <View style={styles.insightsTile}>
          <Text style={styles.insightsHeader}>Insight 2</Text>
          <Text style={styles.insightDesc}>Data not available</Text>
        </View>
      </View>
      <View style={{ backgroundColor: '#fff', flex: 1 }}>
        <View style={styles.insightsTile}>
          <Text style={styles.insightsHeader}>Insight 3</Text>
          <Text style={styles.insightDesc}>Data not available</Text>
        </View>
        <View style={styles.insightsTile}>
          <Text style={styles.insightsHeader}>Insight 4</Text>
          <Text style={styles.insightDesc}>Data not available</Text>
        </View>
      </View>
    </View>
  );

  return (
    <View
      style={{
        paddingHorizontal: 24,
        backgroundColor: Colors.light.hereworksBackground,
        marginTop: -90,
        paddingTop: 150,
        alignSelf: 'stretch',
        flex: 1,
      }}
    >
      <Text
        style={{
          fontSize: 24,
          fontFamily: 'Poppins_600SemiBold',
          color: Colors.light.hereworksBlack600,
        }}
      >
        Energy
      </Text>
      <View style={{ backgroundColor: 'transparent' }}>
        <DateComponent
          renderDesktopView={false}
          compareEnabled
          onChange={onConfirm}
          onCompareChange={onCompareConfirm}
          onConfirm={() => {
            /** todo */
          }}
          onOpen={() => {}}
          startDate={range.start}
          endDate={range.end}
          compareStartDate={compareRange.start}
          compareEndDate={compareRange.end}
        />
      </View>
      <ScrollView style={{ alignSelf: 'stretch', zIndex: -1 }}>
        <View style={styles.container}>
          <View
            style={{
              flexDirection: 'column',
              backgroundColor: Colors.light.hereworksBackground,
              alignSelf: 'stretch',
              paddingTop: 40,
            }}
          >
            <ScoreCardMobile category={'energy_usage'} range={range} compareRange={compareRange} happyMetric={'happy_group_energy_usage_local_happyscore_percent'} />
            <Text style={styles.sectionHeading}>Utilities</Text>
            <View
              style={{
                borderColor: Colors.light.hereworksPrimaryGrey300,
                borderWidth: 1,
                borderRadius: 10,
                marginVertical: 24,
                marginRight: 24,
                backgroundColor: '#fff',
                zIndex: -1,
                width: '100%',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 22,
                  color: Colors.light.hereworksPrimaryGrey,
                  marginLeft: 20,
                  marginTop: 18,
                }}
              >
                Utility vs occupancy
              </Text>
              {compareDatesView()}
              {renderGraphInsightsButtons(vsGraphVisible, setVsGraphVisible)}
              {vsGraphVisible && (
                <>
                  <Text
                    style={{
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 18,
                      color: Colors.light.hereworksPrimaryGrey,
                      marginLeft: 20,
                      marginTop: 24,
                      marginBottom: 12,
                    }}
                  >
                    {utility}
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      backgroundColor: 'transparent',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginRight: 20,
                      zIndex: -1,
                    }}
                  >
                    <Text style={styles.legendTitle}>Occupancy</Text>
                    <View style={{ flexDirection: 'row', backgroundColor: 'transparent' }}>
                      <View
                        style={{
                          backgroundColor: Colors.light.hereworksPurple500,
                          borderRadius: 2,
                          width: 24,
                          height: 2,
                          marginLeft: 12,
                        }}
                      />
                      {compareRange.end !== null && (
                        <View
                          style={{
                            backgroundColor: Colors.light.hereworksYellow800,
                            borderRadius: 2,
                            width: 24,
                            height: 2,
                            marginLeft: 4,
                          }}
                        />
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      backgroundColor: 'transparent',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginRight: 20,
                      zIndex: -1,
                    }}
                  >
                    <Text style={styles.legendTitle}>Utility</Text>
                    <View style={{ flexDirection: 'row', backgroundColor: 'transparent' }}>
                      <View
                        style={{
                          backgroundColor: Colors.light.hereworksDarkBlue,
                          borderRadius: 2,
                          width: 12,
                          height: 12,
                          marginLeft: 12,
                        }}
                      />
                      {compareRange.end !== null && (
                        <View
                          style={{
                            backgroundColor: Colors.light.hereworksYellow600,
                            borderRadius: 2,
                            width: 12,
                            height: 12,
                            marginLeft: 4,
                          }}
                        />
                      )}
                    </View>
                  </View>
                  {/* <View
                style={{
                  flexDirection: 'row',
                  backgroundColor: 'transparent',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginRight: 20,
                }}
              >
                <Text style={styles.legendTitle}>Target utility</Text>
                <View style={{ flexDirection: 'row', backgroundColor: 'transparent' }}>
                  <View
                    style={{
                      backgroundColor: Colors.light.hereworksDarkBlue200,
                      borderRadius: 2,
                      width: 12,
                      height: 12,
                      marginLeft: 12,
                    }}
                  />
                  {compareRange.end !== null && (
                    <View
                      style={{
                        backgroundColor: Colors.light.hereworksYellow300,
                        borderRadius: 2,
                        width: 12,
                        height: 12,
                        marginLeft: 4,
                      }}
                    />
                  )}
                </View>
              </View> */}
                  <BarWithLineGraphMobile
                    dates={range}
                    compareDates={compareRange}
                    metricName={metric ?? 'data-unitsconsumed'}
                    title="Occupancy vs. Utilities"
                    interval="24h"
                    compareToggled={compareRange.end !== null}
                  />
                  <ScrollView horizontal={true}>
                    <View
                      style={{
                        backgroundColor: 'transparent',
                        marginBottom: 20,
                        marginLeft: 20,
                        marginTop: 12,
                      }}
                    >
                      <SelectUtility
                        data={indexButtonData}
                        onSelect={(value, metricValue) => {
                          selectUtility(value, metricValue);
                        }}
                        compareToggled={compareRange.end !== null}
                      />
                    </View>
                  </ScrollView>
                </>
              )}
              {!vsGraphVisible && renderInsights()}
            </View>
            <Text style={styles.sectionHeading}>Water</Text>
            <View
              style={{
                borderColor: Colors.light.hereworksPrimaryGrey300,
                borderWidth: 1,
                borderRadius: 10,
                marginVertical: 24,
                marginRight: 24,
                backgroundColor: '#fff',
                zIndex: -1,
                width: '100%',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 22,
                  color: Colors.light.hereworksPrimaryGrey,
                  marginLeft: 20,
                  marginTop: 18,
                }}
              >
                Water consumption
              </Text>
              {compareDatesView()}
              {renderGraphInsightsButtons(waterGraphVisible, setWaterGraphVisible)}
              {waterGraphVisible && (
                <BarWithLineGraphMobile
                  dates={range}
                  compareDates={compareRange}
                  hideOccupancy={true}
                  metricName="happy_metric_water_raw_value"
                  title="Water"
                  interval="24h"
                  compareToggled={compareRange.end !== null}
                />
              )}
              {!waterGraphVisible && renderInsights()}
            </View>
            <Text style={styles.sectionHeading}>Electricity</Text>
            <View
              style={{
                borderColor: Colors.light.hereworksPrimaryGrey300,
                borderWidth: 1,
                borderRadius: 10,
                marginVertical: 24,
                marginRight: 24,
                backgroundColor: '#fff',
                zIndex: -1,
                width: '100%',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 22,
                  color: Colors.light.hereworksPrimaryGrey,
                  marginLeft: 20,
                  marginTop: 18,
                }}
              >
                Electricity
              </Text>
              {compareDatesView()}
              {renderGraphInsightsButtons(elecGraphVisible, setElecGraphVisible)}
              {elecGraphVisible && (
                <BarWithLineGraphMobile
                  dates={range}
                  compareDates={compareRange}
                  hideOccupancy={true}
                  metricName="data-unitsconsumed"
                  indexName="hw-processeddata-electricity"
                  title="Electricity"
                  interval="24h"
                  compareToggled={compareRange.end !== null}
                />
              )}
              {!elecGraphVisible && renderInsights()}
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  graphCard: {
    marginVertical: 40,
    marginRight: 40,
    borderRadius: 24,
    backgroundColor: 'white',
  },
  sectionHeading: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 22,
    color: Colors.light.hereworksBlack600,
    marginTop: 40,
  },
  legendTitle: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    color: Colors.light.hereworksBlack500,
    marginLeft: 20,
  },
  insightsTile: {
    backgroundColor: Colors.light.hereworksWhite100,
    borderRadius: 10,
    margin: 10,
  },
  insightsHeader: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 16,
    color: Colors.light.hereworksBlack600,
    margin: 16,
  },
  insightDesc: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    lineHeight: 18,
    color: Colors.light.hereworksBlack600,
    marginHorizontal: 16,
    marginBottom: 16,
  },
});
