/* eslint-disable import/prefer-default-export */
import Moment from 'moment';
import { GetMetricByDate } from './GetMetricByDate';

export const GetMultipleMetric = async (timeString) => {
  const timestamps = { start: Moment().subtract(timeString, 'days').startOf('day').toDate(), end: Moment().toDate() };
  try {
    const res = await Promise.all([
      // GetMetricByDate('happy_score_flat_permin', timestamps),
      GetMetricByDate('happy_group_internal_environment_local_happyscore_percent', timestamps),
      GetMetricByDate('happy_group_external_environment_local_happyscore_percent', timestamps),
      GetMetricByDate('happy_group_it_local_happyscore_percent', timestamps),
      GetMetricByDate('happy_group_energy_usage_local_happyscore_percent', timestamps),
      GetMetricByDate('happy_metric_total_building_occupancy_local_happyscore_percent', timestamps),
      GetMetricByDate('happy_group_amenities_local_happyscore_percent', timestamps),
      GetMetricByDate('happy_group_ergonomics_local_happyscore_percent', timestamps),
      GetMetricByDate('happy_group_occupant_satisfaction_local_happyscore_percent', timestamps),
    ]);
    // console.log(data.flat());
    return res;
  } catch {
    throw Error('Promise failed');
  }
};
