import { GetMetricByDate } from './GetMetricByDate';
import { GetProcessedElectricity } from './GetProcessedElectricity';

export default async function GetUtilitiesMetricByDate(range, thingId) {
  // let timestring="7d"
  try {
    const res = await Promise.all([
      GetProcessedElectricity('data-unitsconsumed', range, '24h', 'hw-processeddata-electricity', thingId),
      GetMetricByDate('happy_metric_gas_raw_value', range, '24h'),
      GetMetricByDate('happy_metric_water_raw_value', range, '24h'),
    ]);
    return res;
  } catch {
    return null;
  }
}
