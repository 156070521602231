import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

const InternetIcon24 = (props: SvgProps) => (
  <Svg width={25} height={24} fill="none" {...props}>
    <Circle cx={12.333} cy={12.001} r={10.45} stroke="#89A7CB" strokeWidth={1.5} />
    <Path
      d="M15.983 12c0 3.024-.483 5.727-1.237 7.646-.378.962-.81 1.69-1.25 2.164-.44.474-.834.64-1.163.64-.33 0-.724-.166-1.164-.64-.44-.474-.872-1.202-1.25-2.164-.754-1.92-1.236-4.622-1.236-7.645 0-3.023.482-5.726 1.236-7.646.378-.962.81-1.69 1.25-2.164.44-.473.834-.64 1.164-.64.33 0 .723.167 1.163.64.44.475.872 1.202 1.25 2.164.754 1.92 1.237 4.623 1.237 7.646ZM1.933 9.201h21M1.933 14.8h21"
      stroke="#89A7CB"
      strokeWidth={1.5}
    />
  </Svg>
);

export default InternetIcon24;
