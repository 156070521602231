import { processFontFamily } from 'expo-font';
import React from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import Colors from '../constants/Colors';

const TotalBarGraphWeb = (props) => (
  <VictoryChart
    containerComponent={
      <VictoryVoronoiContainer
        style={{
          flex: 1,
          zIndex: -1,
          width: '100%',
        }}
      />
    }
    width={518}
    height={121}
    domain={{ x: [0, 2], y: [0, Math.max(props.target * 1.5, props.total, props.compareTotal)] }}
    padding={{
      top: 10,
      left: 24,
      right: 30,
      bottom: 34,
    }}
  >
    <VictoryAxis
      disableInlineStyles
      fixLabelOverlap={true}
      dependentAxis
      tickCount={7}
      tickFormat={(t) => Math.floor(t)}
      style={{
        axis: { stroke: 'none' },
        grid: {
          stroke: Colors.light.hereworksWhite100,
          strokeWidth: 2,
          pointerEvents: 'none',
        },
      }}
      tickLabelComponent={
        <VictoryLabel
          dy={12}
          style={{
            stroke: Colors.light.hereworksBlack400,
            fontSize: 14,
            strokeWidth: 0,
            fontFamily: processFontFamily('Poppins_400Regular'),
          }}
        />
      }
    />
    <VictoryAxis
      disableInlineStyles
      fixLabelOverlap={true}
      axisLabelComponent={
        <VictoryLabel style={{ stroke: Colors.light.hereworksYellow500, fontSize: 12 }} />
      }
      style={{
        axis: { stroke: 'none' },
      }}
      tickFormat={() => ''}
    />
    {!props.compareToggled && props.total <= props.target && (
      <VictoryLine
        data={[
          { y: props.target, x: 0 },
          { y: props.target, x: 2 },
        ]}
        standalone={false}
        style={{
          data: { stroke: Colors.light.hereworksBlack300, strokeWidth: 1.5, strokeDasharray: 8 },
        }}
        labels={({ datum }) => datum.label}
        labelComponent={
          <VictoryLabel
            renderInPortal
            textAnchor="end"
            dy={-6}
            dx={-12}
            style={{
              stroke: Colors.light.hereworksBlack400,
            }}
          />
        }
      />
    )}
    {!props.compareToggled && props.total <= props.target && (
      <VictoryBar
        horizontal
        style={{
          data: { fill: Colors.light.hereworksGreen },
          labels: {
            fontSize: 12,
            strokeWidth: 0,
            fill: Colors.light.hereworksDarkBlue,
            fontFamily: processFontFamily('Roboto_400Regular'),
          },
        }}
        x={'name'}
        y={'value'}
        data={[{ name: 1, value: props.total }]}
        barWidth={24}
        labels={({ datum }) => datum.value}
        labelComponent={<VictoryTooltip />}
        cornerRadius={{ top: 2 }}
      />
    )}
    {!props.compareToggled && props.total > props.target && (
      <VictoryGroup>
        <VictoryBar
          horizontal
          style={{
            data: { fill: Colors.light.hereworksRed500 },
            labels: {
              fontSize: 12,
              strokeWidth: 0,
              fill: Colors.light.hereworksDarkBlue,
              fontFamily: processFontFamily('Roboto_400Regular'),
            },
          }}
          x={'name'}
          y={'value'}
          data={[{ name: 1, value: props.total }]}
          barWidth={24}
          labels={({ datum }) => datum.value}
          labelComponent={<VictoryTooltip />}
          cornerRadius={{ top: 2 }}
        />
        <VictoryBar
          horizontal
          style={{
            data: { fill: Colors.light.hereworksDarkBlue },
            labels: {
              fontSize: 12,
              strokeWidth: 0,
              fill: Colors.light.hereworksDarkBlue,
              fontFamily: processFontFamily('Roboto_400Regular'),
            },
          }}
          x={'name'}
          y={'value'}
          data={[{ name: 1, value: props.target }]}
          barWidth={24}
          labels={({ datum }) => datum.value}
          labelComponent={<VictoryTooltip />}
          cornerRadius={{ top: 2 }}
        />
      </VictoryGroup>
    )}
    {props.compareToggled && (
      <VictoryGroup offset={15}>
        <VictoryBar
          horizontal
          style={{ data: { fill: Colors.light.hereworksYellow500 } }}
          x={'name'}
          y={'value'}
          data={[
            { name: 0, value: 0 },
            { name: 1, value: props.compareTotal },
          ]}
          barWidth={11}
          cornerRadius={{ top: 2 }}
        />
        <VictoryBar
          horizontal
          style={{ data: { fill: Colors.light.hereworksDarkBlue } }}
          x={'name'}
          y={'value'}
          data={[{ name: 1, value: props.total }]}
          barWidth={11}
          cornerRadius={{ top: 2 }}
        />
      </VictoryGroup>
    )}
  </VictoryChart>
);

export default TotalBarGraphWeb;
