import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const RightArrow = (props: SvgProps) => (
    <Svg width='8' height='14' viewBox='0 0 8 14' fill='none' {...props}>
        <Path d='M1 13L7 7L1 1' stroke={props.color ?? '#243848'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </Svg>

);

export default RightArrow;
