/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disableay-callback-return */
import React, { useEffect, useState, useRef } from 'react';

// import { Card } from 'react-native-elements';
// eslint-disable-next-line object-curly-newline
import { Text, TouchableOpacity, View } from 'react-native';
import { Defs, LinearGradient, Stop } from 'react-native-svg';
import {
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory-native';
import * as Animatable from 'react-native-animatable';
import { FontAwesome } from '@expo/vector-icons';
import { ActivityIndicator } from 'react-native-paper';
import DropdownMetricSelect from '../components/DropdownMetricSelect';
import Colors from '../constants/Colors';

import { GetMultipleMetric } from '../requests/GetMultipleMetric';
import SmallTimeSelector from '../components/SmallTimeSelector';

const HappyDetailsGraph = (props) => {
  const DropdownButton = useRef();

  const [internalGraphData, setInternalGraphData] = useState([]);
  const [externalGraphData, setExternalGraphData] = useState([]);
  const [ITGraphData, setITGraphData] = useState([]);
  const [energyGraphData, setEnergyGraphData] = useState([]);
  const [occupancyGraphData, setOccupancyGraphData] = useState([]);
  const [satisfactionGraphData, setSatisfactionGraphData] = useState([]);
  const [amenitiesGraphData, setAmenitiesGraphData] = useState([]);
  const [ergonomicsGraphData, setErgonomicsGraphData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [interval, setInterval] = useState(props.interval);
  // eslint-disable-next-line no-unused-vars
  const [selectedTimeFrame, setTimeFrame] = useState(7);
  const [pointSize, setPointSize] = useState(2);
  const [loading, setLoading] = useState(false);

  const [visibleLines, setVisibleLines] = useState([
    'internal',
    'external',
    'occupancy',
    'energy',
    'people',
    'IT',
    'ergonomics',
    'amenities',
  ]);

  const [hidden, setHidden] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const buttondata = [
    { value: '1D', timeString: 1 },
    { value: '1W', timeString: 7 },
    { value: '1M', timeString: 30 },
    { value: '3M', timeString: 90 },
    { value: '6M', timeString: 180 },
    { value: 'Y', timeString: 365 },
  ];

  function handleSelect(listSelected) {
    setVisibleLines(listSelected);
  }
  function handleClick(value, timeString) {
    setLoading(true);
    setTimeFrame(timeString);
    setPointSize(timeString);
    setHidden(true);
  }

  function toggleList() {
    setHidden(!hidden);
  }

  interface Props {
    title: string;
  }

  class CustomFlyout extends React.Component<Props> {
    render() {
      const {
        // @ts-ignore
        x, y, datum,
      } = this.props;
      const left = x > 300 ? x - 85 : x < 80 ? x - 30 : x - 50;
      const top = y - 60;
      // console.log(this.props);
      return (
        <View
          style={{
            borderRadius: 8,
            justifyContent: 'space-evenly',

            backgroundColor: '#fff',
            left,
            top,
            width: 110,
            height: 50,
            shadowOffset: {
              width: 0,
              height: 12,
            },
            shadowOpacity: 0.1,
            shadowRadius: 40,
            shadowColor: 'black',
            elevation: 5,
            flexDirection: 'row',
          }}
        >
          <View style={{ padding: 0, justifyContent: 'center' }}>
            <Text style={{ color: Colors.light.hereworksPrimaryGrey800, fontSize: 11 }}>
              {datum.title}
            </Text>
            <Text style={{ color: Colors.light.hereworksPrimaryGrey800, fontSize: 11 }}>
              {datum.name}
            </Text>
          </View>
          <View
            style={{
              justifyContent: 'center',
            }}
          >
            <Text
              style={{
                color: datum._y > 65 ? '#025157' : '#C07E00',
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              {datum._y}
            </Text>
          </View>
        </View>
      );
    }
  }

  // ==================================================================================
  useEffect(() => {
    const getData2 = async () => {
      GetMultipleMetric(selectedTimeFrame).then((data) => {
        const externalData = [];
        const itData = [];
        const energyData = [];
        const satisfactionData = [];
        const occupancyData = [];
        const amenitiesData = [];
        const ergonomicsData = [];
        const internalData = [];

        data[0]?.map((e) => {
          let point = {};
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          const dateobj = new Date(e.key_as_string);
          const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
          point = {
            title: 'Internal',
            name: hourLabel,
            Internal: Math.floor(e.score.value * 10) / 10,
          };
          internalData.push(point);
        });
        data[1]?.map((e) => {
          let point = {};
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          const dateobj = new Date(e.key_as_string);
          const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
          point = {
            title: 'External',
            name: hourLabel,
            External: Math.floor(e.score.value * 10) / 10,
          };
          externalData.push(point);
        });
        data[2]?.map((e) => {
          let point = {};
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          const dateobj = new Date(e.key_as_string);
          const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
          point = {
            title: 'IT',
            name: hourLabel,
            IT: Math.floor(e.score.value * 10) / 10,
          };
          itData.push(point);
        });
        data[3]?.forEach((e) => {
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          const dateobj = new Date(e.key_as_string);
          const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
          const point = {
            title: 'Energy',
            name: hourLabel,
            Energy: Math.floor(e.score.value * 10) / 10,
          };
          energyData.push(point);
        });
        data[4]?.map((e) => {
          let point = {};
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          const dateobj = new Date(e.key_as_string);
          const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
          point = {
            title: 'Satisfaction',
            name: hourLabel,
            Satisfaction: Math.floor(e.score.value * 10) / 10,
          };
          satisfactionData.push(point);
        });
        data[5]?.forEach((e) => {
          let point = {};
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          const dateobj = new Date(e.key_as_string);
          const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
          point = {
            title: 'Occupancy',
            name: hourLabel,
            Occupancy: Math.floor(e.score.value * 10) / 10,
          };
          occupancyData.push(point);
        });
        data[6]?.map((e) => {
          let point = {};
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          const dateobj = new Date(e.key_as_string);
          const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
          point = {
            title: 'Amenities',
            name: hourLabel,
            Amenities: Math.floor(e.score.value * 10) / 10,
          };
          amenitiesData.push(point);
        });
        data[7]?.map((e) => {
          let point = {};
          function pad(n) {
            return n < 10 ? `0${n}` : n;
          }
          const dateobj = new Date(e.key_as_string);
          const hourLabel = `${pad(dateobj.getDate())}.${pad(dateobj.getMonth() + 1)}`;
          point = {
            title: 'Ergonomics',
            name: hourLabel,
            Ergonomics: Math.floor(e.score.value * 10) / 10,
          };
          ergonomicsData.push(point);
        });
        // });
        setInternalGraphData(internalData);
        setExternalGraphData(externalData);
        setITGraphData(itData);
        setEnergyGraphData(energyData);
        setSatisfactionGraphData(satisfactionData);
        setOccupancyGraphData(occupancyData);
        setAmenitiesGraphData(amenitiesData);
        setErgonomicsGraphData(ergonomicsData);
        setLoading(false);
        //= ===========================================================
      });
    };
    getData2();
  }, [selectedTimeFrame]);

  const chartTheme = {
    axis: {
      stroke: 'none',
      style: {
        stroke: '#696D70',
        tickLabels: {
          fill: '#696D70',
        },
        grid: { stroke: 'none' },
      },
    },
  };
  return (
    <View style={{ width: '100%', flex: 1 }}>
      <View
        style={{
          alignSelf: 'center',
          marginVertical: 10,
        }}
      >
        <SmallTimeSelector
          data={buttondata}
          onSelect={(value, timeString) => handleClick(value, timeString)}
          unselectedColor="fff"
        />
      </View>
      <TouchableOpacity
        style={{
          backgroundColor: '#fff',
          borderColor: '#C9DBE3',
          borderWidth: 2,
          padding: 3,
          borderRadius: 13,
          width: 60,
          marginLeft: 260,
          justifyContent: 'center',
          height: 40,
          alignItems: 'center',
        }}
        ref={DropdownButton}
        onPress={toggleList}
      >
        <View
          style={{
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ color: '#323E48', paddingHorizontal: 6 }}>
            {visibleLines.length === 8 ? 'All' : visibleLines.length}
          </Text>
          <FontAwesome
            size={20}
            style={{ color: '#696D70', marginBottom: 1 }}
            name={hidden ? 'angle-down' : 'angle-up'}
          />
        </View>
      </TouchableOpacity>
      <View
        style={{
          zIndex: 100,
          position: 'absolute',
          top: 115,
          left: 5,
          width: '50%',
        }}
      >
        {hidden ? null : (
          <Animatable.View animation={'fadeIn'}>
            <DropdownMetricSelect
              onSelect={(listSelected) => handleSelect(listSelected)}
              selected={visibleLines}
            />
          </Animatable.View>
        )}
      </View>
      {loading && <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size={'large'} color={Colors.light.hereworksLoading} />
        </View>}
      {!loading && <VictoryChart
        containerComponent={<VictoryVoronoiContainer labels={({ datum }) => datum} labelComponent={<CustomFlyout title='test' />} />}
        theme={chartTheme}
        height={450}
        width={400}
      >
        <VictoryAxis
          dependentAxis
          tickValues={[0, 20, 40, 60, 80, 100]}
          fixLabelOverlap={true}
          style={{
            axis: { stroke: 'none' },
            tickLabels: { fontSize: 10, padding: 10 },
            grid: { stroke: '#fff', strokeWidth: 30, pointerEvents: 'none' },
          }}
          domain={{ y: [0, 100] }}
        />
        <VictoryAxis
          fixLabelOverlap={true}
          style={{
            axis: { stroke: 'none' },
            tickLabels: { fontSize: 10, padding: 20 },
          }}
        />
        <Defs>
          <LinearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <Stop offset="0%" stopColor="#003A70" stopOpacity={0.2} />

            <Stop offset="100%" stopColor="#003A70" stopOpacity={0} />
          </LinearGradient>
        </Defs>

        <VictoryLine
          name="externalLine"
          style={{
            data: { stroke: '#7E57C5', strokeWidth: 1.5 },
          }}
          data={visibleLines.includes('external') ? externalGraphData : []}
          x="name"
          y="External"
          interpolation="catmullRom"
        />
        <VictoryScatter
          name="externalScatter"
          labels={() => ''}
          symbol={'triangleUp'}
          data={visibleLines.includes('external') ? externalGraphData : []}
          x="name"
          y="External"
          style={{
            data: { fill: '#7E57C5', strokeWidth: 1.5 },
            labels: { fill: '#025157' },
          }}
          size={({ active }) => (active ? 6 : pointSize > 7 ? 1.1 : 3)}
          labelComponent={
            <VictoryTooltip
              renderInPortal={false}
            />
          }
        />
        {/* =========================================================================== */}

        <VictoryLine
          name="internalLine"
          style={{
            data: { stroke: '#2173C4', strokeWidth: 1.5 },
          }}
          key="internal"
          data={visibleLines.includes('internal') ? internalGraphData : []}
          x="name"
          y="Internal"
          interpolation="catmullRom"
        />
        <VictoryScatter
          name="internalScatter"
          labels={() => ''}
          symbol={'diamond'}
          data={visibleLines.includes('internal') ? internalGraphData : []}
          x="name"
          y="Internal"
          style={{
            data: { fill: '#2173C4' },
          }}
          size={({ active }) => (active ? 6 : pointSize > 7 ? 1.1 : 3)}
          labelComponent={
            <VictoryTooltip
              renderInPortal={false}
            />
          }
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: '#4D7F71', strokeWidth: 1.5 },
          }}
          data={visibleLines.includes('people') ? satisfactionGraphData : []}
          x="name"
          y="Satisfaction"
          interpolation="catmullRom"
        />
        <VictoryScatter
          labels={() => ''}
          symbol={'plus'}
          data={visibleLines.includes('people') ? satisfactionGraphData : []}
          x="name"
          y="Satisfaction"
          style={{
            data: { fill: '#4D7F71' },
          }}
          size={({ active }) => (active ? 6 : pointSize > 7 ? 1.1 : 3)}
          labelComponent={
            <VictoryTooltip
              renderInPortal={false}
              // flyoutComponent={<CustomFlyout title="Satisfaction" />}
            />
          }
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: '#D6006D', strokeWidth: 1.5 },
          }}
          data={visibleLines.includes('energy') ? energyGraphData : []}
          x="name"
          y="Energy"
          interpolation="catmullRom"
        />
        <VictoryScatter
          labels={() => ''}
          symbol={'square'}
          data={visibleLines.includes('energy') ? energyGraphData : []}
          x="name"
          y="Energy"
          style={{
            data: { fill: '#D6006D' },
            labels: { fill: '#025157' },
          }}
          size={({ active }) => (active ? 6 : pointSize > 7 ? 1.1 : 3)}
          labelComponent={<VictoryTooltip renderInPortal={false} />}
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: '#F5B335', strokeWidth: 1.5 },
          }}
          data={visibleLines.includes('IT') ? ITGraphData : []}
          x="name"
          y="IT"
          interpolation="catmullRom"
        />
        <VictoryScatter
          labels={() => ''}
          symbol={'triangleDown'}
          data={visibleLines.includes('IT') ? ITGraphData : []}
          x="name"
          y="IT"
          style={{
            data: { fill: '#F5B335' },
          }}
          size={({ active }) => (active ? 6 : pointSize > 7 ? 1.1 : 3)}
          labelComponent={<VictoryTooltip renderInPortal={false} />}
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: '#003A70', strokeWidth: 1.5 },
          }}
          data={visibleLines.includes('occupancy') ? occupancyGraphData : []}
          x="name"
          y="Occupancy"
          interpolation="catmullRom"
        />
        <VictoryScatter
          labels={() => ''}
          // symbol={'triangleDown'}
          data={visibleLines.includes('occupancy') ? occupancyGraphData : []}
          x="name"
          y="Occupancy"
          style={{
            data: { fill: '#003A70' },
          }}
          size={({ active }) => (active ? 6 : pointSize > 7 ? 1.1 : 3)}
          labelComponent={
            <VictoryTooltip
              renderInPortal={false}
            />
          }
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: Colors.light.hereworksWine, strokeWidth: 1.5 },
          }}
          data={visibleLines.includes('ergonomics') ? ergonomicsGraphData : []}
          x="name"
          y="Ergonomics"
          interpolation="catmullRom"
        />
        <VictoryScatter
          labels={() => ''}
          symbol={'star'}
          data={visibleLines.includes('ergonomics') ? ergonomicsGraphData : []}
          x="name"
          y="Ergonomics"
          style={{
            data: { fill: Colors.light.hereworksWine },
          }}
          size={({ active }) => (active ? 6 : pointSize > 7 ? 1.1 : 3)}
          labelComponent={
            <VictoryTooltip
              renderInPortal={false}
            />
          }
        />
        {/* =========================================================================== */}
        <VictoryLine
          style={{
            data: { stroke: Colors.light.hereworksTeal, strokeWidth: 1.5 },
          }}
          data={visibleLines.includes('amenities') ? amenitiesGraphData : []}
          x="name"
          y="Amenities"
          interpolation="catmullRom"
        />
        <VictoryScatter
          labels={() => ''}
          symbol={'minus'}
          data={visibleLines.includes('amenities') ? amenitiesGraphData : []}
          x="name"
          y="Amenities"
          style={{
            data: { fill: Colors.light.hereworksTeal },
          }}
          size={({ active }) => (active ? 6 : pointSize > 7 ? 1.1 : 3)}
          labelComponent={
            <VictoryTooltip
              renderInPortal={false}
            />
          }
        />
        {/* =========================================================================== */}
      </VictoryChart>
      }
      {/* </Card> */}
    </View>
  );
};

export default HappyDetailsGraph;
