import * as React from 'react';
import Svg, { SvgProps, Rect } from 'react-native-svg';

const TemplateSubIcon = (props: SvgProps) => (
  <Svg width={24} height={25} fill="none" {...props}>
    <Rect x={4.5} y={5} width={6} height={6} rx={1.5} fill="#B0CBE6" stroke="#B0CBE6" />
    <Rect x={6} y={6.5} width={3} height={3} rx={0.7} fill="#B0CBE6" />
    <Rect x={13.5} y={5} width={6} height={6} rx={1.5} fill="#B0CBE6" stroke="#B0CBE6" />
    <Rect x={15} y={6.5} width={3} height={3} rx={0.7} fill="#B0CBE6" />
    <Rect x={13.5} y={14} width={6} height={6} rx={1.5} fill="#B0CBE6" stroke="#B0CBE6" />
    <Rect x={15} y={15.5} width={3} height={3} rx={0.7} fill="#B0CBE6" />
    <Rect x={4.5} y={14} width={6} height={6} rx={1.5} fill="#B0CBE6" stroke="#B0CBE6" />
    <Rect x={6} y={15.5} width={3} height={3} rx={0.7} fill="#B0CBE6" />
  </Svg>
);

export default TemplateSubIcon;
