import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const RightArrow24 = (props: SvgProps) => (
  <Svg
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <Path
      stroke="#0058AD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m12.5 17.75 5.25-5.25m0 0L12.5 7.25m5.25 5.25h-12"
    />
  </Svg>
);
export default RightArrow24;
