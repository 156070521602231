import React, { useState } from 'react';
import * as Analytics from 'expo-firebase-analytics';
import {
  Image, StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import Moment from 'moment';
import Colors from '../../constants/Colors';
import EditIcon24 from '../24pxIcons/EditIcon24';
import NoteIcon from '../NoteIcon';
import EditUser from './EditUser';
import CheckBox from '../16pxIcons/CheckBox';

export default function UserRowWeb(props) {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selected, setSelected] = useState(false);

  const showNoteAndEdit = false;

  const generateColor = (length) => {
    const randomColor = Math.floor((1 / length) * 16777215)
      .toString(16)
      .padStart(6, '0');
    return `#${randomColor}`;
  };

  const renderUserIcon = (imageSource, facilityManager = false) => {
    if (imageSource === undefined) {
      return (
        <View
          style={{
            width: 24,
            height: 24,
            borderRadius: 12,
            marginRight: 8,
            backgroundColor: generateColor(props?.user?.FullName?.length),
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              color: '#fff',
              fontFamily: 'Roboto_400Regular',
              fontSize: 8,
            }}
          >
            {props?.user?.FullName
              ? props?.user?.FullName?.split(' ').map((word, index) => {
                if (index > 1) {
                  return '';
                }
                return word.charAt(0).toUpperCase();
              })
              : `${props?.user?.GivenName ?? ''} ${props?.user?.FamiyName ?? ''}`}
          </Text>
        </View>
      );
    }
    return (
      <View
        style={{
          width: 48,
          height: 48,
          backgroundColor: facilityManager ? '#fff' : 'transparent',
          borderWidth: 1.5,
          borderColor: facilityManager ? Colors.light.hereworksBlue500 : 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 24,
          marginLeft: -14,
        }}
      >
        <Image
          style={styles.userPic}
          source={{
            uri: imageSource,
          }}
        />
      </View>
    );
  };

  return (
    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
      <EditUser
        editModalVisible={editModalVisible}
        user={props.user}
        imageSource={props.user.icon}
        facilityManager={props.user.facilityManager}
        setEditModalVisible={setEditModalVisible}
      />
      <View
        style={{
          marginLeft: 22,
          flex: 1,
          flexDirection: 'row',
          marginVertical: 8,
          alignItems: 'center',
        }}
      >
        {props.selectable && (
          <TouchableOpacity
            onPress={() => setSelected(!selected)}
            style={{ marginRight: 30, justifyContent: 'center' }}
          >
            <CheckBox
              checkcolor={selected ? null : '#fff'}
              color={selected ? null : Colors.light.hereworksBlue100}
            />
          </TouchableOpacity>
        )}
        {renderUserIcon(props.user.icon, props.user.facilityManager)}
        <View style={{ marginLeft: 4, flex: 1 }}>
          <Text numberOfLines={1} style={styles.nameStyle}>{props.user.FullName}</Text>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={[styles.userDetails, { marginLeft: 34 }]}>{props.user.Email}</Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={[styles.userDetails, { alignSelf: 'flex-end', marginLeft: 34 }]}>
          {Moment(props.user.LastAuthenticatedOn).format('ll')}
        </Text>
      </View>
      {showNoteAndEdit && (
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: 'row',
              marginRight: 8,
              width: 96,
              alignSelf: 'flex-end',
            }}
          >
            <>
              <TouchableOpacity
                onPress={() => Analytics.logEvent('button_press', {
                  screen: 'UserMgmt',
                  name: 'Note',
                })
                }
              >
                <NoteIcon color={Colors.light.hereworksBlue500} />
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginLeft: 12 }}
                onPress={() => {
                  setEditModalVisible(true);
                  Analytics.logEvent('button_press', {
                    screen: 'UserMgmt',
                    name: 'Edit',
                  });
                }}
              >
                <EditIcon24 />
              </TouchableOpacity>
            </>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  userDetails: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
  },
  roleText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
    marginLeft: 10,
    color: Colors.light.hereworksBlack400,
  },
  nameStyle: {
    marginLeft: 10,
    fontFamily: 'Poppins_500Medium',
    fontSize: 16,
    color: Colors.light.hereworksBlack600,
  },
  userPic: {
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: 'hidden',
  },
});
