import * as React from 'react';
import { Component } from 'react';
import {
  LayoutAnimation, StyleSheet, View, ScrollView, UIManager, Platform,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import HomeIconDesktop from './24pxIcons/HomeIconDesktop';
import ExpandableItemComponent from './ExpandableItemComponent';
import FlagIcon from './FlagIcon';
import SmileIcon from './SmileIcon';
import CheckCircle from './CheckCircle';
import GridIcon from './24pxIcons/GridIcon';
import MapIcon from './MapIcon';
import CoffeeIcon from './CoffeeIcon';
import BoltIconDesktop from './24pxIcons/BoltIconDesktop';
import ChairIconDesktop from './24pxIcons/ChairIconDesktop';
import MonitorIcon from './MonitorIcon';
import SunIcon from './SunIcon';
import SolveIcon24 from './24pxIcons/SolveIcon24';
import PeopleIcon24 from './24pxIcons/PeopleIcon24';
import OccupancyIcon from './24pxIcons/OccupancyIcon';

interface Props {
  navObj: any;
  initialState: any;
}

interface State {
  listDataSource: any;
  selected: string;
}

export default class ExpandableDrawer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let isDev = false;
    this.state = { listDataSource: [], selected: '' };
    AsyncStorage.getItem('environment').then((env) => {
      isDev = env === 'dev';
      if (Platform.OS === 'android') {
        UIManager.setLayoutAnimationEnabledExperimental(true);
      }
      this.setState({ listDataSource: isDev ? DEV_CONTENT : CONTENT, selected: '' });
    });
  }

  updateLayout = (index: number) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    const array = [...this.state.listDataSource];
    array[index].isExpanded = !array[index].isExpanded;
    this.setState(() => ({
      listDataSource: array,
    }));
  };

  updateCategory = (selected: string) => {
    this.setState(() => ({
      selected,
    }));
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView style={{}}>
          {this.state.listDataSource.map((item: any, key: number) => (
            <ExpandableItemComponent
              key={key}
              onClickFunction={this.updateLayout.bind(this, key)}
              item={item}
              navObj={this.props.navObj}
              initialState={this.props.initialState}
              updateCategory={this.updateCategory.bind(this, item.category_name)}
              selectedCategory={this.state.selected}
            />
          ))}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    minHeight: 400,
  },
});

const DEV_CONTENT = [
  {
    isExpanded: true,
    category_name: 'MANAGEMENT',
    subcategory: [
      {
        id: 3,
        val: 'Home',
        icon: <HomeIconDesktop />,
        selectedIcon: <HomeIconDesktop color={'#fff'} />,
      },
      {
        id: 13,
        val: 'Tickets',
        icon: <FlagIcon />,
        selectedIcon: <FlagIcon color={'#fff'} />,
      },
      {
        id: 14,
        val: 'AssetManagement',
        title: 'Asset management',
        icon: <GridIcon />,
        selectedIcon: <GridIcon color={'#fff'} />,
      },
      {
        id: 15,
        val: 'Analytics',
        title: 'Solve analytics',
        icon: <SolveIcon24 />,
        selectedIcon: <SolveIcon24 color={'#fff'} />,
      },
      {
        id: 16,
        val: 'UserManagement',
        title: 'User management',
        icon: <PeopleIcon24 />,
        selectedIcon: <PeopleIcon24 color={'#fff'} />,
      },
    ],
  },
  {
    isExpanded: true,
    category_name: 'BUILDING',
    subcategory: [
      {
        id: 7,
        val: 'Map',
        icon: <MapIcon />,
        selectedIcon: <MapIcon color={'#fff'} />,
      },
      {
        id: 8,
        val: 'BuildingOccupancy',
        title: 'Building Occupancy',
        icon: <OccupancyIcon />,
        selectedIcon: <OccupancyIcon color={'#fff'} />,
      },
      {
        id: 9,
        val: 'Energy',
        icon: <BoltIconDesktop />,
        selectedIcon: <BoltIconDesktop color={'#fff'} />,
      },
      {
        id: 10,
        val: 'IT',
        icon: <MonitorIcon />,
        selectedIcon: <MonitorIcon color={'#fff'} />,
      },
      {
        id: 11,
        val: 'ExternalEnvironment',
        title: 'External environment',
        icon: <SunIcon />,
        selectedIcon: <SunIcon color={'#fff'} />,
      },
      {
        id: 12,
        val: 'InternalEnvironment',
        title: 'Internal environment',
        icon: <CoffeeIcon />,
        selectedIcon: <CoffeeIcon color={'#fff'} />,
      },
      {
        id: 4,
        val: 'OccupantSatisfaction',
        title: 'Occupant Satisfaction',
        icon: <SmileIcon />,
        selectedIcon: <SmileIcon color={'#fff'} />,
      },
      {
        id: 5,
        val: 'Amenities',
        icon: <CheckCircle />,
        selectedIcon: <CheckCircle color={'#fff'} />,
      },
      {
        id: 6,
        val: 'Ergonomics',
        icon: <ChairIconDesktop />,
        selectedIcon: <ChairIconDesktop color={'#fff'} />,
      },
    ],
  },
];

const CONTENT = [
  {
    isExpanded: true,
    category_name: 'MANAGEMENT',
    subcategory: [
      {
        id: 3,
        val: 'Home',
        icon: <HomeIconDesktop />,
        selectedIcon: <HomeIconDesktop color={'#fff'} />,
      },
      {
        id: 13,
        val: 'Tickets',
        icon: <FlagIcon />,
        selectedIcon: <FlagIcon color={'#fff'} />,
      },
      {
        id: 14,
        val: 'AssetManagement',
        title: 'Asset management',
        icon: <GridIcon />,
        selectedIcon: <GridIcon color={'#fff'} />,
      },
      {
        id: 15,
        val: 'Analytics',
        title: 'Solve analytics',
        icon: <SolveIcon24 />,
        selectedIcon: <SolveIcon24 color={'#fff'} />,
      },
      {
        id: 16,
        val: 'UserManagement',
        title: 'User management',
        icon: <PeopleIcon24 />,
        selectedIcon: <PeopleIcon24 color={'#fff'} />,
      },
    ],
  },
  {
    isExpanded: true,
    category_name: 'BUILDING',
    subcategory: [
      {
        id: 7,
        val: 'Map',
        icon: <MapIcon />,
        selectedIcon: <MapIcon color={'#fff'} />,
      },
      {
        id: 9,
        val: 'Energy',
        icon: <BoltIconDesktop />,
        selectedIcon: <BoltIconDesktop color={'#fff'} />,
      },
      {
        id: 8,
        val: 'BuildingOccupancy',
        title: 'Building Occupancy',
        icon: <OccupancyIcon />,
        selectedIcon: <OccupancyIcon color={'#fff'} />,
      },
      {
        id: 10,
        val: 'IT',
        icon: <MonitorIcon />,
        selectedIcon: <MonitorIcon color={'#fff'} />,
      },
      {
        id: 11,
        val: 'ExternalEnvironment',
        title: 'External environment',
        icon: <SunIcon />,
        selectedIcon: <SunIcon color={'#fff'} />,
      },
      {
        id: 12,
        val: 'InternalEnvironment',
        title: 'Internal environment',
        icon: <CoffeeIcon />,
        selectedIcon: <CoffeeIcon color={'#fff'} />,
      },
      {
        id: 4,
        val: 'OccupantSatisfaction',
        title: 'Occupant Satisfaction',
        icon: <SmileIcon />,
        selectedIcon: <SmileIcon color={'#fff'} />,
      },
      {
        id: 5,
        val: 'Amenities',
        icon: <CheckCircle />,
        selectedIcon: <CheckCircle color={'#fff'} />,
      },
      {
        id: 6,
        val: 'Ergonomics',
        icon: <ChairIconDesktop />,
        selectedIcon: <ChairIconDesktop color={'#fff'} />,
      },
    ],
  },
];
