import React, { useState } from 'react';
import { Text } from 'react-native';
import Moment from 'moment';

export default function TimerFrom({ startDate, style }: { startDate: string; style: any }) {
  const [counter, setCounter] = useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 500);

    return () => clearInterval(interval);
  }, [counter]);

  return (
    <Text style={style}>
      {Moment.duration(Moment().diff(Moment(new Date(startDate)))).asHours() < 24
        ? Moment.utc(Moment().diff(Moment(new Date(startDate)))).format('HH:mm:ss')
        : `${Moment().diff(Moment(new Date(startDate)), 'days')} days`}
    </Text>
  );
}
