import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const DownArrow = (props: SvgProps) => (
  <Svg width={12} height={14} fill="none" {...props}>
    <Path
      d="M6.53 13.53a.75.75 0 0 1-1.06 0L.697 8.757a.75.75 0 1 1 1.06-1.06L6 11.939l4.243-4.242a.75.75 0 0 1 1.06 1.06L6.53 13.53ZM5.25 1a.75.75 0 0 1 1.5 0h-1.5Zm0 12V1h1.5v12h-1.5Z"
      fill={props.color ?? '#323E48'}
    />
  </Svg>
);

export default DownArrow;
