import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

interface Props extends SvgProps {
  add?: boolean;
}

const AssigneeIcon = (props: Props) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Circle
      cx={11}
      cy={11}
      r={9.3}
      fill="#E6EEF7"
      stroke="#548FC8"
      strokeWidth={1.4}
      strokeDasharray="2.8 2.8"
    />
    <Circle cx={11} cy={8} r={1.5} fill="#548FC8" stroke="#548FC8" />
    <Path d="M15 15a4 4 0 0 0-8 0" stroke="#548FC8" strokeWidth={1.4} />
    {props.add && (
      <>
        <Circle cx={17} cy={17} r={5.5} fill="#fff" stroke="#003A70" />
        <Path fill="#121B23" stroke="#003A70" strokeWidth={0.5} d="M16.75 14.75h.5v4.5h-.5z" />
        <Path fill="#121B23" stroke="#003A70" strokeWidth={0.5} d="M19.25 16.75v.5h-4.5v-.5z" />
      </>
    )}
  </Svg>
);

export default AssigneeIcon;
