import { GetMetricByDate } from './GetMetricByDate';

export default async function GetSensorData(range, thingId) {
  interface MetricProps {
    value: string,
    table: string,
    title: string,
    fullTitle?: string,
    unit: string,
    scoreValue: string,
  }

  const metrics: MetricProps[] = [
    {
      table: 'hw-rawdata-awairscore', value: 'data-score', title: 'Score', unit: '', fullTitle: 'Score', scoreValue: 'happy_metric_internal_air_co2_local_happyscore_percent',
    },
    {
      table: 'hw-rawdata-co2', value: 'data-co2', title: 'CO2', unit: 'ppm', fullTitle: 'CO₂', scoreValue: 'happy_metric_internal_air_co2_local_happyscore_percent',
    },
    {
      table: 'hw-rawdata-lux', value: 'data-lux', title: 'Light', unit: 'lx', scoreValue: 'happy_metric_internal_light_control_local_happyscore_percent',
    },
    {
      table: 'hw-rawdata-spl', value: 'data-spl', title: 'Noise', unit: 'dB', scoreValue: 'happy_metric_internal_acoustics_spl_local_happyscore_percent',
    },
    {
      table: 'hw-rawdata-pm25', value: 'data-pm25', title: 'PM2.5', unit: 'µg/m3', scoreValue: 'happy_metric_internal_air_pm25_local_happyscore_percent',
    },
    {
      table: 'hw-rawdata-voc', value: 'data-voc', title: 'TVOC', unit: 'ppb', scoreValue: null,
    },
    {
      table: 'hw-rawdata-temp', value: 'data-temperature', title: 'Temperature', unit: '°C', scoreValue: 'happy_metric_office_temp_local_happyscore_percent',
    },
    {
      table: 'hw-rawdata-humidity', value: 'data-humidity', title: 'Humidity', unit: '%', scoreValue: 'happy_metric_office_humidity_local_happyscore_percent',
    },
  ];
  const data = { timestamp: null };
  await Promise.all(metrics.map(async (metric) => {
    const result = await GetMetricByDate(metric.value, range, '5m', metric.table, thingId);
    data[metric.value] = result?.length > 0
      ? result[result.length - 1]?.score?.value ?? 0 : 0;
    data.timestamp = result?.length > 0 ? result[result.length - 1]?.key_as_string : '-';
  }));
  return data;
}
