import * as React from 'react';
import Svg, {
  SvgProps, G, Path,
} from 'react-native-svg';

const SLATimeIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <G fill="#121B23">
      <Path d="M13 6.833h-2v6h6v-2h-4v-4Z" />
      <Path
        d="M12 4a8 8 0 0 0-8 8H2C2 6.477 6.477 2 12 2s10 4.477 10 10h-2a8 8 0 0 0-8-8Z"
      />
      <Path d="M7.845 20.313a.813.813 0 0 0-.048-.29.589.589 0 0 0-.176-.242 1.61 1.61 0 0 0-.36-.22 4.458 4.458 0 0 0-.611-.233 8.236 8.236 0 0 1-.822-.316 3.539 3.539 0 0 1-.69-.418 1.827 1.827 0 0 1-.479-.553 1.526 1.526 0 0 1-.171-.739c0-.272.06-.518.18-.738.12-.22.289-.407.505-.563.217-.158.472-.278.765-.36.293-.085.615-.127.967-.127.469 0 .88.083 1.235.25.357.164.635.396.835.695.199.295.298.64.298 1.032H7.85a.925.925 0 0 0-.11-.457.738.738 0 0 0-.325-.316 1.145 1.145 0 0 0-.541-.114c-.21 0-.388.032-.532.096a.725.725 0 0 0-.32.255.64.64 0 0 0-.106.356c0 .103.026.196.08.281.055.082.134.16.236.233.106.07.235.138.387.202.152.065.327.126.523.185.343.108.646.228.91.36.266.132.49.282.672.448.182.168.318.357.409.567.094.211.14.45.14.717 0 .284-.055.536-.167.756-.108.22-.266.407-.474.562a2.209 2.209 0 0 1-.738.347c-.288.08-.608.119-.963.119-.322 0-.64-.041-.953-.123a2.819 2.819 0 0 1-.853-.387 1.98 1.98 0 0 1-.606-.66 1.946 1.946 0 0 1-.225-.957h1.433c0 .193.028.357.084.492a.8.8 0 0 0 .237.32c.105.083.233.142.382.177.15.035.317.052.501.052.214 0 .387-.029.519-.087a.657.657 0 0 0 .299-.25.661.661 0 0 0 .096-.352Zm6.447.58V22h-3.23v-1.107h3.23Zm-2.742-5.291V22h-1.433v-6.398h1.433Zm6.192 1.217L16.089 22H14.56l2.369-6.398h.966l-.153 1.217ZM19.112 22l-1.656-5.181-.171-1.217h.98L20.642 22h-1.53Zm-.07-2.382v1.108h-3.335v-1.108h3.335Z" />
    </G>
  </Svg>
);

export default SLATimeIcon;
