import AsyncStorage from '@react-native-async-storage/async-storage';

const reportIssue = async (
  bid: string,
  issues: string[],
  email: string,
  name: string,
  issueprototypeid: string,
  image: string = null,
  text: string = null,
) => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const deviceid = 'unknown';
    const body = {
      bid,
      deviceid,
      issues,
      // pnt: tokenValue,
      pnttype: 'PushNotificationIOS',
      email,
      name,
      scansrc: bid.split('?ss=')[1],
      issueprototypeid,
    };
    if (image) {
      // @ts-ignore
      body.image = image;
    }
    if (text) {
      // @ts-ignore
      body.text = text;
    }
    return fetch(`https://${apiDomain}/button/reporterapp/issue`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        clientid: clientName,
        accesskey: 'b7d3137e8e7cfb37e4607962fb7b153a',
        deviceuuid: deviceid,
      },
      body: JSON.stringify(body),
    }).then((response) => response?.status === 200).catch(() => false);
  } catch (error) {
    return false;
  }
};

export default reportIssue;
