import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const FlagIcon = (props: SvgProps) => (
  <Svg width={17} height={16} fill="none" {...props}>
    <Path
      d="M4.5 2v12V2Zm0 .667V8l7.447-2a.667.667 0 0 0 0-1.267L4.5 2.667Z"
      stroke={props.color ?? '#3379BD'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default FlagIcon;
