import React, { useEffect, useState } from 'react';
import {
  Animated, Easing, StyleSheet, Text, View,
} from 'react-native';
import Colors from '../../../constants/Colors';

interface MetricScoreCardItemProps {
  metric: { scoreValue: string, title: string },
  showComparison: boolean,
  score: number,
  scoreCompared: number,
}

const MetricScoreCardItem = ({
  metric, score, scoreCompared, showComparison,
}: MetricScoreCardItemProps) => {
  const [barWidth] = useState(new Animated.Value(0));
  const [barComparedWidth] = useState(new Animated.Value(0));

  const animation = (isCompared: boolean = false) => Animated.timing(
    isCompared ? barComparedWidth : barWidth,
    {
      duration: (isCompared ? scoreCompared : score) * 10,
      useNativeDriver: true,
      easing: Easing.ease,
      toValue: (isCompared ? scoreCompared : score) * 0.01
        * (styles.container.width - styles.container.padding * 2),
    },
  ).start();

  useEffect(() => animation(), [score]);
  useEffect(() => animation(true), [scoreCompared]);

  return <View style={styles.container}>
    <View style={styles.textRow}>
      <Text style={styles.title}>{metric.title}</Text>
      <View style={styles.subContainer}>
        <Text style={styles.score}>{score ?? '-'}</Text>
        {showComparison && <Text style={styles.scoreCompared}>{scoreCompared ?? '-'}</Text>}
      </View>
    </View>
    <View style={styles.barTrack}>
      <Animated.View style={[styles.bar, { width: barWidth }]} />
    </View>
    {showComparison && <View style={[styles.barTrack, styles.barTrackCompared]}>
      <Animated.View style={[styles.bar, styles.barCompared, { width: barComparedWidth }]} />
    </View>}
  </View>;
};

const styles = StyleSheet.create({
  container: {
    width: 153,
    padding: 8,
    backgroundColor: '#FFF',
    borderRadius: 10,
    marginTop: 8,
  },
  subContainer: {
    flexDirection: 'row',
  },
  textRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 12,
    lineHeight: 18,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksBlack600,
    marginBottom: 4,
  },
  score: {
    fontSize: 14,
    lineHeight: 14,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksDarkBlue,
  },
  scoreCompared: {
    fontSize: 14,
    lineHeight: 14,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksYellow600,
    marginStart: 4,
  },
  barTrack: {
    backgroundColor: Colors.light.hereworksBlue50,
    height: 8,
    borderRadius: 12,
    marginTop: 4,
  },
  barTrackCompared: {
    backgroundColor: Colors.light.hereworksYellow100,
  },
  bar: {
    backgroundColor: Colors.light.hereworksDarkBlue,
    height: '100%',
    borderRadius: 12,
  },
  barCompared: {
    backgroundColor: Colors.light.hereworksYellow600,
  },
});

export default MetricScoreCardItem;
