import { useNavigation } from '@react-navigation/native';
import Moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import {
  View, StyleSheet, Animated, Easing, Dimensions,
} from 'react-native';
import Colors from '../../constants/Colors';
import HappyDetailsGraphWeb, { PointProps, happyScoreMetrics } from '../../graphs/HappyDetailsGraphWeb';
import DateComponent from '../DateComponent';
import HappyScoreIndicator from '../HappyScoreIndicator';
import SectionHeader from '../SectionHeader';
import TopSectionInsights from '../TopSectionInsights';
import HomeGraphLineSelector from './HomeGraphLineSelector';
import TileSelector from './TileSelector';
import getClientDetails from '../../requests/GetClientDetails';
import { GetMetricByDateWithLive } from '../../requests/GetMetricByDateWithLive';

const defaultAnimationDuration: number = 300;

const animation = (
  animatedValue,
  toValue,
  duration = defaultAnimationDuration,
): Animated.CompositeAnimation => Animated.timing(animatedValue, {
  duration,
  useNativeDriver: true,
  easing: Easing.ease,
  toValue,
});

const linkedScreens = [
  'InternalEnvironment',
  'ExternalEnvironment',
  'IT',
  'Energy',
  'BuildingOccupancy',
  'Amenities',
  'Ergonomics',
  'OccupantSatisfaction',
];

const HomeScreenTopSection = () => {
  const [tileData, setTileData] = useState<{
    title: string,
    score: number,
    scoreCompared: number,
    average: number,
    todayScore: number,
    yesterdayScore: number,
    description: string,
    index: number,
    lineShown: boolean,
  }[]>([
    {
      title: 'Building Happy score',
      score: null,
      scoreCompared: null,
      average: null,
      todayScore: null,
      yesterdayScore: null,
      description: '',
      index: 0,
      lineShown: true,
    },
    {
      title: 'Internal environment',
      score: null,
      scoreCompared: null,
      average: null,
      todayScore: null,
      yesterdayScore: null,
      description: '',
      index: 1,
      lineShown: false,
    },
    {
      title: 'External environment',
      score: null,
      scoreCompared: null,
      average: null,
      todayScore: null,
      yesterdayScore: null,
      description: '',
      index: 2,
      lineShown: false,
    },
    {
      title: 'IT',
      score: null,
      scoreCompared: null,
      average: null,
      todayScore: null,
      yesterdayScore: null,
      description: '',
      index: 3,
      lineShown: false,
    },
    {
      title: 'Energy',
      score: null,
      scoreCompared: null,
      average: null,
      todayScore: null,
      yesterdayScore: null,
      description: '',
      index: 4,
      lineShown: false,
    },
    {
      title: 'Occupancy',
      score: null,
      scoreCompared: null,
      average: null,
      todayScore: null,
      yesterdayScore: null,
      description: '',
      index: 5,
      lineShown: false,
    },
    {
      title: 'Amenities',
      score: null,
      scoreCompared: null,
      average: null,
      todayScore: null,
      yesterdayScore: null,
      description: '',
      index: 6,
      lineShown: false,
    },
    {
      title: 'Ergonomics',
      score: null,
      scoreCompared: null,
      average: null,
      todayScore: null,
      yesterdayScore: null,
      description: '',
      index: 7,
      lineShown: false,
    },
    {
      title: 'Satisfaction',
      score: null,
      scoreCompared: null,
      average: null,
      todayScore: null,
      yesterdayScore: null,
      description: '',
      index: 8,
      lineShown: false,
    },
  ]);

  const [graphData, setGraphData] = useState([[], [], [], [], [], [], [], [], []]);
  const [graphCompareData, setGraphCompareData] = useState([[], [], [], [], [], [], [], [], []]);
  const [topSectionDefaultShown, setTopSectionDefaultShown] = useState(true);
  const [defaultContentShown, setDefaultContentShown] = useState(topSectionDefaultShown);
  const [selectedTileIndex, setSelectedTileIndex] = useState(0);
  const [happyScoreAnimatedValue] = useState(new Animated.Value(0));
  const [happyScoreComparedAnimatedValue] = useState(new Animated.Value(0));
  const [topSectionHeight] = useState(new Animated.Value(327));
  const [happyScoreIndicatorSize] = useState(new Animated.Value(1));
  const [happyScoreIndicatorOffset] = useState(new Animated.Value(-60));
  const [topSectionMarginStart] = useState(new Animated.Value(500));
  const [sectionHeaderPaddingStart] = useState(new Animated.Value(172));
  const [graphContainerHeight] = useState(new Animated.Value(0));
  const [graphContainerMarginTop] = useState(new Animated.Value(0));
  const [graphContainerOpacity] = useState(new Animated.Value(0));
  const [range, setRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: Moment().subtract(6, 'days').startOf('day').toDate(), end: new Date() });
  const [compareRange, setCompareRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: Moment().subtract(6, 'days').startOf('day').toDate(), end: null });

  const animateHappyScoreIndicator = () => Animated.parallel([
    animation(happyScoreAnimatedValue, tileData[selectedTileIndex]?.score ?? 0, 1000),
    animation(
      happyScoreComparedAnimatedValue,
      tileData[selectedTileIndex]?.scoreCompared ?? 0,
      1000,
    ),
  ]).start();

  useEffect(() => {
    getClientDetails().then((response) => {
      Object.keys(response?.clientDetails?.happy_score_insights ?? []).forEach((item) => {
        const id = item.split('_')[0];
        const newTileData = tileData;
        if (id.includes('happy')) {
          newTileData[0].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('internal')) {
          newTileData[1].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('external')) {
          newTileData[2].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id === 'it') {
          newTileData[3].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('energy')) {
          newTileData[4].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('building')) {
          newTileData[5].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('amen')) {
          newTileData[6].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('ergo')) {
          newTileData[7].description = response.clientDetails.happy_score_insights[item].text;
        } else if (id.includes('occupant')) {
          newTileData[8].description = response.clientDetails.happy_score_insights[item].text;
        }
        setTileData(newTileData);
      });
    });
  }, []);

  const updateGraphData = (start: Date, end: Date, compare: boolean = false) => {
    if (compare && !compareRange.end) return;
    const newGraphData: object[][] = [[], [], [], [], [], [], [], [], []];
    if (compare) setGraphCompareData(newGraphData);
    else setGraphData(newGraphData);
    const newTileData = [...tileData];
    happyScoreMetrics.forEach((metric: string, metricIndex: number) => {
      GetMetricByDateWithLive(metric, { start, end })
        ?.then((data: {
          buckets: { key_as_string: string, score: { value: number } }[], source: number,
        }) => {
          const values: number[] = data?.buckets?.map(
            (bucket) => bucket?.score?.value,
          ) ?? [];
          const average: number = values.reduce((prev, next) => prev + next, 0) / values.length;
          newTileData[metricIndex][compare ? 'scoreCompared' : 'average'] = Number.isFinite(average) ? average : null;
          if (!newTileData[metricIndex]?.score) {
            const newScore: number = values[values.length - 1];
            newTileData[metricIndex].score = Number.isFinite(data?.source)
              ? Math.round(data?.source) : null;
            newTileData[metricIndex].todayScore = Number.isFinite(newScore) ? newScore : null;
          }
          if (!newTileData[metricIndex].yesterdayScore) {
            const newScore: number = data?.buckets?.filter((e) => new Date(e.key_as_string).toDateString() === Moment().subtract(1, 'day').toDate().toDateString())[0]?.score.value;
            newTileData[metricIndex].yesterdayScore = Number.isFinite(newScore) ? newScore : null;
          }
          setTileData(newTileData);
          animateHappyScoreIndicator();
          data?.buckets?.forEach((e, index: number) => {
            const point: PointProps = {
              value: Math.floor(e?.score.value),
              date: e.key_as_string,
              index,
            };
            newGraphData[metricIndex].push(point);
          });
          if (compare) setGraphCompareData([...newGraphData]);
          else setGraphData([...newGraphData]);
        });
    });
  };

  useMemo(() => {
    updateGraphData(range.start, range.end, false);
  }, [new Date(range.start)?.toDateString(), new Date(range.end)?.toDateString()]);
  useMemo(() => {
    updateGraphData(compareRange.start, compareRange.end, true);
  }, [new Date(compareRange.start)?.toDateString(), new Date(compareRange.end)?.toDateString()]);

  useEffect(animateHappyScoreIndicator, [selectedTileIndex]);

  useEffect(() => {
    const topSectionHeightAnimation: Animated.CompositeAnimation = animation(
      topSectionHeight,
      topSectionDefaultShown ? 327 : 71,
    );
    const happyScoreIndicatorSizeAnimation: Animated.CompositeAnimation = animation(
      happyScoreIndicatorSize,
      topSectionDefaultShown ? 1 : 0.59,
    );
    const happyScoreIndicatorOffsetAnimation: Animated.CompositeAnimation = animation(
      happyScoreIndicatorOffset,
      topSectionDefaultShown ? -60 : -80,
    );
    const topSectionMarginStartAnimation: Animated.CompositeAnimation = animation(
      topSectionMarginStart,
      topSectionDefaultShown
        ? 500 * Math.min(1, (Dimensions.get('window').width - 252) / 1188)
        : 82,
    );
    const sectionHeaderPaddingStartAnimation: Animated.CompositeAnimation = animation(
      sectionHeaderPaddingStart,
      topSectionDefaultShown ? 172 : 102,
    );
    const graphContainerHeightAnimation: Animated.CompositeAnimation = animation(
      graphContainerHeight,
      // eslint-disable-next-line no-nested-ternary
      topSectionDefaultShown ? 0 : selectedTileIndex === 0 ? 445 : 380,
    );
    const graphContainerMarginTopAnimation: Animated.CompositeAnimation = animation(
      graphContainerMarginTop,
      topSectionDefaultShown ? 0 : 16,
    );
    const graphContainerOpacityAnimation: Animated.CompositeAnimation = animation(
      graphContainerOpacity,
      topSectionDefaultShown ? 0 : 1,
    );
    Animated.parallel([
      topSectionHeightAnimation,
      happyScoreIndicatorSizeAnimation,
      happyScoreIndicatorOffsetAnimation,
      topSectionMarginStartAnimation,
      sectionHeaderPaddingStartAnimation,
      graphContainerHeightAnimation,
      graphContainerMarginTopAnimation,
      graphContainerOpacityAnimation,
    ]).start();
    const timeout = setTimeout(() => {
      setDefaultContentShown(topSectionDefaultShown);
      clearTimeout(timeout);
    }, defaultAnimationDuration);
  }, [topSectionDefaultShown, selectedTileIndex]);

  const navigation = useNavigation();

  return (
    <>
      <View style={[styles.contentWrapper, !defaultContentShown && styles.superImposition]}>
        <Animated.View
          style={[
            styles.contentContainer,
            { height: topSectionHeight, marginStart: topSectionMarginStart },
          ]}
        >
          <SectionHeader
            defaultShown={topSectionDefaultShown}
            setDefaultShown={setTopSectionDefaultShown}
            title1={selectedTileIndex === 0 ? 'Happy score' : tileData[selectedTileIndex].title}
            button1="INSIGHTS"
            button2="GRAPH"
            showLinkArrow={selectedTileIndex !== 0}
            linkAction={() => selectedTileIndex > 0
              // @ts-ignore
              && navigation.navigate(linkedScreens[selectedTileIndex - 1])
            }
            style={[styles.sectionHeader, { paddingStart: sectionHeaderPaddingStart }]}
          />
          {defaultContentShown && topSectionDefaultShown && (
            <TopSectionInsights {...tileData[selectedTileIndex]} />
          )}
          <HappyScoreIndicator
            style={[
              styles.happyScoreIndicator,
              {
                transform: [{ scale: happyScoreIndicatorSize }],
                top: happyScoreIndicatorOffset,
                start: happyScoreIndicatorOffset,
              },
            ]}
            showComparison={!!compareRange.end}
            gradientStartColor="#2BC3A5"
            gradientEndColor="#85E1CF"
            textColor={Colors.light.hereworksExtraDarkTeal}
            happyScoreAnimatedValue={happyScoreAnimatedValue}
            happyScoreComparedAnimatedValue={happyScoreComparedAnimatedValue}
            noValue={!tileData[selectedTileIndex]?.score}
            noValueCompared={!tileData[selectedTileIndex]?.scoreCompared}
          />
        </Animated.View>
        <Animated.View
          style={[
            styles.graphContainer,
            {
              height: graphContainerHeight,
              marginTop: graphContainerMarginTop,
              opacity: graphContainerOpacity,
            },
          ]}
        >
          <View style={styles.dateComponentContainer}>
            <DateComponent
              renderDesktopView
              compareEnabled
              onChange={(start, end) => setRange({ start, end })}
              onCompareChange={(start, end) => setCompareRange({ start, end })}
              onOpen={() => { }}
              onConfirm={() => { }}
              startDate={range.start}
              endDate={range.end}
              compareStartDate={compareRange.start}
              compareEndDate={compareRange.end}
            />
          </View>
          {selectedTileIndex === 0 && <HomeGraphLineSelector
            data={tileData}
            setData={setTileData}
            showComparison={!!compareRange.end}
          />}
          <HappyDetailsGraphWeb
            tileData={tileData}
            graphData={graphData}
            graphCompareData={graphCompareData}
            selectedTileIndex={selectedTileIndex}
            linesShown={tileData.map((tile: { lineShown: boolean }) => tile.lineShown)}
            showComparison={!!compareRange.end}
          />
        </Animated.View>
      </View>
      <TileSelector
        data={tileData}
        selectedIndex={selectedTileIndex}
        setSelectedIndex={setSelectedTileIndex}
      />
    </>
  );
};

const styles = StyleSheet.create({
  contentWrapper: {
    marginTop: 104,
    marginEnd: 40,
    maxWidth: 1148,
  },
  superImposition: {
    zIndex: 2,
  },
  contentContainer: {
    height: 327,
    backgroundColor: '#FFF',
    borderRadius: 24,
    paddingTop: 17,
    paddingBottom: 27,
  },
  sectionHeader: {
    paddingStart: 172,
  },
  graphContainer: {
    marginStart: 40,
    borderRadius: 24,
    paddingStart: 24,
    backgroundColor: '#FFF',
    borderColor: Colors.light.hereworksWhite300,
    borderWidth: 1,
  },
  happyScoreIndicator: {
    top: -60,
    start: -60,
  },
  dateComponentContainer: {
    marginTop: 18,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 2,
  },
});

export default HomeScreenTopSection;
