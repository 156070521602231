import * as React from 'react';
import Svg, {
  SvgProps, G, Circle, Path, Defs, ClipPath, Rect,
} from 'react-native-svg';

const ProfileIcon60 = (props: SvgProps) => (
  <Svg width={120} height={120} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Circle cx={60} cy={60} r={60} fill="#E6EEF7" />
      <Path
        fill="#548FC8"
        d="M98 122V95.462c0-4.897-2.002-9.593-5.565-13.055C88.872 78.945 84.039 77 79 77H41c-5.04 0-9.872 1.945-13.435 5.407C24.002 85.87 22 90.565 22 95.462V122M60 68c10.493 0 19-8.507 19-19s-8.507-19-19-19-19 8.507-19 19 8.507 19 19 19Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Rect width={120} height={120} fill="#fff" rx={60} />
      </ClipPath>
    </Defs>
  </Svg>
);
export default ProfileIcon60;
