import AsyncStorage from '@react-native-async-storage/async-storage';

const deleteTemplate = async (ButtonPrototypeId?: string) => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('jwt', jwtToken ?? '');

    const body: any = { ButtonPrototypeId };

    return fetch(
      `https://${apiDomain}/button/buttonprototypes`,
      {
        method: 'DELETE',
        headers: requestHeaders,
        body: JSON.stringify(body),
      },
    ).then((res) => res?.json()?.then((json) => json));
  } catch (error) {
    return null;
  }
};

export default deleteTemplate;
