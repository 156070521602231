import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const ZoomIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Rect width={24} height={24} rx={3} fill="#002549" fillOpacity={0.3} />
    <Path
      d="M15 4h5v5M9 20H4v-5M20 15v5h-5M4 9V4h5"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ZoomIcon;
