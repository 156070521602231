import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ResumeTriangleIcon = (props: SvgProps) => (
  <Svg
    width={11}
    height={14}
    fill="none"
    {...props}
  >
    <Path
      d="M10 7 1 1v12l9-6Z"
      fill={props.color ? props.color : '#2173C4'}
      stroke={props.color ? props.color : '#2173C4'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ResumeTriangleIcon;
