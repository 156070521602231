import * as React from 'react';
import Svg, { SvgProps, Rect } from 'react-native-svg';
import Colors from '../../constants/Colors';

interface IconProps extends SvgProps {
  isSelected?: boolean,
}

const PausedStatusIcon = (props: IconProps) => (
  <Svg width="43" height="43" viewBox="0 0 43 43" fill="none" {...props}>
    <Rect x="3.5" y="3.5" width="39" height="39" rx="19.5" fill={props.isSelected ? Colors.light.hereworksYellow50 : Colors.light.hereworksYellow500} />
    <Rect x="18.5" y="15.5" width="2" height="16" rx="1" fill={props.isSelected ? Colors.light.hereworksYellow900 : '#FFF'} />
    <Rect x="26.5" y="15.5" width="2" height="16" rx="1" fill={props.isSelected ? Colors.light.hereworksYellow900 : '#FFF'} />
    <Rect x="3.5" y="3.5" width="39" height="39" rx="19.5" stroke={props.isSelected ? Colors.light.hereworksYellow200 : Colors.light.hereworksYellow500} />
  </Svg>
);

export default PausedStatusIcon;
