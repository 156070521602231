import * as React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';

const DownloadIcon16 = (props: SvgProps) => <Svg
  width='32'
  height='32'
  viewBox='0 0 32 32'
  fill='none'
  {...props}
>
  <G id='Icons 16x16/Download 16x16'>
    <Path
      id='primary'
      d='M10.6641 16L15.9974 21.3333L21.3307 16'
      stroke='#B0C2D3'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      id='primary_2'
      d='M6.66406 28H25.3307M15.9974 4V21.3333V4Z'
      stroke='#B0C2D3'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </G>
</Svg>;

export default DownloadIcon16;
