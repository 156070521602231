import { processFontFamily } from 'expo-font';
import React from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLabel,
  VictoryTooltip,
} from 'victory-native';
import Colors from '../constants/Colors';

const ReportsBarGraphMobile = (props) => (
  <VictoryChart
    containerComponent={<VictoryContainer />}
    width={287}
    height={329}
    domain={{ x: [0, 4], y: [0, 100] }}
    padding={{
      top: 50,
      left: 0,
      right: 50,
      bottom: 50,
    }}
  >
    <VictoryAxis
      disableInlineStyles
      fixLabelOverlap={true}
      dependentAxis
      style={{
        axis: { stroke: 'none' },
        axisLabel: { stroke: 'red' },
        tickLabels: {
          stroke: Colors.light.hereworksBlack400,
          fontFamily: processFontFamily('Poppins_400Regular'),
          fontSize: 14,
          strokeWidth: 0,
        },
        grid: {
          stroke: Colors.light.hereworksWhite100,
          strokeWidth: 2,
          pointerEvents: 'none',
        },
      }}
    />
    <VictoryAxis
      disableInlineStyles
      fixLabelOverlap={true}
      tickLabelComponent={
        <VictoryLabel
          style={{
            fill: Colors.light.hereworksBlack400,
            fontFamily: processFontFamily('Poppins_400Regular'),
            fontSize: 12,
            strokeWidth: 0,
            textAnchor: 'start',
          }}
          dy={-24}
          dx={12}
        />
      }
      style={{
        axis: { stroke: 'none' },
      }}
    />
    {!props.compareToggled && (
      <VictoryGroup offset={34}>
        <VictoryBar
          horizontal
          style={{
            data: { fill: Colors.light.hereworksDarkBlue },
            labels: {
              fontSize: 12,
              strokeWidth: 0,
              fill: Colors.light.hereworksDarkBlue,
              fontFamily: processFontFamily('Roboto_400Regular'),
            },
          }}
          x={'key'}
          y={'value'}
          data={props.data}
          barWidth={26}
          labels={({ datum }) => datum.value}
          labelComponent={<VictoryTooltip renderInPortal={false} />}
          cornerRadius={{ top: 2 }}
        />
      </VictoryGroup>
    )}
    {props.compareToggled && (
      <VictoryGroup offset={15}>
        <VictoryBar
          horizontal
          style={{ data: { fill: Colors.light.hereworksYellow500 } }}
          x={'key'}
          y={'value'}
          data={props.data}
          barWidth={11}
          cornerRadius={{ top: 2 }}
        />
        <VictoryBar
          horizontal
          style={{ data: { fill: Colors.light.hereworksDarkBlue }, labels: { fill: 'red' } }}
          x={'key'}
          y={'value'}
          data={props.compareData}
          barWidth={11}
          cornerRadius={{ top: 2 }}
        />
      </VictoryGroup>
    )}
  </VictoryChart>
);

export default ReportsBarGraphMobile;
