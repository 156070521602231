import React, { useEffect, useState, ReactElement } from 'react';
import {
  Dimensions, Platform, StyleSheet, Text, TextInput, TouchableOpacity, View, ActivityIndicator,
} from 'react-native';
import Modal from 'react-native-modal';
import Colors from '../../constants/Colors';
import CloseModalIcon from '../CloseModalIcon';
import Style from '../../constants/Style';
import Button from '../Button';
import AlertModal from '../AlertModal';
import updateTemplateGroup from '../../requests/UpdateTemplateGroup';

const mobile = Platform.OS !== 'web' || Dimensions.get('window').width < 500;

interface AddTemplateGroupModalProps {
  isVisible: boolean,
  hide: () => void,
  id?: string,
  currentName?: string,
  // eslint-disable-next-line no-unused-vars
  update?: (name: string, id: string, create: boolean) => void,
}

const AddTemplateGroupModal = ({
  isVisible, hide, id = null, currentName = '', update,
}: AddTemplateGroupModalProps) => {
  const [name, setName] = useState<string>(currentName);
  const [inputError, setInputError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertError, setAlertError] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string | ReactElement>('');
  const [visibilityToggleEnabled, setVisibilityToggleEnabled] = useState<boolean>(true);

  const showAlert = (title: string, message: string | ReactElement, error: boolean = true) => {
    setAlertError(error);
    setAlertTitle(title);
    setAlertMessage(message);
    setLoading(false);
    setAlertVisible(true);
  };

  useEffect(() => {
    setName(currentName);
    setInputError(false);
    setLoading(false);
    if (isVisible) {
      setVisibilityToggleEnabled(false);
      const timeout = setTimeout(() => {
        setVisibilityToggleEnabled(true);
        clearTimeout(timeout);
      }, 500);
    } else {
      setVisibilityToggleEnabled(true);
    }
  }, [isVisible]);

  const closeModal = () => (visibilityToggleEnabled ? hide() : null);

  const addGroup = () => {
    const error = (text?: string) => {
      showAlert(
        'Error',
        text ?? `Could not ${id ? 'edit' : 'create'} template group. Please try again later`,
      );
      setLoading(false);
    };

    if (!name.trim().length) setInputError(true);
    else {
      setLoading(true);
      updateTemplateGroup(name, id).then((response) => {
        let isValid: boolean = response && !response.isError;
        let newID: string = '';
        if (!id) {
          newID = response.msg?.ButtonPrototypeGroupId;
          isValid = newID?.length > 0;
        }
        if (isValid) {
          showAlert(
            `Template group successfully ${id ? 'edited' : 'added'}`,
            <>
              <Text style={{ fontFamily: 'Roboto_700Bold' }}>
                {name}
              </Text>
              {` template group - successfully ${id ? 'edited' : 'added'}`}
            </>,
            false,
          );
          update(name, id ?? newID, newID?.length > 0);
          hide();
        } else error(response?.errorText);
        setLoading(false);
      }).catch(error);
    }
  };

  return <>
    <Modal isVisible={isVisible}
      onBackButtonPress={closeModal}
      onBackdropPress={closeModal}
      onSwipeComplete={closeModal}
      style={styles.modal}
    >
      <View style={styles.container}>
        <Text style={styles.title}>{`${id ? 'Edit' : 'Add'} template group`}</Text>
        <Text style={styles.label}>Name</Text>
        <TextInput
          value={name}
          onChangeText={(text: string) => {
            setInputError(false);
            setName(text);
          }}
          placeholder='Enter name'
          placeholderTextColor={styles.label.color}
          style={[styles.input, inputError && styles.errorInput]}
        />
        {inputError && <Text style={styles.errorText}>Can't be empty</Text>}
        <View style={[Style.row, styles.buttonContainer]}>
          <Button
            affirmative={false}
            onPress={closeModal}
            title='Cancel'
          />
          <Button
            affirmative={true}
            disabled={loading}
            onPress={loading ? null : addGroup}
            title={`${id ? 'Edit' : 'Add'} group`}
          />
        </View>
        <TouchableOpacity
          style={Style.closeButton}
          onPress={hide}
        >
          <CloseModalIcon />
        </TouchableOpacity>
        <ActivityIndicator
          size='large'
          animating={loading}
          style={Style.activityIndicator}
        />
      </View>
    </Modal>
    <AlertModal
      modalVisible={alertVisible}
      setModalVisible={setAlertVisible}
      error={alertError}
      title={alertTitle}
      message={alertMessage}
    />
  </>;
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  container: {
    backgroundColor: '#FFF',
    alignSelf: 'center',
    borderRadius: 10,
    padding: 40,
    paddingTop: 60,
    width: mobile ? '80%' : 530,
  },
  title: {
    fontSize: 28,
    lineHeight: 35,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: 32,
  },
  label: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
    marginBottom: 4,
  },
  input: {
    fontSize: 16,
    lineHeight: Platform.OS === 'ios' ? undefined : 24,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
    paddingVertical: 10,
    paddingHorizontal: 8,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue400,
  },
  errorInput: {
    borderWidth: 1,
    borderColor: Colors.light.hereworksRed500,
  },
  errorText: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksRed500,
    marginTop: 4,
  },
  buttonContainer: {
    alignSelf: 'center',
    marginTop: 40,
  },
});

export default AddTemplateGroupModal;
