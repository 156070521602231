import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import Colors from '../../../constants/Colors';

interface AirQualitySafeLevelsSectionProps {
  index: number,
  units: string[],
}

export const thresholds: (string)[][] = [
  ['0', '1000', '1500', '2000', '2500', '3000'],
  null,
  ['0', '60', '80', '95', '100', '105'],
  ['0', '12.1', '35.5', '55.5', '150.5', '250.5'],
  ['0', '220', '660', '2200', '3300', '4400'],
  ['18-23', '21'],
  ['0-29', '30-60', '>61'],
];

const labels: string[][] = [
  ['Good', 'Moderate', 'Unhealthy if sensitive', 'Unhealthy', 'Very unhealthy', 'Hazardous'],
  null,
  ['Good', 'Moderate', 'Damaging after 2 hours', 'Damaging after 50 mins', 'Hearing loss after 15 mins', 'Hearing loss after <5 mins'],
  ['Good', 'Moderate', 'Unhealthy if sensitive', 'Unhealthy', 'Very unhealthy', 'Hazardous'],
  ['Good', 'Moderate', 'Unhealthy if sensitive', 'Unhealthy', 'Very unhealthy', 'Hazardous'],
  ['Good', 'Optimal'],
  ['Low', 'Optimal', 'High'],
];

export const defaultSafetyColors: string[] = [
  Colors.light.hereworksGreen300, Colors.light.hereworksYellow500, Colors.light.hereworksOrange500,
  Colors.light.hereworksRed500, Colors.light.hereworksPurple500, Colors.light.hereworksPurple800,
];

export const safetyColors: string[][] = [
  defaultSafetyColors, null, defaultSafetyColors, defaultSafetyColors, defaultSafetyColors,
  [defaultSafetyColors[1], defaultSafetyColors[0], defaultSafetyColors[2]],
  [defaultSafetyColors[1], defaultSafetyColors[0], defaultSafetyColors[2]],
];

const AirQualitySafeLevelsSection = ({
  index, units,
}: AirQualitySafeLevelsSectionProps) => thresholds[index]?.length > 0
  && <View style={styles.container}>
    <View style={[
      styles.section, !(thresholds[index] && thresholds[index][2]) && styles.sectionTrailing]}>
      <View style={styles.sectionContainer}>
        {labels[index] && labels[index][0] && <View style={styles.sectionSubContainer}>
          <View style={[styles.icon, { backgroundColor: safetyColors[index][0] }]} />
          <Text style={styles.text}>{labels[index][0]}</Text>
        </View>}
        {thresholds[index] && thresholds[index][0] && <Text style={styles.text}>
          {`${thresholds[index][0]} ${units[index]}`}
        </Text>}
      </View>
      <View style={styles.sectionContainer}>
        {labels[index] && labels[index][1] && <View style={styles.sectionSubContainer}>
          <View style={[
            styles.icon, { backgroundColor: safetyColors[index][1] }]} />
          <Text style={styles.text}>{labels[index][1]}</Text>
        </View>}
        {thresholds[index] && thresholds[index][1] && <Text style={styles.text}>
          {`${thresholds[index][1]} ${units[index]}`}
        </Text>}
      </View>
    </View>
    <View style={[
      styles.section, !(thresholds[index] && thresholds[index][4]) && styles.sectionTrailing]}>
      {labels[index] && labels[index][2] && <View style={styles.sectionContainer}>
        <View style={styles.sectionSubContainer}>
          <View style={[styles.icon, { backgroundColor: safetyColors[index][2] }]} />
          <Text style={styles.text}>{labels[index][2]}</Text>
        </View>
        {thresholds[index] && thresholds[index][2] && <Text style={styles.text}>
          {`${thresholds[index][2]} ${units[index]}`}
        </Text>}
      </View>}
      {labels[index] && labels[index][3] && <View style={styles.sectionContainer}>
        <View style={styles.sectionSubContainer}>
          <View style={[styles.icon, { backgroundColor: safetyColors[index][3] }]} />
          <Text style={styles.text}>{labels[index][3]}</Text>
        </View>
        {thresholds[index] && thresholds[index][3] && <Text style={styles.text}>
          {`${thresholds[index][3]} ${units[index]}`}
        </Text>}
      </View>}
    </View>
    <View style={[styles.section, styles.sectionTrailing]}>
      {labels[index] && labels[index][4] && <View style={styles.sectionContainer}>
        <View style={styles.sectionSubContainer}>
          <View style={[styles.icon, { backgroundColor: safetyColors[index][4] }]} />
          <Text style={styles.text}>{labels[index][4]}</Text>
        </View>
        {thresholds[index] && thresholds[index][4] && <Text style={styles.text}>
          {`${thresholds[index][4]} ${units[index]}`}
        </Text>}
      </View>}
      {labels[index] && labels[index][5] && <View style={styles.sectionContainer}>
        <View style={styles.sectionSubContainer}>
          <View style={[styles.icon, { backgroundColor: safetyColors[index][5] }]} />
          <Text style={styles.text}>{labels[index][5]}</Text>
        </View>
        {thresholds[index] && thresholds[index][5] && <Text style={styles.text}>
          {`${thresholds[index][5]} ${units[index]}`}
        </Text>}
      </View>}
    </View>
  </View>;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 12,
    borderTopWidth: 1.5,
    borderTopColor: Colors.light.hereworksWhite500,
  },
  sectionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  sectionSubContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  section: {
    paddingEnd: 32,
    marginEnd: 32,
    flex: 1,
    justifyContent: 'space-between',
    borderEndWidth: 1,
    borderEndColor: Colors.light.hereworksWhite500,
  },
  sectionTrailing: {
    paddingEnd: 0,
    marginEnd: 0,
    borderEndWidth: 0,
  },
  icon: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginEnd: 8,
    backgroundColor: '#000',
  },
  text: {
    fontSize: 16,
    lineHeight: 18.75,
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_400Regular',
    textAlign: 'right',
  },
});

export default AirQualitySafeLevelsSection;
