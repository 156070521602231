import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LocationIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <Path
      d="M15 16.125a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75Z"
      stroke="#323E48"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M15 3.75a9 9 0 0 0-9 9c0 2.129.452 3.521 1.688 5.063L15 26.25l7.313-8.438C23.547 16.273 24 14.878 24 12.75a9 9 0 0 0-9-9v0Z"
      stroke="#323E48"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LocationIcon;
