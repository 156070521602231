import * as React from 'react';
import Svg, { SvgProps, Circle, Rect } from 'react-native-svg';

const TemplateIcon = (props: SvgProps) => (
  <Svg width={40} height={40} fill="none" {...props}>
    <Circle cx={20} cy={20} r={20} fill="#003A70" />
    <Rect x={12.5} y={12.5} width={6} height={6} rx={1.5} fill="#fff" stroke="#fff" />
    <Rect x={14} y={14} width={3} height={3} rx={0.7} fill="#fff" />
    <Rect x={21.5} y={12.5} width={6} height={6} rx={1.5} fill="#fff" stroke="#fff" />
    <Rect x={23} y={14} width={3} height={3} rx={0.7} fill="#fff" />
    <Rect x={21.5} y={21.5} width={6} height={6} rx={1.5} fill="#fff" stroke="#fff" />
    <Rect x={23} y={23} width={3} height={3} rx={0.7} fill="#fff" />
    <Rect x={12.5} y={21.5} width={6} height={6} rx={1.5} fill="#fff" stroke="#fff" />
    <Rect x={14} y={23} width={3} height={3} rx={0.7} fill="#fff" />
  </Svg>
);

export default TemplateIcon;
