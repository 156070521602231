import React, { useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Moment from 'moment';
import Modal from 'react-native-modal';
import * as Analytics from 'expo-firebase-analytics';
import { ScrollView } from 'react-native-gesture-handler';
import Colors from '../constants/Colors';
import BinIcon24 from './24pxIcons/BinIcon24';
import CloseModalIcon from './CloseModalIcon';
import Style from '../constants/Style';
import addAssetNote from '../requests/AddAssetNote';
import editAssetNotes from '../requests/EditAssetNote';
import deleteAssetNote from '../requests/DeleteAssetNote';

export default function AddEditNote(props) {
  const [noteTitle, setNoteTitle] = useState(props.selectedNote?.Title);
  const [noteDescription, setNoteDescription] = useState(props.selectedNote?.Description);
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const [titleFocused, setTitleFocused] = useState(false);
  const [titleValid, setTitleValid] = useState(true);
  const [descriptionValid, setDescriptionValid] = useState(true);
  const [savingNote, setSavingNote] = useState(false);

  const maxTitleLength = 50;

  const analytics = (title) => {
    // console.log(title); to test tracking
    Analytics.logEvent('button_press', {
      screen: 'GroupEditModal',
      name: title,
    });
  };

  function validateTitle(title) {
    let valid = true;
    if (
      title === undefined
      || title === null
      || title?.trim().length === 0
      || title?.trim().length > maxTitleLength
    ) {
      valid = false;
      setTitleValid(false);
    } else {
      setTitleValid(true);
    }
    return valid;
  }

  function validateDescription(text) {
    let valid = true;
    if (text?.trim().length > 150) {
      valid = false;
      setDescriptionValid(false);
    } else {
      setDescriptionValid(true);
    }
    return valid;
  }

  const renderSaveCancel = () => (
    <View
      style={{
        marginTop: 40,
        flexDirection: 'row',
        alignSelf: 'stretch',
      }}
    >
      <TouchableOpacity
        style={{
          backgroundColor: Colors.light.hereworksLightBlue,
          borderRadius: 4,
          marginRight: 12,
        }}
        onPress={() => {
          analytics('Save');
          const validTitle = validateTitle(noteTitle ?? props.selectedNote?.Title);
          if (validTitle) {
            const validDescription = validateDescription(noteDescription);
            if (validDescription) {
              const title = noteTitle ?? props.selectedNote?.Title;
              const description = noteDescription ?? props.selectedNote?.Description;
              if (titleValid && props.selectedNote === null) {
                const notes = Object.assign([], props.notes);
                const newNote = {
                  Title: title,
                  Description: description,
                  name_value: props.name,
                };
                notes.push(newNote);
                setSavingNote(true);
                addAssetNote(props.assetId, newNote).then(() => {
                  props.notes.push(newNote);
                  props.setVisible(false);
                  setNoteTitle(null);
                  setNoteDescription(null);
                  setSavingNote(false);
                  props.refresh();
                });
              } else if (props.editNote) {
                const notes = Object.assign([], props.notes);
                let editNote = null;
                notes.forEach((note) => {
                  if (note.Id === props.selectedNote.Id) {
                    editNote = note;
                    editNote.Title = title;
                    editNote.Description = description;
                    editNote.name_value = props.name;
                  }
                });
                setSavingNote(true);
                editAssetNotes(props.assetId, editNote).then(() => {
                  props.selectedNote.Title = title;
                  props.selectedNote.Description = description;
                  props.selectedNote.modified = Moment();
                  props.setVisible(false);
                  setNoteTitle(null);
                  setNoteDescription(null);
                  setSavingNote(false);
                });
              } else {
                props.setEditNote(true);
              }
            }
          }
        }}
      >
        <Text
          style={{
            color: '#fff',
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          {props.editNote || props.selectedNote === null ? 'Save' : 'Edit'}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          backgroundColor: '#fff',
          borderRadius: 4,
          borderWidth: 1,
          borderColor: Colors.light.hereworksLightBlue,
        }}
        onPress={() => {
          analytics('Cancel');
          setNoteDescription(null);
          props.setVisible(false);
        }}
      >
        <Text
          style={{
            color: Colors.light.hereworksLightBlue,
            fontFamily: 'Roboto_400Regular',
            fontSize: 16,
            marginVertical: 8,
            marginHorizontal: 24,
          }}
        >
          Cancel
        </Text>
      </TouchableOpacity>
      {props.selectedNote !== null && (
        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <TouchableOpacity
            style={{
              height: 40,
              width: 40,
              borderColor: Colors.light.hereworksRed,
              borderRadius: 4,
              borderWidth: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              const notes = Object.assign([], props.notes);
              let noteToDelete = null;
              notes.forEach((item) => {
                if (item.Id === props.selectedNote.Id) {
                  noteToDelete = item;
                }
              });
              setSavingNote(true);
              deleteAssetNote(props.assetId, noteToDelete).then(() => {
                props.notes.forEach((item, index) => {
                  if (item === props.selectedNote) {
                    props.notes.splice(index, 1);
                  }
                });
                setSavingNote(false);
                props.setVisible(false);
              });
            }}
          >
            <BinIcon24 color={Colors.light.hereworksRed} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );

  const getTitleColor = () => {
    if (!titleValid) {
      return Colors.light.hereworksUrgentRed;
    }
    if (titleFocused) {
      return titleFocused ? Colors.light.hereworksBlue300 : '#fff';
    }
    return titleFocused ? Colors.light.hereworksBlue300 : '#fff';
  };

  const getDescriptionColor = () => {
    if (!descriptionValid) {
      return Colors.light.hereworksUrgentRed;
    }
    if (descriptionFocused) {
      return titleFocused ? Colors.light.hereworksBlue300 : '#fff';
    }
    return titleFocused ? Colors.light.hereworksBlue300 : '#fff';
  };

  return (
    <Modal
      isVisible={props.modalVisible}
      style={{ alignItems: 'center' }}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      propagateSwipe={false}
    >
      <View
        style={{
          alignItems: 'flex-start',
          justifyContent: 'center',
          borderRadius: 10,
          backgroundColor: '#fff',
          padding: 24,
          alignSelf: props.mobile ? 'stretch' : 'center',
          width: props.mobile ? undefined : 532,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            analytics('Close Modal');
            props.setVisible(false);
          }}
          hitSlop={{
            top: 20,
            bottom: 20,
            left: 40,
            right: 40,
          }}
          style={Style.closeButton}
        >
          <CloseModalIcon />
        </TouchableOpacity>
        {props.selectedNote !== null && !props.editNote && (
          <>
            <Text style={styles.heading} numberOfLines={2}>
              {props.selectedNote?.Title}
            </Text>
            <ScrollView style={{ maxHeight: 300 }}>
              <Text style={styles.description}>{props.selectedNote?.Description}</Text>
            </ScrollView>
            <Text style={styles.dateAndName}>
              {`${Moment(props.selectedNote?.UpdatedOn).format('DD.MM.YYYY')} by ${
                props.selectedNote?.name_value
              }`}
            </Text>
          </>
        )}
        {(props.selectedNote === null || props.editNote) && (
          <>
            <Text style={[styles.heading, { textAlign: 'center' }]}>
              {props.selectedNote === null ? 'Add a note' : 'Edit note'}
            </Text>
            <Text style={styles.textBoxHeaders}>Title</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: getTitleColor() },
                // @ts-ignore
                props.mobile ? {} : { outlineStyle: 'none' },
              ]}
              placeholderTextColor={Colors.light.hereworksBlack400}
              placeholder={'Enter title'}
              value={noteTitle ?? props.selectedNote?.Title ?? ''}
              onSubmitEditing={(text) => {
                setNoteTitle(text.nativeEvent.text);
              }}
              onFocus={() => setTitleFocused(true)}
              onBlur={() => {
                setTitleFocused(false);
              }}
              onChangeText={(text) => {
                validateTitle(text);
                setNoteTitle(text);
              }}
            />
            {noteTitle?.trim().length > maxTitleLength && (
              <Text style={[styles.validationText, { color: Colors.light.hereworksUrgentRed }]}>
                {`${
                  noteTitle?.trim().length ?? props.selectedNote?.Title?.trim().length ?? 0
                }/${maxTitleLength}`}
              </Text>
            )}
            {(noteTitle ?? '').trim().length === 0 && !titleValid && (
              <Text style={[styles.validationText, { color: Colors.light.hereworksUrgentRed }]}>
                This field is required!
              </Text>
            )}
            <Text style={styles.textBoxHeaders}>Description</Text>
            <TextInput
              // @ts-ignore
              style={[styles.inputText, { borderColor: getDescriptionColor() }]}
              placeholderTextColor={Colors.light.hereworksBlack400}
              placeholder={'Enter description'}
              value={noteDescription ?? props.selectedNote?.Description ?? ''}
              onSubmitEditing={(text) => {
                setNoteDescription(text.nativeEvent.text);
              }}
              onFocus={() => setDescriptionFocused(true)}
              onBlur={() => {
                setDescriptionFocused(false);
              }}
              onChangeText={(text) => {
                validateDescription(text);
                setNoteDescription(text);
              }}
            />
            <Text
              style={[
                styles.validationText,
                noteDescription?.trim().length > 150
                  ? { color: Colors.light.hereworksUrgentRed }
                  : {},
              ]}
            >{`${
              noteDescription?.trim().length ?? props.selectedNote?.Description?.trim().length ?? 0
            }/150`}</Text>
          </>
        )}
        {renderSaveCancel()}
        {savingNote && (
          <View
            style={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              borderRadius: 8,
              position: 'absolute',
              justifyContent: 'center',
              backgroundColor: '#00000020',
            }}
          >
            <ActivityIndicator color={Colors.light.hereworksLoading} size={'large'} />
          </View>
        )}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  textBoxHeaders: {
    marginTop: 12,
    color: Colors.light.hereworksBlack400,
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
  },
  validationText: {
    marginTop: 4,
    color: Colors.light.hereworksBlack300,
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 20,
  },
  heading: {
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 28,
    lineHeight: 42,
    marginBottom: 32,
    color: Colors.light.hereworksBlack600,
    alignSelf: 'stretch',
    zIndex: -1,
  },
  description: {
    color: Colors.light.hereworksBlack600,
    alignSelf: 'stretch',
    fontFamily: 'Roboto_400Regular',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: 24,
  },
  inputText: {
    color: Colors.light.hereworksBlack600,
    alignSelf: 'stretch',
    fontFamily: 'Roboto_400Regular',
    alignItems: 'center',
    fontSize: 16,
    backgroundColor: Colors.light.hereworksWhite300,
    borderWidth: 1,
    alignContent: 'center',
    marginVertical: 5,
    paddingVertical: 10,
    paddingHorizontal: 8,
    borderRadius: 5,
    textAlignVertical: 'top',
  },
  dateAndName: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 14,
    color: Colors.light.hereworksBlack400,
    marginTop: 8,
  },
});
