const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

const Colors = {
  light: {
    text: '#000',
    background: '#E5E5E5',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    hereworksPrimaryGrey: '#141E26',
    hereworksPrimaryGrey200: '#F5F5F6',
    hereworksPrimaryGrey300: '#EEEDE850',
    hereworksPrimaryGrey600: '#D9D8D6',
    hereworksPrimaryGrey700: '#919193',
    hereworksPrimaryGrey800: '#696D70',
    hereworksPrimaryGrey900: '#4A4F53',
    hereworksDarkBlue: '#003A70',
    hereworksDarkBlue50: '#E6EBF1',
    hereworksDarkBlue100: '#B0C2D3',
    hereworksDarkBlue200: '#8AA4BD',
    hereworksDarkBlue300: '#547B9F',
    hereworksDarkBlue400: '#2D5882',
    hereworksDarkBlue700: '#8E8EA4',
    hereworksDarkBlue800: '#696B88',
    hereworksDarkBlue900: '#444F71',
    hereworksLightBlue: '#2173C4',
    hereworksLightBlue700: '#AEC0D8',
    hereworksLightBlue800: '#89A7CB',
    hereworksLightBlue900: '#6892C0',
    HW_Purple_AAA: '#7E05B7',
    hereworksPink: '#D6006D',
    hereworksPink400: '#DE338A',
    hereworksBackground: '#F7FAFD',
    hereworksInputBackground: '#EFF2F3',
    hereworksOrange: '#C07E00',
    hereworksOrange50: '#FCEEE9',
    hereworksOrange200: '#F3B098',
    hereworksOrange500: '#E5541F',
    hereworksOrange700: '#963714',
    hereworksExtraDarkTeal: '#025157',
    hereworksExtraDarkTeal700: '#8C9A9B',
    hereworksPaleBlue: '#BED6DB',
    hereworksPaleBlue700: '#E5ECF0',
    hereworksPaleBlue800: '#D7E3EA',
    hereworksPaleBlue900: '#C9DBE3',
    hereworksGreen: '#005F09',
    hereworksGreen50: '#E6EFE6',
    hereworksGreen100: '#B0CDB3',
    hereworksGreen200: '#8AB58E',
    hereworksGreen300: '#54945A',
    hereworksGreen400: '#337F3A',
    hereworksGreen500: '#005F09',
    hereworksCheckGreen: '#A1D683',
    hereworksTeal: '#6BCBB8',
    hereworksDarkGreen: '#4D7F71',
    hereworksCoral: '#FF595A',
    hereworksRed: '#A82636',
    hereworksRed50: '#FDEBED',
    hereworksRed100: '#E40000',
    hereworksRed300: '#F37887',
    hereworksRed500: '#ED364C',
    hereworksRed700: '#A82636',
    hereworksRed800: '#821E2A',
    hereworksUrgentRed: '#E23554',
    hereworksLightPink: '#F9C1C8',
    hereworksWine: '#8A2432',
    hereworksMediumYellow: '#FFA800',
    hereworksYellow50: '#FEF7EB',
    hereworksYellow100: '#FCE7C0',
    hereworksYellow200: '#FADCA2',
    hereworksYellow300: '#F8CC78',
    hereworksYellow400: '#F7C25D',
    hereworksYellow500: '#F5B335',
    hereworksYellow600: '#DFA330',
    hereworksYellow700: '#F7DBA5',
    hereworksYellow800: '#87621D',
    hereworksYellow900: '#674B16',
    hereworksDarkYellow700: '#AE7F26',
    hereworksHover: '#F3F9FC',
    hereworksSelected: '#E0EEF1',
    hereworksLoading: '#323E48',
    hereworksBlack50: '#F8F8F8',
    hereworksBlack100: '#B6B9BC',
    hereworksBlack200: '#93989B',
    hereworksBlack300: '#62686E',
    hereworksBlack400: '#4A535A',
    hereworksBlack500: '#243848',
    hereworksBlack600: '#121B23',
    hereworksBlue100: '#B0CBE6',
    hereworksBlue200: '#8AB2D9',
    hereworksBlue300: '#548FC8',
    hereworksBlue400: '#3379BD',
    hereworksBlue500: '#0058AD',
    hereworksBlue600: '#00509D',
    hereworksBlue50: '#E6EEF7',
    hereworksWhite100: '#F7FAFD',
    hereworksWhite200: '#F4F7FB',
    hereworksWhite300: '#EEF4FA',
    hereworksWhite400: '#EBF1F9',
    hereworksWhite500: '#E6EEF7',
    hereworksWhite600: '#D1D9E1',
    hereworksPurple500: '#9C007A',
    hereworksPurple800: '#560043',
    hereworksEmeraldGreen: '#00575C',
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
};

export default Colors;
