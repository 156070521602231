import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  StyleSheet, ScrollView, Text, View,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SingleLineGraph from '../components/SingleLineGraph';
import TopSection, { TopSectionProps } from '../components/TopSection';
import BackgroundImage from '../components/BackgroundImage';
import Colors from '../constants/Colors';
import EmptyGraph from '../graphs/EmptyGraph';
import CompareLineGraphWeb from '../graphs/CompareLineGraphWeb';

export default function OccupantsScreen() {
  const [range, setRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: Moment().subtract(6, 'days').startOf('day').toDate(), end: new Date() });

  const [compareRange, setCompareRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({ start: null, end: null });

  const onConfirm = React.useCallback(
    (start, end) => {
      setRange({ start, end });
    },
    [setRange],
  );

  const onCompareConfirm = React.useCallback(
    (start, end) => {
      setCompareRange({ start, end });
    },
    [setCompareRange],
  );

  const [averageGraphsShown, setAverageGraphsShown] = useState(true);

  useEffect(() => {
    AsyncStorage.getItem('clientName').then((clientName) => {
      if (clientName === 'mastercard') {
        setAverageGraphsShown(false);
      }
    });
  }, []);

  const topSectionProps: TopSectionProps = {
    range,
    compareRange,
    onConfirm,
    onCompareConfirm,
    title: 'Occupancy',
  };
  return (
    <ScrollView style={styles.container}>
      <BackgroundImage />
      <TopSection {...topSectionProps} />
      <View style={styles.content}>
        <View style={styles.graphCard}>
          <Text style={styles.heading}>Building Occupancy</Text>
          <CompareLineGraphWeb
            dates={range}
            compareDates={compareRange}
            metricName="data-peoplecount"
            title="Occupants"
            indexName="hw-rawdata-buildingoccupancy"
            compareToggled={compareRange.end !== null}
            interval={'24h'}
          />
        </View>
        <View style={styles.graphCard}>
          {averageGraphsShown ? (
            <SingleLineGraph
              metricName="data-desksbookedcount"
              title="Ave. Bookings"
              interval="6h"
              index="hw-rawdata-smartway2desksbookedcount"
            />
          ) : (
            <EmptyGraph title="Bookings" compareDates={compareRange} dates={range} />
          )}
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.light.hereworksBackground,
  },
  content: {
    paddingHorizontal: 24,
    maxWidth: 1212,
  },
  graphCard: {
    marginVertical: 40,
    borderRadius: 24,
    backgroundColor: 'white',
  },
  heading: {
    marginTop: 20,
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_400Regular',
    fontSize: 22,
    lineHeight: 33,
    marginLeft: 24,
  },
});
