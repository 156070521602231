import { View, Pressable } from 'react-native';
import { PanGestureHandlerGestureEvent, PanGestureHandler } from 'react-native-gesture-handler';
import Animated, {
  useSharedValue,
  useAnimatedGestureHandler,
  useAnimatedStyle,
} from 'react-native-reanimated';
import Colors from '../../constants/Colors';

type ContextType = {
  translateX: number;
  translateY: number;
};

export default function MapPoint(props) {
  const translateXsen = useSharedValue(props.point.x);
  const translateYsen = useSharedValue(props.point.y);

  const panGestureSen = useAnimatedGestureHandler<PanGestureHandlerGestureEvent, ContextType>({
    onStart: (event, context) => {
      context.translateX = translateXsen.value;
      context.translateY = translateYsen.value;
    },
    onActive: (event, context) => {
      translateXsen.value = event.translationX + context.translateX;
      translateYsen.value = event.translationY + context.translateY;
    },
    onEnd: () => {
      let error = false;
      const newArr = Object.assign([], props.drawPoints);
      const newPoint = { x: translateXsen.value, y: translateYsen.value };
      newArr[props.index] = newPoint;
      if (
        newPoint.x < 0
        || newPoint.y < 0
        || newPoint.x > props.imageWidth
        || newPoint.y > props.imageHeight
      ) {
        error = true;
      }
      if (!error) {
        props.setDrawPoints(newArr);
      } else {
        props.showAlert('Out of bounds!', true);
      }
    },
  });

  const transformStyleSen = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: translateXsen.value,
      },
      {
        translateY: translateYsen.value,
      },
    ],
  }));

  return (
    <View
      style={{
        zIndex: 3,
        position: 'absolute',
      }}
      key={props.index}
    >
      <PanGestureHandler onGestureEvent={panGestureSen}>
        <Animated.View style={[transformStyleSen]}>
          <Pressable
            onPress={() => {
              const newArr = Object.assign([], props.drawPoints);
              if (props.drawPoints.length > 1 && props.index === 0) {
                props.setAreaComplete(true);
                const newPoint = {
                  x: props.drawPoints[props.index].x,
                  y: props.drawPoints[props.index].y,
                  number: props.drawPoints.length,
                };
                props.setDrawPoints([...props.drawPoints, newPoint]);
              } else {
                newArr.splice(props.index, 1);
                props.setDrawPoints(newArr);
              }
            }}
            style={{
              width: 12,
              height: 12,
              borderRadius: 6,
              borderWidth: 3,
              borderColor: Colors.light.hereworksOrange500,
              backgroundColor: Colors.light.hereworksOrange50,
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 3,
            }}
          />
        </Animated.View>
      </PanGestureHandler>
    </View>
  );
}
