import * as React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';

const ImageIcon16 = (props: SvgProps) => <Svg
  width='16'
  height='16'
  viewBox='0 0 16 16'
  fill='none'
  {...props}>
  <G id='Image'>
    <Path
      id='primary-upstroke'
      d='M6.71875 5.52686H6.78542'
      stroke='#3379BD'
      strokeWidth='1.95'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      id='primary'
      d='M14 3.33317V12.6665C14 12.8433 13.9298 13.0129 13.8047 13.1379C13.6797 13.2629 13.5101 13.3332 13.3333 13.3332H2.66667C2.48986 13.3332 2.32029 13.2629 2.19526 13.1379C2.07024 13.0129 2 12.8433 2 12.6665V3.33317C2 3.15636 2.07024 2.98679 2.19526 2.86177C2.32029 2.73674 2.48986 2.6665 2.66667 2.6665H13.3333C13.5101 2.6665 13.6797 2.73674 13.8047 2.86177C13.9298 2.98679 14 3.15636 14 3.33317ZM7.00667 9.8665L10.4733 13.3332H13.3333C13.5101 13.3332 13.6797 13.2629 13.8047 13.1379C13.9298 13.0129 14 12.8433 14 12.6665V10.4865L10.1933 6.6665L7.00667 9.8665ZM2 11.1398V12.6665C2 12.8433 2.07024 13.0129 2.19526 13.1379C2.32029 13.2629 2.48986 13.3332 2.66667 13.3332H10.4733L5.14 7.99984L2 11.1398Z'
      stroke='#3379BD'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round' />
  </G>
</Svg>;

export default ImageIcon16;
