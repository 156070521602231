import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CompletedOnIcon24 = (props: SvgProps) => (
    <Svg width={24} height={24} fill="none" {...props}>
        <Path d="M3 12.3158L8.78571 18L21 6" stroke="#005608" strokeWidth="1.5" />
    </Svg>
);

export default CompletedOnIcon24;
