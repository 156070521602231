import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PriorityIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M22.5.75h-4.688a.75.75 0 0 0-.75.75v4.688a.75.75 0 0 0 .75.75H22.5a.75.75 0 0 0 .75-.75V1.5a.75.75 0 0 0-.75-.75Zm-.75 4.688h-3.188V2.25h3.188v3.188ZM22.5 8.906h-4.688a.75.75 0 0 0-.75.75v4.688a.75.75 0 0 0 .75.75H22.5a.75.75 0 0 0 .75-.75V9.656a.75.75 0 0 0-.75-.75Zm-.75 4.688h-3.188v-3.188h3.188v3.188ZM18.938 21.75h-.375v-.375a.75.75 0 1 0-1.5 0V22.5a.75.75 0 0 0 .75.75h1.125a.75.75 0 1 0 0-1.5ZM22.5 20.625a.75.75 0 0 0-.75.75v.375h-.375a.75.75 0 1 0 0 1.5H22.5a.75.75 0 0 0 .75-.75v-1.125a.75.75 0 0 0-.75-.75ZM22.5 17.063h-1.125a.75.75 0 1 0 0 1.5h.375v.375a.75.75 0 1 0 1.5 0v-1.125a.75.75 0 0 0-.75-.75ZM18.938 17.063h-1.125a.75.75 0 0 0-.75.75v1.125a.75.75 0 1 0 1.5 0v-.375h.375a.75.75 0 1 0 0-1.5ZM11.835 6.803a.75.75 0 0 0 1.058 0l2.388-2.385c.1-.105.17-.234.203-.375a.749.749 0 0 0-.203-.668L12.893.971a.75.75 0 0 0-1.058 1.058l1.103 1.106H9.656c-11.816.491-11.812 17.325 0 17.813h5.093a.75.75 0 1 0 0-1.5H9.656c-9.825-.405-9.82-14.408 0-14.813h3.282L11.835 5.76a.75.75 0 0 0 0 1.043Z"
      fill="#323E48"
    />
  </Svg>
);

export default PriorityIcon;
