import * as React from 'react';
import Svg, {
  SvgProps, G, Path, Defs, ClipPath,
} from 'react-native-svg';

const NoTaskIcon = (props: SvgProps) => (
  <Svg
    width={120}
    height={120}
    fill="none"
    {...props}
  >
    <G clipPath="url(#a)" fill="#8E8EA4">
      <Path d="M86.718 11.719H76.714a4.69 4.69 0 0 0-4.058-2.344H58.358C57.27 4.033 52.534 0 46.875 0 41.215 0 36.48 4.033 35.39 9.375H21.094a4.69 4.69 0 0 0-4.059 2.344H7.031A7.04 7.04 0 0 0 0 18.75v94.219A7.039 7.039 0 0 0 7.031 120h79.687a7.039 7.039 0 0 0 7.032-7.031V18.75a7.04 7.04 0 0 0-7.032-7.031ZM37.5 14.063a2.344 2.344 0 0 0 2.343-2.344 7.04 7.04 0 0 1 7.032-7.031 7.04 7.04 0 0 1 7.032 7.031 2.344 2.344 0 0 0 2.343 2.344h16.406v9.374H21.094v-9.374H37.5Zm51.562 98.906a2.346 2.346 0 0 1-2.344 2.343H7.031a2.346 2.346 0 0 1-2.344-2.343V18.75a2.346 2.346 0 0 1 2.344-2.344h9.375v4.688H11.72a2.344 2.344 0 0 0-2.344 2.343v84.844a2.344 2.344 0 0 0 2.344 2.344h24.608a2.344 2.344 0 0 0 0-4.688H14.063V25.781h2.972a4.69 4.69 0 0 0 4.059 2.344h51.562a4.69 4.69 0 0 0 4.058-2.344h2.973v80.156H57.421a2.344 2.344 0 0 0 0 4.688h24.61a2.344 2.344 0 0 0 2.344-2.344V23.437a2.344 2.344 0 0 0-2.344-2.343h-4.688v-4.688h9.375a2.346 2.346 0 0 1 2.344 2.344v94.219Z" />
      <Path d="M46.876 14.063a2.344 2.344 0 0 0 0-4.688h-.002a2.343 2.343 0 1 0 .002 4.688ZM110.143 25.515a2.343 2.343 0 0 0-2.096-1.296h-7.032a2.344 2.344 0 0 0 0 4.688h2.344l-4.218 5.625a2.344 2.344 0 0 0 1.874 3.75h7.032a2.343 2.343 0 0 0 0-4.688h-2.344l4.219-5.625c.532-.71.618-1.66.221-2.454ZM117.656 12.5h-4.687l6.562-8.75A2.343 2.343 0 0 0 117.656 0h-9.375a2.344 2.344 0 0 0 0 4.688h4.688l-6.563 8.75a2.345 2.345 0 0 0 1.875 3.75h9.375a2.344 2.344 0 1 0 0-4.688ZM35.349 56.322a4.616 4.616 0 0 1-3.285 1.36 4.615 4.615 0 0 1-3.285-1.36 2.345 2.345 0 0 0-3.315 3.314 9.273 9.273 0 0 0 6.6 2.734 9.273 9.273 0 0 0 6.6-2.734 2.344 2.344 0 0 0-3.315-3.315ZM61.686 62.37a9.273 9.273 0 0 0 6.6-2.734 2.344 2.344 0 0 0-3.315-3.315 4.616 4.616 0 0 1-3.285 1.361 4.616 4.616 0 0 1-3.286-1.36 2.344 2.344 0 0 0-3.314 3.314 9.276 9.276 0 0 0 6.6 2.734ZM50.185 71.396h-6.62a2.344 2.344 0 0 0 0 4.688h6.62a2.344 2.344 0 0 0 0-4.688ZM46.875 105.938h-.002a2.343 2.343 0 1 0 .002 4.687 2.344 2.344 0 0 0 0-4.687Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h120v120H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default NoTaskIcon;
