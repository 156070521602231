import React, { Dispatch, SetStateAction } from 'react';
import {
  StyleSheet, Text, TouchableOpacity,
} from 'react-native';
import Colors from '../../../constants/Colors';

interface MetricSelectionButtonProps {
  title: string,
  onPress: Dispatch<SetStateAction<number>>,
  index: number,
  selected?: boolean,
}

const MetricSelectionButton = (props: MetricSelectionButtonProps) => <TouchableOpacity
  key={`metricSelectionButton_${props.index}`}
  onPress={() => props.onPress(props.index)}
  style={[styles.metricSelectionButton, props.selected && styles.metricSelectionButtonSelected]}>
  <Text style={[
    styles.metricSelectionButtonTitle,
    props.selected && styles.metricSelectionButtonTitleSelected,
  ]}>
    {props.title}
  </Text>
</TouchableOpacity>;

const styles = StyleSheet.create({
  metricSelectionButton: {
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksWhite100,
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  metricSelectionButtonSelected: {
    backgroundColor: Colors.light.hereworksDarkBlue,
  },
  metricSelectionButtonTitle: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
    textAlign: 'center',
  },
  metricSelectionButtonTitleSelected: {
    color: '#FFF',
  },
});

export default MetricSelectionButton;
