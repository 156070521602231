import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const RightPointer = (props: SvgProps) => (
  <Svg width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
    <Path d='M17 23L24 16M24 16L17 9M24 16L8 16' stroke='#8AA4BD' strokeWidth='3' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
  </Svg>

);

export default RightPointer;
