import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Colors from '../constants/Colors';

export interface SwitchProps {
  toggled: boolean,
  toggle: () => void,
}

const Switch = ({ toggled, toggle }: SwitchProps) => <TouchableOpacity
  onPress={toggle}
  style={[styles.track, toggled && styles.enabledTrack]}
>
  <View style={[styles.thumb, toggled && styles.enabledThumb]} />
</TouchableOpacity>;

const styles = StyleSheet.create({
  track: {
    height: 20,
    width: 35,
    borderRadius: 100,
    padding: 1,
    backgroundColor: Colors.light.hereworksBlack100,
    justifyContent: 'center',
  },
  enabledTrack: {
    backgroundColor: Colors.light.hereworksGreen400,
  },
  thumb: {
    height: 18,
    width: 18,
    borderRadius: 9,
    backgroundColor: '#FFF',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.16,
    shadowColor: '#0B2B47',
    shadowRadius: 6,
    elevation: 6,
    alignSelf: 'flex-start',
  },
  enabledThumb: {
    alignSelf: 'flex-end',
  },
});

export default Switch;
