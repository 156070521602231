import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import {
  View, StyleSheet, TouchableOpacity, Text, ScrollView, TextInput, Platform, Dimensions,
  ActivityIndicator,
} from 'react-native';
import Modal from 'react-native-modal';
import Colors from '../../../constants/Colors';
import RightPointer from '../../30pxIcons/RightPointer';
import Button from '../../Button';
import { UserGroup, Issue, Option } from './IssueRow';
import TemplateIcon30 from '../../30pxIcons/TemplateIcon30';
import IssueList, { customTemplateDefaultIssues } from './IssueList';
import AlertModal from '../../AlertModal';
import Style from '../../../constants/Style';
import FieldsList, { Field } from './FieldsList';
import CloseModalIcon from '../../CloseModalIcon';
import DropdownIcon12 from '../../12pxIcons/DropdownIcon12';
import EditIcon24 from '../../24pxIcons/EditIcon24';
import updateTemplate from '../../../requests/UpdateTemplate';

const mobile = Platform.OS !== 'web' || Dimensions.get('window').width < 500;

export type Template = {
  name: string, id: string, issues: Issue[], customfields: Field[],
}

export type TemplateGroup = {
  Name: string, ButtonPrototypeGroupId: string, buttonPrototypes: Template[],
};

interface AddTemplateModalProps {
  isVisible: boolean,
  hide: () => void,
  templateGroups: TemplateGroup[],
  userGroups: UserGroup[],
  templates?: Template[],
  templateToUpdate?: Template,
  defaultTemplateGroupID?: string,
  // eslint-disable-next-line no-unused-vars
  update: (template: Template, add: boolean, groupID: string) => void,
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum InitialOptions { DEFAULT = 1, CUSTOM, EDIT }

interface InitialOptionProps {
  onPress: () => void,
  title: string,
  description: string,
}

const InitialOption = ({
  title, description, onPress,
}: InitialOptionProps) => <TouchableOpacity
  onPress={onPress}
  style={styles.initialOption}
>
    <View style={styles.initialOptionContent}>
      <Text style={styles.initialOptionTitle}>{title}</Text>
      <Text style={styles.initialOptionDescription}>
        {description}
      </Text>
    </View>
    <RightPointer />
  </TouchableOpacity>;

interface TemplateOptionProps extends Template {
  selected: boolean,
  onPress: () => void,
}

const TemplateOption = ({
  name, selected, onPress,
}: TemplateOptionProps) => <TouchableOpacity
  style={[styles.templateOption, selected && styles.selectedTemplateOption]}
  onPress={onPress}
>
    <TemplateIcon30
      fill={selected ? Colors.light.hereworksBlue50 : Colors.light.hereworksBlue500}
      color={selected ? Colors.light.hereworksBlue500 : '#FFF'}
    />
    <Text style={[styles.templateOptionText, selected && styles.selectedTemplateOptionText]}>
      {name}
    </Text>
  </TouchableOpacity>;

interface TabButtonProps {
  selected: boolean,
  onPress: () => void,
  title: string,
}

const TabOption = ({ selected, onPress, title }: TabButtonProps) => <TouchableOpacity
  onPress={onPress}
  style={[styles.tabButton, selected && styles.tabButtonSelected]}
>
  <Text style={[styles.tabButtonText, selected && styles.tabButtonTextSelected]}>
    {title}
  </Text>
</TouchableOpacity>;

const AddTemplateModal = ({
  isVisible, hide, templates, templateToUpdate = null, templateGroups = [], defaultTemplateGroupID,
  userGroups = [], update,
}: AddTemplateModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertShown, setAlertShown] = useState<boolean>(false);
  const [alertDismissed, setAlertDismissed] = useState<boolean>(!alertShown);
  const [alertTitle, setAlertTitle] = useState<string>(null);
  const [alertMessage, setAlertMessage] = useState<string | ReactElement>(null);
  const [alertError, setAlertError] = useState<boolean>(true);
  const [visibilityToggleEnabled, setVisibilityToggleEnabled] = useState<boolean>(true);
  const [initialOption, setInitialOption] = useState<InitialOptions>(templateToUpdate
    ? InitialOptions.EDIT : null);
  const [primedTemplateId, setPrimedTemplateId] = useState<string>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(templateToUpdate?.id);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>(templateToUpdate?.name ?? 'New template');
  const [showingTemplateIssues, setShowingTemplateIssues] = useState<boolean>(false);
  const [fields, setFields] = useState<Field[]>([]);
  const [issues, setIssues] = useState<Issue[]>(customTemplateDefaultIssues);
  const [templateGroupDropdownShown, setTemplateGroupDropdownShown] = useState<boolean>(false);
  const [focusedFieldIndex, setFocusedFieldIndex] = useState<number>(-1);
  const nameField = useRef<TextInput>();
  const templateGroupSelector = useRef<TouchableOpacity>();
  const additionalFieldRefs = [];
  const [selectedTemplateGroupID,
    setSelectedTemplateGroupID] = useState<string>(defaultTemplateGroupID
      ?? templateGroups?.filter((group: TemplateGroup) => group.buttonPrototypes
        ?.map((item: Template) => item.id)?.includes(templateToUpdate?.id))[0]
        ?.ButtonPrototypeGroupId
      ?? templateGroups[0]?.ButtonPrototypeGroupId);

  const setInitialState = () => {
    setTemplateGroupDropdownShown(false);
    setInitialOption(templateToUpdate ? InitialOptions.EDIT : null);
    setFocusedFieldIndex(-1);
    setPrimedTemplateId(templateToUpdate?.id);
    setSelectedTemplateId(templateToUpdate?.id);
    setSelectedTemplateName(templateToUpdate?.name ?? 'New template');
    setSelectedTemplateGroupID(defaultTemplateGroupID
      ?? templateGroups?.filter((group: TemplateGroup) => group.buttonPrototypes
        ?.map((item: Template) => item.id)?.includes(templateToUpdate?.id))[0]
        ?.ButtonPrototypeGroupId
      ?? templateGroups[0]?.ButtonPrototypeGroupId);
    setIssues(templateToUpdate?.issues?.map((issue: any) => ({
      UserGroupId: issue.UserGroupId,
      Text: issue.Text,
      Priority: issue.Priority,
      Id: issue.Id,
      Enabled: issue.Enabled ?? true,
    })) ?? customTemplateDefaultIssues);
    setShowingTemplateIssues(false);
    setFields(templateToUpdate?.customfields ?? []);
  };

  const cancel = () => {
    setTemplateGroupDropdownShown(false);
    setDefaultIssues();
    if (showingTemplateIssues) setShowingTemplateIssues(false);
    else if (initialOption === InitialOptions.DEFAULT) {
      if (selectedTemplateId) {
        setSelectedTemplateId(null);
      } else {
        setPrimedTemplateId(null);
        setSelectedTemplateId(null);
        setInitialOption(templateToUpdate ? InitialOptions.EDIT : null);
      }
    } else if (initialOption === InitialOptions.CUSTOM) {
      setInitialState();
    } else hide();
  };

  const attemptUpdate = () => {
    let issuesToUpdate: Issue[] = [...issues];
    if (initialOption !== InitialOptions.EDIT) {
      issuesToUpdate = issuesToUpdate?.map((issue: Issue) => ({
        Text: issue.Text,
        UserGroupId: issue.UserGroupId ?? [],
        Priority: issue.Priority,
        Enabled: issue.Enabled,
      }));
    } else {
      issuesToUpdate = issuesToUpdate?.map((issue: Issue) => {
        const selectedIssue: Issue = templateToUpdate?.issues
          ?.filter((originalIssue: Issue) => originalIssue?.Id
            === issue?.Id)[0];
        const output: Issue = {
          Id: issue.Id,
          Text: issue.Text,
          UserGroupId: issue.UserGroupId,
          Enabled: issue.Enabled ?? true,
          Priority: issue.Priority,
        };
        if (selectedIssue?.UserGroupId && issue.UserGroupId !== selectedIssue.UserGroupId) {
          output.PrevUserGroupId = selectedIssue.UserGroupId;
        }
        return output;
      });
    }
    setLoading(true);
    const templateGroupName: string = templateGroups
      ?.filter((group: TemplateGroup) => group.ButtonPrototypeGroupId
        === selectedTemplateGroupID)[0]?.Name ?? '';
    updateTemplate(
      selectedTemplateName,
      selectedTemplateGroupID,
      templateGroupName,
      fields,
      issuesToUpdate,
      templateToUpdate?.id,
    ).then((response) => {
      if (response && !response.isError) {
        showAlert(
          `Template ${templateToUpdate?.id ? 'edited' : 'added'} successfully`,
          <Text>
            {'Template '}
            <Text style={styles.boldText}>{selectedTemplateName}</Text>
            {` successfully ${templateToUpdate?.id ? 'edited' : 'added'}`}
            {!templateToUpdate?.id && templateGroupName?.length
              && <Text>
                {' to template group '}
                <Text style={styles.boldText}>{templateGroupName}</Text>
              </Text>}
          </Text>,
          false,
        );
        update({
          name: selectedTemplateName,
          customfields: fields,
          issues,
          id: templateToUpdate?.id ?? response.ButtonPrototypeId
            ?? `${new Date().getMilliseconds()}`,
        }, !templateToUpdate?.id, selectedTemplateGroupID);
        hide();
      } else {
        showAlert(
          'Error',
          response.errorText
          ?? `Could not ${templateToUpdate?.id ? 'edit' : 'add'} template at the moment. Please try again later`,
        );
      }
      setLoading(false);
    }).catch(() => {
      showAlert(
        'Error',
        `Could not ${templateToUpdate?.id ? 'edit' : 'add'} template at the moment. Please try again later`,
      );
      setLoading(false);
    });
  };

  const validate = () => {
    if (!selectedTemplateName?.trim().length) {
      showAlert('Error', 'Template name cannot be empty');
      setShowingTemplateIssues(false);
      setFocusedFieldIndex(0);
    } else if (!selectedTemplateGroupID) {
      showAlert('Error', 'A template group must be chosen to add the template into');
      setShowingTemplateIssues(false);
      setFocusedFieldIndex(1);
    } else {
      let fieldsValid: boolean = true;
      fields?.forEach((field: Field, index: number) => {
        if (fieldsValid && !field.Name?.trim().length) {
          fieldsValid = false;
          setShowingTemplateIssues(false);
          showAlert('Error', 'Additional field names cannot be empty');
          setFocusedFieldIndex(index + 2);
        }
      });
      if (fieldsValid) {
        let issuesValid: boolean = true;
        issues?.forEach((issue: Issue) => {
          if (issue?.Text.toLowerCase() !== 'other') {
            if (issuesValid && !issue.Text?.trim().length) {
              showAlert('Error', 'Issue title cannot be empty');
              setShowingTemplateIssues(true);
              issuesValid = false;
            } else if (issuesValid && !issue.UserGroupId?.length) {
              showAlert('Error', 'Issue must be assigned to a user group');
              setShowingTemplateIssues(true);
              issuesValid = false;
            } else if (issuesValid && !issue.Priority) {
              showAlert('Error', 'Issues must have a priority level specified');
              setShowingTemplateIssues(true);
              issuesValid = false;
            }
          }
        });
        if (issuesValid) attemptUpdate();
      }
    }
  };

  const continuePress = () => {
    if (loading) return;
    setTemplateGroupDropdownShown(false);
    if (initialOption === InitialOptions.DEFAULT
      && !selectedTemplateId) setSelectedTemplateId(primedTemplateId);
    else if (!showingTemplateIssues) setShowingTemplateIssues(true);
    else validate();
  };

  const setDefaultIssues = () => {
    if (templateToUpdate?.issues) {
      setIssues(templateToUpdate?.issues?.map((issue: any) => ({
        UserGroupId: issue.UserGroupId ?? [],
        Text: issue.Text,
        Priority: issue.Priority,
        Id: issue.Id,
        Enabled: issue.Enabled ?? true,
      })));
    } else {
      const selectedTemplate: Template = templates
        ?.filter((template: Template) => template.id === primedTemplateId)[0];
      const templateIssues: Issue[] = selectedTemplate?.issues?.map((issue: any) => ({
        UserGroupId: issue.UserGroupId ?? [],
        Text: issue.Text,
        Priority: issue.Priority,
        Id: issue.Id,
        Enabled: issue.Enabled ?? true,
      })) ?? customTemplateDefaultIssues;
      setIssues(templateIssues);
    }
  };

  useEffect(() => {
    if (alertShown) setAlertDismissed(false);
  }, [alertShown]);

  const focusField = () => {
    if (showingTemplateIssues) setTemplateGroupDropdownShown(false);
    let count: number = 0;
    let fieldIndex: number = focusedFieldIndex;
    if (count < 5 && fieldIndex >= 0 && !showingTemplateIssues
      && ((focusedFieldIndex === 0 && nameField?.current)
        || (focusedFieldIndex === 1 && templateGroupSelector?.current)
        || focusedFieldIndex > 1)
      && !alertShown) {
      count += 1;
      if (focusedFieldIndex === 0) nameField?.current?.focus();
      else if (focusedFieldIndex === 1) templateGroupSelector?.current?.focus();
      else if (focusedFieldIndex > 1) additionalFieldRefs[focusedFieldIndex - 2]?.focus();
      fieldIndex = -1;
      if (!(focusedFieldIndex > 1)) setFocusedFieldIndex(-1);
    }
  };

  useEffect(focusField, [
    showingTemplateIssues, focusedFieldIndex, nameField?.current, templateGroupSelector?.current,
    alertDismissed, additionalFieldRefs[focusedFieldIndex],
  ]);

  useEffect(() => {
    const selectedTemplate: Template = templates
      ?.filter((template: Template) => template.id === selectedTemplateId)[0];
    setSelectedTemplateName(selectedTemplate?.name ?? templateToUpdate?.name ?? 'New template');
    setFields([...(selectedTemplate?.customfields ?? templateToUpdate?.customfields ?? [])
      ?.map((field: Field) => ({ ...field })) ?? []]);
    setDefaultIssues();
    if (selectedTemplate) {
      templateGroups?.forEach((group: TemplateGroup) => {
        group.buttonPrototypes?.forEach((prototype: Template) => {
          if (prototype.id === selectedTemplate.id) {
            setSelectedTemplateGroupID(group.ButtonPrototypeGroupId);
          }
        });
      });
    } else {
      setSelectedTemplateGroupID(defaultTemplateGroupID
        ?? templateGroups?.filter((group: TemplateGroup) => group.buttonPrototypes
          ?.map((item: Template) => item.id)?.includes(templateToUpdate?.id))[0]
          ?.ButtonPrototypeGroupId
        ?? templateGroups[0]?.ButtonPrototypeGroupId);
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    setTemplateGroupDropdownShown(false);
    setInitialState();
    if (isVisible) {
      setVisibilityToggleEnabled(false);
      const timeout = setTimeout(() => {
        setVisibilityToggleEnabled(true);
        clearTimeout(timeout);
      }, 500);
    } else setVisibilityToggleEnabled(true);
  }, [isVisible]);

  const closeModal = () => (visibilityToggleEnabled ? hide() : null);

  const showAlert = (title: string, message: string | ReactElement, error: boolean = true) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertError(error);
    setAlertShown(true);
  };

  return <>
    <Modal
      isVisible={isVisible}
      onSwipeComplete={closeModal}
      onBackButtonPress={closeModal}
      onBackdropPress={closeModal}
      style={styles.modal}
    >
      <View style={styles.container}>
        <Text style={styles.title}>
          {initialOption === InitialOptions.DEFAULT && !selectedTemplateId && 'Choose preset'}
          {((initialOption === InitialOptions.DEFAULT && selectedTemplateId)
            || initialOption === InitialOptions.CUSTOM)
            && (showingTemplateIssues ? 'Issues overview' : 'Template data')}
          {initialOption === InitialOptions.EDIT
            && `Edit ${showingTemplateIssues ? 'issues' : 'template'}`}
          {!initialOption && 'Choose template or create new one'}
        </Text>
        {initialOption && ((selectedTemplateId || initialOption === InitialOptions.CUSTOM) ? <>
          <View style={[styles.buttonContainer, styles.tabButtonContainer]}>
            {['TEMPLATE DATA', 'ISSUES'].map((title: string, index: number) => <TabOption
              key={`tab_${title}`}
              title={title}
              selected={index === 0 ? !showingTemplateIssues : showingTemplateIssues}
              onPress={() => setShowingTemplateIssues(index && true)}
            />)}
          </View>
          <ScrollView style={styles.containerPadding}>
            <Text style={styles.nameLabel}>{showingTemplateIssues ? 'Template\'s name' : 'Name *'}</Text>
            {showingTemplateIssues ? <View style={styles.templateNameContainer}>
              <Text style={styles.initialOptionTitle}>
                {selectedTemplateName}
              </Text>
              <TouchableOpacity
                style={styles.editIcon}
                onPress={() => {
                  setShowingTemplateIssues(false);
                  setFocusedFieldIndex(0);
                }}
              >
                <EditIcon24 />
              </TouchableOpacity>
            </View> : <>
              <TextInput
                ref={(ref) => {
                  nameField.current = ref;
                }}
                value={selectedTemplateName}
                onChangeText={setSelectedTemplateName}
                style={[
                  styles.nameLabel,
                  styles.nameField,
                  !selectedTemplateName?.length && { borderColor: styles.errorText.color },
                ]}
              />
              {!selectedTemplateName?.length
                && <Text style={styles.errorText}>Template name cannot be empty</Text>}
            </>}
            {!showingTemplateIssues && <>
              <Text style={[styles.nameLabel, styles.templateGroupLabel]}>Template group *</Text>
              <TouchableOpacity
                ref={(ref) => { templateGroupSelector.current = ref; }}
                style={[styles.templateGroupIndicator, Style.row]}
                onPress={() => setTemplateGroupDropdownShown(!templateGroupDropdownShown)}
              >
                <Text style={styles.templateGroupText}>
                  {templateGroups?.filter((group: TemplateGroup) => group.ButtonPrototypeGroupId
                    === selectedTemplateGroupID)[0]?.Name ?? 'Unknown template group'}
                </Text>
                <DropdownIcon12
                  color={Colors.light.hereworksBlue200}
                  style={templateGroupDropdownShown && styles.upsideDown}
                />
              </TouchableOpacity>
              {templateGroupDropdownShown && <ScrollView style={styles.templateGroupDropdown}>
                {templateGroups
                  ?.sort((a: TemplateGroup, b: TemplateGroup) => a.Name.localeCompare(b.Name))
                  ?.map((group: TemplateGroup) => <Option
                    key={`template_group_dropdown_option_${group.ButtonPrototypeGroupId}`}
                    text={group.Name}
                    onPress={() => {
                      setSelectedTemplateGroupID(group.ButtonPrototypeGroupId);
                      setTemplateGroupDropdownShown(false);
                    }}
                    selected={group.ButtonPrototypeGroupId === selectedTemplateGroupID}
                  />)}
              </ScrollView>}
            </>}
            <Text style={[styles.initialOptionTitle, styles.subTitle]}>
              {showingTemplateIssues ? 'Issues:' : 'Additional fields for assets'}
            </Text>
            <Text style={showingTemplateIssues
              ? styles.nameLabel : styles.additionalFieldsDescription}>
              {showingTemplateIssues
                ? 'These are the problems that the building occupants will report when scanning the Solve tags.'
                : 'These fields are unique to the assets that are going to be part of this template and have no connection to Solve.'}
            </Text>
            {showingTemplateIssues ? <IssueList
              {...{
                setIssues,
                issues,
                userGroups,
              }}
            /> : <FieldsList
              {...{ fields, setFields, refs: additionalFieldRefs }}
            />}
          </ScrollView>
        </> : <ScrollView style={[styles.templatesList, styles.containerPadding]}>
          {templates?.map((template: Template) => <TemplateOption
            key={`template_option_${template.id}`}
            {...template}
            selected={primedTemplateId === template.id}
            onPress={() => setPrimedTemplateId(template.id)}
          />)}
        </ScrollView>)}
        {initialOption ? <View style={styles.buttonContainer}>
          <Button
            onPress={cancel}
            title='Cancel'
          />
          <Button
            disabled={initialOption === InitialOptions.DEFAULT && !primedTemplateId}
            onPress={continuePress}
            affirmative
            title={showingTemplateIssues ? 'Confirm' : 'Continue'}
          />
        </View> : <View style={styles.initialOptionsContainer}>
          <InitialOption
            onPress={() => setInitialOption(InitialOptions.DEFAULT)}
            title='Default'
            description='Create a template from a ready-made preset'
          />
          <InitialOption
            onPress={() => {
              setInitialState();
              setInitialOption(InitialOptions.CUSTOM);
            }}
            title='Custom'
            description='Create a template completely from scratch'
          />
        </View>}
        <ActivityIndicator
          size='large'
          animating={loading}
          style={Style.activityIndicator}
        />
        <TouchableOpacity
          onPress={hide}
          style={Style.closeButton}
        >
          <CloseModalIcon />
        </TouchableOpacity>
      </View>
    </Modal>
    <AlertModal
      onDismiss={() => setAlertDismissed(true)}
      modalVisible={alertShown}
      setModalVisible={() => setAlertShown(false)}
      title={alertTitle}
      message={alertMessage}
      error={alertError}
    />
  </>;
};

const styles = StyleSheet.create({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
  container: {
    paddingBottom: mobile ? 16 : 40,
    margin: mobile ? 16 : 20,
    paddingTop: 60,
    borderRadius: 10,
    backgroundColor: '#FFF',
    width: mobile ? '90%' : 530,
    maxHeight: mobile ? '85%' : '90%',
  },
  containerPadding: {
    paddingHorizontal: mobile ? 16 : 40,
    paddingVertical: 12,
  },
  templateNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 28,
    lineHeight: 35,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
    textAlign: 'center',
    marginBottom: 32,
    width: mobile ? '75%' : 356,
    alignSelf: 'center',
  },
  initialOptionsContainer: {
    paddingHorizontal: mobile ? 16 : 40,
  },
  initialOption: {
    paddingVertical: 16,
    paddingHorizontal: 12,
    borderRadius: 5,
    backgroundColor: Colors.light.hereworksWhite200,
    borderColor: Colors.light.hereworksBlue100,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  initialOptionContent: {
    flex: 1,
  },
  initialOptionTitle: {
    fontSize: 20,
    lineHeight: 30,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksBlack600,
  },
  initialOptionDescription: {
    fontSize: 16,
    lineHeight: 19,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
  },
  buttonContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    marginTop: mobile ? 15 : 28,
  },
  templatesList: {
    height: 269,
  },
  templateOption: {
    flex: 1,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: Colors.light.hereworksWhite100,
    borderColor: Colors.light.hereworksBlue100,
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedTemplateOption: {
    backgroundColor: Colors.light.hereworksBlue500,
  },
  templateOptionText: {
    fontSize: 14,
    lineHeight: 21,
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_600SemiBold',
    marginStart: 12,
  },
  selectedTemplateOptionText: {
    color: '#FFF',
  },
  tabButtonContainer: {
    marginTop: 0,
    marginBottom: 12,
  },
  tabButton: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue300,
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4,
    borderRadius: 4,
  },
  tabButtonText: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_500Medium',
    color: Colors.light.hereworksBlue400,
  },
  tabButtonSelected: {
    backgroundColor: Colors.light.hereworksBlue500,
    borderColor: Colors.light.hereworksBlue500,
  },
  tabButtonTextSelected: {
    color: '#FFF',
  },
  nameLabel: {
    fontSize: 16,
    lineHeight: Platform.OS === 'ios' ? undefined : 24,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
  },
  nameField: {
    paddingVertical: 10,
    height: 44,
    paddingHorizontal: 8,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue400,
    backgroundColor: Colors.light.hereworksWhite200,
    justifyContent: 'center',
  },
  subTitle: {
    marginTop: 24,
  },
  additionalFieldsDescription: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack300,
    marginBottom: 16,
  },
  editIcon: {
    marginStart: 12,
  },
  templateGroupLabel: {
    marginTop: 12,
    marginBottom: 4,
  },
  templateGroupIndicator: {
    alignSelf: 'stretch',
    paddingHorizontal: 12,
    paddingVertical: 9,
    borderRadius: 10,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
  },
  templateGroupText: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
    flex: 1,
  },
  upsideDown: {
    transform: [{ rotate: '180deg' }],
  },
  templateGroupDropdown: {
    maxHeight: 180,
    alignSelf: 'stretch',
    marginTop: 4,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: Colors.light.hereworksBlue100,
    backgroundColor: '#FFF',
  },
  errorText: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksRed500,
  },
  boldText: {
    fontFamily: 'Roboto_700Bold',
  },
});

export default AddTemplateModal;
