import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Dimensions,
  useWindowDimensions,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Camera } from 'expo-camera';
import Modal from 'react-native-modal';
import * as Analytics from 'expo-firebase-analytics';
import * as ImagePicker from 'expo-image-picker';
import { FontAwesome } from '@expo/vector-icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Haptics from 'expo-haptics';
import Style from '../../constants/Style';
import CloseModalIcon from '../CloseModalIcon';
import CustomTextField from '../CustomTextField';
import Colors from '../../constants/Colors';
import DeleteIcon24 from '../24pxIcons/DeleteIcon24';
import PlaceholderImageIcon from '../PlaceholderImageIcon';
import BinIcon24 from '../24pxIcons/BinIcon24';
import updateAsset from '../../requests/UpdateAsset';
import uploadAssetImage from '../../requests/UploadAssetImage';
import getLocations from '../../requests/GetLocations';
import AlertModal from '../AlertModal';

const geoRegexString: string = '^[0-9]*$|^$';

export default function EditAssetModal(props) {
  const originalCustomFields = [...props.customFields];
  const geoFields = originalCustomFields.filter((field) => field.CustomName.toLowerCase().includes('geo'));
  geoFields?.forEach((field) => {
    if (!field.Format) {
      originalCustomFields
        .filter((ogField) => ogField.CustomName === field.CustomName)
        ?.forEach((geoField) => { geoField.Format = geoRegexString; });
    }
  });
  if (!geoFields?.length) {
    originalCustomFields.push({
      CustomName: 'GeoX', CustomValue: '', IsReadOnlyName: true, Format: geoRegexString,
    });
    originalCustomFields.push({
      CustomName: 'GeoY', CustomValue: '', IsReadOnlyName: true, Format: geoRegexString,
    });
  }
  const [essentialTabSelected, setEssentialTabSelected] = useState(true);
  const [image, setImage] = useState(
    props.asset.Images[0]?.url !== undefined
      ? `${props.asset.Images[0]?.url}?time=${Date.now()}`
      : null,
  );
  const [imageChanged, setImageChanged] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [taking, setTaking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customFields, setCustomFields] = useState(
    JSON.parse(
      JSON.stringify(
        originalCustomFields?.sort((previous, next) => {
          if (previous.CustomName < next.CustomName) {
            return -1;
          }
          if (previous.CustomName > next.CustomName) {
            return 1;
          }
          return 0;
        }),
      ),
    ) ?? [],
  );
  const [name, setName] = useState(props.name);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [manufacturer, setManufacturer] = useState(props.manufacturer);
  const [serial, setSerial] = useState(props.serial);
  const [description, setDescription] = useState(props.description);
  const [model, setModel] = useState(props.model);
  const [geoXInvalid, setGeoXInvalid] = useState(false);
  const [geoYInvalid, setGeoYInvalid] = useState(false);

  const [locationDropdownVisible, setLocationDropdownVisible] = useState(false);
  const [locationSelection, setLocationSelection] = useState('');
  const [locationInvalid, setLocationInvalid] = useState(false);
  const [locationId, setLocationId] = useState(props.locationId);
  const [building, setBuilding] = useState(props.building);
  const [floor, setFloor] = useState(props.floor);
  const [area, setArea] = useState(props.area);
  const [room, setRoom] = useState(props.room);
  const [locations, setLocations] = useState([
    {
      Name: 'Location 1',
      LocationId: '1',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 2',
      LocationId: '2',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 3',
      LocationId: '3',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 4',
      LocationId: '4',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 5',
      LocationId: '5',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 6',
      LocationId: '6',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
  ]);

  const ref = useRef(null);
  const { width } = useWindowDimensions();
  const height = Math.round((width * 16) / 9);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertAction, setAlertAction] = useState(null);
  const [editSuccess, setEditSuccess] = useState(false);

  React.useEffect(() => {
    getLocations().then((response) => {
      if (response?.locations?.length > 0) {
        const locationList = response.locations;
        setLocations(locationList);
        const selectedLocationId = locationList.filter(
          (location) => location.LocationId === locationId,
        )[0]?.Name;
        if (selectedLocationId) setLocationSelection(selectedLocationId);
      }
    });
  }, []);

  const showAlert = (
    title: string,
    message?: string | ReactElement,
    action: () => void = null,
    error: boolean = true,
  ) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertAction(() => action);
    setAlertError(error);
    setAlertVisible(true);
  };

  const restoreDefaults = () => {
    setName(props.name);
    setNameInvalid(false);
    setModel(props.model);
    setSerial(props.serial);
    setDescription(props.description);
    setManufacturer(props.manufacturer);
    setBuilding(props.building);
    setArea(props.area);
    setFloor(props.floor);
    setRoom(props.room);
    setLocationId(props.locationId);
    setLocationInvalid(false);
    setGeoXInvalid(false);
    setGeoYInvalid(false);
    setCustomFields(
      JSON.parse(
        JSON.stringify(
          originalCustomFields?.sort((previous, next) => {
            if (previous.CustomName < next.CustomName) {
              return -1;
            }
            if (previous.CustomName > next.CustomName) {
              return 1;
            }
            return 0;
          }),
        ),
      ) ?? [],
    );
    setImage(
      props.asset.Images[0]?.url !== undefined
        ? `${props.asset.Images[0]?.url}?time=${Date.now()}`
        : null,
    );
    setImageChanged(false);
    setEssentialTabSelected(true);
  };

  const handleImage = (result) => {
    if (result.assets?.length > 0) {
      const { base64 } = result.assets[0];
      setImage(`data:image/jpeg;base64,${base64}`);
      setImageChanged(true);
    }
  };

  const pickImage = async () => {
    analytics('Upload');
    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 0.3,
      base64: true,
    });

    handleImage(result);
  };

  const analytics = (title) => {
    Analytics.logEvent('button_press', {
      screen: 'EditAssetModal',
      name: title,
    });
  };

  const topSafeAreaInset = useSafeAreaInsets().top;

  const updateAssetCall = () => (
    updateAsset(
      props.asset.ButtonInstanceId,
      props.id,
      model,
      serial,
      manufacturer,
      description,
      locationId,
      customFields,
      name,
    )
      .then(async (response) => {
        const json = await response.json();
        const success = response.status === 200 && !json.isError;
        if (success) {
          props.updateName(name);
          props.updateManufacturer(manufacturer);
          props.updateDescription(description);
          props.updateModel(model);
          props.updateSerial(serial);
          props.updateBuilding(building);
          props.updateFloor(floor);
          props.updateArea(area);
          props.updateRoom(room);
          props.updateLocationId(locationId);
          props.updateCustomFields(customFields);
          setEditSuccess(true);
          showAlert(
            'Updated asset!',
            <Text>
              Asset <Text style={styles.alertAssetName}>{name}</Text> successfully updated
            </Text>,
            props.updateAsset({
              ...props.asset,
              Name: name,
              Model: model,
              Serial: serial,
              Description: description,
              Manufacturer: manufacturer,
              LocationId: locationId,
              LocationBuilding: building,
              LocationFloor: floor,
              LocationRoom: room,
              LocationArea: area,
              CustomFields: customFields,
            }),
            false,
          );
        } else showAlert('Error', 'Failed to update asset details. Please try again later.');
        setLoading(false);
      })
      .catch(() => {
        showAlert('Error', 'Failed to update asset details. Please try again later.');
        setLoading(false);
      })
  );

  const save = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    analytics('Save');
    props.updateImage(image);
    if (imageChanged) {
      uploadAssetImage(props.asset.ButtonInstanceId, image).then((result) => {
        if (result.isError) {
          showAlert('Error', 'Image Upload Failed');
          setLoading(false);
        } else {
          updateAssetCall();
        }
      });
    } else {
      updateAssetCall();
    }
  };

  useEffect(() => {
    if (!alertVisible && !alertError && editSuccess) {
      props.setModalVisible(false);
    }
  }, [alertVisible]);

  const renderLocationDropdown = () => (
    <View
      style={{
        height: 200,
        alignSelf: 'stretch',
      }}
    >
      <ScrollView
        nestedScrollEnabled={true}
        indicatorStyle={'black'}
        style={[styles.dropdownView, props.mobile ? { width: '100%' } : {}]}
      >
        {locations?.map((location, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => {
              analytics('Location selected');
              setLocationSelection(location.Name);
              setLocationId(location.LocationId);
              setLocationInvalid(false);
              setBuilding(location.Building ?? '');
              setArea(location.Area ?? '');
              setFloor(location.Floor ?? '');
              setRoom(location.Room ?? '');
              setLocationDropdownVisible(!locationDropdownVisible);
            }}
          >
            <Text style={styles.dropdownItemText}>{location.Name}</Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );

  const renderSaveContinueButton = () => (
    <TouchableOpacity
      style={[
        {
          backgroundColor: Colors.light.hereworksLightBlue,
          borderRadius: 4,
          borderWidth: 1,
          borderColor: Colors.light.hereworksBlue500,
        },
        props.mobile && { flex: 1 },
      ]}
      onPress={() => {
        analytics('Save');
        let valid: boolean = true;
        setNameInvalid(!(name?.trim().length > 0));
        setLocationInvalid(!locationId);
        const newGeoFields: any[] = customFields?.filter((field) => field.CustomName.toLowerCase().includes('geo')) ?? [];
        const geoXField: any = newGeoFields.filter((field) => field.CustomName.toLowerCase().includes('x'))[0];
        const geoYField: any = newGeoFields.filter((field) => field.CustomName.toLowerCase().includes('y'))[0];
        if (!(name?.trim().length > 0)) {
          if (valid) showAlert('Error', 'Asset name cannot be empty');
          valid = false;
        }
        if (!locationId) {
          if (valid) showAlert('Error', 'Location cannot be empty');
          valid = false;
        }
        if (geoXField && !(new RegExp(geoXField?.Format)
          .test(geoXField?.CustomValue?.toString()))) {
          if (valid) showAlert('Error', 'Invalid value for GeoX');
          setGeoXInvalid(true);
          valid = false;
        }
        if (geoYField && !(new RegExp(geoYField?.Format)
          .test(geoYField?.CustomValue?.toString()))) {
          if (valid) showAlert('Error', 'Invalid value for GeoY');
          setGeoYInvalid(true);
          valid = false;
        }
        if (valid) {
          save();
        }
      }}
    >
      <Text
        style={{
          color: '#fff',
          fontFamily: 'Roboto_400Regular',
          fontSize: 16,
          marginVertical: 8,
          marginHorizontal: 24,
          textAlign: 'center',
        }}
      >
        {'Save'}
      </Text>
    </TouchableOpacity>
  );

  const renderBackCancelButton = () => (
    <TouchableOpacity
      style={[
        {
          backgroundColor: '#fff',
          borderRadius: 4,
          borderWidth: 1,
          borderColor: Colors.light.hereworksBlue500,
          marginRight: 12,
        },
        props.mobile && { flex: 1 },
      ]}
      onPress={() => {
        analytics('Cancel');
        props.setModalVisible(false);
        restoreDefaults();
      }}
    >
      <Text
        style={{
          color: Colors.light.hereworksBlue500,
          fontFamily: 'Roboto_400Regular',
          fontSize: 16,
          marginVertical: 8,
          marginHorizontal: 24,
          textAlign: 'center',
        }}
      >
        {'Cancel'}
      </Text>
    </TouchableOpacity>
  );

  const renderCustomField = (field, deleteAction) => {
    let title = field.CustomName;
    if (title.includes('Date')) {
      title = `${title.split('Date')[0]} Date`;
    } else if (title.includes('date')) {
      title = `${title.split('date')[0]} Date]`;
    }
    if (title.includes('Name')) {
      title = `${title.split('Name')[0]} Name`;
    } else if (title.includes('name')) {
      title = `${title.split('name')[0]} Name`;
    }
    if (title.includes('Number')) {
      title = `${title.split('Number')[0]} Number`;
    } else if (title.includes('number')) {
      title = `${title.split('number')[0]} Number`;
    }
    if (title.includes('Address')) {
      title = `${title.split('Address')[0]} Address ${title.split('Address')[1]}`;
    } else if (title.includes('address')) {
      title = `${title.split('address')[0]} Address ${title.split('address')[1]}`;
    }
    const isGeoField: boolean = field?.CustomName.toLowerCase().includes('geo');
    const isGeoX: boolean = isGeoField && field?.CustomName.toLowerCase().includes('x');
    const isGeoY: boolean = isGeoField && field?.CustomName.toLowerCase().includes('y');
    return (
      <View
        style={{
          marginVertical: 10,
          borderWidth: 1,
          borderRadius: 8,
          borderColor: Colors.light.hereworksBlue50,
          padding: 8,
          alignSelf: 'stretch',
        }}
      >
        <View style={{ flexDirection: props.mobile ? 'column' : 'row' }}>
          <View style={{ flex: 1 }}>
            <CustomTextField
              mobile={props.mobile}
              title={'Name'}
              value={title}
              editable={!field.IsReadOnlyName}
              updateValue={(text) => {
                customFields?.forEach((customField, key) => {
                  customField.key = key;
                  if (customField.key === field.key) {
                    customField.CustomName = text;
                  }
                });
                setCustomFields([...customFields]);
              }}
            />
          </View>
          <View style={{ flex: 1, marginLeft: props.mobile ? 0 : 12 }}>
            <CustomTextField
              mobile={props.mobile}
              title={'Content'}
              value={field.CustomValue}
              invalid={(isGeoX && geoXInvalid) || (isGeoY && geoYInvalid)}
              errorText={isGeoField && (`${isGeoX ? 'GeoX' : 'GeoY'} value can only have numeric digits`)}
              updateValue={(text: string) => {
                customFields?.forEach((customField, key) => {
                  customField.key = key;
                  if (customField.key === field.key) {
                    customField.CustomValue = text;
                  }
                  if (isGeoField && isGeoX) {
                    setGeoXInvalid(!(new RegExp(field.Format).test(text)));
                  } else if (isGeoField && isGeoY) {
                    setGeoYInvalid(!(new RegExp(field.Format).test(text)));
                  }
                });
                setCustomFields([...customFields]);
              }}
              scanner={field.CustomName.toLowerCase().includes('mac')}
            />
          </View>
        </View>
        {!field.IsReadOnlyName && (
          <TouchableOpacity
            style={{ position: 'absolute', top: 8, right: 8 }}
            onPress={deleteAction}
          >
            <DeleteIcon24 />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  return (
    <Modal
      isVisible={props.visible}
      animationIn={props.mobile ? 'slideInUp' : 'fadeIn'}
      animationOut={props.mobile ? 'slideOutDown' : 'fadeOut'}
      onBackButtonPress={() => props.setModalVisible(false)}
      style={{
        margin: 0,
        justifyContent: props.mobile ? undefined : 'center',
        flex: 1,
      }}
    >
      <View
        style={[
          {
            alignSelf: props.mobile ? 'auto' : 'center',
            flex: 1,
          },
          !props.mobile && styles.containerStyleWeb,
        ]}
      >
        <ScrollView
          nestedScrollEnabled
          style={{ borderRadius: props.mobile ? 0 : 10 }}
          contentContainerStyle={[
            {
              flexGrow: 1,
              // alignItems: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <View
            style={[
              styles.containerStyle,
              {
                width: props.mobile ? undefined : 530,
                paddingHorizontal: props.mobile ? 24 : 40,
                paddingTop: useSafeAreaInsets().top,
                paddingBottom: 30,
                alignItems: 'center',
              },
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                analytics('Close Modal');
                props.setModalVisible(false);
                restoreDefaults();
              }}
              style={[Style.closeButton, props.mobile && { top: topSafeAreaInset ?? 32 }]}
            >
              <CloseModalIcon />
            </TouchableOpacity>
            <Text style={styles.title}>Edit asset {props.name}</Text>
            <Text style={styles.subTitle}>fields marked with * are mandatory</Text>
            <View style={{ flexDirection: 'row', marginTop: props.mobile ? 24 : 32 }}>
              <TouchableOpacity
                style={[
                  styles.smallButton,
                  {
                    backgroundColor: !essentialTabSelected ? '#fff' : Colors.light.hereworksBlue500,
                    borderColor: !essentialTabSelected
                      ? Colors.light.hereworksBlue300
                      : Colors.light.hereworksBlue500,
                  },
                ]}
                onPress={() => setEssentialTabSelected(!essentialTabSelected)}
              >
                <Text
                  style={[
                    styles.smallButtonText,
                    {
                      color: !essentialTabSelected ? Colors.light.hereworksBlue400 : '#fff',
                    },
                  ]}
                >
                  ESSENTIAL INFO
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.smallButton,
                  {
                    backgroundColor: essentialTabSelected ? '#fff' : Colors.light.hereworksBlue500,
                    borderColor: essentialTabSelected
                      ? Colors.light.hereworksBlue300
                      : Colors.light.hereworksBlue500,
                  },
                ]}
                onPress={() => setEssentialTabSelected(!essentialTabSelected)}
              >
                <Text
                  style={[
                    styles.smallButtonText,
                    {
                      color: essentialTabSelected ? Colors.light.hereworksBlue400 : '#fff',
                    },
                  ]}
                >
                  ADDITIONAL INFO
                </Text>
              </TouchableOpacity>
            </View>
            {essentialTabSelected && (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    borderWidth: 1,
                    marginTop: 24,
                    padding: 12,
                    borderColor: Colors.light.hereworksBlue100,
                    borderRadius: 10,
                    alignSelf: 'stretch',
                  }}
                >
                  {props.asset.Images?.length > 0 || image !== null ? (
                    <Image
                      key={Date.now()}
                      source={{
                        uri: image,
                      }}
                      resizeMode={'cover'}
                      style={{ width: 120, height: 120 }}
                    />
                  ) : (
                    <View
                      style={{
                        width: 120,
                        height: 120,
                        backgroundColor: Colors.light.hereworksWhite200,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <PlaceholderImageIcon />
                    </View>
                  )}
                  <View style={{ marginLeft: 32, justifyContent: 'center' }}>
                    <Text style={styles.imageTitle}>Image</Text>
                    <View style={{ marginTop: 16, flexDirection: props.mobile ? 'column' : 'row' }}>
                      {props.mobile && (
                        <TouchableOpacity
                          style={[
                            styles.smallButton,
                            {
                              alignItems: 'center',
                              alignSelf: props.mobile ? 'stretch' : 'auto',
                            },
                          ]}
                          onPress={() => setCameraOpen(true)}
                        >
                          <Text style={styles.smallButtonText}>Take a photo</Text>
                        </TouchableOpacity>
                      )}
                      {
                        <Modal
                          style={{ margin: 0 }}
                          isVisible={cameraOpen}
                          onBackButtonPress={() => setCameraOpen(false)}
                        >
                          <Camera
                            useCamera2Api={true}
                            style={{
                              height,
                              width: '100%',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                            ref={ref}
                          >
                            <TouchableOpacity
                              style={{
                                borderWidth: 6,
                                borderColor: '#fff',
                                borderRadius: 35,
                                width: 70,
                                height: 70,
                                backgroundColor: 'transparent',
                                padding: 8,
                                marginBottom: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onPress={() => {
                                // Haptics.notificationAsync();
                                // Haptics.selectionAsync();
                                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
                                setTaking(true);
                                ref.current.takePictureAsync({ base64: true, quality: 0.3 })
                                  .then((picture) => {
                                    setImage(`data:image/jpeg;base64,${picture.base64}`);
                                    setImageChanged(true);
                                    setCameraOpen(false);
                                    setTaking(false);
                                  });
                              }}
                            >
                              <View
                                style={{
                                  backgroundColor: '#fff',
                                  width: 50,
                                  height: 50,
                                  borderRadius: 25,
                                }}
                              />
                              {taking && <ActivityIndicator style={{ position: 'absolute' }} />}
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => {
                                analytics('Close Camera');
                                setCameraOpen(false);
                              }}
                              style={[
                                Style.closeButton,
                                props.mobile && { top: topSafeAreaInset ?? 32 },
                              ]}
                            >
                              <CloseModalIcon />
                            </TouchableOpacity>
                          </Camera>
                        </Modal>
                      }
                      <TouchableOpacity
                        style={[
                          styles.smallButton,
                          {
                            backgroundColor: '#fff',
                            borderColor: Colors.light.hereworksBlue400,
                            marginTop: props.mobile ? 8 : 0,
                            alignItems: 'center',
                            alignSelf: props.mobile ? 'stretch' : 'auto',
                          },
                        ]}
                        onPress={pickImage}
                      >
                        <Text
                          style={[styles.smallButtonText, { color: Colors.light.hereworksBlue500 }]}
                        >
                          Upload
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <CustomTextField
                  mobile={props.mobile}
                  title={'Name'}
                  required
                  invalid={nameInvalid}
                  value={name}
                  updateValue={(text: string) => {
                    setNameInvalid(!(text.toString().trim().length > 0));
                    setName(text);
                  }}
                />
                <CustomTextField
                  mobile={props.mobile}
                  title={'Asset ID'}
                  value={props.asset.ButtonInstanceId}
                  editable={false}
                  updateValue={null}
                />
                <CustomTextField
                  mobile={props.mobile}
                  title={'Template'}
                  value={props.asset.ButtonPrototypeName}
                  editable={false}
                  updateValue={null}
                />
                <CustomTextField
                  mobile={props.mobile}
                  title={'Model'}
                  value={model}
                  updateValue={setModel}
                />
                <CustomTextField
                  mobile={props.mobile}
                  title={'Serial number'}
                  value={serial}
                  updateValue={setSerial}
                  scanner
                />
                <CustomTextField
                  mobile={props.mobile}
                  title={'Manufacturer'}
                  value={manufacturer}
                  updateValue={setManufacturer}
                />
                <CustomTextField
                  mobile={props.mobile}
                  title={'Description'}
                  value={description}
                  updateValue={setDescription}
                />
                <Text style={styles.heading}>Location *</Text>
                <TouchableOpacity
                  style={[styles.dropdownBox, locationInvalid && styles.dropdownBoxInvalid]}
                  onPress={() => {
                    analytics('Location dropdown');
                    setLocationDropdownVisible(!locationDropdownVisible);
                  }}
                >
                  <Text style={styles.dropdownBoxText}>{locationSelection}</Text>
                  <View style={{ alignItems: 'flex-end' }}>
                    <FontAwesome
                      size={20}
                      style={{
                        color: Colors.light.hereworksLightBlue,
                        marginRight: 12,
                      }}
                      name={locationDropdownVisible ? 'angle-up' : 'angle-down'}
                    />
                  </View>
                </TouchableOpacity>
                {locationInvalid && <Text style={styles.errorText}>Please select location</Text>}
                {locationDropdownVisible && renderLocationDropdown()}
                <CustomTextField
                  mobile={props.mobile}
                  title={'Building'}
                  value={building}
                  updateValue={setBuilding}
                  editable={false}
                />
                {area?.length ? <CustomTextField
                  mobile={props.mobile}
                  title={'Area'}
                  value={area}
                  updateValue={setArea}
                  editable={false}
                /> : null}
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View style={{ flex: 1 }}>
                    <CustomTextField
                      mobile={props.mobile}
                      title={'Floor'}
                      value={floor}
                      updateValue={setFloor}
                      editable={false}
                    />
                  </View>
                  <View style={{ flex: 1, marginLeft: 16 }}>
                    <CustomTextField
                      mobile={props.mobile}
                      title={'Room'}
                      value={room}
                      updateValue={setRoom}
                      editable={false}
                    />
                  </View>
                </View>
              </>
            )}
            {!essentialTabSelected && (
              <ScrollView
                nestedScrollEnabled={true}
                style={{
                  height: 450,
                  alignSelf: 'stretch',
                }}
              >
                {customFields?.map((field, index) => {
                  if (
                    field.CustomName.toLowerCase().includes('serial')
                    || field.CustomName.toLowerCase().includes('manu')
                    || field.CustomName.toLowerCase().includes('desc')
                    || field.CustomName.toLowerCase().includes('model')
                    || field.CustomName.toLowerCase().includes('building')
                    || field.CustomName.toLowerCase().includes('floor')
                    || field.CustomName.toLowerCase().includes('room')
                    || field.CustomName.toLowerCase() === 'name'
                  ) {
                    return null;
                  }
                  return (
                    <View key={index}>
                      {renderCustomField(field, () => {
                        analytics('Delete field');
                        const newFields = Object.assign([], customFields);
                        newFields.splice(index, 1);
                        setCustomFields(newFields);
                      })}
                    </View>
                  );
                })}
                {/* <TouchableOpacity
                  style={styles.smallButton}
                  onPress={() => {
                    const newFields = Object.assign([], customFields);
                    const newField = { key: customFields.length + 1, name: '' };
                    newFields.push(newField);
                    setCustomFields(newFields);
                  }}
                >
                  <Text style={styles.smallButtonText}>Add field</Text>
                </TouchableOpacity> */}
              </ScrollView>
            )}
            <View style={{ flexDirection: 'row', marginTop: 27, alignSelf: 'stretch' }}>
              {renderBackCancelButton()}
              {renderSaveContinueButton()}
              {!props.mobile && (
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={styles.deleteButton}
                    onPress={() => {
                      analytics('Delete asset');
                      showAlert('Feature coming soon!', null, null, false);
                    }}
                  >
                    <BinIcon24 color={Colors.light.hereworksRed} />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      </View>
      <AlertModal
        modalVisible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        error={alertError}
        action={alertAction}
        setModalVisible={setAlertVisible}
      />
      <ActivityIndicator animating={loading} size="large" style={styles.activityIndicator} />
    </Modal>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  containerStyleWeb: {
    minHeight: 634,
    marginVertical: 40,
  },
  title: {
    marginTop: 70,
    fontSize: 22,
    lineHeight: 33,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
    marginVertical: 10,
    textAlign: 'center',
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack300,
    textAlign: 'center',
  },
  imageTitle: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.light.hereworksBlack400,
    fontFamily: 'Roboto_400Regular',
  },
  circle: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: Colors.light.hereworksDarkBlue,
    marginTop: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleText: {
    fontSize: 16,
    fontFamily: 'Poppins_600SemiBold',
    color: '#fff',
  },
  smallButtonText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    marginVertical: 8,
    marginHorizontal: 12,
    color: '#fff',
  },
  smallButton: {
    borderRadius: 4,
    marginRight: 12,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    backgroundColor: Colors.light.hereworksBlue500,
    alignSelf: 'center',
  },
  deleteButton: {
    borderColor: Colors.light.hereworksRed,
    borderRadius: 4,
    borderWidth: 1,
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
    alignSelf: 'flex-end',
  },
  activityIndicator: {
    position: 'absolute',
    alignSelf: 'center',
    top: Dimensions.get('window').height * 0.45,
  },
  heading: {
    fontSize: 16,
    lineHeight: 24,
    marginTop: 12,
    marginBottom: 4,
    alignSelf: 'flex-start',
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
  },
  dropdownView: {
    zIndex: 5,
    borderRadius: 10,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksPaleBlue900,
    backgroundColor: '#fff',
  },
  dropdownItemText: {
    marginLeft: 12,
    marginVertical: 9,
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
  },
  dropdownBox: {
    width: '100%',
    height: 44,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
    borderRadius: 10,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    zIndex: 2,
  },
  dropdownBoxText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    marginVertical: 9.5,
    marginLeft: 12,
    flex: 1,
  },
  dropdownBoxInvalid: {
    borderColor: Colors.light.hereworksRed500,
  },
  alertAssetName: {
    fontFamily: 'Roboto_700Bold',
  },
  errorText: {
    color: Colors.light.hereworksRed500,
    fontFamily: 'Roboto_400Regular',
    marginTop: 4,
    fontSize: 12,
    lineHeight: 20,
    alignSelf: 'flex-start',
  },
});
