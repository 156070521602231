import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ProfileIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M17 8A5 5 0 1 1 7 8a5 5 0 0 1 10 0Zm-8 5a5 5 0 0 0-5 5v1s2 2 8 2 8-2 8-2v-1a5 5 0 0 0-5-5H9Z"
      stroke="#3379BD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ProfileIcon;
