import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import {
  View, StyleSheet, Text, TouchableOpacity, Platform, Dimensions, ScrollView,
} from 'react-native';
import { DraxProvider, DraxList } from 'react-native-drax';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import Colors from '../../../constants/Colors';
import IssueRow, {
  UserGroup, Issue, priorityColor, Option, ISSUE_PRIORITY_LIST,
} from './IssueRow';
import Switch from '../../Switch';
import QuestionIcon from '../../24pxIcons/QuestionIcon';
import Button from '../../Button';
import Style from '../../../constants/Style';
import PlusIcon from '../../16pxIcons/PlusIcon';
import DropdownIcon from '../../16pxIcons/DropdownIcon';
import PriorityFlag from '../../16pxIcons/PriorityFlag';

const mobile = Platform.OS !== 'web' || Dimensions.get('window').width < 500;

interface IssueListProps {
  issues: Issue[],
  setIssues: Dispatch<SetStateAction<Issue[]>>,
  userGroups: UserGroup[],
}

const otherIssueHelpText: string = 'Switching this on lets the user report a miscellaneous issue, where they can specify exactly what\'s wrong';

export const customTemplateDefaultIssues: Issue[] = [
  {
    Text: '',
    Id: 'Issue1',
    Priority: 3,
    Enabled: true,
  },
  {
    Text: '',
    Id: 'Issue2',
    Priority: 0,
    Enabled: true,
  },
  {
    Text: '',
    Id: 'Issue3',
    Priority: 0,
    Enabled: true,
  },
  {
    Text: '',
    Id: 'Issue4',
    Priority: 0,
    Enabled: true,
  },
];

const IssueList = ({ issues, setIssues, userGroups }: IssueListProps) => {
  const [otherIssueHelpShown, setOtherIssueHelpShown] = useState<boolean>(false);
  const [otherIssueEnabled, setOtherIssueEnabled] = useState<boolean>(
    issues?.filter((issue: Issue) => issue.Text?.toLowerCase() === 'other')[0]?.Enabled ?? false,
  );
  const [otherIssuePriority, setOtherIssuePriority] = useState<number>(
    issues?.filter((issue: Issue) => issue.Text?.toLowerCase() === 'other')[0]?.Priority ?? 3,
  );
  const [priorityDropdownShown, setPriorityDropdownShown] = useState<boolean>(false);

  useEffect(() => {
    setPriorityDropdownShown(false);
    const updatedIssues: Issue[] = [...issues];
    const otherIssueExists: boolean = issues
      ?.filter((issue: Issue) => issue.Text?.toLowerCase() === 'other')?.length > 0;
    if (otherIssueExists) {
      updatedIssues?.forEach((issue: Issue) => {
        if (issue.Text?.toLowerCase() === 'other') {
          issue.Priority = otherIssuePriority;
          issue.Enabled = otherIssueEnabled;
        }
      });
    } else {
      updatedIssues.push({
        Text: 'Other',
        Priority: otherIssuePriority,
        Enabled: otherIssueEnabled,
        UserGroupId: [],
      });
    }
    setIssues(updatedIssues);
  }, [otherIssueEnabled, otherIssuePriority]);

  const toggleHelpBoxVisibility = () => {
    if (otherIssueHelpShown) setOtherIssueHelpShown(false);
    else {
      setOtherIssueHelpShown(true);
      const timeout = setTimeout(() => {
        setOtherIssueHelpShown(false);
        clearTimeout(timeout);
      }, 600 + 400 * otherIssueHelpText.split(' ').length);
    }
  };

  const addIssue = () => {
    const issuesToUpdate: Issue[] = [...issues];
    issuesToUpdate.push({
      Text: '',
      UserGroupId: null,
      Priority: 3,
      Enabled: true,
      LocalId: `${new Date().getMilliseconds()}`,
    });
    setIssues(issuesToUpdate);
  };

  return <View style={styles.container}>
    <GestureHandlerRootView style={styles.stretch}>
      <DraxProvider style={styles.stretch}>
        <DraxList
          disableScrollViewPanResponder
          scrollEnabled={false}
          data={issues?.filter((issue: Issue) => issue.Text?.toLowerCase() !== 'other')}
          CellRendererComponent={({ children }) => children}
          removeClippedSubviews={false}
          onItemReorder={({ fromIndex, toIndex }) => {
            const newData = issues.slice();
            newData.splice(toIndex, 0, newData.splice(fromIndex, 1)[0]);
            setIssues(newData);
          }}
          renderItemContent={({ item }) => <IssueRow
            key={`issue_${item.Id}`}
            deleteIssue={() => setIssues(issues?.filter((issue: Issue) => issue.Id !== item.Id))}
            updateIssue={(title: string, tags: string[], priority: number, enabled: boolean) => {
              const issuesToUpdate: Issue[] = [...issues];
              issuesToUpdate?.forEach((issue: Issue) => {
                if ((item?.Id && issue?.Id && (item.Id === issue.Id))
                  || (item?.LocalId && issue?.LocalId && (item.LocalId === issue.LocalId))) {
                  issue.Text = title;
                  issue.Priority = priority;
                  issue.UserGroupId = tags;
                  issue.Enabled = enabled;
                }
              });
              setIssues(issuesToUpdate);
            }}
            title={item.Text}
            tags={item.UserGroupId}
            id={item.Id}
            enabled={item.Enabled}
            groups={userGroups}
            priority={parseInt(`${item.Priority}`, 10)}
          />}
          itemStyles={{
            draggingStyle: {
              backgroundColor: Colors.light.hereworksBlack100,
              borderRadius: 8,
            },
          }}
        />
      </DraxProvider>
    </GestureHandlerRootView>
    <Button
      title='Add issue  '
      affirmative={false}
      onPress={addIssue}
      disabled={issues?.filter((issue: Issue) => issue.Text.toLowerCase() !== 'other').length > 4}
      style={styles.stretch}
    >
      <PlusIcon color={Colors.light.hereworksBlue500} />
    </Button>
    <View style={styles.separator} />
    <View style={[Style.row, styles.otherIssueRow]}>
      <View style={styles.otherIssueSettings}>
        <View style={Style.row}>
          <View style={Style.row}>
            <Switch
              toggled={otherIssueEnabled}
              toggle={() => setOtherIssueEnabled(!otherIssueEnabled)}
            />
            <Text style={styles.otherText}>Other</Text>
          </View>
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => setPriorityDropdownShown(!priorityDropdownShown)}
            style={[
              styles.priorityContainer, Style.row,
              { borderColor: priorityColor(otherIssuePriority) },
            ]}>
            <PriorityFlag color={priorityColor(otherIssuePriority)} />
            <Text style={[styles.priorityText, { color: priorityColor(otherIssuePriority) }]}>
              {`P${otherIssuePriority}`}
            </Text>
            <DropdownIcon
              color={priorityColor(otherIssuePriority)}
              style={priorityDropdownShown && { transform: [{ rotate: '180deg' }] }}
            />
          </TouchableOpacity>
        </View>
        {priorityDropdownShown && <ScrollView style={styles.priorityDropdown}>
          {ISSUE_PRIORITY_LIST.map((text: string, index: number) => <Option
            key={`option_${text}`}
            text={text}
            selected={otherIssuePriority === index + 1}
            onPress={() => setOtherIssuePriority(index + 1)}
          />)}
        </ScrollView>}
      </View>
      <TouchableOpacity onPress={toggleHelpBoxVisibility}>
        <QuestionIcon />
      </TouchableOpacity>
      {otherIssueHelpShown && <View style={styles.otherIssueHelpBox}>
        <Text style={styles.otherIssueText}>{otherIssueHelpText}</Text>
      </View>}
    </View>
  </View>;
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    paddingBottom: mobile ? 100 : 20,
    marginBottom: 24,
    height: mobile ? undefined : 448,
  },
  separator: {
    alignSelf: 'stretch',
    height: 1,
    marginVertical: 12,
    backgroundColor: Colors.light.hereworksBlue100,
  },
  otherIssueRow: {
    paddingHorizontal: 8,
  },
  otherText: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack500,
    marginHorizontal: 8,
  },
  otherIssueHelpBox: {
    position: 'absolute',
    end: 40,
    bottom: 20,
    backgroundColor: '#FFF',
    width: 200,
    borderRadius: 12,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
    paddingHorizontal: 18,
    paddingVertical: 12,
  },
  otherIssueText: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
  },
  stretch: {
    alignSelf: 'stretch',
  },
  priorityContainer: {
    borderRadius: 4,
    borderWidth: 1,
    paddingHorizontal: 4,
    marginEnd: 8,
    backgroundColor: '#FFF',
  },
  priorityText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
    marginHorizontal: 2,
  },
  otherIssueSettings: {
    flex: 1,
    marginEnd: 64,
  },
  priorityDropdown: {
    width: 170,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: Colors.light.hereworksBlue100,
    marginTop: 8,
    alignSelf: 'flex-end',
  },
});

export default IssueList;
