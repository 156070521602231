/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import { processFontFamily } from 'expo-font';
import React, { useState } from 'react';
import {
  VictoryLine,
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory-native';
import Moment from 'moment';
import {
  Dimensions, StyleSheet, Text, View, useWindowDimensions,
} from 'react-native';
import Colors from '../constants/Colors';
import CompareArrowsIcon from '../components/CompareArrowsIcon';
import { checkIfDatesIdentical, tickValSamples, tooltipDateLabel } from './axisUtils';

const OverallButtonsPressedGraph = (props) => {
  const window = Dimensions.get('window');
  const [dimensions] = React.useState({ window });
  class CustomFlyout extends React.Component {
    render() {
      // @ts-ignore
      const { x, y } = this.props;
      // @ts-ignore
      const { datum } = this.props;
      const graphWidth = (dimensions.window?.width ?? 0) - 142;
      // console.log(graphWidth);
      // console.log(`x: ${x}`);
      const left = x > graphWidth ? x - 150 : x - 50;
      const top = y > 240 ? y - 190 : y - 150;

      // console.log(this.props);
      if (props.compareToggled) {
        return (
          <View
            style={{
              borderRadius: 8,
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              backgroundColor: '#fff',
              left,
              top,
              width: 142,
              shadowOffset: {
                width: 0,
                height: 12,
              },
              shadowOpacity: 0.1,
              shadowRadius: 40,
              shadowColor: 'black',
              elevation: 5,
            }}
          >
            <View
              style={{
                margin: 8,
                alignSelf: 'stretch',
                borderRadius: 4,
                backgroundColor: Colors.light.hereworksWhite100,
              }}
            >
              <View style={{ padding: 0, alignItems: 'flex-start' }}>
                <Text
                  style={{
                    color: Colors.light.hereworksPrimaryGrey800,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 12,
                    marginLeft: 8,
                    marginTop: 4,
                  }}
                >
                  {tooltipDateLabel(props.data, datum._x)}
                </Text>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginVertical: 2,
                }}
              >
                <View
                  style={{
                    marginLeft: 8,
                    width: 12,
                    height: 12,
                    borderRadius: 6,
                    backgroundColor: Colors.light.hereworksDarkBlue,
                  }}
                />
                <Text style={styles.tooltipTitle}>{'Value: '}</Text>
                <Text style={styles.tooltipValue}>{props.data[datum._x]?.value ?? 0}</Text>
              </View>
            </View>
            <CompareArrowsIcon style={{ alignSelf: 'center' }} />
            <View
              style={{
                margin: 8,
                alignSelf: 'stretch',
                borderRadius: 4,
                backgroundColor: Colors.light.hereworksYellow50,
              }}
            >
              <View style={{ padding: 0, alignItems: 'flex-start' }}>
                <Text
                  style={{
                    color: Colors.light.hereworksPrimaryGrey800,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 12,
                    marginLeft: 8,
                    marginTop: 4,
                  }}
                >
                  {tooltipDateLabel(props.compareData, datum._x)}
                </Text>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginVertical: 2,
                }}
              >
                <View
                  style={{
                    marginLeft: 8,
                    width: 12,
                    height: 12,
                    borderRadius: 6,
                    backgroundColor: Colors.light.hereworksYellow800,
                  }}
                />
                <Text style={styles.tooltipTitle}>{'Value: '}</Text>
                <Text style={styles.tooltipValue}>{props.compareData[datum._x]?.value ?? 0}</Text>
              </View>
            </View>
          </View>
        );
      }

      return (
        <View
          style={{
            borderRadius: 8,
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            backgroundColor: '#fff',
            left,
            top,
            width: 112,
            shadowOffset: {
              width: 0,
              height: 12,
            },
            shadowOpacity: 0.1,
            shadowRadius: 40,
            shadowColor: 'black',
            elevation: 5,
          }}
        >
          <View style={{ padding: 0, alignItems: 'flex-start' }}>
            <Text
              style={{
                color: Colors.light.hereworksPrimaryGrey800,
                fontFamily: 'Poppins_500Medium',
                fontSize: 12,
                marginLeft: 8,
                marginTop: 4,
              }}
            >
              {tooltipDateLabel(props.data, datum._x)}
            </Text>
          </View>
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              marginVertical: 2,
            }}
          >
            <View
              style={{
                marginLeft: 8,
                width: 12,
                height: 12,
                borderRadius: 6,
                backgroundColor: Colors.light.hereworksDarkBlue,
              }}
            />
            <Text
              style={{
                color: Colors.light.hereworksBlack400,
                fontFamily: 'Roboto_400Regular',
                fontSize: 12,
                marginLeft: 8,
              }}
            >
              {'Value: '}
            </Text>
            <Text
              style={{
                color: Colors.light.hereworksBlack600,
                fontFamily: 'Roboto_500Medium',
                fontSize: 12,
                marginLeft: 4,
              }}
            >
              {datum.value}
            </Text>
          </View>
        </View>
      );
    }
  }

  const [avg, setAvg] = useState(65);

  const [tickVals, setTickVals] = useState([]);
  const [compareTickVals, setCompareTickVals] = useState([]);

  React.useEffect(() => {
    if (props.data?.length > 0) {
      let sum = 0;
      // eslint-disable-next-line no-return-assign
      props.data.forEach((a) => (sum += a.value));
      const average = sum / Math.max(props.data.length, 1);
      setAvg(Math.floor(average));
    }
  }, [props.data]);

  React.useEffect(() => {
    const tickArray = [...Array(props.data?.length).keys()];
    const tickArray2 = [...Array(props.compareData?.length).keys()];
    setTickVals(tickValSamples(tickArray, Math.min(5, Math.floor(5
      * (tickArray.length / tickArray2.length || 1)))));
    setCompareTickVals(tickValSamples(tickArray2, Math.min(5, Math.floor(5
      * (tickArray2.length / tickArray.length || 1)))));
  }, [props.compareData, props.data]);

  const chartTheme = {
    axis: {
      stroke: 'none',
      style: {
        stroke: '#696D70',
        tickLabels: {
          fill: '#696D70',
        },
        grid: { stroke: 'none' },
      },
    },
  };

  const chartHeight = 368;

  const dataSize = props.data.length;
  const dataSubset = props.data.slice(dataSize * 0.6, dataSize - 1);
  if (dataSubset.length > 0) {
    // let scatterPlotMinPoint = null;
    // const compareDataSize = props.compareData.length;
    // const compareDataSubset = props.compareData.slice(
    //   compareDataSize * 0.6,
    //   compareDataSize - 1,
    // );
    // const dataMinValue = Math.min(...dataSubset.map((item) => item.value));
    // const compareDataMinValue = Math.min(...compareDataSubset.map((item) => item.value));
    // const combinedMinValue = Math.min(dataMinValue, compareDataMinValue);
    // const scale = (chartHeight - 120) / 100;
    // scatterPlotMinPoint = props.compareToggled ? combinedMinValue : dataMinValue;
  }

  return (
    <VictoryChart
      theme={chartTheme}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={({ datum }) => datum}
          labelComponent={<CustomFlyout />}
        />
      }
      width={useWindowDimensions().width}
      height={chartHeight}
      padding={{
        top: 50,
        left: 50,
        right: 80,
        bottom: 50,
      }}
    >
      <VictoryAxis
        disableInlineStyles
        fixLabelOverlap={true}
        tickFormat={(t) => {
          if (typeof t === 'number' && props.data !== null && props.data[t] !== undefined) {
            if (checkIfDatesIdentical(props.dates.start, props.dates.end)) {
              return `${Moment(props.data[t]?.date).format('h a')}`;
            }
            // if (new Date(props.dates.end) - new Date(props.dates.start))
            return `${Moment(props.data[t]?.date).format('MMM')} ${Moment(
              props.data[t]?.date,
            ).format('DD')}`;
          }
          if (checkIfDatesIdentical(props.dates.start, props.dates.end)) {
            return '';
          }
          return `${Moment(props.dates.end).format('MMM')} ${Moment(props.dates.end).format('DD')}`;
        }}
        tickValues={tickVals}
        tickCount={5}
        style={{
          axis: { stroke: 'none' },
        }}
        tickLabelComponent={
          <VictoryLabel
            dy={10}
            style={{
              stroke: Colors.light.hereworksBlack400,
              fontSize: 14,
              strokeWidth: 0,
              fontFamily: processFontFamily('Poppins_400Regular'),
            }}
          />
        }
      />
      <VictoryAxis
        disableInlineStyles
        fixLabelOverlap={true}
        dependentAxis
        domain={{ x: [0, 12], y: [0, 100] }}
        tickValues={[0, 20, 40, 60, 80, 100]}
        style={{
          axis: { stroke: 'none' },
          tickLabels: { fill: 'transparent' },
          grid: {
            stroke: Colors.light.hereworksWhite100,
            strokeWidth: 1,
            borderRadius: 12,
            pointerEvents: 'none',
          },
        }}
      />
      <VictoryLine
        style={{
          data: { stroke: Colors.light.hereworksDarkBlue },
        }}
        data={props.data}
        x="index"
        y="value"
        interpolation="linear"
      />
      <VictoryScatter
        data={props.data}
        x="index"
        y="value"
        style={{
          data: { fill: Colors.light.hereworksDarkBlue },
        }}
        size={({ active }) => (active ? 5 : props.data?.length > 10 ? 2 : 3)}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onMouseOver: () => [
                {
                  target: 'labels',
                  mutation: { active: true },
                },
              ],
            },
          },
        ]}
      />
      {props.compareToggled && (
        <VictoryLine
          style={{
            data: { stroke: Colors.light.hereworksYellow500 },
          }}
          data={props.compareData}
          x="index"
          y="value"
          interpolation="linear"
        />
      )}
      {props.compareToggled && (
        <VictoryAxis
          label="date 2"
          domainPadding={{ x: [0, 0], y: 20 }}
          style={{
            axis: { stroke: 'none' },
            axisLabel: { fill: 'transparent' },
          }}
          tickFormat={(t) => {
            if (
              typeof t === 'number'
              && props.compareData !== null
              && props.compareData[t] !== undefined
            ) {
              if (checkIfDatesIdentical(props.dates.start, props.dates.end)) {
                return `${Moment(props.compareData[t]?.date).format('h a')}`;
              }
              return `${Moment(props.compareData[t]?.date).format('MMM')} ${Moment(
                props.compareData[t]?.date,
              ).format('DD')}`;
            }
            if (checkIfDatesIdentical(props.dates.start, props.dates.end)) {
              return '';
            }
            return `${Moment(props.dates.start).format('MMM')} ${Moment(props.dates.start).format(
              'DD',
            )}`;
          }}
          tickCount={5}
          tickValues={compareTickVals}
          tickLabelComponent={
            <VictoryLabel
              dy={30}
              style={{
                stroke: Colors.light.hereworksYellow500,
                fill: Colors.light.hereworksYellow500,
                strokeWidth: 0,
                fontSize: 14,
                fontFamily: processFontFamily('Poppins_400Regular'),
                marginTop: 20,
              }}
            />
          }
          offsetX={50}
        />
      )}
      {props.compareToggled && (
        <VictoryScatter
          data={props.compareData}
          x="index"
          y="value"
          style={{
            data: { fill: Colors.light.hereworksYellow500 },
          }}
          size={({ active }) => (active ? 5 : props.data?.length > 10 ? 2 : 3)}
        />
      )}
      <VictoryAxis
        dependentAxis
        fixLabelOverlap={true}
        style={{
          axis: { stroke: 'none' },
        }}
        tickLabelComponent={
          <VictoryLabel
            dx={-10}
            style={{
              stroke: Colors.light.hereworksBlack400,
              fontSize: 14,
              strokeWidth: 0,
              fontFamily: processFontFamily('Poppins_400Regular'),
            }}
          />
        }
      />
      <VictoryLine
        data={[
          { y: avg, x: 0 },
          {
            y: avg,
            x: props.data?.length > 12 ? props.data?.length ?? 0 - 1 : props.data?.length,
            label: `${avg} avg`,
          },
        ]}
        standalone={false}
        style={{
          data: { stroke: Colors.light.hereworksBlack300, strokeWidth: 1.5, strokeDasharray: 8 },
        }}
        labels={({ datum }) => datum.label}
        labelComponent={
          <VictoryLabel
            textAnchor={'end'}
            dy={-14}
            dx={0}
            backgroundStyle={{
              fill: 'white',
            }}
            backgroundPadding={6}
            style={{
              stroke: Colors.light.hereworksBlack400,
              fontSize: 14,
              strokeWidth: 0,
              fontFamily: processFontFamily('Poppins_400Regular'),
            }}
          />
        }
      />
    </VictoryChart>
  );
};

const styles = StyleSheet.create({
  tooltipTitle: {
    color: Colors.light.hereworksBlack400,
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    marginLeft: 8,
  },
  tooltipValue: {
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    marginLeft: 2,
  },
});

export default OverallButtonsPressedGraph;
