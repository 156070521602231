import * as React from 'react';
import Svg, { SvgProps, Rect } from 'react-native-svg';

const EmptyBoxIcon = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Rect
      x={1}
      y={1}
      width={18}
      height={18}
      rx={2}
      strokeWidth={2}
      stroke="#D6DFE4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default EmptyBoxIcon;
