import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ReportIssueIcon = (props: SvgProps) => (
  <Svg width='12' height='14' viewBox='0 0 12 14' fill='none' {...props}>
    <Path d='M3.56125 0.332031C2.59544 0.332031 1.9275 0.495987 1.48671 0.675375C1.26673 0.764905 1.10532 0.85748 0.993391 0.933403C0.937507 0.971309 0.89421 1.00489 0.862311 1.03162C0.84637 1.04498 0.833297 1.0566 0.822943 1.06618C0.817766 1.07097 0.813271 1.07524 0.809438 1.07896L0.804183 1.08412L0.801925 1.08638L0.800887 1.08742L0.800391 1.08793C0.800391 1.08793 0.79991 1.08842 1.12316 1.4173L0.79991 1.08842C0.714179 1.17564 0.666016 1.29395 0.666016 1.4173V13.2002C0.666016 13.4571 0.870685 13.6654 1.12316 13.6654C1.37563 13.6654 1.5803 13.4571 1.5803 13.2002V9.09956C1.62622 9.07308 1.68417 9.04283 1.75534 9.01113C2.06394 8.87368 2.63185 8.70413 3.56125 8.70413C3.81373 8.70413 4.0184 8.49588 4.0184 8.23901C4.0184 7.98213 3.81373 7.77389 3.56125 7.77389C2.66113 7.77389 2.01988 7.91632 1.5803 8.08071V1.6577C1.64058 1.62293 1.72167 1.58165 1.82627 1.53907C2.14739 1.40839 2.6985 1.26226 3.56125 1.26226C4.37528 1.26226 5.05939 1.53571 5.82957 1.84915L5.85615 1.85997C6.60295 2.16396 7.43482 2.50257 8.43744 2.50257C9.33724 2.50257 9.9785 2.36026 10.4184 2.19575V3.89792C10.4184 4.1548 10.6231 4.36304 10.8755 4.36304C11.128 4.36304 11.3327 4.1548 11.3327 3.89792V1.4173C11.3327 1.22918 11.2213 1.05958 11.0505 0.987591C10.8829 0.91697 10.6905 0.953919 10.5598 1.08092L10.5552 1.08491C10.5466 1.0921 10.528 1.10697 10.4982 1.1272C10.4387 1.16756 10.3334 1.23002 10.1724 1.29553C9.8513 1.42622 9.3002 1.57234 8.43744 1.57234C7.62341 1.57234 6.9393 1.2989 6.16913 0.985453L6.14254 0.974632C5.39575 0.670647 4.56388 0.332031 3.56125 0.332031Z' fill='#0058AD' />
    <Path d='M8.58982 5.13823C8.58982 4.88136 8.38515 4.67312 8.13268 4.67312C7.88021 4.67312 7.67554 4.88136 7.67554 5.13823V7.46381H5.38982C5.13735 7.46381 4.93268 7.67205 4.93268 7.92893C4.93268 8.18581 5.13735 8.39405 5.38982 8.39405H7.67554V10.7196C7.67554 10.9765 7.88021 11.1847 8.13268 11.1847C8.38515 11.1847 8.58982 10.9765 8.58982 10.7196V8.39405H10.8755C11.128 8.39405 11.3327 8.18581 11.3327 7.92893C11.3327 7.67205 11.128 7.46381 10.8755 7.46381H8.58982V5.13823Z' fill='#0058AD' />
  </Svg>

);

export default ReportIssueIcon;
