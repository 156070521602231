import Moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
/* eslint-disable import/prefer-default-export */
export const GetProcessedElectricity = async (
  metric: string,
  dates: { start: any; end: any },
  interval: string = '24h',
  index: string = 'happyscore_flat_permin',
  thingId: string = null,
  thingRoom: string = null,
  thingFloor: string = null,
) => {
  const clientName = await AsyncStorage.getItem('clientName');
  const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
  const jwtToken = await AsyncStorage.getItem('token');

  const dateRange = dates;
  let queryInterval: string = interval;
  // @ts-ignore
  const differenceHours: number = (Moment(dates.end) - Moment(dates.start)) / 3600000;
  if (differenceHours <= 24) {
    queryInterval = '10m';
  } else if (differenceHours <= 168) {
    queryInterval = '1h';
  }

  let filter: object = thingId ? {
    match: {
      'thing-id': thingId,
    },
  } : null;
  filter = filter ?? (thingRoom ? {
    wildcard: {
      'thing-room.keyword': { value: `*${thingRoom}*` },
    },
  } : null);
  filter = filter ?? (thingFloor ? {
    match: {
      'thing-floor.keyword': thingFloor,
    },
  } : null);

  // eslint-disable-next-line no-undef
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('clientid', clientName ?? 'hereworks');
  requestHeaders.set('jwt', jwtToken ?? '');
  requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
  requestHeaders.set('Content-Type', 'application/json');

  return fetch(
    `https://${apiDomain}/button/oscomms`,
    {
      headers: requestHeaders,
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        size: 0,
        index,
        query: {
          bool: {
            must: {
              range: {
                timestamp: {
                  gte: dateRange.start == null ? 'now-7d/d' : dateRange.start,
                  lte: dateRange.end == null ? 'now/d' : dateRange.end,
                },
              },
            },
            filter,
          },
        },
        aggs: {
          byhour: {
            date_histogram: {
              field: 'timestamp',
              interval: queryInterval,
              min_doc_count: 0,
              extended_bounds: {
                min: dateRange.start,
                max: dateRange.end,
              },
              // offset: '-1h',
            },
            aggs: { score: { sum: { field: metric } } },
          },
        },
      }),
    },
  ).then((res) => (res ? res?.json().then((json) => json?.aggregations?.byhour?.buckets
    ?.filter((e) => {
      const date: Date = new Date(e.key_as_string);
      return date >= Moment(dateRange.start).startOf('day').toDate()
        && date <= Moment(dateRange.end).endOf('day').toDate();
    })) : null))
    .catch(() => null);
};
