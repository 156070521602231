/* eslint-disable array-callback-return */
import { processFontFamily } from 'expo-font';
import React, { useState } from 'react';
import { Text, View } from 'react-native';
import Moment from 'moment';
import {
  VictoryLine,
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory';
import Colors from '../constants/Colors';
import { checkIfDatesIdentical } from './axisUtils';

const OverallButtonsPressedGraphWeb = (props) => {
  const [avg, setAvg] = useState(70);
  const [tickVals, setTickVals] = useState([]);
  const [compareTickVals, setCompareTickVals] = useState([]);
  const minTickCount = checkIfDatesIdentical(props.dates.start, props.dates.end) ? 2 : 3;

  React.useEffect(() => {
    if (props.data?.length > 0) {
      let sum = 0;
      // eslint-disable-next-line no-return-assign
      props.data.forEach((a) => (sum += a.value));
      const average = sum / props.data.length || 0;
      setAvg(Math.floor(average));
    }
  }, [props.data]);

  React.useEffect(() => {
    const tickArray = [...Array(props.data?.length).keys()];
    const tickArray2 = [...Array(props.compareData?.length).keys()];
    setTickVals(tickArray);
    setCompareTickVals(tickArray2);
  }, [props.compareData, props.data]);

  class CustomFlyout extends React.Component {
    render() {
      // @ts-ignore
      const { x, y } = this.props;
      return (
        <svg>
          <defs>
            <filter id="f3" x={-0.5} y={-1} width="200%" height="250%">
              <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0"
              />
              <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="4" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" result="colored" />
            </filter>
          </defs>
          <rect
            x={x < 100 ? x - 46 : x - 78}
            y={y - 60}
            width={100}
            height={40}
            stroke="none"
            rx={7}
            fill="white"
            filter="url(#f3)"
          />
          <text
            x={x < 100 ? x - 40 : x - 73}
            y={y - 33}
            fontFamily="sans-serif"
            fontSize={10}
            strokeWidth={0.3}
            fill={'#696D70'}
            stroke={'#696D70'}
          >
            {/* @ts-ignore */}
            {Moment(this.props.datum.date).format('DD MMM')}
          </text>
          <text
            x={x < 100 ? x + 5 : x}
            y={y - 33}
            fontFamily="sans-serif"
            fontSize={10}
            strokeWidth={0.3}
            fill={'#696D70'}
            stroke={'#696D70'}
          >
            {/* @ts-ignore */}
            {this.props.datum.value}
          </text>
        </svg>
      );
    }
  }

  const chartTheme = {
    axis: {
      stroke: 'none',
      style: {
        stroke: '#696D70',
        tickLabels: {
          fill: 'none',
        },
        grid: { stroke: 'none' },
      },
    },
  };
  return (
    <View
      style={{
        paddingBottom: 18,
        paddingStart: 12,
        paddingEnd: 24,
      }}
    >
      <VictoryChart
        theme={chartTheme}
        domainPadding={{ y: 20 }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => datum}
            labelComponent={<CustomFlyout />}
          />
        }
        width={730}
        height={368}
      >
        <VictoryAxis
          name="axis1"
          disableInlineStyles
          fixLabelOverlap={true}
          style={{
            axis: { stroke: 'none' },
          }}
          tickCount={Math.max(minTickCount, Math.min(5, tickVals.length))}
          tickFormat={(t) => {
            if (typeof t === 'number' && props.data !== null && props.data[t] !== undefined) {
              if (checkIfDatesIdentical(props.dates.start, props.dates.end)) {
                return Moment(props.data[t]?.date)
                  ? `${Moment(props.data[t]?.date).format('h a')}` : '';
              }
              return props.data[t]?.date
                ? `${Moment(props.data[t]?.date).format('MMM')} ${Moment(
                  props.data[t]?.date,
                ).format('DD')}` : '';
            }
            return '';
          }}
          tickValues={tickVals}
          tickLabelComponent={
            <VictoryLabel
              style={{
                stroke: Colors.light.hereworksBlack400,
                fontSize: 14,
                strokeWidth: 0,
                fontFamily: processFontFamily('Poppins_400Regular'),
              }}
              dy={15}
            />
          }
        />
        <VictoryAxis
          disableInlineStyles
          fixLabelOverlap={true}
          dependentAxis
          tickFormat={(t) => Math.floor(t)}
          style={{
            axis: { stroke: 'none' },
            grid: {
              stroke: Colors.light.hereworksWhite500,
              strokeWidth: 1,
              pointerEvents: 'none',
            },
          }}
          tickLabelComponent={
            <VictoryLabel
              style={{
                stroke: Colors.light.hereworksBlack400,
                fontSize: 14,
                strokeWidth: 0,
                fontFamily: processFontFamily('Poppins_400Regular'),
              }}
              dx={-20}
            />
          }
        />
        <VictoryLine
          style={{
            data: { stroke: '#003A70' },
          }}
          data={props.data}
          x="index"
          y="value"
          interpolation="linear"
        />
        <VictoryScatter
          data={props.data}
          x="index"
          y="value"
          style={{
            data: { fill: '#003A70' },
          }}
          size={({ active }) => (active ? 4 : 2)}
          events={[
            {
              target: 'data',
              eventHandlers: {
                onMouseOver: () => [
                  {
                    target: 'labels',
                    mutation: { active: true },
                  },
                ],
              },
            },
          ]}
        />
        {props.compareToggled && (
          <VictoryLine
            style={{
              data: { stroke: Colors.light.hereworksYellow500 },
            }}
            data={props.compareData}
            x="index"
            y="value"
            interpolation="linear"
          />
        )}
        {props.compareToggled && (
          <VictoryAxis
            name="axis2"
            style={{
              axis: { stroke: 'none' },
            }}
            fixLabelOverlap={true}
            tickCount={Math.max(minTickCount, Math.min(5, compareTickVals.length))}
            tickFormat={(t) => {
              if (
                typeof t === 'number'
                && props.compareData !== null
                && props.compareData[t] !== undefined
              ) {
                if (checkIfDatesIdentical(props.dates.start, props.dates.end)) {
                  return props.compareData[t]?.date
                    ? `${Moment(props.compareData[t]?.date).format('h a')}`
                    : '';
                }
                return props.compareData[t]?.date
                  ? `${Moment(props.compareData[t]?.date).format('MMM')} ${Moment(
                    props.compareData[t]?.date,
                  ).format('DD')}` : '';
              }
              return '';
            }}
            tickValues={compareTickVals}
            tickLabelComponent={
              <VictoryLabel
                dy={36}
                style={{
                  fill: Colors.light.hereworksYellow500,
                  strokeWidth: 0,
                  fontSize: 14,
                  fontFamily: processFontFamily('Poppins_400Regular'),
                }}
              />
            }
            axisLabelComponent={
              <VictoryLabel style={{ stroke: Colors.light.hereworksYellow500, fontSize: 12 }} />
            }
            offsetX={50}
          />
        )}
        {props.compareToggled && (
          <VictoryScatter
            data={props.compareData}
            x="index"
            y="value"
            style={{
              data: { fill: Colors.light.hereworksYellow500 },
            }}
            size={({ active }) => (active ? 4 : 2)}
          />
        )}
        {props.data !== null && props.data?.length > 0 && (
          <VictoryLine
            data={[
              { y: avg, x: 0 },
              { y: avg, x: props.data?.length, label: `${avg} avg` },
            ]}
            standalone={false}
            style={{
              data: {
                stroke: Colors.light.hereworksBlack300,
                strokeWidth: 1.5,
                strokeDasharray: 8,
              },
            }}
            labels={({ datum }) => datum.label}
            labelComponent={
              <VictoryLabel
                renderInPortal
                textAnchor="end"
                dx={45}
                dy={6}
                backgroundStyle={{
                  fill: 'white',
                }}
                backgroundPadding={5}
                style={{
                  color: Colors.light.hereworksBlack400,
                  fontSize: 12,
                  strokeWidth: 0,
                  fontFamily: processFontFamily('Poppins_400Regular'),
                }}
              />
            }
          />
        )}
      </VictoryChart>
      {!props.data?.length && (!props.compareToggled || !props.compareData?.length) && (
        <View
          style={{
            position: 'absolute',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <Text
            style={{
              color: Colors.light.hereworksBlack400,
              fontFamily: 'Roboto_400Regular',
              fontSize: 16,
            }}
          >
            No tickets raised
          </Text>
        </View>
      )}
    </View>
  );
};

export default OverallButtonsPressedGraphWeb;
