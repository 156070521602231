import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, Text, Animated, Easing,
} from 'react-native';
import Colors from '../../../constants/Colors';
import { defaultSafetyColors, safetyColors, thresholds } from './AirQualitySafeLevelsSection';

interface MetricValueCardProps {
  index: number,
  metric: { title: string, unit?: string },
  value: number,
  critical?: boolean,
}

const MetricValueCard = ({ index, metric, value }: MetricValueCardProps) => {
  const textColor = () => {
    if (!Number.isFinite(value)) return null;
    if (!thresholds[index]) return defaultSafetyColors[0];
    let output: string = null;
    if (metric.title.toLowerCase().includes('temp')) {
      const tempThresholds: number[] = [];
      tempThresholds[0] = parseInt(thresholds[index][0].split('-')[0], 10);
      tempThresholds[1] = parseInt(thresholds[index][1], 10);
      tempThresholds[2] = parseInt(thresholds[index][0].split('-')[1], 10);
      if (value === tempThresholds[1]) return safetyColors[index][1];
      if (value >= tempThresholds[0] && value <= tempThresholds[2]) return safetyColors[index][0];
      return safetyColors[index][2];
    }
    [...Array(thresholds[index].length).keys()].forEach((thresholdIndex) => {
      if (value >= parseInt(thresholds[index][thresholdIndex], 10)) {
        output = safetyColors[index][thresholdIndex];
      }
    });
    return output;
  };

  const [scoreOpacity] = useState(new Animated.Value(1));

  useEffect(() => {
    Animated.sequence([
      Animated.timing(scoreOpacity, { toValue: 0, duration: 0, useNativeDriver: true }),
      Animated.timing(scoreOpacity, {
        toValue: 1, duration: 500, useNativeDriver: true, easing: Easing.ease,
      }),
    ]).start();
  }, [value]);

  return <View key={`metricValueCard_${index}`} style={styles.container}>
    <Text style={styles.title}>{metric.title}</Text>
    <View style={styles.textContainer}>
      <Animated.Text style={[styles.score, { color: textColor(), opacity: scoreOpacity }]}>
        {value ?? '-'}
      </Animated.Text>
      {/* {showComparison && <Text
        style={[
          styles.metricValueText, styles.metricValueTextCompared,
          critical && { color: Colors.light.hereworksOrange700 },
        ]}>{'-'}</Text>} */}
      <Text style={styles.unit}>{metric.unit}</Text>
    </View>
  </View>;
};

const styles = StyleSheet.create({
  container: {
    flex: 0.14,
    minWidth: 140,
    backgroundColor: Colors.light.hereworksWhite100,
    borderRadius: 12,
    paddingTop: 10,
    paddingBottom: 8,
    paddingHorizontal: 12,
    marginHorizontal: 6,
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  title: {
    fontSize: 12,
    lineHeight: 9.6,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack500,
    marginBottom: 8,
  },
  score: {
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'Poppins_500Medium',
    marginEnd: 2,
    color: Colors.light.hereworksEmeraldGreen,
  },
  scoreCompared: {
    marginStart: 6,
  },
  unit: {
    fontSize: 12,
    lineHeight: 18,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack500,
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
});

export default MetricValueCard;
