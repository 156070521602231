import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const IssueReported = (props: SvgProps) => (
  <Svg width="100" height="100" viewBox="0 0 100 100" fill="none" {...props}>
    <Path d="M22.5044 15.0789L17.7493 2.70695C17.4204 1.85128 16.4416 1.39533 15.5743 1.69236C11.8137 2.97985 9.1111 5.43269 7.46628 9.05125C7.08698 9.88572 7.44605 10.904 8.26593 11.314L20.1205 17.2427C21.5303 17.9474 23.0699 16.55 22.5044 15.0789Z" fill="#F8D448" />
    <Path d="M77.4956 15.0789L82.2509 2.70697C82.5799 1.8515 83.5587 1.39555 84.4258 1.69238C88.1864 2.97988 90.889 5.43271 92.5338 9.05128C92.9131 9.88575 92.5538 10.904 91.7341 11.3141L79.8796 17.2427C78.4698 17.9474 76.9302 16.55 77.4956 15.0789Z" fill="#F8D448" />
    <Path d="M22.5044 84.9213L17.7491 97.2932C17.4202 98.1487 16.4414 98.6046 15.5743 98.3078C11.8137 97.0203 9.1111 94.5675 7.46628 90.9489C7.08698 90.1144 7.44605 89.0961 8.26593 88.6861L20.1205 82.7575C21.5303 82.0527 23.0699 83.4501 22.5044 84.9213Z" fill="#F8D448" />
    <Path d="M77.4956 84.9213L82.2509 97.2932C82.5799 98.1487 83.5587 98.6046 84.4258 98.3078C88.1864 97.0203 90.889 94.5675 92.5338 90.9489C92.9131 90.1144 92.5538 89.0961 91.7341 88.6861L79.8796 82.7575C78.4698 82.0527 76.9302 83.4501 77.4956 84.9213Z" fill="#F8D448" />
    <Path d="M2.22357 52.9991C1.17299 50.9532 1.23699 48.8452 2.24652 46.6847C2.52429 46.0901 3.24924 45.8543 3.83512 46.1502L9.31734 48.9189C10.1995 49.3644 10.1726 50.6332 9.2726 51.0411L3.76101 53.5389C3.18681 53.7991 2.51125 53.5597 2.22357 52.9991Z" fill="#FBE88C" />
    <Path d="M97.7765 52.9991C98.8271 50.9532 98.7631 48.8452 97.7538 46.6847C97.476 46.0901 96.751 45.8543 96.1652 46.1502L90.683 48.9189C89.8008 49.3644 89.8277 50.6332 90.7277 51.0411L96.2393 53.5389C96.8133 53.7991 97.4888 53.5597 97.7765 52.9991Z" fill="#FBE88C" />
    <Path d="M52.505 13.0336L61.305 30.8646C61.6711 31.6064 62.379 32.1207 63.1977 32.2396L82.8752 35.099C84.9369 35.3985 85.7602 37.9323 84.2683 39.3865L70.0294 53.2659C69.4369 53.8434 69.1667 54.6753 69.3066 55.4908L72.668 75.0889C73.0203 77.1422 70.8649 78.7083 69.0209 77.7388L51.4207 68.4859C50.6884 68.101 49.8137 68.101 49.0813 68.4859L31.4812 77.7388C29.6372 78.7083 27.4818 77.1424 27.834 75.0889L31.1955 55.4908C31.3353 54.6753 31.0649 53.8434 30.4726 53.2659L16.2338 39.3865C14.7418 37.9323 15.5652 35.3985 17.6269 35.099L37.3044 32.2396C38.1231 32.1205 38.831 31.6064 39.197 30.8646L47.9971 13.0336C48.9189 11.1652 51.583 11.1652 52.505 13.0336Z" fill="#FBE88C" />
    <Path d="M72.668 75.0889L72.1548 72.0969L52.2149 61.6139C51.354 61.1613 50.3256 61.1613 49.4646 61.6139L38.748 67.248C37.6984 67.7999 36.4718 66.9086 36.6722 65.7399L39.1837 51.0959C39.3236 50.2805 39.0532 49.4485 38.4607 48.871L31.5107 42.0964C30.2078 40.8265 30.9268 38.6138 32.7272 38.3522L44.0719 36.7037C44.8906 36.5847 45.5985 36.0706 45.9645 35.3287L54.7366 17.5553L52.5049 13.0336C51.5829 11.1652 48.9188 11.1652 47.9968 13.0336L39.1968 30.8646C38.8305 31.6066 38.1229 32.1207 37.3041 32.2396L17.6264 35.099C15.5648 35.3985 14.7416 37.9321 16.2333 39.3865L30.4722 53.2661C31.0647 53.8434 31.3351 54.6755 31.1952 55.491L27.834 75.0889C27.4817 77.1424 29.6371 78.7083 31.4813 77.7388L49.0814 68.4859C49.8138 68.101 50.6885 68.101 51.4209 68.4859L69.0212 77.7388C70.8648 78.7081 73.02 77.1422 72.668 75.0889Z" fill="#FBE372" />
    <Path d="M70.4094 55.197C70.3515 54.8584 70.4635 54.5131 70.7098 54.2733L84.8858 40.4549C85.9736 39.3948 86.3575 37.8387 85.8882 36.3942C85.4188 34.9498 84.1934 33.9165 82.6901 33.6983L63.0994 30.8515C62.7594 30.8021 62.4654 30.5885 62.3135 30.2806L53.5524 12.5282C52.8801 11.1662 51.5189 10.3201 49.9999 10.3201C48.481 10.3201 47.12 11.1662 46.4477 12.5282L42.0347 21.4697C41.6782 22.1921 41.9748 23.067 42.6973 23.4236C43.4197 23.7801 44.2944 23.4835 44.6512 22.7611L49.0641 13.8194C49.3232 13.2944 49.805 13.2376 49.9999 13.2376C50.195 13.2376 50.6769 13.2944 50.9359 13.8194L59.6973 31.572C60.2748 32.7412 61.3896 33.5512 62.68 33.7387L82.2708 36.5854C82.8502 36.6697 83.0529 37.1102 83.1132 37.2958C83.1737 37.4814 83.2684 37.957 82.8493 38.3656L68.6732 52.184C67.7393 53.0943 67.3135 54.405 67.5341 55.6903L70.8806 75.2021C70.9796 75.7793 70.6232 76.1084 70.4653 76.2229C70.3073 76.3375 69.885 76.5752 69.3663 76.3023L62.2793 72.5766C61.5662 72.2019 60.6841 72.4758 60.309 73.1889C59.934 73.9022 60.2083 74.7841 60.9214 75.1591L68.0083 78.8849C68.5932 79.1922 69.226 79.3441 69.8555 79.3441C70.6734 79.3441 71.4859 79.0878 72.1801 78.5834C73.4089 77.6906 74.0128 76.2058 73.7561 74.7088L70.4094 55.197Z" fill="black" />
    <Path d="M56.7316 69.6597L51.8434 67.0902C50.689 66.4831 49.3108 66.4831 48.1566 67.0902L30.6341 76.3024C30.1161 76.5751 29.6928 76.3378 29.5349 76.223C29.3771 76.1083 29.0208 75.7794 29.1198 75.202L32.4662 55.6904C32.6866 54.4051 32.2608 53.0942 31.327 52.1841L17.1509 38.3657C16.7317 37.9571 16.8266 37.4815 16.8869 37.2959C16.9472 37.1103 17.1501 36.6698 17.7296 36.5855L37.3204 33.7388C38.6108 33.5513 39.7259 32.7413 40.303 31.5719L41.8815 28.3735C42.2381 27.6509 41.9414 26.7761 41.219 26.4196C40.4962 26.0629 39.6215 26.3597 39.2651 27.0821L37.6866 30.2805C37.5345 30.5884 37.2408 30.802 36.9008 30.8514L17.3098 33.6982C15.8066 33.9166 14.5813 34.9497 14.112 36.3941C13.6426 37.8386 14.0268 39.3947 15.1143 40.4548L29.2904 54.2732C29.5364 54.513 29.6487 54.8583 29.5905 55.1971L26.2441 74.7085C25.9873 76.2057 26.5911 77.6903 27.82 78.5831C28.5145 79.0877 29.3268 79.344 30.1449 79.3438C30.7743 79.3438 31.4073 79.1919 31.992 78.8846L49.5145 69.6724C49.8185 69.5125 50.1817 69.5125 50.4857 69.6724L55.3739 72.2421C56.0876 72.6171 56.9693 72.3425 57.3441 71.6296C57.719 70.9167 57.4447 70.0345 56.7316 69.6597V69.6597Z" fill="black" />
    <Path d="M7.61356 12.6186L19.4681 18.5472C19.9252 18.7758 20.414 18.8876 20.8985 18.8876C21.6704 18.8876 22.4309 18.604 23.0337 18.0568C24.0147 17.1664 24.3415 15.7919 23.8661 14.5554L19.1111 2.18345C18.5035 0.602622 16.705 -0.2371 15.1018 0.312016C10.9641 1.72867 7.94832 4.46569 6.13835 8.44722C5.43713 9.98992 6.09887 11.8612 7.61356 12.6186V12.6186ZM8.79465 9.65477C10.2796 6.38789 12.6519 4.2348 16.0471 3.07257C16.0707 3.0644 16.0956 3.06071 16.1211 3.06071C16.231 3.06071 16.3494 3.13093 16.3877 3.23033L21.1429 15.6023C21.1608 15.6489 21.2086 15.7732 21.0728 15.8964C20.9374 16.0197 20.8182 15.9602 20.7733 15.9376L8.91856 10.009C8.80146 9.95043 8.73999 9.77498 8.79465 9.65477V9.65477Z" fill="black" />
    <Path d="M76.9662 18.0568C77.569 18.6038 78.3296 18.8876 79.1016 18.8876C79.5859 18.8876 80.0751 18.7757 80.5321 18.5472L92.3866 12.6185C93.9011 11.8613 94.5628 9.99006 93.862 8.44736C92.052 4.46584 89.0362 1.72862 84.8985 0.311966C83.2953 -0.236761 81.4968 0.602766 80.8892 2.1834L76.1341 14.5553C75.6587 15.7919 75.9853 17.1663 76.9662 18.0568V18.0568ZM78.8575 15.6022L83.6126 3.23008C83.6507 3.13068 83.7692 3.06046 83.8791 3.06046C83.9047 3.06046 83.9298 3.06435 83.9532 3.07233C87.3482 4.23475 89.7205 6.38784 91.2055 9.65433C91.2601 9.77454 91.1987 9.95019 91.0814 10.0087L79.2268 15.9374C79.1823 15.9597 79.0631 16.0194 78.9273 15.8961C78.7917 15.7732 78.8394 15.6489 78.8575 15.6022Z" fill="black" />
    <Path d="M23.0337 81.9432C22.0526 81.0527 20.6531 80.8601 19.4681 81.4528L7.61356 87.3814C6.09887 88.1388 5.43713 90.0101 6.13835 91.5526C7.94832 95.5341 10.9641 98.2713 15.1018 99.688C15.4348 99.802 15.7762 99.856 16.1139 99.856C17.4008 99.856 18.6299 99.0688 19.1111 97.8166L23.8663 85.4446C24.3415 84.2081 24.0149 82.8336 23.0337 81.9432V81.9432ZM21.1429 84.3979L16.3875 96.7701C16.3405 96.8924 16.1716 96.9704 16.047 96.9278C12.6519 95.7654 10.2796 93.6125 8.79465 90.3454C8.73999 90.2252 8.80146 90.0498 8.91875 89.9912L20.7733 84.0626C20.797 84.0507 20.8417 84.0284 20.8985 84.0284C20.9489 84.0284 21.009 84.0459 21.0728 84.1038C21.2084 84.2268 21.1608 84.3511 21.1429 84.3979Z" fill="black" />
    <Path d="M92.3865 87.3815L90.3531 86.3643C89.6326 86.0039 88.7561 86.2961 88.3959 87.0166C88.0354 87.7372 88.3274 88.6135 89.0483 88.974L91.0817 89.9911C91.199 90.0496 91.2603 90.2251 91.2056 90.3453C89.7209 93.6122 87.3488 95.7653 83.9537 96.9275C83.8292 96.9703 83.6598 96.8921 83.6129 96.7697L78.8578 84.3978C78.8397 84.3511 78.7921 84.2268 78.9276 84.1037C79.063 83.9808 79.1825 84.0403 79.2272 84.0627L83.4268 86.1628C84.1476 86.5235 85.0237 86.2311 85.384 85.5104C85.7444 84.7897 85.4525 83.9135 84.7316 83.5532L80.532 81.453C79.3472 80.8603 77.9475 81.0529 76.9665 81.9432C75.9854 82.8337 75.6586 84.2081 76.134 85.4447L80.8891 97.8166C81.3706 99.0689 82.5995 99.8561 83.8866 99.8561C84.2239 99.8561 84.5655 99.802 84.8985 99.688C89.0362 98.2714 92.052 95.5342 93.8615 91.5526C94.5628 90.0103 93.9012 88.1389 92.3865 87.3815V87.3815Z" fill="black" />
    <Path d="M11.4214 50.0248C11.443 49.0011 10.8886 48.0783 9.97459 47.6167L4.49237 44.848C3.85728 44.5272 3.10743 44.4767 2.43499 44.7093C1.76119 44.9423 1.22452 45.4245 0.924384 46.0672C-0.308647 48.7064 -0.308258 51.2627 0.925356 53.6654C1.39278 54.5757 2.31478 55.1036 3.27549 55.1036C3.63981 55.1036 4.00978 55.0275 4.36244 54.8677L9.87402 52.3699C10.8071 51.947 11.4 51.0485 11.4214 50.0248ZM3.40679 52.0974C2.75166 50.6725 2.76313 49.1911 3.4418 47.586L8.1335 49.9556L3.40679 52.0974Z" fill="black" />
    <Path d="M99.0753 46.0674C98.7752 45.4247 98.2387 44.9425 97.5649 44.7093C96.8925 44.4764 96.1426 44.5272 95.5075 44.848L90.0253 47.6167C89.1113 48.0785 88.5567 49.001 88.5785 50.0248C88.6001 51.0485 89.1928 51.947 90.1255 52.3697L95.6373 54.8676C95.9899 55.0275 96.3599 55.1034 96.7244 55.1034C97.6849 55.1034 98.6071 54.5755 99.0743 53.6653C100.308 51.2623 100.309 48.706 99.0753 46.0674V46.0674ZM96.5933 52.0973L91.8668 49.9553L96.5583 47.5859C97.2369 49.1911 97.2484 50.6723 96.5933 52.0973V52.0973Z" fill="black" />
    <Path d="M34.7138 10.5708C34.8959 11.2131 35.4806 11.6322 36.1167 11.6322C36.2483 11.6322 36.3822 11.6143 36.5152 11.5766C37.2904 11.357 37.7407 10.5505 37.5211 9.7752L35.9521 4.23872C35.7325 3.46357 34.9262 3.01288 34.1507 3.23288C33.3755 3.45249 32.9252 4.25895 33.1449 5.03428L34.7138 10.5708Z" fill="black" />
    <Path d="M15.6533 26.3058C15.982 25.5701 15.6523 24.7075 14.9169 24.3785L10.5656 22.4334C9.82934 22.1047 8.96705 22.4342 8.63832 23.1698C8.30959 23.9055 8.63929 24.7682 9.37476 25.0971L13.7261 27.0422C13.9196 27.1286 14.1217 27.1696 14.3207 27.1696C14.8784 27.1696 15.4109 26.8479 15.6533 26.3058Z" fill="black" />
    <Path d="M8.88583 66.4738C8.21203 66.9156 8.02393 67.8199 8.46567 68.4937C8.74578 68.9208 9.21164 69.1529 9.68703 69.1529C9.96149 69.1529 10.2389 69.0757 10.4855 68.9138L14.4715 66.3005C15.1453 65.8588 15.3334 64.9545 14.8917 64.2807C14.4499 63.6069 13.5454 63.4186 12.8718 63.8605L8.88583 66.4738Z" fill="black" />
    <Path d="M39.0835 87.742C38.3747 87.358 37.4897 87.621 37.1059 88.3295L34.8349 92.5199C34.4509 93.2283 34.7139 94.1137 35.4223 94.4975C35.6433 94.6173 35.8814 94.6741 36.1162 94.6741C36.6344 94.6741 37.136 94.3975 37.4002 93.9101L39.6711 89.7196C40.0549 89.0114 39.7919 88.126 39.0835 87.742V87.742Z" fill="black" />
    <Path d="M63.4849 11.5765C63.6179 11.6143 63.7518 11.6322 63.8834 11.6322C64.5195 11.6322 65.1042 11.2128 65.2863 10.5707L66.8552 5.0342C67.075 4.25906 66.6245 3.4526 65.8494 3.2328C65.0741 3.01299 64.2676 3.46369 64.048 4.23863L62.479 9.77512C62.2592 10.5503 62.7095 11.3567 63.4849 11.5765V11.5765Z" fill="black" />
    <Path d="M91.3618 23.1697C91.0331 22.434 90.1699 22.1049 89.4348 22.4332L85.0835 24.3784C84.3478 24.7073 84.0181 25.57 84.3471 26.3056C84.5894 26.8477 85.1218 27.1695 85.6797 27.1695C85.8787 27.1695 86.0808 27.1286 86.2743 27.0421L90.6256 25.0969C91.3611 24.768 91.6908 23.9053 91.3618 23.1697V23.1697Z" fill="black" />
    <Path d="M85.1081 64.2805C84.6664 64.9543 84.8545 65.8586 85.5283 66.3003L89.5143 68.9136C89.7611 69.0755 90.0383 69.1527 90.3128 69.1527C90.788 69.1527 91.254 68.9206 91.5341 68.4935C91.9759 67.8197 91.7878 66.9154 91.114 66.4736L87.128 63.8603C86.454 63.4186 85.5501 63.6067 85.1081 64.2805Z" fill="black" />
    <Path d="M62.8943 88.3297C62.5107 87.6213 61.6253 87.3579 60.9167 87.7421C60.2082 88.1258 59.9453 89.0113 60.329 89.7197L62.6 93.9101C62.864 94.3976 63.3658 94.6742 63.884 94.6742C64.1188 94.6742 64.3569 94.6174 64.5776 94.4978C65.2861 94.1138 65.5491 93.2284 65.1653 92.5201L62.8943 88.3297Z" fill="black" />
  </Svg>
);
export default IssueReported;
