import { Dimensions, Platform, StyleSheet } from 'react-native';
import Colors from './Colors';

const mobile: boolean = Platform.OS !== 'web' || Dimensions.get('window').width < 500;

export default StyleSheet.create({
  boxShadow: {
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.1,
    shadowRadius: 40,
    shadowColor: 'black',
    elevation: 5,
  },
  circleShadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.6,
    shadowRadius: 16,
    elevation: 5,
  },
  barShadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 16,
    borderRadius: 8,
    shadowColor: 'rgba(0, 31, 49, 1)',
    elevation: 5,
  },
  closeButton: {
    width: 36,
    height: 36,
    borderRadius: 18,
    position: 'absolute',
    right: mobile ? 16 : 24,
    top: mobile ? 16 : 24,
    backgroundColor: Colors.light.hereworksBlue50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicator: {
    position: 'absolute',
    alignSelf: 'center',
    top: '49%',
  },
  row: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
});
