import { processFontFamily } from 'expo-font';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryVoronoiContainer,
} from 'victory';
import Colors from '../constants/Colors';
import { lineColorCompared, lineColorComparedHappy, lineColors } from '../components/Home/HomeGraphLineSelector';
import { tickValSamples, tooltipDateLabel, axisDateLabel } from './axisUtils';

export interface PointProps {
  value: number,
  date: string,
  index: number,
  compareDate?: string
}

interface HappyDetailsGraphWebProps {
  tileData?: { score: number }[],
  linesShown?: boolean[],
  selectedTileIndex?: number,
  graphIndex?: number,
  graphData: PointProps[][],
  graphCompareData: PointProps[][],
  showComparison: boolean,
}

const yPosition = (value: number) => 51 + 1.68 * (100 - value);

const shortNames: string[] = ['Happy score', 'Internal env.', 'External env.', 'IT', 'Energy', 'Occupancy', 'Amenities', 'Ergonomics', 'Satisfaction'];

export const happyScoreMetrics: string[] = ['happyscore',
  'happy_group_internal_environment_local_happyscore_percent',
  'happy_group_external_environment_local_happyscore_percent',
  'happy_group_it_local_happyscore_percent',
  'happy_group_energy_usage_local_happyscore_percent',
  'happy_group_building_occupancy_local_happyscore_percent',
  'happy_group_amenities_local_happyscore_percent',
  'happy_group_ergonomics_local_happyscore_percent',
  'happy_group_occupant_satisfaction_local_happyscore_percent'];

const Line = (data: PointProps[], stroke: string) => <VictoryLine data={data} style={{ data: { stroke, strokeWidth: 1.5 } }} interpolation='linear' x='index' y='value' />;

const XAxis = (tickVals: number[], tickFormat, dy: number, fill: string) => <VictoryAxis name='x-axis'
  crossAxis={false}
  fixLabelOverlap
  style={{ axis: { stroke: 'none' }, tickLabels: { fontSize: 14 } }}
  tickValues={tickVals}
  tickFormat={tickFormat}
  tickLabelComponent={<VictoryLabel dy={dy} dx={-20}
    style={{
      fill,
      fontSize: 14,
      strokeWidth: 0,
      fontFamily: processFontFamily('Poppins_400Regular'),
      textAnchor: 'center',
    }} />}
  tickCount={7} />;

const HappyDetailsGraphWeb = ({
  selectedTileIndex, showComparison, graphData, graphCompareData, graphIndex, linesShown, tileData,
}: HappyDetailsGraphWebProps) => {
  const [target, setTarget] = useState(78);
  const [chartWidth, setChartWidth] = useState(1108);
  const lineIndex: number | null = selectedTileIndex || graphIndex;
  const lineData: { value: number, index: number, date: string }[] = graphData[lineIndex];
  const lineCompareData: { value: number, index: number, date: string }[] = graphCompareData[
    lineIndex];
  const overallData: { value: number, index: number, date: string }[] = graphData[0];
  const overallCompareData: { value: number, index: number, date: string }[] = graphCompareData[0];
  const linesShownIndices: number[] = linesShown?.map((item, index) => (item ? index : -1))
    ?.filter((item) => item > 0);

  useEffect(() => {
    AsyncStorage.getItem('clientName').then((clientName) => {
      if (clientName === 'mastercard') {
        setTarget(50);
      }
    });
  }, []);

  const chartTheme = {
    axis: {
      stroke: 'none',
      style: {
        stroke: '#696D70',
        tickLabels: { fill: '#696D70' },
        grid: { stroke: 'none' },
      },
    },
  };

  class CustomFlyout extends React.Component {
    render() {
      // @ts-ignore
      const { x, y, datum } = this.props;
      let containerHeight: number = selectedTileIndex > 0 ? 76 : 56;
      if (showComparison) containerHeight += selectedTileIndex > 0 ? 60 : 40;
      if (selectedTileIndex === 0) {
        containerHeight += 20 * (linesShown.filter((item) => !!item).length - 1);
      }
      const containerY: number = Math.max(Math.min(y - containerHeight, 210 - containerHeight), 10);
      const date: Date = ((lineData ?? overallData)
        && (lineData ?? overallData)[datum.index])
        && ((lineData ?? overallData)[datum.index]?.date)
        ? new Date((lineData ?? overallData)[datum.index]?.date) : null;
      const compareDate: Date = ((lineCompareData ?? overallCompareData)
        && (lineCompareData ?? overallCompareData)[datum.index])
        && ((lineCompareData ?? overallCompareData)[datum.index]?.date)
        ? new Date((graphIndex ? lineCompareData : overallCompareData)[datum.index]?.date) : null;
      const mainDateString: string | null = date ? `${tooltipDateLabel((lineData ?? overallData), datum.index)}` : '-';
      const compareDateString: string | null = compareDate ? `vs. ${tooltipDateLabel((lineCompareData ?? overallCompareData), datum.index)} (c)` : null;
      const happyScoreString: string | null = overallData ? `${shortNames[0]}: ${overallData[datum.index]?.value ? Math.floor(overallData[datum.index]?.value) : '-'}` : null;
      const metricScoreString: string | null = lineIndex ? `${shortNames[lineIndex]}: ${lineData[datum.index] ? Math.floor(lineData[datum.index].value) : '-'}` : null;
      const happyScoreComparedString: string | null = overallCompareData ? `${shortNames[0]} (c): ${overallCompareData[datum.index]?.value ? Math.floor(overallCompareData[datum.index]?.value) : '-'}` : null;
      const metricScoreComparedString: string | null = lineCompareData ? `${shortNames[lineIndex]} (c): ${lineCompareData[datum.index]?.value ? Math.floor(lineCompareData[datum.index]?.value) : '-'}` : null;
      const containerWidth: number = Math.max(
        160,
        10 + 7.5 * Math.max(
          mainDateString?.length ?? 0,
          compareDateString?.length ?? 0,
          overallData[datum.index]?.value ? happyScoreString?.length : 0,
          shortNames[lineIndex] ? metricScoreString?.length : 0,
          (showComparison && overallCompareData && overallCompareData[datum.index]
            && metricScoreComparedString) ? happyScoreComparedString?.length : 0,
          (showComparison && lineCompareData && lineCompareData[datum.index])
            ? metricScoreComparedString?.length : 0,
        ),
      );
      const containerX: number = x > containerWidth + 10
        ? x - containerWidth - 12 : x + 12;

      return <svg>
        <defs>
          <filter id='f3' x={-0.5} y={-1} width='200%' height='250%'>
            <feOffset result='offOut' in='SourceGraphic' dx='0' dy='0' />
            <feColorMatrix result='matrixOut'
              in='offOut'
              type='matrix'
              values='0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.1 0 0 0 0 0 0.2 0' />
            <feGaussianBlur result='blurOut' in='matrixOut' stdDeviation='4' />
            <feBlend in='SourceGraphic' in2='blurOut' mode='normal' result='colored' />
          </filter>
        </defs>
        {!(graphIndex > 0) && overallData[datum.index]?.value && <circle r={4}
          strokeWidth={3}
          stroke={lineColors[0]}
          cx={x}
          cy={yPosition(overallData[datum.index].value)}
          fill='#FFF' />}
        {lineData && lineData[datum.index]?.value && <circle r={4}
          strokeWidth={3}
          stroke={Colors.light.hereworksDarkBlue}
          cx={x}
          cy={yPosition(lineData[datum.index].value)}
          fill='#FFF' />}
        {!(graphIndex > 0) && showComparison && overallCompareData
          && overallCompareData[datum.index]?.value
          && <circle r={4}
            strokeWidth={3}
            stroke={lineColorComparedHappy}
            cx={x}
            cy={yPosition(overallCompareData[datum.index].value)}
            fill='#FFF' />}
        {showComparison && lineCompareData && lineCompareData[datum.index]?.value && <circle r={4}
          strokeWidth={3}
          stroke={lineColorCompared}
          cx={x}
          cy={yPosition((lineCompareData[datum.index].value))}
          fill='#FFF' />}
        {selectedTileIndex === 0 && linesShown
          ?.map((item: boolean, index: number) => item && graphData[index][datum.index]?.value
            && index && <circle r={4}
              strokeWidth={3}
              stroke={lineColors[index]}
              cx={x}
              cy={yPosition((graphData[index][datum.index].value))}
              fill='#FFF' />)}
        <line x1={x}
          x2={x}
          y1={25}
          y2={220}
          stroke={Colors.light.hereworksBlue100}
          strokeDasharray='10,10'
          strokeWidth={1} />
        <rect
          x={containerX}
          y={containerY}
          width={containerWidth}
          height={containerHeight}
          stroke={Colors.light.hereworksDarkBlue}
          rx={5}
          fill='white'
          filter='url(#f3)' />
        {mainDateString && <text
          x={containerX + 10}
          y={containerY + 20}
          fontFamily={processFontFamily('Poppins_500Medium')}
          fontSize={12}
          strokeWidth={0}
          fill={Colors.light.hereworksBlack600}>
          {mainDateString}
        </text>}
        {showComparison && (lineCompareData ?? overallCompareData)
          && (lineCompareData ?? overallCompareData)[datum.index]
          && compareDateString && <text
            x={containerX + 10}
            y={containerY + 40}
            fontFamily={processFontFamily('Poppins_500Medium')}
            fontSize={12}
            strokeWidth={0}
            fill={Colors.light.hereworksBlack600}>
            {compareDateString}
          </text>}
        {!(graphIndex > 0) && <circle r={6}
          cx={containerX + 15}
          cy={containerY + 40.5 + (showComparison ? 20 : 0)}
          fill={Colors.light.hereworksPurple500} />}
        {!(graphIndex > 0) && happyScoreString && <text
          x={containerX + 30}
          y={containerY + 45 + (showComparison ? 20 : 0)}
          fontFamily={processFontFamily('Poppins_500Medium')}
          fontSize={12}
          strokeWidth={0}
          fill={Colors.light.hereworksBlack600}>
          {happyScoreString}
        </text>}
        {lineIndex && <circle r={6}
          cx={containerX + 15}
          cy={containerY + (!(graphIndex > 0) ? 60.5 : 40.5) + (showComparison ? 20 : 0)}
          fill={Colors.light.hereworksDarkBlue} />}
        {lineIndex && metricScoreString && <text
          x={containerX + 30}
          y={containerY + (!(graphIndex > 0) ? 65 : 45) + (showComparison ? 20 : 0)}
          fontFamily={processFontFamily('Poppins_500Medium')}
          fontSize={12}
          strokeWidth={0}
          fill={Colors.light.hereworksBlack600}>
          {metricScoreString}
        </text>}
        {!(graphIndex > 0) && showComparison && <circle r={6}
          cx={containerX + 15}
          cy={containerY + 80.5 + (selectedTileIndex > 0 ? 20 : 0)}
          fill={lineColorComparedHappy} />}
        {!(graphIndex > 0) && showComparison && happyScoreComparedString && <text
          x={containerX + 30}
          y={containerY + 85 + (selectedTileIndex > 0 ? 20 : 0)}
          fontFamily={processFontFamily('Poppins_500Medium')}
          fontSize={12}
          strokeWidth={0}
          fill={Colors.light.hereworksBlack600}>
          {happyScoreComparedString}
        </text>}
        {showComparison && lineIndex && <circle r={6}
          cx={containerX + 15}
          cy={containerY + (!(graphIndex > 0) ? 100.5 : 60.5) + (showComparison ? 20 : 0)}
          fill={lineColorCompared} />}
        {showComparison && lineIndex && metricScoreComparedString && <text
          x={containerX + 30}
          y={containerY + (!(graphIndex > 0) ? 105 : 65) + (showComparison ? 20 : 0)}
          fontFamily={processFontFamily('Poppins_500Medium')}
          fontSize={12}
          strokeWidth={0}
          fill={Colors.light.hereworksBlack600}>
          {metricScoreComparedString}
        </text>}
        {selectedTileIndex === 0 && linesShownIndices
          ?.map((metricIndex: number, index: number) => metricIndex && <circle r={6}
            cx={containerX + 15}
            cy={containerY + (showComparison ? 100.5 : 60.5) + (20 * index)}
            fill={lineColors[metricIndex]} />)}
        {selectedTileIndex === 0
          && linesShownIndices?.sort((prev, next) => tileData[prev].score - tileData[next].score)
            ?.map((metricIndex: number, index: number) => metricIndex && <text
              x={containerX + 30}
              y={containerY + (showComparison ? 105 : 65) + (20 * index)}
              fontFamily={processFontFamily('Poppins_500Medium')}
              fontSize={12}
              strokeWidth={0}
              fill={Colors.light.hereworksBlack600}>
              {`${shortNames[metricIndex]}: ${graphData[metricIndex][datum.index]?.value ? Math.floor(graphData[metricIndex][datum.index]?.value) : '-'}`}
            </text>)}
      </svg>;
    }
  }

  return <View onLayout={(event) => setChartWidth(event.nativeEvent.layout.width)}>
    <VictoryChart containerComponent={
      <VictoryVoronoiContainer labels={({ datum }) => datum}
        voronoiDimension='x'
        labelComponent={<CustomFlyout />} />}
      theme={chartTheme}
      width={chartWidth}
      domainPadding={{ x: [0, 40] }}
      height={270}>
      <VictoryAxis name='y-axis'
        dependentAxis
        tickValues={[0, 25, 50, 75, 100]}
        tickCount={5}
        domain={[-1, 101]}
        fixLabelOverlap
        crossAxis={false}
        style={{
          axis: { stroke: 'none' },
          tickLabels: {
            fontSize: 14,
          },
          grid: { stroke: Colors.light.hereworksWhite500, strokeWidth: 1, pointerEvents: 'none' },
        }}
        containerComponent={<VictoryVoronoiContainer />}
        tickLabelComponent={
          <VictoryLabel style={{
            fill: Colors.light.hereworksBlack400,
            fontSize: 14,
            strokeWidth: 0,
            fontFamily: processFontFamily('Poppins_400Regular'),
          }}
            textAnchor='end'
            dx={-25}
          />} />
      {XAxis(
        tickValSamples(
          [...Array((lineData ?? overallData)?.length ?? []).keys()],
          (Math.floor(Math.max(1, 7 * Math.min(1, (lineData ?? overallData ?? []).length
            / ((lineCompareData ?? overallCompareData)?.length || 1))))),
        ),
        (index) => (lineData ?? overallData) && ((lineData ?? overallData)[index]?.date ? axisDateLabel(lineData ?? overallData, index) : ''),
        22,
        Colors.light.hereworksBlack400,
      )}
      {showComparison && XAxis(
        tickValSamples(
          [...Array((lineCompareData ?? overallCompareData)?.length ?? []).keys()],
          (Math.floor(Math.max(1, 7 * Math.min(1, (lineCompareData ?? overallCompareData ?? [])
            .length / ((lineData ?? overallData)?.length || 1))))),
        ),
        (index) => (lineCompareData ?? overallCompareData) && ((lineCompareData ?? overallCompareData)[index]?.date ? axisDateLabel(lineCompareData ?? overallCompareData, index) : ''),
        39,
        lineColorCompared,
      )}
      {selectedTileIndex === 0 && <line y1={yPosition(target)}
        y2={yPosition(target)}
        x1={50}
        x2={chartWidth - 120}
        stroke={'#000'}
        strokeDasharray='10,10'
        strokeWidth={1} />}
      {lineData && Line(lineData, Colors.light.hereworksDarkBlue)}
      {lineData && Line(lineData, '#0000')}
      {!(graphIndex > 0) && Line(overallData, Colors.light.hereworksPurple500)}
      {showComparison && lineCompareData && Line(lineCompareData, lineColorCompared)}
      {!(graphIndex > 0) && showComparison && Line(overallCompareData, lineColorComparedHappy)}
      {selectedTileIndex === 0 && linesShown
        ?.map((item: boolean, index: number) => item && Line(graphData[index], lineColors[index]))}
    </VictoryChart>
    {selectedTileIndex === 0 && <Text style={[styles.targetLabel, { top: yPosition(target) - 8 }]}>
      {`Target ${target}`}
    </Text>}
    {!(lineData?.filter((item) => !!item?.value)?.length
      || (showComparison && lineCompareData?.filter((item) => !!item?.value)?.length)
      || (selectedTileIndex === 0 && (overallData?.filter((item) => !!item?.value)?.length
        || (showComparison && overallCompareData?.filter((item) => !!item?.value)?.length))))
      && <Text style={[styles.noDataLabel, selectedTileIndex === 0 && { top: yPosition(0) }]}>
        No data available
      </Text>}
  </View>;
};

const styles = StyleSheet.create({
  targetLabel: {
    position: 'absolute',
    fontSize: 12,
    lineHeight: 18,
    fontFamily: 'Poppins_400Regular',
    end: 50,
    backgroundColor: '#FFFB',
    padding: 4,
  },
  noDataLabel: {
    position: 'absolute',
    top: '45%',
    start: '45%',
  },
});

export default HappyDetailsGraphWeb;
