import React, { useState } from 'react';
import {
  Text,
  StyleSheet,
  View,
  Platform,
  NativeEventEmitter,
  TouchableOpacity,
  TextInput,
  AppState,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Modal from 'react-native-modal';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import BellIcon from './BellIcon';
import NotificationsCard from './NotificationsCard';
import SearchIcon from './SearchIcon';
import getIssues from '../requests/GetIssues';
import ProfileIcon from './24pxIcons/ProfileIcon';
import getUsers from '../requests/GetUsers';

interface Props {
  navigation: any;
}

// const CustomHeader = (props: DrawerHeaderProps) => {
const CustomHeader = (props: Props) => {
  // const title = props.options.title ?? props.route.name;

  const [modalVisible, setModalVisible] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [refreshCount, setRefreshCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [name, updateName] = useState('name');
  const [email, setEmail] = useState('');
  const [groups, setGroups] = useState([]);

  const searchBarShown = false;

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AsyncStorage.getItem('email').then((e) => {
      setEmail(e);
    }) ?? '';
  }, []);

  React.useEffect(() => {
    getUsers().then((response) => {
      if (response.Users?.length > 0) {
        response.Users.forEach((user) => {
          if (user.Email === email) {
            setGroups(user?.UserGroupIds);
          }
        });
      }
    });
  }, [email]);

  React.useEffect(() => {
    if (groups.length > 0) {
      getIssues(
        10,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        '',
        '',
        '',
        groups,
        null,
        null,
      ).then((response) => {
        if (response) {
          setNotificationsCount(response?.stats?.statuses?.reported ?? 0);
        }
      });
    }
  }, [refreshCount]);

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AsyncStorage.getItem('name').then((n) => {
      updateName(n);
    }) ?? '';
  }, []);

  React.useEffect(() => {
    const emitter = new NativeEventEmitter();
    emitter.addListener('event.updatenotifications', () => {
      setRefreshCount(refreshCount + 1);
    });
  }, []);

  React.useEffect(() => {
    if (AppState.currentState === 'active') {
      if (groups.length > 0) {
        const oldCount = notificationsCount;
        getIssues(
          10,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          '',
          '',
          '',
          groups,
          null,
          null,
        ).then((response) => {
          if (response) {
            const newCount = response?.stats?.statuses?.reported ?? 0;
            setNotificationsCount(newCount);
            if (initialLoaded && newCount !== oldCount) {
              const emitter = new NativeEventEmitter();
              emitter.emit('event.newupdates', {});
            }
            setInitialLoaded(true);
          }
        });
      }
    }
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 15000);

    return () => clearInterval(interval);
  }, [counter, groups]);

  return (
    <View style={{ flex: 1, backgroundColor: 'transparent' }}>
      <View style={styles.containerStyle}>
        {/* <Text style={styles.headerText}>{title}</Text> */}
        {searchBarShown && (
          <View style={styles.searchBox}>
            <SearchIcon style={{ marginLeft: 14 }} />
            <TextInput
              placeholder="Search"
              style={[
                styles.searchText,
                // @ts-ignore
                Platform.OS === 'web' ? { outlineStyle: 'none' } : {},
              ]}
            />
          </View>
        )}
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <TouchableOpacity
            style={styles.notificationMenu}
            onPress={() => setModalVisible(!modalVisible)}
          >
            <BellIcon color={Colors.light.hereworksDarkBlue} />
            {notificationsCount > 0 && (
              <View
                style={[
                  styles.notificationCount,
                  notificationsCount > 99 ? { width: 26, right: -10 } : {},
                ]}
              >
                <Text style={styles.notificationCountText}>{notificationsCount}</Text>
              </View>
            )}
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.profileSideMenu}
            onPress={() => {
              props.navigation.navigate('Profile');
              // Auth.signOut();
            }}
          >
            <View
              style={{
                height: 48,
                width: 48,
                borderRadius: 24,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: Colors.light.hereworksBlue100,
              }}
            >
              <ProfileIcon />
            </View>
            <View>
              <Text style={styles.username}>{name}</Text>
              <Text style={styles.viewProfile}>View profile</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => setModalVisible(false)}
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        backdropOpacity={0}
        style={{ margin: 0 }}
      >
        <View style={[styles.notificationsModal, Style.boxShadow]}>
          <NotificationsCard hideModal={() => setModalVisible(false)} />
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: '#FFFFFF',
    height: 92,
    borderLeftWidth: 1,
    borderColor: Colors.light.hereworksWhite500,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 40,
    shadowColor: '#FFF',
    shadowRadius: 20,
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 8 },
    elevation: 5,
  },
  headerText: {
    height: 33,
    fontFamily: 'Poppins_400Regular',
    fontSize: 22,
    marginLeft: 28,
    marginTop: 22,
    marginBottom: 22,
    flex: 1,
  },
  searchBox: {
    height: 48,
    width: 542,
    borderRadius: 8,
    borderColor: Colors.light.hereworksBlue100,
    borderWidth: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchText: {
    color: Colors.light.hereworksPrimaryGrey,
    marginLeft: 9,
    borderWidth: 0,
  },
  notificationMenu: {
    height: 48,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: Colors.light.hereworksPaleBlue900,
    backgroundColor: Colors.light.hereworksBackground,
    borderWidth: 1.5,
    borderRadius: 24,
    marginRight: 14,
  },
  notificationsModal: {
    position: 'absolute',
    backgroundColor: '#fff',
    top: 92,
    bottom: 0,
    right: 0,
    width: 400,
    maxHeight: 640,
  },
  notificationCount: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: -2.5,
    right: -3.5,
    borderRadius: 10,
    justifyContent: 'center',
    backgroundColor: Colors.light.hereworksUrgentRed,
  },
  notificationCountText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#fff',
    fontFamily: 'Roboto_700Bold',
  },
  profileSideMenu: {
    marginLeft: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  username: {
    fontFamily: 'Poppins_500Medium',
    fontSize: 14,
    marginLeft: 12,
    color: Colors.light.hereworksBlack600,
  },
  viewProfile: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 14,
    marginLeft: 12,
    marginTop: 4,
    color: Colors.light.hereworksBlue400,
  },
});

export default CustomHeader;
