import React, { useState } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import Colors from '../../constants/Colors';
import NewSwitch from '../NewSwitch';

type Props = {
  title: string;
  subTitle?: string;
  toggled: boolean;
  showSwitches?: boolean;
};

export default function IssueSettingRow(props: Props) {
  const [toggled, setToggled] = useState(props.toggled);

  React.useEffect(() => {
    setToggled(props.toggled);
  }, [props.toggled]);
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>{props.title}</Text>
        {props.subTitle && <Text style={styles.subTitle}>{props.subTitle}</Text>}
      </View>
      {props.showSwitches && (
        <TouchableOpacity
          onPress={() => setToggled(!toggled)}
          style={{ position: 'absolute', right: 16 }}
        >
          <NewSwitch isToggled={toggled} color={Colors.light.hereworksGreen400} style={{}} />
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.light.hereworksWhite500,
    alignSelf: 'stretch',
    paddingVertical: 10,
    paddingLeft: 16,
    borderRadius: 8,
    marginTop: 4,
  },
  title: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
  },
  subTitle: {
    fontSize: 16,
    lineHeight: 22,
    fontFamily: 'Roboto_500Medium',
    color: Colors.light.hereworksBlack400,
  },
});
