import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ReportTimeIcon = (props: SvgProps) => (
  <Svg
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <Path
      d="M15 4.167C9.027 4.167 4.167 9.027 4.167 15S9.027 25.833 15 25.833c5.974 0 10.833-4.86 10.833-10.833 0-5.974-4.86-10.834-10.833-10.834Zm0 19.5c-4.779 0-8.667-3.889-8.667-8.667 0-4.779 3.888-8.667 8.667-8.667s8.667 3.888 8.667 8.667c0 4.778-3.888 8.666-8.667 8.666Z"
      fill="#323E48"
      stroke="#F8FAFB"
      strokeWidth={0.5}
    />
    <Path
      d="M16.083 9.584h-2.166v6.5h6.5v-2.167h-4.334V9.584Z"
      fill="#323E48"
      stroke="#F8FAFB"
      strokeWidth={0.5}
    />
  </Svg>
);

export default ReportTimeIcon;
