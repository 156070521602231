import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UploadIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="m8 12 4-4 4 4M5 3h14m-7 18V8v13Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default UploadIcon;
