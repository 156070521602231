import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, TouchableOpacity, Text, TextInput, Platform, Dimensions, ActivityIndicator,
  ScrollView,
} from 'react-native';
import Modal from 'react-native-modal';
import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';
import Button from '../../Button';
import BinIcon24 from '../../24pxIcons/BinIcon24';
import CloseModalIcon from '../../CloseModalIcon';
import DropdownIcon from '../../16pxIcons/DropdownIcon';
import FlagIcon16 from '../../16pxIcons/FlagIcon16';
import {
  Floor, FloorPriority, FLOOR_PRIORITY_OPTIONS, LocationPriority,
} from './FloorItem';
import FloorList from './FloorList';
import FloorPlanBox from './FloorPlanBox';
import updateFloorPlan from '../../../requests/UpdateFloorPlan';
import AlertModal from '../../AlertModal';

const mobile: boolean = Platform.OS !== 'web' || Dimensions.get('window').width < 500;

interface SetFloorsModalProps {
  isVisible: boolean,
  hide: () => void,
  parentId: string,
  defaultFloorData: Floor[],
}

const SetFloorsModal = ({
  isVisible, hide, parentId, defaultFloorData = [],
}: SetFloorsModalProps) => {
  const [visibilityToggleEnabled, setVisibilityToggleEnabled] = useState<boolean>(true);
  const [selectedFloorIndex, setSelectedFloorIndex] = useState<number>(-1);
  const [floors, setFloors] = useState<Floor[]>(defaultFloorData);
  const [currentFloorName, setCurrentFloorName] = useState<string>(null);
  const [currentFloorNumber, setCurrentFloorNumber] = useState<string>(null);
  const [currentFloorPlan, setCurrentFloorPlan] = useState<string>(null);
  const [currentFloorPlanSize, setCurrentFloorPlanSize] = useState<number>(null);
  const [currentFloorPlanFilename, setCurrentFloorPlanFilename] = useState<string>(null);
  const [currentFloorPriority, setCurrentFloorPriority] = useState<FloorPriority>(null);
  const [priorityDropdownShown, setPriorityDropdownShown] = useState<boolean>(false);
  const [floorNameDuplicate, setFloorNameDuplicate] = useState<boolean>(false);
  const [floorNumberDuplicate, setFloorNumberDuplicate] = useState<boolean>(false);
  const [floorNameEmpty, setFloorNameEmpty] = useState<boolean>(false);
  const [floorNumberEmpty, setFloorNumberEmpty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>();
  const [alertMessage, setAlertMessage] = useState<string>();
  const [alertError, setAlertError] = useState<boolean>();

  const showAlert = (title: string, message: string, error?: boolean) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertError(error);
    setLoading(false);
    setAlertVisible(true);
  };

  useEffect(() => {
    if (isVisible) {
      setVisibilityToggleEnabled(false);
      const timeout = setTimeout(() => {
        setVisibilityToggleEnabled(true);
        clearTimeout(timeout);
      }, 500);
    } else setVisibilityToggleEnabled(true);
    setLoading(false);
    setFloors(defaultFloorData);
    setSelectedFloorIndex(-1);
  }, [isVisible]);

  useEffect(() => {
    setCurrentFloorName(floors[selectedFloorIndex]?.Name);
    setCurrentFloorNumber(floors[selectedFloorIndex]?.Number);
    setCurrentFloorPriority(floors[selectedFloorIndex]?.Priority);
    setCurrentFloorPlan(floors[selectedFloorIndex]?.plan);
    setCurrentFloorPlanSize(floors[selectedFloorIndex]?.planSize);
    setCurrentFloorPlanFilename(floors[selectedFloorIndex]?.planFilename);
  }, [selectedFloorIndex]);

  useEffect(() => {
    setPriorityDropdownShown(false);
    setFloorNameDuplicate(floors?.filter((floor: Floor, index: number) => currentFloorName
      ?.trim() === floor.Name?.trim() && index !== selectedFloorIndex).length > 0);
    setFloorNumberDuplicate(floors?.filter((floor: Floor, index: number) => currentFloorNumber
      ?.trim() === floor.Number?.trim() && index !== selectedFloorIndex).length > 0);
    setFloorNameEmpty(!currentFloorName?.trim().length);
    setFloorNumberEmpty(!currentFloorNumber?.trim().length);
  }, [currentFloorName, currentFloorNumber, currentFloorPriority]);

  const closeModal = visibilityToggleEnabled ? hide : null;

  const cancel = () => {
    if (selectedFloorIndex >= 0) setSelectedFloorIndex(-1);
    else hide();
  };

  const deleteFloor = () => {
    const floorsToUpdate: Floor[] = [...floors];
    floorsToUpdate.splice(selectedFloorIndex, 1);
    setFloors(floorsToUpdate);
    setSelectedFloorIndex(-1);
  };

  const floorPlanError = (errorText: string) => showAlert(
    'Error',
    errorText ?? 'Could not update floor plan at the moment. Please try again later.',
  );

  const save = () => {
    if (loading || (selectedFloorIndex > -1 && (
      floorNameEmpty || floorNameDuplicate || floorNumberEmpty || floorNumberDuplicate
    ))) return;
    if (selectedFloorIndex >= 0) {
      setLoading(true);
      const floorsToUpdate: Floor[] = [...floors];
      floorsToUpdate[selectedFloorIndex].Name = currentFloorName?.trim();
      floorsToUpdate[selectedFloorIndex].Number = currentFloorNumber?.trim();
      floorsToUpdate[selectedFloorIndex].Priority = currentFloorPriority;
      floorsToUpdate[selectedFloorIndex].plan = currentFloorPlan;
      floorsToUpdate[selectedFloorIndex].planSize = currentFloorPlanSize;
      floorsToUpdate[selectedFloorIndex].planFilename = currentFloorPlanFilename?.trim();
      setLoading(false);
      setSelectedFloorIndex(-1);
    } else {
      setLoading(true);
      updateFloorPlan(parentId, floors).then((res) => {
        if (res && !res?.isError) {
          hide();
          showAlert(
            'Updated floor plan',
            'Building floor plan has been successfully updated!',
            false,
          );
        } else floorPlanError(res?.errorText);
      }).catch(floorPlanError);
    }
  };

  return <>
    <Modal
      {...{ isVisible }}
      style={styles.modal}
      onBackButtonPress={hide}
      onBackdropPress={closeModal}
      onSwipeComplete={closeModal}
    >
      <View style={styles.container}>
        <Text style={styles.title}>
          {selectedFloorIndex >= 0 ? 'Edit floor' : 'Set floors'}
        </Text>
        {selectedFloorIndex >= 0 ? <ScrollView style={styles.editFloorContainer}>
          <Text style={styles.description}>Fields marked with * are mandatory</Text>
          <View style={[Style.row, styles.textInputRow]}>
            <View style={styles.textField}>
              <Text style={[styles.description, styles.textInputTitle]}>Floor name *</Text>
              <TextInput
                placeholder='Enter floor name'
                placeholderTextColor={Colors.light.hereworksBlack200}
                style={[
                  styles.textInput,
                  (floorNameDuplicate || floorNameEmpty) && styles.textInputError,
                ]}
                value={currentFloorName ?? ''}
                onChangeText={setCurrentFloorName}
              />
              {floorNameEmpty && <Text style={styles.errorText}>
                Floor name cannot be empty
              </Text>}
              {floorNameDuplicate && <Text style={styles.errorText}>
                Floor name already assigned to another floor
              </Text>}
            </View>
            <View style={styles.textField}>
              <Text style={[styles.description, styles.textInputTitle]}>Floor number *</Text>
              <TextInput
                placeholder='Enter floor number'
                placeholderTextColor={Colors.light.hereworksBlack200}
                style={[
                  styles.textInput,
                  (floorNumberDuplicate || floorNumberEmpty) && styles.textInputError,
                ]}
                value={currentFloorNumber ?? ''}
                maxLength={5}
                onChangeText={setCurrentFloorNumber}
              />
              {floorNumberEmpty && <Text style={styles.errorText}>
                Floor number cannot be empty
              </Text>}
              {floorNumberDuplicate && <Text style={styles.errorText}>
                Floor number already assigned to another floor
              </Text>}
            </View>
            <TouchableOpacity
              style={[
                Style.row, styles.priorityIndicator,
                { borderColor: FLOOR_PRIORITY_OPTIONS[currentFloorPriority]?.color },
              ]}
              onPress={() => setPriorityDropdownShown(!priorityDropdownShown)}
            >
              <FlagIcon16 color={FLOOR_PRIORITY_OPTIONS[currentFloorPriority]?.color} />
              <Text style={[
                styles.priorityText,
                { color: FLOOR_PRIORITY_OPTIONS[currentFloorPriority]?.color },
              ]}>
                {FLOOR_PRIORITY_OPTIONS[currentFloorPriority]?.title}
              </Text>
              <DropdownIcon
                color={FLOOR_PRIORITY_OPTIONS[currentFloorPriority]?.color}
                style={[styles.dropdownIcon, priorityDropdownShown && styles.invert]}
              />
            </TouchableOpacity>
          </View>
          <View>
            {priorityDropdownShown && <View style={styles.priorityDropdown}>
              {FLOOR_PRIORITY_OPTIONS
                .map((option: LocationPriority, index: number) => <TouchableOpacity
                  onPress={() => setCurrentFloorPriority(index)}
                  key={`priority_option_${index}`}
                >
                  <Text style={[
                    styles.optionText,
                    index === currentFloorPriority && styles.optionTextSelected,
                  ]}>
                    {option.title}
                  </Text>
                </TouchableOpacity>)}
            </View>}
          </View>
          <FloorPlanBox
            onPress={() => setPriorityDropdownShown(false)}
            plan={currentFloorPlan}
            planSize={currentFloorPlanSize}
            planFilename={currentFloorPlanFilename}
            updatePlan={(plan: string) => setCurrentFloorPlan(plan)}
            updatePlanSize={(size: number) => setCurrentFloorPlanSize(size)}
            updatePlanFilename={(filename: string) => setCurrentFloorPlanFilename(filename)}
          />
        </ScrollView> : <FloorList
          data={floors}
          setData={(updatedFloors: Floor[]) => setFloors(updatedFloors)}
          edit={setSelectedFloorIndex}
        />}
        <View style={[
          Style.row, styles.buttonRow, !(selectedFloorIndex > -1) && styles.mainButtonsRow,
        ]}>
          {selectedFloorIndex > -1 && <TouchableOpacity
            style={styles.deleteIcon}
            onPress={deleteFloor}
          >
            <BinIcon24 color={styles.deleteIcon.borderColor} />
          </TouchableOpacity>}
          <View style={Style.row}>
            <Button
              affirmative={false}
              onPress={cancel}
              title='Cancel'
            />
            <Button
              affirmative
              onPress={save}
              disabled={selectedFloorIndex > -1 && (
                floorNameEmpty || floorNameDuplicate || floorNumberEmpty || floorNumberDuplicate
              )}
              title={selectedFloorIndex > -1 ? 'Save' : 'Upload'}
            />
          </View>
        </View>
        <TouchableOpacity
          style={Style.closeButton}
          onPress={hide}
        >
          <CloseModalIcon />
        </TouchableOpacity>
        <ActivityIndicator
          style={Style.activityIndicator}
          animating={loading}
          size='large'
        />
      </View>
    </Modal>
    <AlertModal
      modalVisible={alertVisible}
      setModalVisible={setAlertVisible}
      title={alertTitle}
      message={alertMessage}
      error={alertError}
    />
  </>;
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  container: {
    width: mobile ? '80%' : 530,
    maxHeight: '80%',
    justifyContent: 'space-between',
    alignSelf: 'center',
    paddingTop: 60,
    paddingBottom: 40,
    paddingHorizontal: 10,
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: '#FFF',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 0.05,
    elevation: 8,
    shadowRadius: 20,
  },
  title: {
    fontSize: 28,
    lineHeight: 42,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.light.hereworksBlack400,
    fontFamily: 'Roboto_400Regular',
    marginTop: 4,
    marginBottom: 32,
    alignSelf: 'center',
    textAlign: 'center',
  },
  buttonRow: {
    marginTop: 40,
    alignSelf: 'stretch',
    marginHorizontal: 30,
  },
  deleteIcon: {
    height: 40,
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.light.hereworksRed700,
    shadowColor: '#323247',
    shadowRadius: 2,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.06,
  },
  editFloorContainer: {
    paddingHorizontal: 30,
    alignSelf: 'stretch',
  },
  textInputTitle: {
    marginBottom: 4,
    textAlign: 'left',
    alignSelf: 'flex-start',
  },
  textInput: {
    fontSize: 16,
    lineHeight: Platform.OS !== 'ios' ? undefined : 24,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack600,
    height: 44,
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 8,
    backgroundColor: Colors.light.hereworksWhite200,
    borderWidth: 1,
    outlineStyle: 'none',
    borderColor: 'transparent',
  },
  textInputError: {
    borderColor: Colors.light.hereworksRed500,
  },
  textInputRow: {
    width: '100%',
    marginBottom: 16,
  },
  textField: {
    flex: 1,
    marginEnd: 8,
    alignSelf: 'flex-start',
  },
  priorityIndicator: {
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 4,
    backgroundColor: '#FFF',
    position: 'absolute',
    end: 20,
    top: 44,
  },
  priorityText: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_500Medium',
    marginStart: 2,
  },
  dropdownIcon: {
    marginStart: 2,
  },
  priorityDropdown: {
    backgroundColor: '#FFF',
    position: 'absolute',
    borderWidth: 1.5,
    borderRadius: 10,
    borderColor: Colors.light.hereworksBlue100,
    overflow: 'hidden',
    end: 0,
    top: -10,
    zIndex: 2,
  },
  optionText: {
    paddingHorizontal: 12,
    paddingVertical: 9,
    color: Colors.light.hereworksBlack600,
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Poppins_400Regular',
  },
  optionTextSelected: {
    backgroundColor: Colors.light.hereworksDarkBlue,
    color: '#FFF',
  },
  invert: {
    transform: [{ rotate: '180deg' }],
  },
  mainButtonsRow: {
    justifyContent: 'center',
  },
  stretch: {
    alignSelf: 'stretch',
  },
  errorText: {
    fontSize: 12,
    lineHeight: 17.5,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksRed500,
  },
});

export default SetFloorsModal;
