import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const MoveIcon = (props: SvgProps) => (
  <Svg
    width={16}
    height={17}
    fill="none"
    {...props}
  >
    <Path
      stroke={props.color ?? '#548FC8'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m3.332 6.5-2 2m0 0 2 2m-2-2h13.333M6 3.833l2-2m0 0 2 2m-2-2v13.333m2-2-2 2m0 0-2-2M12.665 6.5l2 2m0 0-2 2"
    />
  </Svg>
);

export default MoveIcon;
