import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const MeetingRoom24 = (props: SvgProps) => (
  <Svg width={25} height={24} fill="none" {...props}>
    <Path
      d="m2.667 7 .877 6.141a1 1 0 0 0 .99.859h5.133M23.067 7l-.877 6.141a1 1 0 0 1-.99.859h-5.133M19.268 14v3m0 0-2.5 1m2.5-1 2.5 1M6.467 14v3m0 0 2.5 1m-2.5-1-2.5 1M6.367 10h6.5m6.5 0h-6.5m0 0v7"
      stroke="#89A7CB"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default MeetingRoom24;
