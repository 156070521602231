import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const BellIcon = (props: SvgProps) => (
    <Svg
        width={28}
        height={28}
        fill={props.fill ?? 'none'}
        {...props}
    >
        <Path
            d="M21 9.333a7 7 0 1 0-14 0c0 8.167-3.5 10.5-3.5 10.5h21S21 17.5 21 9.333ZM16.018 24.5a2.334 2.334 0 0 1-4.036 0"
            stroke={props.color ?? '#919193'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default BellIcon;
