import AsyncStorage from '@react-native-async-storage/async-storage';

const getButtonInstances = async (prototypeId = null) => {
  try {
    // const jwtToken = await AsyncStorage.getItem('token');
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('jwt', jwtToken ?? '');

    let requestUrl = `https://${apiDomain}/button/buttoninstances`;
    if (prototypeId) {
      requestUrl = `https://${apiDomain}/button/buttoninstances?buttonPrototypeId=${prototypeId}`;
    }
    const response = await fetch(
      requestUrl,
      {
        method: 'GET',
        headers: requestHeaders,
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default getButtonInstances;
