import React, { useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import Colors from '../../constants/Colors';
import SectionHeader from '../SectionHeader';

interface ItemProps {
  title: string,
  score: number,
  previousScore: number,
  showComparison?: boolean,
  scoreCompared?: number,
  previousScoreCompared?: number,
  analysisShown?: boolean,
  analysisScore?: number,
  analysisStatus?: string,
  analysisDescription?: string,
}

const Item = ({
  title, score, previousScore, showComparison, scoreCompared, previousScoreCompared,
  analysisScore, analysisDescription, analysisStatus, analysisShown,
}: ItemProps) => {
  const improvement: number = score / previousScore;
  const difference: number = score / scoreCompared;
  return <View style={[styles.item, analysisShown && styles.itemAnalysisShown]}>
    <Text style={styles.itemTitle}>{title}</Text>
    <View>
      <View style={styles.scoreSection}>
        <Text style={[
          styles.scoreValue, analysisShown && styles.scoreValueAnalysisShown,
          !(analysisShown ? analysisScore : score) && styles.noValueScore,
        ]}>
          {analysisShown ? analysisScore : (score ?? 'No value')}
        </Text>
        {!analysisShown && showComparison && <Text style={[
          styles.scoreValue, styles.scoreCompared, !scoreCompared && styles.noValueScore]}>
          {scoreCompared ?? 'No value'}
        </Text>}
      </View>
      {analysisShown ? analysisStatus && <Text style={styles.analysisStatusText}>
        {analysisStatus}
      </Text> : <Text style={styles.improvementText}>
        {
          // eslint-disable-next-line no-nested-ternary
          showComparison
            ? (difference && Number.isFinite(difference)) ? `${difference}% difference` : ''
            : (improvement && Number.isFinite(improvement)) ? `${improvement < 0 ? '-' : '+'}${improvement}% from last year` : ''}
      </Text>}
    </View>
    {analysisShown ? analysisDescription && <Text style={styles.description}>
      {analysisDescription}
    </Text> : <View style={styles.previousScoreSection}>
      <Text style={styles.previousScoreTitle}>Last year score</Text>
      <View style={styles.scoreSection}>
        <Text style={[styles.previousScoreValue, !previousScore && styles.noValuePrevious]}>
          {previousScore ?? 'No value'}
        </Text>
        {showComparison && <Text style={[
          styles.previousScoreValue, styles.scoreCompared,
          !previousScoreCompared && styles.noValuePrevious,
        ]}>
          {previousScoreCompared ?? 'No value'}
        </Text>}
      </View>
    </View>}
  </View>;
};

interface YearlyParametersProps {
  showComparison: boolean,
  dates: { start: Date, end: Date },
  compareDates: { start: Date, end: Date },
}

const YearlyParametersSection = ({
  dates, compareDates, showComparison,
}: YearlyParametersProps) => {
  const props: YearlyParametersProps = { dates, compareDates, showComparison };
  const [defaultShown, setDefaultShown] = useState(true);
  const [waterScore] = useState(null);
  const [acousticsScore] = useState(null);
  const [lightingCircadianScore] = useState(null);
  const [electricLightingScore] = useState(null);
  const [waterPreviousScore] = useState(null);
  const [acousticsPreviousScore] = useState(null);
  const [lightingCircadianPreviousScore] = useState(null);
  const [electricLightingPreviousScore] = useState(null);
  const [waterScoreCompared] = useState(null);
  const [acousticsScoreCompared] = useState(null);
  const [lightingCircadianScoreCompared] = useState(null);
  const [electricLightingScoreCompared] = useState(null);
  const [waterPreviousScoreCompared] = useState(null);
  const [acousticsPreviousScoreCompared] = useState(null);
  const [lightingCircadianPreviousScoreCompared] = useState(null);
  const [electricLightingPreviousScoreCompared] = useState(null);
  return <View style={styles.container}>
    <SectionHeader
      defaultShown={defaultShown}
      setDefaultShown={setDefaultShown}
      button1='YEARLY SCORE'
      button2='ANALYSIS'
      title1='Yearly parameters score'
      title2='Yearly Analysis'
      {...props}
    />
    <View style={styles.content}>
      <Item title='Water quality'
        score={waterScore}
        previousScore={waterPreviousScore}
        showComparison={showComparison}
        scoreCompared={waterScoreCompared}
        previousScoreCompared={waterPreviousScoreCompared}
        analysisDescription='Water delivered to the building and intended for human contact (e.g., drinking, cooking and dishwashing, handwashing, showering or bathing) should meet the appropriate Standards.'
        analysisShown={!defaultShown} />
      <Item title='Acoustics'
        score={acousticsScore}
        previousScore={acousticsPreviousScore}
        showComparison={showComparison}
        scoreCompared={acousticsScoreCompared}
        previousScoreCompared={acousticsPreviousScoreCompared}
        analysisDescription='This feature requires that steps be taken to address acoustical comfort, by controlling reverberation time based on room functionality. Various thresholds exists in standards based on room volume and room type.'
        analysisShown={!defaultShown} />
      <Item title='Lighting circadian design'
        score={lightingCircadianScore}
        previousScore={lightingCircadianPreviousScore}
        showComparison={showComparison}
        scoreCompared={lightingCircadianScoreCompared}
        previousScoreCompared={lightingCircadianPreviousScoreCompared}
        analysisDescription='This feature requires the building to provide users with appropriate exposure to light for maintaining circadian health and aligning the circadian rhythm with the day-night cycle. Thresholds for light levels are measured on the vertical plane at eye level and should be achieved as per agreed standards.'
        analysisShown={!defaultShown} />
      <Item title='Electric lighting quality'
        score={electricLightingScore}
        previousScore={electricLightingPreviousScore}
        showComparison={showComparison}
        scoreCompared={electricLightingScoreCompared}
        previousScoreCompared={electricLightingPreviousScoreCompared}
        analysisDescription='Good Quality Electric Lighting includes; Colour Rendering Quality and be classified as ‘reduced flicker operation’.'
        analysisShown={!defaultShown} />
    </View>
  </View>;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    borderRadius: 24,
    borderWidth: 1,
    borderColor: Colors.light.hereworksWhite300,
    paddingTop: 18,
    paddingBottom: 27,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 24,
  },
  item: {
    flex: 1,
    marginHorizontal: 8,
    backgroundColor: '#D6F1FA',
    borderRadius: 16,
    padding: 24,
    justifyContent: 'space-between',
  },
  itemAnalysisShown: {
    justifyContent: 'flex-start',
    backgroundColor: Colors.light.hereworksWhite100,
    padding: 16,
  },
  itemTitle: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_500Medium',
    height: 48,
    marginBottom: 16,
  },
  scoreValue: {
    fontSize: 48,
    lineHeight: 46,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksEmeraldGreen,
    marginEnd: 16,
  },
  scoreValueAnalysisShown: {
    fontSize: 24,
    lineHeight: 24,
  },
  improvementText: {
    fontSize: 12,
    lineHeight: 9.6,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack500,
    marginTop: 8,
  },
  analysisStatusText: {
    fontSize: 14,
    lineHeight: 14,
    fontFamily: 'Poppins_500Medium',
    color: Colors.light.hereworksEmeraldGreen,
    marginTop: 4,
  },
  previousScoreSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    marginTop: 35,
  },
  previousScoreTitle: {
    fontSize: 14,
    lineHeight: 16.8,
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_400Regular',
  },
  previousScoreValue: {
    fontSize: 24,
    lineHeight: 24,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksDarkBlue,
    marginStart: 4,
  },
  scoreCompared: {
    color: Colors.light.hereworksYellow600,
  },
  scoreSection: {
    flexDirection: 'row',
  },
  description: {
    fontSize: 14,
    lineHeight: 16.4,
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack500,
    marginTop: 12,
  },
  noValueScore: {
    fontSize: 24,
    lineHeight: 36,
  },
  noValuePrevious: {
    fontSize: 14,
    lineHeight: 16.8,
  },
});

export default YearlyParametersSection;
