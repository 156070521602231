import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SensorIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path d="M13.33 19.29a1.29 1.29 0 1 1-2.58 0 1.29 1.29 0 0 1 2.58 0Z" fill="#fff" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.373 15.81c-.957.851-1.623 2.143-1.623 3.94a.75.75 0 0 1-1.5 0c0-2.203.834-3.911 2.127-5.06C8.656 13.551 10.34 13 12 13c1.66 0 3.344.552 4.623 1.69 1.293 1.149 2.127 2.857 2.127 5.06a.75.75 0 0 1-1.5 0c0-1.797-.666-3.089-1.623-3.94-.971-.862-2.287-1.31-3.627-1.31-1.34 0-2.656.448-3.627 1.31ZM15.97 10.03a5.588 5.588 0 0 0-7.94 0 .75.75 0 0 1-1.06-1.06 7.087 7.087 0 0 1 10.06 0 .75.75 0 1 1-1.06 1.06Z"
      fill="#fff"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.47 7.905c-4.207-4.207-10.733-4.207-14.94 0a.75.75 0 0 1-1.06-1.06c4.793-4.793 12.267-4.793 17.06 0a.75.75 0 0 1-1.06 1.06Z"
      fill="#fff"
    />
  </Svg>
);

export default SensorIcon;
