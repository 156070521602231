import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
  View, Text, StyleSheet, Animated,
} from 'react-native';
import Svg, {
  Defs, LinearGradient, Stop, Path,
} from 'react-native-svg';
import Colors from '../constants/Colors';

interface ArcProps {
  animatedValue: Animated.Value,
  value: number,
  size: number,
  strokeWidth: number,
  gradientStartColor: string,
  gradientEndColor: string,
  still?: boolean,
  noValue?: boolean,
}

const Arc = ({
  size, strokeWidth, animatedValue, gradientStartColor, gradientEndColor, still, value, noValue,
}: ArcProps) => {
  const AnimatedPath = Animated.createAnimatedComponent(Path);
  const { PI, cos, sin } = Math;
  const r = (size - strokeWidth) / 2;
  const cx = size / 2;
  const cy = size / 2;
  const A = PI * 2;
  const startAngle = PI * 0.5;
  const endAngle = PI * 0.501;
  const x1 = cx - r * cos(startAngle);
  const y1 = -r * sin(startAngle) + cy;
  const x2 = cx - r * cos(endAngle);
  const y2 = -r * sin(endAngle) + cy;
  const d = `M ${x1} ${y1} A ${r} ${r} 0 1 0 ${x2} ${y2}`;
  const circumference = r * A;
  const strokeDashoffset = animatedValue.interpolate({
    inputRange: [0, 100],
    outputRange: [-circumference, 0],
  });
  const stillStrokeDashOffset = -circumference * 0.01 * (100 - (noValue ? 0 : value ?? 0));
  const routeID = useRoute().name;

  const output = (blur: boolean) => <Svg width={size} height={size} style={[
    styles.arc,
    // @ts-ignore
    blur && { filter: 'blur(16px)' },
  ]}>
    <Defs>
      <LinearGradient id={`grad_${routeID}_${size}_${blur}`} x1="0" y1="0" x2="0" y2="100%">
        <Stop offset="0" stopColor={gradientStartColor} />
        <Stop offset="1" stopColor={gradientEndColor} />
      </LinearGradient>
    </Defs>
    <AnimatedPath
      stroke={`url(#grad_${routeID}_${size}_${blur})`}
      strokeLinecap="round"
      fill="none"
      strokeDasharray={`${circumference}, ${circumference}`}
      {...{ d, strokeDashoffset: still ? stillStrokeDashOffset : strokeDashoffset, strokeWidth }}
    />
  </Svg>;

  return <>
    {output(true)}
    {output(false)}
  </>;
};

interface ArcTrackProps {
  inner?: boolean,
  showComparison?: boolean
}

const ArcTrack = (props: ArcTrackProps) => {
  const { inner, showComparison: compareEnabled } = props;
  let pathVector = compareEnabled ? 'M184 96C184 142.392 146.392 180 100 180C53.6081 180 16 142.392 16 96C16 49.6081 53.6081 12 100 12C146.392 12 184 49.6081 184 96ZM25.324 96C25.324 137.242 58.7576 170.676 100 170.676C141.242 170.676 174.676 137.242 174.676 96C174.676 54.7576 141.242 21.324 100 21.324C58.7576 21.324 25.324 54.7576 25.324 96Z' : 'M184 96C184 142.392 146.392 180 100 180C53.6081 180 16 142.392 16 96C16 49.6081 53.6081 12 100 12C146.392 12 184 49.6081 184 96ZM30.9506 96C30.9506 134.135 61.8651 165.049 100 165.049C138.135 165.049 169.049 134.135 169.049 96C169.049 57.8651 138.135 26.9506 100 26.9506C61.8651 26.9506 30.9506 57.8651 30.9506 96Z';
  if (inner) {
    pathVector = 'M160 96C160 135.765 127.765 168 88 168C48.2355 168 16 135.765 16 96C16 56.2355 48.2355 24 88 24C127.765 24 160 56.2355 160 96ZM23.776 96C23.776 131.47 52.5301 160.224 88 160.224C123.47 160.224 152.224 131.47 152.224 96C152.224 60.5301 123.47 31.776 88 31.776C52.5301 31.776 23.776 60.5301 23.776 96Z';
  }
  const width = inner ? 176 : 200;
  const height = inner ? 192 : 206;
  const offset = inner ? 1 : 14;
  const routeID = useRoute().name;
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height - offset}`} fill="none" style={styles.arc}>
      <g filter={`url(#filter0_ddii_7934_22092_${routeID})`}>
        <path d={pathVector} fill="#F8FAFB" />
      </g>
      <defs>
        <filter id={`filter0_ddii_7934_22092_${routeID}`} x="0" y={-offset} width={width} height={height + offset} filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.123019 0 0 0 0 0.193316 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7934_22092" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="-10" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_7934_22092" result="effect2_dropShadow_7934_22092" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_7934_22092" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.121569 0 0 0 0 0.192157 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_7934_22092" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect3_innerShadow_7934_22092" result="effect4_innerShadow_7934_22092" />
        </filter>
      </defs>
    </Svg>
  );
};

interface HappyScoreIndicatorProps {
  happyScoreAnimatedValue: Animated.Value,
  happyScoreComparedAnimatedValue?: Animated.Value,
  happyScoreValue?: number,
  happyScoreComparedValue?: number,
  showComparison?: boolean,
  still?: boolean,
  style?,
  gradientStartColor?: string,
  gradientEndColor?: string,
  textColor?: string,
  noValue?: boolean,
  noValueCompared?: boolean,
}

const HappyScoreIndicator = ({
  happyScoreAnimatedValue,
  happyScoreComparedAnimatedValue,
  happyScoreValue,
  happyScoreComparedValue,
  showComparison,
  still,
  style,
  gradientStartColor,
  gradientEndColor,
  textColor,
  noValue,
  noValueCompared,
}: HappyScoreIndicatorProps) => {
  const [happyScoreTextValue, setHappyScoreTextValue] = useState('0');
  const [happyScoreComparedTextValue, setHappyScoreComparedTextValue] = useState('0');
  useEffect(() => {
    happyScoreAnimatedValue?.addListener(({ value }) => {
      setHappyScoreTextValue(Math.floor(value).toString());
    });
    happyScoreComparedAnimatedValue?.addListener(({ value }) => {
      setHappyScoreComparedTextValue(Math.floor(value).toString());
    });
  }, []);

  return <Animated.View style={[styles.container, style]}>
    <ArcTrack showComparison={showComparison} />
    {showComparison && <ArcTrack inner showComparison />}
    {(happyScoreValue || happyScoreAnimatedValue) && <Arc strokeWidth={showComparison ? 6 : 12}
      size={165}
      animatedValue={happyScoreAnimatedValue}
      value={happyScoreValue}
      gradientStartColor={gradientStartColor ?? '#5D93C5'}
      gradientEndColor={gradientEndColor ?? Colors.light.hereworksDarkBlue}
      still={still || (!happyScoreAnimatedValue)}
      noValue={noValue}
    />}
    {showComparison && (happyScoreComparedValue || happyScoreComparedAnimatedValue) && <Arc
      strokeWidth={6}
      size={142}
      animatedValue={happyScoreComparedAnimatedValue}
      value={happyScoreComparedValue}
      gradientStartColor={Colors.light.hereworksYellow500}
      gradientEndColor='#FFE0A6'
      still={still || (!happyScoreComparedAnimatedValue)}
      noValue={noValueCompared}
    />}
    <View style={[
      styles.textContainer, showComparison && styles.textContainerComparisonEnabled,
    ]}>
      <Text style={[
        styles.happyScoreTextValue,
        showComparison && styles.happyScoreComparedTextSize,
        textColor && { color: textColor },
      ]}>
        {noValue ? '-' : happyScoreTextValue}
      </Text>
      {showComparison && <Text style={[
        styles.happyScoreTextValue,
        styles.happyScoreComparedTextSize,
        styles.happyScoreComparedTextColor,
      ]}>{noValueCompared ? '-' : happyScoreComparedTextValue}</Text>}
    </View>
  </Animated.View>;
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    height: 190,
    aspectRatio: 1,
    top: 1,
    start: 10,
    borderRadius: 100,
    backgroundColor: '#FFF',
    borderBottomWidth: 1,
    borderBottomColor: Colors.light.hereworksGreen50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    height: '100%',
    justifyContent: 'center',
    paddingVertical: 53,
  },
  textContainerComparisonEnabled: {
    justifyContent: 'space-between',
  },
  happyScoreTextValue: {
    fontSize: 54,
    lineHeight: 46,
    textAlign: 'center',
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksDarkBlue,
  },
  happyScoreComparedTextSize: {
    fontSize: 36,
    lineHeight: 36,
  },
  happyScoreComparedTextColor: {
    color: Colors.light.hereworksYellow600,
  },
  arc: {
    position: 'absolute',
  },
});

export default HappyScoreIndicator;
