import React from 'react';
import Svg, { Circle, G } from 'react-native-svg';
import Colors from '../constants/Colors';

type Props = {
  low: number;
  normal: number;
  high: number;
  urgent: number;
  compareLow: number;
  compareNormal: number;
  compareHigh: number;
  compareUrgent: number;
  compareToggled: boolean;
};

const CircleChart = (props: Props) => {
  const outerRadius = 120;
  const innerRadius = 95;
  const strokeWidth = 15;
  const translateVal = outerRadius + strokeWidth;

  return (
    <Svg
      style={{
        width: 265,
        height: 265,
        transform: [{ rotateZ: '-90deg' }],
      }}
    >
      <G
        rotation={(360 / 100) * (props.low + props.urgent + props.high)}
        translate={[translateVal, translateVal]}
      >
        <Circle
          r={outerRadius}
          stroke={Colors.light.hereworksBlue500}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={Math.PI * (outerRadius * 2)}
          strokeDashoffset={
            Math.PI * (outerRadius * 2) - (props.normal / 100) * (Math.PI * (outerRadius * 2))
          }
        />
      </G>
      <G
        rotation={(360 / 100) * (props.low + props.urgent)}
        translate={[translateVal, translateVal]}
      >
        <Circle
          r={outerRadius}
          stroke={Colors.light.hereworksYellow500}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={Math.PI * (outerRadius * 2)}
          strokeDashoffset={
            Math.PI * (outerRadius * 2) - (props.high / 100) * (Math.PI * (outerRadius * 2))
          }
        />
      </G>
      <G rotation={(360 / 100) * props.low} translate={[translateVal, translateVal]}>
        <Circle
          r={outerRadius}
          stroke={Colors.light.hereworksRed500}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={Math.PI * (outerRadius * 2)}
          strokeDashoffset={
            Math.PI * (outerRadius * 2) - (props.urgent / 100) * (Math.PI * (outerRadius * 2))
          }
        />
      </G>
      <G translate={[translateVal, translateVal]}>
        <Circle
          r={outerRadius}
          stroke={Colors.light.hereworksBlue200}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={Math.PI * (outerRadius * 2)}
          strokeDashoffset={
            Math.PI * (outerRadius * 2) - (props.low / 100) * (Math.PI * (outerRadius * 2))
          }
        />
      </G>
      {props.compareToggled && (
        <>
          <G
            rotation={(360 / 100) * (props.compareLow + props.compareUrgent + props.compareHigh)}
            translate={[translateVal, translateVal]}
          >
            <Circle
              r={innerRadius}
              stroke={Colors.light.hereworksBlue500}
              fill="transparent"
              strokeWidth={strokeWidth}
              strokeDasharray={Math.PI * (outerRadius * 2)}
              strokeDashoffset={
                Math.PI * (outerRadius * 2)
                - (props.compareNormal / 100) * (Math.PI * (outerRadius * 2))
              }
            />
          </G>
          <G
            rotation={(360 / 100) * (props.compareLow + props.compareUrgent)}
            translate={[translateVal, translateVal]}
          >
            <Circle
              r={innerRadius}
              stroke={Colors.light.hereworksYellow500}
              fill="transparent"
              strokeWidth={strokeWidth}
              strokeDasharray={Math.PI * (innerRadius * 2)}
              strokeDashoffset={
                Math.PI * (innerRadius * 2)
                - (props.compareHigh / 100) * (Math.PI * (innerRadius * 2))
              }
            />
          </G>
          <G rotation={(360 / 100) * props.compareLow} translate={[translateVal, translateVal]}>
            <Circle
              r={innerRadius}
              stroke={Colors.light.hereworksRed500}
              fill="transparent"
              strokeWidth={strokeWidth}
              strokeDasharray={Math.PI * (innerRadius * 2)}
              strokeDashoffset={
                Math.PI * (innerRadius * 2)
                - (props.compareUrgent / 100) * (Math.PI * (innerRadius * 2))
              }
            />
          </G>
          <G translate={[translateVal, translateVal]}>
            <Circle
              r={innerRadius}
              stroke={Colors.light.hereworksBlue200}
              fill="transparent"
              strokeWidth={strokeWidth}
              strokeDasharray={Math.PI * (innerRadius * 2)}
              strokeDashoffset={
                Math.PI * (innerRadius * 2)
                - (props.compareLow / 100) * (Math.PI * (innerRadius * 2))
              }
            />
          </G>
        </>
      )}
    </Svg>
  );
};

export default CircleChart;
