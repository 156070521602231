import AsyncStorage from '@react-native-async-storage/async-storage';

type FilterFieldsObject = {
  Name?: string,
  Manufacturer?: string,
  Description?: string,
  Serial?: string,
  Model?: string,
}

const assetSearch = async (
  name,
  manufacturer,
  description,
  serial,
  model,
) => {
  try {
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('accesskey', 'b7d3137e8e7cfb37e4607962fb7b153a' ?? '');
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('jwt', jwtToken ?? '');

    const filters: FilterFieldsObject = {};
    if (name.length > 0) {
      filters.Name = name;
    }
    if (manufacturer.length > 0) {
      filters.Manufacturer = manufacturer;
    }
    if (serial.length > 0) {
      filters.Serial = serial;
    }
    if (model.length > 0) {
      filters.Model = model;
    }
    if (description.length > 0) {
      filters.Description = description;
    }

    const requestBody = JSON.stringify({
      FilterFields: filters,
    });
    const response = await fetch(
      `https://${apiDomain}/button/assetsearch`,
      {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
      },
    );
    return response;
  } catch (error) {
    return null;
  }
};

export default assetSearch;
