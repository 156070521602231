import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const WarningIcon = (props: SvgProps) => (
  <Svg width={64} height={64} fill="none" {...props}>
    <Path
      d="M32 24v10.667m-4.667-23.494L7.013 48a5.333 5.333 0 0 0 4.667 8h40.64a5.332 5.332 0 0 0 4.667-8l-20.32-36.827a5.333 5.333 0 0 0-9.334 0Z"
      stroke="#ED364C"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M32.133 45.334h-.266"
      stroke="#ED364C"
      strokeWidth={3.9}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default WarningIcon;
