import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PlusIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 2a.75.75 0 0 0-1.5 0v5.25H2a.75.75 0 0 0 0 1.5h5.25V14a.75.75 0 0 0 1.5 0V8.75H14a.75.75 0 0 0 0-1.5H8.75V2Z"
      fill={props.color ?? '#fff'}
    />
  </Svg>
);

export default PlusIcon;
