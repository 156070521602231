import * as React from 'react';
import Svg, { SvgProps, Rect } from 'react-native-svg';

const TemplateIcon30 = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Rect width={32} height={32} rx={16} fill={props.fill ?? '#003A70'} />
    <Rect x={8.5} y={8.5} width={6} height={6} rx={1.5} fill={props.color ?? '#FFF'} stroke={props.color ?? '#FFF'} />
    <Rect x={10} y={10} width={3} height={3} rx={0.7} fill={props.color ?? '#FFF'} />
    <Rect x={17.5} y={8.5} width={6} height={6} rx={1.5} fill={props.color ?? '#FFF'} stroke={props.color ?? '#FFF'} />
    <Rect x={19} y={10} width={3} height={3} rx={0.7} fill={props.color ?? '#FFF'} />
    <Rect x={17.5} y={17.5} width={6} height={6} rx={1.5} fill={props.color ?? '#FFF'} stroke={props.color ?? '#FFF'} />
    <Rect x={19} y={19} width={3} height={3} rx={0.7} fill={props.color ?? '#FFF'} />
    <Rect x={8.5} y={17.5} width={6} height={6} rx={1.5} fill={props.color ?? '#FFF'} stroke={props.color ?? '#FFF'} />
    <Rect x={10} y={19} width={3} height={3} rx={0.7} fill={props.color ?? '#FFF'} />
  </Svg>
);

export default TemplateIcon30;
