import * as React from 'react';
import Svg, {
  SvgProps, G, Path, Defs, ClipPath,
} from 'react-native-svg';

const DeleteIcon16 = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.773 4.288a.75.75 0 1 0-1.06-1.061L8 6.939 4.288 3.227a.75.75 0 0 0-1.061 1.06L6.939 8l-3.712 3.712a.75.75 0 0 0 1.06 1.061L8 9.061l3.712 3.712a.75.75 0 0 0 1.061-1.06L9.061 8l3.712-3.712Z"
        fill={props.color ?? '#93989B'}
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default DeleteIcon16;
