import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const BinIcon24 = (props: SvgProps) => (
  <Svg width={25} height={24} fill="none" {...props}>
    <Path
      d="m18.925 5.281-.952 15.455a1.64 1.64 0 0 1-1.608 1.608H8.031a1.64 1.64 0 0 1-1.608-1.608L5.472 5.28m-1.805 0H20.73 3.667Zm5.25 0V3.936a1.28 1.28 0 0 1 1.28-1.28H14.2a1.28 1.28 0 0 1 1.28 1.28V5.28H8.916Z"
      stroke={props.color ?? '#89A7CB'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m15.48 7.906-.657 11.813M12.198 7.906V19.72 7.906Zm-3.281 0 .656 11.813-.656-11.813Z"
      stroke={props.color ?? '#89A7CB'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default BinIcon24;
