import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PlaceholderImageIcon = (props: SvgProps) => (
  <Svg width={64} height={64} fill="none" {...props}>
    <Path
      d="M26.88 22.105h.268"
      stroke="#B0CBE6"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M56 13.335v37.333a2.667 2.667 0 0 1-2.667 2.667H10.667A2.667 2.667 0 0 1 8 50.668V13.335a2.667 2.667 0 0 1 2.667-2.667h42.666A2.667 2.667 0 0 1 56 13.335ZM28.027 39.468l13.866 13.867h11.44A2.667 2.667 0 0 0 56 50.668v-8.72l-15.227-15.28-12.746 12.8ZM8 44.561v6.107a2.667 2.667 0 0 0 2.667 2.667h31.226L20.56 32 8 44.561Z"
      stroke="#B0CBE6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default PlaceholderImageIcon;
