import AsyncStorage from '@react-native-async-storage/async-storage';

const getFloorPlan = async () => {
  try {
    const jwtToken = await AsyncStorage.getItem('token');
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('auth-client-id', clientName ?? 'hereworks');
    requestHeaders.set('auth-jwt', jwtToken ?? '');
    requestHeaders.set('Content-Type', 'application/json');

    const response = await fetch(
      `https://${apiDomain}/happy/sw2helper-get-current-floorplan-status`,
      {
        method: 'POST',
        headers: requestHeaders,
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export default getFloorPlan;
