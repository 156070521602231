import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SolveIcon24 = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm0 0v9m0 0 6.36 6.36M12 12l-8.68 2.37"
      stroke={props.color ?? '#3379BD'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default SolveIcon24;
