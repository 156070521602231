import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const DeleteIcon24 = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.856 17.583a.75.75 0 0 0 1.06 1.06l5.834-5.833 5.834 5.834a.75.75 0 0 0 1.06-1.06l-5.833-5.835 5.833-5.833a.75.75 0 1 0-1.06-1.06l-5.834 5.833-5.834-5.834a.75.75 0 1 0-1.06 1.06l5.833 5.834-5.833 5.834Z"
        fill={props.color ?? '#ED364C'}
      />
    </G>
  </Svg>
);

export default DeleteIcon24;
