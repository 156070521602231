import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const DeskIcon60 = (props: SvgProps) => (
  <Svg width={61} height={60} fill="none" {...props}>
    <Path
      d="M8.072 45V17.5h30m15 27.5V31.25m-15-13.75h15v13.75m-15-13.75v13.75m0 13.75V31.25m0 0h15"
      stroke={props.color ?? '#fff'}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default DeskIcon60;
