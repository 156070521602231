import React, { useState } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import Moment from 'moment';
import Colors from '../constants/Colors';
import FlagIcon from './FlagIcon';
import DeleteIcon16 from './16pxIcons/DeleteIcon16';
import OrionArrowIcon from './24pxIcons/OrionArrowIcon';
import AlertModal from './AlertModal';

export default function Notification({
  isNew,
  title,
  priority,
  id,
  createdon,
  hideModal,
  clientId,
}: {
  isNew: boolean;
  title: string;
  priority: string;
  id: string;
  createdon: string;
  hideModal?: () => void;
  clientId;
}) {
  const navigation = useNavigation();

  function getPriorityColor() {
    if (priority === 'low') {
      return Colors.light.hereworksCheckGreen;
    }
    if (priority === 'moderate') {
      return Colors.light.hereworksLightBlue;
    }

    if (priority === 'high') {
      return Colors.light.hereworksMediumYellow;
    }

    if (priority === 'critical') {
      return Colors.light.hereworksUrgentRed;
    }

    return 'transparent';
  }

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState('');

  const showAlert = (newAlertTitle: string, error: boolean = true) => {
    setAlertTitle(newAlertTitle);
    setAlertError(error);
    setAlertVisible(true);
  };

  return (
    <View
      style={[
        styles.notificationCard,
        isNew ? { backgroundColor: Colors.light.hereworksWhite100 } : { backgroundColor: '#fff' },
      ]}
    >
      <View
        style={{
          width: 40,
          height: 40,
          borderRadius: 20,
          borderColor: Colors.light.hereworksBlack100,
          borderWidth: 1,
          marginRight: 12,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FlagIcon />
      </View>
      {/* <View style={styles.bellIconContainer}>
          <BellIcon color={Colors.light.hereworksDarkBlue} />
          <View style={[styles.priorityIndicator, { backgroundColor: getPriorityColor() }]} />
        </View> */}
      <View style={{ flex: 1 }}>
        <Text style={styles.typeText}>TICKET RAISED:</Text>
        <Text numberOfLines={2} style={styles.descriptionText}>
          {title}
        </Text>
        <View
          style={{
            alignSelf: 'flex-start',
            borderWidth: 1,
            borderRadius: 4,
            paddingHorizontal: 4,
            marginTop: 4,
            marginBottom: 12,
            borderColor: getPriorityColor(),
          }}
        >
          <Text style={[styles.typeText, { color: getPriorityColor() }]}>
            {`${priority} priority`}
          </Text>
        </View>
        <Text style={styles.timeText}>{Moment(createdon).fromNow()}</Text>
        <TouchableOpacity
          style={{
            paddingVertical: 9,
            paddingHorizontal: 24,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: Colors.light.hereworksBlue500,
            alignSelf: 'flex-start',
            alignItems: 'center',
            marginTop: 16,
            flexDirection: 'row',
            backgroundColor: '#fff',
          }}
          onPress={() => {
            // @ts-ignore
            navigation.navigate('Tickets', { reportId: id, clientId });
            if (hideModal) {
              hideModal();
            }
          }}
        >
          <Text style={[styles.timeText, { color: Colors.light.hereworksBlue500 }]}>
            Open ticket
          </Text>
          <OrionArrowIcon style={{ transform: [{ rotateZ: '180deg' }] }} />
        </TouchableOpacity>
      </View>
      <View style={styles.deleteIcon}>
        <TouchableOpacity
          hitSlop={{
            top: 20,
            bottom: 20,
            left: 50,
            right: 50,
          }}
          onPress={() => {
            showAlert('Feature coming soon!', false);
          }}
        >
          <DeleteIcon16 />
        </TouchableOpacity>
      </View>
      <AlertModal modalVisible={alertVisible}
        error={alertError}
        title={alertTitle}
        setModalVisible={setAlertVisible} />
    </View>
  );
}

const styles = StyleSheet.create({
  notificationCard: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 12,
    overflow: 'hidden',
    borderBottomWidth: 1,
    borderColor: Colors.light.hereworksBlue100,
  },
  bellIconContainer: {
    marginLeft: 20,
    marginRight: 15,
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  priorityIndicator: {
    position: 'absolute',
    top: 2,
    right: 2,
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  typeText: {
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
  },
  descriptionText: {
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 14,
    lineHeight: 21,
  },
  timeText: {
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksPrimaryGrey700,
    fontSize: 14,
  },
  deleteIcon: {
    position: 'absolute',
    top: 20,
    right: 48,
  },
  settingsModal: {
    height: 104,
    zIndex: 1,
    right: 0,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 8,
    overflow: 'hidden',
  },
  modalText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 10,
    color: Colors.light.hereworksPrimaryGrey800,
  },
  modalItem: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 1,
  },
});
