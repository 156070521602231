import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';

const getIssues = async (
  limit = 500,
  low = false,
  med = false,
  high = false,
  critical = false,
  done = false,
  inprogress = false,
  paused = false,
  withoutstatus = false,
  assignee = '',
  locationid = '',
  assetid = '',
  usergroupids = [],
  startTime = null,
  endTime = null,
) => {
  try {
    // const jwtToken = await AsyncStorage.getItem('token');
    const clientName = await AsyncStorage.getItem('clientName');
    const apiDomain = await AsyncStorage.getItem('apiDomain') ?? 'api.hereworks.tech';
    const jwtToken = await AsyncStorage.getItem('token');

    // eslint-disable-next-line no-undef
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('clientid', clientName ?? 'hereworks');
    requestHeaders.set('jwt', jwtToken ?? '');
    requestHeaders.set('Content-Type', 'application/json');

    const noPriorityFilter = !low && !med && !high && !critical;

    const noStatusFilter = !done && !inprogress && !paused && !withoutstatus;

    let query = `https://${apiDomain}/button/issues?limit=${limit}`;

    if (!noPriorityFilter) {
      if (low) {
        query += '&priority=low';
      }
      if (med) {
        query += '&priority=moderate';
      }
      if (high) {
        query += '&priority=high';
      }
      if (critical) {
        query += '&priority=critical';
      }
    }

    if (!noStatusFilter) {
      if (done) {
        query += '&status=completed';
      }
      if (inprogress) {
        query += '&status=inprogress';
      }
      if (paused) {
        query += '&status=paused';
      }
      if (withoutstatus) {
        query += '&status=reported';
      }
    }

    if (assignee !== '') {
      query += `&assignee=${assignee}`;
    }

    if (locationid !== '') {
      query += `&locationid=${locationid}`;
    }

    if (assetid !== '') {
      query += `&assetid=${assetid}`;
    }

    if (usergroupids?.length > 0) {
      usergroupids.forEach((id) => {
        if (id !== '') {
          query += `&usergroupid=${id}`;
        }
      });
    }

    if (startTime !== null) {
      query += `&startdatetime=${Moment(startTime).format('YYYY-MM-DDTHH:mm:ss')}`;
    }

    if (endTime !== null) {
      query += `&enddatetime=${Moment(endTime).format('YYYY-MM-DDTHH:mm:ss')}`;
    }

    const response = await fetch(
      query,
      {
        method: 'GET',
        headers: requestHeaders,
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
};

export default getIssues;
