import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LeftArrow = (props: SvgProps) => (
    <Svg width='8' height='14' viewBox='0 0 8 14' fill='none' {...props}>
        <Path d='M7 1L1 7L7 13' stroke={props.color ?? '#243848'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </Svg>
);

export default LeftArrow;
