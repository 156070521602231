import React, { Dispatch, SetStateAction, useRef } from 'react';
import {
  StyleSheet, View, TouchableOpacity, Text, Animated,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import Colors from '../constants/Colors';
import LinkArrow from './16pxIcons/LinkArrow';
import CompareDatesView from './CompareDatesView';

interface SectionHeaderProps {
  defaultShown: boolean,
  setDefaultShown: Dispatch<SetStateAction<boolean>>,
  button1: string,
  button2: string,
  title1: string,
  title2?: string,
  dates?: { start: Date, end: Date },
  compareDates?: { start: Date, end: Date },
  showComparison?: boolean,
  style?,
  showLinkArrow?: boolean,
  linkAction?: () => void,
}

const SectionHeader = ({
  defaultShown, setDefaultShown, button1, button2, title1, title2, dates, compareDates,
  showComparison, style, showLinkArrow, linkAction,
}: SectionHeaderProps) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return <Animated.View style={[styles.container, style]}>
    <View style={styles.titleContainer}>
      <TouchableOpacity activeOpacity={1}
        onPress={linkAction}
        style={styles.textContainer}>
        <Text style={styles.title}>{defaultShown ? title1 : (title2 ?? title1)}</Text>
        {showLinkArrow && <View accessibilityRole='link' ref={ref}
          style={[styles.linkArrowContainer, isHovered && styles.hoveredLink]}>
          <LinkArrow />
        </View>}
      </TouchableOpacity>
      {showComparison && dates && compareDates && <CompareDatesView
        range={dates} compareRange={compareDates} />}
    </View>
    <View style={styles.buttonsSection}>
      <TouchableOpacity
        onPress={() => setDefaultShown(true)}
        activeOpacity={defaultShown ? 1 : undefined}
        style={[styles.button, defaultShown && styles.buttonSelected]}
      >
        <Text style={[styles.buttonTitle, defaultShown && styles.buttonTitleSelected]}>
          {button1}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => setDefaultShown(false)}
        activeOpacity={defaultShown ? undefined : 1}
        style={[styles.button, !defaultShown && styles.buttonSelected]}
      >
        <Text style={[styles.buttonTitle, !defaultShown && styles.buttonTitleSelected]}>
          {button2}
        </Text>
      </TouchableOpacity>
    </View>
  </Animated.View>;
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    marginBottom: 28,
    alignItems: 'center',
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 22,
    lineHeight: 33,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
    marginBottom: 4,
    alignSelf: 'center',
  },
  buttonsSection: {
    flexDirection: 'row',
  },
  button: {
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    borderRadius: 4,
    marginStart: 8,
  },
  buttonTitle: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'Roboto_500Medium',
    color: Colors.light.hereworksBlue500,
  },
  buttonSelected: {
    backgroundColor: Colors.light.hereworksBlue500,
  },
  buttonTitleSelected: {
    color: '#FFF',
  },
  linkArrowContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    width: 32,
    height: 32,
    backgroundColor: Colors.light.hereworksBlue50,
    marginStart: 16,
  },
  hoveredLink: {
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
  },
});

export default SectionHeader;
