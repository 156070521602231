/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';

export default function SmallTimeSelector({
  data,
  onSelect,
  unselectedColor = '#F8FAFB',
}) {
  const [userOption, setUserOption] = useState('1W');
  const selectHandler = (value, timeString) => {
    onSelect(value, timeString);
    setUserOption(value);
  };
  return (
    <View style={{ flexDirection: 'row' }}>
      {data.map((item, index) => (
        <Pressable
          style={(state) => [
            item.value === userOption
              ? styles.selected
              : unselectedColor === '#F8FAFB'
                ? styles.unselected
                : styles.unselectedWhite,
            { opacity: state.pressed ? 0.3 : 1 },
          ]}
          onPress={() => selectHandler(item.value, item.timeString)}
          key={index}
        >
          <Text
            style={
              item.value === userOption
                ? styles.selectedText
                : styles.unselectedText
            }
          >
            {item.value}
          </Text>
        </Pressable>
      ))}
    </View>
  );
}
const styles = StyleSheet.create({
  option: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center',
  },
  unselected: {
    backgroundColor: '#F8FAFB',
    // padding: 8,
    paddingVertical: 14,
    borderRadius: 8,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  unselectedWhite: {
    backgroundColor: '#fff',
    // padding: 8,
    paddingVertical: 14,
    borderRadius: 8,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    backgroundColor: '#003A70',
    // padding: 8,
    paddingVertical: 14,
    borderRadius: 8,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  unselectedEnd: {
    backgroundColor: '#F8FAFB',
    padding: 8,
    paddingVertical: 10,
    borderRadius: 8,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedEnd: {
    backgroundColor: '#003A70',
    padding: 8,
    borderRadius: 8,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedText: {
    color: 'white',
    alignSelf: 'center',
    fontSize: 15,
  },
  unselectedText: {
    color: '#696D70',
    alignSelf: 'center',
    fontSize: 15,
  },
});
