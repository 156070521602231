import React, { ReactElement, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  // Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Modal from 'react-native-modal';
import * as Analytics from 'expo-firebase-analytics';
// import * as ImagePicker from 'expo-image-picker';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { FontAwesome } from '@expo/vector-icons';
import Style from '../../constants/Style';
import CloseModalIcon from '../CloseModalIcon';
import CustomTextField from '../CustomTextField';
import Colors from '../../constants/Colors';
import DeleteIcon24 from '../24pxIcons/DeleteIcon24';
// import PlaceholderImageIcon from '../PlaceholderImageIcon';
import ScanIcon from '../ScanIcon';
import addAsset from '../../requests/AddAsset';
import uploadAssetImage from '../../requests/UploadAssetImage';
import getLocations from '../../requests/GetLocations';
import AlertModal from '../AlertModal';
import getButtonPrototypes from '../../requests/GetButtonPrototypes';

const geoRegexString: string = '^[0-9]*$|^$';
const alphanumericRegex: string = '^[a-zA-Z0-9-_]+$';

export default function AddAssetModal(props) {
  const originalCustomFields = [...props.template?.CustomFields ?? []];
  const geoFields = originalCustomFields?.filter((field) => field?.Name?.toLowerCase()?.includes('geo'));
  geoFields?.forEach((field) => {
    if (!field.Format) {
      originalCustomFields
        .filter((ogField) => ogField.Name === field.Name)
        ?.forEach((geoField) => { geoField.Format = geoRegexString; });
    }
  });
  if (!geoFields?.length) {
    originalCustomFields.push({
      Name: 'GeoX', CustomValue: '', IsReadOnlyName: true, Format: geoRegexString,
    });
    originalCustomFields.push({
      Name: 'GeoY', CustomValue: '', IsReadOnlyName: true, Format: geoRegexString,
    });
  }
  const templateCustomFields = () => [...originalCustomFields]
    ?.sort((previous, next) => {
      if (previous.Name < next.Name) {
        return -1;
      }
      if (previous.Name > next.Name) {
        return 1;
      }
      return 0;
    })
    .map((field, index) => ({
      key: index,
      CustomName: field.Name ?? field.CustomName,
      CustomValue: '',
      IsReadOnlyName: field.IsReadOnlyName ?? false,
      Format: field.Format,
    }));

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState(true);
  const [alertTitle, setAlertTitle] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const [essentialTabSelected, setEssentialTabSelected] = useState(true);
  const [image, setImage] = useState(null);
  const [addMode, setAddMode] = useState(null);
  const [scanned, setScanned] = useState(true);
  const [scanValue, setScanValue] = useState(null);
  const [assetName, setAssetName] = useState('');
  const [nameInvalid, setNameInvalid] = useState(false);
  const [idInvalid, setIdInvalid] = useState(false);
  const [idInvalidText, setIdInvalidText] = useState('Please enter asset ID');
  const [locationInvalid, setLocationInvalid] = useState(false);
  const [templateInvalid, setTemplateInvalid] = useState(false);
  const [model, setModel] = useState('');
  const [serial, setSerial] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [description, setDescription] = useState('');
  const [building, setBuilding] = useState('');
  const [floor, setFloor] = useState('');
  const [area, setArea] = useState('');
  const [room, setRoom] = useState('');
  const [assetId, setAssetId] = useState(null);
  const [locationDropdownVisible, setLocationDropdownVisible] = useState(false);
  const [templateDropdownVisible, setTemplateDropdownVisible] = useState(false);
  const [locationSelection, setLocationSelection] = useState('');
  const [templateSelection, setTemplateSelection] = useState('');
  const [locationId, setLocationId] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [loading, setLoading] = useState(false);
  const [customFields, setCustomFields] = useState(templateCustomFields());
  const [geoXInvalid, setGeoXInvalid] = useState(false);
  const [geoYInvalid, setGeoYInvalid] = useState(false);

  const [templates, setTemplates] = useState([]);
  const [locations, setLocations] = useState([
    {
      Name: 'Location 1',
      LocationId: '1',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 2',
      LocationId: '2',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 3',
      LocationId: '3',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 4',
      LocationId: '4',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 5',
      LocationId: '5',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
    {
      Name: 'Location 6',
      LocationId: '6',
      Building: '',
      Area: '',
      Floor: '',
      Room: '',
    },
  ]);

  const showAlert = (title: string, message?: string | ReactElement, error: boolean = true) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertError(error);
    setAlertVisible(true);
  };

  React.useEffect(() => {
    getLocations().then((response) => {
      if (response?.locations?.length > 0) {
        setLocations(response.locations);
        const location = response.locations[0];
        setLocationSelection(location.Name);
        setLocationId(location.LocationId);
        setBuilding(location.Building ?? '');
        setArea(location.Area ?? '');
        setFloor(location.Floor ?? '');
        setRoom(location.Room ?? '');
      }
    });
  }, []);

  React.useEffect(() => {
    getButtonPrototypes().then((response) => {
      if (response) {
        setTemplates(response.buttonPrototypes?.sort((a, b) => a.Name.localeCompare(b.Name)) ?? []);
      }
    });
  }, []);

  React.useEffect(() => {
    setCustomFields(templateCustomFields());
    setTemplateSelection(props.template?.name);
    setTemplateId(props.template?.id);
  }, [props.template]);

  React.useEffect(() => {
    customFields?.forEach((field) => {
      if (field.CustomName.toLowerCase() === 'name') {
        field.CustomValue = assetName;
      } else if (field.CustomName.toLowerCase().includes('model')) {
        field.CustomValue = model;
      } else if (field.CustomName.toLowerCase().includes('serial')) {
        field.CustomValue = serial;
      } else if (field.CustomName.toLowerCase().includes('manu')) {
        field.CustomValue = manufacturer;
      } else if (field.CustomName.toLowerCase().includes('desc')) {
        field.CustomValue = description;
      }
    });
  }, [assetName, model, serial, manufacturer, description]);

  const handleBarCodeScanned = ({ data }) => {
    const scanInput = data.split('.tech/')[1]?.split('?')[0];
    setScanned(true);
    if (scanInput) {
      // Alert.alert(data);
      setAddMode('Scan');
      setScanValue(scanInput);
      setAssetId(scanInput);
    } else {
      showAlert('Invalid asset tag');
    }
  };

  const asset = {
    Name: '',
    Template: '',
    Building: '',
    Area: '',
    Floor: '',
    Room: '',
    ButtonInstanceId: '',
    Images: [],
    CustomFields: [],
  };

  // const handleImage = (result) => {
  //   if (!result.cancelled) {
  //     const { uri } = result as ImagePicker.ImageInfo;
  //     setImage(uri);
  //   }
  // };

  // const pickImage = async () => {
  //   // No permissions request is necessary for launching the image library
  //   const result = await ImagePicker.launchImageLibraryAsync({
  //     mediaTypes: ImagePicker.MediaTypeOptions.Images,
  //     allowsEditing: true,
  //     quality: 1,
  //   });

  //   handleImage(result);
  // };

  // const takePhoto = async () => {
  //   const result = await ImagePicker.launchCameraAsync({
  //     allowsEditing: true,
  //     quality: 1,
  //   });

  //   handleImage(result);
  // };

  const analytics = (title) => {
    Analytics.logEvent('button_press', {
      screen: 'EditAssetModal',
      name: title,
    });
  };

  const restoreDefaults = () => {
    setAssetId('');
    setAssetName('');
    setModel('');
    setSerial('');
    setManufacturer('');
    setDescription('');
    setImage(null);
    setAddMode(null);
    setScanValue(null);
    setScanned(true);
    setLocationDropdownVisible(false);
    setLoading(false);
    setLocationSelection(locations[0]?.Name ?? '');
    setLocationId(locations[0]?.LocationId ?? '');
    setTemplateId(props.template?.id ?? templateId);
    setBuilding(locations[0]?.Building ?? '');
    setFloor(locations[0]?.Floor ?? '');
    setArea(locations[0]?.Area ?? '');
    setRoom(locations[0]?.Room ?? '');
    setEssentialTabSelected(true);
    setCustomFields(templateCustomFields);
    setNameInvalid(false);
    setIdInvalid(false);
    setLocationInvalid(false);
    setTemplateInvalid(false);
    setGeoXInvalid(false);
    setGeoYInvalid(false);
  };

  const topSafeAreaInset = useSafeAreaInsets().top;

  const save = () => {
    if (loading) return;
    setLoading(true);
    analytics('Save');
    uploadAssetImage(asset.ButtonInstanceId, image);
    addAsset(
      scanValue ?? assetId,
      model,
      serial,
      manufacturer,
      description,
      templateId,
      customFields,
      assetName,
      locationId,
    ).then(async (response) => {
      const json = await response?.json();
      const success = response.status === 200 && !json.isError;
      setLoading(false);
      if (success) {
        showAlert('Success', <Text>
          Asset <Text style={styles.alertAssetName}>{assetName}</Text> successfully added
        </Text>, false);
      } else showAlert('Failure', json?.errorText);
    }).catch(async (response) => {
      const json = await response?.json();
      if (json?.errorText) showAlert('Failure', json?.errorText);
      setLoading(false);
    });
  };

  useEffect(() => {
    if ((!alertError || scanValue !== null) && !alertVisible) {
      setAddMode(null);
      props.setModalVisible(false);
      restoreDefaults();
      props.addAsset({
        Name: assetName,
        ButtonInstanceId: assetId,
        ButtonPrototypeId: templateId,
        ButtonPrototypeName: templateSelection,
        Images: [],
        Serial: serial,
        Model: model,
        Description: description,
        Manufacturer: manufacturer,
        LocationId: locationId,
        LocationBuilding: building,
        LocationFloor: floor,
        LocationArea: area,
        LocationRoom: room,
        CustomFields: customFields,
      });
    }
  }, [alertVisible]);

  const renderSaveContinueButton = () => (
    <TouchableOpacity
      style={[
        {
          backgroundColor: Colors.light.hereworksLightBlue,
          borderRadius: 4,
          borderWidth: 1,
          borderColor: Colors.light.hereworksBlue500,
        },
        props.mobile && { flex: 1 },
      ]}
      onPress={() => {
        let valid: boolean = true;
        const newGeoFields: any[] = customFields?.filter((field) => field.CustomName.toLowerCase().includes('geo')) ?? [];
        const geoXField: any = newGeoFields.filter((field) => field.CustomName.toLowerCase().includes('x'))[0];
        const geoYField: any = newGeoFields.filter((field) => field.CustomName.toLowerCase().includes('y'))[0];
        if (!(assetName?.trim().length > 0)) {
          if (valid) showAlert('Error', 'Asset name cannot be empty');
          setNameInvalid(true);
          valid = false;
        }
        if (!(assetId?.trim().length > 0)) {
          if (valid) showAlert('Error', 'Asset ID cannot be empty');
          setIdInvalidText('Please enter asset ID');
          setIdInvalid(true);
          valid = false;
        }
        if (assetId?.includes(' ')) {
          if (valid) showAlert('Error', 'Asset ID cannot have spaces');
          setIdInvalidText('Asset ID cannot have spaces');
          setIdInvalid(true);
          valid = false;
        }
        if (!(new RegExp(alphanumericRegex).test(assetId))) {
          if (valid) showAlert('Error', 'Asset ID must be alphanumeric');
          setIdInvalidText('Asset ID must be alphanumeric');
          setIdInvalid(true);
          valid = false;
        }
        if (!locationSelection) {
          if (valid) showAlert('Error', 'Location cannot be empty');
          setLocationInvalid(true);
          valid = false;
        }
        if (!templateSelection) {
          if (valid) showAlert('Error', 'Template cannot be empty');
          setTemplateInvalid(true);
          valid = false;
        }
        if (geoXField && !(new RegExp(geoXField?.Format)
          .test(geoXField?.CustomValue?.toString()))) {
          if (valid) showAlert('Error', 'Invalid value for GeoX');
          setGeoXInvalid(true);
          valid = false;
        }
        if (geoYField && !(new RegExp(geoYField?.Format)
          .test(geoYField?.CustomValue?.toString()))) {
          if (valid) showAlert('Error', 'Invalid value for GeoY');
          setGeoYInvalid(true);
          valid = false;
        }
        if (valid) {
          save();
        }
      }}
    >
      <Text
        style={{
          color: '#fff',
          fontFamily: 'Roboto_400Regular',
          fontSize: 16,
          marginVertical: 8,
          marginHorizontal: 24,
          textAlign: 'center',
        }}
      >
        {'Add Asset'}
      </Text>
    </TouchableOpacity>
  );

  const renderBackCancelButton = () => (
    <TouchableOpacity
      style={[
        {
          backgroundColor: '#fff',
          borderRadius: 4,
          borderWidth: 1,
          borderColor: Colors.light.hereworksBlue500,
          marginEnd: 12,
        },
        props.mobile && { flex: 1 },
      ]}
      onPress={() => {
        analytics('Cancel');
        props.setModalVisible(false);
        setAddMode(null);
        restoreDefaults();
      }}
    >
      <Text
        style={{
          color: Colors.light.hereworksBlue500,
          fontFamily: 'Roboto_400Regular',
          fontSize: 16,
          marginVertical: 8,
          marginHorizontal: 24,
          textAlign: 'center',
        }}
      >
        Cancel
      </Text>
    </TouchableOpacity>
  );

  const renderCustomField = (field, deleteAction, index) => {
    let title = field.CustomName;
    if (title.includes('Date')) {
      title = `${title.split('Date')[0]} Date`;
    } else if (title.includes('date')) {
      title = `${title.split('date')[0]} Date]`;
    }
    if (title.includes('Name')) {
      title = `${title.split('Name')[0]} Name`;
    } else if (title.includes('name')) {
      title = `${title.split('name')[0]} Name`;
    }
    if (title.includes('Number')) {
      title = `${title.split('Number')[0]} Number`;
    } else if (title.includes('number')) {
      title = `${title.split('number')[0]} Number`;
    }
    if (title.includes('Address')) {
      title = `${title.split('Address')[0].trim()} Address ${title.split('Address')[1].trim()}`;
    } else if (title.includes('address')) {
      title = `${title.split('address')[0].trim()} Address ${title.split('address')[1].trim()}`;
    }
    const isGeoField: boolean = field?.CustomName.toLowerCase().includes('geo');
    const isGeoX: boolean = isGeoField && field?.CustomName.toLowerCase().includes('x');
    const isGeoY: boolean = isGeoField && field?.CustomName.toLowerCase().includes('y');
    return (
      <View
        style={{
          marginVertical: 10,
          borderWidth: 1,
          borderRadius: 8,
          borderColor: Colors.light.hereworksBlue50,
          padding: 8,
          alignSelf: 'stretch',
        }}
      >
        <View style={{ flexDirection: props.mobile ? 'column' : 'row' }}>
          <View style={{ flex: 1 }}>
            <CustomTextField
              mobile={props.mobile}
              title={'Name'}
              value={title}
              editable={!field.IsReadOnlyName}
              updateValue={(text: string) => {
                const newFields = Object.assign([], customFields);
                newFields[index].CustomName = text;
                setCustomFields(newFields);
              }}
            />
          </View>
          <View style={{ flex: 1, marginLeft: props.mobile ? 0 : 12 }}>
            <CustomTextField
              mobile={props.mobile}
              title={'Content'}
              value={field.CustomValue}
              updateValue={(text: string) => {
                const newFields = Object.assign([], customFields);
                newFields[index].CustomValue = text;
                setCustomFields(newFields);
                if (isGeoField && isGeoX) {
                  setGeoXInvalid(!(new RegExp(field.Format).test(text)));
                } else if (isGeoField && isGeoY) {
                  setGeoYInvalid(!(new RegExp(field.Format).test(text)));
                }
              }}
              invalid={(isGeoX && geoXInvalid) || (isGeoY && geoYInvalid)}
              errorText={isGeoField && (`${isGeoX ? 'GeoX' : 'GeoY'} value can only have numeric digits`)}
              scanner={field.CustomName.toLowerCase().includes('mac')}
            />
          </View>
        </View>
        {!field.IsReadOnlyName && (
          <TouchableOpacity
            style={{ position: 'absolute', top: 8, right: 8 }}
            onPress={deleteAction}
          >
            <DeleteIcon24 />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const scanner = () => {
    if (props.hasPermission === null) {
      return <Text>Requesting for camera permission</Text>;
    }
    if (props.hasPermission === false) {
      return <Text>No access to camera</Text>;
    }

    return (
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />
    );
  };

  const renderContent = () => {
    if (props.mobile && addMode === null) {
      return (
        <View style={{ alignItems: 'center', padding: 16 }}>
          <ScanIcon style={{ marginTop: 24 }} />
          <Text
            style={{
              fontFamily: 'Poppins_600SemiBold',
              fontSize: 20,
              marginTop: 24,
              color: Colors.light.hereworksBlack600,
            }}
          >
            Add Asset
          </Text>
          <Text
            style={{
              fontFamily: 'Roboto_400Regular',
              fontSize: 14,
              lineHeight: 22,
              marginHorizontal: 56,
              marginTop: 12,
              color: Colors.light.hereworksBlack600,
              textAlign: 'center',
            }}
          >
            Scan the QR code or use NFC to add installed asset
          </Text>
          <TouchableOpacity
            style={[
              {
                backgroundColor: Colors.light.hereworksLightBlue,
                borderRadius: 4,
                borderWidth: 1,
                marginTop: 12,
                borderColor: Colors.light.hereworksBlue500,
                alignSelf: 'stretch',
              },
            ]}
            onPress={() => {
              analytics('Scan');
              setScanned(false);
            }}
          >
            <Text
              style={{
                color: '#fff',
                fontFamily: 'Roboto_400Regular',
                fontSize: 16,
                marginVertical: 8,
                marginHorizontal: 24,
                textAlign: 'center',
              }}
            >
              Scan
            </Text>
          </TouchableOpacity>
          <View
            style={{
              flexDirection: 'row',
              alignSelf: 'stretch',
              alignItems: 'center',
              marginVertical: 12,
            }}
          >
            <View
              style={{
                height: 1,
                flex: 1,
                backgroundColor: Colors.light.hereworksBlue100,
              }}
            />
            <Text
              style={{
                color: Colors.light.hereworksBlue400,
                fontFamily: 'Roboto_400Regular',
                fontSize: 14,
                marginHorizontal: 8,
              }}
            >
              or
            </Text>
            <View
              style={{
                height: 1,
                flex: 1,
                backgroundColor: Colors.light.hereworksBlue100,
              }}
            />
          </View>
          <TouchableOpacity
            style={[
              {
                backgroundColor: '#fff',
                borderRadius: 4,
                borderWidth: 1,
                borderColor: Colors.light.hereworksBlue500,
                alignSelf: 'stretch',
              },
            ]}
            onPress={() => {
              analytics('Scan');
              setAddMode('Scan');
            }}
          >
            <Text
              style={{
                color: Colors.light.hereworksBlue500,
                fontFamily: 'Roboto_400Regular',
                fontSize: 16,
                marginVertical: 8,
                marginHorizontal: 24,
                textAlign: 'center',
              }}
            >
              Add manually
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              {
                alignSelf: 'stretch',
                marginTop: 16,
              },
            ]}
            onPress={() => {
              props.setModalVisible(false);
            }}
          >
            <Text
              style={{
                color: Colors.light.hereworksBlue500,
                fontFamily: 'Roboto_400Regular',
                fontSize: 16,
                lineHeight: 24,
                marginHorizontal: 24,
                textAlign: 'center',
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
          <Modal
            isVisible={!scanned}
            style={{ alignItems: 'center', margin: 0 }}
            onSwipeComplete={() => setScanned(true)}
            onBackButtonPress={() => setScanned(true)}
            swipeDirection={'down'}
            animationIn={'fadeIn'}
            animationOut={'fadeOut'}
          >
            {scanner()}
            <TouchableOpacity
              onPress={() => setScanned(true)}
              style={[Style.closeButton, props.mobile && { top: topSafeAreaInset ?? 32 }]}
            >
              <CloseModalIcon />
            </TouchableOpacity>
          </Modal>
        </View>
      );
    }

    const renderLocationDropdown = () => (
      <View
        style={{
          height: 200,
          alignSelf: 'stretch',
        }}
      >
        <ScrollView
          nestedScrollEnabled={true}
          indicatorStyle={'black'}
          style={[styles.dropdownView, props.mobile ? { width: '100%' } : {}]}
        >
          {locations?.map((location, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => {
                setLocationInvalid(false);
                setLocationSelection(location.Name);
                setLocationId(location.LocationId);
                setBuilding(location.Building ?? '');
                setArea(location.Area ?? '');
                setFloor(location.Floor ?? '');
                setRoom(location.Room ?? '');
                setLocationDropdownVisible(!locationDropdownVisible);
              }}
            >
              <Text style={styles.dropdownItemText}>{location.Name}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    );

    const renderTemplateDropdown = () => (
      <View
        style={{
          height: 200,
          alignSelf: 'stretch',
        }}
      >
        <ScrollView
          nestedScrollEnabled={true}
          indicatorStyle={'black'}
          style={[styles.dropdownView, props.mobile ? { width: '100%' } : {}]}
        >
          {templates?.map((template, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => {
                setTemplateInvalid(false);
                setTemplateSelection(template.Name);
                setTemplateId(template.Id);
                setTemplateDropdownVisible(!templateDropdownVisible);
              }}
            >
              <Text style={styles.dropdownItemText}>{template.Name}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    );

    return (
      <>
        <TouchableOpacity
          onPress={() => {
            analytics('Close Modal');
            setAddMode(null);
            props.setModalVisible(false);
            restoreDefaults();
          }}
          style={[Style.closeButton, props.mobile && { top: topSafeAreaInset ?? 32 }]}
        >
          <CloseModalIcon />
        </TouchableOpacity>
        <Text style={[styles.title, !props.mobile && { marginTop: 24 }]}>
          Adding asset {asset.Name}
        </Text>
        <Text style={styles.subTitle}>fields marked with * are mandatory</Text>
        <View style={{ flexDirection: 'row', marginTop: props.mobile ? 24 : 32 }}>
          <TouchableOpacity
            style={[
              styles.smallButton,
              {
                backgroundColor: !essentialTabSelected ? '#fff' : Colors.light.hereworksBlue500,
                borderColor: !essentialTabSelected
                  ? Colors.light.hereworksBlue300
                  : Colors.light.hereworksBlue500,
              },
            ]}
            onPress={() => setEssentialTabSelected(!essentialTabSelected)}
          >
            <Text
              style={[
                styles.smallButtonText,
                {
                  color: !essentialTabSelected ? Colors.light.hereworksBlue400 : '#fff',
                },
              ]}
            >
              ESSENTIAL INFO
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.smallButton,
              {
                backgroundColor: essentialTabSelected ? '#fff' : Colors.light.hereworksBlue500,
                borderColor: essentialTabSelected
                  ? Colors.light.hereworksBlue300
                  : Colors.light.hereworksBlue500,
              },
            ]}
            onPress={() => setEssentialTabSelected(!essentialTabSelected)}
          >
            <Text
              style={[
                styles.smallButtonText,
                {
                  color: essentialTabSelected ? Colors.light.hereworksBlue400 : '#fff',
                },
              ]}
            >
              ADDITIONAL INFO
            </Text>
          </TouchableOpacity>
        </View>
        {essentialTabSelected && (
          <>
            <CustomTextField
              mobile={props.mobile}
              title={'Asset name'}
              required
              invalid={nameInvalid}
              errorText='Please enter asset name'
              value={assetName}
              updateValue={(name) => {
                setNameInvalid(!(name.toString().trim().length > 0));
                setAssetName(name);
              }}
            />
            <CustomTextField
              mobile={props.mobile}
              title={'Asset ID (Tag)'}
              required
              errorText={idInvalidText}
              invalid={idInvalid}
              value={scanValue ?? assetId}
              editable={scanValue === null}
              updateValue={(id) => {
                if (!(id.toString().trim().length > 0)) {
                  setIdInvalid(true);
                  setIdInvalidText('Asset ID cannot be empty');
                } else if (id.toString().includes(' ')) {
                  setIdInvalid(true);
                  setIdInvalidText('Asset ID cannot have spaces');
                } else if (!(new RegExp(alphanumericRegex).test(id.toString()))) {
                  setIdInvalid(true);
                  setIdInvalidText('Asset ID must be alphanumeric');
                } else {
                  setIdInvalid(false);
                }
                setAssetId(id);
              }}
            />
            <Text style={styles.heading}>Template *</Text>
            <TouchableOpacity
              style={[styles.dropdownBox, locationInvalid && styles.dropdownBoxInvalid]}
              onPress={() => setTemplateDropdownVisible(!templateDropdownVisible)}
            >
              <Text style={styles.dropdownBoxText}>{templateSelection}</Text>
              <View style={{ alignItems: 'flex-end' }}>
                <FontAwesome
                  size={20}
                  style={{
                    color: locationInvalid ? Colors.light.hereworksRed500
                      : Colors.light.hereworksLightBlue,
                    marginRight: 12,
                  }}
                  name={templateDropdownVisible ? 'angle-up' : 'angle-down'}
                />
              </View>
            </TouchableOpacity>
            {templateInvalid && <Text style={styles.errorText}>Please select a template</Text>}
            {templateDropdownVisible && renderTemplateDropdown()}
            <CustomTextField
              mobile={props.mobile}
              title={'Description'}
              value={description}
              updateValue={(name) => {
                setDescription(name);
              }}
            />
            <CustomTextField
              mobile={props.mobile}
              title={'Model'}
              value={model}
              updateValue={(name) => {
                setModel(name);
              }}
            />
            <CustomTextField
              mobile={props.mobile}
              title={'Serial number'}
              value={serial}
              updateValue={(name) => {
                setSerial(name);
              }}
              scanner
            />
            <CustomTextField
              mobile={props.mobile}
              title={'Manufacturer'}
              value={manufacturer}
              updateValue={(name) => {
                setManufacturer(name);
              }}
            />
            <Text style={styles.heading}>Location *</Text>
            <TouchableOpacity
              style={[styles.dropdownBox, locationInvalid && styles.dropdownBoxInvalid]}
              onPress={() => setLocationDropdownVisible(!locationDropdownVisible)}
            >
              <Text style={styles.dropdownBoxText}>{locationSelection}</Text>
              <View style={{ alignItems: 'flex-end' }}>
                <FontAwesome
                  size={20}
                  style={{
                    color: locationInvalid ? Colors.light.hereworksRed500
                      : Colors.light.hereworksLightBlue,
                    marginRight: 12,
                  }}
                  name={locationDropdownVisible ? 'angle-up' : 'angle-down'}
                />
              </View>
            </TouchableOpacity>
            {locationInvalid && <Text style={styles.errorText}>Please select location</Text>}
            {locationDropdownVisible && renderLocationDropdown()}
            <CustomTextField
              mobile={props.mobile}
              title={'Building'}
              value={building}
              updateValue={setBuilding}
              editable={false}
            />
            {area?.length ? <CustomTextField
              mobile={props.mobile}
              title={'Area'}
              value={area}
              updateValue={setArea}
              editable={false}
            /> : null}
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ flex: 1, marginEnd: 16 }}>
                <CustomTextField
                  mobile={props.mobile}
                  title={'Floor'}
                  value={floor}
                  updateValue={setFloor}
                  editable={false}
                />
              </View>
              <View style={{ flex: 1 }}>
                <CustomTextField
                  mobile={props.mobile}
                  title={'Room'}
                  value={room}
                  updateValue={setRoom}
                  editable={false}
                />
              </View>
            </View>
          </>
        )}
        {!essentialTabSelected && (
          <ScrollView
            nestedScrollEnabled={true}
            indicatorStyle={'black'}
            style={{
              height: 450,
              alignSelf: 'stretch',
            }}
          >
            {customFields?.map((field, index) => {
              if (
                field.CustomName.toLowerCase().includes('serial')
                || field.CustomName.toLowerCase().includes('manu')
                || field.CustomName.toLowerCase().includes('desc')
                || field.CustomName.toLowerCase().includes('model')
                || field.CustomName.toLowerCase().includes('building')
                || field.CustomName.toLowerCase().includes('floor')
                || field.CustomName.toLowerCase().includes('room')
              ) {
                return null;
              }
              return (
                <View key={index}>
                  {renderCustomField(
                    field,
                    () => {
                      const newFields = Object.assign([], customFields);
                      newFields.splice(index, 1);
                      setCustomFields(newFields);
                    },
                    index,
                  )}
                </View>
              );
            })}
            {/* <TouchableOpacity
              style={styles.smallButton}
              onPress={() => {
                const newFields = Object.assign([], customFields);
                const newField = { key: customFields.length + 1, name: '' };
                newFields.push(newField);
                setCustomFields(newFields);
              }}
            >
              <Text style={styles.smallButtonText}>Add field</Text>
            </TouchableOpacity> */}
          </ScrollView>
        )}
        <View style={{ flexDirection: 'row', marginTop: 27, alignSelf: props.mobile ? 'stretch' : 'center' }}>
          {renderBackCancelButton()}
          {renderSaveContinueButton()}
        </View>
      </>
    );
  };

  return (
    <Modal
      isVisible={props.visible}
      animationIn={props.mobile ? 'slideInUp' : 'fadeIn'}
      animationOut={props.mobile ? 'slideOutDown' : 'fadeOut'}
      onBackButtonPress={() => props.setModalVisible(false)}
      style={{
        margin: 0,
        justifyContent: props.mobile ? undefined : 'center',
        flex: 1,
      }}
    >
      <View
        style={[
          {
            alignSelf: props.mobile ? 'auto' : 'center',
            flex: 1,
          },
          !props.mobile && styles.containerStyleWeb,
        ]}
      >
        <ScrollView
          nestedScrollEnabled
          style={{ borderRadius: props.mobile ? 0 : 10 }}
          contentContainerStyle={[
            {
              flexGrow: 1,
              // alignItems: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <View
            style={[
              styles.containerStyle,
              {
                width: props.mobile ? undefined : 530,
                paddingHorizontal: props.mobile ? 24 : 40,
                paddingTop: useSafeAreaInsets().top,
                paddingBottom: 30,
                borderRadius: props.mobile ? 0 : 10,
                alignItems: 'center',
              },
            ]}
          >
            {renderContent()}
          </View>
        </ScrollView>
      </View>
      <AlertModal modalVisible={alertVisible}
        error={alertError}
        title={alertTitle}
        message={alertMessage}
        setModalVisible={setAlertVisible} />
      <ActivityIndicator size="large" animating={loading} style={styles.activityIndicator} />
    </Modal>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: '#fff',
  },
  containerStyleWeb: {
    minHeight: 634,
    marginVertical: 40,
  },
  title: {
    fontSize: 22,
    lineHeight: 33,
    fontFamily: 'Poppins_600SemiBold',
    color: Colors.light.hereworksBlack600,
    marginVertical: 10,
    textAlign: 'center',
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 22,
    color: Colors.light.hereworksBlack300,
    fontFamily: 'Roboto_400Regular',
    textAlign: 'center',
  },
  imageTitle: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.light.hereworksBlack400,
    fontFamily: 'Roboto_400Regular',
  },
  circle: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: Colors.light.hereworksDarkBlue,
    marginTop: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleText: {
    fontSize: 16,
    fontFamily: 'Poppins_600SemiBold',
    color: '#fff',
  },
  smallButtonText: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    marginVertical: 8,
    marginHorizontal: 12,
    color: '#fff',
  },
  smallButton: {
    borderRadius: 4,
    marginRight: 12,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue500,
    backgroundColor: Colors.light.hereworksBlue500,
    alignSelf: 'center',
  },
  deleteButton: {
    borderColor: Colors.light.hereworksRed,
    borderRadius: 4,
    borderWidth: 1,
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
    alignSelf: 'flex-end',
  },
  heading: {
    fontSize: 16,
    lineHeight: 24,
    marginTop: 12,
    marginBottom: 4,
    alignSelf: 'flex-start',
    fontFamily: 'Roboto_400Regular',
    color: Colors.light.hereworksBlack400,
  },
  dropdownView: {
    zIndex: 5,
    borderRadius: 10,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksPaleBlue900,
    backgroundColor: '#fff',
  },
  dropdownItemText: {
    marginLeft: 12,
    marginVertical: 9,
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksPrimaryGrey,
  },
  dropdownBox: {
    width: '100%',
    height: 44,
    borderWidth: 1.5,
    borderColor: Colors.light.hereworksBlue100,
    borderRadius: 10,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    zIndex: 2,
  },
  dropdownBoxInvalid: {
    borderColor: Colors.light.hereworksRed500,
  },
  dropdownBoxText: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: Colors.light.hereworksBlack600,
    marginVertical: 9.5,
    marginLeft: 12,
    flex: 1,
  },
  activityIndicator: {
    position: 'absolute',
    top: Dimensions.get('window').height * 0.45,
    alignSelf: 'center',
  },
  alertAssetName: {
    fontFamily: 'Roboto_700Bold',
  },
  errorText: {
    color: Colors.light.hereworksRed500,
    fontFamily: 'Roboto_400Regular',
    marginTop: 4,
    fontSize: 12,
    lineHeight: 20,
    alignSelf: 'flex-start',
  },
});
