import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PeopleIcon24 = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M15 13.1a4.712 4.712 0 0 0-1-.1H8a5 5 0 0 0-5 5v1s2 2 8 2a22 22 0 0 0 3-.19c.35 0 .69-.1 1-.17M17 17h4m-2-2v4M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0Z"
      stroke={props.color ?? '#3379BD'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default PeopleIcon24;
