import React, { useState } from 'react';
import {
  StyleSheet, Text, TextInput, TouchableOpacity, View, ScrollView, Platform, Dimensions,
} from 'react-native';
import Colors from '../../../constants/Colors';
import DeleteIcon24 from '../../24pxIcons/DeleteIcon24';
import PriorityFlag from '../../16pxIcons/PriorityFlag';
import UserIcon24 from '../../24pxIcons/UserIcon24';
import Switch from '../../Switch';
import PlusIcon20 from '../../24pxIcons/PlusIcon';
import DropdownIcon from '../../16pxIcons/DropdownIcon';
import Style from '../../../constants/Style';
import DropdownIcon12 from '../../12pxIcons/DropdownIcon12';
import PlusIcon from '../../16pxIcons/PlusIcon';
import CloseIconSmall from '../../12pxIcons/CloseIconSmall';

const mobile = Platform.OS !== 'web' || Dimensions.get('window').width < 500;

export const ISSUE_PRIORITY_LIST: string[] = ['P1 - Critical', 'P2 - High', 'P3 - Moderate', 'P4 - Low', 'P5 - Other'];

export type Issue = {
  Id?: string,
  LocalId?: string,
  Text: string,
  UserGroupId?: string[],
  Priority?: number,
  Enabled?: boolean,
  PrevUserGroupId?: string[],
}

export type UserGroup = {
  Name: string,
  UserGroupId: string,
}

const priorityList: number[] = [1, 2, 3, 4];

export const priorityColor = (priority: number) => {
  switch (priority) {
    case 1: return Colors.light.hereworksRed500;
    case 2: return Colors.light.hereworksYellow500;
    case 3: return Colors.light.hereworksBlue600;
    case 4: return Colors.light.hereworksGreen400;
    case 5: return Colors.light.hereworksDarkGreen;
    default: return Colors.light.hereworksBlack400;
  }
};

export interface OptionProps {
  text: string,
  onPress: () => void,
  selected?: boolean,
}

export const Option = ({ text, onPress, selected }: OptionProps) => <TouchableOpacity
  onPress={onPress}
  style={selected && styles.selectedOption}
>
  <Text style={[styles.optionText, selected && styles.selectedOptionText]}>{text}</Text>
</TouchableOpacity>;

interface IssueRowProps {
  groups: UserGroup[],
  title: string,
  tags: string[],
  priority: number,
  id: string,
  enabled: boolean,
  deleteIssue: () => void,
  // eslint-disable-next-line no-unused-vars
  updateIssue: (title: string, tags: string[], priority: number, enabled: boolean) => void,
}

export default function IssueRow({
  title, tags, priority, id, updateIssue, deleteIssue, enabled = true, groups,
}: IssueRowProps) {
  const [priorityDropdownShown, setPriorityDropdownShown] = useState<boolean>(false);
  const [tagDropdownShownIndex, setTagDropdownShownIndex] = useState<number>(-1);
  const [issueTitle, setIssueTitle] = useState<string>(title);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [tagDropdownHeight, setTagDropdownHeight] = useState<number>(0);
  const [priorityDropdownHeight, setPriorityDropdownHeight] = useState<number>(0);

  return <View
    key={`issue_${id}`}
    style={[
      Style.row,
      styles.container,
      {
        marginBottom: 8 + Math.max(
          0,
          tagDropdownShownIndex > -1
            ? 80 + tagDropdownHeight + styles.tagDropdown.top - containerHeight : 0,
          priorityDropdownShown ? priorityDropdownHeight
            + styles.priorityDropdown.top - containerHeight : 0,
        ),
      },
    ]}
  >
    <View
      style={styles.box}
    >
      <View
        style={[
          {
            borderTopStartRadius: styles.box.borderRadius,
            borderBottomStartRadius: styles.box.borderRadius,
          },
          styles.indication, enabled && styles.enabledIndication,
        ]}
        onLayout={(event) => setContainerHeight(event.nativeEvent.layout.height)}
      />
      <View style={styles.boxContent}>
        <View style={Style.row}>
          <View style={[Style.row, { flex: 1 }]}>
            <TextInput
              placeholder='Enter issue'
              placeholderTextColor={Colors.light.hereworksBlack400}
              value={issueTitle}
              onChangeText={(text: string) => {
                setPriorityDropdownShown(false);
                setTagDropdownShownIndex(-1);
                setIssueTitle(text);
              }}
              onBlur={() => updateIssue(issueTitle, tags, priority, enabled)}
              onEndEditing={() => updateIssue(issueTitle, tags, priority, enabled)}
              onSubmitEditing={() => updateIssue(issueTitle, tags, priority, enabled)}
              style={styles.textInput}
            />
            <View>
              {priorityList.includes(parseInt(`${priority}`, 10)) ? <TouchableOpacity
                activeOpacity={1}
                onPress={() => {
                  setTagDropdownShownIndex(-1);
                  setPriorityDropdownShown(!priorityDropdownShown);
                }}
                style={[
                  styles.priorityContainer, Style.row, { borderColor: priorityColor(priority) },
                ]}>
                <PriorityFlag color={priorityColor(priority)} />
                <Text style={[styles.priorityText, { color: priorityColor(priority) }]}>
                  {`P${priority}`}
                </Text>
                <DropdownIcon
                  color={priorityColor(priority)}
                  style={priorityDropdownShown && { transform: [{ rotate: '180deg' }] }}
                />
              </TouchableOpacity> : <TouchableOpacity
                onPress={() => {
                  setTagDropdownShownIndex(-1);
                  setPriorityDropdownShown(true);
                }}
              >
                <PlusIcon20 />
              </TouchableOpacity>}
            </View>
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={deleteIssue}>
            <DeleteIcon24 />
          </TouchableOpacity>
        </View>
        {groups?.length ? <View style={styles.tagContainer}>
          {(typeof tags === 'object' && tags?.length) ? tags?.map((tag: string, index: number) => <>
            <TouchableOpacity
              onPress={() => {
                setPriorityDropdownShown(false);
                setTagDropdownShownIndex(tagDropdownShownIndex > -1 ? -1 : index);
              }}
              style={styles.tag}
            >
              <UserIcon24 />
              <Text style={styles.tagText}>
                {groups?.filter((group: UserGroup) => group.UserGroupId === tag)[0]
                  ?.Name ?? 'Unknown group'}
              </Text>
              <DropdownIcon12 />
              <TouchableOpacity onPress={() => {
                const updatedTags = [...tags];
                updatedTags.splice(index, 1);
                updateIssue(title, updatedTags, priority, enabled);
              }}>
                <CloseIconSmall />
              </TouchableOpacity>
            </TouchableOpacity>
            {tagDropdownShownIndex === index && <ScrollView
              style={[styles.dropdown, styles.tagDropdown]}
              onLayout={(event) => setTagDropdownHeight(event.nativeEvent.layout.height)}
            >
              {groups.filter((group: UserGroup) => !tags?.includes(group.UserGroupId))
                .map((group: UserGroup) => <Option
                  key={`option_${group.UserGroupId}`}
                  text={group.Name}
                  selected={tags?.length && group.UserGroupId === tags[index]}
                  onPress={() => {
                    const updatedTags: string[] = tags ?? [];
                    updatedTags[index] = group.UserGroupId;
                    updateIssue(title, updatedTags, priority, enabled);
                    setTagDropdownShownIndex(-1);
                  }}
                />)}
            </ScrollView>}
          </>) : null}
          <>
            <TouchableOpacity
              onPress={() => {
                setPriorityDropdownShown(false);
                setTagDropdownShownIndex(tagDropdownShownIndex > -1 ? -1 : (tags?.length ?? 0));
              }}
              style={styles.addTagIcon}
            >
              <PlusIcon color={Colors.light.hereworksDarkBlue} />
            </TouchableOpacity>
            {tagDropdownShownIndex === (tags?.length ?? 0) && <ScrollView
              style={[styles.dropdown, styles.tagDropdown]}
              onLayout={(event) => setTagDropdownHeight(event.nativeEvent.layout.height)}
            >
              {groups.filter((group: UserGroup) => !tags?.includes(group.UserGroupId))
                .map((group: UserGroup) => <Option
                  key={`option_${group.UserGroupId}`}
                  text={group.Name}
                  selected={tags?.length && group.UserGroupId === tags[tags?.length]}
                  onPress={() => {
                    const updatedTags: string[] = tags ?? [];
                    updatedTags.push(group.UserGroupId);
                    updateIssue(title, updatedTags, priority, enabled);
                    setTagDropdownShownIndex(-1);
                  }}
                />)}
            </ScrollView>}
          </>
        </View> : null}
        <View style={[Style.row, styles.dotsRow]}>
          {[...Array(30).keys()].map((dotIndex: number) => <View
            style={styles.dot}
            key={`dot_first_row_${dotIndex}`}
          />)}
        </View>
        <View style={[Style.row, styles.dotsRow]}>
          {[...Array(30).keys()].map((dotIndex: number) => <View
            style={styles.dot}
            key={`dot_second_row_${dotIndex}`}
          />)}
        </View>
      </View>
    </View>
    <Switch
      toggled={enabled}
      toggle={() => updateIssue(title, tags, priority, !enabled)}
    />
    {priorityDropdownShown && <View
      style={[styles.dropdown, styles.priorityDropdown]}
      onLayout={(event) => setPriorityDropdownHeight(event.nativeEvent.layout.height)}
    >
      {ISSUE_PRIORITY_LIST.map((text: string, index: number) => <Option
        key={`option_${text}`}
        text={text}
        selected={priority === index + 1}
        onPress={() => {
          updateIssue(title, tags, index + 1, enabled);
          setPriorityDropdownShown(false);
        }}
      />)}
    </View>}
  </View>;
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 8,
  },
  priorityExpandedContainer: {
    marginBottom: 88,
  },
  tagExpandedContainer: {
    marginBottom: 100,
  },
  box: {
    flexDirection: 'row',
    borderRadius: 10,
    backgroundColor: Colors.light.hereworksWhite100,
    flex: 1,
    marginEnd: mobile ? 10 : 20,
  },
  indication: {
    backgroundColor: Colors.light.hereworksWhite600,
    borderTopEndRadius: 0,
    borderBottomEndRadius: 0,
    width: 8,
  },
  enabledIndication: {
    backgroundColor: Colors.light.hereworksGreen400,
  },
  boxContent: {
    paddingHorizontal: 8,
    paddingVertical: 12,
    flex: 1,
  },
  priorityContainer: {
    borderRadius: 4,
    borderWidth: 1,
    paddingHorizontal: 4,
    marginEnd: 8,
    backgroundColor: '#FFF',
  },
  priorityText: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 20,
    marginHorizontal: 2,
  },
  textInput: {
    color: Colors.light.hereworksBlack600,
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    lineHeight: Platform.OS === 'ios' ? undefined : 24,
    flex: 1,
    flexWrap: 'wrap',
    paddingHorizontal: 10,
    paddingBottom: 8,
    paddingTop: 8,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.light.hereworksBlue50,
    marginEnd: 4,
    backgroundColor: '#FFF',
  },
  button: {
    marginStart: 8,
  },
  tagContainer: {
    marginTop: 4,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  tag: {
    backgroundColor: Colors.light.hereworksDarkBlue,
    borderRadius: 8,
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginVertical: 1,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginEnd: 8,
  },
  tagText: {
    color: '#FFF',
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    lineHeight: 24,
    marginHorizontal: 4,
    flex: 1,
  },
  switch: {
    transform: [{ scale: 0.7 }],
  },
  dotsRow: {
    overflow: 'hidden',
    flexShrink: 1,
    zIndex: -1,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 3,
    backgroundColor: Colors.light.hereworksDarkBlue100,
    marginHorizontal: 5,
    marginTop: 10,
  },
  dropdown: {
    position: 'absolute',
    width: 170,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: Colors.light.hereworksBlue100,
    backgroundColor: '#FFF',
    alignSelf: 'center',
    overflow: 'hidden',
  },
  priorityDropdown: {
    top: 50,
    end: 0,
  },
  tagDropdown: {
    start: 18,
    top: 36,
    maxHeight: 180,
  },
  optionText: {
    paddingHorizontal: 12,
    paddingVertical: 9,
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Poppins_400Regular',
    color: Colors.light.hereworksBlack600,
  },
  addTagIcon: {
    width: 32,
    height: 32,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.light.hereworksDarkBlue,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 1,
  },
  selectedOption: {
    backgroundColor: Colors.light.hereworksDarkBlue,
  },
  selectedOptionText: {
    color: '#FFF',
  },
});
